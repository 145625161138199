/**
 * Imaging model to group all apis related to it
 *
 *
 * To seperate all logic
 *
 */

import React, { useState, useEffect, useContext, useRef } from 'react';
import { Card, Space, Button, Select, Form, Tag, Row, message, Alert, Skeleton, Typography, Input, Statistic, PageHeader, Col, Avatar } from 'antd';

import Model from './models';

import { ApiUtils } from 'soxo-bootstrap-core';

import moment from 'moment-timezone';

class MedicalReports extends Model {
    constructor() {
        super();

        this.title = 'Reporting List';

        this.fields = [
            {
                field: 'name',
                caption: 'Name',
            },
            {
                field: 'amount',
                caption: 'Amount',
            },
        ];

        // this.columns = ;
    }

    get id() {
        return 'cold_id';
    }

    get getEndpoint() {
        return 'colldetails';
    }

    get path() {
        return `collections`;
    }

    get getName() {
        return this.modelName + `s`;
    }

    get modelName() {
        return `collection`;
    }

    get columns() {
        return [
            {
                caption: 'Staff',
                field: 'staff.name',
                key: 'staff',
            },
            {
                caption: 'Weight',
                field: 'weight',
                key: 'weight',
            },
            {
                caption: 'Date',
                field: 'created_at',
                key: 'created_at',
            },
        ];
    }

    /**
     *
     */
    createNew = (id) => {
        return ApiUtils.post({ url: 'opbill/kiosk/' + id });
    };

    /**
     * Create Patient Medical Report
     *
     * @param {*} patient
     * @param {*} values
     *
     * @returns
     */
    create = (patient, values, content, user, patientMedicalReportModule) => {

        var formBody = {
            pmr_opvid: null,
            pmr_opno: patient.opreg.op_no,
            pmr_ipno: ' ',
            pmr_doctorptr: user.staff_id,
            pmr_report: content,
            pmr_remark: ' ',
            pmr_user: user.email,
            pmr_userid: user.id,
            pmr_shiftid: null,
            pmr_shiftdt: null, // has to be taken
            // pmr_canflg: patient.opreg.opb_canflg,
            // pmr_canuser: patient.opb_canuser,
            // pmr_canuserid: patient.opb_canuserid,
            // pmr_cantime: patient.opb_cantime,
            // pmr_canremark: patient.opb_canremarks,
            // pmr_canshiftno: patient.opb_canshiftno,
            // pmr_canshiftdt: null,
            pmr_entrydt: moment.tz('Asia/Calcutta').format(), // Server date
            pmr_reportnm: null, // Selected template name
            pmr_depptr: null, // Dept reporting field
            pmr_rptstatus: values.report_status ? 'S' : 'D', // add a checkbox on detail screen for S or D .
            pmr_module: patientMedicalReportModule,
            pmr_moduleRefNo: values.accessionno,
            pmr_serviceitemrefid: patient.modality.mpst_detrefid, // pass modality detail ref id
            pmr_serviceitmeptr: patient.modality.mpst_itemptr, // Modality item ptr
            pmr_patienttype: 'OP',
            pmr_entrytm: moment.tz('Asia/Calcutta').format('YYYY-MM-DD hh:mm'), // DATE AND TIME
            pmr_templateptr: null, // SELECTED TEMPLATE PTR
            pmr_timetaken: 12, // time taken
            pmr_otherdetails1: null,
            pmr_contenttype: 'HTML', // Pass HTML
            pmr_timetaken: parseInt(values.time_taken),
        };

        return ApiUtils.post({
            url: `patientmedicalreports`,
            formBody,
        });
    };


      /**
     * Create Patient Medical Report
     *
     * @param {*} patient
     * @param {*} values
     *
     * @returns
     */
      saveMedicalreport = (formBody) => {

        return ApiUtils.post({
            url: `patientmedicalreports/save-medical-report`,
            formBody,
        });
    };

      /**
     * Cancel Patient Medical Report
     *
     * @param {*} patient
     * @param {*} values
     *
     * @returns
     */
      cancelMedicalreport = (id,formBody) => {

        return ApiUtils.put({
            url: `patientmedicalreports/cancel-medical-reports/${id}`,
            formBody,
        });
    };

    /**
     *
     *
     * @param {*} id
     * @param {*} params
     */
    update = (id, formBody) => {
        return ApiUtils.put({
            url: `patientmedicalreports/${id}`,
            formBody,
        });
    };
}

export default MedicalReports;
