/***
 *
 *
 * @description
 * @author Sneha
 */

import React, { useEffect, useState, useContext } from 'react';

import { Link } from 'react-router-dom';

import { Card, Table, Button, Space, Typography, Skeleton, Tag, Input, Switch, Tabs, Form, Modal, message, Select } from 'antd';

import { DateUtils, GlobalContext, Location, RangePicker, ReferenceSelect, CustomActions, InputComponent, useTranslation } from 'soxo-bootstrap-core';

import { Bills, Customers, Opreg, Appointments } from '../../../../models';

import moment from 'moment-timezone';

import { ReloadOutlined, OrderedListOutlined, PicCenterOutlined } from '@ant-design/icons';

import './bill-list.scss';

import Bill from '../../../../models/bill';

import OpregSearch from '../../../candidate-visit/opreg-search';

const { Search } = Input;

const { TabPane } = Tabs;

const { Option } = Select;

const { Title, Text } = Typography;

export default function BillList({ model, edit, history, match, menu, redirect_link, order, filterCredit, corporateFilter, ...props }) {
    let { attributes = {} } = menu;

    //Patients list array
    var [patients, setPatients] = useState([]);

    var [query, setQuery] = useState('');

    const { user = {}, isMobile, dispatch } = useContext(GlobalContext);

    var [actives, setActives] = useState([]);

    //Need to check this condition
    const [view, setView] = useState(isMobile ? true : false);

    var param = Location.search();

    const [loading, setLoading] = useState(true);

    const [corporate, setCorporate] = useState(false);

    const [limit, setLimit] = useState(10);

    const [page, setPage] = useState(1);

    useEffect(() => {
        loadData(range);
    }, []);

    var starttime = moment().startOf('day');

    var endtime = moment().endOf('day');

    if (param.start_time) {
        updateRange();
    }

    function changeView(result) {
        setView(result);
    }

    function updateRange() {
        starttime = moment(param.start_time, 'DD/MM/YYYY', process.env.REACT_APP_TIMEZONE).startOf('day');
        endtime = moment(param.end_time, 'DD/MM/YYYY', process.env.REACT_APP_TIMEZONE).startOf('day');

        return starttime, endtime;
    }

    const [range, setRange] = useState([starttime, endtime]);

    const { t, i18n } = useTranslation();   // To Translate to another language

    /**
     *  columns of table for Reward list
     */

    const bookingColums = [
        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => {
                return (page - 1) * limit + index + 1;
            },
        },

        {
            title: t('Name'),
            key: 'name',
            render: ele => {
                const { bill, reward } = ele;

                return ele.opb_name;
            },
        },

        {
            title: t('OP No'),
            key: 'opb_opno',
            render: ele => {
                const { bill, reward } = ele;

                return (
                    <span>
                        <Tag color="green">{ele.opb_opno}</Tag>
                    </span>
                );
            },
        },

        {
            //for stoptb title: ' Order Date',
            title: t('Date'),
            key: 'created-at',
            render: ele => {
                const { bill, reward } = ele;

                if (ele.opb_dt) {
                    return moment(ele.opb_dt).format('DD/MM/YYYY');
                }
            },
        },

        {
            title: t('Gender'),
            key: 'gender',

            render: ele => {
                const { bill, reward } = ele;

                return ele.opb_gender;
            },
        },
        // for stoptb
        {
            title: t('Age'),
            key: 'opb_age',
            render: ele => {
                const { bill, reward } = ele;

                return <span>{ele.opb_age}</span>;
            },
        },

        {
            title: t('Contact'),
            // dataIndex: 'Name',
            key: 'Mobile',
            render: ele => {
                // const { bill, reward } = ele;

                if (ele.opb_mobile) return ele.opb_mobile;
            },
        },

        //only for nura
        // {
        //     title: 'Wallet',
        //     key: 'wallet',
        //     render: (ele) => {
        //         const { bill, reward } = ele;

        //         if (reward) {
        //             return reward.status === 'E' ? <Tag color="blue">Under Processing</Tag> : <Tag color="green">Active</Tag>;
        //         } else {
        //             return <Tag color="orange">Inactive</Tag>;
        //         }
        //     },
        // },

        //only for nura
        // {
        //     title: 'Accumulated Points',
        //     key: 'wallet',
        //     render: (ele) => {
        //         const { bill, reward } = ele;

        //         if (reward) {
        //             return reward.accumulated_points;
        //         } else {
        //             return 0;
        //         }
        //     },
        // },

        //only for nura
        // {
        //     title: 'Balance Points',
        //     key: 'wallet',
        //     render: (ele) => {
        //         const { bill, reward } = ele;

        //         if (reward) {
        //             return reward.balance_points;
        //         } else {
        //             return 0;
        //         }
        //     },
        // },

        // {
        //     title: 'Paid amount',
        //     // dataIndex: 'Name',
        //     key: 'paid',
        //     render: (ele) => {
        //         if (ele.opb_paidamt)
        //             return ele.opb_paidamt;
        //     },
        // },
        // {
        //     title: 'Discount',
        //     // dataIndex: 'Name',
        //     key: 'paid',
        //     render: (ele) => {
        //         if (ele.opb_disamt)
        //             return ele.opb_disamt;
        //     },
        // },

        // {
        //     title: 'Total',
        //     // dataIndex: 'Name',
        //     key: 'Total',
        //     render: (ele) => {
        //         return ele.opb_netamt;
        //     },
        // },

        {
            title: t('Action'),
            fixed: 'right',

            render: record => {
                const { bill, reward } = record;

                let redirectLink;
                console.log('ddddd', redirectLink);
                if (order) redirectLink = `/order/:id`;
                else redirectLink = `/bill/:id`;

                if (redirect_link) {
                    redirectLink = redirect_link;
                }

                redirectLink = redirectLink.replace(':id', record.opb_id);

                // If the bill list is used to list credit type bills
                if (filterCredit) {
                    return <Link to={`${redirectLink}`}>{t('View')}</Link>;
                } else return <Link to={`${redirectLink}?opb_id=${record.opb_id}&opb_no=${record.opb_bno}&opno=${record.opb_opno}`}>{t('View')}</Link>;
            },
        },
    ];

    /**
     * Load Data
     *
     */
    function loadData() {
        let urlParams = Location.search();

        // Match params
        let params = {
            start_time: range[0].format('MM/DD/YYYY'),
            end_time: range[1].format('MM/DD/YYYY'),

            ...urlParams,
        };

        getAppointments(range);
    }

    function onSearch(event) {
        setQuery(event.target.value);
    }

    /**
     * Loading the appointments
     *
     * @param {*} startTime
     * @param {*} endTime
     */
    async function getAppointments(range) {
        setLoading(true);
        let corporateValue;

        if (corporateFilter) {
            corporateValue = corporate;
        }
        // const result = await Bills.getbillList(range);
        // If the bill list is used to list credit type bills
        if (filterCredit) result.data = result.data.filter(ele => ele.opb_btype === '0');

        const result = await Bills.getbillList(range, corporateValue);

        // If the bill list is used to list credit type bills
        if (filterCredit) {
            result.data = result.data.filter((ele) => ele.opb_btype === "0")
        }
        if (result.data) {
            setPatients(result.data);
        } else {
            setPatients([]);
        }

        setLoading(false);
    }

    let dataSource = patients.filter(record => {
        query = query.toUpperCase();

        if (query) {
            if (record && record.opb_name && record.opb_name.toUpperCase().indexOf(query) != -1) {
                return true;
            } else if (record & record.opb_mobile && record.opb_mobile.indexOf(query) != -1) {
                return true;
            }
        } else {
            return true;
        }
    });

    //only for nura
    let filtered = actives.filter(record => {
        query = query.toUpperCase();
        const { opreg } = record;

        if (query) {
            // console.log("record",record);
            // if(record.opbill){
            if (opreg && opreg.op_fname.toUpperCase().indexOf(query) != -1) {
                return true;
            } else if (opreg && opreg.op_mobile && opreg.op_mobile.indexOf(query) != -1) {
                return true;
            }

            // }
        } else {
            return true;
        }
    });

    function updateTime(range) {
        setRange(range);

        Location.search({
            start_time: moment(range[0]).format('DD/MM/YYYY'),
            end_time: moment(range[1]).format('DD/MM/YYYY'),
        });

        getAppointments(range); //Patiant list get function
    }

    const onSelect = (value) => {

        setCorporate(value)

        // getAppointments()

        console.log("valueee", value);
    };


    // async function getOPNO() {
    //     Appointments.getBillNo().then((result) => {
    //         setBillno(result.no);
    //     });
    // }

    // function updateTime(dt) {
    //     // setRange(dt);

    //     let start_time = DateUtils.getFormattedTimeDate(dt[0])

    //     let end_time = DateUtils.getFormattedTimeDate(dt[1]);

    //     setRange(dt);

    //     Location.search({

    //         start_time,

    //         end_time,
    //     });

    //      getAppointments(dt[0], dt[1]); //Patiant list get function

    // }

    /**
     *
     */

    // Variable for the dropdown of category
    let department = [
        {
            value: 'ALL',
            label: 'ALL',
        },
        {
            value: 'OP',
            label: 'OP',
        },
        {
            value: 'IP',
            label: 'IP',
        },
        {
            value: 'PHM',
            label: 'PHARMACY',
        },
    ];

    function refresh() {
        loadData();
    }

    /**
     * redirect to patients details
     */
    function redirectToPatientDetails() {
        Location.navigate({ url: '/personal-details' });
    }

    return (
        <section className="bill-list card card-shadow">
            {/* Page Header */}

            {/* Page Header Ends */}

            {/* Content Below */}
            {loading ? (
                <>
                    <Skeleton active />
                </>
            ) : (
                <>
                    <>
                        <div className="left">
                            {/* <Title style={{ marginBottom: '0px' }} level={3}>
                                {order ? 'Order List' : 'Bill List'}
                            </Title> */}

                            {/* <div>
                                            {view ? (
                                                <>
                                                    <CardList redirect_link={redirect_link} dataSource={dataSource} />
                                                </>
                                            ) : (
                                                <>
                                                    <Card className="table-card">
                                                        <Table
                                                            scroll={{ x: true }}
                                                            rowKey={(record) => record.da_id}
                                                            dataSource={dataSource}
                                                            columns={bookingColums}
                                                            pagination={{
                                                                current: page,
                                                                onChange(current) {
                                                                    setPage(current);
                                                                },
                                                            }}
                                                        />
                                                    </Card>
                                                </>
                                            )}
                                        </div> */}


                        </div>

                        {/* Tab needed only in nura */}
                        {/* <Tabs defaultActiveKey="0">
                            <TabPane tab="Bills" key="0"> */}
                        <div className="page-header">
                            <div className="left">
                                <Search
                                    placeholder={t("Enter Search Value")}
                                    allowClear
                                    style={{ width: 300, marginTop: '10px', marginBottom: '20px' }}
                                    onChange={onSearch}
                                />
                                {filterCredit ? (
                                    <>
                                        <ReferenceSelect
                                            label="cc_desc"
                                            mode="nura-base"
                                            model={Customers}
                                            style={{ width: 100, marginTop: '10px', marginBottom: '20px' }}
                                            field={'cc_code'}
                                            value={corporate}
                                            onChange={entry => onSelect(entry)}
                                        />
                                        <Select defaultValue="All"
                                            style={{ width: 100, marginTop: '10px', marginBottom: '20px' }}
                                        //  onChange={handleChange}
                                        >
                                            {department.map((record) => (
                                                <Option value={record.label}>{record.label}</Option>
                                            ))}
                                        </Select>
                                    </>
                                ) : null}
                                <div className="top-actions">
                                    {/* <Button
                                    type="primary"
                                    size="small"
                                    onClick={() => {
                                        Location.navigate({ url: `/multiple-candidate/bill` });
                                    }}
                                >
                                    Bill
                                </Button> */}
                                    {/* </div>

                            <div className="top-actions"> */}

                                </div>
                            </div>

                            <div className="right">
                                <Switch
                                    defaultChecked={view}
                                    onChange={changeView}
                                    checked={view}
                                    checkedChildren={<OrderedListOutlined />}
                                    unCheckedChildren={<PicCenterOutlined />}
                                />
                                <div className="date-and-fltr">
                                    {/** OpregSearch in billing screen start */}
                                    <OpregSearch
                                        dataSource={filtered}
                                        title={t('Advance Search')}
                                        mode="opreg"
                                        enableModal={'bill'}
                                        callback={data => {
                                            // set callback values to variable
                                            let bill = data.bill;

                                            let redirectLink;
                                            // if there is redirect url is present then replace the id values 
                                            if (redirect_link) {
                                                redirectLink = redirect_link;
                                            }
                                            redirectLink = redirectLink.replace(':id', bill.opb_id);
                                            
                                            Location.navigate({ url: `${redirectLink}?opb_id=${bill.opb_id}&opb_no=${bill.opb_id}&opno=${bill.opb_opno}` });
                                        }}
                                        model={Opreg}
                                    />
                                    {/** OpregSearch in billing screen End */}
                                    <RangePicker
                                        allowClear={false}
                                        inputReadOnly
                                        format={'DD/MM/YYYY'}
                                        value={range}
                                        onChange={time => {
                                            updateTime(time, range);
                                        }}
                                        ranges={{
                                            Today: [moment(), moment()],

                                            Yesterday: [
                                                moment()
                                                    .subtract(1, 'days')
                                                    .startOf('day'),
                                                moment()
                                                    .subtract(1, 'days')
                                                    .endOf('day'),
                                            ],

                                            'This Week': [moment().startOf('week'), moment().endOf('week')],

                                            'Last Week': [
                                                moment()
                                                    .subtract(1, 'week')
                                                    .startOf('week'),
                                                moment()
                                                    .subtract(1, 'week')
                                                    .endOf('week'),
                                            ],

                                            'This Month': [moment().startOf('month'), moment().endOf('month')],

                                            'Last Month': [
                                                moment()
                                                    .subtract(1, 'month')
                                                    .startOf('month'),
                                                moment()
                                                    .subtract(1, 'month')
                                                    .endOf('month'),
                                            ],
                                        }}
                                    />

                                    <Button onClick={refresh} type="secondary" size={'small'}>
                                        <ReloadOutlined />
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div>
                            {view ? (
                                <>
                                    <CardList redirect_link={redirect_link} dataSource={dataSource} />
                                </>
                            ) : (
                                <>
                                    <Card className="table-card">
                                        <Table
                                            scroll={{ x: true }}
                                            rowKey={record => record.da_id}
                                            dataSource={dataSource}
                                            columns={bookingColums}
                                            pagination={{
                                                current: page,
                                                onChange(current) {
                                                    setPage(current);
                                                },
                                            }}
                                        />
                                    </Card>
                                </>
                            )}
                        </div>

                        <p className="size-hint">{loading ? 'Loading' : patients.length} records.</p>
                    </>
                </>
            )}
        </section>
    );
}

function CardList({ dataSource, redirect_link }) {
    /**
     * Redirect to the arrived redirect link
     * on click
     *
     * @param {*} record
     */
    // function onClick(record) {
    //     let redirectLink = `/bill/:id`;

    //     if (redirect_link) {
    //         redirectLink = redirect_link;
    //     }

    //     redirectLink = redirectLink.replace(':id', record.opb_opno);

    //     Location.navigate({
    //         url: `${redirectLink}?opb_id=${record.opb_id}&opb_no=${record.opb_bno}&opno=${record.opb_opno}`,
    //     });
    // }

    /**
     *
     */
    return dataSource.map((item, index) => {
        const { bill, reward } = item;

        let redirectLink = `/order/:id`;

        redirectLink = redirect_link.replace(':id', item.opb_id);

        return (
            <Link
                to= {`${redirectLink}?opb_id=${item.opb_id}&opb_no=${item.opb_bno}&opno=${item.opb_opno}`}
                key={index}
                // onClick={bill => {
                //     onClick(bill);
                // }}
            >
                <GuestCard record={item} />
            </Link>
        );
    });
}

/**
 *
 * @param {*} param0
 * @returns
 */
function GuestCard({ record, ele }) {
    const { bill, reward } = record;

    const { t, i18n } = useTranslation();   // To Translate to another language

    return (
        <Card className="card vehicle-card">
            <div className="title">
                <p>
                    {t('Name')}: <strong>{record.opb_name} </strong>
                </p>
            </div>
            <div className="values">
                <p>
                    {t('Order Date')}: <strong>{moment(record.opb_dt).format('DD/MM/YYYY')}</strong>{' '}
                </p>
            </div>
            <div className="values">
                    {t('OP NO')}:{' '}
                <Tag color="green">
                    <strong>{record.opb_opno}</strong>
                </Tag>
            </div>

            <div className="values">
                <p>
                    {t('Gender')} : <strong>{record.opb_gender}</strong>
                </p>
            </div>

            <div className="values">
                <p>
                    {t('Age')}: <strong> {record.opb_age}</strong>
                </p>
            </div>

            <div className="values">
                <p>
                    {t('Contact')}:<strong>{record.opb_mobile}</strong>{' '}
                </p>
            </div>
            {/* <div className="values">
                 <p>Wallet: {reward ? <Tag color="green">Active</Tag> : <Tag color="orange">Inactive</Tag>}</p>
             </div> */}
        </Card>
    );
}
