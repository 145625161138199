import React, { useState, useEffect, useContext } from 'react';

import { Tag, Card, Modal, Button, Form, Table, Row, message, Select, Skeleton, Typography, Input, Timeline, Col, InputNumber, Alert } from 'antd';

import './result-detail.scss';

import { Location, GlobalContext, ReferenceSelect, ConfirmModal, useTranslation, ExtraInfoDetail } from 'soxo-bootstrap-core';

import ReportPreview from '../report-preview/report-preview';

import { Samples, ResultEntry, GeneralLists } from '../../../../models';

import Formula from '../../../formula/formula';

import EntryInfo from '../../../common/components/result-entry-info/result-entry-info';

import ResutLog from '../../../result-log/result-log';

const { Title } = Typography;

const { TextArea } = Input;

const { Option } = Select;

export default function ResultDetail({
    mode = 'bill',
    bill_id,
    preview,
    groupPointer,
    match,
    allowUnauthorise,
    extraInfo, // To manage extra info component
    modeValue, // To mension mode for script execution
    title, // To mension title of extra info
    icon, // To mension icon
    ...props
}) {
    const [selectedreport, setSelectedreport] = useState({});

    const [btnloading, setBtnloading] = useState(false);

    const [loading, setLoading] = useState(true);

    const [resultloading, setResultloading] = useState(false);

    const [labReportResult, setLabReportResult] = useState({ test: {}, result: {}, entries: [], index: null });

    const [selected, setSelected] = useState({});

    const [chiefTechnician, setCheifTechnician] = useState('');

    const [technician, setTechnician] = useState('');

    var urlParams = Location.search();

    const { user, isMobile, index } = useContext(GlobalContext);

    //Patients list array
    const [patient, setPatient] = useState({});

    //Index of each test
    const [testIndex, setTestIndex] = useState(0);

    const [form] = Form.useForm();

    var [byteArray, setByteArray] = useState([]);

    const [reportModalVisible, setReportModalVisible] = useState(false);

    const [report, setReport] = useState(true);

    const [remarks, setRemarks] = useState('');

    const [alert, setAlert] = useState(false);

    var [labresult, setLabResult] = useState([]);

    const [disabledfield, setDisabledfield] = useState(false);

    const [disabled, setDisabled] = useState(true);

    // Modal to confirm submission if the entered result is out of bound
    const [warningModalVisible, setWarningModalVisible] = useState(false);

    const [verified, setVerified] = useState(false);

    // state to manage eGFR value
    const [eGFRValue, seteGFRValue] = useState();

    // state to manage Creatinine values
    const [itemValue, setItemValues] = useState();

    // state to manage item values
    const [item, setItem] = useState();

    const { t, i18n } = useTranslation(); // To Translate to another language

    // state to manage modal visibility
    const [resultLogView, setResultLogView] = useState(false);

    var billId;

    // If mode visit,we pass visit id as props
    if (mode === 'visit') {
        billId = bill_id;
    } else {
        var { id } = match.params;

        billId = id;
    }

    // color indications  after saving and authorized result
    useEffect(async () => {
        // Set initial values
        const initialValues = {};
        await labReportResult.entries.forEach((item, index) => {
            const resultFieldName = `lbtrsd_result${item.testKey}_${index}`;
            initialValues[resultFieldName] = item.lbtrsd_result;
        });
        form.setFieldsValue(initialValues);
        form.validateFields()
            .then(() => {
                console.log('Initial validation complete');
            })
            .catch((err) => {
                console.log('Initial validation failed', err);
            });
    }, [form, labReportResult]);

    useEffect(() => {
        getPatientDetails(urlParams, testIndex);
    }, []);

    useEffect(() => {
        formatResult(itemValue);
    }, [itemValue]);

    // for updating value of eGFR
    useEffect(() => {
        formatResult(eGFRValue, labReportResult.entries[3]);
    }, [eGFRValue]);

    /**
     * @param {*} value
     * For storing eGFR value
     */
    const onUpdate = (value) => {
        // The following line is commented out to address the Prod issue.
        // It seems that setting item values to 0 was causing the eGFR value to be missed.
        // setItemValues(0);
        seteGFRValue(value);
    };

    /**
     * @param {*} value
     * Format result entered accordng to lbtr_resformat
     */

    async function formatResult(value, item, index, edit, verified) {
        setItem(item);
        var formatFlag = true;
        var name;

        // When editing result field name will change
        if (edit === true) {
            name = 'lbtrsd_result';
        } else {
            name = 'result';
        }
        //To get validity status of form item
        await form.getFieldsError().some((i) => {
            // Check if the user  verified flag is true
            if (verified) {
                // Set the formatFlag to true if the verified flag is true then allow format result
                formatFlag = true;
            } else {
                // If verified is false, check the condition on the name and errors properties Not allow to format values
                if (i.name[0] === name + '_' + item.testKey + '_' + index && i.errors[0] != undefined) {
                    // Set the formatFlag to false if the condition is met
                    formatFlag = false;
                }
            }
        });

        var formattedValue;

        // Formatting of value is done only if formatFlag is true,
        // If the field validity status is in error or warning ,formatting of value is not done, formatflag will be false.
        if (formatFlag) {
            var format = item.lbtr_resformat;

            // If there is no format given for the item, then value entered is taken as it is.
            if (format === '') {
                formattedValue = value;
            } else if (format.includes('.')) {
                // Format value according to res_format
                // Different scenarios considered here are:
                // - Case when format contains . (eg: #.0#)
                // - Case when format contains , (eg: #,##,###)
                // - Case when format does not contain . or , (eg: #0)

                //  Get string before and after decimal point in format
                var arr = format.split('.');

                //  string after decimal in format
                var decimalPart = arr[1];

                if (value.length) {
                    // Here we consider different scenarios.

                    // Once the value is entered, we check if decimal part of format has 0 in it.

                    // If only decimal part is entered as value (eg: .8)
                    // Under this case again different scenarious are considered.
                    // - When value entered decimal part length is greater than decimal part of format. (We roundoff value according to length of decimal part in format)
                    // - When entered value has same number of decimal part or lesser it is kept as it is.

                    // If a decimal number containing both whole number part and decimal part is entered, then we check length of decimal part and roundoff accordingly.

                    // If nothing is entered after decimal point in value  or if 0 is entered.(eg: 11. or 11.0 then only 11 will be the formatted value)

                    if (!decimalPart.includes('0')) {
                        // Get only wholenumber
                        var valueSplitted = value.split('.');

                        // If whole number part is not entered
                        if (valueSplitted[0] === '') {
                            // Decimal part should include same number of characters as in format
                            if (valueSplitted[1] && valueSplitted[1].length > decimalPart.length) {
                                formattedValue = `0${parseFloat(value).toFixed(decimalPart.length)}`;
                            } else {
                                // When decimal part in value is less than or equal to decimal part in format
                                formattedValue = `0${value}`;
                            }
                        } else if (valueSplitted[1] && valueSplitted[1].length > decimalPart.length) {
                            // Case when decimal number is entered as value
                            formattedValue = parseFloat(value).toFixed(decimalPart.length);
                        } else if ((value.includes('.') && valueSplitted[1].length === 0) || /^[0]*$/.test(valueSplitted[1]) === true) {
                            // If decimal is given but no digit entered in decimal part(case where decimall part format includes only #)
                            formattedValue = valueSplitted[0];
                        } else {
                            // If just a number is entered without decimal then the value is considered as it is.
                            formattedValue = value;
                        }
                    } else {
                        // Case when decimal part of format contains 0 in it.
                        // If a number without decimal is entered then it is converted to decimal number, that has same length of decimal part as in format
                        // (eg : if decimal part length of format is 2 and value entered is 1, then it is converted into 1.00 )
                        // If a decimal number is entered then round off is done according to decimal part length in format

                        // Convert into integer
                        if (!value.includes('.')) {
                            value = parseInt(value);
                        } else {
                            value = parseFloat(value);
                        }

                        //To show as decimal
                        formattedValue = value.toFixed(decimalPart.length);
                    }
                } else {
                    // If no value is entered , then 0 will be the value
                    formattedValue = 0;
                }
            } else if (format.includes(',')) {
                //Case when format includes ','

                // If no value entered then 0 will be the value
                // When value entered contain '.' then values after decimal will not be considered and number before decimal is convereted  into comma format.
                // When normal number is entered without . or , it is converted to comma format

                if (!value.length) {
                    formattedValue = 0;
                } else if (value.includes('.')) {
                    // If format contains , and if '.' is entered , then values after decimal will not be considered
                    value = value.substring(0, value.indexOf('.'));

                    if (value.length) {
                        value = parseInt(value);

                        formattedValue = value.toLocaleString('en-IN');
                    } else {
                        formattedValue = 0;
                    }
                } else {
                    if (value.includes(',')) {
                        // If value entered includes , then
                        // Remove , from value
                        // And then format
                        value = value.replace(/\,/g, '');

                        value = parseInt(value);
                    } else {
                        value = parseInt(value);
                    }

                    formattedValue = value.toLocaleString('en-IN');
                }
            } else if (!format.includes(',') && !format.includes('.')) {
                // Case when format does not include . or ,

                // If no value entered then 0 will be the value
                // If decimal number is entered then formatted value will be only whole number part of the number.
                // If value entered includes , then comma is removed from value.
                // If number starts with 0 then first character is  removed

                if (value.length) {
                    if (value.includes('.')) {
                        // Show only wholenumber
                        // value = value.split('.');
                        formattedValue = Math.round(value);

                        //if whole number includes zero in first index,then remove 0
                        // if (value[0].charAt(0) === '0') {
                        //     formattedValue = value[0].slice(1);
                        // } else {
                        //     formattedValue = value[0];
                        // }
                    } else if (value.includes(',')) {
                        // Remove , from value and display
                        value = value.replace(/\,/g, '');

                        //if  number includes zero in first index,then remove 0
                        if (value.charAt(0) === '0') {
                            formattedValue = value.slice(1);
                        } else {
                            formattedValue = value;
                        }
                    } else if (value === '') {
                        // If nothing entered then value would be zero
                        formattedValue = 0;
                    } else if (value.charAt(0) === '0' && value.length != 1) {
                        // if starts with 0 then remove 0
                        value = value.slice(1);

                        formattedValue = value;
                    } else {
                        //same value
                        formattedValue = value;
                    }
                } else {
                    formattedValue = 0;
                }
            }

            // Number has to be converted to string since lbtrsd_result column type is string. Api will fail otherwise.
            formattedValue = formattedValue.toString();

            //To save the formatted result
            labReportResult.entries.forEach((record, index) => {
                if (record.lbtr_id === item.lbtr_id) {
                    // Update the data to save the Results
                    record.values = {
                        result: formattedValue,
                        status: t('Pending for Submission'),
                        // status: selectedreport.lbtr_id && !selectedreport.lbtrsd_id?'Pending for Submission':'Pending for Updation',
                    };
                }
            });

            //We include testindex and specimen index along with name
            form.setFieldsValue({ [name + '_' + item.testKey + '_' + index]: formattedValue });

            // Result is updated with formatted value
            await updateResult(formattedValue, item);
        } else {
            await updateResult(value, item);
        }

        setDisabled(true);
    }

    /**
     * @param {*} value
     *view Result data
     */

    async function viewResultLog() {
        setResultLogView(true);
    }

    // Columns shown before saving any result
    const reportColumns = [
        {
            title: '#',
            width: '10px',
            key: 'lbtr_particulrs',
            render: (value, item, index) => index + 1,
        },

        {
            title: t('Description'),
            width: '25%',
            dataIndex: 'lbtr_particulrs',
            key: 'lbtr_particulrs',
        },

        {
            title: t('Result'),
            width: '50px',
            render: (value, item, index) => {
                var resultFieldName = `result_${item.testKey}_${index}`;

                var warning;

                // Rules given when lbtr_vtype is numeric is given here, as for each format rule will change
                var rules = [
                    {
                        max: 50,
                        message: t('Length cannot exceed 50 characters !'),
                    },

                    {
                        // async validator to show red alert
                        validator(_, value) {
                            /*if (((item.lbtr_lbound || item.lbtr_ubound) != 0 && value >= item.lbtr_ubound) || value <= item.lbtr_lbound) {
                                return Promise.reject('Result out of bound');
                            } else if(((item.lbtr_lbound || item.lbtr_ubound) == 0) || value >= item.lbtr_ubound && value <= item.lbtr_lbound) {

                                return Promise.reject('Result out  of bound');

                            }*/

                            if (
                                ((item.lbtr_lbound && item.lbtr_ubound) == 0 && (value > item.lbtr_refmax || value < item.lbtr_refmin)) ||
                                ((item.lbtr_lbound && item.lbtr_ubound) != 0 && value > item.lbtr_ubound) ||
                                value < item.lbtr_lbound
                            ) {
                                // setWarningAlert(true);
                                return Promise.reject('Result out of bound');
                            } else {
                                return Promise.resolve();
                            }
                        },
                        // warningOnly: true,
                    },
                    {
                        // async validator to show yellow warning
                        validator(_, value) {
                            if (value) {
                                // console.log("QQQQQQQ",item.lbtr_lbound,item.lbtr_refmin,item.lbtr_refmax,item.lbtr_ubound)
                                if (
                                    (item.lbtr_lbound && item.lbtr_ubound) != 0 &&
                                    ((value >= item.lbtr_lbound && value < item.lbtr_refmin) ||
                                        (value <= item.lbtr_ubound && value > item.lbtr_refmax))
                                ) {
                                    warning = true;
                                    // setWarningAlert(false);
                                    return Promise.reject('Result out of range');
                                } else {
                                    warning = false;
                                    return Promise.resolve();
                                }
                            }
                        },
                        warningOnly: { warning },
                    },
                ];

                // , is allowed only if resformat includes ,
                if (item.lbtr_resformat && item.lbtr_resformat.includes(',')) {
                    rules.push({
                        pattern: /^[0-9.,]*$/g,
                        message: t('Please Enter Valid Result!'),
                    });
                } else {
                    rules.push({
                        pattern: /^[0-9.]*$/g,
                        message: t('Please Enter Valid Result!'),
                    });
                }

                if (item.lbtr_vtype === 'Text') {
                    return (
                        <Form form={form}>
                            <Form.Item
                                name={resultFieldName}
                                rules={[
                                    {
                                        max: 50,
                                        message: t('Length cannot exceed 50 characters !'),
                                    },
                                    // {
                                    //     pattern: /^[a-zA-Z\s]*$/g,
                                    //     message: 'Please Enter Valid Result!',
                                    // },
                                ]}
                                hasFeedback
                            >
                                <Input
                                    autoComplete="off"
                                    onChange={(event) => {
                                        updateResult(event.target.value, item);
                                    }}
                                    // In emr screen result entry disabled
                                    disabled={mode === 'visit'}
                                ></Input>
                            </Form.Item>
                        </Form>
                    );
                } else if (item.lbtr_vtype === 'Numeric') {
                    if (item.lbtr_particulrs == 'eGFR') {
                        form.setFieldsValue({ [resultFieldName]: eGFRValue });
                        return (
                            <>
                                <Form form={form}>
                                    <Form.Item name={resultFieldName} rules={rules} hasFeedback>
                                        <Input
                                            style={{ minWidth: '150px' }}
                                            autoComplete="off"
                                            // In emr screen result entry disabled
                                            disabled={mode === 'visit'}
                                            onBlur={(e) => formatResult(e.target.value, item, index, seteGFRValue(e.target.value))}
                                        />
                                    </Form.Item>
                                </Form>
                                <p>
                                    {' '}
                                    {item.lbtr_resformat ? <p style={{ color: 'gray', fontSize: '12px' }}>(format:{item.lbtr_resformat})</p> : null}
                                </p>
                            </>
                        );
                    } else if (item.lbtr_particulrs == 'Creatinine') {
                        return (
                            <>
                                <Form form={form}>
                                    <Form.Item name={resultFieldName} rules={rules} hasFeedback>
                                        <Input
                                            style={{ minWidth: '150px' }}
                                            autoComplete="off"
                                            // In emr screen result entry disabled
                                            disabled={mode === 'visit'}
                                            onBlur={(e) => formatResult(e.target.value, item, index, setItemValues(e.target.value))}
                                        />
                                    </Form.Item>
                                </Form>
                                <p>
                                    {' '}
                                    {item.lbtr_resformat ? <p style={{ color: 'gray', fontSize: '12px' }}>(format:{item.lbtr_resformat})</p> : null}
                                </p>
                            </>
                        );
                    } else {
                        return (
                            <>
                                <Form form={form}>
                                    <Form.Item name={resultFieldName} rules={rules} hasFeedback>
                                        <Input
                                            style={{ minWidth: '150px' }}
                                            autoComplete="off"
                                            // In emr screen result entry disabled
                                            disabled={mode === 'visit'}
                                            onBlur={(e) => formatResult(e.target.value, item, index)}
                                        />
                                    </Form.Item>
                                </Form>
                                <p>
                                    {' '}
                                    {item.lbtr_resformat ? <p style={{ color: 'gray', fontSize: '12px' }}>(format:{item.lbtr_resformat})</p> : null}
                                </p>
                            </>
                        );
                    }
                } else if (item.lbtr_vtype === 'Select') {
                    return (
                        <Form.Item name={resultFieldName} rules={[{ required: true, message: t('Please select result') }]}>
                            <Select
                                //defaultValue={item.lbtr_otherdetails1.lab_result_parameters.value[0]}
                                onSelect={(event) => {
                                    updateResult(event, item);
                                }}
                                // In emr screen result entry disabled
                                disabled={mode === 'visit'}
                            >
                                {item.lbtr_otherdetails1.lab_result_parameters.option.map((otherDetail) => {
                                    return <Option value={otherDetail.value}>{otherDetail.label}</Option>;
                                })}
                            </Select>
                        </Form.Item>
                    );
                }
            },
        },

        // {
        //     title: 'Value',
        //     width:'25%',
        //     render: (item, record) => {
        //         return <>{item.values && item.values.result}</>;
        //     },
        //     key: 'lbtrsd_result',
        // },

        {
            width: '100px',
            title: t('Range'),
            // dataIndex: 'lbtr_refrange',
            key: 'lbtr_refrange',
            render: (record) => {
                return <p style={{ whiteSpace: 'pre-line', fontSize: '10px' }}>{record.lbtr_refrange}</p>;
            },
        },

        {
            title: t('Unit'),
            dataIndex: 'lbtr_unit',
            key: 'lbtr_unit',
            width: '20px',
        },
    ];

    // Columns shown after saving result
    const resultColumns = [
        {
            title: '#',
            key: 'lbtr_particulrs',
            render: (value, item, index) => index + 1,
        },

        {
            title: t('Description'),
            width: '25%',
            dataIndex: 'lbtrsd_particulrs',
            key: 'lbtrsd_particulrs',
        },

        {
            width: '25%',
            title: t('Value'),
            render: (record, item, index) => {
                var resultFieldName = `lbtrsd_result_${item.testKey}_${index}`;
                var name = 'lbtrsd_result';
                var initialValues;

                //We need different name for each field
                initialValues = {
                    [name + '_' + item.testKey + '_' + index]: item.lbtrsd_result,
                };

                var warning;
                var rules = [
                    {
                        max: 50,
                        message: t('Length cannot exceed 50 characters !'),
                    },

                    {
                        // async validator to show red alert
                        validator(_, value) {
                            if (((item.lbtr_lbound || item.lbtr_ubound) != 0 && value > item.lbtr_ubound) || value < item.lbtr_lbound) {
                                // setWarningAlert(true);
                                form.setFieldsValue({ resultFieldName: null });
                                return Promise.reject('Result out of bound');
                            } else {
                                return Promise.resolve();
                            }
                        },
                        // warningOnly: true,
                    },
                    {
                        // async validator to show yellow warning
                        validator(_, value) {
                            if (value) {
                                if (
                                    (value >= item.lbtr_lbound && value < item.lbtr_refmin) ||
                                    (value <= item.lbtr_ubound && value > item.lbtr_refmax)
                                ) {
                                    warning = true;
                                    // setWarningAlert(false);
                                    return Promise.reject('Result out of range');
                                } else {
                                    warning = false;
                                    return Promise.resolve();
                                }
                            }
                        },
                        warningOnly: { warning },
                    },
                ];

                // show resultfield
                if (item.lbtr_particulrs == 'eGFR' && eGFRValue) {
                    form.setFieldsValue({ [resultFieldName]: eGFRValue });
                }
                // Rules given when lbtr_vtype is numeric is given here, as for each format rule will change

                // , is allowed only if resformat includes ,
                if (item.lbtr_resformat && item.lbtr_resformat.includes(',')) {
                    rules.push({
                        pattern: /^[0-9.,]*$/g,
                        message: t('Please Enter Valid Result!'),
                    });
                } else {
                    rules.push({
                        pattern: /^[0-9.]*$/g,
                        message: t('Please Enter Valid Result!'),
                    });
                }

                // Set the validateStatus based on the initial values of the form fields. This ensures that the form fields display the correct validation status when the form is first rendered.

                const validateStatus = (value) => {
                    if (value) {
                        if ((value >= item.lbtr_lbound && value <= item.lbtr_ubound) &&
                            (value < item.lbtr_refmin || value > item.lbtr_refmax)) {
                            return 'warning';
                        } else if (value > item.lbtr_ubound || value < item.lbtr_lbound) {
                            return 'error';
                        } else {
                            return 'success';
                        }
                    }
                    return undefined;
                };
    
                const initialValidationStatus = validateStatus(initialValues[resultFieldName]);
    

                return (
                    <>
                        <Form initialValues={initialValues} form={form}>
                            {/**Case when dropdown is needed.To select postive or negative */}
                            {item.lbtr_vtype === 'Select' ? (
                                <Form.Item name={resultFieldName} rules={[{ required: true, message: t('Please select result') }]}
                                validateStatus={initialValidationStatus}>
                                    <Select
                                        // defaultValue={item.lbtr_otherdetails1.lab_result_parameters.option[0].value[0]}
                                        onSelect={(event) => {
                                            updateResult(event, item);
                                        }}
                                        // In emr screen result entry disabled
                                        disabled={disabledfield || mode === 'visit'}
                                    >
                                        {item.lbtr_otherdetails1.lab_result_parameters.option.map((otherDetail) => {
                                            return <Option value={otherDetail.value}>{otherDetail.label}</Option>;
                                        })}
                                    </Select>
                                </Form.Item>
                            ) : item.lbtr_vtype === 'Numeric' && item.lbtr_particulrs == 'eGFR' ? (
                                <>
                                    <Form.Item
                                        // validateTrigger="onBlur"

                                        // validateFirst="parallel"
                                        // validateStatus="warning"
                                        name={resultFieldName}
                                        rules={rules}
                                        hasFeedback
                                        validateStatus={initialValidationStatus}
                                    >
                                        <Input
                                            style={{ minWidth: '150px' }}
                                            autoComplete="off"
                                            disabled={disabledfield || mode === 'visit'}
                                            onBlur={(e) => formatResult(e.target.value, item, index, true, seteGFRValue(e.target.value))}
                                        ></Input>
                                    </Form.Item>

                                    <p>
                                        {item.lbtr_resformat ? (
                                            <p style={{ color: 'gray', fontSize: '12px' }}>(format:{item.lbtr_resformat})</p>
                                        ) : null}
                                    </p>
                                </>
                            ) : item.lbtr_vtype === 'Numeric' && item.lbtr_particulrs == 'Creatinine' ? (
                                <>
                                    <Form.Item
                                        // validateTrigger="onBlur"

                                        // validateFirst="parallel"
                                        // validateStatus="warning"
                                        name={resultFieldName}
                                        rules={rules}
                                        hasFeedback
                                        validateStatus={initialValidationStatus}>
                                        <Input
                                            style={{ minWidth: '150px' }}
                                            autoComplete="off"
                                            disabled={disabledfield || mode === 'visit'}
                                            onBlur={(e) => formatResult(e.target.value, item, index, true, setItemValues(e.target.value))}
                                        ></Input>
                                    </Form.Item>

                                    <p>
                                        {item.lbtr_resformat ? (
                                            <p style={{ color: 'gray', fontSize: '12px' }}>(format:{item.lbtr_resformat})</p>
                                        ) : null}
                                    </p>
                                </>
                            ) : item.lbtr_vtype === 'Numeric' ? (
                                <>
                                    <Form.Item name={resultFieldName} rules={rules} hasFeedback 
                                     
                                      validateStatus={initialValidationStatus}>
                                        <Input
                                            style={{ minWidth: '150px' }}
                                            autoComplete="off"
                                            disabled={disabledfield || mode === 'visit'}
                                            onBlur={(e) => formatResult(e.target.value, item, index, true)}
                                        ></Input>
                                    </Form.Item>

                                    <p>
                                        {item.lbtr_resformat ? (
                                            <p style={{ color: 'gray', fontSize: '12px' }}>(format:{item.lbtr_resformat})</p>
                                        ) : null}
                                    </p>
                                </>
                            ) : item.lbtr_vtype === 'Text' ? (
                                <Form.Item
                                    name={resultFieldName}
                                    // rules={rules.push(
                                    //     {
                                    //         max: 50,
                                    //         message: 'Length cannot exceed 50 characters !',
                                    //     },
                                    //     {
                                    //         pattern: /^[a-zA-Z\s]*$/g,
                                    //         message: 'Please Enter Valid Result!',
                                    //     }
                                    // )}
                                    rules={[
                                        {
                                            max: 50,
                                            message: t('Length cannot exceed 50 characters !'),
                                        },
                                        // {
                                        //     pattern: /^[a-zA-Z\s]*$/g,
                                        //     message: 'Please Enter Valid Result!',
                                        // },
                                    ]}
                                    hasFeedback
                                    validateStatus={initialValidationStatus}>
                                
                                    <Input
                                        autoComplete="off"
                                        disabled={disabledfield || mode === 'visit'}
                                        onChange={(event) => {
                                            updateResult(event.target.value, item);
                                        }}
                                    ></Input>
                                </Form.Item>
                            ) : null}
                        </Form>
                    </>
                );
                // }
            },
            //key: 'lbtrsd_result',
        },

        {
            width: '100px',
            title: t('Range'),
            // dataIndex: 'lbtrsd_refrange',
            key: 'lbtrsd_refrange',
            render: (record) => {
                return <p style={{ whiteSpace: 'pre-line' }}>{record.lbtr_refrange}</p>;
            },
        },

        {
            title: t('Unit'),
            dataIndex: 'lbtrsd_unit',
            key: 'lbtrsd_unit',
        },
    ];

    // Add column to show authorised or unauthorised if mode is visit
    if (mode === 'visit') {
        resultColumns.push({
            fixed: 'right',
            render: (item) => {
                if (item.lbtrsd_isclosed === 'N') {
                    return <Tag color="orange"> {t('Not authorised')}</Tag>;
                } else {
                    return <Tag color="orange"> {t('Authorised')}</Tag>;
                }
            },
        });
    }

    var columns;

    if (labReportResult.entries.length && labReportResult.entries[0].lbtrsd_id) {
        columns = resultColumns;
    } else {
        columns = reportColumns;
    }

    /**
     *
     * Patient Data
     *
     * @param {*} urlParms
     */
    async function getPatientDetails(urlParms, index) {
        setLoading(true);

        Samples.getLabDetail(billId, mode).then((result) => {
            // We get opbilld and opbill in result
            setPatient(result);
            // Load selected test details
            loadTestDatawithIndex({ index, result });

            setLoading(false);
        });
    }

    /**
     * Get the Lab Report and Lab result if present
     */
    function getLabReportResult(opbilld, status, key) {
        Samples.getLabReportResult(opbilld.labTest.lbt_itemptr, opbilld.opbd_hdrid).then((result) => {
            //To add status with each parameters
            result.result.parameters = result.result.parameters.map((ele) => {
                // This condition is include due to prod issue , That  eGFR value is not showing in initial time

                if (ele.lbtr_particulrs === 'Creatinine') {
                    setItemValues(ele.lbtrsd_result);
                }
                return {
                    ...ele,
                    status: status,
                    testKey: key || 0,
                };
            });

            setLabReportResult({ test: result.result.labTest, result: result.result.labTestResult, entries: result.result.parameters });

            if (result.result.labTestResult) {
                {
                    /** Set  values for Technican and remarks  */
                }
                setRemarks(result.result.labTestResult.lbtrs_remarks);

                setTechnician(result.result.labTestResult.lbtrs_technician);

                setCheifTechnician(result.result.labTestResult.lbtrs_chieftechnician);
            }

            // Checking if the lab test result is authorized or not
            if (result.result.labTestResult && result.result.labTestResult.lbtrs_isclosed === 'Y') {
                //// Disable form fields
                setDisabledfield(true);
                //save  button disabled when lbtrs_isclosed is 'Y'
                setDisabled(false);
            } else {
                //save  button enabled otherwise
                setDisabled(true);
                // Enable  form fields
                setDisabledfield(false);
            }
            // setTest(result.result.labTest);

            // setResult(result.result.labTestResult);

            // setLabentries({ entries: result.result.parameters });

            setResultloading(false);
        });
    }

    /**
     * load the lab test data
     */
    function loadTestData(test, key) {
        // Set result loading
        setResultloading(true);

        var status = test.status;

        if (test && test.labTest) {
            // get lab test
            getLabReportResult(test, status, key);
        } else {
            setResultloading(false);

            message.error('Laboratory Test is missing');
        }
    }

    function refresh() {
        var urlParams = Location.search();

        getPatientDetails(urlParams, testIndex);
    }

    /**
     * Update the values for the selected test
     */
    function updateResult(test, values) {
        // update value when eGFR value
        if (values.lbtr_particulrs == 'eGFR') {
            if (eGFRValue) {
                test = eGFRValue;
            }
        }

        var arr = [];
        setSelectedreport(values);
        // Pushing values from table
        arr.push({ test: test, id: values.lbtr_id });

        // This condition is used to disable Submit Button
        if (arr.length > 0) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }

        if (selectedreport.lbtr_id || values.lbtr_id) {
            var rowIndex = null;

            labReportResult.entries.forEach((record, index) => {
                // Only when lbtr_id of selected record is same as lbtr_id the result we entered is saved for particular parameter
                if (record.lbtr_id === values.lbtr_id) {
                    rowIndex = index;

                    arr[rowIndex] = test;

                    // Update the data to save the Results
                    record.values = {
                        result: test,
                        status: t('Pending for Submission'),
                    };
                }
            });

            console.log({ ...labReportResult, entries: labReportResult.entries, index });

            setLabReportResult({ ...labReportResult, entries: labReportResult.entries, index });
        } else {
            var rowIndex = null;

            labReportResult.entries.forEach((record, index) => {
                if (record.lbtr_id === values.lbtr_id) {
                    rowIndex = index;

                    labReportResult.entries[index].values = {
                        result: test,
                        status: 'Result Updated',
                    };
                }
            });

            //
            console.log({ ...labReportResult, entries: labReportResult.entries, index });

            setLabReportResult({ ...labReportResult, entries: labReportResult.entries, index });
        }
    }
    /**
     * Submits the lab result form.
     *
     * @param {boolean} verified - Indicates whether the form has been verified.
     *
     * The function performs the following actions:
     * 1. If the form is verified:
     *    - Validates the form fields and resets invalid fields.
     *    - Ensures both technician and chief technician are selected.
     *    - Constructs form data and sends an API request to create or update the lab result.
     *    - Updates the state and displays appropriate messages based on success or failure.
     *    - Loads the next test data if available.
     * 2. If the form is not verified:
     *    - Checks for warnings in the form fields.
     *    - Displays a warning modal if any warnings are found.
     *    - If no warnings, sets the form as verified and recursively calls `saveLabResult` with `verified` set to `true`.
     *
     * @returns {void}
     */
    async function saveLabResult(verified) {
        // If the form is verified:
        if (verified === true) {
            setBtnloading(true); // Show loading indicator on the button

            let validFlag = true;

            // Check for form field errors
            // This line is commented out due to a production issue to solved a formatting issue.
            // await form.getFieldsError().map((i) => {
            //     if (i.errors[0] !== undefined) {
            //         let fieldName = { x: i.name[0] }; // Extract the field name
            //         let field = [fieldName.x]; // Prepare the field to be set
            //         form.setFieldsValue({ [field]: null }); // Set the field value to null
            //         validFlag = false; // Mark the form as invalid
            //     }
            // });

            await labReportResult.entries.map(async (entry, index) => {
                // Initialize variables to control editing and format verification
                let edit = false;
                let resultFieldName;

                // Determine if this is an edit case or create case based on the presence of lbtrsd_id
                if (entry.lbtrsd_id) {
                    edit = true;
                    resultFieldName = `lbtrsd_result_${entry.testKey}_${index}`;
                } else {
                    resultFieldName = `result_${entry.testKey}_${index}`;
                }
                
                // Check if the entry's type is not 'Select'
                if (entry.lbtr_vtype !== 'Select') {
                    if (entry.values) {
                        // If values exist, format the result using the formatResult function
                        await formatResult(entry.values.result, entry, index, edit, verified);
                    } else {
                        // Get the field value from the form using the dynamically constructed field name
                        // let resultFieldName = `result_${entry.testKey}_${index}`;
                        let fieldValue = form.getFieldValue(resultFieldName);
                        // If the field value exists, assign it to entry.values
                        if (fieldValue) {
                            entry.values = {
                                result: fieldValue,
                            };
                        }
                        await formatResult(fieldValue, entry, index, edit, verified);
                    }
                }
            });

            // Ensure technician and chief technician are selected
            if (technician && chiefTechnician) {
                // This line is commented out due to a production issue to solved a formatting issue.
                // if (validFlag === false) {
                //     message.error('Enter Valid Result'); // Show error if form is invalid
                // } else {
                // const formValues = form.getFieldValue(); // Get all form values
                // Construct the form body for the API call
                let formBody = {
                    bill_id: patient.opbill[0].opb_id,
                    chief_technician: chiefTechnician,
                    technician: technician,
                    remarks: remarks,
                    item_pointer: selectedreport.lbtr_itemptr|| selectedreport.lbt_itemptr, //there is an issue with the itempointer being incorrect in prod.,
                    resultdetails: labReportResult.entries
                        // .filter((val) => val.values)
                        .map((entry) => ({
                            id: entry.lbtrsd_id ? entry.lbtrsd_id : null,
                            perticular_id: entry.lbtr_id,
                            // lbtrsd_result: entry.values && entry.values.result ? entry.values.result : '',
                            result:  entry.values && entry.values.result ? entry.values.result : '',
                            // lbtrsd_codeptr: entry.lbtr_code,
                            code_ptr: entry.lbtr_code,
                        })),
                };

                // If the lab result already exists, add its ID to the form body
                if (labReportResult.result && labReportResult.result.lbtrs_id) {
                    formBody = {
                        ...formBody,
                        id: labReportResult.result.lbtrs_id,
                    };
                }


                try {
                    // Make API call to create or update lab result
                    const result = await ResultEntry.saveLabResult(formBody);
                   if(result.success){
                    //setLabResult(result); // Update the state with the new result
                    message.success(result.message); // Show success message

                    let index;
                    // Determine the next test index
                    // After saving result of each test next test is sekected by default
                    if (testIndex + 1 !== opbilld.length) {
                        index = testIndex + 1;
                    } else {
                        // If last test result is saved then firt result is automatically selected
                        index = 0;
                    }

                    setVerified(false); // Reset verified status
                    loadTestDatawithIndex({ index }); // Load the next test data
                    setReport(true); // Show the report
                }else{
                    message.error(result.message)
                }
                } catch (error) {
                    message.error('Operation failed'); // Show error message if API call fails
                }
                // }
            } else {
                message.warning('Please Enter Technician'); // Show warning if technician or chief technician is not selected
            }

            setBtnloading(false); // Hide loading indicator on the button
            setLoading(false); // Hide overall loading indicator
        } else {
            const arr = [];
            // Check for warnings in the form fields
            // If there is any warning in  the form, we push a warning string to an array. This is to find if there are any warning
            // If there are no waring, we set proceed with saving the changes
            // Else we will have to confirm from the user that they want to continue
            await form.getFieldsError().map((i) => {
                if (i.warnings[0] !== undefined) {
                    arr.push('warning'); // Add warning to array if present
                } else if (i.errors[0] != undefined) {
                    arr.push('error');
                } else {
                    arr.push('valid'); // Mark as valid if no warnings
                }
            });

            if (arr.indexOf('warning') !== -1 || arr.indexOf('error') !== -1) {
                setWarningModalVisible(true); // Show warning modal if there are warnings
            } else {
                setVerified(true); // Set the form as verified
                saveLabResult(true); // Recursively call saveLabResult with verified set to true
            }
        }
    }
    /**
     * Once the results are entered , we submit to update on db
     */
    async function submitChanges(verified) {
        // If verified is true, continue on with saving the changes
        // Else check if there are any wrning in the form, If yes confirm if the use wants to continue or not
        if (verified === true) {
            setBtnloading(true);

            var validFlag = true;
            var field;
            // This line is commented out due to a production issue to solved a formatting issue.
            //To get validity status of form
            // await form.getFieldsError().map((i) => {
            //     if (i.errors[0] != undefined) {
            //         let fieldName = { x: i.name[0] };
            //         let field = [fieldName.x];
            //         form.setFieldsValue({ [field]: null });
            //         validFlag = false;
            //     }
            // });

            await labReportResult.entries.map(async (entry, index) => {
                // Initialize variables to control editing and format verification
                let edit = false;
                // // let formatVerified = true;
                // await formatResult(entry.values.result, entry, index, edit, verified);

                if (entry.values) {
                    // If values exist, format the result using the formatResult function
                    await formatResult(entry.values.result, entry, index, edit, verified);
                } else {
                    // Get the field value from the form using the dynamically constructed field name
                    let resultFieldName = `result_${entry.testKey}_${index}`;
                    let fieldValue = form.getFieldValue(resultFieldName);
                    // If the field value exists, assign it to entry.values
                    if (fieldValue) {
                        entry.values = {
                            result: fieldValue,
                        };
                    }
                    await formatResult(fieldValue, entry, index, edit, verified);
                }
            });

            // Check if technicain and chief technician is selected
            if (technician && chiefTechnician) {
                // Shows error if result is not valid
                // if(){
                //     message.error('Enter Valid Result');
                // }

                // This line is commented out due to a production issue to solved a formatting issue.
                // if (validFlag === false) {
                //     message.error('Enter Valid Result');
                // } else {
                await ResultEntry.saveResults({ patient, selectedreport, labReportResult, user, technician, chiefTechnician, remarks })
                    .then(async (result) => {
                        setLabResult(result);

                        message.success('Your entries has been recorded');

                        var index;

                        // After saving result of each test next test is sekected by default
                        if (testIndex + 1 != opbilld.length) {
                            index = testIndex + 1;
                        } else {
                            // If last test result is saved then firt result is automatically selected
                            index = 0;
                        }

                        setVerified(false);
                        // set await for this function based on prod issue  to slove multiple data creation in result entry
                        await loadTestDatawithIndex({ index });
                        //   getPatientDetails(urlParams, index);

                        // setDisabled(false);

                        setReport(true);
                    })
                    .catch((error) => {
                        message.error('Operation failed');
                    });
                // }
            } else {
                message.warning('Please Enter Technician ');
            }

            setBtnloading(false);

            setLoading(false);
        } else {
            const arr = [];
            // If there is any warning in  the form, we push a warning string to an array. This is to find if there are any warning
            // If there are no waring, we set proceed with saving the changes
            // Else we will have to confirm from the user that they want to continue
            await form.getFieldsError().map((i) => {
                if (i.warnings[0] != undefined) {
                    arr.push('warning');
                } else if (i.errors[0] != undefined) {
                    arr.push('error');
                } else {
                    arr.push('valid');
                }
            });

            if (arr.indexOf('warning') !== -1 || arr.indexOf('error') !== -1) {
                setWarningModalVisible(true);
            } else {
                setVerified(true);
                saveLabResult(true);
            }
        }
    }
    /**
     * Once the results are entered , we submit to update on db
     */
    async function updateChanges(verified) {
        // If verified is true, continue on with saving the changes
        // Else check if there are any warning in the form, If yes confirm if the use wants to continue or not
        if (verified === true) {
            setBtnloading(true);
            var formValues = form.getFieldValue();

            // Iterate over each entry in the labReportResult.entries array to formatting the result
            await labReportResult.entries.map(async (entry, index) => {
                // Initialize variables to control editing and format verification
                let edit = true;
                // Call the formatResult function with the current entry's result values,
                // the entire entry, the current index, and the control variables
                // await formatResult(entry.values.result, entry, index, edit, verified);
                if (entry.values) {
                    // If values exist, format the result using the formatResult function
                    await formatResult(entry.values.result, entry, index, edit, verified);
                } else {
                    // Get the field value from the form using the dynamically constructed field name
                    let resultFieldName = `lbtrsd_result_${entry.testKey}_${index}`;
                    let fieldValue = form.getFieldValue(resultFieldName);
                    // If the field value exists, assign it to entry.values
                    if (fieldValue) {
                        entry.values = {
                            result: fieldValue,
                        };
                    }
                    //format the result using the formatResult function
                    await formatResult(fieldValue, entry, index, edit, verified);
                }
            });

            // Formbody Used for edit lab Results
            let formBody = {
                // Lab results
                record: labReportResult.entries,
                // this Object is used to pass Labrsult id
                labresult: labReportResult.result,
                // form values of technicans and Remarks
                form: formValues,
            };
            var validFlag = true;

            // This line is commented out due to a production issue to solved a formatting issue.
            //To get validity status of form
            // await form.getFieldsError().map((i) => {
            //     if (i.errors[0] != undefined) {
            //         let fieldName = { x: i.name[0] };
            //         let field = [fieldName.x];
            //         form.setFieldsValue({ [field]: null });
            //         validFlag = false;
            //     }
            // });

            // This line is commented out due to a production issue to solved a formatting issue.
            // Shows error message if entered value is not valid
            // if (validFlag === false) {
            //     message.error('Enter Valid Result');
            // } else {
            // To update result
            const result = await ResultEntry.updateResults(formBody);

            message.success('Your entries has been recorded');

            var index;

            //This is done to select next test after result is saved
            if (testIndex + 1 != opbilld.length) {
                index = testIndex + 1;
            } else {
                index = 0;
            }

            loadTestDatawithIndex({ index });

            // setDisabled(false);
            // }

            setBtnloading(false);

            setLoading(false);
        } else {
            // If there is any warning in  the form, we push a warning string to an array. This is to find if there are any warning
            // If there are no waring, we set proceed with saving the changes
            // Else we will have to confirm from the user that they want to continue
            const arr = [];
            await form.getFieldsError().map((i) => {
                if (i.warnings[0] != undefined) {
                    arr.push('warning');
                } else if (i.errors[0] != undefined) {
                    arr.push('error');
                } else {
                    arr.push('valid');
                }
            });

            if (arr.indexOf('warning') !== -1 || arr.indexOf('error') !== -1) {
                setWarningModalVisible(true);
            } else {
                setVerified(true);
                saveLabResult(true);
            }
        }
    }

    /**
     * Used to load test data when each test is selected
     *
     *
     * @param {*} param0
     */
    function loadTestDatawithIndex({ index, result }) {
        var test;

        // Initially on page load we get opbilld from result passed here
        if (result) {
            test = result.opbilld;
        } else {
            // On saving or updating result, in such cases we get opbilld from state variable
            test = opbilld;
        }

        if (test.length) {
            // To load selected test after saving result
            setSelected(test[index]);
            setRemarks('');
            setTestIndex(index);

            // opbilld data of the index is passed to loadTestData to load the details
            test = test[index];

            loadTestData(test, index);
        }
    }

    /**
     * Function to authorize the result. Here the lab result lbtrs_isclosed is updates to 'Y'
     * indicating that the resultis authorized
     */

    async function authorizeResult() {
        var params = {
            lbtrs_itemptr: labReportResult.result.lbtrs_itemptr,
            lbtrs_refmode: labReportResult.result.lbtrs_refmode,
            lbtrs_refid: labReportResult.result.lbtrs_refid,
            lbtrs_isclosed: 'Y',
        };

        //Only if all results are entered authorise result is possible
        const filter = labReportResult.entries.filter((ele) => ele.lbtr_vtype === 'Heading' || ele.lbtrsd_result !== '');

        if (filter.length === labReportResult.entries.length) {
            Samples.authorizeLabResult(params).then((res) => {
                // Index is passed so that even after reloading the same test is selected
                getPatientDetails(urlParams, testIndex);
                setDisabled(false);
            });
            message.success('You have Successfully Authorized the Result');
        } else {
            message.warning('You have to enter Full Results ');
        }
    }

    /**
     *  Api is Used to unAuthorize lab Result
     */
    async function unAuthorizeResult(values) {
        var params = {
            id: labReportResult.result.lbtrs_id,
            remarks: values.remarks,
        };

        Samples.UnauthorizeLabResult(params).then((res) => {
            // Index is passed so that even after reloading the same test is selected
            getPatientDetails(urlParams, testIndex);
            if (res) {
                message.success('You have Successfully Unauthorized the Result');
            }
            setDisabled(false);
        });
    }
    /**
     * On Update remarks
     *
     * @param {*} event
     */
    function onUpdateRemarks(event) {
        setRemarks(event.target.value);
    }
    var { opbill, opbilld } = patient;

    var arr = [];
    var collectedTime = null;
    var recievedTime = null;
    var collectedRemarks = null;
    var recievedRemarks = null;

    if (selected && selected.result && selected.result[0]) {
        // collectedTime = selected.result[0].lbss_samtrandttm
        var sampleCollected = selected.result.filter((ele) => ele.lbss_samtranmode === 'SC');

        var sampleRecieved = selected.result.filter((ele) => ele.lbss_samtranmode === 'LR');

        if (sampleCollected && sampleCollected.length && (selected.status === 'Collected' || selected.status === 'Lab Recieved')) {
            // Sample collected remarks
            collectedRemarks = sampleCollected[0].lbss_remarks;

            // Sample collected time
            collectedTime = sampleCollected[0].lbss_samtrandttm;

            sampleCollected.map((sample) => {
                if (sample.lbss_samtrandttm > collectedTime) collectedTime = sample.lbss_samtrandttm;
            });
        }
        if (sampleRecieved && sampleRecieved.length && selected.status === 'Lab Recieved') {
            recievedRemarks = sampleRecieved[0].lbss_remarks;

            recievedTime = sampleRecieved[0].lbss_samtrandttm;

            sampleRecieved.map((sample) => {
                if (sample.lbss_samtrandttm > recievedTime) recievedTime = sample.lbss_samtrandttm;
            });
        }
    }

    /**
     *  Load default Technician an CheifTechnician
     * @param {*} options
     * @returns
     */
    function identifyDefaultTechnician(options = []) {
        let matching = {};
        if (!technician && !chiefTechnician) {
            if (options) {
                options.forEach(async (record) => {
                    if (record.genl_mode === 'LCTEC' && user.staff_id === record.genl_code) {
                        matching = record;
                    } else if (record.genl_mode === 'LTEC' && user.staff_id === record.genl_code) {
                        matching = record;
                    }
                });
            }

            if (matching) {
                return matching;
            }
        }
    }

    /**
     * View the Report
     */

    function viewReport() {
        setReportModalVisible(true);
    }

    const downloadFileName = patient.opbill;

    // This filtering is used to Display The submit an d edit buttons Based on result
    const labresults = labReportResult.entries.filter((ele) => ele.lbtrsd_result);

    /**
     * Get details with billno and prepopulate result
     */

    async function readData() {
        // To get result that needs to be prepopulated with bill number and item_code
        const result = await Samples.readData(opbill[0].opb_bno);

        // Set result when test code matches lbtr_code
        result.data.map((testOrder) => {
            labReportResult.entries.map((entry, index) => {
                if (entry.lbtr_code === testOrder.ParamCode) {
                    formatResult(testOrder.Result, entry, index);
                }
                // saving creatinine value for eGFR calculation
                if (entry.lbtr_code === testOrder.ParamCode && entry.lbtr_particulrs == 'Creatinine') {
                    setItemValues(testOrder.Result);
                }
            });
        });
    }

    return (
        <div className="result-details">
            {/* <div className="left"> */}
            {/* Modal for Viewing Report */}
            <Modal
                width={'80%'}
                destroyOnClose={true}
                footer={null}
                title={t('Report Preview')}
                visible={reportModalVisible}
                cancelButtonProps={{ style: { display: 'none' } }}
                okText="Close"
                onOk={() => {
                    setReportModalVisible(false);
                }}
                onCancel={() => {
                    setReportModalVisible(false);
                }}
            >
                <ReportPreview billId={billId} downloadFileName={downloadFileName} groupPointer={groupPointer} props={props} />
            </Modal>

            {/* Modal for Viewing ResultLog */}
            {resultLogView ? (
                <>
                    <Modal
                        width={'35%'}
                        destroyOnClose={true}
                        footer={null}
                        header={null}
                        title={t('Timeline')}
                        visible={resultLogView}
                        okText="Okay"
                        onOk={() => {
                            setResultLogView(false);
                        }}
                        onCancel={() => {
                            setResultLogView(false);
                        }}
                    >
                        <ResutLog
                            callback={(values) => {
                                onUpdate(values);
                            }}
                            guest={opbill[0]}
                            mode={mode}
                            selected={selected}
                            opbill={opbill}
                            labReportResult={labReportResult}
                            collectedTime={collectedTime}
                            collectedRemarks={collectedRemarks}
                            recievedTime={recievedTime}
                            recievedRemarks={recievedRemarks}
                        />
                    </Modal>
                </>
            ) : (
                ''
            )}

            {/* Loading Indicator */}
            {loading ? (
                <div className="skeleton-wrapper card card-shadow guest-info">
                    <Skeleton active />
                </div>
            ) : (
                <>
                    {mode === 'visit' ? null : (
                        <div className="">
                            <EntryInfo guest={opbill[0]} />
                        </div>
                    )}
                    <div style={{ display: 'flex', width: '100%' }}>
                        <div className="detail-content card card-shadow" style={{ width: '28%' }}>
                            <h5 level={6}>
                                {t('TEST NAMES')} ({opbilld.length})
                            </h5>

                            <div className="text-names">
                                <div className="col">
                                    {opbilld.map((entry, key) => {
                                        if (key < 6)
                                            return (
                                                <div className="col">
                                                    <div
                                                        onClick={() => {
                                                            // When each test is selected we need to load data of selected test
                                                            setSelected(entry);

                                                            setVerified(false);
                                                            // The code sets the selected report for a lab test. However, there is an issue with the itempointer being incorrect.
                                                            setSelectedreport(entry.labTest);

                                                            setTestIndex(key);
                                                            setRemarks('');
                                                            // Load Test Details
                                                            loadTestData(entry, key);
                                                        }}
                                                        className={`lab-test card card-shadow ${selected.opbd_id === entry.opbd_id ? 'active' : ''}`}
                                                        key={key}
                                                    >
                                                        {key + 1}. {entry.opbd_itemdesc}
                                                    </div>
                                                </div>
                                            );
                                    })}
                                </div>

                                <div className="col">
                                    {opbilld.map((entry, key) => {
                                        if (key >= 6)
                                            return (
                                                <div className="col">
                                                    <div
                                                        onClick={() => {
                                                            // When each test is selected we need to load data of selected test
                                                            setSelected(entry);

                                                            setVerified(false);
                                                            // The code sets the selected report for a lab test. However, there is an issue with the itempointer being incorrect in prod.
                                                            setSelectedreport(entry.labTest);

                                                            setTestIndex(key);

                                                            // Load Test Details
                                                            loadTestData(entry, key);
                                                        }}
                                                        className={`lab-test card card-shadow ${selected.opbd_id === entry.opbd_id ? 'active' : ''}`}
                                                        key={key}
                                                    >
                                                        {key + 1}. {entry.opbd_itemdesc}
                                                    </div>
                                                </div>
                                            );
                                    })}
                                </div>
                            </div>

                            <Modal
                                destroyOnClose={true}
                                open={warningModalVisible}
                                okText={'Yes'}
                                cancelText="No"
                                onCancel={() => {
                                    setWarningModalVisible(false);
                                    return false;
                                }}
                                onOk={() => {
                                    setVerified(true);
                                    if (mode != 'visit' && labReportResult.result && labReportResult.result.lbtrs_isclosed === 'N') {
                                        saveLabResult(true);
                                    } else {
                                        saveLabResult(true);
                                    }
                                    setWarningModalVisible(false);
                                }}
                            >
                                <p> {t('The entered Result is out of bound. Do you want to continue?')}</p>
                            </Modal>
                            {/* </div>  */}
                        </div>

                        {/* </div> */}
                        <div className="print-template card card-shadow">
                            <div className="page-header">
                                {/* <div className="left"></div> */}
                                {/* <div className='right'>
                        {report === false || !preview ? null : (
                            <Button className="preview-report-trigger" type="dashed" onClick={viewReport}>
                                Preview Report{' '}
                            </Button>
                        )}
                    </div> */}
                            </div>
                            <div className="bill-summary" style={{ width: '100%' }}>
                                {resultloading && mode != 'visit' ? (
                                    <Card className="card-shadow card" style={{ width: '100%' }}>
                                        <Skeleton active style={{ width: '100%' }} />
                                    </Card>
                                ) : (
                                    <>
                                        {mode === 'visit' ? null : (
                                            <div>
                                                {/* Result Section */}
                                                {/* <Title level={5}>PARAMETERS</Title> */}

                                                <div className="form-container">
                                                    <div style={{ flex: '1 1 50%' }}>
                                                        <Form
                                                            layout="vertical"
                                                            initialValues={{
                                                                chief_technician: chiefTechnician,
                                                            }}
                                                        >
                                                            <Form.Item
                                                                name="chief_technician"
                                                                rules={[{ required: true, message: t('Please select Technician') }]}
                                                                label={t('CHIEF TECHNICIAN')}
                                                            >
                                                                <ReferenceSelect
                                                                    field="genl_desc"
                                                                    config={{
                                                                        queries: [
                                                                            {
                                                                                field: 'genl_mode',
                                                                                operator: 'LIKE',
                                                                                value: 'LCTEC',
                                                                            },
                                                                            {
                                                                                field: 'genl_active', // check active status of CHIEF TECHNICIAN
                                                                                operator: 'EQUALS',
                                                                                value: 'Y',
                                                                            },
                                                                        ],
                                                                    }}
                                                                    defaultValueCondition={identifyDefaultTechnician}
                                                                    onChange={(e) => setCheifTechnician(e)}
                                                                    allowClear
                                                                    label="genl_desc"
                                                                    mode="nura-base"
                                                                    model={GeneralLists}
                                                                    style={{ width: '160px' }}
                                                                />
                                                            </Form.Item>
                                                        </Form>
                                                    </div>
                                                    <div style={{ flex: '1 1 50%' }}>
                                                        <Form
                                                            layout="vertical"
                                                            initialValues={{
                                                                technician: technician,
                                                            }}
                                                        >
                                                            <Form.Item
                                                                name="technician"
                                                                rules={[{ required: true, message: t('Please select Technician') }]}
                                                                label={t('TECHNICIAN')}
                                                            >
                                                                <ReferenceSelect
                                                                    field="genl_desc"
                                                                    config={{
                                                                        queries: [
                                                                            {
                                                                                field: 'genl_mode',
                                                                                operator: 'LIKE',
                                                                                value: 'LTEC',
                                                                            },
                                                                            {
                                                                                field: 'genl_active', //to show  active TECHNICIAN
                                                                                operator: 'EQUALS',
                                                                                value: 'Y',
                                                                            },
                                                                        ],
                                                                    }}
                                                                    defaultValueCondition={identifyDefaultTechnician}
                                                                    allowClear
                                                                    label="genl_desc"
                                                                    mode="nura-base"
                                                                    onChange={(e) => setTechnician(e)}
                                                                    model={GeneralLists}
                                                                    style={{ width: '160px' }}
                                                                />
                                                            </Form.Item>
                                                        </Form>
                                                    </div>

                                                    <div className="actions">
                                                        {/*Extra Info component start  */}
                                                        {extraInfo ? (
                                                            <ExtraInfoDetail record={billId} modeValue={modeValue} title={title} icon={icon} />
                                                        ) : null}
                                                        {/*Extra Info component end  */}
                                                        {/* Results Log Button */}
                                                        <Button type="primary" size="small" class="btn" onClick={viewResultLog}>
                                                            {' '}
                                                            {t('VIEW LOGS')}
                                                        </Button>

                                                        {report === false || !preview ? null : (
                                                            <Button
                                                                size="small"
                                                                className="preview-report-trigger"
                                                                type="dashed"
                                                                onClick={viewReport}
                                                            >
                                                                {t('Preview Report')}{' '}
                                                            </Button>
                                                        )}

                                                        {/* EGFR Calculator */}
                                                        {selected.opbd_itemdesc == 'Kidney' && opbill[0] ? (
                                                            <Formula
                                                                opbill={opbill[0]}
                                                                code="eGFR"
                                                                caption={t('EGFR Calculator')}
                                                                inputParameters={labReportResult.entries}
                                                                callback={(formulaResult) => {
                                                                    onUpdate(formulaResult);
                                                                }}
                                                            />
                                                        ) : (
                                                            ''
                                                        )}
                                                        {/* EGFR Calculator Ends */}
                                                    </div>
                                                </div>
                                                {/** Technician and remark  Select section Ends */}
                                            </div>
                                        )}

                                        <div className="table-content">
                                            <Table
                                                rowKey={(record, key) => key}
                                                pagination={false}
                                                dataSource={labReportResult.entries}
                                                columns={columns}
                                            />
                                        </div>

                                        <Form.Item name="remarks">
                                            <div className="detail-element">
                                                <Row span={12}>
                                                    <span>{t('Remarks')}</span>
                                                </Row>
                                                <Row span={12}>
                                                    <TextArea maxLength={100} value={remarks} onChange={onUpdateRemarks}></TextArea>

                                                    {/* <h3>{(labReportResult.result && labReportResult.result.lbtrs_remarks) || ''}</h3> */}
                                                </Row>
                                            </div>
                                        </Form.Item>
                                        <div className="result-read">
                                            {/** Read Data section start */}
                                            <div style={{ marginLeft: '20px' }} onClick={readData}>
                                                <Button type="primary" disabled={!disabled}>
                                                    {t('Read Data')}
                                                </Button>
                                            </div>
                                            {/** Read Data section End */}
                                            {/** Read */}
                                            <div style={{ marginRight: '20px' }}>
                                                <Button type="primary" disabled={!disabled} onClick={saveLabResult} loading={btnloading}>
                                                    Save
                                                </Button>
                                            </div>
                                            {/**If the mode is not 'visit', and there is a lab report result, and the result is not closed: */}
                                            {mode != 'visit' && labReportResult.result && labReportResult.result.lbtrs_isclosed === 'N' ? (
                                                <div className="edit-changes">
                                                    {alert ? (
                                                        <Alert
                                                            showIcon
                                                            type="warning"
                                                            message={t('Result is not within range')}
                                                            style={{ margin: '10px 0px' }}
                                                        />
                                                    ) : null}
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                        <div style={{ marginRight: '20px' }}>
                                                            <Button type="primary" onClick={authorizeResult}>
                                                                {t('Authorize Result')}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : labReportResult.result && labReportResult.result.lbtrs_isclosed === 'Y' && allowUnauthorise ? (
                                                /*Section start  unAuthorize lab results.Else if there is a lab report result, the result is closed, and unauthorization is allowed */

                                                <div className="submit-changes">
                                                    <ConfirmModal
                                                        maxLength={250}
                                                        size={200}
                                                        type="primary"
                                                        className="submit-changes"
                                                        caption={t('Unauthorize')}
                                                        description={t('Are you sure you want to unauthorise this result? ')}
                                                        callback={(values) => {
                                                            unAuthorizeResult(values);
                                                        }}
                                                    ></ConfirmModal>
                                                    {/**Section End  unAuthorize lab results*/}
                                                </div>
                                            ) : null}
                                        </div>
                                    </>
                                )}
                                {/* Result Section */}
                            </div>
                            -
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
