/**
 * Component to Show corporate - view offers
 *
 *
 *
 */
import React, { useEffect, useState, useContext } from 'react';
import { ReferenceSelect, useTranslation } from 'soxo-bootstrap-core';
import { Customers } from '../../../../models';
import { Typography, Modal, Skeleton, Button, Input, Select } from 'antd';
import './corporate-sections.scss';
// import '../bill-items.scss';
const { Option } = Select;

const { Title, Text } = Typography;

const { TextArea } = Input;

export default function CorporateSections({ value, setCorporate, packageValue, callback }) {
    const [selected, setSelected] = useState(null);

    // To Translate to another language
    const { t, i18n } = useTranslation();     

    // Variable used for controlling the modal
    const [isModalVisible, setModalVisible] = useState(false);

    /**
     * Open the modal
     */
    const showStydecontent = () => {
        setModalVisible(true);
    };

    /**
     * Close the modal
     */
    const handleModalClose = () => {
        setModalVisible(false);
    };

    useEffect(async () => {
        if (value) {
            // setCorporate(true)
            let data = await Customers.getCustomerWithId(value);
            let corporate;
            corporate = {
                record: data.result,
            };
            setSelected(corporate);
        }
    }, []);

    // const onUpdateSelect = (value, updateMethod) => {
    //     if (value) {
    //         updateMethod(value);
    //     } else {
    //         updateMethod('');
    //     }
    // };

    /**
     * Update the selected value and call the provided update method.
     *
     * @param {string} value - The selected value.
     * @param {Function} updateMethod - The method to update the corporate section value.
     * @param {string} type - The selected type.
     */
    async function onselectChange(value, type, updateMethod) {
        updateMethod(value);
        // set values
        let record = {
            // package_value: packageValue.items[0].ir_itemptr,
            type: type.selected_type,
            corporate_pointer: value,
        };
        if (packageValue && packageValue.items.length > 0) {
            record = {
                ...record,
                package_value: packageValue.items[0].ir_itemptr,
            };
        }
        callback(record);
    }

    return (
        <div className="corporate-sections card card-shadow">
            <div className="corporate-section">
                <Title level={5}>{t('Corporate')}</Title>
                <ReferenceSelect
                    label="cc_desc"
                    mode="nura-base"
                    model={Customers}
                    field={'cc_code'}
                    value={value}
                    // onChange={(entry, record) => {
                    //     onUpdateSelect(entry, setCorporate);

                    //     setSelected(record);
                    // }}
                    config={{
                        queries: [
                            {
                                field: 'cc_active',
                                value: 'Y',
                            },
                        ],
                    }}
                    onChange={(entry, record) => {
                        onselectChange(entry, { selected_type: 'C' }, setCorporate);
                        setSelected(record);
                    }}
                />
            </div>

            <div className="corporate-view">
                {selected && selected.record && selected.record.cc_styledcontent && (
                    <Button size={'medium'} onClick={showStydecontent}>
                        {t('View offers')}
                    </Button>
                )}
            </div>

            <Modal visible={isModalVisible} onCancel={handleModalClose} footer={null}>
                {selected && selected.record && selected.record.cc_styledcontent ? (
                    <div dangerouslySetInnerHTML={{ __html: selected.record.cc_styledcontent }}></div>
                ) : (
                    <p>{t('No content available')}</p>
                )}
            </Modal>
        </div>
    );
}
