/**
 * Discount Model
 *
 *
 *
 *
 */
import React from 'react';

import Model from './models';

import { ApiUtils, BaseAPI } from 'soxo-bootstrap-core';

class Discount extends BaseAPI {
    constructor() {
        super();

        this.fields = [
            {
                field: 'name',

                caption: 'Name',
            },
        ];
    }

    get id() {
        return 'cmgdcm_id';
    }

    get getEndpoint() {
        return 'campgndiscountmas';
    }

    get path() {
        return `campgndiscountmas`;
    }

    get getName() {
        return this.modelName;
    }

    get modelName() {
        return `campgndiscountmas`;
    }

    getCoupons = ({mode,active,limit,page}) => {
        return ApiUtils.get({
            url: `campgndiscountmas/coupons-by-mode?mode=${mode}&active=${active}&limit=${limit}&page=${page}`,
        });
    };


    
    updateCoupons = (formBody) => {
        return ApiUtils.put({
            url: `campgndiscountmas/coupon-update`,
            formBody
        });
    };

}

export default Discount;
