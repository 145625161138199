/***
 *
 *
 * @description
 * @author Sameena
 */

import React, { useState, useEffect, useContext } from 'react';

import {
    Skeleton,
    Row,
    Col,
    Button,
    DatePicker,
    Input,
    Form,
    Select,
    Card,
    Typography,
    Space,
    message,
    Tag,
    Checkbox,
    InputNumber,
    Popconfirm,
    Modal,
} from 'antd';

import { Location, DateUtils, InvoiceNumbers, GlobalContext, InputComponent, useTranslation } from 'soxo-bootstrap-core';

import moment from 'moment';

import { Candidates, Registrations } from './../../../../models/';

// import CreatableSelect, { makeCreatableSelect } from 'react-select/creatable';

import './registration-add.scss';
import Appointments from '../../../../models/appointments';

const { Option } = Select;

const { Title, Text } = Typography;

const { TextArea } = Input;

const formItemLayout = {
    labelCol: {
        // xs: { span:0 },
        // sm: { span: 0},
    },
    wrapperCol: {
        // xs: { span: 0},
        // sm: { span: 0},
    },
    layout: 'vertical',
};

const title = ['Mr', 'Ms', 'Mrs', 'Dr'];

export default function CandidateAdd({ menu }) {
    const { user } = useContext(GlobalContext);

    const [btnloading, setBtnLoading] = useState(false);

    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();

    const [billno, setBillno] = useState([]);

    const [modes, setModes] = useState([]);

    const [selected, setSelected] = useState({});

    const [corporate, setCorporate] = useState(false);

    const [list, setList] = useState([]);

    const [countries, setCountrys] = useState([]);

    const [city, setCity] = useState([]);

    const [coupon, setCoupon] = useState(null);

    const [visible, setVisible] = useState(false);

    const [couponloading, setCouponLoading] = useState(false);

    const [item, setItem] = useState({}); //Get Package Details

    const { t, i18n } = useTranslation(); // To Translate to another 

    var urlParams = Location.search();

    useEffect(() => {
        getRegistrationNumber();
    }, []);

    async function getRegistrationNumber() {
        const result = await InvoiceNumbers.getNextNumber({ category: 'registration', menu, user });

        console.log(result, menu);
    }

    //Get country list
    function getCountries() {
        Appointments.getCountries().then((result) => {
            setCountrys(result);
        });
    }

    //Get Communication mode
    function getCommunicationMode() {
        Appointments.getCommunication().then((result) => {
            console.log(result);

            setModes(result);
        });
    }

    /**
     * Get city list of selected country
     */
    function getCityList(value) {
        return Appointments.getCityList(value).then((result) => {
            console.log(result);

            setCity(
                result.map((record) => {
                    return {
                        label: record,
                        value: record,
                    };
                })
            );
        });
    }

    let disabled = false;

    let required = true;

    let disabledDates = false;

    let addressRequired = true;

    let couponvisibility = false;

    let date,
        time,
        editEnable = null;

    //To save Coupon details
    var total, couponno, couponvalue, billvalue;

    //Initaialising Values
    const [guest, setGuest] = useState({
        op_patcatptr: 'GEN',
        op_modeofcommptr: '1',
    });

    var emailExists;

    /**
     * on Submit
     * @param {*} formBody
     */

    const onFinish = (formBody) => {
        setBtnLoading(true);

        formBody = {
            ...formBody,
            date_of_birth: DateUtils.formatDate(formBody.date_of_birth),
        };

        Registrations.add(formBody).then((record) => {
            Location.navigate({
                url: `/new-billing?registration_id=${record.id}`,
            });

            setBtnLoading(false);
        });
    };

    useEffect(() => {}, []);

    return (
        <div className="registration-add">
            <Card className="card vehicle-card form-step">
                {/* Form Content */}
                <div className="form-content">
                    {!loading ? (
                        <>
                            <div className="page-header">
                                <div>
                                    <Title level={4}>{t('Guest Information')} # {billno}</Title>

                                    <p className="page-desc">{t('Please enter the below details to complete the appointment.')}</p>
                                </div>
                            </div>

                            <Form
                                {...formItemLayout}
                                form={form}
                                name="register"
                                initialValues={{ ...guest }}
                                onFieldsChange={(fields) => {
                                    if (fields[0].name[0] === 'op_iscorporate') {
                                        console.log(fields[0].value);

                                        setCorporate(fields[0].value);
                                    }
                                }}
                                onFinish={onFinish}
                                scrollToFirstError
                            >
                                <div className="steps-content">
                                    <>
                                        <Row gutter={20}>
                                            <Col xs={24} md={12} xl={8}>
                                                <Form.Item name="title" label={t("Title")} rules={[{ required: false, message: t('Please Select Title') }]}>
                                                    <Select value={title}>
                                                        <Option value="Mr.">{t('Mr')}.</Option>
                                                        <Option value="Ms.">{('Ms')}.</Option>
                                                        <Option value="Mrs.">{('Mrs')}.</Option>
                                                        <Option value="Dr.">{('Dr')}.</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col xs={24} md={12} xl={8}>
                                                <Form.Item
                                                    name="first_name"
                                                    label={t("First Name")}
                                                    rules={[{ required: true, message: t('Please Enter First Name') }]}
                                                >
                                                    <InputComponent />
                                                </Form.Item>
                                            </Col>

                                            <Col xs={24} md={12} xl={8}>
                                                <Form.Item
                                                    name="last_name"
                                                    label={t("Last Name")}
                                                    rules={[{ required: true, message: t('Please Enter Last Name') }]}
                                                >
                                                    <InputComponent />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={20}>
                                            <Col xs={24} md={8} xl={8}>
                                                <Form.Item name="gender" label={t("Gender")} rules={[{ required: true, message: t('Please Select Gender') }]}>
                                                    <Select disabled={disabled}>
                                                        <Option value="M">{t('Male')}</Option>
                                                        <Option value="F">{t('Female')}</Option>
                                                        <Option value="Other">{t('Other')}</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col xs={12} md={8} xl={8}>
                                                <Form.Item
                                                    name="date_of_birth"
                                                    label={t("Date of Birth")}
                                                    rules={[{ required: required, message: t('Please Select Date of Birth') }]}
                                                >
                                                    <DatePicker
                                                        format="DD/MM/YYYY"
                                                        defaultPickerValue={moment('01-01-1990', 'DD-MM-YYYY')}
                                                        disabledDate={disabledDates}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            {/*For Age */}
                                            <Col xs={12} md={8} xl={8}>
                                                <Form.Item
                                                    name="age"
                                                    label={t("Age")}
                                                    rules={[
                                                        { required: false, message: t('Please Enter Age ') },
                                                        // {
                                                        //     pattern: /^S[0-9]{0,3}$/,
                                                        //     message: "Invalid Age",
                                                        // },
                                                    ]}
                                                >
                                                    <InputNumber min="0" />
                                                </Form.Item>
                                            </Col>
                                            {/* 
                  <Col span={4}>
    
                    <Form.Item name="op_agetype" label="AgeType">
                      <Input />
                    </Form.Item>
    
                  </Col>
    
                  <Col span={4}>
    
                    <Form.Item name="op_ageyear" label="ageyear">
                      <Input />
                    </Form.Item>
    
                  </Col>
    
                  <Col span={4}>
    
                    <Form.Item name="op_agemonth" label="agemonth">
                      <Input />
                    </Form.Item>
    
                  </Col>
    
                  <Col span={4}>
    
                    <Form.Item name="op_ageday" label="ageday">
                      <Input />
                    </Form.Item>
                  </Col> */}
                                        </Row>
                                        <div>
                                            <Row gutter={20}>
                                                <Col xs={24} md={12} xl={8}>
                                                    <Form.Item
                                                        name="phone_number"
                                                        label={t("Phone Number")}
                                                        rules={[
                                                            { required: true, message: t('Please Enter Mobile Number') },
                                                            {
                                                                pattern: /^(\+\d{1,3}[- ]?)?\d{10}$/,
                                                                message: t('Please enter 10 digit mobile number'),
                                                            },
                                                        ]}
                                                        // hidden={urlParams.edit}
                                                    >
                                                        <InputComponent />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={12} xl={8}>
                                                    <Form.Item
                                                        name="alternate_phone_number"
                                                        label={t("Alternate Phone Number")}
                                                        rules={[
                                                            { required: false, message: t('Please Enter Mobile Number') },
                                                            {
                                                                pattern: /^(\+\d{1,3}[- ]?)?\d{10}$/,
                                                                message: t('Please enter 10 digit mobile number'),
                                                            },
                                                        ]}
                                                        // hidden={urlParams.edit}
                                                    >
                                                        <InputComponent />
                                                    </Form.Item>
                                                </Col>

                                                {urlParams.edit || urlParams.da_id ? null : (
                                                    <Col xs={24} md={12} xl={8}>
                                                        <Form.Item
                                                            name="op_email"
                                                            label={t("E-mail")}
                                                            rules={[
                                                                {
                                                                    type: 'email',
                                                                    message: t('The input is not valid E-mail!'),
                                                                },
                                                                {
                                                                    required: true,
                                                                    message: t('Please Enter E-mail!'),
                                                                },
                                                            ]}
                                                        >
                                                            <InputComponent />
                                                        </Form.Item>
                                                    </Col>
                                                )}
                                            </Row>

                                            <Row gutter={20}>
                                                <Col xs={24} md={12} xl={8}>
                                                    <Form.Item
                                                        name="op_add1"
                                                        label={t("Address1")}
                                                        rules={[{ required: false, message: t('Please Enter Primary Address') }]}
                                                    >
                                                        <InputComponent />
                                                    </Form.Item>
                                                </Col>

                                                <Col xs={24} md={12} xl={8}>
                                                    <Form.Item
                                                        name="op_add2"
                                                        label={t("Address2")}
                                                        rules={[{ required: false, message: t('Please Enter Secondary Address') }]}
                                                    >
                                                        <InputComponent />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row gutter={20}>
                                                <Col xs={24} md={12} xl={8}>
                                                    <Form.Item
                                                        name="country"
                                                        label={t("Country")}
                                                        rules={[{ required: false, message: t('Please Enter Country') }]}
                                                    >
                                                        {/* <Select value={title} onSelect={getCountries}>
                                                             {countries.map((mode) => {
                                                                 return <Option value={mode.lcnt_desc}>{mode.lcnt_desc}</Option>;
                                                             })}
                                                         </Select> */}
                                                    </Form.Item>
                                                    s
                                                </Col>

                                                <Col xs={24} md={12} xl={8}>
                                                    <Form.Item
                                                        name="place"
                                                        label={t("City/Town")}
                                                        rules={[{ required: false, message: t('Please Enter City/Town') }]}
                                                    >
                                                        {/* <Select value={title}>
                                                              {city.map((mode) => {
                                                                  return <Option value={mode}>{mode}</Option>;
                                                              })}
                                                          </Select> */}

                                                        {/* <CreatableSelect
                                                             isClearable
                                                             // onChange={this.handleChange}
                                                             // onInputChange={this.handleInputChange}
                                                             options={city}
                                                             value={title}
                                                         /> */}
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row gutter={20}>
                                                <Col xs={24} md={12} xl={8}>
                                                    <Form.Item
                                                        name="zip"
                                                        label={t("PIN Code")}
                                                        rules={[{ required: false,min:5,max:10, message: t('Please Enter Pin Code') }]}
                                                    >
                                                        <InputComponent />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            {urlParams.mode === 'Call' || urlParams.edit ? null : (
                                                <>
                                                    <Row gutter={20}>
                                                        <Col xs={24} md={12} xl={8}>
                                                            <Form.Item
                                                                name="op_modeofcommptr"
                                                                label={t("Communication Mode")}
                                                                rules={[{ required: true, message: t('Please Enter Communication Mode') }]}
                                                            >
                                                                <Select value={title}>
                                                                    {modes.map((mode) => {
                                                                        return <Option value={mode.comt_code}>{mode.comt_desc}</Option>;
                                                                    })}
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>

                                                        <Col xs={24} md={12} xl={8}>
                                                            <Form.Item
                                                                name="op_patcatptr"
                                                                label={t("Category")}
                                                                rules={[{ required: false, message: t('Please Enter Category') }]}
                                                            >
                                                                <Select>
                                                                    <Option value="GEN">{t('General')}</Option>
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>

                                                    <Row gutter={20}>
                                                        <Col xs={24} md={12} xl={8}>
                                                            <Form.Item
                                                                valuePropName="checked"
                                                                name="op_iscorporate"
                                                                rules={[{ required: false, message: t('Is this a Corporate Customer') }]}
                                                            >
                                                                <Checkbox>{t('Is this a Corporate Customer?')}</Checkbox>
                                                            </Form.Item>
                                                        </Col>

                                                        {corporate || guest.op_corporateptr ? (
                                                            <Col xs={24} md={12} xl={8}>
                                                                <Form.Item
                                                                    name="op_corporateptr"
                                                                    label={t("Corporate")}
                                                                    rules={[{ required: false, message: t('Is this a Corporate Customer') }]}
                                                                >
                                                                    <Select allowClear style={{ width: '100%' }} placeholder={t("Choose Company")}>
                                                                        {list.map((item, key) => (
                                                                            <Option key={key} value={item.cc_code}>
                                                                                {item.cc_desc}
                                                                            </Option>
                                                                        ))}
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                        ) : null}
                                                    </Row>
                                                </>
                                            )}
                                        </div>
                                    </>
                                </div>

                                <Space>
                                    <Button loading={btnloading} type="primary" htmlType="submit">
                                        {t('Save')}
                                    </Button>

                                    <Button
                                        type="secondary"
                                        onClick={() => {
                                            Location.navigate({ url: '/new-billing' });
                                        }}
                                    >
                                        {t('Clear')}
                                    </Button>
                                </Space>
                            </Form>
                        </>
                    ) : (
                        <Skeleton />
                    )}
                </div>

                {/* Form Content Ends */}
            </Card>

            {/* Package Details or Other Content */}
            <div className="hero-part">
                <div className="card card-shadow">
                    <div className="section-header">
                        <Title level={5}>{t('Package')}</Title>
                        {/* 
              <Button
                size={'small'}
                onClick={() => {
  
                  callback('guest');
                }}
              >
                Edit
              </Button> */}
                    </div>

                    {loading ? (
                        <Skeleton />
                    ) : (
                        <>
                            {/*  */}
                            <div className="section-content">
                                <div className="section-row">
                                    <div className="section-label">
                                        <Text>
                                            <strong>{selected.itm_desc}</strong>
                                        </Text>
                                    </div>
                                    <div className="section-value">
                                        <strong>{item.ir_rate}</strong>
                                    </div>
                                </div>

                                <div className="section-row">
                                    <div className="section-label">
                                        <Text type="secondary">{t('Coupon Discount')}</Text>
                                    </div>
                                    <div className="section-value">
                                        {coupon && couponvalue ? (
                                            <>
                                                <strong>{couponvalue}</strong>

                                                <Popconfirm
                                                    title={t("Are you sure you want to Cancel the Coupon ? ")}
                                                    onConfirm={() => {
                                                        setCoupon(null);
                                                    }}
                                                    className="cancel-coupon-button"
                                                    onCancel={() => {}}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <Button size="small" className="cancel-coupon-button">
                                                        {t('Cancel')}
                                                    </Button>
                                                </Popconfirm>
                                            </>
                                        ) : (
                                            <Button
                                                size="small"
                                                // type="ghost"
                                                // shape="round"
                                                onClick={couponvisibility}
                                            >
                                                {' '}
                                                {t('Apply Coupon')}
                                            </Button>
                                        )}
                                    </div>
                                </div>

                                <div className="section-row">
                                    <div className="section-label">
                                        <Text>{t('Total')}</Text>
                                    </div>
                                    <div className="section-value">
                                        <strong>{coupon && total ? total : item.ir_rate}</strong>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>

                {/* Mode and Time */}
                <div className="card card-shadow">
                    <div className="section-header">
                        <Title level={5}>{t('Mode')}</Title>
                    </div>

                    <div className="section-content">
                        {urlParams.mode ? (
                            <div className="section-row">
                                <div className="section-label">
                                    <Text type="secondary">{t('Type')}</Text>
                                </div>
                                <div className="section-value">
                                    <strong>
                                        <Tag color="green">{urlParams.mode}</Tag>
                                    </strong>
                                </div>
                            </div>
                        ) : null}

                        <div className="section-row">
                            <div className="section-label">
                                <Text type="secondary">{t('Time')}</Text>
                            </div>
                            {/* {urlParams.redirect === "appointment" ?
                                  <div className="section-value">
                                      <strong>
                                          {urlParams.selected_date} {urlParams.selected_time}
                                      </strong>
                                  </div> : */}

                            <div className="section-value">
                                <strong>
                                    {date} {time}
                                </strong>
                            </div>
                        </div>
                        {editEnable ? (
                            <div className="section-label">
                                <Popconfirm
                                    title={t("Are you sure you want to edit your Appointment date and time ? ")}
                                    onConfirm={() =>
                                        Location.navigate({ url: `/new-registration/slots?da_packagetypevalue=${selected.itm_code}&edit=true` })
                                    }
                                    onCancel={() => {}}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button size="small">{t('Edit')}</Button>
                                </Popconfirm>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>

            <Modal
                destroyOnClose={true}
                footer={null}
                title={t("Apply Coupon")}
                visible={visible}
                okText="Okay"
                onOk={() => {
                    setVisible(false);
                }}
                onCancel={() => {
                    setVisible(false);
                }}
            >
                <CouponModal setVisible={setVisible} setCoupon={setCoupon} />
            </Modal>
        </div>
    );
}

function CouponModal({ setVisible, setCoupon }) {
    const [loading, setLoading] = useState(false);

    const { t, i18n } = useTranslation(); // To Translate to another language

    const onFinish = (values) => {
        setVisible(false);

        Appointments.applyCoupon(values.coupon)
            .then((result) => {
                console.log(result);

                setCoupon(result);

                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);

                message.error('Invalid Coupon');
            });
    };

    return (
        <>
            <p>{t("Mention the discount")}</p>

            <Form name="basic" initialValues={{ remember: true }} onFinish={onFinish}>
                <Form.Item label={t("Coupon")} name="coupon" rules={[{ required: true, message: t('Please Enter Coupon') }]}>
                    <InputComponent />
                </Form.Item>
                <Form.Item name="remarks" label={t("Remarks")} rules={[{ required: true, message: t('Please Enter Remarks') }]}>
                    <TextArea rules={[{ required: true, message: t('Please enter your remarks') }]} />
                </Form.Item>

                <Form.Item>
                    <Button loading={loading} type="primary" htmlType="submit">
                        {t("Save")}
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}
