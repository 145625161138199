/**
 * Discount Model
 *
 *
 *
 *
 */
import React from 'react';

import Model from './models';

import { ApiUtils, BaseAPI } from 'soxo-bootstrap-core';

class Coupon extends BaseAPI {
    constructor() {
        super();

        this.fields = [
            {
                field: 'name',

                caption: 'Name',
            },
        ];
    }

    get id() {
        return 'cmgdcpn_id';
    }

    get getEndpoint() {
        return 'campgndiscountcoupons';
    }

    get path() {
        return `campgndiscountcoupons`;
    }

    get getName() {
        return this.modelName;
    }

    get modelName() {
        return `campgndiscountcoupons`;
    }

}

export default Coupon;
