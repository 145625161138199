import React, { useState, useContext, useRef } from 'react';

import logoimg from './../../../../assets/images/logo_1.png';

import healthimg from './../../../../assets/images/health_1.png';

import { Button, Input, Form, message } from 'antd';

import { QRcode, GlobalContext,InputComponent, useTranslation } from 'soxo-bootstrap-core';

import Newstate from './wrist_band';

import './kiosk-screen.scss';

import { Opreg } from '../../../../models';

import { ArrowRightOutlined } from '@ant-design/icons';

import ArrowIcon from './../../../../assets/icons/Icons.png';

import { useReactToPrint } from 'react-to-print';

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const states = {
    input: 'input',
    result: 'result',
    continue: 'continue',
};

export default function KioskScreen({ connect_local_client }) {

    const { kiosk, dispatch } = useContext(GlobalContext);

    // Variable to save the guest data
    const [guest, setGuest] = useState({});

    const [ID, setID] = useState('');

    const [patientname, setPatientname] = useState('');

    const [currentState, setCurrentState] = useState(states.input);

    const [isKioskMode, setIsKioskMode] = useState(false);

    const [startDragPoint, setStartDragPoint] = useState({ x: 50, y: 50 });

    const [endDragPoint, setEndDragPoint] = useState({ x: 30, y: 30 });

    const { t, i18n } = useTranslation(); // To Translate to another language

    const [inputValue, setInputValue] = useState('');
    /**
     *
     *
     *
     * @param {*} e
     */

    const handleInputChange = e => {
        setInputValue(e.target.value);
    };

    const handleCancelClick = () => {
        setCurrentState(states.input);
    };
    /**
     * @
     */
    async function handleSubmit(formBody) {
        let queries = [
            {
                field: 'op_no',
                value: formBody.opno,
            },
        ];

        let result = await Opreg.get({ url: 'opreg/search', queries });

        if (result.length === 0) {

            message.error('Invalid OP No');
        } else {
            setPatientname(result[0].op_fname + ' ' + result[0].op_lname);

            setGuest(result[0])

            setCurrentState(states.result);
        }
    }

    const getPrint = () => {
        if (connect_local_client) {
            handleContinueClick()
        } else {
            handlePrint()
        }
    }

    /**
     * Print op number
     */
    var print = useRef();

    const handlePrint = useReactToPrint({
        content: () => print.current,
        bodyClass: 'hello',
    });

    /**
     * 
     */
    const handleContinueClick = () => {

        // Make the POST request to the server to trigger the print
        fetch('http://localhost:3000/trigger-print', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                // Pass any data you want to send to the server here
                ID,
                patientName: patientname,
                opNumber: guest.op_no
            }),
        })
            .then(response => {
                // Check if the response status is 200 (success)
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json(); // Parse the JSON data from the response
            })
            .then(data => {
                console.log('Server response:', data); // Log the response data
                setCurrentState(states.continue); // Move to the 'continue' state after receiving the response

                // Simulate a 3-second delay
                setTimeout(() => {
                    // Make a second POST request inside the response handler
                    fetch('http://localhost:3000/trigger-epctag-query', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            // Pass any data you want to send to the server here
                            ID,
                            patientName: patientname,
                            opNumber: guest.op_no
                        }),
                    })
                        .then(response => {
                            // Check if the response status is 200 (success)
                            if (!response.ok) {
                                throw new Error('Network response second was not ok');
                            }
                            return response.json(); // Parse the JSON data from the response
                        })
                        .then((secondData) => {
                            // Data received from the second POST request
                            console.log('Second POST response:', secondData);
                        })
                        .catch((error) => {
                            console.error('Error in second POST request:', error);
                        });
                }, 3000); // Delay for 3 seconds
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    /**
     * Function enables the kiosk mode
     */
    const enterKioskMode = () => {
        setCurrentState(states.input);
        setIsKioskMode(true);

        dispatch({ type: "kiosk", payload: true });
    };

    const handleDragStart = event => {
        // Store the starting point when dragging starts
        setStartDragPoint({ x: event.clientX, y: event.clientY });
    };

    /**
     * 
     * @param {*} event 
     */
    const handleDragEnd = event => {

        // Variable to confirm if the kiosk mode need to be exited
        const dragThreshold = 50;

        // Store the ending point when dragging ends
        setEndDragPoint({ x: event.clientX, y: event.clientY });

        // Calculate the distance dragged (optional)
        const distanceDraggedX = endDragPoint.x - startDragPoint.x;
        const distanceDraggedY = endDragPoint.y - startDragPoint.y;

        // Determine if the drag is primarily horizontal or vertical based on the distance comparison
        if (Math.abs(distanceDraggedX) > dragThreshold || Math.abs(distanceDraggedY) > dragThreshold) {

            // Only consider it a valid drag if the movement is significant enough
            if (isKioskMode && currentState === states.input) {

                // Set the kiosk mode
                setIsKioskMode(!isKioskMode);

                // We have to trigger an event to disable kiosk
                dispatch({ type: "kiosk", payload: false });
            }
        } else {
            // Ignore the drag event if it's not significant enough
            console.log('Ignored Drag');
        }
    };

    // /**
    //  * Function listends for the drag on the screen to control
    //  * 
    //  * @param {*} event 
    //  */
    // const handleDrag = event => {
    //     console.log({ event });
    //     event.preventDefault();
    //     const dragThreshold = 20;


    //     const distanceX = event.clientX - startDragPoint.x;
    //     const distanceY = event.clientY - startDragPoint.y;
    // };

    const renderContent = () => {
        switch (currentState) {
            case states.input:
                return (
                    <div
                        className={`drag-container ${isKioskMode ? 'drag-container-kiosk-mode' : ''}`}
                        onDragStart={handleDragStart}
                        onDragEnd={handleDragEnd}
                        // onDrag={handleDrag}
                        draggable="false"
                    >
                        <div
                            className="main_container"
                            style={{
                                margin: 0,
                            }}
                        >
                            <div className="container_1">



                                <Form className="form" onFinish={handleSubmit}>
                                    <h4 className="title">{t('ENTER YOUR NURA ID')}</h4>
                                    <Form.Item name="opno" rules={[
                                        {
                                            required: true,

                                            message: t('Please select OP No'),
                                        },

                                    ]} >
                                        <InputComponent name="opno" className="input" value={inputValue} onChange={handleInputChange} mode={'uppercase'}></InputComponent>
                                        {/* <Input name="opno" className="input" value={inputValue} onChange={handleInputChange}></Input> */}
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            block
                                            type="primary"
                                            htmlType="submit"
                                            // disabled={isInputEmpty}
                                            className="btn btn-primary"
                                        >
                                            {t('SEARCH')}
                                            <img src={ArrowIcon} className="arrow-icon"></img>
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                            <div className="container_2">
                                <div className="logo_container">
                                    <img src={logoimg}

                                        // onDragStart={handleDragStart}
                                        //     onDragEnd={handleDragEnd}
                                        //     onDrag={handleDrag}
                                        className="logo_img" alt="logo_pic" />
                                </div>
                                <div className="health_container">
                                    <img src={healthimg} className="health_img" alt="health_pic" />
                                </div>

                                <div className="btn_kiosk">
                                    {
                                        !isKioskMode ? <Button
                                            type="secondary"
                                            size='small'
                                            htmlType="submit"

                                            onClick={enterKioskMode}
                                            disabled={isKioskMode}
                                        >
                                            {t('Enter Kiosk Mode')}
                                            <ArrowRightOutlined />
                                        </Button> : null
                                    }

                                </div>

                            </div>
                        </div>
                    </div>
                );
            case states.result:
                return (
                    <div
                        className="main_container"
                        style={{
                            margin: 0,
                        }}
                    >
                        <div className="container_1">
                            <Form className="form_result" action="http://localhost:3000/trigger-print" method="post">
                                <h4 className="title">{t('ENTER YOUR NURA ID OR BOOKING ID')}</h4>
                                <Form.Item>
                                    <Input
                                        className="input"
                                        value={inputValue}
                                        readOnly
                                        style={{
                                            fontSize: '19px',
                                            fontWeight: '600',
                                            color: '#333',
                                            backgroundColor: '#f0f0f0',
                                            border: '1px solid #ccc',
                                            padding: '8px',
                                            borderRadius: '4px',
                                            /* Add more styles as needed */
                                        }}
                                    />
                                    <h4 className="title">{t('NAME')}</h4>
                                    <p className="patient-name">{patientname}</p>
                                    <Button block type="primary" htmlType="submit" onClick={getPrint} className="btn btn-primary">
                                        {t('CONTINUE')}
                                        <img src={ArrowIcon} className="arrow-icon"></img>
                                    </Button>
                                    <Button block type="primary" htmlType="submit" onClick={handleCancelClick} className=" btn btn-secondary cancel-btn">
                                        {t('CANCEL')}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                        <div className="container_2">
                            <div className="logo_container">
                                <img src={logoimg} className="img" alt="logo_pic" />
                            </div>
                            <div className="health_container">
                                <img src={healthimg} className="health_img" alt="health_pic" />
                            </div>
                        </div>
                    </div>
                );
            case states.continue:
                return <Newstate inputValue={inputValue} />;
            default:
                return null;
        }
    };

    const imageSettings = {
        src: '',
        height: 1000,
        width: 1000,
        x: 40,
        y: 40,
        // excavate:true
    }

    return (
        <div className='kiosk-screen'>
            <div className="">{renderContent()}</div>
            {/* Print Block */}
            <div className="print-block-kiosk">
                <WrapperClass ref={print}>

                    <div className="print-content-kiosk">

                        {/* <p className='nura-id'>{patientname}</p> */}
                        <h1 className='nura-id'>{guest.op_no}</h1>
                        {guest && guest.op_no ? <QRcode value={guest.op_no} imageSettings={imageSettings} /> : null}

                    </div>


                </WrapperClass>
            </div>
            {/* Print Block ends */}
        </div>
    );
}
/**
 * Function for print
 */
class WrapperClass extends React.Component {
    render() {
        return <div>{this.props.children}</div>;
    }
}