import React, { useState, useEffect, useContext } from 'react';

import { Card, Space, Switch, Button, Typography, Table, Skeleton, Input, Tag } from 'antd';

import { Route, Link } from 'react-router-dom';

import { GlobalContext, Location, useTranslation } from 'soxo-bootstrap-core';

import moment from 'moment-timezone';

import './samples-list.scss';

import { RangePicker } from 'soxo-bootstrap-core';

import { ReloadOutlined, OrderedListOutlined, PicCenterOutlined } from '@ant-design/icons';
import Samples from '../../../../models/samples';

const { Title, Text } = Typography;

const { Search } = Input;

export default function LabTest({ match, redirect_link, title, sampleRecieved }) {
    var param = Location.search();

    const [range, setRange] = useState([
        moment.tz(param.start_time, process.env.REACT_APP_TIMEZONE).startOf('day'),
        moment.tz(param.end_time, process.env.REACT_APP_TIMEZONE).endOf('day'),
    ]);

    const { user = {}, isMobile } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);

    const { pageno } = match.params; //Get pagination number

    const [pageNo, setPageNo] = useState(pageno); // Pagination number

    const [patients, setPatients] = useState([]); //Patients list array

    var param = Location.search();

    var [query, setQuery] = useState('');

    const [view, setView] = useState(isMobile ? true : false); //Need to check this condition

    const [page, setPage] = useState(1);

    const [limit, setLimit] = useState(10);

    const { t, i18n } = useTranslation();   // To Translate to another language

    //PatiantData fetching function
    async function getPatientDetails(pageNo, range) {
        setLoading(true);

        Samples.getsamplelist(range, user).then((result) => {
            setPatients(result);
            // setPatients(jsonData[0]);

            setLoading(false);
        });
    }

    useEffect(() => {
        if (user) {
            getPatientDetails(pageNo, range); //Patiant list get function
        }
    }, [user]);

    function updateTime(dt, result) {
        setRange(dt);

        Location.search({
            start_time: moment(dt[0]).format('MM/DD/YYYY'),
            end_time: moment(dt[1]).format('MM/DD/YYYY'),
        });

        setQuery('');

        getPatientDetails(pageNo, dt); //Patiant list get function
    }

    function refresh() {
        getPatientDetails(pageNo, range);
    }
    function onSearch(event) {
        setQuery(event.target.value);
    }
    function changeView(result) {
        setView(result);
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => {
                return (page - 1) * limit + index + 1;
            },
        },

        {
            title: t('Name'),
            dataIndex: 'opb_name',
            // dataIndex: 'PName',
            key: 'opb_name',
        },

        {
            title: t('Date'),
            // dataIndex: 'Mobile',

            key: 'opb_tm',

            render: (record) => {
                return (
                    <>
                        <div>{moment.tz(record.opb_tm, '').format('DD/MM/YYYY')}</div>
                        <div>{moment.tz(record.opb_tm, '').format('hh:mm a')}</div>
                    </>
                );
            },
        },

        {
            title: t('OP No'),
            render: (record) => {
                if (record.opb_opno) {
                    return <Tag color="green">{record.opb_opno}</Tag>;
                }
            },

            // dataIndex: 'OpNo',
            key: 'opb_opno',
        },

        {
            title: t('Order No'),
            render: (record) => {
                if (record.opb_bno) {
                    return <Tag color="blue">{record.opb_bno}</Tag>;
                }
            },

            // dataIndex: 'OpNo',
            key: 'opb_bno',
        },

        {
            title: t('Age'),
            dataIndex: 'opb_age',
            key: 'opb_age',
        },
        {
            title: t('Gender'),
            render: (text, record) => {
                return (
                    <>
                        <Tag color={record.opb_gender === 'Male' ? 'blue' : 'pink'}> {record.opb_gender}</Tag>
                    </>
                );
            },
            // dataIndex: 'opb_gender',
            key: 'gender',
        },

        {
            title: t('Mobile'),
            // dataIndex: 'Mobile',
            dataIndex: 'opb_mobile',
            key: 'opb_mobile',
        },

        {
            title: '',
            key: 'action',
            fixed: 'right',

            render: (text, record) => {
                let redirectLink = `/sample-detail/:id`;

                if (redirect_link) {
                    redirectLink = redirect_link;
                }
                if (sampleRecieved) {
                    redirectLink = `/sample-details/:id`;

                    redirectLink = redirectLink.replace(':id', record.opb_id);
                } else redirectLink = redirectLink.replace(':id', record.opb_id);

                return (
                    <Space size="middle">
                        <Link to={`${redirectLink}`}>{t('View')}</Link>
                    </Space>
                );
            },
        },
    ];
    function callback(key) {
        console.log(key);
    }
    let dataSource = patients.filter((record) => {
        query = query.toUpperCase();

        if (query) {
            if (record.opb_name.toUpperCase().indexOf(query) != -1) {
                return true;
            } else if (record.opb_mobile && record.opb_mobile.indexOf(query) != -1) {
                return true;
            } else if (record.opb_opno && record.opb_opno.indexOf(query) != -1) {
                return true;
            }

            // }
        } else {
            return true;
        }
    });

    return (
        <section className="sample-list card card-shadow">
            {/* Page Header */}

            <div className="page-header">
                <div className="left">
                    {/* <Title style={{ marginBottom: '0px' }} level={3}>
                        {title}
                    </Title> */}

                    <Search
                        placeholder={t("Enter Search Value")}
                        allowClear
                        style={{ width: 300, marginTop: '10px', marginBottom: '20px' }}
                        onChange={onSearch}
                    />
                </div>

                <div className="right">
                    <div className="date-and-fltr">
                        <RangePicker
                            allowClear={false}
                            inputReadOnly
                            format={'DD/MM/YYYY'}
                            value={range}
                            onChange={(time) => {
                                updateTime(time, range);
                            }}
                            ranges={{
                                Today: [moment(), moment()],

                                Yesterday: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],

                                'This Week': [moment().startOf('week'), moment().endOf('week')],

                                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],

                                'This Month': [moment().startOf('month'), moment().endOf('month')],

                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                            }}
                        />

                        <Switch
                            defaultChecked={view}
                            onChange={changeView}
                            checked={view}
                            checkedChildren={<OrderedListOutlined />}
                            unCheckedChildren={<PicCenterOutlined />}
                        />
                        {/* 
                        <Button onClick={refresh} type="secondary" size={'small'}>
                            <ReloadOutlined />
                        </Button> */}
                    </div>
                </div>
            </div>
            {/* Page Header Ends */}

            {/* Content Below */}
            {loading ? (
                <>
                    <Skeleton active />
                </>
            ) : (
                <>
                    <div className="page-header">



                    </div>
                    <div>
                        {view ? (
                            <>
                                <CardList dataSource={dataSource} redirect_link={redirect_link} />
                            </>
                        ) : (
                            <>
                                <Card className="table-card">
                                    <Table
                                        scroll={{ x: true }}
                                        rowKey={(record) => record.da_id}
                                        dataSource={dataSource}
                                        columns={columns}
                                        pagination={{
                                            // current: page,
                                            onChange(current) {
                                                setPage(current);
                                            },
                                        }}
                                    />
                                </Card>
                            </>
                        )}
                    </div>

                    <div className="top-actions">
                        {dataSource.length ? (
                            <p className="size-hint">{dataSource.length} records.</p>
                        ) : (
                            <p className="size-hint">{patients.length} records.</p>
                        )}

                    </div>
                </>
            )}
        </section>
    );
}

//Mobile view card Section
function CardList({ dataSource, columns, url,redirect_link }) {
    const { user = {}, isMobile, dispatch } = useContext(GlobalContext);

    // function onClick(item) {
    //     Location.navigate({
    //         url: `/sample-details/${item.opb_id}`,
    //     });

    //     dispatch({ type: 'index', payload: item.rowIndex });
    // }

    return dataSource.map((item, index) => {
        let redirectLink;

        redirectLink = redirect_link.replace(':id', item.opb_id);
        return (
            <Link
            to={`${redirectLink}`}
                key={index}
                className="report-item"
                // onClick={() => {
                //     onClick(item);
                // }}
            >
                <GuestCard record={item} />
            </Link>
        );
    });
}

function GuestCard({ record, selected = {} }) {
    const { t, i18n } = useTranslation();   // To Translate to another language
    return (
        <Card className={`card ${selected.AccessionNo === record.AccessionNo ? 'selected' : ''}`}>
            <div className="">
                <p>
                    {' '}
                    <strong className=''> {record.opb_name}</strong>
                </p>
            </div>


            <div className='content-block'>
                <p>
                    <strong className=''>{record.opb_age}</strong>{' '}
                    {' '}
                    <Tag color={record.opb_gender === 'Male' || record.opb_gender === 'M' ? 'blue' : 'pink'}>
                        {record.opb_gender === 'M' || record.opb_gender === 'Male' ? 'Male' : 'Female'}
                    </Tag>{' '}
                </p>
            </div>
            <div className='content-block'>
                <p>
                    {' '}
                    {t('OP NO')} :{' '}
                    <Tag color="green">
                        {' '}
                        <strong className='result'>{record.opb_opno}</strong>
                    </Tag>
                </p>
            </div>

            <div className='content-block'>

                <p>
                    {t('Order')}:<strong className='result'>{record.opb_bno}</strong>{' '}
                </p>


            </div>
            {record.TechResult ? (
                <p>
                    {t('Tech Result')}: <strong className='result'>{record.TechResult}</strong>
                </p>
            ) : null}

            <div>
                <small>
                    {' '}
                    {t('Screened on')} <strong className='result'>{record.opb_tm}</strong>
                </small>
            </div>

        </Card>
    );
}
