/**
 * @description
 * @author Sneha
 *
 * Corporate-Master page to list customer, add new customers, edit customers
 */

import React, { useState, useEffect } from 'react';

import { Customers } from '../../../../models';

import moment from 'moment-timezone';

import { Editor } from '@tinymce/tinymce-react';

import { InputComponent, CountryPhoneInput, useTranslation, ExtraInfoDetail } from 'soxo-bootstrap-core';

import { isValidPhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js';

import { Skeleton, Button, Input, Form, Card, Table, Typography, Space, Checkbox, Switch, Modal, Row, Col, message, Select } from 'antd';

import { ReloadOutlined } from '@ant-design/icons';

import { Link } from 'react-router-dom';

const { Title, Text } = Typography;

const { Option } = Select;

const { TextArea, Search } = Input;

const formItemLayout = {
    labelCol: {
        // xs: { span:0 },
        // sm: { span: 0},
    },

    wrapperCol: {
        span: 50,
    },

    layout: 'vertical',
};

export default function CorporateMaster({
    cc_mode,
    extraInfo, // To manage extra info component
    modeValue, // To mension mode for script execution 
    title,  // To mension title of extra info
    icon // To mension icon
}) {

    const [customer, setCustomers] = useState([]);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [page, setPage] = useState(1);

    const [limit, setLimit] = useState(10);

    const [view, setView] = useState(false);

    const [loading, setLoading] = useState(true);

    //To disable feilds
    const [disabled, setDisabled] = useState(false);

    const [btnloading, setBtnloading] = useState(false);

    var [query, setQuery] = useState('');

    const [initial, setInitial] = useState({});

    const [edit, setEdit] = useState(false);

    //To disable all fields on view
    const [viewDisable, setViewDisable] = useState(false);

    const [content, setContent] = useState('');

    // To Translate to another language
    const { t, i18n } = useTranslation();

    useEffect(() => {
        getCustomers(cc_mode);
    }, []);

    /**
     *  Get Customers
     */

    function getCustomers(cc_mode) {
        Customers.getCustomers(cc_mode).then(result => {
            setCustomers(result.result);

            setLoading(false);
        });
    }
    /**
     *
     * @param {*} evt
     * @param {*} editor
     */
    const handleInit = (evt, editor) => {
        const editorContent = editor.getContent();

        setContent(editorContent);
    };

    /**
     *
     * @param {*} value
     * @param {*} editor
     * @param  {...any} props
     */

    const handleUpdate = (value, editor, ...props) => {
        const editorContent = editor.getContent();

        // editor.
        if (editor.hasFocus()) {
            setContent(editorContent);
        }
    };
    /**
     * Search function
     */

    function onSearch(event) {
        setQuery(event.target.value);
    }


    /**
     *
     * @param {*} formBody
     */
    const onSubmit = value => {
        setBtnloading(true);
        let values;
        let otherDetails;
        // Storing value of cc_otherdetail1 in  json format
        otherDetails = {
            idCardUpload: value.cc_otherdetail1,
        };
        // // Storing value of op_mobile to a variable
        // var mobile = value.cc_mobile;

        // //Storing country code length
        // var dialCodeLength = mobile.code.dialCode.length;

        //When Adding new customer
        if (!edit) {
            values = {
                ...value,
                // cc_otherdetail1: allowIdCard ? 'Y' : 'N',
                cc_otherdetail1: JSON.stringify(otherDetails),
                cc_styledcontent: content,
            };

            // set active defalut 
            if (!value.cc_active) {
                values = {
                    ...values,
                    cc_active: 'Y',
                };
            }

            Customers.addCustomer(values).then(() => {
                getCustomers(cc_mode);

                setBtnloading(false);

                handleOk();

                message.success('Corporate Added Successfully!');
            });
        } else {
            // // Storing value of op_mobile to a variable
            // var mobile = value.cc_mobile;

            // //Storing country code length
            // var dialCodeLength = mobile.code.dialCode.length;

            //In case of edit

            values = {
                ...value,
                // cc_otherdetail1: allowIdCard ? 'Y' : 'N',
                cc_otherdetail1: JSON.stringify(otherDetails),
                cc_styledcontent: content,
            };

            Customers.editCustomer(value.cc_code, values).then(() => {
                setBtnloading(false);

                handleOk();

                message.success('Corporate Edited Successfully!');
            });
        }
    };

    //add customer
    const addCustomer = () => {
        setEdit(false);

        setDisabled(false);

        setViewDisable(false);

        setInitial({});

        showModal();
    };

    //edit customer
    const getCustomerDetails = async code => {
        await getCustomerWithId(code);

        await showModal();
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);

        getCustomers(cc_mode);
    };

    /**
     * get customer with Id
     * @param {} result
     */

    async function getCustomerWithId(code) {
        // Getting customer with code
        var result = await Customers.getCustomerWithId(code);
        let customerOtherDetails;
        // set values to allow id card select box 
        if (result.result.cc_otherdetail1) {
            customerOtherDetails = JSON.parse(result.result.cc_otherdetail1);
            result = {
                ...result.result,
                cc_otherdetail1: customerOtherDetails.idCardUpload,
            };
        } else if (result.result.cc_code) {
            result = {
                ...result.result,
            };
        }

        // Set as initial values of form
        setInitial(result);

        // setContent(result.cc_styledcontent)
    }

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    var validationProps = [
        {
            required: true,

            message: t('Please Enter Code'),
        },

        {
            max: 10,

            message: t(' Length cannot exceed 10 characters!'),
        },

        {
            pattern: /^[ A-Za-z0-9]*$/,

            message: t(' Please Enter valid Code!'),
        },
    ];

    //To check if entered customer code is unique
    const checkCode = async (code, callback) => {
        const result = await Customers.checkCode(code, cc_mode);

        if (result.data == null) {
            return true;
        } else {
            return false;
        }
    };

    /**
     *  columns
     */
    const Customercolumns = [
        ...[
            {
                title: '#',

                dataIndex: 'index',

                render: (value, item, index) => {
                    return (page - 1) * limit + index + 1;
                },
            },
        ],

        {
            title: t('Sl No.'),

            key: 'cc_slno',

            render: record => {
                return record.cc_slno;
            },
        },

        {
            title: t('Code'),

            key: 'cc_code',

            render: record => {
                return record.cc_code;
            },
        },

        {
            title: t('Name'),

            key: 'cc_desc',

            width: '15px',

            render: record => {
                return record.cc_desc;
            },
        },

        {
            title: t('Address 1'),

            key: 'cc_add1',

            render: record => {
                return record.cc_add1;
            },
        },

        {
            title: t('Address 2'),

            key: 'cc_add1',

            render: record => {
                return record.cc_add2;
            },
        },

        {
            title: t('Place'),

            key: 'cc_place',

            render: record => {
                return record.cc_place;
            },
        },

        {
            title: t('Zip Code'),

            key: 'cc_zip',

            render: record => {
                return record.cc_zip;
            },
        },

        {
            title: t('Phone'),

            key: 'cc_phone',

            render: record => {
                return record.cc_phone;
            },
        },

        {
            title: t('Fax'),

            key: 'cc_fax',

            render: record => {
                return record.cc_fax;
            },
        },

        {
            title: t('Register No. 1'),

            key: 'cc_regno1',

            render: record => {
                return record.cc_regno1;
            },
        },

        {
            title: t('Register No. 2'),

            key: 'cc_regno2',

            render: record => {
                return record.cc_regno2;
            },
        },

        {
            title: t('Contact Person'),

            key: 'cc_contactper',

            render: record => {
                return record.cc_contactper;
            },
        },

        {
            title: t('Designation'),

            key: 'cc_cntperdesg',

            render: record => {
                return record.cc_cntperdesg;
            },
        },

        {
            title: t('Mobile'),

            key: 'cc_mobile',

            render: record => {
                return record.cc_mobile;
            },
        },

        {
            title: t('Account Code'),

            key: 'cc_acpostcode',

            render: record => {
                return record.cc_acpostcode;
            },
        },

        {
            title: t('Active'),

            key: 'cc_active',

            render: record => {
                return record.cc_active;
            },
        },

        {
            title: t('Remarks'),

            key: 'cc_remarks',

            render: record => {
                return record.cc_remarks;
            },
        },

        {
            title: t('Date'),

            key: 'cngd_dt',

            render: record => {
                return moment(record.cngd_dt).format('DD/MM/YYYY');
            },
        },

        {
            title: t('Mode'),

            key: 'cc_mode',

            render: record => {
                return record.cc_mode;
            },
        },

        {
            title: t('Customer Type'),

            key: 'cc_type',

            render: record => {
                return record.cc_type;
            },
        },

        {
            title: t('Eye'),

            // key: 'cc_type',

            render: ele => {

                return (
                    <div>
                        {/*Extra Info component start  */}
                        {extraInfo ? (
                            <ExtraInfoDetail
                                {...ele}
                                // record={urlParams} 
                                modeValue={modeValue}
                                title={title}
                                icon={icon}
                            />

                        ) : null}
                        {/*Extra Info component end  */}

                    </div>
                );

            },
        },

        {
            title: '',

            key: 'action',

            fixed: 'right',

            render: record => {
                function onClick() { }

                return (
                    <Space size="middle">
                        <Link
                            onClick={() => {
                                setEdit(false);

                                setDisabled(false);

                                setViewDisable(true);

                                getCustomerDetails(record.cc_code);
                            }}
                        >
                            {t('View')}
                        </Link>
                    </Space>
                );
            },
        },

        {
            title: '',
            key: 'action',
            fixed: 'right',

            render: record => {
                function onClick() { }

                return (
                    <Space size="middle">
                        <Link
                            onClick={async () => {
                                setEdit(true);

                                setDisabled(true);

                                setViewDisable(false);

                                await getCustomerDetails(record.cc_code);
                            }}
                        >
                            {t('Edit')}
                        </Link>
                    </Space>
                );
            },
        },
    ];

    // Defining Data source for passing to list
    var dataSource = customer.filter(item => {
        query = query.toUpperCase();

        if (query) {
            if (item.cc_desc.toUpperCase().indexOf(query) != -1) {
                return true;
            }
        } else {
            return true;
        }
    });

    //In case of edit, validation of code is not required
    if (!edit) {
        validationProps.push({
            //Custom validation
            validator(rule, value) {
                return new Promise(async (resolve, reject) => {
                    //Check if code is unique
                    const result = await checkCode(value);

                    //if result returns false this message is shown
                    if (!result) {
                        reject('Please enter unique code');
                    } else {
                        resolve();
                    }
                });
            },
        });
    }

    return (
        <div className="card card-shadow">
            {loading ? (
                <Skeleton />
            ) : (
                <>
                    <div className="page-header">
                        <div className="left">
                            {/* <Title level={3}>Corporate</Title> */}

                            <Search
                                placeholder={t("Enter Search Value")}
                                allowClear
                                style={{ width: 300, marginTop: '10px', marginBottom: '20px' }}
                                onChange={onSearch}
                            />
                        </div>

                        <div className="table-actions right">
                            <div className="button-container">
                                <Space size="small">
                                    <Button onClick={getCustomers} size={'small'}>
                                        <ReloadOutlined />
                                    </Button>

                                    <Button type="primary" size="small" onClick={addCustomer}>
                                        {t('Add')}
                                    </Button>
                                </Space>
                            </div>
                        </div>
                    </div>

                    <div className="table-card">
                        <Table
                            dataSource={dataSource}
                            scroll={{ x: true }}
                            columns={Customercolumns}
                            pagination={{
                                onChange(current) {
                                    setPage(current);
                                },
                            }}
                        />
                    </div>
                </>
            )}

            {/** Modal For Add New Customer  Start */}
            <Modal
                title={t("Corporate Master")}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                destroyOnClose
                footer={[]}
                width={'80%'}
            >
                <div>
                    <Card className="card">
                        <Form
                            {...formItemLayout}
                            initialValues={{
                                ...initial,

                                cc_mode: cc_mode,
                            }}
                            onFinish={onSubmit}
                        >
                            <Row gutter={20}>
                                <Col xs={12} md={12} xl={12}>
                                    <Form.Item
                                        name="cc_code"
                                        label={t("Code")}
                                        // rules={[

                                        //     {
                                        //         pattern: /^[0-9]*$/g,

                                        //         message: 'Please Enter Number',
                                        //     },
                                        // ]}

                                        rules={validationProps}
                                        hasFeedback
                                    >
                                        <InputComponent placeholder={t("Enter Code")} disabled={viewDisable || disabled} />
                                    </Form.Item>
                                </Col>

                                <Col xs={12} md={12} xl={12}>
                                    <Form.Item name="cc_mode" label={t("Mode")}>
                                        <InputComponent placeholder={t("Enter mode")} disabled={viewDisable || true} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col xs={24} md={24} xl={24}>
                                    <Form.Item
                                        name="cc_desc"
                                        label={t("Name")}
                                        rules={[
                                            {
                                                required: true,

                                                message: t('Please Enter Name!'),
                                            },

                                            {
                                                pattern: /^[a-zA-Z0-9][\s\w]*$/g,

                                                message: t('Please Enter Valid Name!'),
                                            },

                                            {
                                                max: 50,

                                                message: t('Length cannot exceed 50 characters !'),
                                            },
                                        ]}
                                    >
                                        <InputComponent placeholder={t("Enter Name")} disabled={viewDisable} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col xs={24} md={24} xl={24}>
                                    <Form.Item
                                        name="cc_add1"
                                        label={t("Address 1")}
                                        rules={[
                                            {
                                                required: false,

                                                message: t('Please Enter Address '),
                                            },

                                            {
                                                max: 100,

                                                message: t('Length cannot exceed 100 characters !'),
                                            },

                                            {
                                                pattern: /^[ A-Za-z0-9_.,()&-]*$/,

                                                message: t('Enter Valid Address !'),
                                            },
                                        ]}
                                    >
                                        <InputComponent placeholder={t("Enter Address")} disabled={viewDisable} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col
                                    xs={24}
                                    md={24}
                                    xl={24}
                                    rules={[
                                        {
                                            required: false,

                                            message: t('Please Enter Address'),
                                        },

                                        {
                                            max: 100,

                                            message: t('Length cannot exceed 100 characters !'),
                                        },

                                        {
                                            pattern: /^[ A-Za-z0-9_.,()&-]*$/,

                                            message: t('Enter Valid Address !'),
                                        },
                                    ]}
                                >
                                    <Form.Item name="cc_add2" label={t("Address 2")}>
                                        <InputComponent placeholder={t("Enter Address")} disabled={viewDisable} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col xs={12} md={12} xl={12}>
                                    <Form.Item
                                        name="cc_place"
                                        label={t("Place")}
                                        rules={[
                                            {
                                                required: false,

                                                message: t('Please Enter Address'),
                                            },

                                            {
                                                max: 50,

                                                message: t('Length cannot exceed 50 characters !'),
                                            },

                                            {
                                                pattern: /^[ A-Za-z0-9_.,()&-]*$/,

                                                message: t('Enter Valid Address !'),
                                            },
                                        ]}
                                    >
                                        <InputComponent placeholder={t("Enter Place")} disabled={viewDisable} />
                                    </Form.Item>
                                </Col>

                                <Col xs={12} md={12} xl={12}>
                                    <Form.Item
                                        name="cc_zip"
                                        label={t("Zip Code")}
                                        rules={[
                                            {
                                                required: false,
                                            },

                                            {
                                                max: 10,

                                                message: t('Length cannot exceed 10 characters !'),
                                            },

                                            {
                                                pattern: /(^\d{6,7}$)|(^\d{5}-\d{4}$)/,

                                                message: t('Please enter valid Zip Code '),
                                            },
                                        ]}
                                    >
                                        <InputComponent placeholder={t("Enter Zip Code")} disabled={viewDisable} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col xs={12} md={12} xl={12}>
                                    <Form.Item
                                        name="cc_phone"
                                        label={t("Phone")}
                                        disabled={viewDisable}
                                        rules={[
                                            {
                                                pattern: /^[\d+ ]+$/g,

                                                message: t('Please Enter Mobile !'),
                                            },
                                        ]}
                                    >
                                        <InputComponent placeholder={t("Enter Phone")} disabled={viewDisable} />
                                    </Form.Item>
                                </Col>

                                <Col xs={12} md={12} xl={12}>
                                    <Form.Item
                                        name="cc_fax"
                                        label={t("Fax")}
                                        rules={[
                                            {
                                                max: 25,

                                                message: t('Length cannot exceed 25 characters !'),
                                            },

                                            {
                                                pattern: /^[0-9]*$/g,

                                                message: t('Please Enter Fax'),
                                            },
                                        ]}
                                    >
                                        <InputComponent placeholder={t("Enter Fax")} disabled={viewDisable} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col xs={12} md={12} xl={12}>
                                    <Form.Item
                                        disabled={viewDisable}
                                        name="cc_regno1"
                                        label={t("Reg No. 1")}
                                        rules={[
                                            {
                                                max: 50,

                                                message: t('Length cannot exceed 50 characters !'),
                                            },

                                            {
                                                pattern: /^[a-zA-Z0-9]*$/g,

                                                message: t('Please Enter Reg No.'),
                                            },
                                        ]}
                                    >
                                        <InputComponent placeholder={t("Enter Register No. 1")} disabled={viewDisable} />
                                    </Form.Item>
                                </Col>

                                <Col xs={12} md={12} xl={12}>
                                    <Form.Item
                                        name="cc_regno2"
                                        label={t("Reg No. 2")}
                                        rules={[
                                            {
                                                max: 50,

                                                message: t('Length cannot exceed 50 characters !'),
                                            },

                                            {
                                                pattern: /^[a-zA-Z0-9]*$/g,

                                                message: t('Please Enter Reg No.'),
                                            },
                                        ]}
                                    >
                                        <InputComponent placeholder={t("Enter Register No. 2")} disabled={viewDisable} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col xs={24} md={24} xl={24}>
                                    <Form.Item
                                        name="cc_contactper"
                                        label={t("Contact Person")}
                                        rules={[
                                            {
                                                max: 50,

                                                message: t('Length cannot exceed 50 characters !'),
                                            },

                                            {
                                                pattern: /^[a-zA-Z\s]*$/g,

                                                message: t('Please Enter Valid Name!'),
                                            },
                                        ]}
                                    >
                                        <InputComponent placeholder={t("Enter Contact person")} disabled={viewDisable} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col xs={24} md={24} xl={24}>
                                    <Form.Item
                                        name="cc_cntperdesg"
                                        label={t("Designation")}
                                        rules={[
                                            {
                                                max: 50,

                                                message: t('Length cannot exceed 50 characters !'),
                                            },

                                            {
                                                pattern: /^[a-zA-Z\s]*$/g,

                                                message: t('Please Enter Valid Name!'),
                                            },
                                        ]}
                                    >
                                        <InputComponent placeholder={t("Enter Designation")} disabled={viewDisable} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col xs={24} md={24} xl={24}>
                                    <Form.Item
                                        name="cc_mobile"
                                        label={t("Mobile")}
                                        rules={[
                                            {
                                                pattern: /^[\d+ ]+$/g,

                                                message: t('Please Enter Mobile !'),
                                            },
                                        ]}

                                    // rules={[
                                    //     { required: true, message: 'Please Enter Mobile Number' },
                                    //     () => ({
                                    //         validator(_, value) {
                                    //             var code = value.code.countryCode.toUpperCase();

                                    //             // Check if the number is valid
                                    //             if (isValidPhoneNumber(value.value, code)) {
                                    //                 return Promise.resolve();
                                    //             } else {
                                    //                 // Returns the reason for the number to be invalid,mostly TOO SHORT
                                    //                 var errorMessage = validatePhoneNumberLength(value.value, code);

                                    //                 if (errorMessage) {
                                    //                     return Promise.reject(errorMessage);
                                    //                 } else {
                                    //                     return Promise.reject('Invalid Number');
                                    //                 }
                                    //             }
                                    //         },
                                    //     }),
                                    // ]}
                                    >
                                        {/* <CountryPhoneInput disabled={viewDisable} /> */}

                                        <InputComponent placeholder={t("Enter Mobile Number")} disabled={viewDisable} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col xs={12} md={12} xl={12}>
                                    <Form.Item
                                        name="cc_acpostcode"
                                        label={t("Account Code")}
                                        rules={[
                                            {
                                                pattern: /^[a-zA-Z0-9][\s\w]*$/g,

                                                message: t('Please Enter Valid Account Code!'),
                                            },

                                            {
                                                max: 25,

                                                message: t('Length cannot exceed 25 characters !'),
                                            },
                                        ]}
                                    >
                                        <InputComponent placeholder={t("Enter Account code")} disabled={viewDisable} />
                                    </Form.Item>
                                </Col>

                                <Col xs={12} md={12} xl={12}>
                                    {/* <Form.Item name="cc_mode" label="Mode">

                                        <InputComponent placeholder="Enter mode" disabled={viewDisable || true} />

                                    </Form.Item> */}
                                    <Form.Item
                                        name="cc_slno"
                                        label={t("Sl No.")}
                                        rules={[
                                            {
                                                pattern: /^[a-zA-Z0-9][\s\w]*$/g,

                                                message: t('Please Enter Valid Sl No. !'),
                                            },
                                        ]}
                                    >
                                        <InputComponent placeholder={t("Enter Sl No.")} disabled={viewDisable} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col xs={12} md={12} xl={12}>
                                    <Form.Item
                                        name="cc_type"
                                        label={t("Customer Type")}
                                        placeholder={t("Enter Customer type")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('Please Enter Customer type'),
                                            },
                                        ]}
                                    >
                                        <Select disabled={viewDisable || disabled}>
                                            <Option value="PYR">{t("PAYER")}</Option>

                                            <Option value="TPA">{t("TPA")}</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col xs={12} md={12} xl={12}>
                                    <Form.Item name="cc_active" label={t("Active")}>
                                        <Select disabled={viewDisable} defaultValue={'Y'}>
                                            <Option value="Y">{t("Yes")}</Option>

                                            <Option value="N">{t("No")}</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            {/* MND => Mandatory OPT => Optional NAP => Not Applicable */}
                            <Row gutter={20}>
                                <Col xs={12} md={12} xl={12}>
                                    <Form.Item
                                        name="cc_otherdetail1"
                                        label={t("Allow ID Card")}
                                        rules={[
                                            {
                                                required: true,

                                                message: t('Please Enter Id card Info!'),
                                            },
                                        ]}
                                    >
                                        {/* <Checkbox onChange={onChange} checked={allowIdCard} defaultChecked={allowIdCard} /> */}
                                        <Select disabled={viewDisable}>
                                            <Option value="MND">{t("Mandatory")}</Option>

                                            <Option value="OPT">{t("Optional")}</Option>
                                            <Option value="NAP">{t("Not Applicable")}</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col xs={24} md={24} xl={24}>
                                    <Form.Item
                                        name="cc_remarks"
                                        label={t("Remarks")}
                                        rules={[
                                            {
                                                max: 250,

                                                message: t('Length cannot exceed 250 characters !'),
                                            },

                                            {
                                                pattern: /^[\w\s\S]*$/,

                                                message: t('Please Enter Valid Remarks !'),
                                            },
                                        ]}
                                    >
                                        <InputComponent placeholder={t("Enter remarks")} disabled={viewDisable} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            {/** Editor Section Start  */}
                            <Row gutter={20}>
                                <Col xs={24} md={24} xl={24}>
                                    <Form.Item name="cc_styledcontent" label={t("Notes")}>
                                        <Editor
                                            apiKey="y7bj7rt02vqi84dggp8vydsl2tu4e21rzomlej8at6ynx9ja"
                                            initialValue={initial.cc_styledcontent}
                                            value={content}
                                            onInit={handleInit}
                                            onEditorChange={handleUpdate}
                                            init={{
                                                // selector: 'textarea',
                                                height: 500,
                                                //  menubar: false,
                                                // menubar: 'insert',
                                                paste_block_drop: false,
                                                plugins: [
                                                    // 'paste',
                                                    'template',
                                                    // 'advlist autolink lists link image charmap print preview anchor paste',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount',
                                                ],
                                                toolbar:
                                                    'template | undo redo | formatselect | ' +
                                                    'bold italic backcolor | alignleft aligncenter ' +
                                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                                    'removeformat | help',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                                template_cdate_classes: 'cdate creationdate',
                                                template_mdate_classes: 'mdate modifieddate',
                                                template_selected_content_classes: 'selcontent',
                                                template_cdate_format: '%m/%d/%Y : %H:%M:%S',
                                                template_mdate_format: '%m/%d/%Y : %H:%M:%S',
                                                // template_replace_values: {
                                                //     fullName: 'Jack Black',
                                                //     opno: '991234',
                                                // },
                                                // templates: templates,
                                            }}
                                        />
                                        {/* <TextArea placeholder="Enter Notes" /> */}
                                    </Form.Item>
                                </Col>
                            </Row>
                            {/** Editor Section End  */}
                            {/* <Row gutter={20}>
                                <Col xs={24} md={24} xl={24}>
                                    <Form.Item name="cngd_dt" label="Date">
                                        <InputComponent placeholder="Enter date" />
                                    </Form.Item>
                                </Col>
                            </Row> */}
                            <Form.Item>
                                <div>
                                    <Button loading={btnloading} type="primary" htmlType="submit" disabled={viewDisable}>
                                        {t("Save")}
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </Modal>

            {/** end  */}
        </div>
    );
}
