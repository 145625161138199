/**
 * Component to Show Formula Calculation
 *
 *
 * @author Nihala Dilshi
 * 
 * @author Ashique
 * 
 */

import React, { useState, useEffect } from 'react';

import { Appointments } from '../../models';

import { useTranslation } from 'soxo-bootstrap-core';

import { Table, Card, Button, Modal, Select, Form, } from 'antd';

import './formula.scss';

const { Option } = Select;


export default function Formula({ code, caption, opbill, inputParameters, item = {}, callback }) {


    // Variable to control the open/close of the modal
    const [visible, setVisible] = useState(false);

    useEffect(() => {

    }, []);

    /**
     * Toggle the modal
     */
    const viewFormula = () => {

        setVisible(true);
    }
    const applyFormula = (value) => {
        callback(value);
    }
    const { t, i18n } = useTranslation(); // To Translate to another language

    return (
        <>

            <Button size="small" class="btn" onClick={viewFormula}>
                <i class="fa fa-calculator"></i> {caption}
            </Button>

            {/* Modal for Viewing Formula calculation */}
            <>
                <Modal
                    width={'70%'}
                    destroyOnClose={true}
                    footer={null}
                    title={t("CALCULATOR")}
                    active={visible}
                    visible={visible}
                    okText="Okay"
                    onOk={() => {
                        setVisible(false)
                    }}
                    onCancel={() => {
                        setVisible(false)
                    }}
                >
                    <FormulaCalculator
                        code={code}
                        guest={opbill}
                        inputParameters={inputParameters}

                        callback={(values) => {
                            setVisible(false)
                            applyFormula(values);
                        }}

                        // creatinine_value={itemValue}
                        item={item}
                    />
                </Modal>
            </>
        </>
    );
}

/**
 * Formula Calculator
 * 
 * @param {*} param0 
 * @returns 
 */
function FormulaCalculator({ code, guest = {}, inputParameters = {}, callback }) {

    // Variable to hold the formula and its results
    const [results, setResults] = useState([]);
    const [value, setValue] = useState();

    const { t, i18n } = useTranslation(); // To Translate to another language


    useEffect(() => {

        loadFormula(code);

    }, [])

    /**
     * Load the formula for the code
     * 
     * @param {*} code 
     */
    async function loadFormula(code) {

        // Get the fomula for the particular code
        const formula = await Appointments.getFormula(code);

        // Prepare the values as per the formula
        let results = await prepareFormula(formula, inputParameters);

        // Set the results
        console.log(results);

        console.log(inputParameters);

        setResults(results);
    }

    /**
     * Replace the formala with input parameters
     * 
     * @param {*} record 
     * @param {*} inputParameters 
     * @returns 
     */
    async function prepareFormula(record, inputParameters) {

        // Variable to store value for table
        let result = [];

        if (guest.opb_gender === 'Male' || guest.opb_gender === 'M') {

            gender = 'Male';

            result.push({
                ...record,
                gen: (record.formula.root.param[0].gender),
                age_from: (record.formula.root.param[0].agefrom),
                age_to: (record.formula.root.param[0].ageto),
                formula_calc: (record.formula.root.param[0].formula),
                result_caption: (record.formula.root.param[0].resultcaption),
                result_code: (record.formula.root.param[0].resultcode)
            })

        } else {

            gender = 'Female';

            result.push({
                ...record,
                gen: (record.formula.root.param[1].gender),
                age_from: (record.formula.root.param[1].agefrom),
                age_to: (record.formula.root.param[1].ageto),
                formula_calc: (record.formula.root.param[1].formula),
                result_caption: (record.formula.root.param[1].resultcaption),
                result_code: (record.formula.root.param[1].resultcode)
            })
        }

        // Take the first formula
        let formula = result[0];

        // Base Formula
        let baseFormula = formula['formula_calc'][0];

        // For each of the parameter we have to arrive and replace the result
        inputParameters.forEach((parameter) => {

            // For each of the result
            // result.forEach((entry) => {

            // If there are result we have to replace the variables
                if ((parameter.values && parameter.values.result) || (parameter.lbtrsd_result)) {

                    // The values to be replaced
                    let values = (parameter.values && parameter.values.result) || (parameter.lbtrsd_result)
                    baseFormula = baseFormula
                        // Replace the results
                        .replace(
                            `@${parameter.lbtr_code};`, values

                        )
                }
            
            // Age
            if (guest.opb_age) {

                baseFormula = baseFormula
                    // Replace the results
                    .replace(
                        '@age_years;', (guest.opb_age).split(' ')[0]
                    )
            }
            // The formula calculation
            baseFormula = baseFormula
                // Replace the age
                // ^ is not supported in javascript so we replace with **
                .replace(
                    '^', '**'
                )

            // The formula calculation
            let arrivedFormula = baseFormula
                // Replace the age
                // ^ is not supported in javascript so we replace with **
                .replace(
                    '^', '**'
                )

            try {

                // Evalulate the value for final output
                formula['value'] = Math.trunc(eval(arrivedFormula))
                // state to save eGFR value
                setValue(formula['value'])


            } catch (error) {

            }

            // })
        })

        return result;
    }


    // if (creatinine_value) {
    //     updateResult(creatinine_value);
    // }

    let gender;

    /**
    * Calculate Formula By value and age 
    */
    // function updateResult(val) {

    //     if (val) {
    //         let form_calc, a, b, c

    //         let age = (guest.opb_age).split(' ');

    //         a = Math.pow(val, (-1.154))

    //         b = Math.pow((age[0]), (-0.203))

    //         if (guest.opb_gender === 'Female') {
    //             // 175*(@LP39;^(-1.154))*(@age_years;^(-0.203))*0.742
    //             c = ((175) * (a) * (b) * (0.742))
    //         }
    //         else {
    //             // 175*(@LP39;^(-1.154))*(@age_years;^(-0.203))
    //             c = ((175) * (a) * (b))
    //         }

    //         form_calc = Math.trunc(c)
    //         setValue(form_calc)
    //     }
    // }

    // Columns of the formula that need to be displayed
    const formulaColumns = [

        {
            title: '#',
            render: ele => {
                return <div>{ele.slno}</div>;
            },
            key: 'slno',
        },

        {
            title: t('Result Code'),
            render: ele => {

                return <div>{ele.result_code}</div>;
            },
            key: 'result_code',
        },
        {
            title: t('Result Caption'),
            render: ele => {
                return <div>{ele.result_caption}</div>;
            },
            key: 'result_caption',
        },


        {
            title: t('Gender'),
            render: ele => {
                return <div>{ele.gen}</div>;
            },
            key: 'gender',
        },

        {
            title: t('Age From'),
            render: ele => {
                return <div>{ele.age_from}</div>;
            },
            key: 'age_from',
        },
        {
            title: t('Age To'),
            render: ele => {
                return <div>{ele.age_to}</div>;
            },
            key: 'age_to',
        },
        {
            title: t('Formula'),
            render: ele => {
                return <div>{ele.formula_calc}</div>;
            },
            key: 'formula',
        },
        {
            title: t('Value'),
            render: ele => {
                return (

                    <div className="formula">
                        <div className="background-style">{ele.value} </div>
                    </div>
                );
            },
            key: 'result',
        },
    ];


    const applyFormula = () => {
        callback(value);
    }


    const cancelFormula = () => {
        callback()
    }

    return (<>

        <div className="detail-element">
            <div className="top-section">
                <div className="summary-bottom" style={{ width: '30%' }}>
                    <div className="right">
                        <Form layout="vertical">
                            <Form.Item name="selected"
                                rules={[{ required: true, message: t('Please select value type ') }]}
                                label={t("VALUE TYPE")}>
                                <div className=''>
                                    <Select
                                        value={'eGFR'}
                                        allowClear
                                        style={{ width: '60%' }}
                                    >
                                        <Option value={'eGFR'}>{t('eGFR')}</Option>
                                    </Select>
                                </div>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>


            <Card className="table-card">
                <Table
                    scroll={{ x: true }}
                    rowKey={(record, key) => key}
                    pagination={false}
                    dataSource={results}
                    columns={formulaColumns}

                />
            </Card>

            <div>
                <Button
                    size="small"
                    type="primary"
                    onClick={applyFormula}

                >
                    {' '}
                    {t('Apply')}
                </Button>

                <Button
                    style={{ marginLeft: '20px' }}
                    size="small"
                    type="secondary"
                    onClick={cancelFormula}

                >
                    {' '}
                    {t('Cancel')}
                </Button>
            </div>
        </div>


    </>)

}



// private void CalculateParamValues(DataTable _dtData)
// {
//     try {
//                 string strCalc = "";
//         foreach(DataRow dr in _dtData.Rows)
//         {
//             strCalc = mCommFuncs.ConvertToString(dr["formula"]).Trim().Replace(" ", "");
//             if (SourceDataTable != null && SourceDataTable.Rows.Count > 0) {
//                 #region Age
//                 strCalc = strCalc.ToLower().Replace("@age_years;", Age_Years.ToString().Trim());
//                 strCalc = strCalc.ToLower().Replace("@mge_months;", Age_Months.ToString().Trim());
//                 strCalc = strCalc.ToLower().Replace("@age_days;", Age_Days.ToString().Trim());
//                 #endregion

//                 foreach(DataRow drInputParams in SourceDataTable.Rows)
//                 {
//                     strCalc = strCalc.ToLower().Replace("@" + mCommFuncs.ConvertToString(drInputParams["paramcode"]).Trim().ToLower() + ";", mCommFuncs.ConvertToString(drInputParams["resultvalue"]).Trim());
//                 }
//             }
//                     ExpressionParser parser = new ExpressionParser();
//                     double result = parser.Parse(strCalc, null);
//             txtResult.Text = result.ToString(); // output;
//             dr["Value"] = result;
//         }
//         //dgvFormulaParam.DataSource = _dtData;
//         //dgvFormulaParam.Refresh();
//     }
//     catch (Exception ex) { MessageBox.Show("Formula calculation failed." + Environment.NewLine + ex.Message); }
// }