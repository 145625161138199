/**
 * Message Template
 *
 *
 *
 *
 */

import React from 'react';

import Model from './models';

import { ApiUtils, BaseAPI } from 'soxo-bootstrap-core';

class MessageTemplate extends BaseAPI {


    get id() {
        return 'mt_code';
    }

    get getEndpoint() {
        return 'msg-template';
    }

    get path() {
        return `msg-template`;
    }

    get getName() {
        return this.modelName;
    }

    get modelName() {
        return `msg-template`;
    }
    /**
       *Get templates
       * @param {*} 
       * @returns
       */
    getMsgTemplate = ({ formBody }) => {
        return ApiUtils.post({ url: `msg-template/get-template-data`, formBody });
    };
}

export default MessageTemplate;