/**
 * Component to display trends data fetched from an API in a modal window.
 *
 * @param {string} reference_id - The reference ID used to fetch trends data.
 */

import React, { useEffect, useState } from 'react';

import { Modal, Button, Skeleton, Form, Row, Col } from 'antd';
import { ReferenceSelect, Card, useTranslation } from 'soxo-bootstrap-core';
import { Bills, CoreScripts } from '../../../models';

import './trends.scss';

const formItemLayout = {
    layout: 'vertical',
};
export function Trends({ reference_id }) {
    // State to manage the visibility of the modal
    let [visible, setVisible] = useState(false);

    let [loading, setLoading] = useState(false);

    // State to store the fetched trends data
    var [trends, settrends] = useState([]);

    var [data, setData] = useState({});

    const { t, i18n } = useTranslation(); // To Translate to another language

    //state to store the fetched
    const [form] = Form.useForm();

    // Effect hook to load trends data when the component mounts
    useEffect(() => {
        // loadTrends(bill_id,mode,submode);
    }, []);

    /**
     * Function to fetch trends data from the API
     */
    function loadTrends(reference_id, record) {
        setLoading(true);
        Bills.loadTrends(reference_id, record).then((result) => {
            setLoading(false);

            settrends(result.result);
        });
    }
    /**
     * On change of Category items should update its values
     * @param {*} value
     */

    function handleChange(record) {
        setData(record);
        // loadTrends(reference_id, record);
    }

    /**
     * Onfinish of preview button
     */

    function Onfinish() {
        loadTrends(reference_id, data);
    }

    // Render the component
    return (
        <div className="trends">
            <div className="paga-actions">
                <Button
                    type="primary"
                    size={'small'}
                    onClick={() => {
                        setVisible(true);
                    }}
                >
                    {t('Predictive AI')}
                </Button>
            </div>

            <Modal
                destroyOnClose={true}
                visible={visible}
                footer={null}
                title={t("Predictive AI")}
                className="doctor-notes-trends"
                onCancel={() => {
                    setVisible(false);
                }}
                width={'100%'}
                maskClosable={false}
            >
                {loading ? (
                    <div className="">
                        <Skeleton active />
                    </div>
                ) : (
                    <div className="trends-details">
                        <div className="top-section">
                            <Card className="card-shadow card">
                                <div>
                                    <Form {...formItemLayout} form={form} onFinish={Onfinish}>
                                        <Row gutter={20}>
                                            <Col xs={24} md={12} xl={6}>
                                                <Form.Item label={t("Mode")} name="caption">
                                                    <ReferenceSelect
                                                        config={{
                                                            queries: [
                                                                {
                                                                    field: 'mode',
                                                                    value: 'NPA',
                                                                },
                                                            ],
                                                        }}
                                                        field={"id"}
                                                        label={t("caption")}
                                                        mode="nura-base"
                                                        model={CoreScripts}
                                                        style={{ width: '100%' }}
                                                        onChange={(value, options) => handleChange(options.record)}
                                                        placeholder={t("Select")}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={12} xl={6}>
                                                <Form.Item>
                                                    <Button
                                                        // onClick={(ele)=>{handleChange(ele)}}

                                                        className="preview-button"
                                                        type="primary"
                                                        htmlType="submit"
                                                    >
                                                        {t('Preview')}
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        {/* <Form.Item> */}

                                        {/* </Form.Item> */}
                                    </Form>
                                </div>
                            </Card>
                        </div>

                        <>
                            <div className="bottom-section">
                                {trends && trends.content ? <div dangerouslySetInnerHTML={{ __html: trends.content }}></div> : null}
                            </div>
                        </>
                    </div>
                )}
            </Modal>
        </div>
    );
}
