/**
 * OPvisit  Model
 *
 *
 * To EMR screen
 *
 */

import Model from './models';

import { ApiUtils } from 'soxo-bootstrap-core';

import moment from 'moment-timezone';

class Opvisit extends Model {
    constructor() {
        super();

        this.fields = [
            {
                field: 'name',
                caption: 'Name',
            },
        ];
    }

    get id() {
        return 'cold_id';
    }

    get getEndpoint() {
        return 'opreg';
    }

    get modelName() {
        return `collection`;
    }

    get columns() {
        return [
            {
                caption: 'Staff',
                field: 'staff.name',
                key: 'staff',
            },
            {
                caption: 'Weight',
                field: 'weight',
                key: 'weight',
            },
            {
                caption: 'Date',
                field: 'created_at',
                key: 'created_at',
            },
        ];
    }

    /**
     * candidate visit list
     * @param {*} range
     * @returns
     */

    getCandidateList = ({ range, doctorPtr, includeItems, includeConsultation, includeSummary }) => {
        const start_time = moment(range[0]).format('MM/DD/YYYY');

        const end_time = moment(range[1]).format('MM/DD/YYYY');

        let url = `opvisit/opvisit-list?start_date=${start_time}&end_date=${end_time}`;
        /**
         * doctorPtr is a staff_id is passed as doctor_ptr  for
         *  create a candidate visit list if the logged in is a doctor
         */

        if (doctorPtr) {
            url += `&doctor_ptr=${doctorPtr}&includeItems=${includeItems}&includeConsultation=${includeConsultation}&includeSummary=${includeSummary}`;
        } else {
            url += `&includeItems=${includeItems}&includeConsultation=${includeConsultation}&includeSummary=${includeSummary}`;
        }

        return ApiUtils.get({ url });
    };

    /**
     * Find  all the package items
     */
    getBillID = (opno) => {
        return ApiUtils.get({
            url: `opbill/findByOpNo?op_no=${opno}`,
        });
    };

    /**
     * Get Byte Array for the Report
     */
    getReport = (formBody) => {
        return ApiUtils.post({
            url: `opvisit/patient-report`,
            formBody,
        });
    };

    /**
     *
     * @returns Get genralList
     */
    getGenericList = () => {
        return ApiUtils.get({
            url: `Generallist?genl_mode=OPDIR`,
        });
    };

    /**
     * investigation search
     * @param {*} query
     * @returns
     */
    getSearch = (query) => {
        return ApiUtils.get({
            url: `item/search?itm_desc=%${query}`,
        });
    };

    /**
     * medicine search
     * @param {*} query
     * @returns
     */
    getMedicineSearch = (query) => {
        return ApiUtils.get({
            url: `phm-product?pm_nm=%${query}`,
        });
    };

    /**
     * Find  all the package items
     */
    getItems = (page, limit, includeRate, category, count, itemMode) => {
        if (includeRate) {
            return ApiUtils.get({
                url: `item/itemWithCategory?category=${category}`,
            });
        } else {
            // If count is greater than zero, There is  no need for the API to return count
            if (count > 0)
                return ApiUtils.get({
                    url: `item?page=${page}&stats=${true}&limit=${limit}&itm_active=Y`,
                });
            // If count is equal to zero, The API should return number of records, so pagination can be implemnted, for this stats is maintained
            else {
                return ApiUtils.get({
                    url: `item/item-filter?mode=${itemMode}`,
                });
            }
        }
    };

    /**
     * on submit for emr screen
     * @param {*} formBody
     * @returns
     */
    patientInvestigation = (formBody) => {
        return ApiUtils.post({
            url: `md-patient-investigation-details/md-create`,
            formBody,
        });
    };

    /**
     *
     * @returns patient personal information from opreg
     */
    getPatient = (id) => {
        return ApiUtils.get({
            url: `opvisit?opv_id=${id}&includes=op_reg,doctor`,
        });
    };


    visitInfo = (id) => {
        return ApiUtils.get({
            url: `opvisit/opvisit-details?opv_id=${id}`,
        });
    };


    /**
     *
     * @returns patient history
     */
    getPatientHistory = (op_no,doctorPtr) => {

        if(doctorPtr){
        return ApiUtils.get({
            // url: `opvisit?opv_opno=${op_no}&includes=reason&opv_doctorptr=${doctorPtr}`,
            url :`opvisit/opvisit-list-with-doctor?opno=${op_no}&doctor_pointer=${doctorPtr}`
        });
    }else{
        return ApiUtils.get({
            // url: `opvisit?opv_opno=${op_no}&includes=reason`,
            url :`opvisit/opvisit-list-with-doctor?opno=${op_no}`
        });
    }
    };
    /**
     *  Consulted details of patient include medicine and package
     * @returns
     */
    getConsultDetails = (id) => {
        return ApiUtils.get({
            url: `md-patient-consult-details?pcd_consultid=${id}&includes=patient_investigation_details,patient_medicine_details`,
        });
    };

    /**
     *
     * @returns Find all medicine items
     */
    getMedicine = (page, limit) => {
        return ApiUtils.get({
            url: `phm-product?page=${page}&limit=${limit}`,
        });
    };

    /**
     * Find all generic medicines
     * @param {*} page
     * @param {*} limit
     * @returns
     */
    getGenericMedicine = (page, limit, query, count) => {
   
        if (query != null) {
            // If count is greater than zero, There is  no need for the API to return count
            if (count > 0) {
                return ApiUtils.get({
                    url: `phm-generic?page=${page}&limit=${limit}&ge_nm=%${query}`,
                });
                // If count is equal to zero, The API should return number of records, so pagination can be implemnted, for this stats is maintained
            } else {
                return ApiUtils.get({
                    url: `phm-generic?page=${page}&limit=${limit}&ge_nm=%${query}&stats=${true}`,
                });
            }
        } else {
            // If count is greater than zero, There is  no need for the API to return count
            if (count > 0) {
                return ApiUtils.get({
                    url: `phm-generic?page=${page}&limit=${limit}`,
                });
            } else {
                // If count is equal to zero, The API should return number of records, so pagination can be implemnted, for this stats is maintained
                return ApiUtils.get({
                    url: `phm-generic?page=${page}&limit=${limit}&stats=${true}`,
                });
            }
        }
    };

    /**
     *
     * @returns Find all medicine items
     */
    getMedicineWithStock = (page, limit, includeStock, query, count) => {
        if (query === '') {
            query = null;
        }
        // If count is greater than zero, There is  no need for the API to return count
        if (count > 0) {
            return ApiUtils.get({
                url: `phm-product/product-with-stock?page=${page}&limit=${limit}&includeStock=${includeStock}&pm_nm=${query}`,
            });
            // If count is equal to zero, The API should return number of records, so pagination can be implemnted, for this stats is maintained
        } else {
            return ApiUtils.get({
                url: `phm-product/product-with-stock?page=${page}&limit=${limit}&includeStock=${includeStock}&pm_nm=${query}&stats=${true}`,
            });
        }
    };
}

export default Opvisit;
