/**
 *
 * Component for Previewing reports
 *
 * @description
 * @author Sneha
 *
 */

import React, { useEffect, useState } from 'react';

import './print-preview.scss';

import axios from 'axios';

import { Card, ReferenceSelect, useTranslation ,ApiUtils} from 'soxo-bootstrap-core';

// import { Samples } from '../../../../models';

import PdfViewer from './../../../../components/pdf-viewer/pdf-viewer';

import { Checkbox, Dropdown, Select, Button, Form, Skeleton, Empty, message, Modal } from 'antd';

import { TemplateMasters, Bills } from '../../../../models';

/**
 *
 *
 * @param {*} param0
 * @returns
 */
export default function PrintPreview({ refereneceId, groupPointer, templatecode, props }) {

    // Variable to convert BytesArray
    var [byteArray, setByteArray] = useState(null);

    const [reportModalVisible, setReportModalVisible] = useState(false);

    //Variable to handle button loading
    const [btnloading, setBtnLoading] = useState(false);

    //Variable to handle publish button loading
    // const [publishLoading, setPublishLoading] = useState(false);

    // Variable to disable and enable button
    const [disable, setDisable] = useState(false);

    const [blob, setBlob] = useState();

    const [reportBlob, setReportBlob] = useState();

    const [file, setFile] = useState()

    const [templateCode, setTemplateCode] = useState();

    // State to hold the width and height extracted from the template styles
    // const [templateStyles, setTemplateStyles] = useState({ width: null, height: null });


    const [form] = Form.useForm();

    const { t, i18n } = useTranslation(); // To Translate to another language

    useEffect(() => {
        // loadLabResults(billId);
    }, []);

    /**
     * This is done to preview pdf on first load
     */
    useEffect(() => {
        preview();
    }, []);

    /**
     * Function to preview result
     *
     *
     */
    async function preview(values) {
        // if (labResults.length && templateCode) {
        setBtnLoading(true);

        let formbody = {

            id: refereneceId,
            template_code: templateCode ? templateCode : templatecode,

        };

        // let method = 'GET'

        // let payload = {
        //     method: 'GET',
        //     responseType: 'blob',
        //     headers: {
        //         db_ptr: props.settings ? props.settings.headers.db_ptr.toString() : props,
        //         // db_ptr: 'nura',
        //         Authorization: 'Bearer ' + localStorage.access_token,
        //     },

        // };


        // let url = process.env.REACT_APP_endpoint + `reporting/print-preview`

        let response = await ApiUtils.post({

            url: `reporting/print-preview`,

            responseType: 'blob',

            formBody: formbody
        });

        // await axios.post(`${url}`, formbody, payload)

        //     .then((res) => {

        // var report = new Blob([res], {

        //     type: 'application/pdf',

        // });


        // setBlob(report);

        // setReportBlob(report);

        // let fileURL = URL.createObjectURL(report);

        // setFile(fileURL);

        // Assuming response object has {contentType, raw} properties
        const report = new Blob([response.raw], { type: response.contentType });

        setBlob(report);

        setReportBlob(report);

        const fileURL = URL.createObjectURL(report);
        
        setFile(fileURL);

        let reportData = {

            report_url: fileURL,
            report_remote_path: response.url,
            report_remote_token: localStorage.access_token
        }

        setByteArray(reportData.report_url)

        setBtnLoading(false);

        // })
        // .catch(() => ({

        //     error: true,

        //     data: null,
        // }))
    }

    /**
     *  Load default Template
     * @param {*} options
     * @returns
     */
    function identifyDefaultTemplate(options = []) {
        let matching = {};

        //To load lab report as default template
        if (options) {
            options.forEach(async (record) => {
                if (record.tmpl_code === templatecode) {
                    matching = record;

                     // Extract width and height from template styles
                    // const templateDetails = JSON.parse(matching.tmpl_otherdetails);
                    // const styles = templateDetails.styles[0];
                    // setTemplateStyles({
                    //     width: styles.width,
                    //     height: styles.height
                    // });
                }
            });
        }

        if (matching) {
            setTemplateCode(matching.tmpl_code);
            return matching;
        }
    }


    /**
     * View the Report
     */

    function viewReport() {
        setReportModalVisible(true);
    }

    function selectedCode(event) {
        setTemplateCode(event)
    }

    return (
        <div className="detail-contents">
            <div className="left-sections">
                <div className="top">
                    {/* <Button onClick={viewReport}>print</Button> */}
                </div>

                <div className="bottom">
                    <Card className="card-shadow card" loading={() => <Skeleton />}>
                        {/* Preview Action Form */}
                        <Form onFinish={preview} form={form}>
                            <div>
                                <h2>{t('Report Actions')}</h2>
                            </div>

                            <div className="labels">
                                <label>{t('Template')}</label>
                            </div>

                            <div className="template">
                                <Form.Item name="template_code">
                                    <ReferenceSelect
                                        config={{
                                            // limit: 50,
                                            queries: [
                                                {
                                                    field: 'tmpl_groupptr',
                                                    value: groupPointer,
                                                },
                                            ],
                                        }}
                                        defaultValueCondition={identifyDefaultTemplate}
                                        onChange={selectedCode}
                                        field="tmpl_code"
                                        label="tmpl_desc"
                                        mode="nura-base"
                                        model={TemplateMasters}
                                    />
                                </Form.Item>

                                <Form.Item>
                                    <Button disabled={disable} loading={btnloading} className="preview-button" type="primary" htmlType="submit">
                                        {t('Preview')}
                                    </Button>
                                </Form.Item>

                            </div>
                        </Form>

                        {/* Preview Action Form */}
                        {/* <Select className='select-action' placeholder="- Select Action -" /> */}
                    </Card>

                    {/* Modal for Viewing Report */}
                    <Modal
                        width={'80%'}
                        heigh={'570px'}
                        destroyOnClose={true}
                        footer={null}
                        title={t("Bill Preview")}
                        visible={reportModalVisible}
                        cancelButtonProps={{ style: { display: 'none' } }}
                        okText="Close"
                        onOk={() => {
                            setReportModalVisible(false);
                        }}
                        onCancel={() => {
                            setReportModalVisible(false);
                        }}
                    >
                    </Modal>
                </div>
            </div>

            <div className="right-sections">
                {/* Report Preview */}
                {byteArray || btnloading ? (
                    <div className="report-container">
                        {btnloading ? (
                            <>
                                <Skeleton />
                            </>
                        ) : (
                            <div>

                                {/* <PdfViewer url={byteArray} /> */}
                                 {/* Iframe to display the PDF preview with dynamic width and height from template styles */}
                                {/* <iframe src={byteArray} title='preview' style={{ width: templateStyles.width, height: templateStyles.height }} />                             */}
                                <iframe src={byteArray} style={{ width: '100%', height: '573px' }} />

                            </div>
                        )}
                    </div>
                ) : (
                    <Empty />
                )}
                {/* Report Preview Ends */}
            </div>
        </div>
    );
}
