/**
 *@description
 * @author Sameenaammad
 * This compnent is used to display the summary
 * The time date of appoinment, package and the total value
 * Apply discount is also managed in this component
 * On click of Apply discount, discount value is enterd and submitted
 * On Submission of the form applied discount value is deducted from da_billvalue
 */

import React, { useState, useEffect,useContext } from 'react';

import { Button, Input, Typography, Modal, message, Form, Popconfirm, Skeleton, InputNumber, Radio, Card } from 'antd';

import { Location, InputComponent,GlobalContext,useTranslation } from 'soxo-bootstrap-core';

import { Appointments, Items } from './../../../../models';

const { Title, Text } = Typography;

const { TextArea } = Input;




export function Summary({
    setItems,
    loading,
    setGender,
    appliedDiscount,
    appliedCoupon,
    totalValue,
    callback,
    hideDiscountButton,
    disableApplyCoupon,
}) {
    var urlParams = Location.search();
    const [selected, setSelected] = useState({});

    const [visible, setVisible] = useState(false);

    const [couponVisible, setCouponVisible] = useState(false);

    // Variable used to determine if disocunt or coupon chosen
    const [selectedMode, setSelectedMode] = useState(appliedDiscount ? 'discount' : 'coupon');

    const [coupon, setCoupon] = useState(appliedCoupon);

    const [discount, setDiscount] = useState(appliedDiscount);

    const { user = {} } = useContext(GlobalContext);
    const { t, i18n } = useTranslation(); // To Translate to another language

    // when applying 100% discount coupon , total is zero so there want to take total is zero  not null
    const [total, setTotal] = useState(totalValue == 0 || totalValue ? totalValue : null);

    useEffect(async () => {
        if (urlParams.da_packagetypevalue) await getSelectedPackage(urlParams.da_packagetypevalue);
    }, [urlParams.da_packagetypevalue]);

    useEffect(() => {
        if (urlParams.edit == 'true') {
            urlParams.edit = true;
        }
    }, [urlParams]);

    useEffect(async () => {
        if (totalValue) {
            setTotal(totalValue);
        } else {
            // setTotal(null);
            getSelectedPackage(urlParams.da_packagetypevalue);
        }
    }, [totalValue]);
    /**
     *Get Selected package
     */
    function getSelectedPackage(da_packagetypevalue) {
        Items.getItems(da_packagetypevalue).then(async (result) => {
            setSelected(result);
            setItems(result);

            setGender(result.itm_gender);
            // If total has not been set
            if (totalValue === null) setTotal(result.ir_rate);
        });
    }

    function discountVisibility() {
        setVisible(true);
    }

    const onChange = async (e) => {
        setSelectedMode(e.target.value);
    };

    function couponvisibility() {
        setCouponVisible(true);
    }

    return (
        <div className="right-content">
            <div className="card card-shadow">
                <div className="section-header">
                    <Title level={5}>{t('Package')}</Title>
                </div>

                {loading ? (
                    <Skeleton />
                ) : (
                    <>
                        {/*  */}
                        <div className="section-content">
                            <div className="section-row">
                                <div className="section-label">
                                    <Text>
                                        <strong>{selected.itm_desc}</strong>
                                    </Text>
                                </div>
                                <div className="section-value">
                                    <strong>{selected.ir_rate}</strong>
                                </div>
                            </div>
                            {/* Coupon Apply Option to be enabled with Attribute 'disableApplyCoupon*/}
                            {disableApplyCoupon ? null : (
                                <div>
                                    <div className="section-row">
                                        <div className="section-label">
                                            <Text type="secondary"> {t('Apply Discount/Coupon')}</Text>
                                        </div>

                                        <div className="section-value">
                                            <Radio.Group defaultValue={selectedMode}>
                                                <Radio value="coupon" id="1" onChange={onChange}>
                                                    {t('Coupon')}
                                                </Radio>
                                                {/* {hideDiscountButton ? null : (
                                            <Radio value="discount" id="2" onChange={onChange}>
                                                Discount
                                            </Radio>
                                        )} */}
                                            </Radio.Group>
                                        </div>
                                    </div>
                                    {/** For mode discount, show discount modal */}
                                    {selectedMode && selectedMode === 'discount' ? (
                                        <div className="section-row">
                                            <div className="section-label">
                                                <Text type="secondary"> {t('Discount')}</Text>
                                            </div>
                                            <div className="section-value">
                                                {discount && discount.discount ? (
                                                    <>
                                                        <strong>{discount.discount}</strong>

                                                        <Popconfirm
                                                            title={t("Are you sure you want to Cancel the Applied Discount ? ")}
                                                            onConfirm={() => {
                                                                setTotal(total + discount.discount);
                                                                setDiscount(null);
                                                                callback(null, 'discount', total + discount.discount);
                                                            }}
                                                            className="cancel-coupon-button"
                                                            onCancel={() => {}}
                                                            okText="Yes"
                                                            cancelText="No"
                                                        >
                                                            <Button size="small" className="cancel-coupon-button">
                                                                {t('Cancel')}
                                                            </Button>
                                                        </Popconfirm>
                                                    </>
                                                ) : (
                                                    <Button
                                                        size="small"
                                                        // type="ghost"
                                                        // shape="round"
                                                        onClick={discountVisibility}
                                                        disabled={urlParams.mode === 'Walk In' || urlParams.edit}
                                                    >
                                                        {' '}
                                                        {t('Apply Discount')}
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="section-row">
                                            {/** for mode coupon, show coupon modal*/}
                                            <div className="section-label">
                                                <Text type="secondary"> {t('Coupon')}</Text>
                                            </div>
                                            <div className="section-value">
                                                {coupon ? (
                                                    urlParams.edit ? (
                                                        <Button size="small" className="cancel-coupon-button" disabled>
                                                            {t('Cancel')}
                                                        </Button>
                                                    ) : (
                                                        <Popconfirm
                                                            title={t("Are you sure you want to Cancel the Coupon ? ")}
                                                            onConfirm={() => {
                                                                setTotal(total + coupon.coupon_amount);
                                                                setCoupon(null);
                                                                callback(null, 'coupon', total + coupon.coupon_amount);
                                                            }}
                                                            onCancel={() => {}}
                                                            okText="Yes"
                                                            cancelText="No"
                                                        >
                                                            <Button size="small" className="cancel-coupon-button">
                                                                {t('Cancel')}
                                                            </Button>
                                                        </Popconfirm>
                                                    )
                                                ) : (
                                                    <Button
                                                        loading={loading}
                                                        size="small"
                                                        type="secondary"
                                                        // shape="round"
                                                        onClick={couponvisibility}
                                                        disabled={urlParams.mode === 'Walk In'}
                                                    >
                                                        {t('Apply Coupon')}
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            <div className="section-row">
                                <div className="section-label">
                                    <Text>{t('Bill Value')}</Text>
                                </div>
                                <div className="section-value">
                                    <strong>{total == 0 || total ? total : selected.ir_rate}</strong>
                                </div>
                            </div>
                        </div>
                        
                    </>
                )}

                {/* Mode and Time */}

                <div className="section-content">
                    <div className="section-row">
                        <div className="section-label">
                            <Text type="secondary">{t('Time')}</Text>
                        </div>

                        <div className="section-value">
                            <strong>{urlParams.selected_time}</strong>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                destroyOnClose={true}
                footer={null}
                title={t("Apply Discount")}
                visible={visible}
                okText="Okay"
                onOk={() => {
                    setVisible(false);
                }}
                onCancel={() => {
                    setVisible(false);
                }}
            >
                <DiscountModal
                    setVisible={setVisible}
                    total={total}
                    callback={(values) => {
                        // When discount is set, remove the applied coupon if  any
                        setDiscount(values);
                        setCoupon(null);

                        //Total value is set
                        setTotal(selected.ir_rate - values.discount);
                        callback(values, 'discount', selected.ir_rate - values.discount);
                    }}
                />
            </Modal>
            {/**Coupon Modal starts */}
            <Modal
                destroyOnClose={true}
                footer={null}
                title={t("Apply Coupon")}
                visible={couponVisible}
                okText="Okay"
                onOk={() => {
                    setCouponVisible(false);
                }}
                onCancel={() => {
                    setCouponVisible(false);
                }}
            >
                <CouponModal
                    setCoupon={setCoupon}
                    setTotal={setTotal}
                    total={total}
                    itemCode={urlParams.da_packagetypevalue}
                    urlParams={urlParams}
                    callback={(values, result) => {
                        //If coupon is applied, remove any discount if applied
                        setDiscount(null);
                        if (total) {
                            setTotal(total - result.data.coupon_amount);
                            callback(values, 'coupon', total - result.data.coupon_amount, result);
                        } else {
                            setTotal(selected.ir_rate - result.data.coupon_amount);
                            callback(values, 'coupon', selected.ir_rate - result.data.coupon_amount, result);
                        }

                        setCouponVisible(false);
                    }}
                />
            </Modal>
            {/**Coupon Modal Ends */}
        </div>
    );
}

function DiscountModal({ setVisible, callback, total }) {
    const [loading, setLoading] = useState(false);

    const { t, i18n } = useTranslation(); // To Translate to another language


    const onFinish = (values) => {
        setVisible(false);
        callback(values);
    };

    return (
        <>
            <p>{t('Mention the Discount')}</p>

            <Form name="basic" initialValues={{ remember: true }} onFinish={onFinish}>
                <Form.Item
                    label={t("Discount (in Rs)")}
                    name="discount"
                    rules={[
                        { required: true, message: t('Please Enter Discount Amount') },

                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (value > total) {
                                    return Promise.reject(new Error('Discount should not exceed net amount.'));
                                } else if (total - value < 0) {
                                    return Promise.reject(new Error('Total amount cannot be less than zero'));
                                } else if (value < 0) return Promise.reject(new Error('Discount Amount cannot be less than zero'));
                                else return Promise.resolve();
                            },
                        }),
                    ]}
                >
                    <InputNumber />
                </Form.Item>

                <Form.Item>
                    <Button loading={loading} type="primary" htmlType="submit">
                        {t('Save')}
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}

/**
 * Modal to apply coupon
 * @param {*} param0
 * @returns
 */
function CouponModal({ setCoupon, setTotal, total, itemCode, callback, selected, urlParams }) {
    const [btnLoading, setBtnLoading] = useState(false);

    const { t, i18n } = useTranslation(); // To Translate to another language

    const onFinish = (values) => {
        setBtnLoading(true);

        let formBody = {
            coupon_no: values.coupon,
            item: itemCode,
            booking_date: urlParams.selected_date,
            applicable_on: 'MED',
        };
        Appointments.applyCoupon(formBody)
            .then((result) => {
                if (result.success) {
                    let couponAmount;
                    // Check if coupon type is percetage or amount
                    if (result.data.value_type === 'P') couponAmount = total * (result.data.coupon_value / 100);
                    else if (result.data.value_type === 'A') couponAmount = result.data.coupon_value;
                    result.data = {
                        ...result.data,
                        coupon_amount: couponAmount,
                    };

                    // If after applying coupon, the total becomes less tha zero, it should send an error message
                    if (total - result.data.coupon_value < 0) {
                        message.error('Coupon Amount exceeds total Amount. Please check your coupon');
                        return false;
                    } else {
                        message.success(result.message);

                        setCoupon(result.data);

                        setTotal(total - result.data.coupon_amount);

                        setBtnLoading(false);

                        callback(values, result);
                    }
                } else {
                    message.error(result.message);

                    setBtnLoading(false);
                }
            })
            .catch((error) => {
                message.error('Invalid Coupon');

                setBtnLoading(false);
            });
    };

    return (
        <>
            <p>{t('Mention the Coupon')}</p>

            <Form name="basic" initialValues={{ remember: true }} onFinish={onFinish}>
                <Form.Item label={t("Coupon")} name="coupon" rules={[{ required: true, message: t('Please Enter Coupon') }]}>
                    <InputComponent />
                </Form.Item>
                <Form.Item name="Remarks" label={t("Remarks")}  rules={[{ required: true, message: t('Please Enter Remarks') }]}>
                    <TextArea rules={[{ required: true, message: t('Please enter your remarks') }]} />
                </Form.Item>

                <Form.Item>
                    <Button loading={btnLoading} type="primary" htmlType="submit">
                        {t('Save')}
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}
