/**
 *
 * Component for Price Format
 *
 * @description
 * @author Jaseela
 *
 */

import React, { useEffect } from 'react';

export default function PriceFormatter({ price, currency, locale, enablePriceFormatting }) {
    let formattedPrice;

    {
        /* Formatted price with currency and locale */
    }
    if (enablePriceFormatting) {
        formattedPrice = new Intl.NumberFormat(locale, {
            style: currency ? 'currency' : undefined,
            currency: currency || undefined,
        }).format(price);
        {
            /* Raw price without formatting */
        }
    } else {
        formattedPrice = price;
    }

    return (
        <strong>
            <span>{formattedPrice}</span>
        </strong>
    );
}
