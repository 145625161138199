/**
 * Component to Show Report List
 *
 *
 * @author Nihala Dilshi
 */

import React, { useState, useEffect, useRef } from 'react';

import { Input, Table, Menu, message, Modal, Form, Button } from 'antd';
import moment from 'moment';
import { MoreOutlined, EditOutlined, EyeOutlined, CloseOutlined, CloseCircleFilled } from '@ant-design/icons';
import { Editor } from '@tinymce/tinymce-react';
import { Tag, Card, useTranslation } from 'soxo-bootstrap-core';
// import { MedicalReports } from '../../../../models';
// import { color } from 'd3-color';

export default function ReportList({ callback, template, medicalReport, cancelReport }) {
     const { TextArea } = Input;
     const [cancelReportModalVisible, setCancelReportModalVisible] = useState(false);
     const [selectedRecord, setSelectedRecord] = useState({})
     const { t, i18n } = useTranslation(); // To Translate to another language
     

     const ReportListColumns = [
          {
               title: '#',
               dataIndex: 'index',
               render: (value, item, index) => index + 1,
          },

          {
               title: t('ID'),
               render: ele => {
                    return <div>{ele.id}</div>;
               },
               key: 'id',
          },
          {
               title: t('Date'),

               render: ele => {
                    return ele.date ? moment.tz(ele.date, '').format('DD/MM/YYYY hh:mm a') : null;
               },

               key: 'date',
          },

          {
               title: t('Report'),
               render: ele => {
                    return <div>{ele.report_name}</div>;
               },
               key: 'report',
          },
          {
               title: t('Status'),

               render: ele => <Tag color={ele.status === 'D' ? 'blue' : 'green'}>{ele.status === 'D' ? 'Draft' : 'Sign Off'}</Tag>,
               key: 'status',
          },

          {
               title: t('Action'),
               fixed: 'center',
               render: (ele) => {
                    return (
                         <>
                              {ele.status === 'D' ? (
                                   <Button type="secondary" key="edit" htmlType="submit" style={{ marginLeft: '20px' }} onClick={() => callback(ele, 'edit')}>
                                        <EditOutlined /> {t('Edit')}
                                   </Button>
                              ) : null}
                              <Button type="secondary" key="view" htmlType="submit" style={{ marginLeft: '20px' }} onClick={() => callback(ele, 'view')}>
                                   <EyeOutlined />{t('View')}
                              </Button>
                              <Button type="secondary" key="edit" htmlType="submit" style={{ marginLeft: '20px' }} onClick={() => {
                                   setSelectedRecord(ele)
                                   setCancelReportModalVisible(true)
                              }}>
                                   <CloseCircleFilled /> {t('Cancel')}
                              </Button>
                         </>
                    );
               },
               key: 'action',
          },
     ];

     return (
          <>
               <div className="detail-element">
                    <Card className="table-card">
                         <Table
                              scroll={{ x: true }}
                              rowKey={(record, key) => key}
                              pagination={false}
                              dataSource={medicalReport}
                              columns={ReportListColumns}
                         />
                    </Card>
                    {/**----Modal for Report Cancellation Starts----- */}
                    <Modal
                         destroyOnClose={true}
                         footer={null}
                         title="Cancel Report"
                         visible={cancelReportModalVisible}
                         cancelButtonProps={{ style: { display: 'none' } }}
                         onOk={() => {
                              setCancelReportModalVisible(false);

                         }}
                         onCancel={() => {
                              setCancelReportModalVisible(false);
                         }}
                    >
                         <Form onFinish={(values) => cancelReport(values, selectedRecord)}>
                              <Form.Item name="remarks" label="Remarks">
                                   <TextArea />
                              </Form.Item>
                              <Button htmlType="submit">Proceed to Cancel Report</Button>
                         </Form>
                    </Modal >
                    {/**----Modal for Report Cancellation Ends----- */}
               </div>
          </>
     );
}
