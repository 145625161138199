/**
 *
 * This should ideally be satisfied by a generic listing page
 *
 *
 */

import React, { useState, useEffect, useContext } from 'react';

//
import { Space, DatePicker, Switch, Form, Button, Typography, Table, Skeleton, Pagination, Input, Tag, Popover } from 'antd';

import { Link } from 'react-router-dom';

import { ModalityGroups } from './../../../../models';

import Select from 'react-select';

import { Location, GlobalContext, Popconfirm, useTranslation } from 'soxo-bootstrap-core';

import moment from 'moment-timezone';

import TooltipWrapper from '../../../../components/tooltip-wrapper/tooltip-wrapper';

import './patient-list.scss';

import { OrderedListOutlined, PicCenterOutlined } from '@ant-design/icons';

import ReportPreview from './../../../../components/report-preview/report-preview';

import { loadPDF } from '../../../../utils/common.utils';


const { RangePicker } = DatePicker;

const { Search } = Input;

let defaultValue = {
    value: { mod_code: 'all' },
    label: 'All',
};

const filterOptions = [
    {
        label: 'All',
        value: 'all',
    },
    {
        label: 'Reported',
        value: 'reported',
    },
    {
        label: 'Unreported',
        value: 'unreported',
    },
];

/**
 * Patient List
 *
 * @param {*} match
 * @returns
 */
export default function PatientListResearch({
    match,
    mode,
    excludeColpo,
    redirect,
    title,
    filter = 'TECH',
    defaultModality,
    defaultLabel,
    mod_code,
    doctorNote,
    disablePagination,
    reference_id,
    

    reportConfig = {
        baseUrl: 'http://localhost:3800/',
    },
}) {
    // location params
    var param = Location.search();

    const [range, setRange] = useState([
        moment.tz(param.start_time, process.env.REACT_APP_TIMEZONE).startOf('day'),
        moment.tz(param.end_time, process.env.REACT_APP_TIMEZONE).endOf('day'),
    ]);

    const [groups, setGroups] = useState([]);

    const { user = {}, isMobile } = useContext(GlobalContext);

    const [pending, setPending] = useState(filterOptions[0]);

    const [loading, setLoading] = useState(false);

    const [page, setPage] = useState(1); // Pagination number

    const [limit, setLimit] = useState(20);

    const [patients, setPatients] = useState([]); //Patients list array

    const [view, setView] = useState(isMobile ? true : false); //Need to check this condition

    const [modality, setModality] = useState(defaultValue);

    const [subModality, setSubModality] = useState('');

    const { t, i18n } = useTranslation(); // To Translate to another language

    const [suboptions, setSuboptions] = useState([]);

    // Number of total records
    const [count, setCount] = useState(0);

    //To disable select box when mamo is defaultModality
    const [disabled, setDisabled] = useState(false);

    if (defaultModality) {
        defaultValue = {
            value: {
                mod_code: mod_code,
                mod_groupptr: defaultModality,
            },
            //default label for default modality
            label: defaultLabel,
        };
    }
    var [query, setQuery] = useState('');

    var [opvalue, setOpvalue] = useState(param.opno || '');

    useEffect(() => {
        // checking if there is default modality, to disable the modality filter option
        if (defaultModality) {
            setDisabled(true);
        }
        // On url update load the data
        // loadModality()
        if (doctorNote) {
            loadRadiologyList(reference_id);
        } else {
            loadData();
        }
    }, [match.params]);

    useEffect(() => {
        return () => {
            // This will be called when the component unmounts
            defaultValue = {
                value: { mod_code: 'all' },
                label: t('All'),
            };
        };
    }, []);

    /**
     * Load the data
     */
    function loadData(record) {
        let urlParams = Location.search();

        // Match params
        let params = {
            start_time: range[0].format('MM/DD/YYYY'),
            end_time: range[1].format('MM/DD/YYYY'),
            limit: 20,
            modality: defaultValue.value.mod_code,

            ...urlParams,
        };

        // set params for opno search 
        // if (urlParams.opno) {
        //     params = {
        //         ...params,
        //         opno: urlParams.opno,
        //     };
        // }

        loadGuestRecords(params);
    }

    useEffect(() => {
        getGroups();
    }, [user]);

    /**
     * load the data
     *
     * @param {*} params
     */

    function loadGuestRecords(params) {
        setLoading(true);
        // Load the data

        ModalityGroups.loadGuests({ ...params, user, mode, excludeColpo }).then(result => {
            setPatients([...result[0]]);

            // If the count is present , update the size of the pagination component
            if (result[0] && result[0][0] && result[0][0].Count) {
                setCount(result[0][0].Count);
            }

            setLoading(false);
        });
    }
    /***
     * Radiology lIst for doctor notes screen
     *
     */
    function loadRadiologyList() {
        //
        setLoading(true);

        // Load the data
        ModalityGroups.getRadiologyList(reference_id).then(result => {
            setPatients(result.result);

            setLoading(false);
        });
    }

    /**
     * On Page Change
     *
     * @param {*} data
     */
    function onPagination(page, limit) {
        setPage(page);

        setLimit(limit);

        Location.search({ page, limit });
    }

    /**
     * Get all the groups
     */
    function getGroups() {
        // get groups
        ModalityGroups.getModality().then(result => {
            setGroups([
                defaultValue,

                ...result.result
                    .filter(item => item.mod_active === 'Y')
                    .map(record => {
                        return {
                            value: record,
                            label: record.mod_desc,
                        };
                    }),
            ]);
        });
    }

    /**
     * On change of datetime
     *
     * @param {*} dt
     */
    function updateTime(dt) {
        setPage(1);
        setRange(dt);
        setSubModality('');

        Location.search({
            page: 1,
            limit: 20,
            start_time: moment(dt[0]).format('MM/DD/YYYY'),
            end_time: moment(dt[1]).format('MM/DD/YYYY'),
        });
    }

    /**
     * Change view
     *
     * @param {*} result
     */
    function changeView(result) {
        setView(result);
    }

    /**
     * Refresh the
     */
    function refresh() {
        setSubModality('');

        loadData();
    }

    /**
     * for Search
     * @param {*} event
     */
    function onSearch(event) {
        setQuery(event.target.value);
    }

    /**
     * for Search against opno
     * 
     * @param {*} event
     */
    async function onSearchOp(value) {

        setOpvalue(value);

        // let value = value;

        let params = Location.search();

        if (!value) {

            // If the value is not present , we have to remove from the
            // object
            delete params.opno

            Location.search({
                ...params
            }, {
                reset: true
            })

        } else {

            // Append opno to search
            Location.search({
                opno: value
            })
        }
    }

    /**
     * Filter by modality
     *
     * @param {*} value
     * @param {*} content
     */
    function filterModality(value, content) {
        setPage(1);

        setModality(value);

        setSubModality('');

        Location.search({ modality: value.value.mod_code, page: 1 }, { reset: true });

        if (value.label !== 'All') {
            ModalityGroups.getModalityGroupItemGroupMap(value.value.mod_groupptr).then(result => {
                setSuboptions(
                    result.map(record => {
                        return {
                            value: record,
                            label: record.itm_desc,
                        };
                    })
                );
            });
        } else {
        }
    }

    /**
     * Subfilter
     *
     * @param {*} value
     */
    function subFilterModality(value) {
        setSubModality(value);

        Location.search({ submodality: value.value.itm_code });
    }

    

    const radiologyColumns = [
        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => (page - 1) * limit + index + 1,
        },
        {
            title: t('Date'),

            render: value => {
                return moment(value.process_start_time).format('DD/MM/YYYY');
            },
            // render: (ele) => {
            //    console.log(ele)
            // },
            key: 'ProcessStartTime',
        },
        {
            title: t('Modality'),
            dataIndex: 'modality',
            key: 'modality',
            // filters: filterChoices && filterChoices[0],
            // onFilter: (value, record) => (record.Modality ? record.Modality.indexOf(value) === 0 : null),
        },
        {
            title: t('Report'),
            key: 'action',
            // fixed: 'right',
            render: (text, record) => {
                if (record.report_type) {
                    return (
                        <Space size="middle">
                            <ReportPreview config={reportConfig} record={record}  />

                            {/* <a target="_blank" href={record.pacs_path}>View</a> */}
                        </Space>
                    );
                }
            },
        },

        {
            title: t('PACS'),
            key: 'action',
            // fixed: 'right',
            render: (text, record) => {
                if (record.pacs_path) {
                    return (
                        <Space size="middle">
                            <a target="_blank" href={record.pacs_path}>
                                {t('View')}
                            </a>
                        </Space>
                    );
                }
            },
        },

        {
            title: t('Accession No'),
            dataIndex: 'accession_no',
            key: 'accessionno',
        },

        {
            title: t('Tech Result'),
            dataIndex: 'tech_result',
            key: 'TechResult',
        },

        {
            width: 100,
            title: t('Tech Remarks'),
            dataIndex: 'tech_description',
            key: 'tech_description',
            render: tech_description => {
                return (
                    <>
                        <TooltipWrapper content={tech_description} length={15} />
                    </>
                );
            },
        },

        // {
        //     title: 'Dr Result',
        //     key: 'result',
        //     render: (text, record) => {
        //         const content = (
        //             <div>
        //                 <p>{text.DrRemarks}</p>
        //             </div>
        //         );

        //         return (
        //             <>
        //                 <Popover content={content} title="Remarks">
        //                     {text.DrResult}
        //                 </Popover>
        //             </>
        //         );
        //     },
        // },
    ];

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            // render: (value, item, index) => (options.page - 1) * options.pageSize + index + 1,
            render: (value, item, index) => (page - 1) * limit + index + 1,

            // render: (value, item, index) => page * index + 1,
        },

        {
            title: t('Name'),
            dataIndex: 'Name',
            key: 'name',
        },

        {
            title: t('Date'),
            dataIndex: 'ProcessStartTime',
            key: 'ProcessStartTime',
        },
        {
            title: t('Age'),
            dataIndex: 'Age',
            key: 'age',
        },

        {
            title: t('Gender'),
            // dataIndex: 'Gender',
            render: record => {
                return (
                    <>
                        <Tag color={record.Gender === 'Male' ? 'blue' : 'pink'}> {record.Gender}</Tag>
                    </>
                );
            },
            key: 'gender',
        },
        // {
        //     title: 'Billed  Date',
        //     dataIndex: 'ProcessStartTime',
        //     key: 'ProcessStartTime',
        // },
        {
            title: t('OP No'),
            // dataIndex: 'opno',
            render: record => {
                return (
                    <>
                        <Tag color="green"> {record.opno}</Tag>
                    </>
                );
            },
            key: 'OpNo',
        },

        {
            title: t('Modality'),
            dataIndex: 'Modality',
            key: 'Modality',
            // filters: filterChoices && filterChoices[0],
            // onFilter: (value, record) => (record.Modality ? record.Modality.indexOf(value) === 0 : null),
        },

        {
            title: t('Accession No'),
            dataIndex: 'AccessionNo',
            key: 'accessionno',
        },

        {
            title: t('Tech Result'),
            dataIndex: 'TechResult',
            key: 'TechResult',
        },

        {
            title: t('Tech Remarks'),
            dataIndex: 'TechDesc',
            key: 'TechDesc',
        },

        {
            title: t('Dr Result'),
            key: 'result',
            render: (text, record) => {
                const content = (
                    <div>
                        <p>{text.DrRemarks}</p>
                    </div>
                );

                return (
                    <>
                        <Popover content={content} title={t("Remarks")}>
                            {text.DrResult}
                        </Popover>
                    </>
                );
            },
        },

        {
            title: '',
            key: 'action',
            fixed: 'right',
            render: (text, record) => {
                let isPresent = false;

                if (text.DrResult) {
                    isPresent = true;
                }

                return (
                    <Space size="middle">
                        {/* ${record.MODE} */}

                        <Link
                            to={`/${redirect}/${record.opno}?accessionno=${record.AccessionNo}&mode=${isPresent ? 'RAD' : 'TECH'}&opb_id=${record.billid
                                }&item_ptr=${record.template_code}`}
                        >
                            {t('View')}
                        </Link>
                    </Space>
                );
            },
        },
    ];

    // columns
    let tableColumns;
    if (doctorNote) {
        tableColumns = radiologyColumns;
    } else {
        tableColumns = columns;
    }

    // Defining Data source for passing to list
    var dataSource = patients.filter(item => {
        query = query.toUpperCase();

        if (query) {
            if (filter === 'TECH') {
                if (pending.value === 'reported' && item.Name.toUpperCase().indexOf(query) != -1) {
                    return item.TechResult;
                } else if (pending.value === 'unreported' && item.Name.toUpperCase().indexOf(query) != -1) {
                    return !item.TechResult;
                } else if (item.Name.toUpperCase().indexOf(query) != -1) {
                    return true;
                }
            } else if (filter === 'RAD') {
                if (pending.value === 'reported' && item.Name.toUpperCase().indexOf(query) != -1 && item.MODE === 'RAD') {
                    return item.DrResult;
                } else if (pending.value === 'unreported' && item.Name.toUpperCase().indexOf(query) != -1 && item.MODE === 'RAD') {
                    return !item.DrResult;
                } else if (item.Name.toUpperCase().indexOf(query) != -1 && item.MODE === 'RAD') {
                    return true;
                }
            }
        } else {
            if (filter === 'TECH') {
                if (pending.value === 'reported') {
                    return item.TechResult;
                } else if (pending.value === 'unreported') {
                    return !item.TechResult;
                } else {
                    return true;
                }
            } else if (filter === 'RAD') {
                if (pending.value === 'reported') {
                    return item.DrResult;
                } else if (pending.value === 'unreported') {
                    return !item.DrResult;
                } else {
                    return true;
                }
            }
        }
    });

    return (
        <section className="patient-list card card-shadow">
            {/* Page Header */}
            {doctorNote ? (
                <>
                    <div class="card-header">
                        <div className="card-title">
                            <h3>{t('RADIOLOGY')}</h3>
                        </div>

                        <div className="table-actions">
                            {dataSource.length ? <OpenAllPDF config={reportConfig} records={dataSource} /> : null}
                        </div>
                    </div>
                </>
            ) : (
                <div className="page-header">
                    <div className="left">
                        <Search
                            placeholder={t("Search Page")}
                            allowClear
                            style={{ width: 180, marginTop: '10px', marginBottom: '20px' }}
                            onChange={onSearch}
                        />


                        {/**Component to search without repeated api call */}
                        {/* <SearchDebounce
                            placeholder="Enter OP No"
                            value={query}
                            onChange={(value) => {

                                onSearchOp(value);
                                // setQuery(value);
                                // getMedicine(value, category);
                            }}
                        /> */}

                        <Search
                            placeholder={t("Search OP No")}
                            allowClear
                            style={{ width: 180, marginTop: '10px', marginBottom: '20px' }}
                            onSearch={onSearchOp}
                            onChange={(event) => {
                                setOpvalue(event.target.value);
                            }}
                            value={opvalue}
                        />
                    </div>

                    <div className="right" style={{ alignItems: 'end' }}>
                        <div style={{ display: 'flex' }}>

                            <div style={{ marginRight: '10px' }} className="modality-name">
                                <div className="action-header">{t('Filter')}</div>
                                <Select
                                    options={filterOptions}
                                    labelInValue={'mod_desc'}
                                    value={pending}
                                    onChange={value => {
                                        setPending(value);
                                    }}
                                    // defaultValue="all"
                                    style={{ width: 120 }}
                                ></Select>
                            </div>

                            {/* not needed for stoptb */}
                            <div style={{ marginRight: '10px' }} className="modality-name">
                                <div className="action-header">{t('Modality')}</div>
                                <Select
                                    options={groups}
                                    // labelInValue={'mod_desc'}
                                    value={modality}
                                    onChange={value => {
                                        filterModality(value);
                                    }}
                                    // defaultValue="all"
                                    style={{ width: 80 }}
                                    isDisabled={disabled}
                                ></Select>
                            </div>

                            {/* not needed for stoptb */}
                            {/* Filter for Sub Modality */}
                            {modality.label !== 'All' ? (
                                <div style={{ marginRight: '10px' }} className="sub-modality-name">
                                    <div className="action-header">{t("Sub Modality")}</div>
                                    <Select options={suboptions} value={subModality} onChange={subFilterModality} style={{ width: 80 }}></Select>
                                </div>
                            ) : null}
                            {/* Filter for Sub Modality Ends */}

                            {/* Filtering data */}
                        </div>

                        {/* Filtering data */}
                        <div className="date-and-fltr">
                            <div className="action-header">{t("Date")}</div>
                            <RangePicker
                                allowClear={false}
                                inputReadOnly
                                format={'DD/MM/YYYY'}
                                value={range}
                                onChange={time => {
                                    updateTime(time, range);
                                }}
                                ranges={{
                                    Today: [moment(), moment()],

                                    Yesterday: [
                                        moment()
                                            .subtract(1, 'days')
                                            .startOf('day'),
                                        moment()
                                            .subtract(1, 'days')
                                            .endOf('day'),
                                    ],

                                    'This Week': [moment().startOf('week'), moment().endOf('week')],

                                    'Last Week': [
                                        moment()
                                            .subtract(1, 'week')
                                            .startOf('week'),
                                        moment()
                                            .subtract(1, 'week')
                                            .endOf('week'),
                                    ],

                                    'This Month': [moment().startOf('month'), moment().endOf('month')],

                                    'Last Month': [
                                        moment()
                                            .subtract(1, 'month')
                                            .startOf('month'),
                                        moment()
                                            .subtract(1, 'month')
                                            .endOf('month'),
                                    ],
                                }}
                            />

                            {/* <Button onClick={refresh} type="secondary" size={'small'}>
                                <ReloadOutlined />
                            </Button> */}

                            <Switch
                                defaultChecked={view}
                                onChange={changeView}
                                checked={view}
                                checkedChildren={<OrderedListOutlined />}
                                unCheckedChildren={<PicCenterOutlined />}
                            />
                        </div>
                    </div>
                </div>
            )}


            {/* Content Below */}
            {loading ? (
                <>
                    <Skeleton active />
                </>
            ) : (
                <>
                    {view ? (
                        <>
                            <CardList dataSource={dataSource} redirect={redirect} />
                        </>
                    ) : (
                        <>
                            {
                                <Table
                                    className="radioloy-table"
                                    rowKey={record => record.AccessionNo}
                                    dataSource={dataSource}
                                    scroll={{ x: true }}
                                    columns={tableColumns}
                                    pagination={false}
                                />
                            }{' '}
                        </>
                    )}
                </>
            )}

            <div className="table-footer">
                {!loading && !disablePagination ? (
                    <Pagination pageSize={limit} showSizeChanger={true} onChange={onPagination} defaultCurrent={page} total={count} />
                ) : null}
            </div>

            {/* Page Header Ends */}
            {doctorNote ? null : <p className="size-hint">{loading ? 'Loading' : dataSource.length} records.</p>}

        </section>
    );
}

//Mobile view card Section
function CardList({ dataSource, redirect }) {
    return dataSource.map((item, index) => {
        let isPresent = false;

        if (item.DrResult) {
            isPresent = true;
        }
        return (
            <Link
                key={index}
                className="report-item"
                to={`/${redirect}/${item.opno}?accessionno=${item.AccessionNo}&mode=${isPresent ? 'RAD' : 'TECH'}&opb_id=${item.billid}&item_ptr=${item.template_code
                    }`}
            >
                <ModalityGroups.GuestCard record={item} />
            </Link>
        );
    });
}

/**
 *
 * A helper Component that opens all pdf
 *
 * @returns
 */
function OpenAllPDF({
    config = {
        type: 'RPT'
    },
    records = [] }) {


    const [loading, setLoading] = useState(false);

    /**
     * When the button is clicked
     *
     */
    const openAll = async () => {

        setLoading(true);

        const reportPaths = [];

        // let reports = [records[0]];

        await Promise.all(records.map(async (report) => {

            if (report.report_type == 'RPT') {

                let path = await loadPDF({
                    report_link: report.report_path,
                    config,
                    type: 'RPT'
                });

                reportPaths.push(path);

                window.open(`${path}`, '_blank');
            }
        }))

        setLoading(false);

        console.log(reportPaths);

    };

    return (
        <>


            <Button onClick={openAll} loading={loading} size={'small'} >
                Open All
            </Button>


            {/* <Popconfirm
                title="Are you sure you want to open all modality reports ? "
                onConfirm={() => openAll()}
                onCancel={() => { }}
                okText="Yes"
                cancelText="No"
            >
                <Button loading={loading} size={'small'} >
                    Open All
                </Button>
            </Popconfirm> */}


        </>
    );
}
