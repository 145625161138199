/**
 * Payment Model
 *
 *
 *
 *
 */
import React from 'react';

import Model from './models';

import { ApiUtils } from 'soxo-bootstrap-core';

class Payment extends Model {

    constructor() {

        super();

        this.fields = [

            {
                field: 'name',

                caption: 'Name',
            },

        ];

    }

    get id() {

        return 'payment';

    }

    get getEndpoint() {

        return 'payment';

    }

    get path() {

        return `payment`;

    }

    get getName() {

        return 'payment';

    }

    get modelName() {

        return `payment`;

    }

    getPaymentList = ( id ) => {
console.log('ID',id)
        return ApiUtils.get({ url: `payment/appointment-id/${id}` });

    };
    getPaymentLink = formBody => {
        return ApiUtils.post({
            url: `razorpay/send-pay-link`,
            formBody,
        });
    };
    
}

export default Payment;
