/**
 * Registration Model
 *
 *
 * To seperate all login for Registration Process
 *
 */

import Model from './models';

import { ApiUtils } from 'soxo-bootstrap-core';

import moment from 'moment-timezone';

class EventTransaction extends Model {
    constructor() {
        super();

        this.fields = [
            {
                field: 'name',
                caption: 'Name',
            },
        ];

        // this.columns = ;
    }

    get id() {
        return 'cold_id';
    }

    get getEndpoint() {
        return 'opreg';
    }

    get modelName() {
        return `collection`;
    }

    get columns() {
        return [
            {
                caption: 'Staff',
                field: 'staff.name',
                key: 'staff',
            },
            {
                caption: 'Weight',
                field: 'weight',
                key: 'weight',
            },
            {
                caption: 'Date',
                field: 'created_at',
                key: 'created_at',
            },
        ];
    }

    /**
     *
     * @param {*} range
     * @returns
     */
    getEventDate = range => {
        const start_time = moment(range[0]).format('MM/DD/YYYY');

        const end_time = moment(range[1]).format('MM/DD/YYYY');

        return ApiUtils.get({
            url: `event-trans/complete-timeline?start_date=${start_time}&end_date=${end_time}`,
        });
    };

    getGuestCards = () => {
        return ApiUtils.get({
            url: `event-trans/patients-count`,
        });
    };

    getEventsTimeline = values => {
        /** base url  */
        let url = `event-trans/timeline?bill_id=${values.opb_id}`;
        /** url used for corresponding users  */
        if (values.location_ptr) {
            url += `&location_ptr=${values.location_ptr}`;
        }
        return ApiUtils.get({
            url: url,
        });
    };
    /**
     *
     * @param {*} values
     * @returns
     */
    createEvent = formBody => {
        return ApiUtils.post({
            url: `event-trans/create-event`,
            formBody,
        });
    };

     /**
     *
     * @param {*} values
     * @returns
     */
     eventRevert = formBody => {
        return ApiUtils.post({
            url: `event-trans/revert-events`,
            formBody,
        });
    };
}

export default EventTransaction;
