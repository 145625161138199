/**
 * @description
 * @author jinshakappil
 * Component Is usedd show registration Address Details of Guest
 *
 */

import { Row, Col, Form, Select, Typography, Checkbox } from 'antd';

import { InputComponent, ReferenceSelect, useTranslation } from 'soxo-bootstrap-core';

import { Customers, Departments, Doctors, Opreg } from '../../../../models';

import './registration-address-details.scss';

const { Option } = Select;

const { Title } = Typography;

// const formItemLayout = {
//     layout: 'vertical',
// };

export function GuestRegistrationAddressDetails({ config, form,props }) {

    const { fields = {} } = config;

    const { t, i18n } = useTranslation(); // To Translate to another language

    /**
     *  When selecting Department the Doctor want to prepopulate
     * @param {*} e
     */
    function onchange(e) {
        // get doctor
        Opreg.getDoctor(e).then((result) => {
            if (result.result.length) {
                // set field value of doctor
                form.setFieldsValue({ doctor: result.result[0].do_code});
            }
        });
    }


    
    return (
        <div className="registration-deatils">
            {/** Form content section starts */}
            <div className="form-content">
                <Row gutter={20}>
                    {fields.department.visible ? (
                        <Col xs={24} md={12} xl={8}>
                            <Form.Item
                                name="department"
                                label={fields.department.caption}
                                rules={[{ required: fields.department.required, message: t('Please Select Department') }]}
                            >
                                <ReferenceSelect label="dp_desc" mode="nura-base" model={Departments} field={'dp_id'} onChange={(e) => onchange(e)}/>
                            </Form.Item>
                        </Col>
                    ) : null}

                    {fields.doctor.visible ? (
                        <Col xs={24} md={12} xl={8}>
                            <Form.Item
                                name="doctor"
                                label={fields.doctor.caption}
                                rules={[
                                    {
                                        required: fields.doctor.required,
                                        message: `Please Select ${fields.doctor.caption}`,
                                    },
                                ]}
                            >
                                <ReferenceSelect label="do_name" mode="nura-base" model={Doctors} field={'do_code'}/>
                            </Form.Item>
                        </Col>
                    ) : null}

                    {fields.reason.visible ? (
                        <Col xs={24} md={12} xl={8}>
                            <Form.Item
                                name="reason"
                                label={fields.reason.caption}
                                rules={[
                                    {
                                        required: fields.reason.required,
                                        message: t('Please Enter Reason '),
                                    },
                                ]}
                            >
                                <InputComponent />
                            </Form.Item>
                        </Col>
                    ) : null}
                </Row>

                <Row gutter={20}>
                    {fields.category.visible ? (
                        <Col xs={24} md={12} xl={8}>
                            <Form.Item
                                name="category"
                                label={fields.category.caption}
                                rules={[{ required: fields.category.required, message: t('Please Select Category') }]}
                            >
                                <Select disabled={props.disabled}>
                                    <Option value="GEN">{t('General')}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    ) : null}

                    {fields.corporate.visible ? (
                        <Col xs={24} md={12} xl={8}>
                            <Form.Item
                                name="corporate"
                                label={fields.corporate.caption}
                                rules={[{ required: fields.corporate.required, message: t('Please Select Corporate') }]}
                            >
                                <Select disabled={props.disabled}>
                                    <Option value="Y">{t('Yes')}</Option>
                                    <Option value="N">{t('No')}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    ) : null}

                    {fields.corporate_pointer.visible ? (
                        <Col xs={24} md={12} xl={8}>
                            <Form.Item
                                label={fields.corporate_pointer.caption}
                                name="corporate_pointer"
                                rules={[{ required: fields.corporate_pointer.required, message: t('Please Select Corporate') }]}
                            >
                                <ReferenceSelect label="cc_desc" mode="nura-base" model={Customers} field={'cc_code'} disabled={props.disabled}/>
                            </Form.Item>
                        </Col>
                    ) : null}

                    {fields.reference_details.visible ? (
                        <Col xs={24} md={12} xl={8}>
                            <Form.Item
                                rules={[{ required: fields.reference_details.required, message: t('Please Select Category') }]}
                                name="reference_details"
                                label={fields.reference_details.caption}
                            >
                                <InputComponent />
                            </Form.Item>
                        </Col>
                    ) : null}
                </Row>

                <Row gutter={20}>
                    {fields.new_born_baby.visible ? (
                        <Col xs={24} md={12} xl={8}>
                            <Checkbox>{t('IS NEW BORN')} </Checkbox>

                            {/* <ReferenceSelect/> */}

                            <Form.Item
                                rules={[{ required: fields.new_born_baby.required, message: t('Please Select Category') }]}
                                name="new_born_baby"
                                label={fields.new_born_baby.caption}
                            >
                                <InputComponent />
                            </Form.Item>
                        </Col>
                    ) : null}
                </Row>
            </div>
            {/** Form content section Ends */}
        </div>
    );
}
