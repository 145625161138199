/**
 *
 */

import React, { useState, useEffect, useContext } from 'react';

import { Button, Select, Form, Row, message, Skeleton, Typography, Input, Alert, Tag, Modal } from 'antd';

import './radiology-summary.scss';

import { Location, GlobalContext, ApiUtils, ConvertBytesToArray, DateUtils, ImageWrapper, useTranslation } from 'soxo-bootstrap-core';

import { ModalityGroups } from './../../../../models/';

const { Option } = Select;

export default function RadiologistEntry({ mode = 'RAD', match, history, dashboard, onSubmit, accessionno, opno }) {
    let arr = [
        {
            url: require('./../../../../assets/images/chest-xray-1.jpg'),
        },
        // {
        //     url: require('./../../../../assets/images/chest-xray-2.jpg'),
        // },
        {
            url: require('./../../../../assets/images/10.png'),
        },
    ];

    const [initial, setInitial] = useState({});

    const [options, setOptions] = useState([
        {
            srvrsm_code: '2',
            srvrsm_desc: 'Negative',
        },
        {
            srvrsm_code: '6',
            srvrsm_desc: 'Pending',
        },
        {
            srvrsm_code: '1',
            srvrsm_desc: 'Positive',
        },
        {
            srvrsm_code: '7',
            srvrsm_desc: 'Observation Required',
        },
    ]);

    const { user } = useContext(GlobalContext);

    const [loading, setLoading] = useState(true);

    const [btnloading, setBtnloading] = useState(false);

    const [tech, setTech] = useState({});

    const [rad, setRad] = useState({});

    const { t, i18n } = useTranslation(); // To Translate to another language

    const [digitalreport, setDigitalReport] = useState({});

    const [visible, setVisible] = useState(false);

    const [patient, setPatient] = useState({
        opbill: {},
        opreg: {},
        modality: { item: {} },
    }); //Patiants list array

    const [form] = Form.useForm();

    var urlParams = Location.search();

    useEffect(() => {
        // Url Params
        var urlParams = Location.search();

        // getPatientDetails(urlParams); //Patiant list get function
        getModalityDetails(urlParams);
    }, []);

    /**
     *
     */

    function getModalityDetails(urlParams) {
        setLoading(true);

        // Load the details
        ModalityGroups.getDetails({
            op_no: opno,
            accessionno: accessionno,
            mode: `'TECH','RAD'`,
        }).then((result) => {
            setPatient(result);
            // console.log('rad', result);

            let resultValues = {};

            setRad({});

            setTech({});

            setInitial({});

            var rad_entry = result.serviceResultTran.filter((record) => record.srvrs_mode === 'RAD');

            var tech_entry = result.serviceResultTran.filter((record) => record.srvrs_mode === 'TECH');

            if (rad_entry && rad_entry[0]) {
                setRad(rad_entry[0]);

                resultValues = {
                    ...resultValues,
                    radiologist_remarks: rad_entry[0].srvrs_resultdedesc,
                    radiologist_status: rad_entry[0].srvrs_resultptr,
                };
            }

            if (tech_entry && tech_entry[0]) {
                setTech(tech_entry[0]);

                resultValues = {
                    ...resultValues,
                    technician_remarks: tech_entry[0].srvrs_resultdedesc,
                    technician_status: tech_entry[0].srvrs_resultptr,
                };
            }

            // Update the values to the state
            setInitial({
                ...resultValues,
            });

            getDropdownOptions(result.modality.mpst_itemptr);

            setLoading(false);
        });
    }

    /**
     *
     * @param {*} itemptr
     */
    function getDropdownOptions(itemptr) {
        ModalityGroups.getOptions(itemptr).then((result) => {
            console.log(result);

            const array = result.sort((a, b) => {
                return a.srvrsm_slno < b.srvrsm_slno ? 1 : -1;
            });

            setOptions(array);
        });
    }

    /**
     *
     * @param {*} values
     */
    const onFinish = (values) => {
        setBtnloading(true);
        console.log(user);
        values = {
            ...values,
            resultptr: values.status,
            resultdedesc: values.remarks,
            user: user.email,
            doctorptr: user.staff_id,
            mode: mode,
            op_no: opno || urlParams.opno,
            accessionno: urlParams.accessionno,
            slno: mode === 'TECH' ? 1 : 5,
        };

        ModalityGroups.update(values).then(() => {
            message.success('Your update has been captured');

            setBtnloading(false);

            if (dashboard) {
                onSubmit();
            } else {
                history.goBack();
                // Location.back();
            }
        });
    };

    function refresh() {
        var urlParams = Location.search();

        getModalityDetails(urlParams);

        // getPatientDetails(urlParams);
    }

    let disabled = false;

    if (mode === 'RAD') {
        if (!tech.srvrs_resultptr) {
            disabled = true;
        }
    }

    if (mode === 'TECH') {
        if (rad.srvrs_resultptr) {
            disabled = true;
        }
    }

    // From the report , get the image and link

    var image_link = {};

    var report_link = {};

    if (patient.scrfiles) {
        var images = patient.scrfiles.filter((record) => record.sflink_type === 'IMG');

        if (images.length) {
            image_link = images[0];
        }

        var reports = patient.scrfiles.filter((record) => record.sflink_type === 'RPT');

        if (reports.length) {
            report_link = reports[0];
        }
    }

    async function loadPDF(report_link) {
        var hide = message.loading('Loading digital report');

        const result = await ApiUtils.get({
            baseUrl: 'https://blrffdkhportal.nura-in.com',
            url: `/FileFSLink/files/read-file?type=RPT&file=${report_link.sflink_filename}`,
        });

        setVisible(true);

        if (result.success) {
            let data = ConvertBytesToArray(result.content.data);

            setDigitalReport({ data });
        }

        hide();
    }

    async function loadXray() {
        setVisible(true);
    }

    return (
        <section className="patient-details">
            {/* Loading Indicator */}
            {loading ? (
                <div className="card-shadow card">
                    <Skeleton active />
                </div>
            ) : (
                <div className="patient-main">
                    <>
                        <div className="card-shadow card">
                            {/* <div className="page-header">
                                <div>
                                    <Title level={3}>Radiology Summary</Title>

                                    <p className="size-hint">{loading ? 'Loading data' : 'Refer below information and update feedback'}</p>
                                </div>

                                <Button
                                    onClick={() => {
                                        refresh();
                                    }}
                                    type="secondary"
                                    size={'small'}
                                >
                                    <ReloadOutlined />
                                </Button>
                            </div> */}

                            <div gutter={0} className="detail-wrapper">
                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>{t('Name')}</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3>{patient.opbill.opb_name}</h3>
                                    </Row>
                                </div>

                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>{t('Date')}</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3>{DateUtils.formatDate(patient.opbill.opb_dt)}</h3>
                                    </Row>
                                </div>

                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>{t('Modality')}</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3>
                                            <Tag color={'green'}>{patient.modality.item.itm_desc}</Tag>
                                        </h3>
                                    </Row>
                                </div>
                            </div>
                        </div>

                        {/* <ImageViewer images={arr}/> */}

                        <div className="card-shadow rightCard">
                            <div className="report-links">
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={() => {
                                        loadXray(report_link);
                                    }}
                                    // href={report_link.sflink_filename}
                                    className={`report-link vehicle-card card ${report_link.sflink_filename ? 'available' : ''}`}
                                >
                                    <h1>{t('X-RAY')}</h1>
                                    {t('View X-Ray')}
                                </a>

                                {/* <ImagePreview content={arr} /> */}

                                {report_link.sflink_filename ? (
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        onClick={() => {
                                            loadPDF(report_link);
                                        }}
                                        // href={report_link.sflink_filename}
                                        className={`report-link vehicle-card card ${report_link.sflink_filename ? 'available' : ''}`}
                                    >
                                        <h1>{t('AI')}</h1>
                                        {t('View AI Report')}
                                    </a>
                                ) : null}

                                {/* {image_link.sflink_filename ? ( */}
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={
                                        'http://localhost:8030/patient/1'
                                    }
                                    className={`report-link vehicle-card card`}
                                >
                                    <h1>{t('PACS')}</h1>
                                    {t('View PACS Image')}
                                </a>
                                {/* ) : null} */}

                                <div></div>
                            </div>

                            <div className="ptnt_dtls_form ">
                                <Form
                                    // {...layout}
                                    form={form}
                                    name="new-record"
                                    onFinish={onFinish}
                                    layout="vertical"
                                    initialValues={initial}
                                >
                                    {/* <Title level={5}>Remarks</Title> */}

                                    <Form.Item name={'technician_status'} label={t('Technician Remarks')}>
                                        <Select
                                            disabled
                                            // defaultValue="positive"
                                            style={{ width: 120 }}
                                        >
                                            {options.map((option) => (
                                                <Option value={option.srvrsm_code}>{option.srvrsm_desc}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span>{t('Technician Remarks')}</span>
                                        </Row>
                                        <Row span={12}>
                                            <h3>{tech.srvrs_resultptr ? tech.srvrs_resultdedesc : <Tag color="orange">{t('Pending')}</Tag>}</h3>
                                        </Row>
                                    </div>

                                    <Form.Item name={'radiologist_status'} label={t("Radiologist Status")}>
                                        <Select
                                            disabled
                                            // defaultValue="positive"
                                            style={{ width: 120 }}
                                        >
                                            {options.map((option) => (
                                                <Option value={option.srvrsm_code}>{option.srvrsm_desc}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span>{t('Radiologist Remarks')}</span>
                                        </Row>
                                        <Row span={12}>
                                            <h3>{rad.srvrs_resultptr ? rad.srvrs_resultdedesc : <Tag color="orange">{t('Pending')}</Tag>}</h3>
                                        </Row>
                                    </div>
                                </Form>
                            </div>
                            {/* )} */}
                        </div>
                    </>
                </div>
            )}

            {/* Modal for Viewing AI Report */}
            <Modal
                width={'50%'}
                destroyOnClose={true}
                title={t("Digital Report")}
                visible={visible}
                okText="Okay"
                onOk={() => {
                    setVisible(false);
                }}
                onCancel={() => {
                    setVisible(false);
                }}
            >
                <ImageWrapper type="array" content={arr} />
            </Modal>
            {/* Modal for Viewing AI Report */}
        </section>
    );
}
