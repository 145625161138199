/**
 * SCRFilesLinks Model
 *
 *
 *
 *
 */
import React from 'react';

import Model from './models';

import { ApiUtils } from 'soxo-bootstrap-core';

import { Card, Tag } from 'antd';

class SCRFilesLinks extends Model {
    constructor() {
        super();

        this.fields = [
            {
                field: 'name',

                caption: 'Name',
            },
        ];
    }

    get id() {
        return 'scrfileslinks';
    }

    get getEndpoint() {
        return 'scrfileslinks';
    }

    get path() {
        return `scrfileslinks`;
    }

    get getName() {
        return this.modelName;
    }

    get modelName() {
        return `scrfileslinks`;
    }

    //  getReports = (opb_id) =>{

    //         return ApiUtils.get({

    //             url: `opbill/load-scr-filelink/${opb_id}`,

    //         });

    //     }

    /**
     * get all scrfilelinks data
     * @param {*} param0
     * @returns
     */
    loadData = ({ start_time, end_time }) => {
        return ApiUtils.get({
            baseUrl: 'https://dev.nura.in/dev/',
            headers: {
                db_ptr: 'un',
            },
            url: `scrfileslinks/get-scrfileslinks?date_gte=${start_time}&date_lte=${end_time}`,
        });
    };
    /**
     * Get scrfileLink master Data
     * @param {*} item_ptr
     * @returns
     */
    getScrfileLinksMaster = (item_ptr, accessionno) => {
        return ApiUtils.get({
            // url: `scrfileslinksmas?itemptr=${item_ptr}`,
            url: `scrfileslinks/get-scrfilelinks?item_ptr=${item_ptr}&accessionno=${accessionno}`,
        });
    };

    /**
     *
     * @param {*} param0
     * @returns
     */
    getDetails = ({ op_no, accessionno, mode }) => {
        return ApiUtils.get({ url: `modalitygroup/Details?op_no=${op_no}&accessionno=${accessionno}&mode=${mode}` });
    };

    /**
     * update scrfileslinks
     * @param {*} formBody
     * @returns
     */
    update = (opno, formBody) => {
        return ApiUtils.put({ url: `scrfileslinks/${opno}`, formBody });
    };
    /**
     *
     * @param {*} itemptr
     * @returns
     */

    getOptions = itemptr => {
        return ApiUtils.get({ url: `service-result-map/drop-down/${itemptr}` });
    };

    /**
     *
     * @param {*} value
     * @returns
     */

    getRecord = value => {
        return ApiUtils.get({ url: `scrfileslinks/scr/${value}` });
    };

    // Load scrfilelink
    getReports = (id, item_ptr) => {
        return ApiUtils.get({
            url: `opbill/load-scr-filelink/${id}`,
            // url: `opbill/load-scr-filelink/18170?item_ptr=DEXAS00`,
            // url: `opbill/load-scr-filelink?accession_no=${accessionno}&op_no=${opno}`,
        });
    };

    loadScrfile = id => {
        return ApiUtils.get({
            url: `scrfileslinks/get-scrfile/${id}`,
        });
    };
    /**
     * Get scrrreporttran and scrreporttrand records for the bill_id and opno
     * @param {*} id
     * @param {*} opno
     * @returns
     */
    getReportDetails = (id, opno) => {
        return ApiUtils.get({
            url: `scr-report-tran/get-report-details/${id}`,
        });
    };
    /**
     * Get scrfileslinksMas imagelink for the bill_id and opno
     * @param {*} id
     * @param {*} opno
     * @returns
     */
    getSyncImageLink = (id, opno) => {
        return ApiUtils.get({
            url: `scrfileslinksMas/get-link?op_number=${opno}&bill_id=${id}`,
        });
    };

    /**
     * Function accepts the endpoint and the local file
     *
     */
    checkLocalFileSystem = ({ endpoint, filePath }) => {
        console.log(filePath);

        // Endpoint + file path
        // let url = endpoint + filePath;
        let url = endpoint;

        return fetch(`${url}`, {
            // method: 'GET',
            method: 'POST',
            body: JSON.stringify(filePath),
            // body:filePath,
            headers: {
                'Content-Type': 'application/json',
            },
            // headers: {
            //     //   'App-Type': 313,
            //     //   'App-Version': '1.0.1',
            //     // Authorization: 'Bearer ' + localStorage.access_token,
            //     // type:'multipart/formData',
            //     // 'db_ptr': 'nura'
            // },
        })
            .then(result => {
                return result.json();
            })
            .then(response => {
                return response;
            });
    };

    /**
     * Creating files in scrfilelinks.
     * @param {*} urlParams
     * @returns
     */

    // attachScrfilelink = ({ formBody }) => {

    //     return ApiUtils.get({
    //         url: `scrfileslinks/attach-scrfilelink?file_path=${file_path}&accessionno=${accessionno}&item_ptr=${item_ptr}`,
    //     });
    // };

    attachScrfilelink = ({ formBody, accessionno }) => {
        return ApiUtils.post({
            url: `scrfileslinks/attach-scrfilelink`,
            formBody,
        });
    };
    /**
   * Send Report Mail
   * @param {*} formBody
   * @returns
   */
    async sendReportMail(formBody, token, dbPtr) {
        let url = process.env.REACT_APP_endpoint + `scr-report-tran/trigger-report-mail`

        // Payload for the request
        let payload = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                db_ptr: dbPtr,
                Authorization: 'Bearer ' + token,
            },
            body: formBody ? JSON.stringify(formBody) : null,
        };

        return fetch(url, payload)
            .then(response => {
                return response.json();
            })
            .catch(error => {
                // Handle errors
                console.error('Something went wrong:', error);
            });




    };
      /**
   * Send SyncareImageLinks Mail
   * @param {*} formBody
   * @returns
   */
      async sendSyncareLinks(formBody, dbPtr,token) {
        let url = process.env.REACT_APP_endpoint + `scrfileslinksMas/syncare-mail`

        // Payload for the request
        let payload = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                db_ptr: dbPtr,
                Authorization: 'Bearer ' + token,
            },
            body: formBody ? JSON.stringify(formBody) : null,
        };

        return fetch(url, payload)
            .then(response => {
                return response.json();
            })
            .catch(error => {
                // Handle errors
                console.error('Something went wrong:', error);
            });




    };


    getAccessTokenForReportSending = formBody => {
        return ApiUtils.post({
            url: `auth/login`,
            formBody,
        });
    };

    /**
     *
     *
     * @param {*} formData
     * @param {*} accession_no
     * @returns
     */
    uploadReport = (formData, accession_no) => {
        return fetch(process.env.REACT_APP_ON_PREMISE_URL + `/scrfileslinks/upload-file?accession_no=${accession_no}`, {
            // return fetch(`https://blrffdkhportal.nura-in.com/FileFSLinkTest/files/upload-file`, {
            // return fetch(`scrfileslinks/upload-file`, {

            // return fetch(`https://blrffdkhportal.nura-in.com/FileFSLinkTest/dev/scrfileslinks/upload-file`, {
            // Your POST endpoint
            // mode: 'no-cors',
            method: 'POST',
            headers: {
                //   'App-Type': 313,
                //   'App-Version': '1.0.1',
                // Authorization: 'Bearer ' + localStorage.access_token,
                // type:'multipart/formData',
                // 'db_ptr': 'nura'
            },
            //   credentials: 'include',
            body: formData,
        }).then(
            result => {
                console.log('result', result);
                // return result.json();
                return result;
            },
            error => {
                console.log(error);
                return error;
            },
            progress => {
                console.log(progress);
                return progress;
            }
        );
        // return ApiUtils.post({
        //     url: `scrfileslinks/upload-file?accession_no=${accession_no}`,
        //     formData,
        // });
    };

    /**
     * Api call to publish report
     * @param {*} opb_id
     * @returns
     */
    publishReport = opb_id => {
        return ApiUtils.post({
            url: `scrfileslinks/publish-report/${opb_id}`,
        });
    };
    // /**
    //  * Send Report Mail
    //  * @param {*} formBody
    //  * @returns
    //  */
    // sendReportMail = formBody => {
    //     return ApiUtils.post({
    //         url: `scr-report-tran/trigger-report-mail`,
    //         formBody,
    //     });
    // };

    /**
     * Api call to delete report
     * @param {*} sflink_id
     * @returns
     */
    deleteReport = sflink_id => {
        return ApiUtils.post({
            url: `scrfileslinks/delete-report/${sflink_id}`,
        });
    };
}

GuestCard = GuestCard;

ResearchCard = GuestCard;

function GuestCard({ record, selected = {} }) {
    return (
        <Card className={`card ${selected.AccessionNo === record.AccessionNo ? 'selected' : ''}`}>
            <h3 className="title">Case No : {record.sflink_refno}</h3>

            <p>{/* <Tag color="magenta">{record.Modality}</Tag> */}</p>

            {/* <p>Accession : {record.AccessionNo}</p> */}

            <p className="no-margin">{record.sflink_dttm}</p>
        </Card>
    );
}
function ResearchCard({ record, selected = {} }) {
    return (
        <Card className={`card ${selected.AccessionNo === record.AccessionNo ? 'selected' : ''}`}>
            <h3 className="title">{record.Name}</h3>

            <h4 className="values">
                {record.Age} <Tag color={record.Gender === 'Male' ? 'blue' : 'pink'}> {record.Gender}</Tag> {selected.opno}
            </h4>

            <p>
                <Tag color="magenta">{'LUNG'}</Tag>
            </p>

            <p>Accession : {record.AccessionNo}</p>

            <p className="no-margin">Process started on {record.ProcessStartTime}</p>
        </Card>
    );
}

export default SCRFilesLinks;
