/**
 *
 * @description
 * lab result details componet
 * fetching data based on billid
 * @author Jinishakappil
 *
 */

import React, { useState, useEffect, useContext } from 'react';

import { Tag, Card, Table, Select, Skeleton, Tooltip, Input } from 'antd';

import ToolTipWrapper from '../../../components/tooltip-wrapper/tooltip-wrapper';

import './lab-result-doctor-notes.scss';

import { Samples } from '../../../models';

import { useTranslation} from 'soxo-bootstrap-core';

export default function DoctorNotesLabResult({ mode = 'bill', bill_id }) {
    const [labReportResult, setLabReportResult] = useState({ test: {}, result: {}, entries: [], index: null });

    const [loading, setLoading] = useState(true);

    const { t, i18n } = useTranslation(); // To Translate to another language

    useEffect(() => {
        getLabResultByBillid(bill_id);
    }, []);

    /**
     * This Api is used in doctor notes screen .
     * used to load lab resultsbased on bill id
     *
     */
    function getLabResultByBillid() {
        setLoading(true);
        Samples.getLabReportResultByBillId(bill_id).then(result => {
            let arr = [];

            result.data.forEach(ele => {
                let index = 0; // Initialize index for each lab_test array
                // pushing lab result to an array
                arr.push({ title: true, result: ele.lab_test });
                ele.lab_test.parameters && ele.lab_test.parameters.forEach((value) => {
                    // recording parameter and its index to array
                    if (value && value.value !== '') {
                        index = index + 1; // Increment index
                        // console.log("indexx", index);
                        arr.push({ value: value, index: index });
                    }
                });
            });
            
    
            
            // set array to the state
            setLabReportResult(arr);

            setLoading(false);
        });
    }

    const labResultColumns = [
        {
            title: '#',
            render: (value, item, index) => {
                return value.title ? (
                    <small>
                        <strong>{value.result.test_name}</strong>
                        {value.result.test_status === 'pending' ? (
                            <Tag color="blue" style={{ marginLeft: "20px" }}>
                                {t('Pending')}
                            </Tag>
                        ) : null}
                    </small>
                ) : (
                    value.index
                );
            },
            // onCell: (ele, index) => ({
            //     // colSpan: ele.title ? 5 : 1,
            // }),
        },
        {
            title: t('Particulars'),
            render: ele => {
                return ele.title ? null : ele.value.particulars;
            },
        },
        {
            title: t('Result'),
            key: 'result',
            render: ele => {
                let resultClassName = '';

                /* Ensure that ele.value and ele.value.value are defined */
                let rawValue = ele.value && ele.value.value !== undefined ? ele.value.value : '';

                /* Check if rawValue is not an empty string */
                let value = rawValue !== '' ? rawValue : '';

                /* Convert value to a numeric representation if it's not an empty string */
                let numericValue = rawValue !== '' ? parseFloat(value) : null;
                /** If the result value is zero or if both the lower bound (lbound) and upper bound (ubound) become -1, then there is no need for any color */

                if ((numericValue === 0 )||(ele.value?.lbound ===-1 && ele.value?.ubound===-1))    {
                    resultClassName = '';
                } 
                else if (numericValue !== null && !isNaN(numericValue)) {
                    /* Check for abnormal values */
                    if (
                        (ele.value.lbound !== 0 || ele.value.ubound !== 0) &&
                        ((ele.value.lbound !== 0 && numericValue < ele.value.lbound) || (ele.value.ubound !== 0 && numericValue > ele.value.ubound))
                    ) {
                        resultClassName = 'abnormal';
                    } else if (
                        ele.value.refmin !== -1 &&
                        ele.value.refmax !== -1 &&
                        numericValue >= ele.value.refmin && numericValue <= ele.value.refmax
                    ) {
                        /* Check for normal values */
                        resultClassName = 'normal';
                    } else if (
                        ele.value.refmin !== -1 &&
                        ele.value.refmax !== -1 &&
                        ((numericValue < ele.value.refmin && numericValue >= ele.value.lbound) ||
                            (numericValue > ele.value.refmax && numericValue <= ele.value.ubound))
                    ) {
                        /* Check for warning values */
                        resultClassName = 'warning';
                    } else if (
                        ele.value.refmin === -1 &&
                        ele.value.refmax === -1 &&
                        numericValue >= ele.value.lbound &&
                        numericValue <= ele.value.ubound
                    ) {
                        /* Check for normal values without refmin and refmax*/
                        resultClassName = 'normal';
                    }
                }

                /* Add an else block to handle the case where none of the conditions are met*/
                else {
                    resultClassName = '';
                }

                return ele.title ? null : <div className={`result-display ${resultClassName}`}>{value}</div>;
            },
        },
        {
            title: t('Ref.Range'),
            key: 'range',
            render: ele => {
                //
                return ele.title ? null : <ToolTipWrapper content={ele.value.reference_range} length={15} />;
            },
        },
        {
            title: t('Unit'),
            key: 'unit',
            render: ele => {
                return ele.title ? null : <ToolTipWrapper content={ele.value.unit} length={10} />;
            },
        },
    ];

    return (
        <div className="result-deatails lab-result-doctor-notes card card-shadow">
            <div>
                <div class="card-header">
                    <div className="card-title">
                        <h3>{t('LAB')}</h3>
                    </div>

                    <div className="legend-container">
                        <Legend />
                    </div>
                </div>
            </div>
            {loading ? (
                <Card className="card-shadow card">
                    <Skeleton active />
                </Card>
            ) : (
                <div>
                    <Table
                        className="doctor-notes-lab-deatils"
                        rowKey={(record, key) => key}
                        pagination={false}
                        // scroll={{ x: true }}
                        dataSource={labReportResult}
                        columns={labResultColumns}
                    />
                </div>
            )}
        </div>
    );
}

/**
 *
 * @returns
 */
function Legend() {
    return (
        <>
            {[
                {
                    caption: 'Normal',
                    color: 'normal',
                },
                {
                    caption: 'Warning',
                    color: 'warning',
                },
                {
                    caption: 'Abnormal',
                    color: 'abnormal',
                },
            ].map(status => (
                <StatusLabel status={status} />
            ))}
        </>
    );
}

/**
 *
 * @param {*} param0
 * @returns
 */
function StatusLabel({ status = {} }) {
    return (
        <div className={`status-label ${status.color}`}>
            <div className={`circle ${status.color}`}></div>

            <p>{status.caption}</p>
        </div>
    );
}
