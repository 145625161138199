import React, { useState, useEffect, useCallback } from 'react';

import AsyncSelect from 'react-select/async';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { Button, Form, Select, Card, Typography, DatePicker, Steps, Modal, message, Input, Progress } from 'antd';

import './multiple-candidate.scss';

import { Location, InputComponent, useTranslation } from 'soxo-bootstrap-core';

import _debounce from 'lodash/debounce';

import BillItems from '../../common/components/bill-items/bill-items';

import GuestDetailsForMultipleCandidate from './guest-details';

import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { withRouter } from 'react-router-dom';

import moment from 'moment';

import { useLocation } from 'react-router-dom';

import { Items, Appointments, Opreg, Bills, Settings } from './../../../models';
import Status from '././status-component/status';
import billItems from './../../common/components/bill-items/bill-items';
import debounce from 'lodash.debounce';

const { Option } = Select;

const { Title } = Typography;
const { TextArea } = Input;
let timeoutinterval = null;
let myTimeout = null;
const formItemLayout = {
    labelCol: {
        // xs: { span: 8 },
        // sm: { span: 0 },
    },
    wrapperCol: {
        // xs: { span: 24 },
        // sm: { span: 16 },
    },
    layout: 'vertical',
};

const status = {
    // image: require(`./../../../../public/soxo/doctor3.png`),
    Heading: 'Health Tip',
    text: 'Early to bed and early to rise, makes a man healthy wealthy and wise',
    colour: '#466891',
    width: '36%',
    right: '-15px',
};

/**
 *
 * @returns
 */
function MultipleCandidate({ ...props }) {
    // let match = useRouteMatch();

    // console.log(props.route);

    let location = useLocation();

    const { t, i18n } = useTranslation(); // To Translate to another language

    let search = Location.search();

    useEffect(() => { }, []);

    // Maintaining an object to show content according to the route
    let content = {};

    //
    let cases = {
        registration: {
            title: t('Registration'),
            description: '',
        },
        appointment: {
            title: t('Appointment'),
            description: 'Book an appointment for a guest in few easy steps',
        },
        reschedule: {
            title: t('Reschedule Appointment'),
            description: 'Reschedule the appointment',
        },
    };

    //
    var title = t('Registration');

    let urlParams = Location.search();

    if (urlParams.redirect === 'appointment') {
        title = t('Appointment');
        content = cases['appointment'];
    }

    if (urlParams.appointId) {
        title = t('Reschedule Appointment');
        content = cases['reschedule'];
    }


    // console.log(props.route);


    return (
        <>
            <section className="new-registration page">
                <div className="page-content">
                    {search.edit ? (
                        <>
                            <Title level={3}>{t('Edit')} {title}</Title>
                            <p>{t('Mention the updates you have to make for the')} {title}.</p>
                        </>
                    ) : (
                        <>
                            {/* <Title level={3}>User Type</Title> */}

                            {/* <Steps size="small" current={position[stage]}>

                                <Step title="Select Package" />

                                <Step title="Choose Time Slot" />

                                <Step title="Enter Guest Details" />

                                <Step title="Generate Bill" />
                            </Steps> */}
                        </>
                    )}


                    <Switch>
                        {/* <Route path="/multiple-candidate/mode" component={PackageSelection} /> */}
                        <Route
                            path={`/${props.route}/mode`}
                            render={(routeParams) => {
                                return <PackageSelection {...props} />;
                            }}
                        />

                        <Route
                            path={`/patient-visits-new/mode`}
                            render={(routeParams) => {
                                return <PackageSelection {...props} />;
                            }}
                        />

                        <Route
                            path={`${props.route}`}
                            render={(routeParams) => {
                                return <GuestDetailsForMultipleCandidate {...props} />;
                            }}
                        />

                        {/* <Route path="/${props.route}/guest-details" component={GuestDetailsForMultipleCandidate} /> */}

                        <Route
                            path={`/${props.route}/bills`}
                            render={(routeParams) => {
                                return <BillItems {...props} />;
                            }}
                        />
                    </Switch>
                </div>
            </section>
        </>
    );
}

export default withRouter(MultipleCandidate);

const modes = ['New Guest', 'Existing Guest'];

// Below Component coould be reused at multiple places

/**
 * Select the Package
 */
function PackageSelection({ doctorSelectionDisable, AutomateLabBill, setting, extraBillingParams }) {

    const [btnloading, setBtnLoading] = useState(false);

    const [choice, setChoice] = useState('New Guest');

    const [list, setList] = useState([]);

    const [generic, setGeneric] = useState([]);

    const [opreg, setOpReg] = useState([]);

    const { t, i18n } = useTranslation(); // To Translate to another language

    useEffect(() => {

        getPackages();

        getGenericList();

    }, []);

    const [form] = Form.useForm();


    /**
     * Get all the packages
     */
    const getPackages = () => {
        Items.getDoctorsList().then((result) => {
            setList(result);

            // setList(result.filter((item) => item.itm_active === 'Y'));
        });
    };

    /**
     *  Get Generic List
     */
    const getGenericList = () => {

        Items.getReasonsList().then((result) => {

            // set state 

            setGeneric(result.result);
        });
    };

    /**
     *
     *
     * @param {*} values
     */
    const onFinish = (values) => {
        setBtnLoading(true);

        //If Invalid Op number is entered
        if (choice === 'Existing Guest') {
            if (opreg === null || (opreg && !opreg.op_no)) {
                message.error('Enter an Existing Guest');
                setBtnLoading(false);

                return false;
            }
        }

        if (choice === 'Existing Guest') {
            var formbody = {
                mode: 'exist',
                opv_opno: opreg.op_no,
                op_no: opreg.op_no,
                opv_visitcount: 0,

                opv_reason: values.reason.value,
                // opv_diagnosis: 'f',
                opv_remarks: values.remarks,

                opv_doctorptr: null,
                //date
                // opv_nextschvisitdt: null,
                // opv_regfee: 0,
                // opv_consfee: 0,
                // opv_gpfee: 0,
                //date
                // opv_edd: null,
                // opv_shiftno: opreg.op_shiftno, -- CROSS CHECK
                //date
                // opv_shiftdt: opreg.op_shiftdt, -- CROSS CHECK

                // opv_canflg: 'f',
                // opv_canuser: 'f',

                // opv_canuserid: 0,
                // date
                // opv_cantime: null,
                // opv_billed: null,
                // opv_entryremarks: null,
            };

            // if (values.selected) {
            //     formbody = {
            //         ...formbody,
            //         opv_doctorptr: values.selected.value,
            //     };
            // }

            if (extraBillingParams)
                formbody = {
                    ...formbody,
                    ...extraBillingParams,
                };

            if (extraBillingParams) {
                Opreg.createRegistration(formbody).then(async (result) => {

                    if (result.success === true) {

                        Location.back();

                        setBtnLoading(false);
                    } else {
                        message.error(result.message);
                        setBtnLoading(false);
                    }
                });
            } else {
                var queryParam = {
                    op_no: opreg.op_no,
                };

                Location.navigate({ url: `/multiple-candidate/bill`, queryParam });
                setBtnLoading(false);
            }

        } else {
            var queryParam = {
                reason: values.reason.value,
                remarks: values.remarks || '',
            };

            if (values.selected) {
                queryParam = {
                    ...queryParam,
                    doctor: values.selected.value,
                };
            }

            Location.navigate({ url: 'guest-details', queryParam });
        }
    };

    //Get Option matching the input
    async function loadHeads(query) {
        setOpReg(null);
        if (query) {
            var result = await Appointments.OpregSearch(query.trim());

            return result.map((item) => {
                return {
                    value: item.op_no,
                    label: item.op_fname + ' ' + item.op_lname + ' ' + '-' + ' ' + item.op_mobile + ' ' + '-' + ' ' + item.op_no,
                    ...item,
                };
            });
        }
    }

    // Debounce the load function
    // const loadSuggestions = debounce(loadHeads, 300);

    //Set opreg with selected customers details
    const handleChange = (result) => {
        if (result) setOpReg(result);
        else {
            setOpReg(null);
        }
    };

    return (
        <div className="package-selection">
            {/* Different Packages */}
            <div className="card form-step">
                <Title level={4}>{t('Mode of Visit')}</Title>

                <p>{t('Please select your preference')}</p>

                <div className="registration-mode">
                    {modes.map((mode, key) => {
                        return (
                            <div key={key} className={`mode ${mode === choice ? 'active' : ''}`} onClick={() => setChoice(mode)}>
                                <CheckCircleOutlined />

                                {mode}
                            </div>
                        );
                    })}
                </div>
                {choice === 'Existing Guest' ? (
                    <>
                        <div className="registration-mode">
                            <Form
                                form={form}
                                onValuesChange={(feilds) => {
                                    setOpReg(null);
                                }}
                            >
                                <div>
                                    <p>{t('Search Guest')}</p>
                                    <AsyncSelect
                                        value={opreg}
                                        onChange={handleChange}
                                        cacheOptions
                                        defaultOptions
                                        loadOptions={loadHeads}
                                        isClearable="true"
                                    />
                                </div>
                            </Form>
                        </div>

                        <div className="registration-mode">
                            <div className="details">
                                {opreg && opreg.op_no ? (
                                    <>
                                        <Title level={5}>{t('Selected Guest')}</Title>
                                        <div className="customer">
                                            <div className="name">
                                                <Avatar icon={<UserOutlined />} />
                                                <Title level={5}>
                                                    {opreg.op_fname} {opreg.op_lname}
                                                </Title>
                                            </div>
                                            <div className="guest-details">
                                                <p>
                                                    <span className="guest">{t('OP No:')}</span>
                                                    {opreg.op_no}
                                                </p>
                                                <p>
                                                    <span className="guest">{t('Gender :')}</span>
                                                    {opreg.op_gender}
                                                </p>
                                                {opreg.op_dob ? (
                                                    <p>
                                                        <span className="guest">{t('DOB :')}</span>
                                                        {opreg.op_dob ? moment(opreg.op_dob).format('DD/MM/YYYY') : null}
                                                    </p>
                                                ) : (
                                                    <p>
                                                        <span className="guest">{t('Age :')}</span>
                                                        {opreg.op_ageyear ? opreg.op_ageyear : null}
                                                    </p>
                                                )}

                                                <p>
                                                    <span className="guest">{t('Address:')}</span>
                                                    {opreg.op_add1}
                                                    {opreg.op_add ? <br /> : null}
                                                    {opreg.op_add2}
                                                </p>
                                                {/* <p>
                                                    <span className="guest">Mobile :</span>
                                                    {opreg.op_mobile}
                                                </p> */}
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </>
                ) : null}

                {/* Selection Content */}
                <div className="form-content">
                    {/* {choice !== 'Online' ? ( */}
                    <>
                        <Form {...formItemLayout} name="register" onFinish={onFinish} scrollToFirstError>
                            {doctorSelectionDisable ? null : (
                                <>
                                    <Form.Item name="selected" label={t("Doctor")}>
                                        <Select labelInValue>
                                            {list.map((item, key) => (
                                                <Option key={key} value={item.do_code}>
                                                    {item.do_name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </>
                            )}

                            <Form.Item name="reason" label={t("Reason")} rules={[{ required: true, message: t('Please Enter Reason') }]}>
                                <Select showSearch optionFilterProp="children" labelInValue>
                                    {generic.map((item, key) => (
                                        <Option key={key} value={item.genl_code}>
                                            {item.genl_desc}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="remarks"
                                label={t("Remarks")}
                                rules={[
                                    {
                                        max: 250,
                                        message: t('Length cannot exceed 250 characters!'),
                                    },
                                ]}
                            >
                                <TextArea rows={3} />
                            </Form.Item>

                            <Button loading={btnloading} type="primary" htmlType="submit">
                                {t('Proceed')}
                            </Button>


                        </Form>
                    </>
                    {/* ) : null} */}
                </div>

                {/* Selection Content Ends */}
            </div>


            <div className="status-Card">
                <Status status={status}></Status>
            </div>
            <></>
        </div>
    );
}
