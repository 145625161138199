/**
 * @author Jinishakappil 
 * Listing professions 
 *
 *
 *
 *
 */

import Model from './models';

import {Typography} from 'antd';

import { BaseAPI } from 'soxo-bootstrap-core';


const { Text } = Typography;

const moment = require('moment-timezone');

class ListProfession extends Model {
    
    constructor() {
        super();

        
        this.fields = [
            {
                field: 'name',

                caption: 'Name',
            },
        ];
    }

    get id() {
        return 'pfsn_id';
    }

    get getEndpoint() {
        return 'listprofession';
    }

    get path() {
        return `listprofession`;
    }

    get getName() {
        return this.modelName;
    }

    get modelName() {
        return `listprofession`;
    }

    disableAdd = () => {
        return true;
    };
}

export default ListProfession;
