
import { DocDetails } from '../models';

let reportTypes = {
    'RAW': 'json',
    'RPT': 'pdf',
    'IMG': 'png'
}




/*
Implements utility functions to be used across project
*/

/*eslint no-useless-escape:"off",eqeqeq: "off"*/

export function IsObjectHaveKeys(obj) {
    return obj && typeof obj == 'object' && Object.keys(obj).length;
}

export function capitalizeSentence(sentence) {
    const words = sentence.split(' ');

    return words
        .map((word) => {
            return word[0].toUpperCase() + word.substring(1);
        })
        .join(' ');
}

// export function capitalizeStrings(str) {
//     // Split the string at all space characters
//     return str.trim().split(' ')
//         // get rid of any extra spaces using trim
//         .map(a => a.trim())
//         // Convert first char to upper case for each word
//         .map(a => a[0].toUpperCase() + a.substring(1).toLowerCase())
//         // Join all the strings back together
//         .join(" ")
// }

export function capitalizeStrings(str) {
    return str;
}


/**
 * Load the file from the particular path
 * 
 * @param {*} report_link 
 * @returns 
 */
export async function loadPDF({ report_link, config = {
    requireLinuxPath: false,
    replaceBranch: false
}, url, type = 'RPT' }) {

    let filePath = report_link;

    // We have 4 cases two handle here 
    // Case 1 - Application hosted on linux and require a replacement for url - No Usecase right now 
    // Case 2 - Application hosted on linux and does not require a replacement for url - For local site - hyderabad 
    // Case 3 - Application hosted on windows an require a replacement of url  - For Remote Usage - delhi / mumbai / hyderabad
    // Case 4 - Application hosted on windows and do not require a replace of url - For Local Site Usage - Mumbai 

    // For the linux vm we require file structure to be changed
    filePath = replaceFileName(report_link, config.requireLinuxPath, config.replaceBranch);

    const uncompressedArray = await DocDetails.loadFile(config, filePath.trim(), reportTypes[type], {});

    var fileImage = new Blob([uncompressedArray], { type: `application/${reportTypes[type]}` });

    return URL.createObjectURL(fileImage);

}


/**
* Replace the filename with BLR for any other branch
* This is a custom implementation for Nura
*
* @param {*} fileName
* @returns
*
* @author Ashique Mohammed
*/
function replaceFileName(fileName, requireLinuxPath, replaceFileName) {

    if (replaceFileName) {

        const wordsToReplace = ['DEL', 'BOM', 'HYD'];

        // // The path has to be changed to bangalore
        const replacementString = 'BLR';

        // To replace if its either of the strings and case insensitive
        const regex = new RegExp(wordsToReplace.join('|'), 'i');

        fileName = fileName.replace(regex, replacementString);

    } else {

        fileName = fileName

    }

    // We need to get the raw path and replace it for use in linux
    // For normal we dont want that . 
    return requireLinuxPath ? String.raw`${fileName}`.replace(/\\/g, '/') : fileName;
}
