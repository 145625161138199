import React, { useState, useEffect, useContext } from 'react';

import {
    Form,
    Radio,
    InputNumber,
    Input,
    DatePicker,
    Button,
    Typography,
    message,
    Divider,
    Space,
    Popconfirm,
    Select,
    Modal,
    Table,
    Skeleton,
    Card,
    Checkbox,
} from 'antd';

import { GlobalContext, useTranslation } from 'soxo-bootstrap-core';

import { Location, InputComponent } from 'soxo-bootstrap-core';

import { Bills, CollectionDetails, Collections } from './../../../../models/';

import './collections-add.scss';

import moment from 'moment-timezone';

import PrintPreview from '../../../common/components/print-preview/print-preview';
import PriceFormatter from '../../../common/components/format-price/format-price';

const { Title, Text } = Typography;

const { TextArea } = Input;

const { Option } = Select;

const layout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        lg: { span: 24 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        lg: { span: 24 },
    },
};

let defaultCollection = {};
let dateTimeFormat = 'DD/MM/YYYY';

/**
 * Collection add
 *
 * @param {*} param0
 * @returns
 */
export default function CollectionAdd({ model, history, match, templateCode, groupPointer, enableReceiptPrint, ...props }) {
    const { user } = useContext(GlobalContext);

    // Bills
    const [bill, setBill] = useState({});

    const [collection, setCollection] = useState({
        collected_amount: 0,
    });

    const { id } = match.params;

    console.log(id);

    const [records, setRecords] = useState([defaultCollection]);

    const { t, i18n } = useTranslation();     

    const [loader, setLoader] = useState(false);

    const [openForm, setOpenForm] = useState(0);

    const [errMessage, setError] = useState('');

    const [receipt, setReceipt] = useState(false);

    const [form] = Form.useForm();

    const [type, setType] = useState('Cash');

    const [mounted, setMount] = useState(0);

    // Modal visible for add payment
    const [visible, setVisible] = useState(false);

    const [billNumber, setBillNumber] = useState();

    const [summary, setSummary] = useState({});

    const [payment, setPayment] = useState([]);

    const [printReciept, setPrintReciept] = useState(true); //check 'print' checkbox with attribute

    const [recieptNo, setRecieptNo] = useState(); // to store recipt number

    const [loading, setLoading] = useState(false);

    // Total amount paid in  credit entry
    const [totalPaid, setTotalPaid] = useState(0);

    const [reportModalVisible, setReportModalVisible] = useState(false); // for 'print preview model visible


    // Cancel bill visible modal
    const [cancelBillVisible, setCancelBillVisible] = useState(false);

    const [mode, setMode] = useState('OP');

    // State for checkbox.Initially checkbox is not checked
    const [triggerMail, setTriggerMail] = useState(false);
    useEffect(() => {
        // Load bill details
        if (id) {
            loadBillDetails({ id });
        }
    }, []);

    // for getting currecncy

    let otherDetails = user.firm.f_otherdetails1;

    let currency = JSON.parse(otherDetails);
    let currencySymbol = currency.currency_symbol;


    /**
     * Function to load bill details,
     * This function is trigeerd at first when loadign the page and when the bill number is changed
     * @param {*} e
     */
    function loadBillDetails(e) {
        let bill_no;
        if (e && e.target && e.target.value) bill_no = e.target.value;
        // Bill detail is loaded either using bill id or bill number
        Bills.getBillDetails(id, bill_no).then((result) => {
            if (result.success) {
                // set Bill data
                setBill(result.data);
                // Set everything to summary, summary contains payment and bil details
                setSummary({
                    payment: {
                        ...summary.payment,
                    },
                    guest: result.data,
                });
                // Set the current bill number
                setBillNumber(result.data.bill_no);
                // If a new bill number is given navigate to its collection entry url
                if (bill_no) Location.navigate({ url: `/collection-add/${result.data.bill_id}` });
            }
        });
    }

    /**
     *
     *  Trigger mail check box
     */

    function onCheckMailTrigger(e) {
       setTriggerMail(e.target.checked);
       
    }
    /**
     * This function is triggered when collections are save
     * All the collections are saved to collection details in db
     * @param {*} values
     */
    const onSubmit = () => {
        setError('');
        setLoader(true);
        // Records
        if (payment.length > 0) {
            Collections.addEntry(payment, bill, user, triggerMail)

                .then((result) => {
                    // Location.back();

                    setLoader(false);
                    setError('');
                    message.success('New record added');
                    setRecieptNo(result.data.receipt_no); //to store recipt no here
                    // Navigate to the 'print preview' component based on the checkbox state after successfully saving the collection.
                    if (printReciept && enableReceiptPrint) {
                        setReportModalVisible(true);
                    } else {
                        Location.navigate({ url: `/collections` });
                    }
                })
                .catch(() => {
                    setLoader(false);
                });
        } else {
            message.error('Please add atleast One collection entry');
            setLoader(false);
        }
    };


    /**
     * Add New record
     */
    function addNew() {
        //
        console.log(records);

        var values = form.getFieldsValue();

        console.log(values);

        // Save the previous record
        records[records.length - 1] = {
            ...records[records.length - 1],
            ...values,

            status: 'saved',
        };
        setOpenForm(records.length);

        let defaultCollection = {};

        records.push(defaultCollection);

        setRecords([...records]);

        form.setFieldsValue({ type: 'Cash', amount: undefined, remarks: '' });
        setType('Cash');
    }

    /**
     * Edit record
     */

    function editRecord(index) {
        records[index].status = 'editing';

        setOpenForm(index);

        setRecords([...records]);

        form.setFieldsValue(records[index]);

        setType(records[index].type);
    }

    /**
     * When a new payment is added, the  payment is pushed to payment array to list it in the table
     *
     */

    function updateRecord(index) {
        setLoading(true);

        setVisible(false);
        var values = form.getFieldsValue();

        // Set expirydate .
        // if there is expiry date is present then it save like this format
        if (values.expiryDate) {
            values = {
                ...values,
                expiryDate: moment(values.expiryDate).format('MM/YYYY'),
            };
        }

        payment.push(values);

        setSummary({
            payment: payment,
            guest: { ...summary.guest },
        });

        form.resetFields();
        setLoading(false);
    }

    /**
     * Delete record
     */

    function deleteRecord(index) {
        records.splice(index, 1);
        message.success('Record deleted');
        setMount((prev) => {
            return prev + 1;
        });
    }

    function cancel() {
        message.success('Cancelled');
    }

    /**
     * Load the matching heads
     */
    function loadHeads(query) {
        if (query) {
            return Bills.getOplist(query).then((result) => {
                return result.map((item) => {
                    return {
                        value: item.opb_id,
                        label: item.opb_name,
                        ...item,
                    };
                });
            });
        }
    }
    const handleChange = (value) => {
        /**
         * Load Previous Collection
         */
        var bill_id = +value.opb_id;

        Bills.getCollections(bill_id).then((collections) => {
            var collected_amount = 0;

            var coll = [];
            // console.log('collections',collections)

            coll = collections.map((element) => {
                collected_amount = collected_amount + element.cold_amt;
                return element;
            });

            var coll_object = { collected_amount, coll };

            //  console.log("collections", coll_object)

            setCollection(coll_object);
        });

        // Bill
        setBill(value);
    };

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => index + 1,
        },

        {
            title: t('Mode'),
            render: () => {
                return mode;
            },
            key: 'mode',
        },

        {
            title: t('Card Name'),
            dataIndex: 'cardName',
            key: 'cardName',
        },

        {
            title: t('Holder Name'),
            dataIndex: 'holderName',
            key: 'holderName',
        },

        {
            title: t('Amount'),
            dataIndex: 'amount',
            key: 'amount',
        },
    ];

    // If the added amount exceeds the total balance, the add button is disabled
    let disabled = false;

    if (bill && bill.net_amount) {
        if (bill.net_amount - bill.paid_amount - totalPaid <= 0) disabled = true;
    }

    /**
     * View the Recipt
     */

    function viewReceipt() {
        setReceipt(true);
    }

    //    The function `handlePrintChange` sets the state of `printReceipt` based on the checked status of a target element.

    const handlePrintChange = (e) => {
        setPrintReciept(e.target.checked);
    };

    return (
        <section className="collections-add card card-shadow">
            {/* <Title level={4}>New Collection</Title> */}
            <div className="page-content">
                <div className="left">
                    <div className="page-header" style={{ display: 'flex' }}>
                        <div>
                            <p>{t('Record the collection details below.')}</p>
                        </div>

                        <div className="page-actions">
                            <Button size="small" type="primary" className="collection-but" onClick={() => setVisible(true)} disabled={disabled}>
                                {t('Add')}
                            </Button>
                        </div>
                    </div>

                    {/** Paymengt Modal starts------------------ */}
                    <Modal
                        open={visible}
                        title={t("Add Payment")}
                        destroyOnClose={true}
                        onCancel={() => {
                            setVisible(false);
                        }}
                        // closable={false}
                        footer={null}
                    >
                        <Payment
                            updateRecord={updateRecord}
                            closeModal={() => {
                                setVisible(false);
                                form.resetFields();
                            }}
                            form={form}
                            toBePaid={bill.net_amount - bill.paid_amount - totalPaid}
                        />
                    </Modal>
                    {/* Modal for Viewing Report */}
                    <Modal
                        width={'40%'}
                        heigh={'650px'}
                        destroyOnClose={true}
                        footer={null}
                        title={t("Receipt Preview")}
                        visible={receipt}
                        cancelButtonProps={{ style: { display: 'none' } }}
                        okText="Close"
                        onOk={() => {
                            setReceipt(false);
                        }}
                        onCancel={() => {
                            setReceipt(false);
                        }}
                    >
                        <Receipt
                            callback={() => {
                                setReceipt(false);
                            }}
                            tmpl_code={templateCode}
                            grp_pointer={groupPointer}
                        />
                    </Modal>

                    {/** Payment Modal ends--------------- */}

                    {loading ? (
                        <Skeleton />
                    ) : (
                        <Table
                            scroll={{ x: true }}
                            //    rowKey={(record) => record.da_id}
                            dataSource={payment}
                            columns={columns}
                            pagination={false}
                            summary={(pageData) => {
                                // Total
                                let total = 0;

                                pageData.forEach((entry) => {
                                    if (entry.amount) {
                                        total += parseFloat(entry.amount);
                                    }
                                });

                                setTotalPaid(total);

                                return (
                                    <>
                                        <Table.Summary.Row>
                                            {/* {disableExpand ? null : (
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                            )} */}

                                            {/* <Table.Summary.Cell></Table.Summary.Cell> */}

                                            <Table.Summary.Cell></Table.Summary.Cell>

                                            <Table.Summary.Cell></Table.Summary.Cell>

                                            <Table.Summary.Cell></Table.Summary.Cell>

                                            <Table.Summary.Cell>{t('Total')}</Table.Summary.Cell>

                                            <Table.Summary.Cell>
                                                <Text>
                                                    <strong>
                                                        <PriceFormatter price={total} 
                                                        locale={currency.priceFormat}
                                                        currency={currency.currency_code}
                                                        enablePriceFormatting={currency.enablePriceFormatting}/>
                                                        
                                                        </strong>
                                                </Text>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                        ></Table>
                    )}
                </div>
                {/** Bill details-------------- */}
                <div className="right card card-shadow">
                    <div className="page-header">
                        <div className="page-title  header-caption">
                            <p>{t('BILL DETAILS')}</p>
                        </div>

                        <div className="page-actions">
                            <>
                                {/* <Button size="small" type="secondary" className="receipt-but" onClick={viewReceipt}>
                                    Preview
                                </Button> */}
                            </>
                            <div className="actions"></div>
                        </div>
                    </div>
                    {/* print preview model start */}
                    <div>
                        <Modal
                            width={'80%'}
                            heigh={'570px'}
                            destroyOnClose={true}
                            footer={null}
                            title="Collection Preview"
                            visible={reportModalVisible}
                            cancelButtonProps={{ style: { display: 'none' } }}
                            okText="Close"
                            onOk={() => {
                                setReportModalVisible(false);
                            }}
                            onCancel={() => {
                                setReportModalVisible(false);
                                // callback={() => {
                                setReceipt(false);
                                Location.navigate({ url: `/collections` }); // navigate to collection lists

                                // }}
                            }}
                        >
                            <PrintPreview templatecode={templateCode} refereneceId={recieptNo} groupPointer={groupPointer} props={{ ...props }} />
                        </Modal>
                    </div>
                    {/* print prview model end */}


                    {bill.bill_no ? (
                        <>
                            <div>
                                <div className="section-content">
                                    <div className="section-content">
                                        <p>{t('Mode')}</p>
                                        <Select style={{ width: '100%' }} value={'Out Patient'}>
                                            <Option value="OP">{t('Out Patient')}</Option>
                                        </Select>
                                    </div>

                                    <div className="section-content">
                                        <p>{t('Bill Number')}</p>
                                        <Input value={billNumber} onBlur={loadBillDetails} onChange={(e) => setBillNumber(e.target.value)} />
                                    </div>

                                    <div className="section-row">
                                        <div className="section-label">
                                            <Text>{t('Date')}</Text>
                                        </div>
                                        <div className="section-value">
                                            <strong>{moment(bill.date).format('DD/MM/YYYY')}</strong>
                                        </div>
                                    </div>

                                    <div className="section-row">
                                        <div className="section-label">
                                            <Text>{t('Name')}</Text>
                                        </div>
                                        <div className="section-value">
                                            <strong>{bill.name}</strong>
                                        </div>
                                    </div>
                                    <div className="section-row">
                                        <div className="section-label">
                                            <Text>{t('OP No.')}</Text>
                                        </div>
                                        <div className="section-value">
                                            <strong>{bill.opno}</strong>
                                        </div>
                                    </div>

                                    <div className="section-row">
                                        <div className="section-label">
                                            <Text>{t('Gender.')}</Text>
                                        </div>
                                        <div className="section-value">
                                            <strong>{bill.gender}</strong>
                                        </div>
                                    </div>

                                    <div className="section-row">
                                        <div className="section-label">
                                            <Text>{t('Amount')}</Text>
                                        </div>
                                        <PriceFormatter price={bill.net_amount}  locale={currency.priceFormat}
                                            // currency={currency.currency_code}
                                            enablePriceFormatting={currency.enablePriceFormatting}/>
                                        {/* <div className="section-value">
                                            <strong>{bill.net_amount}</strong>
                                        </div> */}
                                    </div>

                                    {/* <div className="section-row">
                                        <div className="section-label">
                                            <Text>Bill Amount</Text>
                                        </div>
                                        <div className="section-value">
                                            <strong>{bill.net_amount}</strong>
                                        </div>
                                    </div> */}

                                    <div className="section-row">
                                        <div className="section-label">
                                            <Text>{t('Balance to Pay')}</Text>
                                        </div>
                                        <PriceFormatter price={bill.net_amount - bill.paid_amount} locale={currency.priceFormat}
                                            // currency={currency.currency_code}
                                            enablePriceFormatting={currency.enablePriceFormatting}/>
                                        {/* <div className="section-value">
                                            <strong>{bill.net_amount - bill.paid_amount}</strong>
                                        </div> */}
                                    </div>
                                </div>

                                <div className="section-content">
                                    <p>{t('Remarks')}</p>
                                    <TextArea />
                                </div>

                                <div className="actions">
                                    <Button size="small" type="primary" onClick={onSubmit} loading={loader}>
                                        {t('Save Collection')}
                                    </Button>
                                       {/* print Recpt */}
                                     { enableReceiptPrint ? ( <Checkbox onChange={handlePrintChange} checked={printReciept}>
                                        Print
                                    </Checkbox>):null}
                                      
                                    {/* <Checkbox onChange={onCheckMailTrigger}>Send Mail</Checkbox> */}
                                    {/* <Button onClick={() => setCancelBillVisible(true)}>Cancellation</Button> */}
                                </div>
                            </div>
                            {/* Package Details  */}

                            {/* Package Details */}
                        </>
                    ) : null}

                    {/** Modal cancel Receipt starts-------------- */}
                    <Modal open={cancelBillVisible} title={t("Bill Cancellation" )}destroyOnClose={true} closable={false} footer={null}>
                        <CancelBillModal closeModal={() => setCancelBillVisible(false)} />
                    </Modal>
                    {/** Modal to cancel Receipt ends------------ */}
                </div>
            </div>
        </section>
    );
}

/**
 * Function to add receipt
 * @param {*} param0
 * @returns
 */

function Receipt({ callback, tmpl_code, grp_pointer }) {
    const [reportModalVisible, setReportModalVisible] = useState(false);

    const [receiptNO, setReceiptNo] = useState(false);

    const { t, i18n } = useTranslation();     // To Translate to another language

    /**
     * View the Report
     */

    function viewReport() {
        setReportModalVisible(true);
    }
    /**
     * Get receiptNo
     */
    function receiptNo(value) {
        setReceiptNo(value);
    }

    return (
        <div className="card" style={{ height: '127px' }}>
            <div>{t('Recipt No')}</div>
            <div>
                <InputComponent
                    onChange={(value) => {
                        receiptNo(value);
                    }}
                    placeholder="Receipt No"
                />
            </div>

            <div style={{ paddingTop: '11px' }}>
                <Button style={{ float: 'right' }} onClick={viewReport}>
                {t('Preview')}
                </Button>
            </div>

            <div>
                <Modal
                    width={'80%'}
                    heigh={'570px'}
                    destroyOnClose={true}
                    footer={null}
                    title={t("Collection Preview")}
                    visible={reportModalVisible}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okText="Close"
                    onOk={() => {
                        setReportModalVisible(false);
                    }}
                    onCancel={() => {
                        setReportModalVisible(false);
                        callback();
                    }}
                >
                    <PrintPreview templatecode={tmpl_code} refereneceId={receiptNO} groupPointer={grp_pointer} />
                </Modal>
            </div>
            {/* </Card> */}
        </div>
    );
}

/**
 * Function to add payment
 * @param {*} param0
 * @returns
 */
function Payment({ updateRecord, closeModal, form, toBePaid }) {
    const [type, setType] = useState('Cash');

    const { t, i18n } = useTranslation();    // To Translate to another language

    return (
        <Form
            {...layout}
            form={form}
            name="new-record"
            onCancel={closeModal}
            onFinish={updateRecord}
            layout="vertical"
            // validateMessages={validateMessages}
            initialValues={{}}
            onFieldsChange={(fields, value) => {
                let field = fields[0];

                if (field && field.value) {
                    if (field.name[0] === 'type') {
                        setType(field.value);
                    }
                } else {
                }
            }}
        >
            <Form.Item
                name={'type'}
                label={t("Payment Type")}
                initialValue="Cash"
                style={{ marginTop: '10px' }}
                rules={[
                    {
                        required: true,
                        message: t('Please choose the payment type.'),
                    },
                ]}
            >
                <Radio.Group>
                    <Radio value="Cash">{t('Cash')}</Radio>
                    <Radio value="Credit/Debit">{t('Credit/Debit Card')}</Radio>
                    <Radio value="Cheque">{t('Cheque')}</Radio>
                    <Radio value="Other">{t('Other')}</Radio>
                </Radio.Group>
            </Form.Item>

            {/* Payment Type */}

            <Form.Item
                className="amount-field"
                name={'amount'}
                label={t("Amount")}
                rules={[
                    {
                        required: true,
                        message: t('Please enter Amount'),
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (value > toBePaid) {
                                return Promise.reject(new Error('The amount exceeds total amount to be paid'));
                            } else if (value <= 0) {
                                return Promise.reject(new Error('Enter a valid amount'));
                            } else return Promise.resolve();
                        },
                    }),
                ]}
            >
                <InputNumber style={{ width: '100 %' }} className="amount-field" />
            </Form.Item>

            {type === 'Net Banking' ? (
                <Form.Item
                    name={'instrumentNo'}
                    label={t("UTR Number")}
                    rules={[
                        {
                            required: true,
                            message: t('Please enter UTR Number'),
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            ) : null}

            {type === 'Cheque' ? (
                <>
                    <Form.Item
                        name={'holderName'}
                        label={t(" Party Name")}
                        rules={[
                            {
                                required: true,
                                message: t('Please enter Card Holder Name'),
                            },
                        ]}
                    >
                        <Input maxLength={100} />
                    </Form.Item>

                    <Form.Item
                        name={'bankName'}
                        label={t(" Bank Name")}
                        rules={[
                            {
                                required: true,
                                message: t('Please enter Bank Name'),
                            },
                        ]}
                    >
                        <Input maxLength={100} />
                    </Form.Item>
                    <Form.Item
                        name={'instrumentNo'}
                        label={t("Cheque Number")}
                        rules={[
                            {
                                required: true,
                                message: t('Please enter Cheque Number'),
                            },
                        ]}
                    >
                        <Input maxLength={25} />
                    </Form.Item>

                    <Form.Item
                        name={'chequeDate'}
                        label={t("Cheque Date")}
                        rules={[
                            {
                                required: true,
                                message: t('Please enter Cheque Date'),
                            },
                        ]}
                    >
                        <DatePicker inputReadOnly />
                    </Form.Item>
                </>
            ) : null}

            {type === 'Credit/Debit' ? (
                <>
                    <Form.Item
                        name={'instrumentNo'}
                        label={t("Card Number(Last 4 digits)")}
                        rules={[
                            {
                                required: true,
                                message: t('Please enter Card Number'),
                            },
                        ]}
                    >
                        <Input maxLength={4} />
                    </Form.Item>

                    <Form.Item
                        name={'holderName'}
                        label={t("Card Holder Name")}
                        rules={[
                            {
                                required: true,
                                message: t('Please enter Card Holder Name'),
                            },
                        ]}
                    >
                        <Input maxLength={100} />
                    </Form.Item>

                    <Form.Item
                        name={'bankName'}
                        label={t("Bank Name")}
                        rules={[
                            {
                                required: true,
                                message: t('Please enter Bank Name'),
                            },
                        ]}
                    >
                        <Input maxLength={100} />
                    </Form.Item>

                    <Form.Item
                        name={'cardName'}
                        label={t("Card Name")}
                        rules={[
                            {
                                required: true,
                                message: t('Please enter Card Name'),
                            },
                        ]}
                    >
                        <Input maxLength={100} />
                    </Form.Item>

                    {/* <Form.Item
                        name={'issuedBy'}
                        label="Card Issued By"
                        rules={[
                            {
                                required: true,
                                message: 'Card Issued By',
                            },
                        ]}
                    >
                        <Input maxLength={100} />
                    </Form.Item> */}

                    <Form.Item
                        name={'expiryDate'}
                        label={t("Card Expiry Date")}
                        rules={[
                            {
                                required: true,
                                message: t('Expiry Date'),
                            },
                        ]}
                    >
                        {/* <Input maxLength={7} /> */}
                        <DatePicker format={dateTimeFormat} />
                    </Form.Item>
                </>
            ) : null}

            {/* Remarks */}
            <Form.Item
                name={'remarks'}
                label={t("Remarks")}
                rules={[
                    {
                        required: true,
                        message: t('Please enter remarks'),
                    },
                ]}
            >
                <TextArea rows={4} maxLength={50} />
            </Form.Item>

            <Space>
                <Button size="small" type="primary" htmlType="submit">
                    {t('Add Amount')}
                </Button>
                <Button size="small" type="secondary" onClick={closeModal}>
                    {t('Cancel')}
                </Button>
            </Space>
        </Form>
    );
}

/**
 * Function to cancel bill,
 * For the entered receipt number load the receipt and cancel receipt
 * @param {*} param0
 * @returns
 */
function CancelBillModal({ closeModal }) {
    const [receipt, setReceipt] = useState();

    const [remarks, setRemarks] = useState();

    const [loading, setLoading] = useState(false);

    const { t, i18n } = useTranslation();     // To Translate to another language

    /**
     * Function to load receipt data
     * @param {*} e
     */
    async function loadReceipt(e) {
        const receiptNo = e.target.value;

        const receiptDetails = await CollectionDetails.getReceiptDetails(receiptNo);

        setReceipt(receiptDetails);
    }

    /**
     * Cancel Bill
     */
    async function cancelBill() {
        setLoading(false);

        const cancelReceipt = await CollectionDetails.cancelReceipt(receipt.collection_id, remarks);

        if (cancelReceipt.success) {
            message.success(cancelReceipt.message);
            setLoading(false);
            closeModal();
        } else {
            message.error(cancelReceipt.message);
            setLoading(false);
        }
    }

    return (
        <div className="cancel-modal">
            <p>{t('Receipt No')}</p>
            <Input onBlur={loadReceipt} />

            {receipt && receipt.receipt_no ? (
                <div className="cancel-receipt">
                    <div className="card cancel">
                        <p>{t('RECEIPT DETAILS')}</p>
                        <div className="receipt-details">
                            <div>
                                {t('Receipt No.')}
                                <strong>
                                    <p>{receipt.receipt_no}</p>
                                </strong>
                            </div>
                            <div>
                            {t('Mode')}
                                <strong>
                                    <p>{receipt.mode}</p>
                                </strong>
                            </div>
                            <div>
                            {t('Bill No.')}
                                <strong>
                                    <p>{receipt.bill_no}</p>
                                </strong>
                            </div>
                            <div>
                            {t('Amount')}
                                <strong>
                                    <p>{receipt.amount}</p>
                                </strong>
                            </div>
                        </div>
                    </div>
                    <div className="remarks">
                    {t('Remarks')}
                        <TextArea onChange={(e) => setRemarks(e.target.value)} maxLength={50} />
                    </div>
                </div>
            ) : null}
            <div className="submit">
                <Button className="button" onClick={cancelBill} loading={loading} disabled={receipt && receipt.receipt_no ? false : true}>
                {t('Cancel Bill')}
                </Button>
                <Button className="button" onClick={closeModal}>
                {t('Exit')}
                </Button>
            </div>
        </div>
    );
}
// export default withRouter(CollectionAdd);
