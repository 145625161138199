/***
 *
 * Outbox list component
 * @description
 * @author Sneha
 */

import React, { useState, useEffect } from 'react';

import { Select, Table, Tag, Skeleton } from 'antd';

import moment from 'moment-timezone';
import {  DateUtils, useTranslation } from 'soxo-bootstrap-core';

import { OutBoxs } from '../../models';

import './outbox-list.scss';

export default function OutboxList({ record, filteredMail }) {

    const [resultArray, setResultArray] = useState([]);

    const [list, setList] = useState([]);

    const [filtered, setFiltered] = useState(false);

    const [page, setPage] = useState(1);

    const [limit, setLimit] = useState(10);

    const [loading, setLoading] = useState(true);

    const { t, i18n } = useTranslation(); // To Translate to another language
    

    const filterOptions = [

        {

            label: t('All'),

            value: 'all',

        },

        {
            label: t('SMS'),

            value: 'sms',

        },

        {
            label: t('Mail'),

            value: 'mail',

        },

    ];

    /**
     * Columns according to type
     */

    const columns = [

        {
            title: '#',

            dataIndex: 'index',

            render: (value, item, index) => (page - 1) * limit + index + 1,
        },

        {
            title: t('Date'),

            key: 'date',

            render: (record) => {

                return moment.tz(record.time, '').format('DD/MM/YYYY hh:mm a');
               
            },
        },

        {
            title: t('Subject'),

            key: 'subject',

            render: (record) => {

                return record.subject;
            },
        },

        {
            title: t('Receiver'),

            key: 'receiver',

            render: (record) => {

                return record.receiver;

            },
        },

        {
            title: t('Status'),

            key: 'status',

            render: (record) => {

                return (

                    <Tag className={record.status}>{record.status}</Tag>

                )
            },
        },
    ];

    /**
     * function to get outboxlist
     */

    async function getList() {

        setPage(1)

        setLimit(10)

        let formBody = {

            recepients: [record.da_email, record.da_mobile],

            messageTemplates: filteredMail

        };

        await OutBoxs.getOutboxList({ formBody }).then((res) => {

            setLoading(false);

            setResultArray(res.data);

        });

    }


    /**
     * Fitering values according to type
     */

    function filterList(value) {

        // List by type sms 
        if (value === 'sms') {

            let listvalue = resultArray.filter((list) => list.type === 'SMS')

            setList(listvalue)

            setFiltered(true);

        } else if (value === 'mail') {

            // List by type mail
            let listvalue = resultArray.filter((list) => list.type === 'MAIL')

            setList(listvalue)

            setFiltered(true);

        } else if (value === 'all') {

            // List all type
            setFiltered(false);

            return resultArray;

        }

    }

    useEffect(() => {

        getList();

    }, []);

    return (

        <div className="outbox">

            {loading ? (
                <>
                    <Skeleton active />
                </>

            ) : (

                <>
                    <>
                        <div className="selection">

                            <Select

                                options={filterOptions}

                                defaultValue="All"

                                style={{ width: '8rem', paddingBottom: '10px' }}

                                onChange={(value) => {

                                    filterList(value);
                                }}

                            />

                        </div>

                        <div className="mailout-box">

                            <Table

                                dataSource={filtered ? list : resultArray}

                                scroll={{ x: true }}

                                columns={columns}

                                pagination={{

                                    current: page,

                                    pageSize: limit,

                                    onChange(current, limit) {

                                        setPage(current);

                                        setLimit(limit)

                                    },


                                }}
                            />



                        </div>

                    </>

                </>
            )}

        </div>
    );
}
