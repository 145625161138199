/***
 *
 *
 * @description
 * @author jinshakappil
 */

import React, { useEffect, useState, useCallback } from 'react';

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import { Opvisits } from '../../../../models';

import { Typography, Card, Button, Modal, Tag, Input, message, Form, Popconfirm, Skeleton, Empty, Select, Pagination, Table, Space } from 'antd';

import { InputComponent, ImageWrapper, SearchDebounce, useTranslation } from 'soxo-bootstrap-core';

import './medicine.scss';

const referenceImage = require('./../../../../assets/images/reference.jpeg');

const { Search } = Input;
const { Option } = Select;
const { Paragraph } = Typography;
const { Title } = Typography;

/**
 *
 */
export default function MedicineCard({ loading, selectedMedicine, setSelectedMedicine, cartMedicine, setcartMedicine, includeStock, histories }) {
    const [referenceVisible, setReferenceVisible] = useState(false);

    const [visibleMedicine, setVisibleMedicine] = useState(false);

    const [mounted, setMount] = useState(0);

    const [page, setPage] = useState(1);

    const [limit, setLimit] = useState(10);

    const { t, i18n } = useTranslation(); // To Translate to another language

    /**
     * Delete record of medicine
     */

    function deleteMedicine(key) {
        cartMedicine.splice(key, 1);
        setcartMedicine([...cartMedicine]);
        message.success('Record deleted');

        setMount((prev) => {
            return prev + 1;
        });
    }

    function viewReport() {
        setReferenceVisible(true);
    }

    const investigationColums = [
        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => {
                return (page - 1) * limit + index + 1;
            },
        },
        
        {
            title: t('Medicine Name'),
            key: 'medicineName',
            render: (record) => {
                return record.name;
            },
        },

        {
            title: t('Type'),
            key: 'capsule',
            render: (record) => {
                return record.type;
            },
        },

        {
            title: t('Direction'),
            key: 'direction',
            render: (record) => {
                return record.direction;
            },
        },
        {
            title: t('Days'),
            key: 'days',
            render: (record) => {
                return record.days;
            },
        },
        {
            title: t('Remarks'),
            key: 'remarks',
            render: (record) => {
                return record.remarks;
            },
        },

      
    ];

     {/** If the histories returns false then adding a new column called action*/}
    if(!histories){
        investigationColums.push({
            title: t('Action'),
            key: 'action',
            fixed: 'right',

            render: (value, item, index) => {
                return (
                    <Space size="middle">
                        <Popconfirm title={t("Are you sure")} onConfirm={() => deleteMedicine(index)}>
                            <Button size={'small'} type="dashed">
                                <DeleteOutlined />
                            </Button>
                        </Popconfirm>
                    </Space>
                );
            },
        },)
      }
    

    return (
        <>
            <div className="medicine-card card">
                <div className="card-header">
                    <div className="card-title">
                        <div className="title">
                            <img
                                class="card-icon-left"
                                src={require(`../../../../assets/icons/external-medicine-travel-accessories-icongeek26-flat-icongeek26.png`)}
                            />
                            <h3>{t('Medicine/Advice')}</h3>
                        </div>
                        {!histories ? (
                            <div className="button-section">
                                {/* <Button size="small" className="preview-report-trigger" type="dashed" onClick={viewReport}>
                                Refer Sheet
                            </Button> */}

                                <Button
                                    type="primary"
                                    size="small"
                                    className="button"
                                    onClick={() => {
                                        setVisibleMedicine(true);
                                    }}
                                >
                                    <PlusOutlined />
                                    {t('Add')}
                                </Button>
                            </div>
                        ) : (null)}
                    </div>
                    <div>
                        {loading ? (
                            <Skeleton />
                        ) : (
                            <>
                                {cartMedicine.length ? (
                                    <>
                                        <Card className="inner-card">
                                            <Table
                                                scroll={{ x: true }}
                                                rowKey={(record) => record.da_id}
                                                dataSource={cartMedicine}
                                                columns={investigationColums}
                                                pagination={{
                                                    current: page,
                                                    onChange(current) {
                                                        setPage(current);
                                                    },
                                                }}
                                            />
                                        </Card>
                                        {/* );
                                })} */}
                                    </>
                                ) : (
                                    <>
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>

            {/** modal for medicine */}
            <Modal
                className="medicine-modal"
                width="100%"
                destroyOnClose={true}
                footer={null}
                title={t("Medicine/Advice")}
                visible={visibleMedicine}
                okText="Okay"
                onOk={() => {
                    setVisibleMedicine(false);
                }}
                onCancel={() => {
                    setVisibleMedicine(false);
                }}
            >
                <MedicineModal
                    callback={() => {
                        // refresh();
                        setVisibleMedicine(false);
                    }}
                    setcartMedicine={setcartMedicine}
                    selectedMedicine={selectedMedicine}
                    setSelectedMedicine={setSelectedMedicine}
                    setMount={setMount}
                    includeStock={includeStock}
                />
            </Modal>
            {/** end */}

            {/* Modal for Viewing Refernce */}
            <Modal
                width={'55%'}
                destroyOnClose={true}
                title={t("Reference")}
                visible={referenceVisible}
                okText="Close"
                cancelButtonProps={{ style: { display: 'none' } }}
                onOk={() => {
                    setReferenceVisible(false);
                }}
                onCancel={() => {
                    setReferenceVisible(false);
                }}
            >
                <ImageWrapper content={{ url: referenceImage }} />
            </Modal>

            {/* Modal for Viewing Refernce */}
        </>
    );
}

/**
 * model for medicine/advice
 *
 * @returns
 */
function MedicineModal({ callback, setcartMedicine, selectedMedicine, setSelectedMedicine, setMount, includeStock, generallist }) {
    const [loading, setLoading] = useState(false);

    const [btnloading, setBtnLoading] = useState(false);

    var [query, setQuery] = useState('');

    var [filteredMedicine, setfilteredMedicine] = useState([]);

    const [page, setPage] = useState(1); // Pagination number

    const [limit, setLimit] = useState(20);

    const [category, setCategory] = useState('Brand');

    //Number of total records
    const [count, setCount] = useState(0);

    const [code, setCode] = useState([]);

    const { t, i18n } = useTranslation(); // To Translate to another language

    var [generallist, setgeneralList] = useState([]);

    /**
     * Get call
     */
    useEffect(() => {
        // get record from generallist
        getGeneralList();
    }, []);

    /**
     *  This function is used to list down directions of medicine
     */
    function getGeneralList() {
        Opvisits.getGenericList().then((result) => {
            // console.log(result)
            setgeneralList(result.result);
        });
    }
    /**
     * get call for medicine items
     */
    async function getMedicine(query, category, pageNo = page, pageLimit = limit) {
        setLoading(true);

        var result;
        if (category === 'Brand') {
            //If category is brand,this api is called
            result = await Opvisits.getMedicineWithStock(pageNo, pageLimit, includeStock, query, count);
        } else {
            //If category is generic
            result = await Opvisits.getGenericMedicine(pageNo, pageLimit, query, count);
        }

        if (result.result && result.result.length) {
            setfilteredMedicine(result.result);

            setCount(result.count);
        } else {
            setfilteredMedicine([]);
        }

        setLoading(false);
    }

    /**
     * delete cart medicine
     * @param {} index
     */
    function deleteMedicine(index) {
        selectedMedicine.splice(index, 1);
        message.success('Record deleted');
        setMount((prev) => {
            return prev + 1;
        });
    }
    /**
     * Search
     * @param {*} event
     */

    function onSearch(event) {
        setQuery(event.target.value);
    }
    /**
     *  Remark change in cart
     * @param {*} remarks
     * @param {*} index
     */
    function onRemarkChange(remarks, index) {
        selectedMedicine[index].remarks = remarks;

        setSelectedMedicine([...selectedMedicine]);
    }

    /**
     *  Direction change in Cart
     * @param {} direction
     * @param {*} index
     */
    function onDirectionChange(direction, index) {
        selectedMedicine[index].direction = direction;
        setSelectedMedicine([...selectedMedicine]);
    }
    /**
     * Number Of days change in cart
     * @param {*} days
     * @param {*} index
     */
    function onDaysChange(days, index) {
        selectedMedicine[index].days = days;

        setSelectedMedicine([...selectedMedicine]);
    }
    /**
     * Change the type of medicine tap/syrup
     * @param {*} type
     * @param {*} index
     */
    function OnTypeChange(type, index) {
        let selectedMedicineType = medicineTypes.filter((medicine) => {
            return type === medicine.label;
        })[0];

        // Update the type
        selectedMedicine[index].type = selectedMedicineType.label;

        // We also need the label
        selectedMedicine[index].value = selectedMedicineType.value;

        setSelectedMedicine([...selectedMedicine]);
    }

    /**
     * Add Custom Item
     */
    const addCustomItem = (values) => {
        addItem(
            {
                pm_nm: query,
                pm_cod: query,
            },
            {
                remarks: values.remarks,
                direction: values.direction,
                days: values.days,
                type: values.type,
            }
        );
    };
    //create an array for filtering the medicines
    let medicineItem = selectedMedicine.map((record) => record.pm_cod);

    let medicine_code = selectedMedicine.map((record) => record.value);

    // Filter the medicine from the cart

    if (filteredMedicine && filteredMedicine.length) {
        filteredMedicine = filteredMedicine.filter((record) => {
            query = query.toUpperCase();

            if (medicineItem.indexOf(record.pm_cod) !== -1) {
                return false;
            } else {
                return true;
            }
        });
    }

    /**
     *  set selected medicine to array
     * @param {*} item
     * @param {*} key
     */
    const addItem = (item, values) => {
        setBtnLoading(true);

        // To get the matching type of medicine , we filter from the available
        // types with the field that we have
        let selectedMedicineType = medicineTypes.filter((medicine) => {
            return values.type === medicine.label;
        })[0];

        // Iterate over the category list to get the object as we need to map both the values
        // that needs to be send to the payload
        let selectedCategies = medicineCategory.filter((record) => {
            return category === record.label;
        });

        let selectedCat = selectedCategies[0];

        selectedMedicine.push({
            ...item,

            ...{ code: medicine_code },

            ...{ name: item.pm_nm || item.ge_nm },

            ...{ pm_cod: item.pm_cod || item.ge_cod },

            ...{ remarks: values.remarks },

            ...{ direction: values.direction },

            ...{ days: values.days },

            ...{ type: values.type || 'Cap/Tab' },

            // Value of the type of medicine
            ...{ value: selectedMedicineType.value },

            // Category code of the medicine also should be recorded .
            ...{ category: selectedCat.value },

            // Category of the medicine also should be recorded .

            ...{ medicinetype: selectedCat.label },
        });

        setSelectedMedicine([...selectedMedicine]);

        setBtnLoading(false);
    };

    /**
     *
     * @param {*} value
     */
    function handleChange(category) {
        setCategory(category);

        getMedicine(null, category);
    }

    /**
     *  pagination
     * @param {} page
     * @param {*} limit
     */
    function onPagination(pageNo, pageLimit) {
        setPage(pageNo);

        setLimit(pageLimit);

        getMedicine(null, category, pageNo, pageLimit);
    }

    // Variable used for recording type of medicine selected
    // and to map the field once the item is selected
    let medicineTypes = [
        {
            value: '1',
            label: t('Cap/Tab'),
        },
        {
            value: '2',
            label: t('Syrup'),
        },
    ];

    // Variable for the dropdown of category
    let medicineCategory = [
        {
            value: 'B',
            label: t('Brand'),
        },
        {
            value: 'G',
            label: t('Generic'),
        },
    ];

    /**
     *  medicine onsubmit
     * @param {} value
     */

    function onSubmit(value) {
        setcartMedicine(selectedMedicine);

        callback();
    }

    return (
        <div className="medicine-modal">
            <div className="cart-section">
                <div className="item-selection-container">
                    <div className="cart">{t('Cart')}</div>
                </div>

                {/* Cart Section */}
                <Form onFinish={onSubmit}>
                    {selectedMedicine.map((ele, key) => {
                        return (
                            <div className="cart-items">
                                <div className="cart-item-icon">
                                    <div>{ele.name}</div>
                                    <Popconfirm
                                        title={t("Are you sure to delete this medicine?")}
                                        onConfirm={() => deleteMedicine(ele.key)}
                                        // onCancel={cancel}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <DeleteOutlined className="delete" />
                                    </Popconfirm>
                                </div>

                                {/**selected medicine details   start*/}
                                <Form
                                    initialValues={{
                                        type: ele.type,
                                        direction: ele.direction,
                                    }}
                                >
                                    <Form.Item name="type" label={t("Type")}>
                                        <Select
                                            style={{ width: 120 }}
                                            onChange={(value) => {
                                                OnTypeChange(value, key);
                                            }}
                                        >
                                            {medicineTypes.map((mediciineType) => (
                                                <Option value={mediciineType.label}>{mediciineType.label}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item label={t("Direction")} name="direction">
                                        <Select
                                            onChange={(i) => {
                                                onDirectionChange(i, key);
                                            }}
                                        >
                                            {generallist.map((option, key) => (
                                                <Option value={option.genl_remarks}>{option.genl_remarks}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item label={t("Days")}
                                        rules={[
                                            {
                                                // pattern: /^(\+\d{1,3}[- ]?)?\d{10}$/,
                                                pattern: /^[0-9]$/,
                                                message: t("Please enter valid days"),
                                            },
                                        ]}

                                    >
                                        <Paragraph
                                            className="remarks"
                                            editable={{
                                                onChange: (value) => {
                                                    onDaysChange(value, key);
                                                },

                                                maxLength: 4,

                                            }}
                                        >
                                            {ele.days} {' days '}
                                        </Paragraph>
                                    </Form.Item>

                                    <Form.Item label={t("Remarks")}>
                                        <Paragraph
                                            className="remarks"
                                            editable={{
                                                onChange: (value) => {
                                                    onRemarkChange(value, key);
                                                },
                                                maxLength: 100,

                                            }}
                                        >
                                            {ele.remarks}
                                        </Paragraph>
                                    </Form.Item>
                                </Form>
                            </div>
                        );
                    })}
                    <Button type="primary" htmlType="submit">
                        {t('Save')}
                    </Button>
                </Form>
            </div>
            {/* Cart Section Ends */}

            <div className="medicine-items">
                <div className="medicine-header">
                    <div className="brand">
                        <Select defaultValue="Brand" onChange={handleChange}>
                            {medicineCategory.map((record) => (
                                <Option value={record.label}>{record.label}</Option>
                            ))}
                        </Select>
                    </div>

                    {/**Component to search without repeated api call */}
                    <SearchDebounce
                        value={query}
                        onChange={(value) => {
                            setQuery(value);
                            getMedicine(value, category);
                        }}
                    />
                </div>

                {loading ? (
                    <Skeleton />
                ) : (
                    <>
                        {/* Matching Results */}

                        {/** custom preparing data */}
                        {query ? (
                            <>
                                <p>
                                    <Form onFinish={addCustomItem} initialValues={{ type: 'Cap/Tab' }}>
                                        <div className="serach">
                                            <div className="customname">
                                                <Form.Item name="customitem">
                                                    <div className="title">
                                                        <Title className="title" level={5}>
                                                            {query}
                                                        </Title>
                                                    </div>
                                                </Form.Item>

                                                <div className="new-icon">
                                                    <Tag color="green">{t('New')}</Tag>
                                                </div>
                                            </div>
                                            <div className="type">
                                                <Form.Item name="type">
                                                    <Select>
                                                        {medicineTypes.map((mediciineType) => (
                                                            <Option value={mediciineType.label}>{mediciineType.label}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </div>

                                            <div className="direction">
                                                <Form.Item name="direction" rules={[{ required: true, message: t('Please Enter Direction') }]}>
                                                    <Select>
                                                        {generallist.map((option, key) => (
                                                            <Option value={option.genl_remarks}>{option.genl_remarks}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div className="days">
                                                <Form.Item
                                                    name="days"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t('Please Enter Number of days'),
                                                        },
                                                        {
                                                            pattern: /^[ A-Za-z0-9_@./()&-]*$/, // /^[ A-Za-z0-9_@./#()&-]*$/,
                                                            message: t('Enter Valid Days!'),
                                                        },
                                                    ]}
                                                >
                                                    <InputComponent placeholder={t("No of Days")} />
                                                </Form.Item>
                                            </div>
                                            <div className="remarks">
                                                <Form.Item name="remarks" rules={[{ max: 100, message: t('Length cannot exceed 100 characters!') }]}>
                                                    <InputComponent placeholder={t("Remarks")} />
                                                </Form.Item>
                                            </div>

                                            <Button loading={btnloading} s size="small" className="button" type="primary" htmlType="submit">
                                                {t('Add New')}
                                            </Button>
                                        </div>
                                    </Form>
                                </p>
                            </>
                        ) : (
                            <p className="length">
                                {count > 0 ? <Title level={4}>{count} {t('results')}</Title> : <Title level={4}>{t('No results found')}</Title>}
                            </p>
                        )}

                        {/* Matching Results Ends */}

                        {/** select medicine items start */}
                        {filteredMedicine.map((entry, key) => (
                            <MedicineEntryCard
                                entry={entry}
                                key={key}
                                callback={(key, values) => addItem(entry, values)}
                                includeStock={includeStock}
                                medicineTypes={medicineTypes}
                                btnloading={btnloading}
                                generallist={generallist}
                            />
                        ))}
                        <div className="pagination">
                            {!loading ? (
                                <Pagination pageSize={limit} showSizeChanger={true} onChange={onPagination} defaultCurrent={page} total={count} />
                            ) : null}
                        </div>
                        {/** ends */}
                    </>
                )}
            </div>
        </div>
    );
}

/**
 *
 * @returns
 */
function MedicineEntryCard({ entry, callback, key, includeStock, medicineTypes, btnloading, generallist }) {
    const [form] = Form.useForm();

    const { t, i18n } = useTranslation(); // To Translate to another language

    /**
     * On Submission of the form
     *
     * @param {*} values
     */
    const onFinish = (values) => {
        callback(key, values);

        form.resetFields();
    };

    return (
        <>
            <Form form={form} onFinish={onFinish} key={key} initialValues={{ type: 'Cap/Tab' }}>
                <div className="card item">
                    <div className="description">
                        <div className="card-title">
                            <Title className="title" level={5}>
                                {entry.pm_nm || entry.ge_nm}
                            </Title>
                            {entry.pm_nm && includeStock ? <p>{entry.total_stock_qty} in stock</p> : null}
                        </div>

                        <Form.Item name="type" className="type" defaultValue="Cap/Tab">
                            <Select>
                                {medicineTypes.map((mediciineType) => (
                                    <Option value={mediciineType.label}>{mediciineType.label}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        {/* </Form.Item> */}
                        <div className="direction">
                            <Form.Item name="direction" rules={[{ required: true, message: t('Please Enter Direction') }]}>
                                <Select>
                                    {generallist.map((option, key) => (
                                        <Option value={option.genl_remarks}>{option.genl_remarks}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="days">
                            <Form.Item
                                name="days"
                                rules={[
                                    { required: true, message: t('Please Enter Number of days') },

                                    {
                                        pattern: /^[0-9]|(^\d{2})/,
                                        // pattern: /(^\d{6,7}$)|(^\d{5}-\d{4}$)/,
                                        message: t('Please enter valid days'),
                                    }, {
                                        max: 4,
                                        message: t('Please enter valid days'),

                                    }
                                ]}
                            >
                                <InputComponent placeholder={t("Days")} />
                            </Form.Item>
                        </div>
                        <div className="remarks">
                            <Form.Item name="remarks" rules={[{ max: 100, message: t('Length cannot exceed 100 characters!') }]}>
                                <InputComponent placeholder={t("Remarks")} />
                            </Form.Item>
                        </div>
                    </div>

                    <Button loading={btnloading} size="small" className="button" type="primary" htmlType="submit">
                        {t('Add')}
                    </Button>
                </div>
            </Form>
        </>
    );
}
// }
