/***
 *
 *
 * @description
 * @author Sneha
 */

import React, { useEffect, useState, useContext } from 'react';

import { Link, useHistory } from 'react-router-dom';

import { Card, Table, Button, Space, Typography, Skeleton, Tag, Input, Switch, Tabs, Checkbox, Modal, Select } from 'antd';

import { DateUtils, GlobalContext, getExportData, Location, ReferenceSelect, RangePicker ,useTranslation } from 'soxo-bootstrap-core';

import { Bills, Customers, Opreg, Appointments } from '../../../../models';
import { ExportReactCSV } from 'soxo-bootstrap-core';

import moment from 'moment-timezone';

import { ReloadOutlined, OrderedListOutlined, PicCenterOutlined } from '@ant-design/icons';

import OpregSearch from '../../../candidate-visit/opreg-search';

import './credit-list.scss';

const { Search } = Input;

const { TabPane } = Tabs;

const { Option } = Select;

const { Title, Text } = Typography;

export default function CreditList({ redirect_link }) {
    //Patients list array
    var [patients, setPatients] = useState([]);

    var [query, setQuery] = useState('');

    const [visible, setVisible] = useState(false);

    const { isMobile } = useContext(GlobalContext);

    const [check, setCheck] = useState(false);

    const [checkAll, setAllCheck] = useState(false);

    const [disabled, setDisabled] = useState();

    //Need to check this condition
    const [view, setView] = useState(isMobile ? true : false);

    var params = Location.search();

    const [loading, setLoading] = useState(true);

    const [limit, setLimit] = useState(10);

    const [page, setPage] = useState(1);

    // To Translate to another language
    const { t, i18n } = useTranslation();
    const [exportData, setExportData] = useState({}); // for export

    const history = useHistory();

    var starttime = moment.tz().startOf('day'),
        endtime = moment.tz().endOf('day').startOf('day');

    //Setting Range by default
    const [range, setRange] = useState([starttime, endtime]);

    useEffect(() => {
        loadData();
    }, []);

    function changeView(result) {
        setView(result);
    }

    /**
     *  columns of table for Credit list
     */

    const creditBillColums = [
        {
            title: '#',
            dataIndex: 'index',
            key: '#',
            render: (value, item, index) => {
                return (page - 1) * limit + index + 1;
            },
        },

        {
            title: t('Date'),
            key: 'created-at',
            render: (ele) => {
                if (ele.billdate) {
                    return moment(ele.billdate).format('DD/MM/YYYY');
                }
            },
        },

        {
            title: t('Dept.'),
            key: 'billdept',
            render: (ele) => {
                return (
                    <span>
                        <Tag color="green">{ele.billdept}</Tag>
                    </span>
                );
            },
            exportDefinition: (ele) => {
                return ele.billdept ? ele.billdept : '';
            },
        },

        {
            title: t('Bill No.'),
            key: 'billno',
            render: (ele) => {
                return (
                    <span>
                        <Tag color="green">{ele.billno}</Tag>
                    </span>
                );
            },
            exportDefinition: (ele) => {
                return ele.billno ? ele.billno : '';
            },
        },

        {
            title: t('OP No'),
            key: 'opb_opno',
            render: (ele) => {
                return (
                    <span>
                        <Tag color="green">{ele.opno}</Tag>
                    </span>
                );
            },

            exportDefinition: (ele) => {
                return ele.opno ? ele.opno : '';
            },
           
        },

        {
            title: t('Name'),
            key: 'Name',
            render: (ele) => {
                return ele.cusname;
            },
        },

        {
            title: t('Corporate'),
            key: 'corporate',

            render: (ele) => {
                return ele.corporate;
            },
        },
        {
            title: t('Amount'),
            key: 'billamt',
            render: (ele) => {
                return <span>{ele.billamt}</span>;
            },
            exportDefinition: (ele) => {
                return ele.billamt ? ele.billamt : '';
            },
        },

        {
            title: t('Payable'),
            // dataIndex: 'Name',
            key: 'amtpayable',
            render: (ele) => {
                return ele.amtpayable;
            },
        },

        {
            title: t('Paid'),
            key: 'totpaid',

            render: (ele) => {
                return ele.totpaid;
            },
        },

        {
            title: t('Balance'),
            key: 'balance',
            render: (ele) => {
                // Assuming ele.amtpayable and ele.totpaid are the properties containing payable and paid values
                const balance = ele.amtpayable - ele.totpaid;
                return balance;
            },
        },

        {
            title: t('Action'),
            disableExport: true,
            fixed: 'right',
            key: 'action',

            render: (ele) => {
                let redirectLink;
                if (redirect_link) {
                    redirectLink = redirect_link.replace(':id', ele.billid);
                }

                return <Link to={`${redirectLink}`}>{t('View')}</Link>;
            },
        },
    ];

    /**
     * Load Data
     *
    //  */
    function loadData() {
        if (params.allCorporate === "false") {
            setDisabled(false);
        } else {
            setDisabled(true);
            // Location.search({ allCorporate: false })
        }

        Location.search({
            //  limit: 10 ,
            start_time: moment(starttime).format('MM/DD/YYYY'),
            end_time: moment(endtime).format('MM/DD/YYYY'),
        });

        getCreditList();
    }

    /**
     * Listened for checkbox
     *
     * @param {*} event
     */
    const onChange = (event) => {
        setCheck(!check);

        setAllCheck(false);

        setDisabled(false);

        // Get the current pathname and search parameters
        const { pathname, search } = history.location;

        // Remove the 'corporateValue' parameter from the search string
        const updatedSearch = search.replace(/&?corporateValue=[^&]*/, '');

        // Use push to update the URL without triggering a full page reload
        history.push({
            pathname,
            search: updatedSearch,
        });

        // Location.search({ corporateValue: 'MS' },{method:'replace'});

        Location.search({ allCorporate: false });

        getCreditList();
    };

    /**
     * Listened for checkbox
     *
     * @param {*} event
     */
    const onChangeAll = (event) => {
        // setCheck(!check)

        setCheck(false);

        setAllCheck(!checkAll);

        setDisabled(true);
        // Get the current pathname and search parameters
        const { pathname, search } = history.location;

        // Remove the 'corporateValue' parameter from the search string
        const updatedSearch = search.replace(/&?corporateValue=[^&]*/, '');

        // Use push to update the URL without triggering a full page reload
        history.push({
            pathname,
            search: updatedSearch,
        });

        Location.search({ allCorporate: !checkAll });

        getCreditList();
    };

    //If starttime and endtime are in uprl
    if (params.start_time) {
        updateRange();
    }

    function changeView(result) {
        setView(result);
    }

    function updateRange() {
        starttime = moment.tz(params.start_time, 'Asia/Calcutta').startOf('day');

        endtime = moment.tz(params.end_time, 'Asia/Calcutta').startOf('day');

        return starttime, endtime;
    }

    function updateTime(dt) {
        setRange(dt);

        setPage(1);

        Location.search({
            //  limit: 10 ,
            start_time: moment(dt[0]).format('MM/DD/YYYY'),
            end_time: moment(dt[1]).format('MM/DD/YYYY'),
            // allCorporate: false
        });
        setQuery('');

        getCreditList(); //Patiant list get function
    }

    /**
     *  Function for corporate selection
     */

    const onSelect = (corporateValue) => {
        Location.search({ corporateValue: corporateValue });

        getCreditList();
    };

    /**
     *  Function for department selection
     */

    const handleChange = (departments) => {
        Location.search({ departments: departments });

        getCreditList();
    };

    /**
     *  Function for search
     */
    function getOp(opNo) {
        Location.search({ opNo: opNo });
        getCreditList();
    }

    /**
     * Loading the appointments
     *
     * @param {*} startTime
     * @param {*} endTime
     */
    async function getCreditList() {
        setLoading(true);
        let urlParams = Location.search();
        // Match params
        let param;

        param = {
            // corporateValue: corporateValue,
            ...urlParams,
        };

        let result;

        result = await Bills.getCreditList(param);

        if (result.data) {
            setPatients(result.data);

            loadPageConfiguration(result.data);
        } else {
            setPatients([]);
        }

        setLoading(false);
    }

    let dataSource = patients.filter((record) => {
        query = query.toUpperCase();

        if (query) {
            if (record && record.cusname && record.cusname.toUpperCase().indexOf(query) != -1) {
                return true;
            } else if (record & record.opb_mobile && record.opb_mobile.indexOf(query) != -1) {
                return true;
            }
        } else {
            return true;
        }
    });

    // Variable for the dropdown of category
    let department = [
        {
            value: 'ALL',
            label: 'ALL',
        },
        {
            value: 'OP',
            label: 'OP',
        },
        {
            value: 'IP',
            label: 'IP',
        },
        {
            value: 'PHM',
            label: 'PHARMACY',
        },
    ];

    function refresh() {
        // loadData();
        getCreditList();
    }

    /**
     *
     * generate export data for credit bills.
     */

    async function loadPageConfiguration(patients) {
        // for export data
        try {
            // Retrieve export data
            let exportDatas = await getExportData(patients, creditBillColums);
    
            // Check if exportDataColumns and exportDataHeaders arrays are non-empty
            if (Array.isArray(exportDatas.exportDataColumns) && exportDatas.exportDataColumns.length > 0 &&
                Array.isArray(exportDatas.exportDataHeaders) && exportDatas.exportDataHeaders.length > 0) {
                // Set the export data if the conditions are met
                setExportData({ exportDatas });
            }
        } catch (error) {
            console.error("Failed to load page configuration:", error);
        }
    }

    return (
        <section className="credit-list card card-shadow">
            {loading ? (
                <>
                    <Skeleton active />
                </>
            ) : (
                <>
                    <>
                        <div className="page-header">
                            <div className="page-header">
                                <div className="date-and-fltr">
                                    <RangePicker
                                        allowClear={false}
                                        inputReadOnly
                                        format={'DD/MM/YYYY'}
                                        value={range}
                                        onChange={(time) => {
                                            updateTime(time, range);
                                        }}
                                        ranges={{
                                            Today: [moment(), moment()],

                                            Yesterday: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],

                                            'This Week': [moment().startOf('week'), moment().endOf('week')],

                                            'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],

                                            'This Month': [moment().startOf('month'), moment().endOf('month')],

                                            'Last Month': [
                                                moment().subtract(1, 'month').startOf('month'),
                                                moment().subtract(1, 'month').endOf('month'),
                                            ],
                                        }}
                                    />
                                </div>

                                <>
                                    <div className="all-corporate">
                                        <Checkbox checked={checkAll} onChange={onChangeAll}>
                                            {t('All Corporate')}
                                        </Checkbox>
                                        {/* <Checkbox checked={check} onChange={onChange}>Corporate Selection</Checkbox> */}
                                    </div>
                                    <div className="select-box">
                                        <div>
                                            <label htmlFor={t("Corporate")}>
                                                <Checkbox checked={check} onChange={onChange}>
                                                    {t('Corporate Selection')}
                                                </Checkbox>
                                            </label>
                                            <ReferenceSelect
                                                label="cc_desc"
                                                mode="nura-base"
                                                model={Customers}
                                                style={{ width: '230px', marginTop: '10px', marginBottom: '20px' }}
                                                field={'cc_code'}
                                                value={!disabled ? params.corporateValue : null}
                                                onChange={(entry) => onSelect(entry)}
                                                disabled={!check}
                                            />
                                        </div>
                                        <div className="depart">
                                            <label htmlFor="departments">{t('Departments')}</label>
                                            <Select
                                                defaultValue="ALL"
                                                value={params.departments}
                                                style={{ width: '100px', marginTop: '14px', marginBottom: '20px' }}
                                                onChange={handleChange}
                                            >
                                                {department.map((record) => (
                                                    <Option value={record.label} label={t("Department")}>
                                                        {record.label}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="top-actions">
                                        {/* Export Data start */}

                                        {exportData.exportDatas ? (
                                            <ExportReactCSV
                                                headers={exportData.exportDatas.exportDataHeaders}
                                                csvData={exportData.exportDatas.exportDataColumns}
                                            />
                                        ) : null}

                                        {/* Export Data Ends */}
                                    </div>

                                    <OpregSearch
                                        enableVisit={false}
                                        callback={(record) => {
                                            setVisible(false);
                                            getOp(record.op_number);
                                        }}
                                        model={Appointments}
                                        title={t('Advance Search')}
                                    />
                                </>
                            </div>

                            <div className="right">
                                <Switch
                                    defaultChecked={view}
                                    onChange={changeView}
                                    checked={view}
                                    checkedChildren={<OrderedListOutlined />}
                                    unCheckedChildren={<PicCenterOutlined />}
                                />

                                <Button onClick={refresh} type="secondary" size={'small'}>
                                    <ReloadOutlined />
                                </Button>
                            </div>
                        </div>

                        <div>
                            {view ? (
                                <>
                                    <CardList redirect_link={redirect_link} dataSource={dataSource} />
                                </>
                            ) : (
                                <>
                                    <Card className="table-card">
                                        <Table
                                            scroll={{ x: true }}
                                            rowKey={(record) => record.da_id}
                                            dataSource={dataSource}
                                            columns={creditBillColums}
                                            pagination={{
                                                current: page,
                                                onChange(current) {
                                                    setPage(current);
                                                },
                                            }}
                                            // bordered
                                            summary={(pageData) => {
                                                let totalbillamt = 0;
                                                let totalamtpayable = 0;
                                                let totaltotpaid = 0;
                                                let totalbalance = 0;
                                                pageData.forEach((data) => {
                                                    totalbillamt += data.billamt;
                                                    totalamtpayable += data.amtpayable;
                                                    totaltotpaid += data.totpaid;
                                                    totalbalance += data.balance;
                                                    // console.log(data);
                                                    // totalRepayment += repayment;
                                                });
                                                return (
                                                    <>
                                                        <Table.Summary.Row>
                                                            <Table.Summary.Cell index={0} />
                                                            <Table.Summary.Cell index={0} />
                                                            <Table.Summary.Cell index={0} />
                                                            <Table.Summary.Cell index={0} />
                                                            <Table.Summary.Cell index={0} />
                                                            <Table.Summary.Cell index={0}>{t("Total")}</Table.Summary.Cell>
                                                            <Table.Summary.Cell index={0}></Table.Summary.Cell>
                                                            <Table.Summary.Cell index={7}>
                                                                <Text>{totalbillamt}</Text>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={2}>
                                                                <Text>{totalamtpayable}</Text>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={2}>
                                                                <Text>{totaltotpaid}</Text>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={2}>
                                                                <Text>{totalbalance}</Text>
                                                            </Table.Summary.Cell>
                                                        </Table.Summary.Row>
                                                        {/* <Table.Summary.Row>
                                                            <Table.Summary.Cell index={0}>Balance</Table.Summary.Cell>
                                                            <Table.Summary.Cell index={1} colSpan={2}>
                                                                <Text type="danger">{totalBorrow - totalRepayment}</Text>
                                                            </Table.Summary.Cell>
                                                        </Table.Summary.Row> */}
                                                    </>
                                                );
                                            }}
                                        />
                                    </Card>

                                    <p className="size-hint">{loading ? 'Loading' : patients.length} records.</p>
                                </>
                            )}
                        </div>
                    </>
                </>
            )}
        </section>
    );
}

function CardList({ dataSource, redirect_link }) {
    /**
     * Redirect to the arrived redirect link
     * on click
     *
     * @param {*} record
     */
    // function onClick(record) {

    //     // let redirectLink = `/bill/:id`;

    //     // if (redirect_link) {
    //     //     redirectLink = redirect_link;
    //     // }

    //     redirect_link = redirect_link.replace(':id', record.opb_opno);

    //     Location.navigate({
    //         url: `${redirect_link}`,
    //     });
    // }

    return dataSource.map((item, index) => {
        let redirectLink;

        redirectLink = redirect_link.replace(':id', item.billid);

        return (
            <Link
                to={`${redirectLink}`}
                key={index}
                // onClick={(bill) => {
                //     onClick(bill);
                // }}
            >
                <GuestCard record={item} />
            </Link>
        );
    });
}

/**
 *
 * @param {*} param0
 * @returns
 */
function GuestCard({ record }) {
    const { t, i18n } = useTranslation();     // To Translate to another language
    return (
        <Card className="card vehicle-card">
            <div className="title">
                <p>
                {t("Name")}: <strong>{record.cusname} </strong>
                </p>
            </div>
            <div className="values">
                <p>
                {t("Order Date")}: <strong>{moment(record.billdate).format('DD/MM/YYYY')}</strong>{' '}
                </p>
            </div>
            <div className="title">
                <p>
                {t("Bill No")}: <strong>{record.billno} </strong>
                </p>
            </div>
            <div className="title">
            {t("OP NO")}:{' '}
                <Tag color="green">
                    <strong>{record.opno}</strong>
                </Tag>
            </div>
            {/* <div className="values">
                    <p>
                        Gender : <strong>{record.opb_gender}</strong>
                    </p>
                </div>

                <div className="values">
                    <p>
                        Age: <strong> {record.opb_age}</strong>
                    </p>
                </div>

                <div className="values">
                    <p>
                        Contact:<strong>{record.opb_mobile}</strong>{' '}
                    </p>
                </div> */}
            {/* <div className="values">
                 <p>Wallet: {reward ? <Tag color="green">Active</Tag> : <Tag color="orange">Inactive</Tag>}</p>
             </div> */}
        </Card>
    );
}
