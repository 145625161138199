
/**
 * @author Jaseela
 * @description
 * ProfileModel is a  functional component that manages the profile completion process for new and existing customers.
 * It includes features such as selecting the customer mode, verifying contact details via OTP, and displaying billing details
 * 
 * Props:
 * - billingDetails: A JSON string containing billing details of the user.
 * - Id: A unique identifier for the user.
 * - values: An object containing various values needed for the registration process.
 * - props: Additional properties passed to the component.
 * 
 */
import React, { useState, useEffect} from 'react';

import { Button, Form, Input, Card, Typography, Select, Radio, Modal, message, Row, Col } from 'antd';

import { CheckCircleOutlined, CheckCircleFilled } from '@ant-design/icons';

import OTPInput from 'otp-input-react';

import {Location } from 'soxo-bootstrap-core';

import './complete-profile-model.scss';

import moment from 'moment';

import { Appointments, Settings } from '../../../../models';

import AdvanceSearch from '../../../candidate-visit/advance-search';

const { Title, Text } = Typography;

const { Option } = Select;

// Defining the ProfileModel component
export default function ProfileModel({ billingDetails, Id, values, props }) {
    let urlParams = Location.search();// Getting URL parameters
    // Modes of booking
    const modes = ['New Customer'];

    // for Billing Details
    const user = JSON.parse(billingDetails);

    // Add 'Existing Customer' mode if the booking mode is not 'Walk In'
    if (urlParams.mode !== 'Walk In') {
        modes.push('Existing Customer');
    }

    let [visible, setVisible] = useState(false); // State for modal visibility

    const [isLoading, setIsLoading] = useState(false); // State for loading indicator

    const [otpGenerated, setOtpGenerated] = useState(false); // track whether OTP is generated

    const [otpValues, setOtpValues] = useState(''); // State to store OTP values

    const [verificationOption, setVerificationOption] = useState(); // State to store verification options

    const [choice, setChoice] = useState('New Customer'); // State to store the selected customer mode

    const [useSameContact, setUseSameContact] = useState('No'); // State to track if same contact should be used

    const [isModalVisible, setIsModalVisible] = useState(false); // State for OTP verification modal visibility

    const [formSubmitted, setFormSubmitted] = useState(false); // State to track if the form has been submitted

    const [details, setDetails] = useState();// State to store contact details
    const [form] = Form.useForm();

    // const [range, setRange] = useState([moment().startOf('day'), moment().endOf('month')]); // State to store the date range
    // const dateFormat = 'YYYY-MM-DD';

    // Fetch settings data on component mount
    useEffect(async () => {
        await getSettingsData(); // Call function to get settings data
    }, []);

    /**
     *
     */
    async function getOtp() {
        setIsLoading(true); // Start loading
        let formBody = {};

        if (useSameContact === 'Yes') {
            // Use the same contact details
            formBody = {
                id: Id,
                email: user.email_verified == true ? '' : user.email,
                phone_number: user.mobile_verified == true ? '' : user.mobile,
            };
        } else {
            // Use different contact details
            formBody = {
                id: Id,
                email: details.email,
                phone_number: details.mobile,
            };
        }
        // Generate OTP Api call
        let response = await Appointments.generateOtp(formBody);
        if (response.success) {
            setIsLoading(false); // Stop loading
            message.success(response.message);
            setOtpGenerated(true); // Set OTP generated state to true
        } else {
            setIsLoading(false);
            message.error(response.message);
            // setDisable(true);
        }
    }

    /**
     * OTP verification
     */

    async function submit() {
        setIsLoading(true); //  loading

        let formBody = {};
        // Use the same contact details
        if (useSameContact === 'Yes') {
            //  the same contact
            formBody = {
                id: Id,
                otp_type: user.email_verified == true ? 'MSG' : 'EMAIL',
                otp: otpValues,
                email: user.email,
                phone_number: user.mobile,
            };
        } else {
            // Use different contact details
            formBody = {
                id: Id,
                otp_type: verificationOption.OTPemail ? 'EMAIL' : 'MSG',
                otp: otpValues,
                email: details.email,
                phone_number: details.mobile,
            };
        }

        let response = await Appointments.verifiedOtp(formBody);
        if (response.success) {
            setIsLoading(false); // loading stop
            // message.success(response.message);
            // Show verification alert
            showModal();

            setFormSubmitted(true); // Set formSubmitted to true after submitting OTP
        } else {
            setIsLoading(false);
            message.error(response.message);
            // setDisable(true);
        }
    }

    /**
     * Function to get settings data
     */
    async function getSettingsData() {
        const result = await Settings.getSettings('NBOKVALS'); // Get settings data

        const setValue = JSON.parse(result.settl_value); // Parse settings data

        setVerificationOption(setValue); // Set verification options
    }

    /**
     * Function to handle OTP input change
     * @param {*} otpValues
     */
    const handleChange = (otpValues) => {
        setOtpValues(otpValues);
    };
    /**
     * Function to show OTP verification modal
     */
    const showModal = () => {
        setIsModalVisible(true);
    };

    /**
     * Function to handle modal cancel action
     */
    const handleCancel = () => {

        // Add time to da_date, to pass selected_time in the url for edit
        var date = moment(values.da_date).format('DD/MM/YYYY');

        date = date + ' ' + values.da_aptime;

        setIsModalVisible(false); // Set modal visibility to false
        Location.navigate({
            url: `/new-registrations/complete-profile?da_id=${values.da_id}&da_packagetypevalue=${values.da_packagetypevalue}&id=${
                values.da_referenceid
            }&prereg_opno=${values.da_newopno}&mode=call&selected_time=${date}&complete_profile=true`,
        }); // Navigate to a new URL
    };

    /**
     *  Function to set form field values based on 'useSameContact' value
     * @param {*} useSameContactValue
     */
    const setFormValues = (useSameContactValue) => {
        if (useSameContactValue === 'Yes') {
            form.setFieldsValue({ contact_details: user.email_verified == true ? user.mobile : user.email });
        } else {
            form.setFieldsValue({ details: '' }); // Reset details field value
        }
    };
    return (
        <div className="complete-profile">
            {/* Different Packages */}
            <Card className="card form-step">
                <Title level={5}>Mode of Appointment</Title>

                <p>Please select the mode of Customer</p>

                <div className="registration-mode">
                    {/* Map over modes and render each mode with click handler */}
                    {modes.map((mode, key) => {
                        return (
                            <div
                                key={key}
                                className={`mode ${mode === choice ? 'active' : ''}`}
                                onClick={() => {
                                    setChoice(mode);
                                    // setSearchEnable(false);
                                }}
                            >
                                <CheckCircleOutlined />
                                {mode}
                            </div>
                        );
                    })}
                </div>
                {/* Existing Customer Section */}
                {choice === 'Existing Customer' ? (
                    <>
                        {/** Functionality for search is done in advancesearch component */}
                        <div className="card">
                            <AdvanceSearch
                                values={values}
                                setVisible={setVisible}
                                mode={'prereg'}
                                model={Appointments}
                                //  enableVisit={enableVisit}
                                // enableModal={'doctorAppointment'}
                                callback={(ele) => {
                                    // If there is an appointment , we navigate to the record
                                    // if (record.doctorAppointment && record.doctorAppointment.id) {
                                    //     Location.navigate({ url: `/appointment/${record.doctorAppointment.id}` });
                                    // } else {
                                    //     message.info('Could not find an appointment for the guest');
                                    // }
                                    Location.navigate({
                                        url: `/new-registrations/existing-guest-complete-profile?da_id=${values.da_id}&da_packagetypevalue=${
                                            values.da_packagetypevalue
                                        }&id=${ele.prereg_id}&token=${values.da_tokenno}&prereg_opno=${
                                            values.da_newopno
                                        }&mode=Existing_customer&repeat=true&selected_time=${moment(values.da_date).format(
                                            'DD/MM/YYYY'
                                        )}&table=prereg&complete_profile=true`,
                                    });
                                }}
                                title={'Advanced Search'}
                            />
                        </div>
                    </>
                ) : null}

                {/* existing customer end */}
                {/* new customer card start */}
                {choice === 'New Customer' && (
                    <>
                        <div className="card">
                            <Title type="primary" level={5}>
                                BILLING DETAILS
                            </Title>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <div className="section-label">
                                    <Text type="secondary">MOBILE NO </Text>
                                </div>
                                <div className="section-value">
                                    <strong>{user.mobile}</strong>
                                    {/* Mobile verification tick */}
                                    {user.mobile_verified == true ? <CheckCircleOutlined style={{ color: 'green', marginLeft: '5px' }} /> : null}
                                </div>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <div className="section-label">
                                    <Text type="secondary">EMAIL </Text>
                                </div>
                                <div className="section-value">
                                    <strong>{user.email}</strong>
                                    {/* Email verification tick */}
                                    {user.email_verified == true ? <CheckCircleOutlined style={{ color: 'green', marginLeft: '5px' }} /> : null}
                                </div>
                            </div>
                        </div>
                        {verificationOption && (verificationOption.OTPmobile == 'true' || verificationOption.OTPemail == 'true') ? (
                            <div className="form-content">
                                <>
                                    <Form name="billingForm" form={form} onFinish={getOtp}>
                                        <Text type="secondary">WOULD YOU LIKE TO USE THE SAME MOBILE NO AND EMAIL?</Text>
                                        <Form.Item name="sameContact" initialValue={useSameContact}>
                                            <Select
                                                onChange={(value) => {
                                                    setUseSameContact(value);
                                                    setFormValues(value);
                                                }}
                                            >
                                                <Option value="Yes">Yes</Option>
                                                <Option value="No">No</Option>
                                            </Select>
                                        </Form.Item>

                                        {useSameContact == 'No' && verificationOption && (
                                            <>
                                                <Form.Item>
                                                    <Radio.Group defaultValue={verificationOption.OTPemail == 'true' ? 'email' : 'mobile'}>
                                                        {verificationOption.OTPemail == 'true' ? <Radio value="email">Email</Radio> : ''}

                                                        {verificationOption.OTPmobile == 'true' ? <Radio value="phone">Phone Number</Radio> : ''}
                                                    </Radio.Group>
                                                </Form.Item>
                                                <Text type="secondary">VERIFY CONTACT DETAILS</Text>

                                                <Form.Item
                                                    name="details"
                                                    rules={[
                                                        {
                                                            type: 'email',
                                                            message: `The input is not valid email address`,
                                                        },
                                                        {
                                                            required: true,
                                                            message: `Please Enter Email`,
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        // placeholder={
                                                        //     verificationOption.OTPemail == "true" ? 'Enter your email address' : 'Enter your phone number'
                                                        // }
                                                        // value={verificationOption === 'email' ? details.email: details.mobile}
                                                        onChange={(e) => {
                                                            if (verificationOption.OTPemail == 'true')
                                                                setDetails({ ...details, email: e.target.value });
                                                            else setDetails({ ...details, mobile: e.target.value });
                                                        }}
                                                    />
                                                </Form.Item>
                                            </>
                                        )}
                                        {useSameContact === 'Yes' && (
                                            <>
                                                <Text type="secondary">VERIFY CONTACT DETAILS</Text>

                                                <Form.Item name="contact_details">
                                                    <Input readOnly value={user.email_verified == true ? user.mobile : user.email} />
                                                </Form.Item>
                                            </>
                                        )}

                                        <Form.Item>
                                            {/* {!otpGenerated && ( */}
                                            <Button type="primary" htmlType="submit" loading={isLoading} disabled={otpGenerated}>
                                                Get OTP
                                            </Button>
                                            {/* )} */}
                                        </Form.Item>
                                    </Form>
                                </>
                            </div>
                        ) : (
                            <>
                                <div className="submit-section">
                                    <Button onClick={handleCancel} type="primary" htmlType="submit">
                                        submit
                                    </Button>
                                </div>
                            </>
                        )}
                        {otpGenerated && !formSubmitted && (
                            <div className="card">
                                <>
                                    <div>
                                        <Text type="primary">ENTER YOUR CODE</Text>
                                        <p>Enter the verification code send to your contact details</p>
                                    </div>
                                    <Form name="otp" form={form} onFinish={submit} layout="inline" disabled={formSubmitted}>
                                        <Row gutter={20}>
                                            <Col xs={24} md={24} xl={18}>
                                                <Form.Item name="otpValues">
                                                    <OTPInput
                                                        value={otpValues}
                                                        onChange={handleChange}
                                                        autoFocus
                                                        OTPLength={6} // Specify the length of the OTP
                                                        // disabled={otpGenerated} // Disable input if OTP is already generated
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={12} xl={6}>
                                                <Form.Item>
                                                    <Button type="primary" htmlType="submit">
                                                        Submit
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                </>
                            </div>
                        )}
                        {/* Verification alert modal */}
                        <Modal visible={isModalVisible} width={'30%'} height={'40%'} footer={null}>
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                <CheckCircleFilled style={{ color: 'blue', fontSize: '38px', marginRight: '10px' }} />
                                <Text>Account Verified</Text>
                                <p>Your account has verified successfully</p>
                                <Button type="secondary" onClick={handleCancel}>
                                    Close
                                </Button>
                            </div>
                        </Modal>
                    </>
                )}

                {/* new customer card end */}
            </Card>
        </div>
    );
}
