/**
 *
 * File loader is build specific to Nura case where we need to load the file
 *
 * Initially we had seperate application for loading files ,
 * But that did not work well so we have to move to on premise
 *
 * @author Ashique Mohammed
 * @author Marvan
 *
 */
import React, { useRef, useState, useContext } from 'react';

// import Draggable from 'react-draggable';

import { Modal, Button } from 'antd';

import FileLoader from '../file-loader/file-loader';

// import { Resizable, ResizableBox } from 'react-resizable';

import { DraggableModal, DraggableModalProvider } from 'ant-design-draggable-modal';

import 'ant-design-draggable-modal/dist/index.css';

import './report-preview.scss';

let reportTypes = {
    RAW: 'json',
    RPT: 'pdf',
    IMG: 'png',
};

/**
 * Component to Show the button and preview the report
 *
 * @param {*} param0
 * @returns
 */
function ReportPreview({ config, children, className, record }) {
    // const draggleRef = useRef(null);

    // const [open, setOpen] = useState(false);

    // const [disabled, setDisabled] = useState(true);

    // const [bounds, setBounds] = useState({
    //     left: 0,
    //     top: 0,
    //     bottom: 0,
    //     right: 0,
    // });

    // Control the visibility
    const [visible, setVisible] = useState(false);

    // const onStart = (_event, uiData) => {
    //     const { clientWidth, clientHeight } = window.document.documentElement;
    //     const targetRect = draggleRef.current?.getBoundingClientRect();
    //     if (!targetRect) {
    //         return;
    //     }
    //     setBounds({
    //         left: -targetRect.left + uiData.x,
    //         right: clientWidth - (targetRect.right - uiData.x),
    //         top: -targetRect.top + uiData.y,
    //         bottom: clientHeight - (targetRect.bottom - uiData.y),
    //     });
    // };

    return (
        <>
            <Button
                className={className}
                type="secondary"
                size={'small'}
                onClick={() => {
                    setVisible(true);
                    document.body.style.overflow = 'auto';
                }}
            >
                {children || 'View'}
            </Button>

            <DraggableModalProvider>

                <DraggableModal
                    style={{ paddingLeft: '16px', paddingRight: '16px' }}
                    // destroyOnClose={true}
                    visible={visible}
                    open={visible}
                    // title='Advance Search'
                    footer={null}
                    className="report-preview"
                    onCancel={() => {
                        setVisible(false);
                        //setResult(null);
                    }}
                    width={'80%'}
                    // maskClosable={false}
                    callback={values => {
                        setVisible(values);
                    }}>

                    {/* <h1>Helo there</h1> */}

                    <ReportPreviewFileLoader config={config} selected={record} />

                    {/* <ReportPreviewFileLoader config={config} selected={record} /> */}


                </DraggableModal>

            </DraggableModalProvider>



            {/* <DraggableModal
                destroyOnClose={true}
                visible={visible}
                // title='Advance Search'
                footer={null}
                className="report-preview"
                // onCancel={() => {
                //     setVisible(false);
                //     //setResult(null);
                // }}
                width={'80%'}
                maskClosable={false}
                callback={values => {
                    setVisible(values);
                }}
            >
                <h1>Helo there</h1>

                {/* <ReportPreviewFileLoader config={config} selected={record} /> */}
            {/* </DraggableModal>} */}

            {/* <Modal

            // title={
            //     <div
            //         style={{
            //             width: '100%',
            //             cursor: 'move',
            //         }}
            //         onMouseOver={() => {
            //             if (disabled) {
            //                 setDisabled(false);
            //             }
            //         }}
            //         onMouseOut={() => {
            //             setDisabled(true);
            //         }}
            //         // fix eslintjsx-a11y/mouse-events-have-key-events
            //         // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
            //         onFocus={() => { }}
            //         onBlur={() => { }}
            //     // end
            //     >
            //         Draggable Modal
            //     </div>
            // }

            // modalRender={(modal) => (
            //     <Draggable
            //         disabled={disabled}
            //         bounds={bounds}
            //         nodeRef={draggleRef}
            //         onStart={(event, uiData) => onStart(event, uiData)}
            //     >
            //         <div ref={draggleRef}>{modal}</div>
            //     </Draggable>
            // )}

            destroyOnClose={true}
            visible={visible}
            // title='Advance Search'
            footer={null}
            className="report-preview"
            onCancel={() => {
                setVisible(false);
                //setResult(null);
            }}
            width={'80%'}
            maskClosable={false}
        >
            <ReportPreviewFileLoader config={config} selected={record} />
        </Modal> */}
        </>
    );
}

export default ReportPreview;

/**
 *
 * @param {*} value
 * @returns
 */
export function ReportPreviewFileLoader({ config, selected }) {
    console.log(selected);

    let fileName;

    if (selected.report_type === 'IMG') {
        fileName = selected.pacs_path;
    } else {
        fileName = selected.report_path;
    }

    let location = `${fileName}`;

    /**
     * In case we have different type of components that needs to be rendered
     *
     * @param {*} ele
     * @returns
     */
    const renderFile = ele => {
        switch (ele.report_type) {
            case 'IMG':
                // return <LoadPDFImg report={selected} />;
                // return (
                //     <a target="_blank" rel="noreferrer" href={selected.report_filename} className={`report-link vehicle-card card`}>
                //         <h1>PACS</h1>
                //         View PACS Image
                //     </a>
                // );
                return <FileLoader config={config} url={location} type={ele.report_type} />;
                break;
            case 'RPT':
                return <FileLoader config={config} url={location} type={ele.report_type} />;
                break;
            default:
                return <FileLoader config={config} url={location} type={ele.report_type} />;
        }
    };

    return <>{renderFile(selected)}</>;
}

/**
 *
 *
 * @param {*} param0
 * @returns
 */
// const DraggableModal = ({ children, callback, ...rest }) => {
//     const [width, setWidth] = useState(500); // Initial width

//     const [height, setHeight] = useState(100); // Initial height

//     const [visible, setVisible] = useState(rest.visible);

//     const draggleRef = useRef(null);

//     const [disabled, setDisabled] = useState(false);

//     const [bounds, setBounds] = useState({
//         left: 0,
//         top: 0,
//         bottom: 0,
//         right: 0,
//     });

//     const [dragging, setDragging] = useState(false);
//     const [position, setPosition] = useState({ x: 0, y: 0 });
//     const modalRef = useRef();

//     const onResize = (event, { size }) => {
//         setWidth(size.width);
//         setHeight(size.height);
//     };

//     const handleMouseDown = e => {
//         console.log('Down', modalRef.current.getBoundingClientRect().left);

//         if (modalRef.current && modalRef.current.contains(e.target)) {
//             setDragging(true);
//             setPosition({
//                 x: e.clientX - modalRef.current.getBoundingClientRect().left,
//                 y: e.clientY - modalRef.current.getBoundingClientRect().top,
//             });
//         }
//     };

//     const handleMouseMove = e => {
//         if (dragging) {
//             const newX = e.clientX - position.x;
//             const newY = e.clientY - position.y;
//             modalRef.current.style.left = `${newX}px`;
//             modalRef.current.style.top = `${newY}px`;
//         }
//     };

//     const handleMouseUp = () => {
//         setDragging(false);
//     };

//     const onStart = (_event, uiData) => {
//         const { clientWidth, clientHeight } = window.document.documentElement;
//         const targetRect = draggleRef.current?.getBoundingClientRect();
//         if (!targetRect) {
//             return;
//         }
//         setBounds({
//             left: -targetRect.left + uiData.x,
//             right: clientWidth - (targetRect.right - uiData.x),
//             top: -targetRect.top + uiData.y,
//             bottom: clientHeight - (targetRect.bottom - uiData.y),
//         });
//     };

//     const handleOk = () => {
//         setVisible(false);
//     };

//     return (
//         // <Draggable handle=".ant-modal-header">

//         <Modal
//             style={{
//                 position: 'fixed',
//                 // transform: 'translateX(-50%)',
//                 left: (document.body.clientWidth - 500) / 2,
//             }}
//             wrapClassName="report-view-modal-wrapper"
//             mask={false}
//             maskClosable={false}
//             visible={rest.visible}
//             onOk={handleOk}
//             // onCancel={onClose}
//             width={width}
//             footer={null}
//             onCancel={() => {
//                 // setVisible(false);
//                 callback(false);
//             }}
//             // maskStyle={{ backgroundColor: 'transparent' }}

//             title={
//                 <div
//                     style={{
//                         width: '100%',
//                         cursor: 'move',
//                     }}
//                     onMouseOver={() => {
//                         if (disabled) {
//                             // setDisabled(false);
//                         }
//                     }}
//                     onMouseOut={() => {
//                         // setDisabled(true);
//                     }}
//                     // fix eslintjsx-a11y/mouse-events-have-key-events
//                     // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
//                     onFocus={() => { }}
//                     onBlur={() => { }}
//                 // end
//                 >
//                     Draggable Modal
//                 </div>
//             }
//             modalRender={modal => (
//                 <Resizable
//                     width={width}
//                     height={height}
//                     onResize={onResize}
//                     minConstraints={[200, 200]}
//                     maxConstraints={[800, 600]}
//                     draggableOpts={{ enableUserSelectHack: false }}
//                     resizeHandles={['sw', 'se', 'nw', 'ne', 'w', 'e', 'n', 's']}
//                 >
//                     {/* <div className="resizable-content"> */}
//                     {/* <Draggable disabled={disabled} bounds={bounds} nodeRef={draggleRef} onStart={(event, uiData) => onStart(event, uiData)}> */}
//                     <div ref={draggleRef}>{modal}</div>
//                     {/* </Draggable> */}

//                     {/* </div> */}
//                 </Resizable>
//             )}
//             // {...rest}
//             ref={modalRef}
//             onMouseDown={handleMouseDown}
//             onMouseMove={handleMouseMove}
//             onMouseUp={handleMouseUp}
//         >


//             {children}


//         </Modal>

//         // </Draggable>
//     );
// };

// export default DraggableModal;
