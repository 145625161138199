/***
 *
 *
 * @description
 * @author Sneha
 */

import React, { useEffect, useState, useContext } from 'react';

import { Button, Typography, Form, Modal, Input, message, Statistic, Tabs, Tag, Space, Skeleton, InputNumber, Switch, Checkbox } from 'antd';

import { Card, ConfirmModal, DateUtils, ReferenceSelect, GlobalContext, Location } from 'soxo-bootstrap-core';

import { Bills, Registrations, RewardTransactions, RewardMasters } from '../../models';

import BillInfo from './../billing/components/bill-info/bill-info';

import { PortletTable, InputComponent, useTranslation } from 'soxo-bootstrap-core';

import './create-rewards.scss';

const { Title } = Typography;
const { TextArea } = Input;

const { TabPane } = Tabs;

export default function CreateRewards({ match, onUpdate, reward_master_id, isAuthorize, ...props }) {
    const { user = {} } = useContext(GlobalContext);

    const [loading, setLoading] = useState(true);
    // Reward list
    const [wallet, setWallet] = useState({ details: [] });

    const [visible, setVisible] = useState(false);
    const [tittle, setTittle] = useState();

    const [redeemvisible, setRedeemvisible] = useState(false);

    const [issuevisible, setIssuevisible] = useState(false);

    let urlParams = Location.search();

    const { params } = match;

    const { t, i18n } = useTranslation();    // To Translate to another language

    useEffect(() => {
        rewardList(params.opno);
    }, []);

    /**
     * Reward List
     */
    async function rewardList(opno) {
        setLoading(true);

        Bills.rewardList(reward_master_id, 'OPNO', opno).then((result) => {
            if (result.result) {
                if (result.result.status === 'A') {
                    setWallet({
                        ...result.result,
                        status: 'Active',
                    });
                } else {
                    setWallet({
                        ...result.result,
                        status: 'Under Processing ',
                    });
                }

                setLoading(false);
            } else {
                /**
                 * Get Active list
                 *
                 * @param {*} opno
                 */
                Registrations.getOpregDetail(opno).then((result) => {
                    if (result) {
                        setWallet({
                            // ...result,
                            opreg: result,
                            status: 'Inactive',
                        });
                    }

                    setLoading(false);
                });
            }
        });
    }

    function refresh() {
        rewardList(params.opno);
    }

    /**
     *  couponvisibilty
     */

    function couponvisibility() {
        setVisible(true);
    }

    /**
     *  columns of table
     */

    let columns = [
        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => index + 1,
        },

        {
            title: t('Date'),
            dataIndex: '',
            render: (record) => {
                // console.log("records", record);
                return DateUtils.formatDate(record.created_at);
            },
        },
        {
            title: t('Coupon'),
            dataIndex: '',
            render: (record) => {
                return record.coupon_code;
            },
        },
        // {
        //     title: 'OP No',
        //     dataIndex: 'opno',
        //     // render: (record) => {
        //     //     return (record.account_code)
        //     // }

        // },
        {
            title: t('Points'),
            dataIndex: 'data',

            render: (record) => {
                if (record.transaction === -1) {
                    return ` ${record.transaction * record.points}`;
                } else {
                    return ` + ${record.transaction * record.points}`;
                }
            },
        },
        {
            title: t('Mode'),
            key: 'data',

            render: (record) => {
                let mode = record.mode;
                let status = {
                    content: {
                        AI: 'Activate and Issue',
                        I: 'Issue',
                        GG: 'Gain General',
                        RD: 'Redemption Direct',
                    },

                    color: {
                        AI: 'green',
                        I: 'blue',
                        GG: 'orange',
                        RD: 'orange',
                    },
                };

                return <Tag color={status.color[mode]}>{status.content[mode]}</Tag>;
            },
        },
        {
            title: t('Details'),
            dataIndex: '',
            render: (record) => {
                if (record.reference_details) {
                    let details = JSON.parse(record.reference_details);

                    return `${details.name} with OPNO ${details.opno} completed screening`;
                } else {
                    return '';
                }
            },
        },
        // {
        //     title: 'Remarks',
        //     dataIndex: '',
        //     render: (record) => {
        //         return (record.remarks)
        //     }

        // }
    ];

    /**
     *  Table Actions
     */

    let tableActions = [];

    if (isAuthorize) {
        tableActions.push({
            render: (record) => {
                return (
                    <>
                        {record.status === 'E' ? (
                            <ConfirmModal
                                caption={'Authorize'}
                                callback={() => {
                                    onAuthorize(record);
                                }}
                            />
                        ) : (
                            <Tag color={'blue'}>{t('Authorized')}</Tag>
                        )}
                    </>
                );
            },
        });
    }
    /**
     * Authorize
     *
     * @param {*} record
     */
    async function onAuthorize(record) {
        const { opreg } = wallet;

        await RewardTransactions.authorize(record, opreg, reward_master_id);

        message.success('Approved');

        refresh();
    }

    /**
     * On enabling the wallet
     */
    function onWalletEnable(result) {
        setVisible(false);
    }

    return (
        <section className="customer-rewards">
            {/* Page Header */}
            <div className="page-header">
                {/* <div className="left">
                    <Title level={2}>Wallet Account</Title>
                </div> */}

                {/* <div className="page-actions">
                    <Button
                        className="billing"
                        onClick={() => {
                            refresh();
                        }}
                        type="secondary"
                        // shape="round"
                    >
                        Refresh
                    </Button>
                </div> */}
            </div>

            {loading ? (
                <Skeleton />
            ) : (
                <>
                    <Card>
                        {/*  Page header for Bill details*/}
                        <div className="wallet-info">
                            <BillInfo className="card" guest={wallet} />

                            {/* Wallet Details */}
                            <div className="card wallet-details">
                                <Tag color={wallet.status === 'Active' ? 'green' : 'orange'}>{wallet.status}</Tag>

                                <Statistic title={t("Accumulated Points")} value={wallet && wallet.accumulated_points} />

                                <Statistic title={t("Balance Points")} value={wallet && wallet.balance_points} />

                                <p message="Info" description={`Wallet is ${wallet.status} for this account`} type="info" showIcon>
                                    Wallet is {wallet.status} for this account
                                </p>
                            </div>
                            {/* Wallet Details Ends */}
                        </div>
                        {/*  Page header for Bill ends*/}

                        {/* wallet enable starts */}
                        <div className="billing">
                            <Space>
                                {wallet.status == 'Inactive' ? (
                                    <>
                                        <div>
                                            <Button
                                                className="billing"
                                                onClick={() => {
                                                    couponvisibility();
                                                    setTittle('Enable wallet');
                                                }}
                                                type="secondary"
                                                shape="round"
                                                // size="small"
                                                color="green"
                                            >
                                                {t('Enable Wallet')}
                                            </Button>
                                        </div>
                                    </>
                                ) : null}

                                {/* If the balance points > 0 , Add the option to redeem points */}

                                {wallet.status === 'Active' ? (
                                    <Button
                                        className="billing"
                                        onClick={() => {
                                            setRedeemvisible(true);
                                        }}
                                        type="secondary"
                                        shape="round"
                                        color="green"
                                    >
                                        {t('Redeem Points')}
                                    </Button>
                                ) : null}
                                {wallet.status === 'Active' ? (
                                    <Button
                                        className="billing"
                                        onClick={() => {
                                            couponvisibility();
                                            setTittle('Issue Wallet');
                                        }}
                                        type="secondary"
                                        shape="round"
                                        color="green"
                                    >
                                        {t('Issue Points')}
                                    </Button>
                                ) : null}
                            </Space>
                        </div>
                    </Card>

                    <Tabs defaultActiveKey="0">
                        <TabPane tab={`Wallet Transactions`} key={0}>
                            <PortletTable columns={columns} records={wallet.details} actions={tableActions} />
                        </TabPane>
                    </Tabs>
                </>
            )}

            {/*  Wallet enable ends */}

            {/*  Enable Wallet Modal  */}
            <Modal
                destroyOnClose={true}
                footer={null}
                title={tittle}
                visible={visible}
                okText="Okay"
                onOk={() => {
                    setVisible(false);
                }}
                onCancel={() => {
                    setVisible(false);
                }}
            >
                <WalletEnableModal onWalletEnable={onWalletEnable} wallet={wallet} refresh={refresh} params={urlParams} />
            </Modal>
            {/*  Enable Wallet Modal Ends */}

            {/*  Redemption Modal  */}
            <Modal
                destroyOnClose={true}
                footer={null}
                title={t("Create Redemption Request")}
                visible={redeemvisible}
                okText="Okay"
                onOk={() => {
                    setRedeemvisible(false);
                }}
                onCancel={() => {
                    setRedeemvisible(false);
                }}
            >
                <RedemptionModal
                    wallet={wallet}
                    callback={() => {
                        refresh();

                        setRedeemvisible(false);
                    }}
                />
            </Modal>
        </section>
    );
}

const layout = {
    labelCol: {},
    wrapperCol: {},
};
const tailLayout = {
    wrapperCol: {},
};

/**
 *Validation of coupon checking api
 *
 * @returns
 */
function WalletEnableModal({ onWalletEnable, refresh, wallet, params }) {
    const { opreg } = wallet;

    const [loading, setLoading] = useState(false);

    const [coupon, setCoupon] = useState(null);

    const [form] = Form.useForm();

    const [rewardSchema, setRewardSchema] = useState();

    const { t, i18n } = useTranslation();    // To Translate to another language

    /**
     * According to the reward Schema render the inputs
     */
    const onRewardSchemaChange = (values) => {
        let dataRecord;
        // check whether there is otherdeatils is present
        if (values.record.other_details) {
            // convert to jison format
            dataRecord = JSON.parse(values.record.other_details);
        }

        if (dataRecord && dataRecord.controls.length !== 0) {
            return dataRecord.controls.map((ele) => {
                switch (ele.type) {
                    case 'ValidateCoupon':
                        return (
                            <>
                                <Form.Item
                                    label={t("Coupon")}
                                    name="coupon"
                                    rules={[{ required: true, message: t('Please Enter Coupon') }]}
                                    onChange={validatecoupon}
                                >
                                    <InputComponent />
                                </Form.Item>

                                <Form.Item
                                    label={t("Number of Coupons")}
                                    name="coupon_numbers"
                                    rules={[{ required: true, message: t('Please Enter Coupon Numbers') }]}
                                >
                                    <InputNumber />
                                </Form.Item>
                            </>
                        );

                    default:
                        return <></>;
                }
            });
        } else {
            return (
                <>
                    <Form.Item disabled label={t("Points")} name="points" rules={[{ required: true, message: t('Please Enter Points to redeem') }]}>
                        <Input readOnly={true} />
                    </Form.Item>
                </>
            );
        }
    };
    /**
     * Function to enable the wallet for the account
     *
     * @returns
     */
    function onFinish(values) {
        setLoading(true);
        if (coupon) {
            var formbody = {
                reward_master_id: rewardSchema.value,
                account_code: opreg.op_no,
                remarks: '',
                type: 'BIL',
                mode: 'ITM',
                items: [
                    {
                        coupon_code: values.coupon,
                        numbers: values.coupon_numbers,
                        remarks: values.remarks,
                        reference_id: params.opb_id,
                        reference_no: params.opb_no,
                    },
                ],
            };

            return Bills.createRewards(formbody).then((result) => {
                setLoading(false);

                if (result.success) {
                    message.success(result.message);

                    refresh();

                    onWalletEnable(result);
                } else {
                    message.error(result.message);
                }
            });
        } else {
            message.error('Please cross check a valid coupon');
        }
    }

    /**
     * function used to validate coupon number
     * @param {*} event
     */
    function validatecoupon(event) {
        let value = event.target.value;
        let formBody = {
            coupon_no: value,
        };
        if (value) {
            Bills.validatecoupon(formBody)
                .then((result) => {
                    if (result.success) {
                        let data = result.data;

                        setCoupon(result);

                        form.setFieldsValue({ coupon_numbers: data.available_numbers });

                        setLoading(false);

                        message.success(result.message);
                    } else {
                        // .catch((error) => {
                        setLoading(false);

                        message.error(result.message);
                        // });
                    }
                })
                .catch((error) => {
                    console.log(error);

                    setLoading(false);

                    message.error('Invalid Coupon');
                });
        }
    }

    return (
        <>
            <p>Share details for the coupon that is shared with the guest while activating the wallet</p>

            <Form
                {...layout}
                form={form}
                layout="vertical"
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFieldsChange={(fields) => {
                    // if (fields[0].name[0] === "coupon") {
                    //     console.log(fields[0].value)
                    //     handleFieldsChange(fields[0].value)
                    // }
                }}
            >
                <Form.Item label={t("Reward Scheme")} name="reward_master_id" rules={[{ required: true, message: t('Please select Reward Scheme') }]}>
                    <ReferenceSelect
                        label="description"
                        mode="nura-single"
                        model={RewardMasters}
                        onChange={(value, select) => {
                            setRewardSchema(select);
                        }}
                    />
                </Form.Item>
                {rewardSchema ? onRewardSchemaChange(rewardSchema) : null}

                {/* <Form.Item label="Coupon" name="coupon" rules={[{ required: true, message: 'Please Enter Coupon' }]} onChange={validatecoupon}>
                    <InputComponent />
                </Form.Item>

                <Form.Item label="Number of Coupons" name="coupon_numbers" rules={[{ required: true, message: 'Please Enter Coupon Numbers' }]}>
                    <InputNumber />
                </Form.Item> */}

                <Form.Item name="remarks" label={t("Remarks")} rules={[{ required: true, message: t('Please Enter Reamrks') }]}>
                    <TextArea rules={[{ required: true, message: t('Please enter your remarks') }]} />
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button loading={loading} type="primary" htmlType="submit">
                        {t("Save")}
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}

/**
 *Validation of coupon checking api
 *
 * @returns
 */
function RedemptionModal({ wallet, callback }) {
    const { opreg } = wallet;

    const [loading, setLoading] = useState(false);

    const [triggerMail, setTriggerMail] = useState(false);

    const { t, i18n } = useTranslation();  // To Translate to another language

    // console.log('guest det',guest)
    /**
     *
     * @param {*} values
     *
     */
    const onFinish = (values) => {
        setLoading(true);

        let formBody = {
            account_id_type: 'OPNO',
            account_code: opreg.op_no,
            reward_master_id: values.reward_master_id,
            triggerPaymentLink: triggerMail,
        };

        if (values.points > 0) {
            return RewardTransactions.createRedemptionRequest({ formBody })
                .then((result) => {
                    // console.log(result);

                    if (result.success) {
                        setLoading(false);
                    } else {
                        // .catch((error) => {
                        setLoading(false);

                        message.error(result.message);
                        // });
                    }

                    callback();
                })
                .catch((error) => {
                    setLoading(false);

                    message.error('Something went wrong');
                });
        } else {
            message.error('You can redeem only if the balance points are above 0');
        }
    };
    /***
     * Controlling Payment Link to the guest
     *
     *
     */
    function onCheckMailTrigger(e) {
        if (e.target.checked) {
            setTriggerMail(true);
        } else {
            setTriggerMail(false);
        }
    }

    return (
        <>
            <p>{t('Mention the Amount')}</p>

            <Form
                {...layout}
                name="basic"
                layout="vertical"
                initialValues={{
                    points: wallet.balance_points,
                    remember: true,
                }}
                onFinish={onFinish}
                onFieldsChange={(fields) => {}}
            >
                <Form.Item label={t("Reward Scheme")} name="reward_master_id" rules={[{ required: true, message: t('Please select Reward Scheme') }]}>
                    <ReferenceSelect label="description" mode="nura-single" model={RewardMasters} />
                </Form.Item>

                <Form.Item disabled label={t("Points")} name="points" rules={[{ required: true, message: t('Please Enter Points to redeem') }]}>
                    <InputComponent readOnly={true} />
                </Form.Item>
                <Form.Item valuePropName="checked" name="mail">
                    <Checkbox onChange={onCheckMailTrigger}>{t('Trigger Payment Link')}</Checkbox>
                </Form.Item>

                <Form.Item name="remarks" label={t("Remarks")} rules={[{ required: true, message: t('Please Enter Reamrks') }]}>
                    <TextArea rules={[{ required: true, message: t('Please enter your remarks') }]} />
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button loading={loading} type="primary" htmlType="submit">
                        {t("Save")}
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}
