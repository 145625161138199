/**
 * @description
 * Component used to get Other details of guest
 *
 *
 */
import { Row, Col, Form, Select, Typography } from 'antd';

import React from 'react';

import { InputComponent } from 'soxo-bootstrap-core';

import './other-details.scss';
const { Option } = Select;

const { Title } = Typography;

const formItemLayout = {
    layout: 'vertical',
};

export function OtherDetailsOfGuest({ config }) {

    // Additional Fields from config
    let { add_on_fields = [], fields = {} } = config;

    console.log(config);

    return (
        <div className="other-detail-guest">
            <div className="form-content">
                {/** Relative details section start */}
                <div className="relative-details">
                    {/* <div>
                        <Title level={5}>RELATIVE DETAILS</Title>
                    </div> */}

                    
                </div>

                {/** Relative details section end */}

                {/** Other details section start */}

                <div className="other-detail">
                    {/* <div>
                        <Title level={5}>OTHER DETAILS</Title>
                    </div> */}

                    
                </div>

                {/** Other details section Ends */}

                {/** Referral details section start */}

                <div className="referral-details">
                    {/* <div>
                        <Title level={5}>REFERRAL DETAILS</Title>
                    </div> */}

                    <Row gutter={20}>

                    {/* {
                    fields.health_facility.visible
                        ?
                        <Col xs={24} md={12} xl={8}>
                            <Form.Item name="health_facility" label={fields.health_facility.caption} rules={[{ required: fields.health_facility.required, message: `Please enter ${fields.health_facility.caption}` }]}>
                                <InputComponent />
                            </Form.Item>
                        </Col>
                        :
                        null
                      }
                    {
                    fields.ethnic_code.visible
                        ? <Col xs={24} md={12} xl={8}>
                            <Form.Item name="ethnic_code" label="ETHNIC CODE(1-6)" rules={[{ required: fields.ethnic_code.required, message: `Please Select ${fields.ethnic_code.caption}` }]}>
                                <Select>
                                    {
                                        [1, 2, 3, 4, 5, 6]
                                            .map((record) =>
                                                <Option value={record}>
                                                    {record}
                                                </Option>
                                            )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        :
                        null
                } */}

                        {
                            fields.referral_type.visible
                                ?
                                <Col xs={24} md={12} xl={8}>
                                    <Form.Item name="referral_type" label={fields.referral_type.caption}>
                                        <InputComponent />
                                    </Form.Item>
                                </Col>
                                :
                                null
                        }

                        {
                            fields.referral_name.visible
                                ?
                                <Col xs={24} md={12} xl={8}>
                                    <Form.Item name="referral_name" label={fields.referral_name.caption}>
                                        <InputComponent />
                                    </Form.Item>
                                </Col>
                                :
                                null
                        }

                    </Row>
                </div>
                {/** Referral details section Ends */}

                {/* Additional Fields */}
                {
                    Object.keys(add_on_fields).length
                        ?
                        <div className="referral-details">
                            {/* <div>
                                <Title level={4}>Additional Fields</Title>
                            </div> */}

                            <Row gutter={20}>

                                {
                                    Object.keys(add_on_fields).filter((key) => add_on_fields[key].visible).map((key) => {

                                        let field = add_on_fields[key];

                                        return (<Col>
                                            <Form.Item name={field.field} label={field.caption} rules={[
                                                {
                                                    required: field.required,
                                                    message: `Please Enter  ${field.caption}`,
                                                }]}>
                                                <InputComponent />
                                            </Form.Item>

                                        </Col>)

                                    })
                                }

                            </Row>
                        </div>
                        :
                        null
                }
                {/* Additional Fields Ends */}

            </div>
        </div>
    );
}
