/**
 * 
 * Component is supposed to preview the documents from the docdetails table 
 * load the files from the file server/or the location and then 
 * preview it 
 * 
 * @author Ashique Mohammed
 */
import React, { useEffect, useState, useContext } from 'react';

// File loader can load the files from the remote path 
import FileLoader from './../file-loader/file-loader';

import { DocDetails } from './../../models/';

import { Camera } from 'soxo-bootstrap-core';

import './document-preview.scss';

/**
 * 
 * @param {*} param0 
 */
export default function DocumentPreview({ id, onDocumentUpload, ...props }) {

    // var id = props.match.params.id;

    // Images 
    const [images, setImages] = useState([]);

    const [selected, setSelected] = useState(null);

    useEffect(() => {

        // Load the files
        loadFiles();

    }, []);

    /**
     * Load the files
     */
    async function loadFiles() {

        const result = await DocDetails.loadFilesById(id, props.props.onPremiseUrl);

        // To filter images according to type
        const images= result.filter((image)=>image.docFileTypes.file_type_code===props.props.type)

        setImages(images);
        setSelected(images[0]);
    }

    const selectImage = (fileLocation) => {
        setSelected(fileLocation);
    }

    return (
        <>

            {/* Camera Component with Advanced View */}
            <Camera
                multiple={true}
                onUpload={(attachments) => {
                    onDocumentUpload(attachments, images.length)
                }}
                mode={'rear'}
            />
            {/* Camera Component with Advanced View Ends */}

            <div className='document-preview-component'>

                {/* <Button>View Images</Button> */}
                <div className='left-container'>
                    {selected ? <FileLoader config={props.props.reportConfig} url={selected.docdet_location} type={selected.docdet_nativefiletype} consent={props.props.reportConfig.documentType} onPremiseUrl={props.props.onPremiseUrl} /> : null}
                </div>
                <div className='right-container'>
                    {
                        images && images[0] && images.map((fileLocation) => {
                            return (
                                <div className='image-name-container'>
                                    <div className='card' style={selected && selected.docdet_filename == fileLocation.docdet_filename ? { border: '1px solid blue' } : {}} onClick={() => { selectImage(fileLocation) }}>
                                        {/* <FileLoader url={fileLocation} /> */}
                                        {fileLocation.docdet_filename}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

            </div>
        </>
    )
}
