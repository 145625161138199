/**
 * Component to Show Formula Calculation
 *
 *
 * @author Nihala Dilshi
 */

import React, { useState } from 'react';

import { Typography, Timeline, Select, Row, Form, message, Input } from 'antd';

import moment from 'moment-timezone';

import { useTranslation} from 'soxo-bootstrap-core';

import './result-log.scss';

import { UserOutlined } from '@ant-design/icons';


export default function ResutLog({ callback, guest = {}, mode, selected, opbill, labReportResult, collectedTime, collectedRemarks, recievedTime, recievedRemarks}) {
    console.log(mode, selected)

    var [result, setResult] = useState([]);

    const [value, setValue] = useState();

    const { t, i18n } = useTranslation(); // To Translate to another language

    const { Title } = Typography;

    const { Option } = Select;

    const [form] = Form.useForm();

    let gender = null;


    if (guest.opb_gender === 'Male' || guest.opb_gender === 'M') {

        gender = t('Male');

    } else {

        gender = t('Female');

    }

    return (
        <>
            <div className="detail-element">
                {/* <div className="guest-info ">
                    <div className="registration-info card">
                        <div className="detail-element-card" style={{ marginRight: '4rem' }} >
                            <Row>
                                <span className="details">NAME</span>
                            </Row>
                            <Row>
                                <h3>{guest.opb_name}</h3>
                            </Row>
                        </div>

                        <div className="detail-element-card" style={{ marginRight: '4rem' }}>
                            <Row>
                                <span className="details">GENDER</span>
                            </Row>
                            <Row>
                                <h3>{gender === 'Male' ? 'Male' : 'Female'}</h3>
                            </Row>
                        </div>

                        <div className="detail-element-card" style={{ marginRight: '4rem' }}>
                            <Row>
                                <span className="details">OP NO:</span>
                            </Row>
                            <Row>
                                <h3>{guest.opb_opno}</h3>
                            </Row>
                        </div>

                        <div className="detail-element-card" style={{ marginRight: '4rem' }}>
                            <Row>
                                <span className="details">ORDER</span>
                            </Row>
                            <Row>
                                <h3>{guest.opb_bno}</h3>
                            </Row>
                        </div>
                    </div>
                </div> */}
                {mode === 'visit' ? null : (
                    <div className="card card-shadow lab-result">
                        <div className="timeline-wrapper">
                            {/* Timeline is to show the status of each test. Also the time it was done*/}
                            <Timeline>
                                <Timeline.Item color="green">
                                    {t('Ordered')}
                                    <p>
                                        <small>{moment.tz(opbill[0].opb_tm, '').format('DD/MM/YYYY HH:mm a')}</small>
                                    </p>
                                </Timeline.Item>

                                <Timeline.Item
                                    color={
                                        (selected && selected.status === 'Collected') ||
                                            (selected && selected.status === 'Lab Recieved')
                                            ? 'green'
                                            : 'gray'
                                    }
                                >
                                    {t('Sample Collected')}
                                    <p>
                                        <small>
                                            {(selected && selected.status === 'Collected') ||
                                                (selected && selected.status === 'Lab Recieved') ? (
                                                <>
                                                    <>{moment.tz(collectedTime, '').format('DD/MM/YYYY HH:mm a')}</>
                                                </>
                                            ) : (
                                                <>{t('Pending')}</>
                                            )}
                                        </small>
                                    </p>
                                    <p>
                                        <small>
                                            {(selected && selected.status === 'Collected') ||
                                                (selected && selected.status === 'Lab Recieved') ? (
                                                <>
                                                    <>{collectedRemarks}</>
                                                </>
                                            ) : null}
                                        </small>
                                    </p>
                                </Timeline.Item>

                                <Timeline.Item color={selected && selected.status === 'Lab Recieved' ? 'green' : 'gray'}>
                                    {t('Lab In')}
                                    <p>
                                        <small>
                                            {recievedTime === null
                                                ? 'Pending'
                                                : moment.tz(recievedTime, '').format('DD/MM/YYYY HH:mm a')}
                                        </small>
                                    </p>
                                    <p>
                                        <small>{recievedRemarks === null ? null : recievedRemarks}</small>
                                    </p>
                                </Timeline.Item>

                                {/* Once the result is entered, status of test changes to test done and also show the time it was done */}
                                <Timeline.Item
                                    color={
                                        labReportResult.result &&
                                            labReportResult.result.lbtrs_istestdone &&
                                            labReportResult.result.lbtrs_istestdone === 'Y' &&
                                            selected.status !== 'Rejected'
                                            ? 'green'
                                            : 'gray'
                                    }
                                >
                                    {t('Test Done')}
                                    <p>
                                        <small>
                                            {labReportResult.result &&
                                                labReportResult.result.lbtrs_testdonedttm &&
                                                selected.status !== 'Rejected'
                                                ? moment
                                                    .tz(labReportResult.result.lbtrs_testdonedttm, '')
                                                    .format('DD/MM/YYYY HH:mm a')
                                                : 'pending'}
                                        </small>
                                    </p>
                                    <p>
                                        <small>
                                            {labReportResult.result &&
                                                labReportResult.result.lbtrs_remarks &&
                                                selected.status !== 'Rejected'
                                                ? labReportResult.result.lbtrs_remarks
                                                : null}
                                        </small>
                                    </p>
                                </Timeline.Item>

                                {/* Once result is authorised status changes to closed and authorised time is shown  */}
                                <Timeline.Item
                                    color={
                                        labReportResult.result &&
                                            labReportResult.result.lbtrs_isclosed === 'Y' &&
                                            selected.status !== 'Rejected'
                                            ? 'green'
                                            : 'gray'
                                    }
                                >
                                    {t('Closed')}
                                    <p>
                                        <small>
                                            {labReportResult.result && labReportResult.result.lbtrs_isclosed === 'Y' ? (
                                                labReportResult.result &&
                                                    labReportResult.result.lbtrs_closeddttm &&
                                                    selected.status !== 'Rejected' ? (
                                                    moment
                                                        .tz(labReportResult.result.lbtrs_closeddttm, '')
                                                        .format('DD/MM/YYYY HH:mm a')
                                                ) : null
                                            ) : (
                                                <>{t('Pending')}</>
                                            )}
                                        </small>
                                    </p>
                                </Timeline.Item>
                            </Timeline>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

