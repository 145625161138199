/**
 * Bill Model
 *
 *
 *
 *
 */
import React, { useState, useEffect, useContext } from 'react';

import { ApiUtils, BaseAPI } from 'soxo-bootstrap-core';

import { Tag, Card, Typography, Button } from 'antd';
// import { Referrers } from '.';

// import BillDetailView from './../modules/common/components/bill-detail/bill-detail';

// import BillDetail from './../modules/common/components/bill-detail/bill-detail';

const { Text } = Typography;

const moment = require('moment-timezone');

class Referrer extends BaseAPI {
    constructor() {
        super();

        this.fields = [
            {
                field: 'opb_name',
                caption: 'Name',
            },
            {
                field: 'opb_opno',
                caption: 'num',
            },
            // {
            //     field: 'cold_tm',
            //     caption: 'Date'
            // },
            // {
            //     field: 'Collected By',
            //     caption: 'cold_user'
            // }
        ];

        // this.columns = ;
    }

    get id() {
        return 'referrer_id';
    }

    get getEndpoint() {
        return 'referrer';
    }

    get path() {
        return `referrer`;
    }

    get getName() {
        return this.modelName;
    }

    get modelName() {
        return `referrer`;
    }

    disableAdd = () => {
        return true;
    };

    checkCode = code => {
        return ApiUtils.get({
            url: `referrer/validate?code=${code}`,
        });
    };

    getReferrerWithId = code => {
        return ApiUtils.get({
            url: `referrer/referrer-code/${code}`,
        });
    };

    /**
     * Add referrer details
     * @param {*} code
     * @returns
     */
    addReferrer = ({values}) => {
    //    let formBody = values
       
        var formBody = {
            ...values,
        };
        return ApiUtils.post({
            url: `referrer/create-referrer`,
            formBody,
            headers: {
                db_ptr: 'nuraho',
            },
        });
    };

    /**
     * Edit referrer details
     * @param {*} code
     * @returns
     */
    // editReferrer = (code, formBody) => {
    //     return ApiUtils.put({
    //         url: `referrer/${code}`,
    //         formBody,
    //     });
    // };

       /**
     * Edit referrer details
     * @param {*} code 
     * @param {*} formBody 
     * @returns 
     */
       editReferrer = (code, formBody) => {
        return ApiUtils.put({
            url: `referrer/update-referrer/${code}`,
            formBody,
            headers: {
                Authorization: 'Bearer' + ' ' + localStorage.access_token,
                db_ptr: 'nuraho',
            },
        });
    };

    get columns() {
        return [
            {
                field: 'opb_name',
                render: record => {
                    return record.opb_name;
                },
                caption: 'Name',
            },
            {
                field: 'opb_gender',
                render: record => {
                    return <Tag color={record.opb_gender === 'Male' ? 'green' : 'orange'}>{record.opb_gender}</Tag>;
                },
                caption: 'Gender',
            },
            {
                field: 'opb_age',
                render: record => {
                    return record.opb_age;
                },
                caption: 'Age',
            },
            {
                field: 'opb_mobile',
                render: record => {
                    return record.opb_mobile;
                },
                caption: 'Mobile',
            },
            {
                field: 'opb_opno',
                render: record => {
                    return record.opb_opno;
                },
                caption: 'OP No.',
            },
            {
                // field: 'cold_amt',
                render: record => {
                    return record.opb_amt;
                },
                caption: 'Amount',
            },

            {
                // field: 'cold_amt',
                render: record => {
                    return record.opb_bdisamt;
                },
                caption: 'Discount',
            },

            {
                // field: 'cold_amt',
                render: record => {
                    return record.opb_netamt;
                },
                caption: 'Net Amount',
            },

            {
                // field: 'cold_amt',
                render: record => {
                    return record.opb_paidamt;
                },
                caption: 'Paid',
            },
            // {
            //     field: 'cold_dt',
            //     render: (record) => {
            //         return record.colldetails ? record.colldetails.cold_dt : null;
            //     },
            //     caption: 'Date'
            // },
            // {
            //     field: 'Collected By',
            //     render: (record) => {
            //         return record.colldetails ? record.colldetails.cold_user : null;
            //     },
            //     caption: 'cold_user'
            // },
            {
                field: 'opb_remarks',
                render: record => {
                    return record.opb_remarks;
                },
                caption: 'Remarks',
            },
        ];
    }
    getReferrer = id => {
        return ApiUtils.get({
            url: `referrer/referrer-code/${id}`,
        });
    };
    Card = ({ ...props }) => {
        return <GuestCard record={props} {...props}></GuestCard>;
    };

    // DetailComponent = (routeParams) => <BillDetailView {...routeParams} model={this} />;
    //  AddComponent = () => <CollectionAdd model={this} />;

    // get = () => {
    //     return ApiUtils.get({
    //         url: `referrer`,
    //     });
    // };
}

function GuestCard({ record }) {
    return (
        <Card className="card vehicle-card">
            <div className="card">
                <h2 className="title">{record.opb_name}</h2>

                {/* {record.preReg.opb_age} */}

                {/* <h3 className="values">{record.da_aptime}</h3> */}

                {/* <Tag color={record.da_questfilled === 'F' ? 'orange' : 'green'}>{record.da_questfilled === 'F' ? 'Filled' : 'Pending'}</Tag> */}

                <Tag color="green">{record.opb_gender}</Tag>

                <Text type="secondary">{record.opb_mobile}</Text>

                <h4 className="values">{record.opb_opno}</h4>
            </div>
        </Card>
    );
}

export default Referrer;
