// import FirebaseUtils from './../utils/firebase.utils';

import { message } from 'antd';

import { GenericAdd, ApiUtils } from 'soxo-bootstrap-core';

import { GenericList } from 'soxo-bootstrap-core';

import { GenericEdit } from 'soxo-bootstrap-core';

import { GenericDetail } from 'soxo-bootstrap-core';

// import GenericUpload from './../lib/components/generic/generic-upload/generic-upload';

class Model {
    name;
    endpoint;
    pluralName;
    schema;
    fields;
    listFields;
    filterFields;

    constructor(fields = []) {
        this.endpoint = this.getEndpoint;


        this.name = this.getName;

        this.fields = fields;

        // this.columns = []
    }

    disableAdd = () => {
        return false;
    };

    /**
     * Get the data from the table
     */

    get = (queries) => {
        return ApiUtils.get({ url: this.getEndpoint, queries });
    };

    /**
     * Get with config
     */

    getWithConfig = (queries) => {
        return ApiUtils.get({ url: this.getEndpoint, config: { queries } });
    };

    /**
     *
     * @param {*} values
     * @returns
     */
    getRecord = (id) => {
        return ApiUtils.getRecordDetail({ url: this.getEndpoint + `/${id}` });
    };

    /**
     *
     * @param {*} values
     * @returns
     */
    getRecordDetail = (url) => {
        return ApiUtils.get(url);
    };

    /**
     * Add a record
     *
     * @param {*} values
     * @param {*} user
     * @returns
     */
    add = (formBody, user) => {
        return ApiUtils.post({ url: this.getEndpoint, formBody });
    };

    AddComponent = GenericAdd;

    ListComponent = GenericList;

    EditComponent = GenericEdit;

    DetailComponent = GenericDetail;

    // UploadComponent = GenericUpload
}

export default Model;
