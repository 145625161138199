/**
 * Scenarios to handle
 *
 * 1. Billing-screen for nura-desk
 *
 * 2. Billing-screen for stoptb
 *
 * 3. Direct-Billing for alsalama
 *
 */

import React, { useEffect, useState, useContext } from 'react';

import { Typography, Modal, Skeleton, Button, Card, Form, Input, Select, Col, message } from 'antd';

import './bill-items.scss';

import { Location, ReferenceSelect, GlobalContext, useTranslation, Trans } from 'soxo-bootstrap-core';

import { withRouter } from 'react-router-dom';

import { ItemSelection } from '../../../items/components/item-selection/item-selection';

import { Categories, Referrers, Items, Bills, Opvisits, Opreg, ItemRateTypes, Appointments, Customers } from '../../../../models';

import GuestInformation from './components/guest-information/guest-information';

import CouponModal from './components/coupon-model/coupon-model';

import DiscountModal from '../discount-model/discount-model';

import SelectedList from './components/selected-list/selected-list';

import { formatCurrency } from '../../../../utils/currency.utils';
import './bill-items.scss';
import PriceFormatter from '../format-price/format-price';

const { Option } = Select;

const { Title, Text } = Typography;

const { TextArea } = Input;

function BillItems({
    defaultItem,
    buttonEnable,
    doctorSelectionDisable,
    callback,
    order,
    referrer,
    disableBillDiscount,
    disableBillCoupon,
    enableItemDiscount,
    // enable corporate and referrer for authorized person
    disableCorporate,
    disableReferrer,
    partyType,
    ...props
}) {
    var urlParams = Location.search();

    var params = Location.search();

   

    const [guest, setGuest] = useState({});

    // Master for each dropdowns
    const [types, setTypes] = useState([]);

    const [referreroptions, setReferreroptions] = useState([]);

    

    // Variables to record user selection/ preference
    // Note : These variables can be replaced with implementation of
    // form items .

    const [selectedbilltype, setSelectedbilltype] = useState('');

    const [selectedcategory, setSelectedcategory] = useState('GEN');

    const [category, setCategory] = useState([]);

    const [corporate, setCorporate] = useState(false);

    const [alert, setAlert] = useState(false);

    const [itemRateData, setItemRateData] = useState(false);

    // Variable to save the remarks of the bill.
    const [remarks, setRemarks] = useState('');

    // Variable to save the discount of the bill.
    const [discount, setDiscount] = useState({});

    const [list, setList] = useState([]);

    const [selected, setSelected] = useState([]);

    // Variables defined for Selected Values End
      // Set default value for referrer
     const defaultValue = '1';
    const [selectedreferror, setSelectedreferrer] = useState(defaultValue);

    // const [selectDoctor, setSelectDoctor] = useState(null);

    const [selecteddoctor, setSelectedDoctor] = useState(null);

    const [visible, setVisible] = useState(false);
    const { user = {}, isMobile, dispatch } = useContext(GlobalContext);
    const [coupon, setCoupon] = useState({});

    const [CouponVisible, setCouponVisible] = useState(false);

    const [doctors, setDoctors] = useState([]);
    const [corporateDetails, setCorporateDetails] = useState([]);

    const [summary, setSummary] = useState({
        total: 0,
        items: [],
    });

    const [roundValue] = useState(2);

    const [btnloading, setBtnloading] = useState(false);

    const [loading, setLoading] = useState(true);

    const [validGender, setValidGender] = useState([]);

    const [alreadyBilledVisible, setAlreadyBilledVisible] = useState(false);

    const [billingSummaryloading, setBillSummaryLoading] = useState(false);

    const [doctorAppointmentId, setDoctorAppointmentId] = useState();

    const [disabled, setDisabled] = useState(false);

    const [doctorAppointment, setDoctorAppointment] = useState();

    const corporateptr = urlParams.op_corporateptr;

    const mode = urlParams.mode;

    const { t, i18n } = useTranslation();   // To Translate to another language

    let total = 0;

    let subTotal = 0;

    // for getting currency details

    // Assuming `user` is defined and has the `firm`and f_otherdetails property
    let otherDetails = user.firm.f_otherdetails1;

    let currency = JSON.parse(otherDetails);
    let currencySymbol = currency.currency_symbol;

    
   
    

    // Load all the
    useEffect(async () => {
        // If appointmentId is present in params
        if (params.appointmentId) {
            await getAppointmentDetails(params.appointmentId);
        } else {
            //get Categories
            await getCategories();
        }
        // Get all the bill types
        await getBillTypes();

        // get Referrer list
        await getReferrers();

        //    await getCategory()
    }, []);

    /**
     * Get the bill types
     */
    function getBillTypes() {
        Bills.getBillTypes().then((result) => {
            setTypes(result);
            setSelectedbilltype(result[0].btyp_code);
        });
    }
    /**
     * Get the referrers
     */
    function getReferrers() {
        Referrers.get().then((result) => {
            setReferreroptions(result.result);
        });
    }
    /**
     * get Categories
     * @param {*} category
     */

  
    function getCategories() {
        Categories.getCategories().then((data) => {
            let value = data.result;
            setCategory(data.result);
            // setCategory(data.result);
            //setSelectedcategory(data.result[0].itmrt_code);)
            // setSelectedcategory(data.result[0].ir_ratecatptr);
            let filter = value.find((e) => e.itmrt_code === 'GEN');
            setSelectedcategory(filter.itmrt_code);

            setLoading(false);
        });
    }

    /**
     * Get appointment and package details
     * @param {*} id
     */
    async function getAppointmentDetails(id) {
        setBillSummaryLoading(true);
        setSelected([]);
        setDoctorAppointmentId(id);
        const result = await Appointments.getAppointmentDetails(id);
        // if result has corporate pointer , that means corporate selected at the time of apppointment
        // so the billvalue are based on corporate item rate mapping .
        // Then we set billvalues as rate .
        //
        if (result.corporate_pointer) {
            setCorporate(result.corporate_pointer);

            setCorporateDetails({ rate: result.billvalues });

            let data = result.packageDetails[0];

            // this FUnction used for fetch category based on corporate
            //There is corporate is present in appointmnt. then we want to load category based on this corporate

            let record = {
                package_value: data.ir_itemptr,
                corporate_pointer: result.corporate_pointer,
                type: 'c',
            };
            await onCorporateChange(record, result.packageDetails);
        }

        // Set the package in appointment as a default package
        setSelected(result.packageDetails);
        // Set the package to summary too
        setSummary({
            items: result.packageDetails,
            guest: summary.guest,
        });

        setDoctorAppointment(result);

        setSelectedDoctor(result.doctor_pointer);

        setDisabled(true);

        // Set coupon applied for the appointment
        setCoupon({
            coupon_no: result.couponno,
            coupon_value: result.couponvalue,
            coupon_amount: result.couponvalue,
        });

        setBillSummaryLoading(false);
        setLoading(false);
    }

    /**
     *
     * @param {*} values
     * @returns
     */
    const createBill = async ({ verified }) => {
        setBtnloading(true);
        setValidGender([]);

        // Check if the gender for the items selected match with gender of the customer
        await Promise.all(
            summary.items.map((ele) => {
                const genderValidity = Opreg.validateGender(guest.op_gender, ele.itm_gender);
                validGender.push(genderValidity);
            })
        );
        // console.log(validGender)
        // If gender is not valid return error
        if (validGender && validGender.indexOf(false) !== -1) {
            message.error('The Selected Gender does not match with the Selected Package Gender. Please Select the Correct Gender');
            setBtnloading(false);
            return false;
        }
        var formBody = {
            op_no: params.op_no,
            opb_opno: params.op_no,
            visit_id: '',
            // opbd_itemptr: params.da_packagetypevalue,
            rate_type: '3', // Not sure if required
            opd_remarks: remarks,
            // opd_corporateptr: "12",
            category: selectedcategory,

            billtype: selectedbilltype,

            // cmgdcpn_couponnumber: coupon.coupon_no,

            doctorptr: selecteddoctor,

            Bdiscount_amt: discount,

            refererptr: selectedreferror,

            corporate: corporate,

            appointmentId: params.appointmentId || doctorAppointmentId,
        };
        // if disount type is percentage return percentage
        if (discount && discount.type === '%') {
            formBody.discountPercentage = discount.value;
        } else if (discount && discount.type === currencySymbol) {
            // If discount type is amount return amount
            formBody.discountAmount = discount.value;
        }

        // If coupon is applied return coupon
        if (coupon && coupon.coupon_no) {
            formBody.couponNo = coupon.coupon_no;
        }

        // If verified is true(when the create bill, is triggered but a bill with same opnumber and date is present in opbills and the customer has approved for fuether billing)
        if (verified) {
            formBody.verified = true;
        }

        if (buttonEnable) {
            formBody = {
                ...formBody,
                name: guest.op_fname,
                age: guest.op_age,
                ageType: guest.op_agetype,
                mobile: guest.op_mobile,
                gender: guest.op_gender,
            };
        }
        if (summary && summary.guest) {
            formBody = {
                ...formBody,
                ...summary.guest,
            };
        }

        if (summary.guest && summary.guest.op_number) {
            formBody = {
                ...formBody,
                op_no: summary.guest.op_number,
            };
        }

        if ((discount.value && discount.value != 0) || (coupon && coupon.coupon_no && coupon.coupon_value)) {
            if (!formBody.opd_remarks) {
                message.error('Please Enter Remarks');
                setBtnloading(false);
                return false;
            }
        }

        // If coupon is applied during appointment, couponno is not send to api
        if (doctorAppointment && doctorAppointment.couponno) {
            delete formBody.couponNo;
        }
        // if the corporate is present then want to sent rate as corporate rate
        // if (corporateDetails && corporateDetails.rate) {
        //     summary.items[0].ir_rate = corporateDetails.rate;
        // }
        Bills.createBill(formBody, summary, guest, corporateptr, discount, coupon, subTotal, currencySymbol)

            .then((result) => {
                if (result) {
                    if (result.success === false) {
                        setBtnloading(false);
                        message.error('Sorry, The Bill Creation is Failed');
                        return false;
                    }
                    // If there is a bill for the dsame op number in same date, make sure that the customer wants to proceed
                    if (result.billed_today) {
                        setAlreadyBilledVisible(true);
                        return false;
                    }

                    let opbd_hdrid = result.OpBillD[0].opbd_hdrid;

                    message.success('Bill has been successfully created');

                    // if (selectedbilltype === '0') {

                    //     Location.navigate({ method: 'replace', url: `/collections/create?opbd_hdrid=${opbd_hdrid}` })

                    //     // history.push('create/collections')
                    //     // Location.navigate({
                    //     //     method: 'push', url: `/collections`
                    //     // })

                    // } else {
                    if (order) Location.navigate({ method: 'replace', url: '/order/' + opbd_hdrid });
                    else Location.navigate({ method: 'replace', url: '/bill/' + opbd_hdrid });

                    // }

                    setBtnloading(false);
                }
            })
            .catch((result) => {
                setBtnloading(false);

                message.error(JSON.stringify(result.message));
            });
    };

    summary.items = summary.items.map((entry) => {
        // Callback Function
        entry.callback = (props) => {
            // While preparing data we prepare discount object
            entry.discount = {
                ...props,
            };

            // setEntries(items);
        };

        entry.quantity = 1;

        entry.amount = entry.ir_rate * entry.quantity;

        total = total + entry.amount;

        // Row level discount is saved in each record
        const { discount, amount } = entry;

        // var final = amount;
        if (discount) {
            if (discount.type === '%') {
                total = total - (amount * discount.value) / 100;
            } else {
                total = total - discount.value;
            }
        }

        return entry;
    });

    // This condition will be true in two scenarios:
    // * during corporate on change
    //* corporate pointer is present at appointment details api

    if (corporateDetails && corporateDetails.rate) {
        subTotal = corporateDetails.rate;
        total = subTotal;
    } else {
        // Find the total of the items
        summary.total = total;

        subTotal = total;
    }

    // Bill level discount if applied
    if (discount) {
        if (discount.type === '%') {
            total = total - (total * discount.value) / 100;
        } else if (discount.type === currencySymbol) {
            if (discount.value <= subTotal) total = total - discount.value;
            else {
                setDiscount({});
                onUpdate({});
            }
        }
    }

    // coupon level discount if applied
    if (guest && guest.drAppointment && guest.drAppointment.da_couponno) {
        total = total - guest.drAppointment.da_couponvalue;
    } else if (coupon && coupon.coupon_value && coupon.coupon_no) {
        if (total >= coupon.coupon_value) total = total - coupon.coupon_amount;
        else {
            setCoupon();
            onUpdateCoupon({});
        }
    }

    useEffect(() => {
        loadData();
        getDoctors();
    }, []);

    /**
     * Get all the packages
     */
    const getDoctors = () => {
        return Items.getDoctorsList().then((result) => {
            setDoctors(result.result);

            return result;
        });
    };

    function loadData() {
        var params = Location.search();
        getCustomers();

        //For direct flow we dont need opReg data
        if (!buttonEnable) {
            getOpRegData(params.op_no);
        }

        // getOPReg(params.op_no)
    }

    /**
     *
     */
    // function getOPReg(id) {
    //     Registrations.getOpregDetail(id).then((result) => {
    //         setGuest(result);
    //         getGuestAge(result);
    //         // setGuestAge(age);
    //     });
    // }
    function getOperg(op_no, da_id) {
        if (op_no) {
            Bills.getOperg(op_no, da_id).then((result) => {
                setGuest(result.data);
            });
        } else {
            setGuest([]);
        }
    }

    async function getOpRegData(op_no) {
        await Bills.getOpRegData(op_no).then(async (result) => {
            if (result.result && result.result[0].op_referrerptr) {
                await setSelectedreferrer(result.result[0].op_referrerptr);
            }

            // // Set applied corporate
            // if (result.result[0] && result.result[0].op_corporateptr) {
            //     setCorporate(result.result[0].op_corporateptr);
            // }

            await setGuest(result.result[0]);
        });
    }

    /**
     * Get Customers
     */
    function getCustomers() {
        Bills.getCustomers().then((result) => {
            setList(result.result);
        });
    }

    /**
     *
     */
    function editDetails() {
        Location.navigate({
            url: `/new-registration/guest?da_id=${guest.drAppointment.da_id}&da_packagetypevalue=${guest.drAppointment.da_packagetypevalue}&prereg_id=${guest.drAppointment.da_referenceid}&prereg_opno=${guest.op_no}&mode=${mode}&edit=true&redirect=bill`,
        });
    }

    function onUpdate(values) {
        setDiscount(values);
        setRemarks(values.remarks);
    }

    function onUpdateCoupon(values) {
        let couponAmount;
        // If coupon type is percentage
        if (values.value_type === 'P') couponAmount = subTotal * (values.coupon_value / 100);
        // If coupon type is amount
        else if (values.value_type === 'A') couponAmount = values.coupon_value;
        values = {
            ...values,
            coupon_amount: couponAmount,
        };
        setCoupon(values);
        if (values && values.remarks) setRemarks(values.remarks);
    }

    function onUpdateRemarks(event) {
        setRemarks(event.target.value);
    }

    /**
     * Select Doctor onchange of doctor
     * @param {*} value
     */
    const selectDoctor = (value) => {
        setSelectedDoctor(value);
    };

    /**
     * On change of Category items should update its values
     * @param {*} value
     */

    async function UpdateCategory(value, record) {
        setSelectedcategory(value);
        setAlert(true);
        setItemRateData(record);

        // var arr = [];
        // var categoryChange = true;
        // if (selected.length) {
        //     Promise.all(
        //         selected.map(async (ele) => {
        //             if (ele && ele.itm_code) var newItem = await getItems(ele.itm_code, value, categoryChange);

        //             if (newItem) arr.push({ ...newItem });
        //             return arr;
        //         })
        //     ).then((arr) => {
        //         setSelected(arr[0]);
        //     });
        // }
    }

    /**
     * Get the master data to show options for the user
     */
    // async function getItems(defaultItem, category, categoryChange) {
    //     setLoading(true);

    //     return await Items.getItems(defaultItem, category).then(async (result) => {
    //         // result = result.map((ele) => {
    //         //     return {
    //         //         ...ele,
    //         //         ir_rate: ele.itemrate[0].ir_rate,
    //         //         ir_itemptr: ele.itemrate[0].ir_itemptr,
    //         //     };
    //         // });

    //         if (categoryChange) {
    //             setLoading(false);

    //             return result[0];
    //         } else {
    //             setMaster(result);

    //             setSelected([result]);

    //             setLoading(false);
    //         }
    //     });
    // }

    const onUpdateSelect = (value, updateMethod) => {
        if (value) {
            updateMethod(value);
        } else {
            updateMethod('');
        }
    };

    // const updatedCorporate = {
    //     ...corporate,
    //     corporate: record.cc_styledcontent,

    //   };
    function onCandidateSelect(guest) {
        summary.guest = guest;

        setGuest(guest);

        if (guest && guest.doctorAppointment) getAppointmentDetails(guest.doctorAppointment.id);
    }
    /**
     *  When Coupon applied from Bills .then want to check coupon is applicable for specific item
     */
    let packageValue;

    if (summary.items.length > 0) {
        packageValue = summary.items[0].ir_itemptr;
    }

    /**
     * Item Rate mapping .
     * This function triggered two scenario
     * * corporate on Change
     * * Category on Change
     * @param {*} record
     */

    async function onCorporateChange(record, selected) {
        let values;
        // itemratemapping based on selected corporate
        let pacakgeValues = [];
        if (selected.length > 0) {
            // selecting package values from selected arry
            selected.forEach((ele) => {
                let package_value = ele.itm_code;
                pacakgeValues.push(package_value);
            });

            // here pacakage value is array of item code
            record = {
                ...record,
                package_value: pacakgeValues,
            };

            let response = await Items.getItemRateMapping(record);

            // if the corperate is selected then want to set bill value
            if (response.success) {
                values = response.data;
                setCorporateDetails(values);

                let selectedData = [];
                /// set rate and discount to the selected state
                await selected.forEach((element) => {
                    let filter = values.filter((item) => item.item_pointer == element.itm_code);

                    let rate = filter[0].rate;
                    // if there is discount then  we  set discount and item rate based on discount
                    if (filter[0].discount) {
                        // element.ir_rate = rate;

                        //  set item rate  based on discount
                        element.ir_rate = filter[0].item_rate;

                        element.discount = { value: filter[0].discount, type: currencySymbol };
                        //  element.amount = rate
                    } else {
                        element.ir_rate = rate;
                        // element.amount = rate
                        element.discount = null;
                    }

                    selectedData.push(element);
                });

                setSelected(selectedData);
                // set selected catogory if the corporate is present
                setSelectedcategory(values[0].category_ptr);
            }
        }
    }

    /**
     *
     * This Function is used for fetch category based on corporate
     * @param {*} record .
     *
     */

    function getCategory(record) {
        Categories.getCategory(record).then((result) => {
            if (result.success) {
                let value = result.data;
                setSelectedcategory(value.category_code);
            } else {
                // getCategories();
                setSelectedcategory('GEN');
            }
        });
    }

    return (
        <div className="bill-summary">
            {/* <div className="page-header">
                <Title className="title" level={4}>
                    {t('BILL SUMMARY')}
                </Title>

                <div className="top-actions">
                    <Button size={'small'} type="secondary" onClick={loadData}>
                        {t('Refresh')}
                    </Button>
                </div>
            </div> */}

            <div></div>
            {loading ? (
                <Skeleton />
            ) : (
                <div className="bill-content">
                    <div className="bill-items card form card-shadow">
                        <div className="form-content">
                            {/* Item Selection Component */}
                            <div className="top-section">
                                <div className="summary-bottom" style={{ width: '30%' }}>
                                    <div className="right">
                                        <Title level={5}>{t('Category')}</Title>

                                        <ReferenceSelect
                                            label="itmrt_desc"
                                            value={selectedcategory}
                                            mode="nura-base"
                                            config={{
                                                queries: [
                                                    {
                                                        field: 'itmrt_active',

                                                        value: 'Y',
                                                    },
                                                ],
                                            }}
                                            // onChange={UpdateCategory}
                                            onChange={(entry) => {
                                                let record = {
                                                    category_pointer: entry,
                                                    // package_value: summary.items[0].ir_itemptr,
                                                };

                                                UpdateCategory(entry, record);
                                            }}
                                            model={ItemRateTypes}
                                            field={'itmrt_code'}
                                        />
                                    </div>
                                </div>
                                <div className="summary-bottom" style={{ width: '20%' }}>
                                    <div className="right">
                                        <Title level={5}>{t('Bill Types')}</Title>
                                        <Select
                                            style={{ marginLeft: '5px', marginRight: '5px', width: '100%' }}
                                            onChange={(entry) => onUpdateSelect(entry, setSelectedbilltype)}
                                            value={selectedbilltype}
                                            // style={{ width: '100%' }}
                                            placeholder={t("Choose Bill Type")}
                                        >
                                            {types.map((item, key) => (
                                                <Option key={key} value={item.btyp_code}>
                                                    {item.btyp_caption}
                                                </Option>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                                <div className="summary-bottom" style={{ width: '20%' }}>
                                    <div className="right">
                                        <Title level={5}>{t('Referrer')}</Title>
                                        <Select
                                            value={selectedreferror}
                                            onChange={(entry) => onUpdateSelect(entry, setSelectedreferrer)}
                                            allowClear
                                            // labelInValue={true}
                                            style={{ width: '100%' }}
                                            disabled={disableReferrer}
                                            placeholder={t("Choose Campaign")}
                                        >
                                            {referreroptions.map((item, key) => (
                                                <Option key={key} value={item.refr_code}>
                                                    {item.refr_desc}
                                                </Option>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                            </div>

                            {/* Component For Billing */}
                            {billingSummaryloading ? (
                                <Skeleton />
                            ) : (
                                <BillSummary
                                    selectedcategory={selectedcategory}
                                    {...props}
                                    defaultItem={defaultItem}
                                    loadData={loadData}
                                    selectedItems={selected}
                                    enableItemDiscount={enableItemDiscount}
                                    // order={order}
                                    buttonEnable={buttonEnable}
                                    currency={currency}
                                    callback={(items, coupon, discount) => {
                                        // items
                                        // setSummary(items);
                                        setSelected(items);
                                        setSummary({
                                            guest: summary.guest,
                                            items: items,
                                            total: 0,
                                        });
                                    }}
                                />
                            )}
                        </div>
                    </div>

                    {/* </div> */}

                    {/* guest Information component */}
                    <div className="bill-part">
                        <GuestInformation
                            enableGuest={props.enableGuest}
                            buttonEnable={true}
                            callback={onCandidateSelect}
                            getReferrer={getOpRegData}
                        />

                        {/* Corporate Selection Component */}
                        <CorporateSection
                            value={corporate}
                            setCorporate={setCorporate}
                            packageValue={summary}
                            disableCorporate={disableCorporate}
                            callback={(values, record) => {
                                if (values.package_value) {
                                    setAlert(true);
                                    setItemRateData(values);
                                } else {
                                    getCategory(values);
                                }
                            }}
                        />
                        {/* Corporate Selection Component Ends */}

                        <div className="right-part card card-shadow">
                            <div className="bill-summary-bottom">
                                <div className="sub-section">
                                    <Title level={5}>{t('BILL DETAILS')}</Title>
                                    <div
                                        className="section-row"
                                        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'nowrap' }}
                                    >
                                        <div className="section-label">
                                            <Text type="secondary">{t('SUB TOTAL')}</Text>
                                        </div>
                                        <div className="section-value">
                                            <strong>
                                                {/* <Rupee value={parseFloat(subTotal.toFixed(roundValue))}></Rupee> */}
                                                {/* {formatCurrency(parseFloat(subTotal.toFixed(roundValue)), currencySymbol)} */}
                                                <PriceFormatter price={parseFloat(subTotal.toFixed(roundValue))}  locale={currency.priceFormat}
                                            currency={currency.currency_code}
                                            enablePriceFormatting={currency.enablePriceFormatting}/>
                                            </strong>
                                        </div>
                                    </div>
                                    {/* {console.log(selected)} */}
                                    {selected && selected.length && selected.length > 0 ? (
                                        disableBillDiscount === true ? null : (
                                            <>
                                                <div className="section-row" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
                                                    <div className="section-label">
                                                        <Text type="secondary">{t('DISCOUNT')}</Text>
                                                    </div>
                                                    {discount && discount.type ? (
                                                        <div className="section-value">
                                                            <strong>
                                                                {discount && discount.type === currencySymbol ? discount.type : null}{' '}
                                                                {discount && discount.value ? discount.value : 0}{' '}
                                                                {discount && discount.type === '%' ? discount.type + 'age' : null}
                                                            </strong>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <Button
                                                                style={{ padding: '0' }}
                                                                className="bill-discount-button"
                                                                size="small"
                                                                type="link"
                                                                onClick={() => {
                                                                    setVisible(true);
                                                                }}
                                                            >
                                                                {t('APPLY DISCOUNT')}
                                                            </Button>
                                                        </div>
                                                    )}
                                                </div>

                                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    {discount && discount.type ? (
                                                        <Button
                                                            style={{ padding: '0' }}
                                                            className="cancel-coupon-button"
                                                            size="small"
                                                            type="text"
                                                            onClick={() => {
                                                                setDiscount({});
                                                                onUpdate({});
                                                            }}
                                                        >
                                                            <span className="disc-btn">{t('Remove')}</span>
                                                        </Button>
                                                    ) : null}
                                                </div>
                                            </>
                                        )
                                    ) : null}

                                    {selected && selected.length && selected.length > 0 ? (
                                        disableBillCoupon === true ? null : (
                                            <>
                                                {guest && guest.drAppointment && guest.drAppointment.da_couponvalue ? (
                                                    <>
                                                        <div className="section-row">
                                                            <div className="section-label">
                                                                <Text type="secondary">{t('COUPON DISCOUNT')}</Text>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div
                                                            className="section-row"
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between',
                                                                whiteSpace: 'nowrap',
                                                            }}
                                                        >
                                                            <div className="section-label">
                                                                <Text type="secondary">{('COUPON DISCOUNT')}</Text>
                                                            </div>
                                                            {coupon && coupon.coupon_no ? (
                                                                <>
                                                                    {/* <Alert message={validMessage}description={coupon.cmgdcpn_remarks} />  */}
                                                                    <div>
                                                                        {/* <div className="section-value">
                                                                            <strong>{coupon.coupon_amount}</strong>
                                                                        </div> */}
                                                                        <PriceFormatter price={coupon.coupon_amount}
                                                                        locale={currency.priceFormat}
                                                                        currency={currency.currency_code}
                                                                        enablePriceFormatting={currency.enablePriceFormatting}
                                                                        />
                                                                        <Button
                                                                            style={{ marginLeft: '10px', padding: '0' }}
                                                                            className="cancel-coupon-button"
                                                                            size="small"
                                                                            type="text"
                                                                            onClick={() => {
                                                                                setCoupon(null);
                                                                                setRemarks('');
                                                                            }}
                                                                        >
                                                                            {/**If coupon is applied from appointemnt, removing of coupon is not supported */}
                                                                            {doctorAppointment&&doctorAppointment.couponno ? null : (
                                                                                <span className="coup-btn">{t('Remove')}</span>
                                                                            )}
                                                                        </Button>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <div>
                                                                    <Button
                                                                        style={{ padding: '0' }}
                                                                        className="add-coupon-button"
                                                                        size="small"
                                                                        type="link"
                                                                        onClick={() => {
                                                                            setCouponVisible(true);
                                                                        }}
                                                                    >
                                                                        {t('APPLY COUPON')}
                                                                    </Button>

                                                                    <strong>{coupon && coupon.couponno ? coupon.coupon_amount : null}</strong>
                                                                </div>
                                                            )}

                                                            {/* <div className="section-value"> */}
                                                            {/* {coupon && coupon.dataValues ? coupon.dataValues.cmgdcpn_couponnumber : null}  */}

                                                            {/* <strong>{coupon ? coupon.coupon_value : 0}</strong>
                                            </div> */}
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        )
                                    ) : null}

                                    {/* <div className="section-row">
                                        <div className="section-label">
                                            <Text type="secondary">Discount Amount</Text>
                                        </div>
                                        <div className="section-value">
                                            {discount && discount.type == '%' ? (
                                                <strong> {parseFloat(((subTotal * discount.value) / 100).toFixed(roundValue))}</strong>
                                            ) : (
                                                <strong>{discount.value}</strong>
                                            )}
                                        </div>
                                    </div> */}
                                </div>
                                {/* Discount Section Ends */}

                                <div>
                                    <p level={3}>
                                        <strong>{t('REMARKS')}</strong>
                                    </p>

                                    <TextArea value={remarks} onChange={onUpdateRemarks} placeholder={t("Enter Remarks")}></TextArea>
                                </div>
                                <hr className="hr-line"></hr>
                                {/* Net Amount */}
                                <div className="section-row net-amount">
                                    <div className="section-label">
                                        <Title level={5}>{t('Net Amount')}</Title>
                                    </div>
                                    <PriceFormatter price={parseFloat(total.toFixed(roundValue))}  locale={currency.priceFormat}
                                             currency={currency.currency_code}
                                             enablePriceFormatting={currency.enablePriceFormatting}/> 
                                    {/* <div className="section-value"> */}
                                        {/* <Title level={5}> */}
                                            {' '}
                                            {/* <Rupee value={parseFloat(total.toFixed(roundValue))}></Rupee> */}
                                            {/* {formatCurrency(parseFloat(total.toFixed(roundValue)), currencySymbol)} */}
                                        {/* </Title> */}
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                        <Modal
                            destroyOnClose={true}
                            footer={null}
                            title={t("Apply Discount")}
                            visible={visible}
                            okText="Okay"
                            onOk={() => {
                                setVisible(false);
                            }}
                            onCancel={() => {
                                setVisible(false);
                            }}
                        >
                            <DiscountModal
                                callback={(values) => {
                                    setVisible(false);

                                    onUpdate(values);

                                    // callback(values);
                                }}
                                discount={discount}
                                subTotal={subTotal}
                                currency={currencySymbol}
                            />
                        </Modal>
                        <Modal
                            destroyOnClose={true}
                            footer={null}
                            title={t("Apply Coupon")}
                            visible={CouponVisible}
                            okText="Okay"
                            onOk={() => {
                                setCouponVisible(false);
                            }}
                            onCancel={() => {
                                setCouponVisible(false);
                            }}
                        >
                            <CouponModal
                                callback={(values) => {
                                    // Set Remarks
                                    setRemarks(`DiscountCoupon:${values.coupon_no}:${values.id}`);
                                    setCouponVisible(false);

                                    onUpdateCoupon(values);

                                    // callback(values);
                                }}
                                setCouponVisible={setCouponVisible}
                                subTotal={subTotal}
                                // item_code={summary.items[0].ir_itemptr}
                                itemCode={packageValue}
                            />
                        </Modal>
                        {/** Modal to confirm that the customer wants to proceed with billing, if there is a bill with same op number and date */}
                        <Modal
                            destroyOnClose={true}
                            // footer={null}
                            title=""
                            visible={alreadyBilledVisible}
                            okText="Okay"
                            onOk={() => {
                                createBill({ verified: true });
                                setAlreadyBilledVisible(false);
                            }}
                            onCancel={() => {
                                setBtnloading(false);
                                setAlreadyBilledVisible(false);
                            }}
                        >
                            <p> {t('One or more items for this op number have been billed today. Are you sure you want to proceed?')}</p>
                        </Modal>
                        {/**  Modal to confirm that corporate or category want to change */}
                        <Modal
                            destroyOnClose={true}
                            // footer={null}
                            title=""
                            visible={alert}
                            okText="Okay"
                            onOk={() => {
                                setAlert(false);
                                onCorporateChange(itemRateData, selected);
                            }}
                            onCancel={() => {
                                setAlert(false);
                            }}
                        >
                            <p>{t('Do you want to change the selected item rate according to rate category')}</p>
                        </Modal>

                        <div className="right-part card card-shadow">
                            {doctorSelectionDisable ? null : (
                                <div className="summary-bottom">
                                    <div className="right">
                                        <Title level={5}>{t('Select Doctor')}</Title>
                                        <Select
                                            onChange={(entry) => selectDoctor(entry)}
                                            value={selecteddoctor}
                                            disabled={disabled}
                                            style={{ width: '100%' }}
                                            placeholder={t("-Select Doctor-")}
                                        >
                                            {doctors.map((item, key) => (
                                                <Option key={key} value={item.do_code}>
                                                    {item.do_name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                            )}
                            <div className="footer-actions">
                                <div className="footer">
                                    <Button
                                        loading={btnloading}
                                        // If items has values and guest is selected
                                        // disabled={!summary.items.length || guest.op_fname}
                                        disabled={
                                            (selected && !selected.length) || (!guest.op_fname && !guest.name && !guest.first_name) || !selected
                                        }
                                        type="primary"
                                        onClick={createBill}
                                    >
                                        {t('Create Bill')}
                                    </Button>
                                </div>
                            </div>

                            {/* Footer Actions Ends */}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default withRouter(BillItems);

/**
 * Component will load the accesories in cart for this booking
 */
function BillSummary({ selectedcategory, callback, loadData, selectedItems, enableItemDiscount, currency }) {

    const { t, i18n } = useTranslation(); // To Translate to another language

    useEffect(() => {
        // Load the selected items with the updated price as per the new category
        reviseItemsCategory();
    }, [selectedcategory]);

    function reviseItemsCategory() {
        // If selectedItem is present, item during appointment, set that to selcted array
        if (selectedItems.length > 0) setSelected(selectedItems);
        else setSelected([]);
    }

    const [selected, setSelected] = useState(selectedItems);

    /**
     * Get the master data to show options for the user
     */

    // Note: Ashique - Commented but need to be considered as a quik solution if needed
    // to implement for category change

    // async function getItems(defaultItem, category, categoryChange) {
    //     setLoading(true);

    //     return await Items.getItems(defaultItem, category).then(async (result) => {
    //         // result = result.map((ele) => {
    //         //     return {
    //         //         ...ele,
    //         //         ir_rate: ele.itemrate[0].ir_rate,
    //         //         ir_itemptr: ele.itemrate[0].ir_itemptr,
    //         //     };
    //         // });

    //         if (categoryChange) {
    //             setLoading(false);

    //             return result[0];
    //         } else {
    //             setMaster(result);

    //             setSelected([result]);

    //             setLoading(false);
    //         }
    //     });
    // }

    function onUpdateitmdiscount(values) {
        // setitmDiscount(values);
    }


    return (
        <div className="item-selection-wrapper">
            {/* Item Selection Component */}
            <ItemSelection
                selectedLabel={t("Selected")}
                includeRate={true}
                category={selectedcategory}
                deleteItem={(index) => {
                    index = index + 1;

                    selected.splice(index);
                }}
                selected={selected}
                callback={(selectedItems) => {
                    setSelected(selectedItems);

                    callback(selectedItems);

                    loadData();
                }}
            />
            {/* Item Selection Component Starts */}
            <>
                {selected && selected.length ? (
                    <SelectedList
                        callback={callback}
                        items={selected}
                        enableItemDiscount={enableItemDiscount}
                        onUpdateitmdiscount={onUpdateitmdiscount}
                        setSelected={setSelected}
                        currency={currency}
                    />
                ) : null}
            </>
        </div>
    );
}

/**
 * On the billing screen , corporate select and
 * its detailed view is seperated as a dedicated component
 *
 * @returns
 */
function CorporateSection({ value, setCorporate, packageValue, callback,disableCorporate}) {
    const [selected, setSelected] = useState(null);

   

    const { t, i18n } = useTranslation(); // To Translate to another language
    
    // Variable used for controlling the modal
    const [isModalVisible, setModalVisible] = useState(false);

    /**
     * Open the modal
     */
    const showStydecontent = () => {
        setModalVisible(true);
    };

    /**
     * Close the modal
     */
    const handleModalClose = () => {
        setModalVisible(false);
    };

    useEffect(async () => {
        if (value) {
            // setCorporate(true)
            let data = await Customers.getCustomerWithId(value);
            let corporate;
            corporate = {
                record: data.result,
            };
            setSelected(corporate);
        }
    }, []);

    // const onUpdateSelect = (value, updateMethod) => {
    //     if (value) {
    //         updateMethod(value);
    //     } else {
    //         updateMethod('');
    //     }
    // };

    /**
     * Update the selected value and call the provided update method.
     *
     * @param {string} value - The selected value.
     * @param {Function} updateMethod - The method to update the corporate section value.
     * @param {string} type - The selected type.
     */
    async function onselectChange(value, type, updateMethod) {
        updateMethod(value);
        // set values
        let record = {
            // package_value: packageValue.items[0].ir_itemptr,
            type: type.selected_type,
            corporate_pointer: value,
        };
        if (packageValue && packageValue.items.length > 0) {
            record = {
                ...record,
                package_value: packageValue.items[0].ir_itemptr,
            };
        }
        callback(record);
    }
    return (
        <div className="corporate-section">
            <Title level={5}>{t('Corporate')}</Title>
            <ReferenceSelect
                label="cc_desc"
                mode="nura-base"
                model={Customers}
                field={'cc_code'}
                value={value}
                disabled={disableCorporate}
                // onChange={(entry, record) => {
                //     onUpdateSelect(entry, setCorporate);

                //     setSelected(record);
                // }}
                config={{
                    queries: [
                        {
                            field: 'cc_active',
                            value: 'Y',
                        },
                    ],
                }}
                onChange={(entry, record) => {
                    onselectChange(entry, { selected_type: 'C' }, setCorporate);
                    setSelected(record);
                }}
            />

            <div className="footer">
                {selected && selected.record && selected.record.cc_styledcontent && (
                    <Button size={'small'} onClick={showStydecontent}>
                        {t('View offers')}
                    </Button>
                )}
            </div>
            <Modal visible={isModalVisible} onCancel={handleModalClose} footer={null}>
                {selected && selected.record && selected.record.cc_styledcontent ? (
                    <div dangerouslySetInnerHTML={{ __html: selected.record.cc_styledcontent }}></div>
                ) : (
                    <p>{t('No content available')}</p>
                )}
            </Modal>
        </div>
    );
}
