import React, { useState, useEffect, useContext } from 'react';

import { Button, Empty, Card, Typography, Skeleton, message } from 'antd';

import { InfoCircleOutlined } from '@ant-design/icons';

import { Items, Appointments, Settings } from './../../../../models/';

import { Link } from 'react-router-dom';

import './slots.scss';

import { Location, GlobalContext ,useTranslation} from 'soxo-bootstrap-core';

import { Calendar } from 'antd';

import moment from 'moment';
import PriceFormatter from '../../../common/components/format-price/format-price';

const { Title, Text } = Typography;

/**
 *
 * @returns
 */
export default function Slots(props) {
    var params = Location.search();

    const [item, setItem] = useState();

    const [today, setToday] = useState(moment());

    const [available, setAvailable] = useState([]);

    const [selected, setSelected] = useState({});

    const [loading, setLoading] = useState(false);

    const [btnLoading, setBtnLoading] = useState(false);

    const [appointment, setAppointment] = useState({});


    const { t, i18n } = useTranslation();   // To Translate to another language

    useEffect(() => {
        setLoading(true);

        // Get the doctor ptr
        Items.getItems(params.da_packagetypevalue).then((result) => {
            setItem(result);

            // checking if rescheduled flow
            if (params.appointId) {
                // to get the appointment details parallel to the id in the route

                Appointments.getRecord(params.appointId).then((res) => {
                    setToday(moment(res.da_date));

                    // Appointment
                    setAppointment(res.drAppntmnt);

                    // to get slot parallel to selected time in the appointment
                    getSlots(moment(res.da_date), result, function (response) {
                        response.map((item, index) => {
                            if (item.as_starttime == res.da_aptime) {
                                // default selecting selected slot as per the appointment taken time
                                selectSlot(item, index);
                            }
                        });
                    });
                });
            } else {
                getSlots(today, result);
            }
        });
    }, []);

    /**
     *
     *
     * @param {*} dt
     */
    function getSlots(dt, selected_item, callback) {
        setLoading(true);

        Appointments.getSlots(dt.format('YYYY-MM-DD'), selected_item).then((results) => {
            var arr = [];

            if (results.message === 'No Slots Available') {
                message.error(results.message);

                setAvailable([]);

                setLoading(false);
            } else {
                results.forEach((item) => {
                    // If selected date equals today

                    if (dt.isSame(moment(), 'day')) {
                        //
                        if (moment(item.as_starttime, 'hh:mm A').isAfter(moment())) {
                            arr.push(item);
                        }
                    } else {
                        arr.push(item);
                    }
                });

                setAvailable([...arr]);

                console.log(arr);

                setLoading(false);

                // in reschedule flow we needed to update slots, for that using a callback to get the updated slots
                if (callback) {
                    callback(arr);
                }
            }
        });
    }

    function onChange(value, mode) {
        setToday(value);
        setSelected({});
        getSlots(value, item);
    }

    /**
     *
     * @param {*} slot
     */
    const selectSlot = (slot, key) => {
        setSelected({ item: slot, key });
    };

    useEffect(() => {
    }, []);

   
    /**
     *
     */
    const onSlotConfirm = () => {
        setBtnLoading(true); // loading 'true'

        var params = Location.search();

        let redirectUrl;

        if (props.onRedirect) {
            //Get redirect function from attributes, to get redirection url
            // If it is a repeated case url will be existing-guest,else it will be guest
            let redirectFunction = eval(props.onRedirect);

            // let redirectFunction = new Function("props",props.onRedirect)

            redirectUrl = redirectFunction(props);
        }

        if (selected.item || selected.key) {
            // for reschedule
            if (params.appointId) {
                let updateParams = {
                    da_doctorptr: appointment.da_doctorptr,
                    da_date: moment(today).format('YYYY-MM-DD 00:00'),
                    da_aptime: selected.item.as_starttime,
                    da_tokenno: selected.item.token_no,
                };

                // Update the slot time for the appointment
                // Appointments.updateAppointment(params.appointId, updateParams).then(() => {
                //     Location.navigate({ url: `/appointment/${params.appointId}` });

                // });

                Appointments.rescheduleAppointment(params.appointId, updateParams).then((result) => {
                    setBtnLoading(false);
                    Location.navigate({ url: `/appointment/${params.appointId}` });

                    if (result.success) 
                        message.success('Appointment has been rescheduled ');
                    else 
                        message.error(result.message);
                    
                });
            } else {
                var selected_time = today.format('DD/MM/YYYY') + ' ' + selected.item.as_starttime;

                var selected_date = today.format('YYYY-MM-DD');

                let queryParam = {
                    ...params,
                    selected_time,
                    selected_date,
                    token_no: selected.item.token_no,
                };
                // if (params.edit)
                //     Location.back()
                if (props.onRedirect)
                    Location.navigate({
                        url: redirectUrl,
                        queryParam,
                    });
                else Location.navigate({ url: `/new-registration/guest`, queryParam });
            }
        } else {
            message.error('Please Select a Time Slot');
        }
    };

    return (
        <>
            <section className="slots page">
                <div className="page-content">
                    {/* <Title level={3}>Select an appointment</Title> */}

                    <div className="calendar-wrapper">
                        <div className="left">
                            <div className="top-bar">
                                <div className="selected-package-container">
                                    <SelectedPackage />
                                </div>

                                <div className="notice-card card">
                                    <Title level={5}>
                                        <InfoCircleOutlined /> {t("Notes")}
                                    </Title>

                                    <p>
                                        {t('Choose a convenient date and time to conduct the screening.')}
                                        {/* You have <h3>
{days}
</h3> days remaining to complete this process. */}
                                    </p>

                                    {/* <Link to="/change-password">
                                        Learn More
                                    </Link> */}
                                </div>
                            </div>

                            <div className="time-slot-container">
                                {loading ? (
                                    <div className="loading-text">
                                        <p>{t("Loading slots")}</p>

                                        <Skeleton />
                                    </div>
                                ) : (
                                    <div className="calendar-container">
                                        <Card className="calendar">
                                            {/* Calendar */}
                                            <Calendar
                                                disabledDate={(current) => {
                                                    return current.diff(moment(), 'hours') < 0;
                                                }}
                                                value={today}
                                                fullscreen={false}
                                                onChange={onChange}
                                            />
                                            {/* Calendar Ends */}
                                        </Card>

                                        <div className="calendar-slots">
                                            <p>{t("When do you want to schedule the appointment ?")}</p>

                                            <div className="time-slots">
                                                {available.map((entry, key) => (
                                                    <div
                                                        className={`item ${key === selected.key ? 'active' : ''} ${entry.booked ? 'booked' : ''}`}
                                                        key={key}
                                                        onClick={() => !entry.booked && selectSlot(entry, key)}
                                                    >
                                                        {/* {moment(entry.as_starttime).format('HH:mm A')} */}
                                                        {entry.as_starttime}
                                                    </div>
                                                ))}
                                            </div>
                                            {available.length ? (
                                                <>
                                                    <Button type="primary" onClick={onSlotConfirm} loading={btnLoading} disabled={btnLoading}>
                                                        {t('Proceed')}
                                                    </Button>
                                                </>
                                            ) : (
                                                <>
                                                    <Empty description={false} />
                                                    <p className="text-center">{t("No slots available")}</p>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

/**
 *
 * @returns
 */
function SelectedPackage() {
    var params = Location.search();

    const { user = {} } = useContext(GlobalContext);

    const [selected, setSelected] = useState({});

    const [loading, setLoading] = useState(false);

    const { t, i18n } = useTranslation();    // To Translate to another language

    useEffect(() => {
        getItems();
    }, []);

    /**
     * Get the master data to show options for the user
     */
    function getItems() {
        setLoading(true);

        Items.getItems(params.da_packagetypevalue).then((result) => {
            console.log(result);

            // setSelected(result.filter((record) => record.itm_active === 'Y'));

            setSelected(result);

            setLoading(false);
        });
    }

    // for getting currency details

    // Assuming `user` is defined and has the `firm`and f_otherdetails property
    let otherDetails = user?.firm?.f_otherdetails1;

    let firmDetails = JSON.parse(otherDetails);


    return (
        <div className="card card-shadow selected-package">
            <Title level={4}>{t("Packages Selected")}</Title>

            {loading ? (
                <>{t("Loading")}</>
            ) : (
                <>
                    <div className="section-content">
                        <div className="section-row">
                            <div className="section-label">
                                <Text type="secondary">{selected.itm_desc}</Text>
                            </div>
                            <PriceFormatter price={selected.ir_rate} enablePriceFormatting={firmDetails.enablePriceFormatting}/>
                            {/* <div className="section-value">
                                <strong>{selected.ir_rate}</strong>
                            </div> */}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
