/***
 *
 * Record Height and Weight
 * 
 * Interfacing followed in the project is of this manner
 * 
 * Create Test Order 
 * Read Machine Results
 * 
 *
 * @description
 * @author Sameena
 * 
 *   
 */

import React, { useState, useEffect } from 'react';

import { Clinicals, Samples } from '../../../../models';

import { Form, Button, message } from 'antd';

// Dateutils does not handle timezone very well , this need to be corrected
// #TODO Create A JIRA AND get this corrected
import { DateUtils, useTranslation } from 'soxo-bootstrap-core';

import './record-height-weight.scss';

// Variable to set interval value
var intervalId = null;

export default function RecordHeightandWeight({ bill, guest, form, setFormDisabled, attributes }) {

     // Variable used to save the attempt id / mint id
     const [id, setId] = useState(null);

     const [btnloading, setBtnLoading] = useState(false); //state to manage button loading

     const [counter, setCounter] = useState(null);

     const { t, i18n } = useTranslation(); // To Translate to another language

     const [view, setView] = useState('read')

     const [result, setResult] = useState({
          height: null,
          weight: null
     });

     useEffect(() => {

          // When the component is unmounted , we 
          // have to stop listener
          return () => {

               // Cleared the interval
               clearInterval(intervalId)
          }

     }, [])

     function deactivateListener() {

     }

     /**
    * Reset the application
    */
     const reset = () => {
          setResult({
               height: null,
               weight: null

          })
          changeView('read');
     }


     function changeView(status) {
          setView(status)
     }

     /**
      * 
      * @param {*} async 
      */
     const initializeReader = async () => {

          // 
          let formattedDob = DateUtils.formatDate(guest.dob, 'DD/MM/YYYY');

          let reference_number = `${bill.opb_bno}_${DateUtils.formatDate(bill.opb_dt, 'DD/MM/YYYY')}`

          let payload = {
               op_number: guest.op_number,
               reference_number: reference_number,
               name: `${guest.first_name} ${guest.last_name}`,
               gender: guest.gender,
               dob: formattedDob,
          }

          // changeView('read');

          const response = await Clinicals.initializeReader(payload)

          return response
     }


     /**
      * Machine Results holds the interfaced 
      * values 
      */
     const createOrder = async (bill) => {

          let payload = {
               bill_no: bill.opb_bno,
               request_number: bill.opb_bno,
               request_date: bill.opb_dt,
               test_code: 'HEIGHT',
               test_name: 'HEIGHT'
          }

          // Create an order
          const result1 = await Samples.createOrder(payload);

          console.log(result);

     }

     /**
      * Machine Results holds the interfaced 
      * values 
      * 
      * Considering Vietnam , Mongolia and Dubai , we have revisited the implementation 
      * to bring the flexibility for interfacing with any equipments going forward 
      * 
      */
     const readMachineResults = async () => {

          setBtnLoading(true);

          let payload = {
               resetConfig: attributes.height.resetConfiguration.scriptId,
               bill_no: bill.opb_bno
          }

          // Reset the existing entries
          await Clinicals.clearExistingEntries(payload);

          // // Set a timeout for 3 minutes
          // const timeoutId = setTimeout(() => {

          //      clearInterval(intervalId);

          //      setFormDisabled(false);

          //      message.error("Its taking longer than expected. Please try again!");

          // }, 180000); // 3 minutes in milliseconds

          // Set the interval for checking data
          intervalId = setInterval(async () => {

               let readPayload = {
                    readConfig: attributes.height.readConfiguration.scriptId
               }

               // let readPayload= attributes.readConfiguration.scriptId

               setCounter(intervalId);

               // To get result that needs to be prepopulated with bill number and item_code
               const response = await Clinicals.readNewEntries(readPayload);

               console.log("RESULTTT", response.response);
               if (response && response.response && response.success) {

                    clearInterval(intervalId);

                    // clearTimeout(timeoutId)

                    setResult(response.response);

                    // Calculating BMI
                    var bmi = (response.response.weight / (response.response.height * response.response.height)) * 10000;

                    var srvbcd_bmi = Math.round(bmi * 100) / 100;

                    // We have to round the number by a single digit
                    var roundedNumber = Math.round(srvbcd_bmi * 10) / 10;
                    // Rounds to one digit after the decimal point

                    form.setFieldsValue({
                         srvbcd_height: response.response.height,
                         srvbcd_weight: response.response.weight,
                         srvbcd_bmi: roundedNumber
                    })

                    setFormDisabled(false);

                    setBtnLoading(false);

                    // Change the view
                    changeView('confirm');
               }
               // Check the data
               // await checkData(id, intervalId, timeoutId);

          }, 3000);


     }

     /**
      * Start Reading 
      */
     const startReading = async () => {

          // Disable form
          setFormDisabled(true)

          var id;

          // Initialise
          var result = await initializeReader()

          // Get Id from result
          if (result.success && result.result) {
               id = result.result.id
               setId(id)
          }

          // Set a timeout for 3 minutes
          const timeoutId = setTimeout(() => {
               clearInterval(intervalId);

               setFormDisabled(false);

               message.error("Its taking longer than expected. Please try again!");

          }, 180000); // 3 minutes in milliseconds

          // Set the interval for checking data
          intervalId = setInterval(async () => {

               setCounter(intervalId);

               // Check the data
               await checkData(id, intervalId, timeoutId);

          }, 3000);
     };


     /**
      * Check if the data has arrived
      */
     const checkData = async (id, intervalId, timeoutId) => {

          // Payload
          let payload = {
               id
          }

          // Read data
          const response = await Clinicals.loadReaderData(payload)

          if (response && response.success) {

               clearInterval(intervalId);

               clearTimeout(timeoutId)

               setResult(response.result);

               // Calculating BMI
               var bmi = (response.result.weight / (response.result.height * response.result.height)) * 10000;

               var srvbcd_bmi = Math.round(bmi * 100) / 100;

               // We have to round the number by a single digit
               var roundedNumber = Math.round(srvbcd_bmi * 10) / 10;
               // Rounds to one digit after the decimal point

               form.setFieldsValue({
                    srvbcd_height: response.result.height,
                    srvbcd_weight: response.result.weight,
                    srvbcd_bmi: roundedNumber
               })

               setFormDisabled(false)

               // Change the view
               changeView('confirm');
          }
     }


     /**
      * Reset the application
      */
     const cancelReading = async () => {

          clearInterval(counter);

          setFormDisabled(false)

          setBtnLoading(false)

          setResult({
               height: null,
               weight: null
     
          })
          
          // changeView('read');

          // # TODO Below needs to be changed to include a reset config for 

          // // Payload
          // let payload = {
          //      id
          // }
          // // Read seca data
          // const response = await Clinicals.cancelReader(payload)

          // if (response.success) {

          //      // const res = JSON.stringify(response.result)
          //      setResult({
          //           height: null,
          //           weight: null
          //      })

          //      // setResult(response.result);

          //      // Change the view
          //      changeView('read');

          //      // form.setFieldsValue({ srvbcd_height: null, srvbcd_weight: null })
          // }
     }

     /**
     *
     * Renders the component according to status
     *
     * @param {*} status
     * @returns
     */
     const renderSwitch = (status) => {

          // Extract the results
          const { height, weight } = result;

          switch (status) {

               case 'read':
                    return (
                         <>
                              <div className='step-header'>
                                   <span>{t('Step 1/3')}</span>
                              </div>

                              <h4>1. {t('Read')}</h4>
                              <p> {t('Height and Weight is ready to read. Click on the read button once ready to start procedure')}

                              </p>

                              <div className='form-actions'>

                                   <Button size='small' loading={btnloading} onClick={readMachineResults} type='primary'>
                                        {t('Start Reading')}
                                   </Button>


                                   {/* <Button size='small' onClick={startReading} type='primary'>
                                        Start Reading
                                   </Button> */}

                                   {/* If ID is present */}
                                   {counter ? <>

                                        <Button size='small' onClick={cancelReading}>
                                             {t('Cancel')}
                                        </Button>
                                   </> : null}
                                   {/* We can cancel */}


                              </div>

                         </>
                    )

               case 'reading':
                    return (
                         <>
                              <div className='step-header'>
                                   <span>{t('Step 2/3')}</span>
                              </div>

                              <h4>2. {t('Reading Clinical Details')} <i class="fas fa-sync fa-spin"></i>
                              </h4>


                              <p> {t('Height and Weight read is ongoing, Please wait')}
                                   <i className="fas fa-sync fa-spin"></i>
                              </p>

                              <div className='form-actions'>

                                   <Button size='small' onClick={cancelReading}>
                                        {t('Cancel')}
                                   </Button>

                              </div>
                         </>
                    )

               case 'confirm':



                    return (<>

                         <div className='step-header'>
                              <span>{t('Step 3/3')}</span>
                         </div>

                         <h4>{t('Confirm')} <i class="fa-solid fa-clipboard-check"></i></h4>
                         {/* <i class="fa-solid fa-circle-check" style={{ color: '#1daa18' }}></i> */}

                         <p> {t('The calculation is complete')}</p>

                         {/* Results Displayed */}
                         <div className='read-results'>

                              <p>{t('Height')}: {height}</p>
                              <p>{t('Weight')}: {weight}</p>

                         </div>
                         {/* Results Displayed Ends */}


                         {/* <div className='form-actions'>

                              <Button onClick={cancelReading}>
                                   Apply Values
                              </Button>

                              <Button onClick={cancelReading}>
                                   Clear
                              </Button>
                         </div> */}

                         {/* <Button size="small" onClick={cancelReading}>
                              Clear
                         </Button> */}

                         <Button size="small" onClick={reset}>
                              {t('Clear')}
                         </Button>
                    </>

                    )
               //    return <Confirmation callback={callback} />;

               default:
                    //    return <InitialContent />;
                    return (
                         <>
                              <p> {t('Click on Read to start the procedure')}</p>

                              <Button onClick={() => changeView('read')}>{t('Read')}</Button>
                         </>
                    )
          }
     }

     return (
          <div className="height-weight-reader card card-shadow">

               {/* <Button onClick={() => {

                    readMachineResults();
               }}>
                    Start Reading
               </Button> */}

               <div className="form-step">
                    {renderSwitch(view)}
               </div>

          </div>
     );
}