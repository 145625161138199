import React, { useState, useEffect } from 'react';
import { Button, Typography, message } from 'antd';
import './read-emiratesid.scss';
import axios from 'axios';
import moment from 'moment';

const { Title } = Typography;

export function ReadEmiratesId(props) {
    const [btnloading, setBtnLoading] = useState(false);
    const [loading, setLoading] = useState(false);


  /**Read Emirates Card Details */
  const readCard = () => {
    setBtnLoading(true);

    axios.get(`http://localhost:5000/Pass/`)           
    .then((response) => {    
        setBtnLoading(false);
        if(response.data !== 'NO PCSC Readers'){
            let result = JSON.parse(response.data); 
      
            // Parse the JSON data from the response
            let fields = {
                // "title": "Mr.",
                "first_name": result.FullName.split(" ")[0],
                "last_name":  result.FullName.substr(result.FullName.indexOf(" ") + 1),
                "gender": result.Sex,
                "date_of_birth": {
                    "dob":  moment(new Date(result.DateOfBirth)),
                    "year": moment().diff(moment(new Date(result.DateOfBirth), 'YYYYMMDD'), 'years'),
                    "month": moment.duration(moment().diff(moment(new Date(result.DateOfBirth)))).months(),
                    "day": moment.duration(moment().diff(moment(new Date(result.DateOfBirth)))).days()
                },
                // "mobile_number": "332148877",
                "email_address": "buivando130898@gmail.com",
                "age": moment().diff(moment(new Date(result.DateOfBirth), 'YYYYMMDD'), 'years'),
                "othernatid":result.IdNumber,
                // "address1": "",
                // "address2": "",
                // "place": null,
                // "pincode": null,
                // "state": null,
                // "country": null,
                // "countrycode": "84",
                "ageyear":  moment().diff(moment(new Date(result.DateOfBirth), 'YYYYMMDD'), 'years'),
                "agemonth": moment.duration(moment().diff(moment(new Date(result.DateOfBirth)))).months(),
                "ageday": moment.duration(moment().diff(moment(new Date(result.DateOfBirth)))).days(),
                // "landmark": null,
                // "alternate_mobile": null,
                // "countrycode2": null,
                // "billValue": 10000,
                // "coupon": null,
                // "couponno": null,
                // "camp_site": null,
                // "remarks": null,
                // "corporate_pointer": null,
                // "corporate_information": null
            }       
            /* Load guest information based on emirates details*/
            props.loadGuestInformation(fields);
        }else{
            message.error(response.data);


        }       
      
    }).catch((error) => {//Handle any errors that occur during the request
        console.error('Error:', error);
        setBtnLoading(false);

    });           

}


    return (
    
        <div className="emirates card">
            <div className="right-card">
                <div className="title">
                    <Title level={5}> EMIRATES DETAILS </Title>
                </div>
                <Button size='small' onClick={() => readCard()} loading={btnloading}> READ</Button>
            </div>
        </div>        
    );
}
