/***
 *
 * Read BP Data
 *
 * @description
 * @author Sameena
 * @author Sneha
 * @author Ashique
 * 
 */

import React, { useEffect, useState, useContext } from 'react';

import { Clinicals } from '../../../../models';

import { message, Button } from 'antd';

import { useTranslation} from 'soxo-bootstrap-core';

import './read-bloodpressure.scss'

// Variable to set the interval id
var bpIntervalId = null;

/**
 * Component interfaced with blood pressure
 * 
 * @param {*} param0 
 * @returns 
 */
export default function ReadBloodPressure({ form, setFormDisabled, attributes }) {

     const [btnloading, setBtnLoading] = useState(false); //state to manage button loading

     const [counter, setCounter] = useState(0)

     const { t, i18n } = useTranslation(); // To Translate to another language

     const [result, setResult] = useState(
          {
               bp_sys: null,
               by_dia: null,
               pulse: null
          }
     )

     const [view, setView] = useState('read')

     useEffect(() => {

          return () => {

               clearInterval(bpIntervalId);
          }

     }, [])

     function changeView(status) {
          setView(status)
     }

     /**
      * Read Data
      */
     // const readData = async () => {

     //      // To disable form
     //      setFormDisabled(true)

     //      // To reset existing entries so that latest data is read
     //      await Clinicals.resetExistingEntries()

     //      changeView('reading')

     //      // Set a timeout for 3 minutes
     //      const timeoutId = setTimeout(() => {
     //           clearInterval(bpIntervalId);

     //           setFormDisabled(false);

     //           message.error("Time is up.Please try again!");

     //           // Change the view
     //           changeView('read');
     //      }, 180000); // 3 minutes in milliseconds

     //      // Set the interval for checking data
     //      bpIntervalId = setInterval(async () => {

     //           setCounter(bpIntervalId);

     //           // Check the data
     //           await startReading(bpIntervalId, timeoutId);

     //      }, 3000);

     // }

     /**
     * Machine Results holds the interfaced 
     * values 
     * 
     * Considering Vietnam , Mongolia and Dubai , we have revisited the implementation 
     * to bring the flexibility for interfacing with any equipments going forward 
     * 
     */
     const readMachineResults = async () => {

          setBtnLoading(true)

          let payload = {
               resetConfig: attributes.bp.resetConfiguration.scriptId
          }

          // Reset the existing entries
          await Clinicals.clearExistingEntries(payload);

          // Create an order to the system
          // await createOrder(bill)
          let readPayload = {
               readConfig: attributes.bp.readConfiguration.scriptId,
          }

          //   const timeoutId = setTimeout(() => {
          //      clearInterval(bpIntervalId);

          //      setFormDisabled(false);

          //      message.error("Time is up.Please try again!");

          //      // Change the view
          //      changeView('read');
          // }, 180000); // 3 minutes in milliseconds

          // Set the interval for checking data
          bpIntervalId = setInterval(async () => {

               setCounter(bpIntervalId);

               // To get result that needs to be prepopulated with bill number and item_code
               const response = await Clinicals.readNewEntries(readPayload);

               if (response && response.response.bp_sys != null && response.response.pulse != null) {

                    setFormDisabled(false)

                    setBtnLoading(false)

                    setResult(response.response);

                    clearInterval(bpIntervalId);

                    // clearTimeout(timeoutId)
                    form.setFieldsValue({
                         srvbcd_bpsys: response.response.bp_sys,
                         srvbcd_bpdia: response.response.bp_dia,
                         srvbcd_pulse: response.response.pulse
                    })

                    setFormDisabled(false);
                    // Change the view
                    changeView('confirm');
               }


               // Check the data
               // await checkData(id, intervalId, timeoutId);

          }, 3000);


     }

     /**
      * Start Reading
      */
     // const startReading = async (bpIntervalId, timeoutId) => {

     //      const response = await Clinicals.loadData().catch(() => {

     //           message.warn('Could not connect');
     //      })

     //      console.log("response",response);

     //      if (response && response.bp_sys != null && response.pulse != null) {

     //           setResult(response);

     //           clearInterval(bpIntervalId);

     //           clearTimeout(timeoutId)

     //           form.setFieldsValue({ srvbcd_bpsys: response.bp_sys, srvbcd_bpdia: response.bp_dia, srvbcd_pulse: response.pulse })

     //           setFormDisabled(false);
     //           // Change the view
     //           changeView('confirm');
     //      }
     // }

     /**
      * Reset the application
      */
     const reset = () => {

          clearInterval(counter);

          setFormDisabled(false)

          setBtnLoading(false)


          setResult({
               bp_sys: null,
               by_dia: null,
               pulse: null

          })
          changeView('read');
     }

     /**
    *
    * Renders the component according to status
    *
    * @param {*} status
    * @returns
    */
     const renderSwitch = (status) => {
          switch (status) {

               case 'read':
                    return (
                         <>

                              <div className='step-header'>
                                   <span>{t('Step 1/3')}</span>
                              </div>


                              <h4>1. {t('Read')}</h4>
                              <p> {t('Click on read to start reading Blood Pressure and Pulse')}</p>

                              <div className='form-actions'>


                                   <Button size="small" loading={btnloading} onClick={readMachineResults} type="primary">
                                        {t('Start Reading')}
                                   </Button>

                                   {/* <Button size="small" onClick={readData} type="primary">
                                        Start Reading
                                   </Button>
 */}
                                   <Button size="small" onClick={reset} >
                                        {t('Cancel')}
                                   </Button>
                              </div>
                         </>
                    )
               case 'reading':
                    return (
                         <>

                              <div className='step-header'>
                                   <span>{t('Step 2/3')}</span>
                              </div>

                              <h4>2. {t('Reading')}
                              </h4>

                              <p> {t('BP Reading is ongoing, Please wait')}</p>

                              <i className="fas fa-sync fa-spin"></i>
                         </>

                    )

               case 'confirm':
                    return (<>
                         <h4>Confirm <i class="fa-solid fa-clipboard-check"></i></h4>

                         <div className='read-results'>

                              <p>{t('BP Sys')}:{result.bp_sys}</p>
                              <p>{t('BP Dia')}:{result.bp_dia}</p>
                              <p>{t('Pulse')}:{result.pulse}</p>
                         </div>

                         <div className='form-actions'>

                              <Button size="small" onClick={reset} type="primary">
                                   {t('Clear')}
                              </Button>

                         </div>
                    </>)

               default:
                    return (
                         <>
                              <p> {t('Click on Read to start the procedure')}</p>

                              <Button size="small" onClick={() => changeView('read')}>{t('Read')}</Button>
                         </>
                    )
          }
     };

     return (
          <div className="blood-pressure-reader card card-shadow" >

               <div className="form-step" >

                    {renderSwitch(view)}
               </div >
          </div>
     );
}
