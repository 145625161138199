import React, { useEffect, useState } from 'react';

import { Referrers } from '../../models';

import moment from 'moment-timezone';

import './referrer.scss';

import { RangePicker, Location, ReferenceSelect,GoogleMap, useTranslation } from 'soxo-bootstrap-core';

import { Form, Button, message, Typography, Card, Alert } from 'antd';

const { Title } = Typography;

export default function Referrer({ history }) {
    const [lat, setLat] = useState(20.5937);

    const [place, setPlace] = useState();

    const [mapcontrol, setMapcontrol] = useState({});

    const { t, i18n } = useTranslation(); // To Translate to another language

    const [lng, setLng] = useState(78.9629);

    const [initial, setInitial] = useState(() => {
        if (localStorage.appConfiguration) {
            let app = JSON.parse(localStorage.appConfiguration);
            getReferrer(app.referrer);

            return {
                range: [moment(app.start_time), moment(app.end_time)],
                referrer: app.referrer,
            };
        } else {
            return {
                range: [moment(), moment()],
                referrer: [],
            };
        }
    });

    var param = Location.search();

    const [range, setRange] = useState([moment.tz(process.env.REACT_APP_TIMEZONE).startOf('day'), moment.tz(process.env.REACT_APP_TIMEZONE).endOf('day')]);

    const [loading, setLoading] = useState(false);

    /**
     *  Get Referrer
     */

    async function getReferrer(value) {
        await Referrers.getReferrer(value).then((result) => {
            // setReferrer(result);
            setLat(result.refr_latitude);
            setLng(result.refr_longitude);
            setPlace(result.refr_desc);
        });
    }

    const onChange = (range) => {
        setRange(range);
    };

    const onFinish = (formBody) => {
        setLoading(true);
        const { referrer, range } = formBody;

        let data = {
            referrer,

            start_time: moment(range[0]).format('MM/DD/YYYY'),
            end_time: moment(range[1]).format('MM/DD/YYYY'),
        };

        localStorage.appConfiguration = JSON.stringify(data);

        setLoading(false);

        localStorage.appConfiguration = JSON.stringify(data);

        message.success('Submitted successfully');

        // Location.navigate({ url: `` });
    };

    return (
        <section className="sample-detail card">
            <div className="page-header">
                <div className="left">
                    <Title style={{ marginBottom: '0px' }} level={3}>
                        {t('Select Campaign')}
                    </Title>
                </div>
            </div>
            <br />
            <div>
                <Alert message={t("Campaign Selection")} description={`Please choose campaign and set a date range for the campaign.`} type="info" />
            </div>

            <br />
            <div className="reffer-section">
                <div className="form-class">
                    <Form
                        name="referrer"
                        labelCol={{
                            span: 4,
                        }}
                        wrapperCol={{
                            span: 5,
                        }}
                        layout="vertical"
                        initialValues={{
                            ...initial,
                        }}
                        onFinish={(formBody) => {
                            onFinish(formBody);
                        }}
                        onFieldsChange={(fields, value) => {
                            let field = fields[0];

                            // if (field && field.value) {
                            if (field.name[0] === 'referrer') {
                                if (field.value) {
                                    getReferrer(field.value);
                                }

                                // setMap(true);
                            }
                            // } else {
                            // }
                        }}
                    >
                        {/* <div className='section'> */}
                        <Form.Item className="camp" label={t("Campaign")} name="referrer" rules={[{ required: true, message: t('Please select Campaign') }]}>
                            <ReferenceSelect
                                label="refr_desc"
                                mode="nura-base"
                                model={Referrers}
                                field={'refr_code'}
                                //  callback={({ ll }) => {
                                //     console.log("ll",ll)

                                //     mapcontrol.setCenter(ll.lng, ll.lat);
                                // }}
                            />
                        </Form.Item>

                        <Form.Item className="range-picker" name="range" label={t("Date")} rules={[{ required: true }]}>
                            <RangePicker
                                allowClear={false}
                                inputReadOnly
                                format={'DD/MM/YYYY'}
                                value={range}
                                // onChange={onChange}
                                onChange={(time) => {
                                    onChange(time, range);
                                }}
                                ranges={{
                                    Today: [moment(), moment()],

                                    Yesterday: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],

                                    'This Week': [moment().startOf('week'), moment().endOf('week')],

                                    'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],

                                    'This Month': [moment().startOf('month'), moment().endOf('month')],

                                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                }}
                            />
                        </Form.Item>
                        {/* </div> */}
                        <div>
                            <Button className="reffer-submit" loading={loading} type="primary" htmlType="submit">
                                {t('Save')}
                            </Button>
                        </div>
                    </Form>
                </div>
                {/* <div className="google-map"> */}

                {/* {localStorage.appConfiguration ? ( */}

                {/* <Card className="inner-card ">
                    <GoogleMap
                        lat={lat}
                        lng={lng}
                        referrer={place || 'India'}
                        center={{
                            lat: lat,
                            lng: lng,
                        }}
                        height={'470px'}
                        // width={"450px"}
                        width={'600px'}
                        onMapsLoaded={(map, maps) => {
                            setMapcontrol(map);
                        }}
                        draggable={false}
                    />
                </Card> */}
                {/* ) : null} */}
            </div>

            {/* </div> */}
        </section>
    );
}
