/**
 * Component for Image Viewer
 *
 *
 *
 */

import React, { useState, useEffect, useRef } from 'react';

import { Modal, Switch } from 'antd';

import './image-viewer.scss';

import { useTranslation } from 'soxo-bootstrap-core';

export default function ImageViewer({ props, billId, mail, callback }) {

    const [referrel, setReferrel] = useState(false);

    // Variable to save the modal control
    const [visible, setVisible] = useState(false);

    const [images, setImages] = useState([]);

    const [data, setData] = useState(null);

    const { t, i18n } = useTranslation(); // To Translate to another language

    useEffect(() => {

        getBranches();

        getImageList();

    }, [])

    const getBranches = () => {

        // self.request_xml = '<x:Envelope xmlns:x="http://schemas.xmlsoap.org/soap/envelope/" xmlns:med="http://medsoft.com/">' +
        //     '<x:Header/>' +
        //     '<x:Body>' +
        //     '<med:GetBranchList>' +
        //     '<med:Key>MedImg0999</med:Key>' +
        //     '<med:BranchCode></med:BranchCode>' +
        //     '</med:GetBranchList>' +
        //     '</x:Body>' +
        //     '</x:Envelope>';

        // return $http.post(self.url, self.request_xml,
        //     {
        //         headers: {
        //             'content-type': 'text/xml; charset=utf-8'
        //         },
        //         transformResponse: function (data) {
        //             var xml = x2js.xml_str2json(data);
        //             var branch_list = JSON.parse(xml.Envelope.Body.GetBranchListResponse.GetBranchListResult);
        //             /*remove test branch from the list*/
        //             branch_list = branch_list.Branch.filter(function (branch) {
        //                 return branch.BranchCode != 99;
        //             });
        //             return branch_list;
        //         }
        //     });

    }

    /**
     * Get the Image list
     */
    const getImageList = async () => {

        let body = '';

        if (referrel) {
            body = '<x:Envelope xmlns:x="http://schemas.xmlsoap.org/soap/envelope/" xmlns:med="http://medsoft.com/">' +
                '<x:Header/>' +
                '<x:Body>' +
                '<med:GetImageList>' +
                '<med:Key>MedImg0999</med:Key>' +
                '<med:Nos>10</med:Nos>' +
                '<med:Mode>P</med:Mode>' +
                '</med:GetImageList>' +
                '</x:Body>' +
                '</x:Envelope>';


        } else {
            body = '<x:Envelope xmlns:x="http://schemas.xmlsoap.org/soap/envelope/" xmlns:med="http://medsoft.com/">' +
                '<x:Header/>' +
                '<x:Body>' +
                '<med:GetImageList>' +
                '<med:Key>MedImg0999</med:Key>' +
                '<med:Nos>10</med:Nos>' +
                '<med:Mode></med:Mode>' +
                '</med:GetImageList>' +
                '</x:Body>' +
                '</x:Envelope>';
        }

        const result = await loadXMLData(body, (xmlDoc) => {

            return xmlDoc.getElementsByTagName("GetImageListResult")[0].textContent;

        }, (response) => {
            return JSON.parse(response)
        });

        setImages(result);

    }

    /**
     * Load the specific image data
     * 
     * @param {*} id 
     */
    const getImageData = async (id) => {

        let payload = '<x:Envelope xmlns:x="http://schemas.xmlsoap.org/soap/envelope/" xmlns:med="http://medsoft.com/">' +
            '<x:Header/>' +
            '<x:Body>' +
            '<med:GetImage>' +
            '<med:_Key>MedImg0999</med:_Key>' +
            '<med:vID>' + parseInt(id) + '</med:vID>' +
            '</med:GetImage>' +
            '</x:Body>' +
            '</x:Envelope>'

        const result = await loadXMLData(payload, (xmlDoc) => {

            return xmlDoc.getElementsByTagName("GetImageResponse")[0].textContent;

            // GetImageResponse.GetImageResult
        })

        var base64String = btoa(String.fromCharCode.apply(null, new Uint8Array(result)));

        console.log(base64String);

        setData(base64String);

        setVisible(true);
    }

    /**
     * On Update of referrel
     * 
     * @param {*} checked 
     */
    const onChangeReferral = (checked) => {

        console.log(checked);

        setReferrel(checked)

    }

    return (
        <div className="image-viewer">

            <Switch
                checked={referrel} onChange={onChangeReferral}
            />

            {images.map((image) => {
                return (<p>
                    {image.ID} - {image.RefNo} <button onClick={() => getImageData(image.ID)}> {t('Open')} </button>
                </p>)
            })}

            <Modal open={visible}>

                <Viewer data={data} />

            </Modal>
        </div>
    );
}

/**
 * 
 * @param {*} param0 
 * @returns 
 */
function Viewer({ data }) {

    useEffect(() => {

        loadStatus();
    }, [])

    /**
     * 
     */
    const loadStatus = async () => {

        let payload = '<x:Envelope xmlns:x="http://schemas.xmlsoap.org/soap/envelope/" xmlns:med="http://medsoft.com/">' +
            '<x:Header/>' +
            '<x:Body>' +
            '<med:GetStatusList>' +
            '<med:Key>MedImg0999</med:Key>' +
            '</med:GetStatusList>' +
            '</x:Body>' +
            '</x:Envelope>';

        const result = await loadXMLData(payload, (xmlDoc) => {
            return xmlDoc.getElementsByTagName("GetStatusListResponse")[0].textContent;
        })

        console.log(result);
    }

    const updateStatus = async () => {

        // self.request_xml = '<x:Envelope xmlns:x="http://schemas.xmlsoap.org/soap/envelope/" xmlns:med="http://medsoft.com/">' +
        //     '<x:Header/>' +
        //     '<x:Body>' +
        //     '<med:UpdateStatus>' +
        //     '<med:Key>MedImg0999</med:Key>' +
        //     '<med:ID>' + id + '</med:ID>' +
        //     '<med:Status>' + status + '</med:Status>' +
        //     '<med:Report>' + report + '</med:Report>' +
        //     '</med:UpdateStatus>' +
        //     '</x:Body>' +
        //     '</x:Envelope>';

        // return $http.post(self.url, self.request_xml,
        //     {
        //         headers: {
        //             'content-type': 'text/xml; charset=utf-8'
        //         },
        //         transformResponse: function (data) {
        //             var xml = x2js.xml_str2json(data);
        //             var update_result = xml.Envelope.Body.UpdateStatusResponse.UpdateStatusResult;
        //             return JSON.parse(update_result);
        //         }
        //     });
    }

    return (<div>

        {data ? <img class="full-image" src={`data:image/png;base64,${data}`} alt="" ng-show="imagectrl.image" /> : null}
    </div>)
}




/**
   * A Wrapper function for loading xml data from legacy APIs
   */
const loadXMLData = (body, transformDocument, transformResponse) => {

    let url = 'http://173.248.128.134/MedImg/WebServ/MedImgJsonService.asmx';

    // Payload for the request
    let payload = {
        method: 'POST',
        headers: {
            'Content-Type': 'text/xml;charset=utf-8',
        },
        body: body,
    };

    return fetch(url, payload)
        .then(res => res.text())
        .then(response => {

            // Parse the XML
            var parser = new DOMParser();
            var xmlDoc = parser.parseFromString(response, "text/xml");

            console.log(xmlDoc)

            // Find the GetImageListResult element and get its text content
            // var result = xmlDoc.getElementsByTagName("GetImageListResult")[0].textContent;

            var result = transformDocument(xmlDoc);

            // Parse the JSON string
            var jsonObject = JSON.parse(result);

            console.log(jsonObject, typeof jsonObject);

            return transformResponse ? transformResponse(jsonObject) : jsonObject;
        })
        .catch(error => {
            // Handle errors
            console.error('Something went wrong:', error);
        });
}

