/**
 * List Country Model
 *
 *
 *
 *
 */


import { ApiUtils, BaseAPI } from 'soxo-bootstrap-core';



class ListCountry extends BaseAPI {
    constructor() {
        super();

    }

    get getEndpoint() {
        return 'list-country';
    }

    get path() {
        return `list-country`;
    }

    get getName() {
        return this.modelName;
    }

    get modelName() {
        return `list-country`;
    }
}
export default ListCountry;
