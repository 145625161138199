/***
 *
 *
 * @description
 * @author Sneha
 */

import React, { useEffect, useState, useContext } from 'react';

import { Link } from 'react-router-dom';

import { Card, Table, Button, Space, Typography, Skeleton, Tag, Input, Switch, Tabs } from 'antd';

import { DateUtils, GlobalContext, Location, RangePicker, CustomActions, useTranslation } from 'soxo-bootstrap-core';

import { Bills } from '../../../../models';

import moment from 'moment-timezone';
import { ReloadOutlined, OrderedListOutlined, PicCenterOutlined } from '@ant-design/icons';
import './reward-list.scss';

const { Search } = Input;

const { TabPane } = Tabs;

const { Title, Text } = Typography;

export default function RewardList({ model, edit, history, match, menu, redirect_link, ...props }) {

    let { attributes = {} } = menu;

    //Patients list array
    var [patients, setPatients] = useState([]);

    var [query, setQuery] = useState('');

    const { user = {}, isMobile, dispatch } = useContext(GlobalContext);

    var [actives, setActives] = useState([]);

    //Need to check this condition
    const [view, setView] = useState(isMobile ? true : false);

    var param = Location.search();

    const [loading, setLoading] = useState(true);

    const [limit, setLimit] = useState(10);

    const [page, setPage] = useState(1);

    const { t, i18n } = useTranslation();    // To Translate to another language

    useEffect(() => {
        loadData(range);

        getList();
    }, []);

    var starttime = moment().startOf('day');

    var endtime = moment().endOf('day');

    if (param.start_time) {
        updateRange();
    }

    function changeView(result) {
        setView(result);
    }

    function updateRange() {
        starttime = moment(param.start_time, 'DD/MM/YYYY', process.env.REACT_APP_TIMEZONE).startOf('day');
        endtime = moment(param.end_time, 'DD/MM/YYYY', process.env.REACT_APP_TIMEZONE).startOf('day');

        return starttime, endtime;
    }

    const [range, setRange] = useState([starttime, endtime]);

    /**
     *  columns of table for Active list
     */

    const colums = [
        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => {
                return (page - 1) * limit + index + 1;
            },
        },

        {
            title: t('Name'),
            key: 'name',
            render: (ele) => {
                const { opreg } = ele;

                if (opreg) {
                    return `${opreg.op_fname} ${opreg.op_lname}`;
                } else {
                    return 0;
                }
            },
        },

        {
            title: t('OP NO'),
            key: 'op no',
            render: (ele) => {
                return ele.account_code;
            },
        },

        {
            title: t('Gender'),
            key: 'gender',
            render: (ele) => {
                const { opreg } = ele;
                if (opreg && opreg != null && opreg.op_gender != null && opreg.op_gender) {
                    let gender = null;
                    if (opreg && opreg.op_gender === 'Male') {
                        gender = 'Male';
                    } else if (opreg && opreg.op_gender === 'M') {
                        gender = 'Male';
                    } else if (opreg && opreg.op_gender === 'Female') {
                        gender = 'Female';
                    } else if (opreg && opreg.op_gender) {
                        gender = 'Female';
                    }
                    return gender;
                }
            },
        },

        {
            title: t('Contact'),
            key: 'contact',
            render: (ele) => {
                const { opreg } = ele;

                if (opreg) {
                    return opreg.op_mobile;
                } else {
                    return 0;
                }
            },
        },

        {
            title: t('Date'),
            key: 'date',
            render: (ele) => {
                return ele.created_at ? moment(ele.created_at).format('DD/MM/YYYY') : null;
            },
        },

        {
            title: t('Wallet'),
            key: 'wallet',
            render: (ele) => {
                if (ele.status === 'A') {
                    return <Tag color="green">{t('Active')}</Tag>;
                } else if (ele.status === 'E') {
                    return <Tag color="orange">{t('Pending For Approvel')}</Tag>;
                } else if (ele.status === 'S') {
                    return <Tag color="orange">{t('Suspended')}</Tag>;
                } else if (ele.status === 'D') {
                    return <Tag color="red">{t('Deactiveted')}</Tag>;
                }
            },
        },
        {
            title: t('Accumulated Points'),
            key: 'accumualted points',
            render: (ele) => {
                return ele.accumulated_points;
            },
        },

        {
            title: t('Balance Points'),
            key: 'balance points',
            render: (ele) => {
                return ele.balance_points;
            },
        },

        {
            title: t('Action'),
            fixed: 'right',

            render: (ele) => {
                let { opreg } = ele;
                let redirectLink = `/bill/:id`;

                if (redirect_link) {
                    redirectLink = redirect_link;
                }

                redirectLink = redirectLink.replace(':id', ele.account_code);

                return <Link to={redirectLink}>{t('View')}</Link>;
            },
        },
    ];

    /**
     *  columns of table for Reward list
     */

    const bookingColums = [
        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => {
                return (page - 1) * limit + index + 1;
            },
        },

        {
            title: t('Name'),
            key: 'name',
            render: (ele) => {
                const { bill, reward } = ele;

                return bill.opb_name;
            },
        },

        {
            title: t('OP No'),
            key: 'opb_opno',
            render: (ele) => {
                // console.log('ele',ele)
                //    if(ele.opbill && ele.opbill.opb_name)

                const { bill, reward } = ele;

                return bill.opb_opno;
            },
        },

        {
            title: t('Date'),
            key: 'created-at',
            render: (ele) => {
                const { bill, reward } = ele;

                if (bill.opb_dt) {
                    return moment(bill.opb_dt).format('DD/MM/YYYY');
                }
            },
        },

        {
            title: t('Gender'),
            key: 'gender',

            render: (ele) => {
                const { bill, reward } = ele;

                if (bill.opb_gender) {
                    let gender = null;
                    if (bill.opb_gender === 'Male') {
                        gender = 'Male';
                    } else if (bill.opb_gender === 'M') {
                        gender = 'Male';
                    } else if (bill.opb_gender === 'Female') {
                        gender = 'Female';
                    } else if (bill.opb_gender) {
                        gender = 'Female';
                    }
                    return gender;
                } else {
                    return bill.opb_gender;
                }
            },
        },

        {
            title: t('Mobile'),
            // dataIndex: 'Name',
            key: 'Mobile',
            render: (ele) => {
                const { bill, reward } = ele;

                if (bill.opb_mobile) return bill.opb_mobile;
            },
        },

        {
            title: t('Wallet'),
            key: 'wallet',
            render: (ele) => {
                const { bill, reward } = ele;

                if (reward) {
                    return reward.status === 'E' ? <Tag color="blue">{t('Under Processing')}</Tag> : <Tag color="green">{t('Active')}</Tag>;
                } else {
                    return <Tag color="orange">{t('Inactive')}</Tag>;
                }
            },
        },

        {
            title: t('Accumulated Points'),
            key: 'wallet',
            render: (ele) => {
                const { bill, reward } = ele;

                if (reward) {
                    return reward.accumulated_points;
                } else {
                    return 0;
                }
            },
        },

        {
            title: t('Balance Points'),
            key: 'wallet',
            render: (ele) => {
                const { bill, reward } = ele;

                if (reward) {
                    return reward.balance_points;
                } else {
                    return 0;
                }
            },
        },

        // {
        //     title: 'Paid amount',
        //     // dataIndex: 'Name',
        //     key: 'paid',
        //     render: (ele) => {
        //         if (ele.opb_paidamt)
        //             return ele.opb_paidamt;
        //     },
        // },
        // {
        //     title: 'Discount',
        //     // dataIndex: 'Name',
        //     key: 'paid',
        //     render: (ele) => {
        //         if (ele.opb_disamt)
        //             return ele.opb_disamt;
        //     },
        // },

        // {
        //     title: 'Total',
        //     // dataIndex: 'Name',
        //     key: 'Total',
        //     render: (ele) => {
        //         return ele.opb_netamt;
        //     },
        // },

        {
            title: t('Action'),
            fixed: 'right',

            render: (record) => {
                const { bill, reward } = record;

                let redirectLink = `/bill/:id`;

                if (redirect_link) {
                    redirectLink = redirect_link;
                }

                redirectLink = redirectLink.replace(':id', bill.opb_opno);

                // return <Link to={redirectLink}>View</Link>;
                return <Link to={`${redirectLink}?opb_id=${bill.opb_id}&opb_no=${bill.opb_bno}`}>{t('View')}</Link>;
            },
        },
    ];

    /**
     * Load Data
     *
     */
    function loadData() {
        let urlParams = Location.search();

        // Match params
        let params = {
            start_time: range[0].format('MM/DD/YYYY'),
            end_time: range[1].format('MM/DD/YYYY'),

            // limit: 20,

            ...urlParams,
        };

        getAppointments(range);
    }

    function onSearch(event) {
        setQuery(event.target.value);
    }

    /**
     * Loading the appointments
     *
     * @param {*} startTime
     * @param {*} endTime
     */
    async function getAppointments(range) {
        setLoading(true);

        const result = await Bills.getRewardList(range);

        if (result.data) {
            setPatients(result.data);
        } else {
            setPatients([]);
        }

        // console.log(result);
        setLoading(false);
    }

    /**
     *  Active list
     */

    async function getList() {
        setLoading(true);

        const list = await Bills.getActiveList();

        setActives(list.result);

        setLoading(false);
    }

    let dataSource = patients.filter((record) => {
        query = query.toUpperCase();

        if (query) {
            // console.log("query",query);
            // if(record.opbill){
            if (record.bill.opb_name.toUpperCase().indexOf(query) != -1) {
                return true;
            } else if (record.bill.opb_mobile && record.bill.opb_mobile.indexOf(query) != -1) {
                return true;
            }

            // }
        } else {
            return true;
        }
    });

    let filtered = actives.filter((record) => {
        query = query.toUpperCase();
        const { opreg } = record;

        if (query) {
            // console.log("record",record);
            // if(record.opbill){
            if (opreg && opreg.op_fname.toUpperCase().indexOf(query) != -1) {
                return true;
            } else if (opreg && opreg.op_mobile && opreg.op_mobile.indexOf(query) != -1) {
                return true;
            }

            // }
        } else {
            return true;
        }
    });

    function updateTime(range) {
        setRange(range);

        Location.search({
            start_time: moment(range[0]).format('DD/MM/YYYY'),
            end_time: moment(range[1]).format('DD/MM/YYYY'),
        });

        getAppointments(range); //Patiant list get function
    }

    // function updateTime(dt) {
    //     // setRange(dt);

    //     let start_time = DateUtils.getFormattedTimeDate(dt[0])

    //     let end_time = DateUtils.getFormattedTimeDate(dt[1]);

    //     setRange(dt);

    //     Location.search({

    //         start_time,

    //         end_time,
    //     });

    //      getAppointments(dt[0], dt[1]); //Patiant list get function

    // }

    /**
     *
     */

    function refresh() {
        loadData();
    }

    /**
     * redirect to patients details
     */
    function redirectToPatientDetails() {
        Location.navigate({ url: '/personal-details' });
    }

    //To download
    // function getExportData(data, bookingColums) {

    //     return data.map((record, index) => {
    //         var content = {
    //             '#': index + 1,
    //         };

    //         bookingColums.forEach((column) => {
    //             if (column.title === '#') {

    //             } else if (column.title === 'Name') {
    //                 return content[column.title] = record.first_name + ' ' + record.last_name
    //             } else if (column.title === 'Appointment Time') {
    //                 return content[column.title] = DateUtils.fromFirebaseTime({ time: record.appointment_time });
    //             } else {
    //                 return (
    //                     content[column.title] = record[column.dataIndex])
    //             }

    //         })

    //         return content
    //     });

    // }

    //Get locations user has access to
    // var queries = [{
    //     // field: firebaseApp.firestore.FieldPath.documentId(),
    //     operator: 'in',
    //     value: user.branch_id
    // }]

    /**
     *
     */
    // const selectBranch = (branch_id) => {

    //     console.log(branch_id);

    //     // setSelectedbranch();

    //     loadData(branch_id);
    // }

    return (
        <section className="rewards-list card card-shadow">
            {/* Page Header */}

            {/* Page Header Ends */}

            {/* Content Below */}
            {loading ? (
                <>
                    <Skeleton active />
                </>
            ) : (
                <>
                    <>


                        <Tabs defaultActiveKey="0"

                            tabBarExtraContent={
                                <div className="page-header">
                                    <Search
                                        placeholder={t("Enter Search Value")}
                                        allowClear
                                        style={{ width: 300, marginTop: '10px', marginBottom: '20px' }}
                                        onChange={onSearch}
                                    />

                                    <div className="top-actions">
                                        <Switch
                                            defaultChecked={view}
                                            onChange={changeView}
                                            checked={view}
                                            checkedChildren={<OrderedListOutlined />}
                                            unCheckedChildren={<PicCenterOutlined />}
                                        />
                                    </div>

                                    <div className="right">
                                        <div className="date-and-fltr">
                                            <RangePicker
                                                allowClear={false}
                                                inputReadOnly
                                                format={'DD/MM/YYYY'}
                                                value={range}
                                                onChange={(time) => {
                                                    updateTime(time, range);
                                                }}
                                                ranges={{
                                                    Today: [moment(), moment()],

                                                    Yesterday: [
                                                        moment().subtract(1, 'days').startOf('day'),
                                                        moment().subtract(1, 'days').endOf('day'),
                                                    ],

                                                    'This Week': [moment().startOf('week'), moment().endOf('week')],

                                                    'Last Week': [
                                                        moment().subtract(1, 'week').startOf('week'),
                                                        moment().subtract(1, 'week').endOf('week'),
                                                    ],

                                                    'This Month': [moment().startOf('month'), moment().endOf('month')],

                                                    'Last Month': [
                                                        moment().subtract(1, 'month').startOf('month'),
                                                        moment().subtract(1, 'month').endOf('month'),
                                                    ],
                                                }}
                                            />

                                            <Button onClick={refresh} type="secondary" size={'small'}>
                                                <ReloadOutlined />
                                            </Button>
                                        </div>
                                    </div>


                                </div>

                            }
                        >
                            <TabPane tab={t("Bills")} key="0">
                                <div className="page-header">
                                    <div className="left">
                                        {/* <Title style={{ marginBottom: '0px' }} level={3}>
                                            Bill List
                                        </Title> */}

                                    </div>

                                </div>

                                <div>
                                    {view ? (
                                        <>
                                            <CardList redirect_link={redirect_link} dataSource={dataSource} />
                                        </>
                                    ) : (
                                        <>
                                            <Card className="table-card">
                                                <Table
                                                    scroll={{ x: true }}
                                                    rowKey={(record) => record.da_id}
                                                    dataSource={dataSource}
                                                    columns={bookingColums}
                                                    pagination={{
                                                        current: page,
                                                        onChange(current) {
                                                            setPage(current);
                                                        },
                                                    }}
                                                />
                                            </Card>
                                        </>
                                    )}
                                </div>
                            </TabPane>

                            <TabPane tab={t("Wallet Accounts")} key="1">
                                <div>
                                    {view ? (
                                        <>
                                            <Actives dataSource={filtered} columns={colums} redirect_link={redirect_link} />
                                        </>
                                    ) : (
                                        <>
                                            <Card className="table-card">
                                                <Table
                                                    scroll={{ x: true }}
                                                    //  rowKey={(record) => record.da_id}
                                                    dataSource={filtered}
                                                    columns={colums}
                                                    pagination={{
                                                        current: page,
                                                        onChange(current) {
                                                            setPage(current);
                                                        },
                                                    }}
                                                />
                                            </Card>
                                        </>
                                    )}
                                </div>
                            </TabPane>
                        </Tabs>

                        {/* <div>{contextHolder}</div> */}
                    </>

                    <p className="size-hint">{loading ? 'Loading' : patients.length} records.</p>


                    {/* <GuestList patients={patients} refresh={refresh} /> */}
                </>
            )}

            {/* <Divider /> */}

            {/* {!loading ? <Pagination pageSize={limit} showSizeChanger={true} onChange={onPagination} defaultCurrent={page} total={100} /> : null} */}
        </section>
    );
}

/**
 * Mobile view card Section for reward list
 */

function CardList({ dataSource, redirect_link, url }) {
    const { user = {}, isMobile, dispatch } = useContext(GlobalContext);

    function onClick(record) {
        const { bill, reward } = record;

        let redirectLink = `/bill/:id`;

        if (redirect_link) {
            redirectLink = redirect_link;
        }

        redirectLink = redirectLink.replace(':id', bill.opb_opno);

        Location.navigate({
            url: redirectLink,
        });

        // dispatch({ type: 'index', payload: item.rowIndex });
    }

    return dataSource.map((item, index) => {
        // to={`/lab-detail/${item.BillID}`}
        return (
            <div
                key={index}
                className="report-item"
                onClick={() => {
                    onClick(item);
                }}
            >
                <GuestCard record={item} />
            </div>
        );
    });
}

function GuestCard({ record, ele }) {
    const { bill, reward } = record;
    const { t, i18n } = useTranslation();    // To Translate to another language

    return (
        <Card className="card vehicle-card">
            <div className="title">
                <h1>
                {t('Name')}: <strong>{bill.opb_name} </strong>
                </h1>
            </div>

            <div className="values">
                <p>
                {t('Gender')} : <strong>{bill.opb_gender}</strong>
                </p>
            </div>

            <div className="values">
                <p>{t('Age')}: {record.opb_age}</p>
            </div>
            <div className="values">
                <p>{t('Mobile')}: {bill.opb_mobile}</p>
            </div>
            <div className="values">
                <p>{t('Wallet')}: {reward ? <Tag color="green">{t('Active')}</Tag> : <Tag color="orange">{t('Inactive')}</Tag>}</p>
            </div>
        </Card>
    );
}

/**
 * Mobile view card Section for active list
 */

function Actives({ dataSource, redirect_link, url }) {
    const { user = {}, isMobile, dispatch } = useContext(GlobalContext);

    function onClick(record) {
        let redirectLink = `/bill/:id`;

        if (redirect_link) {
            redirectLink = redirect_link;
        }

        redirectLink = redirectLink.replace(':id', record.account_code);

        Location.navigate({
            url: redirectLink,
        });

        // dispatch({ type: 'index', payload: item.rowIndex });
    }

    return dataSource.map((item, index) => {
        // to={`/lab-detail/${item.BillID}`}
        return (
            <div
                key={index}
                className="report-item"
                onClick={() => {
                    onClick(item);
                }}
            >
                <RewarCard record={item} />
            </div>
        );
    });
}

function RewarCard({ record }) {
    const { opreg } = record;

    const { t, i18n } = useTranslation();   // To Translate to another language

    return (
        <Card className="card vehicle-card">
            <div className="title">
                <h1>
                {t('Name')}:{' '}
                    <strong>
                        {opreg && opreg.op_fname ? opreg.op_fname.toUpperCase() : null}{' '}
                        {opreg && opreg.op_fname ? opreg.op_lname.toUpperCase() : null}{' '}
                    </strong>
                </h1>
            </div>

            <div className="values">
                <p>
                {t('Email')} : <strong>{opreg && opreg.op_email ? opreg.op_email : null}</strong>
                </p>
            </div>

            <div className="values">
                <p>{t('Age')}: {opreg && opreg.op_ageyear ? opreg.op_ageyear : null}</p>
            </div>
            <div className="values">
                <p>{t('Mobile')}:{opreg && opreg.op_mobile ? opreg.op_mobile : null} </p>
            </div>
            <div className="values">
                <p>
                {t('Wallet')}:{' '}
                    {record.status === 'A' ? (
                        <Tag color="green">{t('Active')}</Tag>
                    ) : record.status === 'E' ? (
                        <Tag color="green">{t('Pending for Approvel')}</Tag>
                    ) : record.status === 'S' ? (
                        <Tag color="green">{t('Suspended')}</Tag>
                    ) : (
                        <Tag color="green">{t('Deactiveted')}</Tag>
                    )}
                </p>
            </div>

            {/* Bill status */}
            {/* 
                    <div>
                        <strong>Bill Status</strong>   <Tag color='orange'>Pending</Tag>
                    </div> */}

            {/* <h3 className="values">
                Primary Result : {record.PrimaryResult || 'Pending'}
            </h3> */}

            {/* <Text type="secondary">{record.place}</Text>
                <h4 className="values">{record.da_date}</h4> */}
        </Card>
    );
}
