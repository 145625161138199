/**
 *
 * Component for Previewing reports
 *
 * @description
 * @author Sneha
 *
 */

import React, { useEffect, useState } from 'react';

import './bill-preview.scss';

import { Card, ReferenceSelect, useTranslation } from 'soxo-bootstrap-core';

import { Button, Form, Skeleton, Empty } from 'antd';

import { TemplateMasters, Bills } from '../../../../models';

/**
 *
 *
 * @param {*} param0
 * @returns
 */
export default function BillPreview({ billId, downloadFileName, groupPointer, templatecode }) {

    // Variable to convert BytesArray
    var [byteArray, setByteArray] = useState([]);

    //Variable to handle button loading
    const [btnloading, setBtnLoading] = useState(false);

    // Variable to disable and enable button
    const [disable, setDisable] = useState(false);

    const [templateCode, setTemplateCode] = useState();

    const [form] = Form.useForm();

    const { t, i18n } = useTranslation(); // To Translate to another 

    useEffect(() => {
        // loadLabResults(billId);
    }, []);

    /**
     * This is done to preview pdf on first load
     */
    useEffect(() => {
        preview();
    }, []);

    /**
     * Function to preview result
     *
     *
     */
    async function preview(values) {

        setBtnLoading(true);

        let formbody = {

            id: billId,
            template_code: templatecode

        };

        Bills.getBillPdf(formbody).then((result) => {
            setBtnLoading(true);

            if (result && result.data) {
                setBtnLoading(false);
                var res = result.data;
                var bytearray = Object.keys(res);
                var arrayelement = Object.values(res);

                var uint8Array = new Uint8Array(bytearray.length);

                for (var i = 0; i < uint8Array.length; i++) {
                    uint8Array[i] = arrayelement[i];
                }

                for (var i = 0; i < bytearray; i++) {
                    var ascii = arrayelement.charCodeAt(i);
                    uint8Array[i] = ascii;
                }

                setByteArray({ data: uint8Array });

                return uint8Array;
            } else {
                setByteArray([]);

                setBtnLoading(false);
            }
        });
    }

    /**
     *  Load default Template
     * @param {*} options
     * @returns
     */
    function identifyDefaultTemplate(options = []) {
        let matching = {};

        //To load lab report as default template
        if (options) {
            options.forEach(async (record) => {
                if (record.tmpl_groupptr === groupPointer) {
                    matching = record;
                }
            });
        }

        if (matching) {
            setTemplateCode(matching.tmpl_code);
            return matching;
        }
    }
    return (
        <div className="detail-contents">
            <div className="left-sections">

                <div className="bottom">
                    <Card className="card-shadow card" loading={() => <Skeleton />}>
                        {/* Preview Action Form */}
                        <Form onFinish={preview} form={form}>
                            <div>
                                <h2>{t('Report Actions')}</h2>
                            </div>

                            {/* <div className='labels'>
                                <label>Header Style</label>
                            </div>

                            <Select className='preview-header' placeholder="- Select header style -" /> */}

                            <div className="labels">
                                <label>{t('Template')}</label>
                            </div>

                            <div className="template">
                                <Form.Item name="template_code">
                                    <ReferenceSelect
                                        config={{
                                            // limit: 50,
                                            queries: [
                                                {
                                                    field: 'tmpl_groupptr',
                                                    value: groupPointer,
                                                },
                                            ],
                                        }}
                                        defaultValueCondition={identifyDefaultTemplate}
                                        field="tmpl_code"
                                        label="tmpl_desc"
                                        mode="nura-base"
                                        model={TemplateMasters}
                                    />
                                </Form.Item>

                                <Form.Item>
                                    <Button disabled={disable} loading={btnloading} className="preview-button" type="primary" htmlType="submit">
                                        {t('Preview')}
                                    </Button>
                                </Form.Item>

                                {/* <Button disabled={disable} loading={publishLoading} className="preview-button" type="primary" onClick={publishReport}>
                                    Publish Report
                                </Button> */}
                            </div>
                        </Form>

                        {/* Preview Action Form */}
                        {/* <Select className='select-action' placeholder="- Select Action -" /> */}
                    </Card>
                </div>
            </div>

            <div className="right-sections">
                {/* Report Preview */}
                {byteArray || btnloading ? (
                    <div className="report-container">
                        {btnloading ? (
                            <>
                                <Skeleton />
                            </>
                        ) : (
                            <div>
                                {/* <PdfViewer url={byteArray} /> */}
                            </div>
                        )}
                    </div>
                ) : (
                    <Empty />
                )}
                {/* Report Preview Ends */}
            </div>
        </div>
    );
}
