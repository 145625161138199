/**
 * Component to Show bill details
 *
 *
 * @author Masuma
 */

import React, { useEffect, useState, Fragment, useRef, useContext } from 'react';

import { Typography, Table, Skeleton, Button, Modal, Space, Switch, Row, Col, Popconfirm, Form, message, Input } from 'antd';

import { useReactToPrint } from 'react-to-print';

import { Location, GlobalContext, useTranslation } from 'soxo-bootstrap-core';

import './bill-detail.scss';

import { BillDetails, Bills } from '../../../../models/';

import { Rupee } from 'soxo-bootstrap-core';
import { formatCurrency } from '../../../../utils/currency.utils';
import moment from 'moment-timezone';

import BillPreview from './bill-preview';
import PrintPreview from '../print-preview/print-preview';
import PriceFormatter from '../format-price/format-price';

// To get logo in bill from env
// var brandLogo = require('../../../.' + process.env.REACT_APP_BRAND_LOGO);

var brandLogo = process.env.REACT_APP_BRAND_LOGO;

const { Title, Text } = Typography;

export default function BillDetailView({ match, order, enableBillCancellation, ...props }) {
    const [record, setRecord] = useState({});

    const [items, setItems] = useState([]);

    const print = useRef();

    const [loader, setLoader] = useState(true);

    const [reportModalVisible, setReportModalVisible] = useState(false);

    const { t, i18n } = useTranslation();  // To Translate to another language

    // Modal visible for cancellation of bill
    const [cancelBillModalVisible, setCancelBillModalVisible] = useState(false);

    const { id } = match.params;

    var urlParams = Location.search();

    const { TextArea } = Input;

    useEffect(() => {
        loadData();
    }, []);

    const handlePrint = useReactToPrint({
        content: () => print.current,
        bodyClass: 'bill-details-component bill-details-template',
    });

    function collectRecord() {
        Location.navigate({ url: `/collections/create?opbd_hdrid=${id}` });
    }

    /**
     * Bill Details
     */
    function loadData() {
        setLoader(true);

        BillDetails.billDetails(id).then((result) => {
            setItems(result.result);
            setLoader(false);
        });
    }

    /**
     * View the Report
     */

    function viewReport() {
        setReportModalVisible(true);
    }

    /**
     * Open bill cancellation Modal
     */
    async function openCancelBillModal() {
        setCancelBillModalVisible(true);
    }

    /**
     * Cancel Bill
     * @param {*} values
     */
    async function cancelBill(values) {
        const result = await Bills.cancelBill(id, values);
        if (result.success) {
            message.success(result.message);
            setCancelBillModalVisible(false);
            Location.navigate({ url: `/order` });
        } else {
            message.error(result.message);
        }
    }

    return (
        <div className="bill-details-component page-content">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: '15px' }}>
                <div>{/* <Title level={3}>ORDER DETAILS</Title> */}</div>
                <div style={{ display: 'flex', paddingBottom: '10px', gap: '10px' }}>
                    {/* <Button type="primary" className="print-button" onClick={handlePrint}>
                        Print Invoice
                    </Button> */}

                    <Button className="preview-report-trigger" type="dashed" onClick={viewReport}>
                        {t('Preview Bill')}{' '}
                    </Button>
                    {enableBillCancellation ? (
                        <Button className="cancel-bill" type="dashed" onClick={openCancelBillModal}>
                            {t('Cancel Bill')}{' '}
                        </Button> ): null}

                </div>
            </div>

            {loader ? (
                <>
                    <Skeleton active />
                </>
            ) : (
                <Fragment>
                    <div className="card card-shadow bill-preview" style={{ padding: '30px' }}>
                        <div>
                            {/* Bill Content */}
                            <BillContent record={record} items={items} brandLogo={brandLogo}  />

                            {/* Bill Content Ends */}
                        </div>

                        <div>
                            {/* <Button type="dashed" className="print-button" onClick={handlePrint}>
                                Print Invoice
                            </Button> */}
                            {/* <PrintPreview templatecode={props.templateCode} refereneceId={id} groupPointer={props.groupPointer} /> */}
                            {/* <Button className="preview-report-trigger" type="dashed" onClick={viewReport}>
                                Preview Bill{' '}
                            </Button> */}
                        </div>

                        {/* Print Block */}
                        <div className="print-block-bill-detail">
                            <WrapperClass ref={print}>
                                <div className="card card-shadow bill-preview">
                                    {/* Bill Content */}
                                    <BillContent record={record} items={items} brandLogo={brandLogo} />
                                    {/* Bill Content Ends */}
                                </div>
                            </WrapperClass>
                        </div>

                        {/* Print Block ends */}
                        {/* </div> */}
                    </div>

                    {/* Modal for Viewing Report */}
                    <Modal
                        width={'80%'}
                        heigh={'570px'}
                        destroyOnClose={true}
                        footer={null}
                        title={t("Bill Preview")}
                        visible={reportModalVisible}
                        cancelButtonProps={{ style: { display: 'none' } }}
                        okText="Close"
                        onOk={() => {
                            setReportModalVisible(false);
                        }}
                        onCancel={() => {
                            setReportModalVisible(false);
                        }}
                    >
                        <PrintPreview templatecode={props.templateCode} refereneceId={id} groupPointer={props.groupPointer} props={props} />
                        {/* <BillPreview billId={id} groupPointer={props.groupPointer} templatecode={props.templatecode} /> */}
                        {/* <ReportPreview billId={billId} downloadFileName={downloadFileName} groupPointer={groupPointer} /> */}
                    </Modal>

                    {/**----Modal for Bill Cancellation Starts----- */}
                    <Modal
                        destroyOnClose={true}
                        footer={null}
                        title={t("Cancel Bill")}
                        visible={cancelBillModalVisible}
                        cancelButtonProps={{ style: { display: 'none' } }}
                        onOk={() => {
                            setCancelBillModalVisible(false);
                        }}
                        onCancel={() => {
                            setCancelBillModalVisible(false);
                        }}
                    >
                        <Form onFinish={cancelBill}>
                            <Form.Item name='remarks' label={t('Remarks')}>
                                <TextArea />
                            </Form.Item>
                            <Button htmlType='submit'>{t('Proceed to Cancel Bill')}</Button>
                        </Form>
                    </Modal>
                    {/**----Modal for Bill Cancellation Ends----- */}
                </Fragment>
            )}
        </div>
    );
}

function BillContent({ items, brandLogo }) {
    // for currency
    const { user = {}, isMobile, dispatch } = useContext(GlobalContext);

    const { t, i18n } = useTranslation(); // To Translate to another language

    /**
     * Expanding bill columns
     * @param {*} record
     * @returns
     */

    const expandedRowRender = record => {

        /**
         * Columns used for subitems
         */
        const subItemColumns = [
            {
                title: null,

                render: (ele) => {
                    return <div>{ele.item_description}</div>;
                },
            },
        ];
        return <Table showHeader={false} columns={subItemColumns} pagination={false} dataSource={record.subItems}></Table>;
    };

    // for getting currecncy

    let otherDetails = user.firm.f_otherdetails1;

    let currency = JSON.parse(otherDetails);
    let currencySymbol = currency.currency_symbol;

    return (
        <>
            <div>
                {/* <div className="card"> */}
                <div className="bill-header">
                    <div>
                        {/* <img className="brand-logo" src={logo} alt="Logo" /> */}

                        <h2>{t('Order No')}: {items.order_number}</h2>
                    </div>
                    <div className="logo">{/* <img style={{ width: '100px' }} src={brandLogo} alt="onedesk" /> */}</div>
                </div>
                <hr />
                <div className="bill-details card">
                    <div>
                        <p>{t('ORDER NO.')}</p>

                        <Title level={5}>{items.order_number}</Title>
                    </div>
                    <div>
                        <p>{t('OP NO.')}</p>

                        <Title level={5}>{items.op_no}</Title>
                    </div>
                    <div>
                        <p>{t('NAME')}</p>

                        <Title level={5}>{items.name}</Title>
                    </div>
                    <div>
                        <p>{t('ADDRESS')}</p>

                        <Title level={5}>
                            {items.address1}
                            {items.address1 && items.address2 ? ',' : null}
                            {items.address1}
                        </Title>
                    </div>
                    <div>
                        <p>{t('DATE & TIME')}</p>

                        <Title level={5}>{moment.tz(items.time, '').format('DD-MM-YYYY hh:mm:ss A')}</Title>
                    </div>
                    <div>
                        <p>{t('AGE/ GENDER')}</p>

                        <Title level={5}>
                            {items.age} / {items.gender}
                        </Title>
                    </div>
                </div>

                <Table
                    className="bill-summary"
                    scroll={{ x: true }}
                    dataSource={items.items}
                    columns={billColumns}
                    pagination={false}
                    expandable={{ expandedRowRender }}
                    // expandable={{
                    //     expandedRowRender: record => {
                    //         <p style={{ margin: 0 }}>hello</p>;

                    //         // record.subItems.map(ele => {
                    //         //     console.log('eeee', ele.item_description);
                    //         //    return <p style={{ margin: 0 }}>{ele.item_description}</p>;
                    //         // });
                    //     },

                    //     rowExpandable: record => record.subItems.length > 0,
                    // }}
                    summary={(pageData) => {
                        let Total = 0;
                        pageData.forEach((entry) => {
                            Total += entry.rate * entry.quantity;
                        });

                        return (
                            <>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                        <strong>{t('Total')}</strong>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                        <strong>
                                            {/* <Rupee value={items.opb_amt}></Rupee> */}
                                            {/* <Rupee value={items.amount - (items.discount - items.bill_discount)}></Rupee> */}
                                            {/* <Rupee value={items.amount}></Rupee> */}
                                            {/* {formatCurrency(items.amount, currencySymbol)} */}
                                        
                                        <PriceFormatter price={items.amount}
                                        locale={currency.priceFormat}
                                        currency={currency.currency_code}/>
                                        </strong>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>

                                <Table.Summary.Row>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                        <strong>{t('Discount')}</strong>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                        <strong>
                                            {/* <Rupee value={items.opb_disamt}></Rupee> */}
                                            {/* <Rupee value={items.bill_discount}></Rupee> */}
                                            {/* <Rupee value={items.discount}></Rupee> */}
                                            {/* {formatCurrency(items.discount, currencySymbol)} */}
                                            <PriceFormatter price={items.discount}
                                             locale={currency.priceFormat}
                                             currency={currency.currency_code}
                                             enablePriceFormatting={currency.enablePriceFormatting}/>

                                            
                                        </strong>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>

                                <Table.Summary.Row>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                        <strong>{t('Net Amount')}</strong>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                        <Title level={4}>
                                            {/* <Rupee value={items.net_amount}></Rupee> */}
                                            {/* {formatCurrency(items.net_amount, currencySymbol)} */}
                                            <PriceFormatter 
                                            price={items.net_amount}  
                                            locale={currency.priceFormat}
                                            currency={currency.currency_code}
                                            enablePriceFormatting={currency.enablePriceFormatting}
                                            />
                                        </Title>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            </>
                        );
                    }}
                />
                <div className="bill-footer">
                    <div>
                        <p>{t('Created On')}: {moment.tz(items.time, '').format('DD-MM-YYYY hh:mm:ss A')}</p>
                        <p>{t('Printed On')}: {moment().format('DD-MM-YYYY hh:mm:ss A')}</p>
                    </div>
                    <p>{t('Signature')}</p>
                </div>
            </div>
        </>
    );
}

class WrapperClass extends React.Component {
    render() {
        return <div style={{ width: '100%' }}>{this.props.children}</div>;
    }
}

const billColumns = [
    {
        title: '#',
        dataIndex: 'index',
        render: (value, item, index) => index + 1,
    },
    {
        title: 'Item Description',
        render: (ele) => {
            return <div>{ele.item_description}</div>;
        },
        key: 'Description',
    },
    Table.EXPAND_COLUMN,
    {
        title: 'Rate',
        render: (ele) => {
            return <div>{ele.rate}</div>;
            // <PriceFormatter price={ele.rate} />
        },
        key: 'rate',
    },
    {
        title: 'Quantity',
        render: (ele) => {
            return <div>{ele.quantity}</div>;
        },
        key: 'quantity',
    },

    {
        title: 'Discount',
        render: (ele) => {
            return <div>{ele.discount_amount}</div>;
        },
        key: 'discount',
    },
    {
        title: 'Total',
        render: (ele) => {
            return <div>{ele.net_amount}</div>;
        },
        key: 'total',
    },
];
