/**
 * Component to Show Payment Link
 *
 *
 * @author jaseela
 */

import React, { useState, useEffect, useRef, useContext } from 'react';
import { Button, Form, Input, Select, Space, Typography, Checkbox, message } from 'antd';

import { GlobalContext, useTranslation } from 'soxo-bootstrap-core';

import { Payments } from '../../../../models';
const { Title, Text } = Typography;

export default function PaymentLink({ callback, mail, selected, totalValue, guest, discount, guestItem ,props }) {
    const [form] = Form.useForm();

    const [disable, setDisable] = useState(true);

    const [isLoading, setIsLoading] = useState(false);

    const [total, setTotal] = useState(totalValue ? totalValue : null);

    const { user = {} } = useContext(GlobalContext);

    const { t, i18n } = useTranslation(); // To Translate to another language

    /**
     * The function `sendPaymentLink` sends a payment link with specified details and handles the
     * response accordingly.
     */
    async function sendPaymentLink() {
        setIsLoading(true);
        try {
            const amountToPay = guest.da_billvalue * 100;

            let formBody = {
                amount: amountToPay,
                currency: 'INR',
                // accept_partial: true,
                // first_min_partial_amount: 100,
                description: guestItem.itm_desc,
                customer: {
                    name: guest.da_fname,
                    email: guest.da_email,
                    contact: guest.da_mobile,
                },
                notify: {
                    sms: true,
                    email: true,
                },
                // reminder_enable: true,
                notes: {
                    index: props.settings.headers.db_ptr.toString(),
                    source: 'DESK',
                    appoinmentId: guest.da_id,
                    // branch: user.firm.f_desc,
                },
            };

            let response = await Payments.getPaymentLink(formBody);
            if (response.success) {
                setIsLoading(false);
                message.success('Payment Link sent successfully');
                // setDisable(true);
                callback();
            } else {
                setIsLoading(false);
                message.error(response.message);
                setDisable(true);
                // callback();
            }
        } catch (error) {
            setIsLoading(false);
            message.error('Failed to send payment link');
        }
    }

    return (
        <div className="detail-element">
            <div className="card-shadow card">
                <Title type="secondary" level={5}>
                    {t('PAYMENT INFO')}
                </Title>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div className="section-label">
                        <Text type="primary">{guestItem.itm_desc} </Text>
                    </div>
                    <div className="section-value">
                        <strong>{selected.ir_rate}</strong>
                    </div>
                </div>
                {discount ?
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div className="section-label">
                            <Text type="secondary"> {t('Discount')}</Text>
                        </div>
                        <div className="section-value">
                            <strong>{discount}</strong>
                        </div>
                    </div>
                    : null}

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div className="section-label">
                        <Text type="secondary">{t('Amount to Pay')}</Text>
                    </div>
                    <div className="section-value">
                        <strong>{guest.da_billvalue}</strong>
                    </div>
                </div>
            </div>

            <Form name="emailForm" onFinish={sendPaymentLink} style={{ width: '450px' }}>
                <Text type="secondary">{t('SEND LINK TO')}</Text>
                <Form.Item name="email" valuePropName="checked" initialValue={true}>
                    <Checkbox>{t('Email')}</Checkbox>
                </Form.Item>
                <Form.Item
                    // label="Email"
                    // name="email"
                    rules={[
                        { required: true, message: t('Please enter your email!') },
                        { type: 'email', message: t('Please enter a valid email address!') },
                    ]}
                >
                    <Input placeholder="bradleylawlor@gmail.com" type="email" readOnly={true} value={mail} />
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Button loading={isLoading} type="primary" htmlType="submit">
                            {t('Send Link')}
                        </Button>
                        <Button htmlType="button" onClick={callback}>
                            {t('Cancel')}
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </div>
    );
}
