/**
 * Component to search by name and opno used inside opreg search component
 *
 * @author Jinisha
 */

import React, { useState, useCallback } from 'react';

import { Input, Modal, Button, Table, Select, Form, Row, Col, Typography, Skeleton, Checkbox, message } from 'antd';

import { Appointments, Bills, Opreg, Opvisits, Registrations } from '../../models';

import { DateUtils,useTranslation } from 'soxo-bootstrap-core';
import _debounce from 'lodash/debounce';

const { Search } = Input;

const { Title } = Typography;

const { Option } = Select;

export default function AdvanceSearch({ model, enableVisit, callback, title, setVisible, mode, disableCheckBox, enableModal }) {
    let config = model.searchConfig;

    // Variable that holds the text query
    let [query, setQuery] = useState('');

    let [searchby, setSearchBy] = useState(model.searchConfig.searchBy[0].field);

    let [criteria, setCriteria] = useState(model.searchConfig.criteria[0].field);

    const [loading, setLoading] = useState(false);

    let [opvisitView, setOpvisitView] = useState(false);

    const [visit, setVisit] = useState([]);

    let [result, setResult] = useState([]);

    const [allbranch, setAllbranch] = useState(true);

    // Selected matching result from the result
    const [selected, setSelected] = useState({});

    const [doctorAppointments, setDoctorAppointments] = useState([]);

    const [doctorAppointmentView, setDoctorAppointmentView] = useState(false);

    const [bill, setBill] = useState([]);

    const { t, i18n } = useTranslation(); // To Translate to another language

    const [billtView, setBillView] = useState(false);

    /**
     * Columns used for Visit Information
     *
     */

    const columns = [
        // {
        //     title: '#',
        //     dataIndex: 'index',
        // },
        {
            title: t('OP No'),
            key: 'name',
            render: ele => {
                return ele.opv_opno;
            },
        },
        {
            title: t('Name'),
            key: 'name',
            render: ele => {
                // var Opreg = result.filter((item) => {
                //     return ele.opv_opno == item.op_no;
                // });

                return selected.op_fname;
            },
        },
        {
            title: t('Visit Date'),
            key: 'date',
            render: ele => {
                return DateUtils.formatDate(ele.opv_dt);
            },
        },
        {
            title: t('Contact'),
            key: 'contact',
            render: ele => {
                return selected.op_mobile;
            },
        },
        {
            // field: '',
            title: t('Actions'),
            render: ele => {
                return (
                    <Button
                        onClick={event => {
                            callback(ele.visit_id, ele.op_no);
                        }}
                        type="link"
                    >
                        {' '}
                        {t('View')}
                    </Button>
                );
            },
        },
    ];

    /**
     * Columns used for doctor appointment
     *
     */

    const doctorAppointmentColumns = [
        // {
        //     title: '#',
        //     dataIndex: 'index',
        // },
        {
            title: t('OP No'),
            key: 'name',
            render: ele => {
                return ele.op_no;
            },
        },
        {
            title: t('Name'),
            key: 'name',
            render: ele => {
                return ele.first_name + ' ' + ele.last_name;
            },
        },
        {
            title: t('Appointment Date'),
            key: 'date',
            render: ele => {
                return DateUtils.formatDate(ele.date);
            },
        },
        {
            title: t('Contact'),
            key: 'contact',
            render: ele => {
                return selected.mobile;
            },
        },
        {
            // field: '',
            title: t('Actions'),
            render: ele => {
                return (
                    <Button
                        onClick={event => {
                            setVisible(false);
                            callback({ ...selected, doctorAppointment: ele });
                        }}
                        type="link"
                    >
                        {' '}
                        {t('Select')}
                    </Button>
                );
            },
        },
    ];

    /**
     * Columns used for Bill
     *
     */

    const BillColums = [
        // {
        //     title: '#',
        //     dataIndex: 'index',
        // },
        {
            title: t('OP No'),
            key: 'name',
            render: ele => {
                return ele.opb_opno;
            },
        },
        {
            title: t('Name'),
            key: 'name',
            render: ele => {
                return ele.opb_name;
            },
        },
        {
            title: t('Bill Date'),
            key: 'date',
            render: ele => {
                return DateUtils.formatDate(ele.opb_dt);
            },
        },
        {
            title: t('Contact'),
            key: 'contact',
            render: ele => {
                return ele.opb_mobile;
            },
        },
        {
            // field: '',
            title: t('Actions'),
            render: ele => {
                return (
                    <Button
                        onClick={event => {
                            setVisible(false);
                            callback({ ...selected, bill: ele });
                        }}
                        type="link"
                    >
                        {' '}
                        {t('Select')}
                    </Button>
                );
            },
        },
    ];

    /**
     * Handle search using debounce
     *
     * @param {*} event
     */
    function handleSearch(event) {
        setQuery(event.target.value);
    }

    // /**
    //  * Function debounces callback passed
    //  */
    //    const debounceFunction = useCallback(_debounce(loadSearch, 1000), []);

    /**
     * Is used to Load search heads based on the query .
     *
     * @param {*} event
     * @returns
     */
    async function loadSearch(searchQuery) {
        /**
         * Form Body
         *
         */
        let formBody = {
            search_by: searchby,
            criteria: criteria,
            search_text: searchQuery,
        };

        setDoctorAppointmentView(false);

        /**Check condition for  Search ALL Branches .
         * If All branches selected the index is passing ALL .
         *  Otherwise index is corresponding branch db_ptr
         * **/
        if (allbranch && !disableCheckBox) {
            formBody = {
                ...formBody,
                index: 'ALL',
            };
        } else {
            formBody = {
                ...formBody,
                index: localStorage.db_ptr ? localStorage.db_ptr : process.env.REACT_APP_DB_PTR,
            };
        }

        if (mode) {
            formBody = {
                ...formBody,
                mode: mode,
            };
        }

        if (model.searchConfig.url) {
            setLoading(true);

            let queries = [
                {
                    field: searchby,
                    value: searchQuery,
                },
            ];

            var result;
            if (enableVisit) {
                // opreg serach For stoptb
                result = await Opreg.get({ url: 'opreg/search', queries });

                result = await result.map(item => {
                    return { ...item, action: 'Select' };
                });
                setResult(result);
            } else {
                // consolidatedSearch For nura only
                let value = await Appointments.consolidatedSearch(formBody);

                setResult(value.result);
            }

            setLoading(false);
        }
    }

    /**
     *
     * To Load bill information
     * @param {*} record
     */

    async function getBillinformation(record) {
        let billData;

        /** If record contains op_number then call the api to list bill against op_number */
        if (record.op_number) {
            billData = await Bills.loadbilllnumbers(record.op_number);
        }
        /**if   list of bill against op_no */
        if (billData.result.length) {
            setBill(billData.result);
            setBillView(true);
        } else {
            // if (record) {

            // The selected should be able to bill
            callback(record);
        }
    }
    /**
     * Get doctor appointment of the selcted opreg
     * @param {*} record
     */
    async function getDoctorAppointment(record) {
        setSelected(record);

        let doctorAppointment;

        /** If record contains op_number then call the api to list appointment against op_number */
        if (record.op_number) {
            doctorAppointment = await Appointments.getDoctorAppointment(record.op_number);
            /**else call the api to list appointment against prereg_id */
        } else {
            doctorAppointment = await Appointments.getDoctorAppointmentAgainstPrereg(record.prereg_id);
        }

        // If there is an appointment , we should let the user select that appointment
        // or else , we should let the user
        if (doctorAppointment.length) {
            setDoctorAppointments(doctorAppointment);
        } else {
            // if (record) {

            // The selected should be able to bill
            callback(record);

            // } else {

            //     message.info('Could not find an appointment');

            // }
        }

        setDoctorAppointmentView(true);
    }
    /**
     * Function triggered when an opreg is selected
     *
     * @param {*} record
     */
    const onSelectRecord = record => {
        // enableModal is given to indicate which modal is to be opened,
        // doctorAppointment indicates doctor appointments should be loaded and shown
        // If it is implemented for opvisit too, switch can be used here instead of if
        if (enableModal == 'doctorAppointment') {
            getDoctorAppointment(record);
        } else if (enableModal == 'bill') {
            // if the enableModal is bill sholud load opbill data against the opno
            getBillinformation(record);
        } else {
            if (enableVisit) {
                // If Enablevisit get visit details with op_no
                getPatientHistory(record.op_no);

                setOpvisitView(true);

                setSelected(record);
            } else {
                setSelected(record);

                callback(record);
                setVisible(false);
            }
        }
    };

    /**
     * Get the opvisit based on corresponding opno
     *
     * @param {*} op_number
     */
    async function getPatientHistory(val) {
        await Opvisits.getPatientHistory(val).then(result => {
            if (result.result) {
                setVisit([...result.result]);
            }
        });
    }

    /**
     * Update all the branch
     *
     * @param {*} value
     */
    function updateAllBranch(event) {
        setAllbranch(event.target.checked);
    }

    return (
        <div>
            <div>
                <Title level={4}>{title}</Title>
            </div>
            <div className="card">
                <Form>
                    <Row gutter={12} className="advance-search-form">
                        {/** Search Section Start */}

                        {/** Search  By Section Start  */}
                        <Col xs={24} md={4} xl={4}>
                            <h4>{t('SEARCH BY')}</h4>
                            <Form.Item>
                                <Select
                                    defaultValue="First Name"
                                    //   style={{ width: "99%" }}
                                    onChange={i => {
                                        setSearchBy(i);
                                    }}
                                >
                                    {model.searchConfig.searchBy.map((option, key) => (
                                        <Option value={option.field}>{option.caption}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        {/** Search B Section end  */}

                        <Col xs={24} md={6} xl={6}>
                            <h4>{t('SEARCH')}</h4>

                            {/* <SearchDebounce
                                value={''}
                                onChange={value => {
                                    if (value.length) {
                                        loadSearch(value);
                                    }
                                }}
                            /> */}
                            <Form.Item>
                                <Search
                                    value={query}
                                    placeholder={t("Enter Search Value")}
                                    // allowClear
                                    onChange={handleSearch}
                                />
                            </Form.Item>
                        </Col>
                        {/** Search section end  */}

                        {/** Criteria Section  starts */}
                        <Col xs={3} md={4} xl={4}>
                            <h4>{t('CRITERIA')}</h4>

                            <Form.Item>
                                <Select
                                    defaultValue="Start With"
                                    //   style={{ width: "99%" }}
                                    onChange={i => {
                                        setCriteria(i);
                                    }}
                                >
                                    {model.searchConfig.criteria.map((option, key) => (
                                        <Option value={option.field}>{option.caption}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        {/** Criteria section End */}

                        {/** Criteria Section  starts */}
                        <Col xs={3} md={6} xl={6}>
                            <Button
                                type="primary"
                                onClick={event => {
                                    loadSearch(query);
                                }}
                            >
                                {' '}
                                {t('Search')}
                            </Button>
                        </Col>
                    </Row>
                    {!disableCheckBox ? (
                        <div className="checkbox-input" style={{ width: '350px' }}>
                            <Checkbox defaultChecked={allbranch} value={allbranch} onChange={updateAllBranch} />

                            <span className="caption">{t('Search All Branches')}</span>
                        </div>
                    ) : null}
                </Form>
            </div>

            {/* Block for showing Opreg search */}
            {!opvisitView && !doctorAppointmentView & !billtView ? (
                <>
                    {/* Table Hint Section */}
                    <p className="size-hint">{result ? result.length : 0} Matches Found For Your Search .</p>
                    {/* Table Hint Section Ends */}

                    {/* Listing Matching Opreg */}

                    {loading ? (
                        <Skeleton />
                    ) : (
                        <Table
                            columns={config.columns(record => {
                                onSelectRecord(record);
                            })}
                            dataSource={result}
                        ></Table>
                    )}
                    {/* Listing Matching Opreg Ends */}
                </>
            ) : null}
            {/* Block for showing Opreg search Ends */}

            {/* Block for showing Opvisit list */}
            {opvisitView ? (
                <>
                    {/* Table Hint Section */}
                    <h2 className="size-hint">{visit ? visit.length : 0} Visits Found under this OP number .</h2>
                    {/* Table Hint Section Ends */}

                    <Table columns={columns} dataSource={visit}></Table>
                </>
            ) : doctorAppointmentView ? (
                <>
                    {/* Table Hint Section */}

                    <p className="size-hint">{doctorAppointments ? doctorAppointments.length : 0} Appointments Found under this OP number .</p>
                    {/* Table Hint Section Ends */}
                    <Table columns={doctorAppointmentColumns} dataSource={doctorAppointments}></Table>
                </>
            ) : billtView ? (
                <>
                    {/* Table Hint Section */}

                    <p className="size-hint">{bill ? bill.length : 0} Bill Found under this OP number .</p>
                    {/* Table Hint Section Ends */}
                    <Table columns={BillColums} dataSource={bill}></Table>
                </>
            ) : null}
        </div>
    );
}
