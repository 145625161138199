/**
 * Collection Details Model
 *
 *
 * Collection is any receipt that a receptionist makes from the guest
 *
 */
import React, { useState, useEffect, useContext } from 'react';

import Model from './models';

import { ApiUtils } from 'soxo-bootstrap-core';

const moment = require('moment-timezone');

var paymentTypes = {
    Cash: 'CS',
    'Net Banking': 'OT',
    'Credit Card': 'CC',
    'Debit Card': 'CC',
    Cheque: 'CQ',
};

class CollectionDetail extends Model {
    constructor() {
        super();

        this.fields = [
            {
                field: 'cold_holdername',
                caption: 'Name',
            },
            {
                field: 'cold_amt',
                caption: 'Amount',
            },
            {
                field: 'cold_tm',
                caption: 'Date',
            },
            {
                field: 'Collected By',
                caption: 'cold_user',
            },
        ];

        // this.columns = ;
    }

    get id() {
        return 'cold_id';
    }

    get getEndpoint() {
        return 'colldetails';
    }

    get path() {
        return `collections`;
    }

    get getName() {
        return this.modelName + `s`;
    }

    get modelName() {
        return `collection`;
    }

    getReceiptItems = (id) => {
        return ApiUtils.get({ url: `colldetails/receipts/${id}` });
    };

    /**
     * Get details of the receipt number, receipt number, collection id, amount, opno and bill id is returned
     * @param {*} id 
     * @returns 
     */
    getReceiptDetails = id => {
        return ApiUtils.get({
            url: `colldetails/receipts-details/${id}`
        })
    }

    /**
     * Cancel the collection with collection id
     * @param {*} id 
     * @param {*} remarks 
     * @returns 
     */
    cancelReceipt = (id, remarks) => {
        console.log(remarks)
        const formBody = {
            remarks: remarks
        }
        return ApiUtils.put({
            url: `colldetails/cancel-receipt/${id}`,
            formBody
        })
    }


    get columns() {
        return [
            {
                field: 'opbill.opb_name',
                render: (record) => {
                    return record.opbill ? record.opbill.opb_name : null;
                },
                caption: 'Name',
            },
            {
                field: 'opbill.opb_gender',
                render: (record) => {
                    return record.opbill ? record.opbill.opb_gender : null;
                },
                caption: 'Gender',
            },
            {
                field: 'opbill.opb_age',
                render: (record) => {
                    return record.opbill ? record.opbill.opb_age : null;
                },
                caption: 'Age',
            },
            {
                field: 'opbill.opb_mobile',
                render: (record) => {
                    return record.opbill ? record.opbill.opb_mobile : null;
                },
                caption: 'Mobile',
            },
            {
                field: 'opbill.opb_opno',
                render: (record) => {
                    return record.opbill ? record.opbill.opb_opno : null;
                },
                caption: 'OP No.',
            },
            {
                field: 'cold_amt',
                render: (record) => {
                    return record.cold_amt;
                },
                caption: 'Amount',
            },
            {
                field: 'cold_dt',
                render: (record) => {
                    return record.colldetails ? record.colldetails.cold_dt : null;
                },
                caption: 'Date',
            },
            {
                field: 'cold_user',
                render: (record) => {
                    return record.cold_user;
                },
                caption: 'Collected By',
            },
            {
                field: 'cold_amt',
                render: (record) => {
                    return record.colldetails ? record.colldetails.cold_remarks : null;
                },
                caption: 'Remarks',
            },
        ];
    }
}

export default CollectionDetail;
