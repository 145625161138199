
import App from './App';

import ReactDOM from 'react-dom';

window.process = {
    env: {

        NODE_ENV:'production'

    }
}

ReactDOM.render(<App />, document.getElementById('root'));

export default App;
