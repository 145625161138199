/**
 * Core Scripts
 *
 *
 *
 *
 */
import React from 'react';

import Model from './models';

import { ApiUtils, BaseAPI } from 'soxo-bootstrap-core';

class CoreScript extends BaseAPI {
    get id() {
        return 'id';
    }

    get getEndpoint() {
        return 'core-scripts';
    }

    get path() {
        return `core-scripts`;
    }

    get getName() {
        return this.modelName;
    }

    get modelName() {
        return `core-scripts`;
    }
}
export default CoreScript;
