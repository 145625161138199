/**
 *
 * @returns
 *
 * Loading Questionaire
 */
import React, { useEffect, useState } from 'react';

import { TableWrapper, useTranslation } from 'soxo-bootstrap-core'

import { Modal, Button, Typography } from 'antd';

import { Opreg } from '../../../models';

const { Title } = Typography;

export function Questionaire({ mode, reference_id}) {

    const { t, i18n } = useTranslation(); // To Translate to another language

    let [visible, setVisible] = useState(false);

    var [questions, setQuestions] = useState([]);

    /**
     * Get call
     */

    useEffect(() => {
        loadQuestionaire();
    }, []);

    /**
     * load questionaire information
     *
     * @param {*} params
     */
    function loadQuestionaire() {
        //
        let formBody = {
            bill_id: reference_id,
            mode: mode,
        };

        Opreg.loadQuestionaire(formBody).then(result => {

            setQuestions(result.data);
        });
    }
    /**
     *
     * @param {*} event
     * @param {*} value
     */


    /**
     *
     * @param {*} event
     * @param {*} value
     */

    var columns = [
        {
            title: t('Sl No'),
            dataIndex: 'index',
            render: (value, item, index) => {
                return index + 1;
            },
        },
        {
            title: t('Questions'),
            key: 'questions',

            render: ele => {
                return ele.description;
            },
        },
        {
            title: t('Value'),
            key: 'value',

            render: ele => {
                return ele.values
            },
        },

        {
            title: t('Remarks'),
            key: 'Remark',

            render: ele => {
                return ele.remarks;
            },
        },
    ];

    return (
        <div className="questionaire">

            <div className='paga-actions'>

                <Button
                    type='primary'
                    size={'small'}
                    onClick={() => {
                        setVisible(true);
                    }}
                >
                    {t('View Questionnaire')}
                </Button>
            </div>

            <Modal
                destroyOnClose={true}
                visible={visible}
                footer={null}
                className="doctor-notes-questionnaire"
                onCancel={() => {
                    setVisible(false);
                }}
                width={'80%'}
                maskClosable={false}
            >
                <div>
                    <div className="left">
                        <Title level={4}>{t('Questionaire')}</Title>
                    </div>
                    <TableWrapper
                        scroll={{ x: true }}
                        records={questions}
                        columns={columns}
                        pagination={false}
                    />

                </div>
            </Modal>
        </div>
    );
}
