/***
 *
 *
 * @description
 * @author jinshakappil
 */
import { DeleteOutlined } from '@ant-design/icons';

import React, { useEffect, useState } from 'react';

import { Space, Table } from 'antd';
import { Opvisits } from '../../../../models';

import { ItemSelection } from './../../../items/components/item-selection/item-selection';

import moment from 'moment-timezone';

import { useTranslation} from 'soxo-bootstrap-core';

import { Typography, Card, Button, Modal, Input, message, Form, Popconfirm, Skeleton, Empty, DatePicker, Tag, Pagination } from 'antd';

import './investigation.scss';

const { Search } = Input;

const { Paragraph } = Typography;

const { Title } = Typography;

const dateFormat = 'DD/MM/YYYY';

export function InvestigationCard({ loading, cartItems, setcartItems, selectedItems, setSelectedItems, getConsultDetails, itemMode, histories }) {
    const [mounted, setMount] = useState(0);
    const [page, setPage] = useState(1);

    const [limit, setLimit] = useState(10);

    const { t, i18n } = useTranslation(); // To Translate to another language

    // const [visible, setvisible] = useState(false);

    /**
     * Delete record of items
     */
    function deleteItem(index) {
        selectedItems.splice(index, 1);

        setSelectedItems([...selectedItems]);

        message.success('Record deleted');

        // setMount((prev) => {
        //     return prev + 1;
        // });
    }

    // for cancle
    function cancel() {
        message.success('Cancelled');
    }

    const investigationColums = [
        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => {
                return (page - 1) * limit + index + 1;
            },
        },
        {
            title: t('Investigation Name'),
            key: 'investigationName',
            render: (record) => {
                return record.name;
            },
        },
        {
            title: t('Date'),
            key: 'date',
            render: (record) => {
                return moment(record.date).format('DD/MM/YYYY');
            },
        },

        {
            title: t('Remarks'),
            key: 'remarks',
            render: (record) => {
                return record.remarks;
            },
        },
    
    ];
    {/** If the histories returns false then adding a new column called action*/}
    if(!histories){
        investigationColums.push({
            title: t('Action'),
            key: 'action',
            fixed: 'right',

            render: (record, item, index) => {
                return (
                    <Space size="middle">
                        <Popconfirm title={t("Are you sure")} onConfirm={() => deleteItem(index)} disabled={record.pid_isbilled === 'Y'}>
                            <Button size={'small'} type="dashed" disabled={record.pid_isbilled === 'Y'}>
                                <DeleteOutlined />
                            </Button>
                        </Popconfirm>
                    </Space>
                );
            },
        },)
       }

    return (
        <>
            <div className="investigation-card">
                <div className="card">
                    <div className="card-header">
                        <div className="card-title">
                            <div className="title">
                                <img
                                    className="card-icon-left"
                                    src={require(`../../../../assets/icons/external-doctor-hospital-and-medical-justicon-flat-justicon.png`)}
                                />

                                <h3>{t('Investigation')}</h3>
                            </div>

                            <div className="button-section">
                                <ItemSelection
                                    histories={histories}
                                    itemMode={itemMode}
                                    selectedLabel={'Investigations'}
                                    callback={(selectedItems) => {
                                        setSelectedItems(selectedItems);
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    {/* </div> */}

                    <div>
                        {loading ? (
                            <Skeleton />
                        ) : (
                            <>
                                {selectedItems.length ? (
                                    <>
                                        <Card className="inner-card">
                                            <Table
                                                scroll={{ x: true }}
                                                rowKey={(record) => record.da_id}
                                                dataSource={selectedItems}
                                                columns={investigationColums}
                                                pagination={{
                                                    current: page,
                                                    onChange(current) {
                                                        setPage(current);
                                                    },
                                                }}
                                            />
                                        </Card>
                                    </>
                                ) : (
                                    <>
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
