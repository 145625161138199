/**
 * @description
 * This component is used to list and edit serviceresultmas table data
 * @author Jinishakappil
 *
 *
 */

import React, { useEffect, useState } from 'react';

import { Card, Switch, Button, Typography, Table, Skeleton, Form, Modal, message, Tag } from 'antd';

import { Link } from 'react-router-dom';

import { InputComponent, useTranslation} from 'soxo-bootstrap-core';

import { ServiceResultMaster } from '../../models';

const { Title } = Typography;

export default function ServiceResultmasterList({ category, mode }) {
    const [isModalVisible, setIsModalVisible] = useState(false);

    var [list, setList] = useState([]);

    const [loading, setLoading] = useState(true);

    var [serviceresultmas, ServiceResultMas] = useState([]);

    const [page, setPage] = useState(1);

    const [limit, setLimit] = useState(10);

    const { t, i18n } = useTranslation(); // To Translate to another language

    /**
     * Get call
     */

    useEffect(() => {
        getServiceResultMaster(category, mode);
    }, []);
    /**
     * Listing  serviceResultmaster data
     */

    async function getServiceResultMaster(category, mode) {
        setLoading(true);

        ServiceResultMaster.getServiceResultMaster(category, mode).then((result) => {
            setList(result.data);

            setLoading(false);
        });
    }

    /**
     * To open modal for edit
     * @param {*} result
     */
    function showModal(result) {
        //  is saved to a state to pass into modal

        ServiceResultMas(result);
        // To show Modal
        setIsModalVisible(true);
    }


    // refreshing after editing
    function refresh() {
        getServiceResultMaster(category, mode);
    }
    /**
     *  Defining columns to be shown in table
     */

    var columns = [
        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => {
                return (page - 1) * limit + index + 1;
            },
        },

        {
            title: t('Code'),
            key: 'code',
            dataIndex: 'code',
        },
        {
            title: t('Description'),
            key: 'description',
            dataIndex: 'description',
        },
        {
            title: t('Serial No'),
            key: 'serialNo',
            dataIndex: 'serialNo',
        },
        {
            title: t('Active Status'),
            key: 'active',
            render: (ele) => {
                if (ele.active === "Y") {
                    return (
                        <>
                            <Tag color="green">{t('Active')}</Tag>
                        </>
                    );
                } else {
                    return (
                        <>
                            <Tag color="red">{t('Inactive')}</Tag>
                        </>
                    );
                }
            }
        },
        {
            title: t('Action'),
            fixed: 'right',

            render: (ele) => {
                return (
                    <Link
                        onClick={() => {
                            //  value passed to modal to prepopulate serviceresultmaster  data on edit
                            showModal(ele);
                        }}
                    >
                        {t('Edit')}
                    </Link>
                );
            },
        },
    ];
    return (
        <section className="service-result-master-list card portlet-container">
            {/* Page Header */}

            <div className="page-header">
                <div className="left">
                    <Title style={{ marginBottom: '8px' }} level={3}>
                        {t('Radiology Result Options')}
                    </Title>
                </div>
            </div>
            {/* Page Header Ends */}

            {/* Content Below */}
            {loading ? (
                <div className="master-list">
                    <Skeleton active />
                </div>
            ) : (
                <>
                    <div className="master-list">
                        <div>
                            <>
                                <Card className="table-card">
                                    <Table
                                        scroll={{ x: true }}
                                        dataSource={list}
                                        columns={columns}
                                        pagination={{
                                            // current: page,
                                            onChange(current) {
                                                setPage(current);
                                            },
                                        }}
                                    />
                                </Card>
                            </>
                        </div>
                    </div>
                </>
            )}
            {/** Modal used to Update service resultmaster data start */}
            <Modal
                width={'40%'}
                title={t("Update Service ResultMaster")}
                visible={isModalVisible}
                destroyOnClose={true}
                // okText="Okay"
                // onOk={() => {
                //     setIsModalVisible(false);
                // }}
                onCancel={() => setIsModalVisible(false)}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
            >
                <UpdateServiceResultMaster list={serviceresultmas} category={category} mode={mode}
                    callback={() => {

                        getServiceResultMaster(category, mode);
                        setIsModalVisible(false)
                        // On callback modal is closed and data is reloaded
                    }}



                />
            </Modal>
            {/** ends  */}
        </section>
    );
}

function UpdateServiceResultMaster({ list, category, mode, callback }) {

    const [loading, setLoading] = useState(false);

    const [active, setActive] = useState(list.active === 'Y' ? true : false);

    const { t, i18n } = useTranslation(); // To Translate to another language


    /**
     * Serviceresultmaster Update APi
     * @param {*} values
     * @returns
     */
    function onFinish(values) {
        setLoading(true);

        /**
         * Formbody for update
         */
        let formBody = {
            code: list.code,
            description: values.description,
            mode: mode,
            category: category,
            serialNo: values.serialNo,
            active: values.active ? 'Y' : 'N',
        };

        return ServiceResultMaster.updateServiceResultMaster(formBody).then((result) => {
            setLoading(false);

            if (result.success) {
                message.success(result.message);
                callback()
            } else {
                message.error(result.message);
            }
        });
    }

    /**
     * Setting value to state when change switch
     */
    function changeView() {
        setActive(true);
    }
    return (
        <>
            <p>Mention the Details</p>

            <Form
                // {...layout}
                name="basic"
                layout="vertical"
                initialValues={{
                    description: list.description,
                    serialNo: list.serialNo,
                    active: active,
                }}
                onFinish={onFinish}
            >
                <Form.Item label={t("Description")} name="description" rules={[{ required: true, message: t('Please Enter Description') },

                {
                    max: 100,
                    message: t('Length cannot exceed 100 characters !'),
                },
                {
                    // pattern: /^[ A-Za-z0-9]*$/,
                    message: t('Please Enter Valid Description!'),
                },
                ]}>
                    <InputComponent />
                </Form.Item>

                <Form.Item disabled label={t("Serial No")} name="serialNo" rules={[{ required: true, message: t('Please Enter Serial Number') },
                // {
                //     max: 4,
                //     message: 'Length cannot exceed 4 characters !',
                // },
                {
                    pattern: /^[0-9]*$/g,
                    message: t('Please Enter Valid Serial Number!'),
                },

                ]}>
                    <InputComponent />
                </Form.Item>

                {/* <Form.Item name="active" label="Active Status">
                    <Switch defaultChecked={active} checked={active} onChange={changeView} />
                </Form.Item> */}


                <Form.Item valuePropName="checked" name={'active'} label={t("Active Status")}>
                    <Switch />
                </Form.Item>


                <Form.Item>
                    <Button loading={loading} type="primary" htmlType="submit">
                        {t('Save')}
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}
