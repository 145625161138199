/***
 *
 *
 * Guest information component in Bill-I
 * @description
 * @author Sharafudheen
 */

import React, { useState, useEffect } from 'react';
import { Card, Typography, Modal, Button, Tabs, Form, Select } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Location, InputComponent, Trans, useTranslation } from 'soxo-bootstrap-core';
import VisitInfo from '../../../../../emr/components/visit-info/visit-info';
import { Items, Opreg, Bills } from '../../../../../../models';
import AdvanceSearch from '../../../../../candidate-visit/advance-search';
import './../../bill-items.scss';

const { TabPane } = Tabs;
const { Option } = Select;

const GuestInformation = ({ buttonEnable, callback, enableGuest, getReferrer }) => {
    var urlParams = Location.search();
    const [form] = Form.useForm();
    const { Title, Text } = Typography;
    const [patient, setPatient] = useState({});
    const [guest, setGuest] = useState({});
    const [, setSelecteddoctor] = useState(null);
    const [setDoctors] = useState([]);
    const [visible, setVisible] = useState(false);

    const [selectedreferror, setSelectedreferrer] = useState('');

    const { t, i18n } = useTranslation(); // To Translate to another language


    let { op_no } = urlParams;

    useEffect(() => {
        getOpRegData(op_no);
    }, [])

    function editClinicalinformation() {
        setVisible(true);
    }

    const getDoctors = () => {
        return Items.getDoctorsList().then(result => {
            setDoctors(result.result);

            return result;
        });
    };

    async function getOpRegData(op_no) {
        await Opreg.getOpregDetails(op_no).then(async result => {
            if (result && result.result) {
                await setSelectedreferrer(result.result.ReferrerPointer);
                // Add values in the field name, to populate these values in guest information card
                await setGuest({
                    ...result.result,
                    name: result.result.PatientName,
                    age: result.result.Age,
                    gender: result.result.Gender,
                    mobile: result.result.Mobile
                });
            }
        });
    }

    const Finish = async values => {
        values = {
            ...values,
            op_agetype: 'Years',
        };

        setGuest(values);

        callback(values)

        setVisible(false);
    };

    function onCancel() {
        setVisible(false);
    }

    return (
        <div className="data-section">
            <div className="right-part card card-shadow">
                <div className="Guest-info-title">
                    <p>{t('GUEST INFORMATION')}</p>
                    {buttonEnable ? <EditOutlined style={{ color: 'blue' }} onClick={editClinicalinformation} /> : null}
                </div>
                {urlParams.visit_id ? (
                    /* Patient Visit Information */
                    <VisitInfo
                        visit_id={urlParams.visit_id}
                        patient={patient}
                        setPatient={async patient => {
                            const result = await getDoctors();

                            setSelecteddoctor(patient.opv_doctorptr);

                            setPatient(patient);
                        }}
                    />
                ) : /* Patient Visit Information Ends */
                    guest ? (
                        <div className="Guest-details">
                            <div className="section-row">
                                <div className="section-label">
                                    <Text type="secondary">{t('NAME')}</Text>
                                </div>
                                <div className="section-value">
                                    <strong>
                                        {guest && guest.name ? guest.name : null}
                                    </strong>
                                </div>
                            </div>

                            <div className="details-row">
                                <div className="section-row">
                                    <div className="section-label">
                                        <Text type="secondary">{t('AGE')}</Text>
                                    </div>
                                    <div className="section-value">
                                        <strong>
                                            {guest && guest.age ? guest.age : null}
                                        </strong>
                                    </div>
                                </div>

                                <div className="section-row">
                                    <div className="section-label">
                                        <Text type="secondary">{t('GENDER')}</Text>
                                    </div>
                                    <div className="section-value">
                                        {/* {guest.op_gender === 'M' || guest.op_gender === 'Male' ? <strong>Male</strong> : <strong>Female</strong>} */}
                                        {/* {guest.op_gender === 'M' || guest.op_gender === 'Male' ? <strong>Male</strong> : null} */}
                                        {/* {guest.op_gender === 'F' || guest.op_gender === 'Female' ? <strong>Female</strong> : null} */}
                                        {['M', 'Male'].indexOf(guest.gender) !== -1 ? <strong>{t('Male')}</strong> :
                                            ['F', 'Female'].indexOf(guest.gender) !== -1 ? <strong>{t('Female')}</strong> : null}
                                    </div>
                                </div>

                                <div className="section-row">
                                    <div className="section-label">
                                        <Text type="secondary">{t('MOBILE')}</Text>
                                    </div>
                                    <div className="section-value">
                                        <strong>{guest && guest.mobile ? guest.mobile : null}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
            </div>
            <div>
                <div>
                    <Modal
                        destroyOnClose={true}
                        title={null}
                        footer={null}
                        visible={visible}
                        okText="Submit"
                        width="85%"
                        onOk={() => {
                            setVisible(false);
                            // Finish();

                            // Location.navigate({ url: `/multiple-candidate/bill` })
                        }}
                        onCancel={() => {
                            setVisible(false);
                        }}
                    >
                        <Tabs defaultActiveKey="0">
                            {/* First tab is to enter details manually */}
                            {/** This condition for nura . nura  allow direct billing only with the opreg  */}
                            {enableGuest ? null :
                                <TabPane tab={t("Enter Details")} key="0">
                                    <Form form={form} name="new-record" layout="vertical" onFinish={Finish}>
                                        <p>{t('Enter below details')}</p>

                                        <Form.Item
                                            name={'name'}
                                            label={t("Name")}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('Please Enter First Name'),
                                                },
                                                {
                                                    max: 100,
                                                    message: t('Length cannot exceed 100 characters !'),
                                                },
                                                {
                                                    pattern: /^[a-zA-Z\s]*$/g,
                                                    message: t('Please Enter Valid Name!'),
                                                },
                                            ]}
                                        >
                                            <InputComponent />
                                        </Form.Item>

                                        <Form.Item
                                            name={'age'}
                                            label={t("Age")}
                                            rules={[
                                                {
                                                    pattern: /^[0-9]{1,3}$/,
                                                    message: t('Please Enter Valid Age!'),
                                                },
                                                {
                                                    required: true,
                                                    message: t('Please Enter Age'),
                                                },
                                            ]}
                                        >
                                            <InputComponent />
                                        </Form.Item>

                                        <Form.Item
                                            name={'gender'}
                                            label={t("Gender")}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('Please Enter Gender'),
                                                },
                                            ]}
                                        >
                                            {/* <InputComponent /> */}

                                            <Select>
                                                <Option value={t('Male')}>{t('Male')}</Option>
                                                <Option value={t('Female')}>{t('Female')}</Option>
                                                <Option value={t('Other')}>{t('Other')}</Option>
                                            </Select>
                                        </Form.Item>

                                        <Form.Item
                                            name={'mobile'}
                                            label={t("Mobile")}
                                            rules={[
                                                { required: true, message: t('Please Enter Mobile Number') },
                                                {
                                                    pattern: /^(\+\d{1,3}[- ]?)?\d{10}$/,
                                                    message: t('Please enter 10 digit mobile number'),
                                                },
                                            ]}
                                        >
                                            <InputComponent />
                                        </Form.Item>

                                        <div className="footer-actions">
                                            <Button
                                                htmlType="submit"
                                                type="primary"
                                                onSubmit={() => {
                                                    setVisible(false);
                                                }}
                                            >
                                                {t('Save')}
                                            </Button>

                                            <Button htmlType="submit" type="primary" onClick={onCancel}>
                                                {t('Cancel')}
                                            </Button>
                                        </div>
                                    </Form>
                                </TabPane>}

                            {/** To Search by op number or name */}
                            <TabPane tab={t("Advanced Search")} key="1">
                                <AdvanceSearch
                                    model={Opreg}
                                    enableVisit={false}
                                    setVisible={setVisible}
                                    mode='opreg'
                                    enableModal={'doctorAppointment'}
                                    disableCheckBox={true}
                                    callback={record => {
                                        getReferrer(record.op_number)
                                        setGuest({ ...record, name: record.first_name + ' ' + record.last_name, mobile: record.mobile1, age: record.age_year });
                                        setVisible(false);
                                        callback(record);
                                    }}
                                    title=""
                                />
                            </TabPane>
                        </Tabs>
                    </Modal>
                </div>
            </div>
        </div>
    );
};

export default GuestInformation;
