/***
 *
 *
 * @description
 * @author Sneha
 *
 *
 *
 */

import React, { useState, useEffect, useContext } from 'react';

import { Button, Skeleton, Tooltip, Typography, Empty, Select } from 'antd';

import { ReloadOutlined } from '@ant-design/icons';

import { Location, GlobalContext, DateUtils, useTranslation } from 'soxo-bootstrap-core';

import { EventTransactions, EventMas } from '../../../../models';

import moment from 'moment-timezone';

import { RangePicker } from 'soxo-bootstrap-core';

import './guest-dashboard.scss';

const { Title } = Typography;

export default function GuestDashboardsListing(props) {
    const [loading, setLoading] = useState(false);

    var param = Location.search();

    const { user = {}, isMobile } = useContext(GlobalContext);

    var params = Location.search();

    const { pageno } = props.match.params; //Get pagination number

    const [pageNo, setPageNo] = useState(pageno);

    const [limit, setLimit] = useState(params.limit || 10);

    const { t, i18n } = useTranslation(); // To Translate to another language

    var [patients, setPatients] = useState([]); //Patients list array

    var [guestCards, setGuestCards] = useState([]); // guestcards array

    var [selectedRoom, setselectedRoom] = useState([]); // Selected Room guest deatils

    var starttime = moment.tz().startOf('day');

    var endtime = moment.tz().endOf('day'); //Setting starttime and endtime within a week from today

    /* Setting default Value */
    let defaultValue = { evtm_desc: 'All' };

    /**state to set package list*/
    const [selectedPackageList, setSelectedPackageList] = useState([])

    /**state to set selected package */
    const [selectedPackage, setSelectedPackage] = useState([]);

    const { Option } = Select;

    if (param.start_time) {
        updateRange();
    }

    function updateRange() {
        starttime = moment.tz(param.start_time, process.env.REACT_APP_TIMEZONE).startOf('day');
        endtime = moment.tz(param.end_time, process.env.REACT_APP_TIMEZONE).startOf('day');

        return starttime, endtime;
    }

    const [range, setRange] = useState([starttime, endtime]);


    /**
     * Patients List
     *
     * @param {*} range
     */

    async function getEventDate(range) {
        setLoading(true);

        EventTransactions.getEventDate(range).then((result) => {
            // console.log(result)

            setPatients(result);

            setLoading(false);
        });
    }

    /**
     * Guestcard List
     *
     * @param {*} range
     */

    async function getGuestCards(range) {
        setLoading(true);

        EventTransactions.getGuestCards(range).then((result) => {
            console.log(result.result);

            setGuestCards(result.result);

            setLoading(false);
        });
    }

    /**
     *  Selected Room guest details
     */

    function selectRoom(list, index) {
        setselectedRoom({ list, index });
    }

    let filtered = patients.filter((item) => {
        if (selectedRoom.list) {
            if (selectedRoom.list.indexOf(item.id) != -1) {
                return true;
            } else {
                return false;
            }
            /**If there is selectedPackages for filtering */
        } else if (selectedPackage.length) {

            /* Filtering according to selected package */
            if (selectedPackage === item.package) {
                return true;
                /* All selction */
            } else if (selectedPackage === 'All') {

                return true
            }

        }
        else {
            return true;
        }
    });

    /**
        * useeffect function for rendering
        */
    useEffect(() => {
        // if (user) {

        getEventDate(range);

        getPackageList()

        getGuestCards(range);
    }, []);

    /**
     * Changin select value
     * @param {*} value 
     */
    function selectChange(value) {

        setSelectedPackage(value)
    }

    /**
     * Getting data for list packages
     */
    function getPackageList() {

        /**Clearing the value choosed from drop down */
        setSelectedPackage([])
        /**Get api call */
        EventMas.getPackageList(props.mode, props.submode).then((result) => {
            /**Setting package list */
            setSelectedPackageList([defaultValue, ...result])

        });

    }

    /**
     *
     * @param {*} range
     */
    function updateTime(range) {
        setRange(range);

        Location.search({
            start_time: moment(range[0]).format('MM/DD/YYYY'),
            end_time: moment(range[1]).format('MM/DD/YYYY'),
        });

        getEventDate(range); //Patiant list get function
        getGuestCards(range);
    }

    function refresh() {
        getEventDate(range, pageNo, limit);
        getGuestCards(range);
    }

    return (
        <section className="guest-dashboard">
            {/* Page Header */}
            <div className="page-header">
                <div className="left">



                    <div className='package-select-box'>
                        <Select className='select-package-data'

                            onChange={selectChange}

                            defaultValue={'All'}

                        >
                            {selectedPackageList && selectedPackageList.map((data, key) => (

                                <Option value={data.evtm_desc}>

                                    {data.evtm_desc}

                                </Option>
                            ))}
                        </Select>
                    </div>


                    {/* <Title level={4}>{t('Guest Dashboard')}</Title> */}
                </div>

                <div className="right">
                    <div className="table-actions">
                        {/* Dashboard Hint Starts */}

                        <div className="page-header">
                            <div className="legend-container">
                                {/* Show Legend */}
                                <Legend />
                                {/* Show Legend */}
                            </div>
                        </div>

                        {/* Dashboard Hint Ends */}

                        <RangePicker
                            allowClear={false}
                            inputReadOnly
                            format={'DD/MM/YYYY'}
                            value={range}
                            onChange={(time) => {
                                updateTime(time, range);
                            }}
                            ranges={{
                                Today: [moment(), moment()],

                                Yesterday: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],

                                'This Week': [moment().startOf('week'), moment().endOf('week')],

                                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],

                                'This Month': [moment().startOf('month'), moment().endOf('month')],

                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                            }}
                        />

                        <Button onClick={refresh} type="secondary" size={'small'}>
                            <ReloadOutlined />
                        </Button>
                    </div>
                </div>
            </div>
            {/* Page Header Ends */}

            {loading ? (
                <Skeleton />
            ) : (
                <div className="card table-card dashboard-timeline">




                    {guestCards && guestCards.length ? (
                        <div className="guest-card-container">
                            <div className="guest-cards">
                                {guestCards
                                    .sort((a, b) => (a.order > b.order ? 1 : -1))
                                    .map((record, index) => (
                                        <GuestCards index={index} key={index} guest={record} selectRoom={selectRoom} selectedRoom={selectedRoom} />
                                    ))}
                            </div>
                        </div>
                    ) : (
                        <>{/* <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />, */}</>
                    )}

                    <>
                        {patients.length ? (
                            <>
                                <div className="guest-record-container">
                                    <div className="patient-list">
                                        {filtered.map((record, index) => (
                                            <GuestName index={index} key={index} guest={record} selectedRoom={selectedRoom} />
                                        ))}
                                    </div>

                                    <div className="event-list-container">
                                        <div className="event-list-parent">
                                            <div class="event-list-child">
                                                {filtered.map((record, index) => (
                                                    <GuestRecord index={index} key={index} guest={record} filtered={filtered} />
                                                ))}
                                            </div>
                                        </div>
                                        {/* 
                                        {filtered.map((record, index) => (
                                            <GuestRecord index={index} key={index} guest={record} filtered={filtered} />
                                        ))} */}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />,
                            </>
                        )}
                    </>
                </div>
            )
            }

            {/* </div> */}

            {/* Content Below */}

            {/* <Divider /> */}

            {/* <><Empty description={false} /></> */}

            {/* {!loading ? <Pagination defaultPageSize={10} defaultCurrent={1} total={100} /> : null} */}
        </section >
    );
}

// guest card details

function GuestCards({ selectRoom, guest, index, selectedRoom }) {
    var values = guest.ongoing_data.map((entry) => entry.patient_id);

    var data = guest.waitng_data.map((record) => record.patient_id);

    var list = [...values, ...data];

    index = index;

    return (
        <div
            onClick={() => selectRoom(list, index)}
            className={`guest-map-container event-summary card  ${selectedRoom.index === index ? 'selected' : ''}`}
        >
            <p className="room-name">{guest.room}</p>

            <div className="count-summary">
                <Tooltip title={`${guest.ongoing_count} Guest are currently in ${guest.room}`}>
                    <h4>
                        {' '}
                        {guest.ongoing_count} <img className="image-icons" src={require(`./../../../../assets/icons/hospital-bed.png`)} />
                    </h4>
                </Tooltip>

                <Tooltip title={`${guest.waiting_count} Guest are currently waiting for ${guest.room}`}>
                    <h4>
                        {' '}
                        {guest.waiting_count} <img className="image-icons" src={require(`./../../../../assets/icons/waiting-room.png`)} />{' '}
                    </h4>
                </Tooltip>
            </div>
        </div>
    );
}

function GuestName({ filtered, guest, index }) {
    // Actual Duration
    var actualDuration = moment(guest.end_time).diff(moment(guest.start_time), 'minutes');

    // Schedule Duration
    var scheduledDuration = moment(guest.scheduled_end_time).diff(moment(guest.scheduled_start_time), 'minutes');

    var Today = moment().format('MMM Do YY');
    // End Caption
    var endCaption = '';

    var finished = true;
    // var  end_string =null

    if (guest.end_time === 'On Going' || guest.end_time === null) {
        finished = false;

        endCaption = 'On Going';

        var added_time = moment(guest.start_time).format('MMM Do YY');

        if (added_time < Today) {
            endCaption = 'Incomplete';
        } else if (added_time === Today) {
            endCaption = 'On Going';
        }
    }

    // if (!guest.end_time && moment(guest.scheduled_end_time).isBefore(moment())) {

    //     finished = false;
    //     endCaption = 'Pending'
    // }

    var color = 'green';

    var procedureStatus = 'On Time';

    // Comparison for Procedure Timing
    if (actualDuration === scheduledDuration) {
        color = 'on-time';

        procedureStatus = 'On Time';
    } else if (actualDuration > scheduledDuration) {
        color = 'delayed';

        procedureStatus = 'Delayed';
    } else if (actualDuration < scheduledDuration) {
        color = 'early';

        procedureStatus = 'Early';
    } else if (!actualDuration && endCaption === 'On Going') {
        color = 'on-going';
        procedureStatus = 'OnGoing';
    } else if (!actualDuration && endCaption === 'Incomplete') {
        color = 'not-done';
        procedureStatus = 'Incomplete';
    }

    // if (!guest.end_time ) {

    //     color = 'not-done';
    //     procedureStatus = 'Incomplete'
    // }

    const billStatus = guest.bill_additional_info;

    return (
        <div className="guest-details-container">
            <div className="serial-number">
                <h4>{index + 1}.</h4>
            </div>

            {/* Guest Details */}

            <div className="guest-details-wrapper">
                <h1>{guest.name} (<span>{guest && guest?.opno ? guest.opno : null}</span>)</h1>


                <div className="row">
                    <p className="package-name">{guest.package}</p>

                    <div className={`procedure-status ${color}`}>{procedureStatus}</div>
                </div>

                <p>
                    {moment(guest.start_time).format('hh:mm a')} -{' '}
                    {finished ? moment(guest.end_time).format('hh:mm a') + ' ( Actual ' + actualDuration + ' min ) ' : endCaption}
                </p>

                <p>
                    <small>
                        {moment(guest.scheduled_start_time).format('hh:mm a')} - {moment(guest.scheduled_end_time).format('hh:mm a')} . Planned (
                        {scheduledDuration} min)
                    </small>
                </p>
                <div className="other-bill-container">
                    {billStatus.map((bill, index) => (
                        <div key={index} className="bill-status">
                            {bill.item_desc}
                        </div>
                    ))}
                </div>
            </div>

            {/* Guest Details */}
        </div>
    );
}

/**
 *
 *
 * @param {*} param0
 * @returns
 */
function Duration({ start_time, end_time }) {
    if (end_time) {
        return DateUtils.getDuration(moment(start_time, 'YYYY-MM-DD HH:mm:ss'), moment(end_time, 'YYYY-MM-DD HH:mm:ss'));
    } else {
        return DateUtils.getDuration(moment(start_time, 'YYYY-MM-DD HH:mm:ss'), moment());
    }
}

/**
 * Timeline of a guest
 */

function GuestRecord({ guest, index }) {
    var finished = true;
    var AppointmentDate = moment(guest.appointment_time).format('HH:mm a');

    if (guest.end_time === 'On Going' || guest.end_time === null) {
        finished = false;
    }

    return (
        <div className="guest-record">
            {/* Timeline */}
            <div className="event-list">
                {guest.events.map((record, index) => (
                    <EventSummary index={index} record={record} />
                ))}
            </div>
        </div>
    );
}

/**
 * Summary of a guest at a stage
 */
function EventSummary({ record, index }) {
    var Today = moment().format('MMM Do YY');

    var start_date = moment(record.start_time).format('MMM Do YY');
    //
    var appointmentdate = moment(record.scheduled_start_time).format('MMM Do YY');

    var starttime = true;

    var timetaken = true;

    var procedureStatus = 'pending';

    var color = 'green';

    // Comparison for Procedure Timing
    if (record.time_taken === record.maximum_time) {
        procedureStatus = 'Ontime';
        color = 'on-time';
    } else if (record.time_taken > record.maximum_time) {
        procedureStatus = 'Delayed';
        color = 'delayed';
    } else if (record.time_taken < record.maximum_time) {
        procedureStatus = 'Early';
        color = 'early';
    } else if (!record.time_taken) {
        procedureStatus = 'Pending';
        color = 'Pending';
    }

    if (record.start_time === 'On Going' || record.start_time === 'Not available' || record.start_time === null) {
        starttime = false;
    }
    //  comparing currentdate and starttime when endtime is ongoing ,notavailable
    var endtime = true;

    var end_string = null;
    if (record.end_time === 'On Going' || record.end_time === 'Not available' || record.end_time === null) {
        end_string = 'On Going';

        var added_time = moment(record.start_time).format('MMM Do YY');

        if (added_time < Today) {
            end_string = 'Incomplete';
        } else if (added_time === Today) {
            end_string = 'On Going';
        }

        endtime = false;
    }

    if (record.time_taken === 'Test on Processing') {
        timetaken = false;

        color = 'pending';

        procedureStatus = 'On going';
    }

    // comparision of procedure is on going
    if (record.end_time === 'On Going' || record.end_time === 'Not available' || record.end_time === null) {
        if (start_date < Today) {
            color = 'not-done';

            procedureStatus = 'Not Done';
        } else if (start_date === Today) {
            color = 'pending';

            procedureStatus = 'Pending';
        }
    }
    // comparing the start time and end time not avalilable
    if (record.start_time === 'Not available') {
        if (appointmentdate < Today) {
            color = 'not-done';
            procedureStatus = 'Not Done';
        } else if (appointmentdate === Today) {
            color = 'pending';

            procedureStatus = 'Pending';
        }
    }

    // comparision procedure
    let status = {
        caption: procedureStatus,
        color: color,
    };

    let entryClass = 'early';
    if (record.start_time === 'Not available') {
        if (appointmentdate < Today) {
            entryClass = 'not-done';
        } else if (appointmentdate === Today) {
            entryClass = 'pending';
        }
    } else if (record.start_time > record.scheduled_start_time) {
        entryClass = 'delayed';
    } else if (record.start_time === record.scheduled_start_time) {
        entryClass = 'on-time';
    } else if (record.start_time < record.scheduled_start_time) {
        entryClass = 'early';
    }

    // ends

    // Timing/Event Status

    // Early : green/ lighter
    // On Time : green
    // Delayed : orange/yellow
    // Not Done : red

    // Duration
    // Summary

    // let procedureStatus = record.time_taken > record.maximun_time ? true : false

    return (
        <Tooltip title={`${record.message}`}>
            <div className="event-summary card">
                {/* Waiting Time */}
                {index !== 0 ? (
                    <div className="waiting-time status-label">
                        <span>{record.waiting + ' mins'}</span>

                        {/* Circle Element */}
                        <div className={`circle ${entryClass}`}></div>

                        {/* Circle Element Ends */}
                    </div>
                ) : (
                    <div className="guest-time status-label ">
                        {/* Circle Element */}
                        {/* <span> 
                  <br></br>  
                </span>

                <div className={`circle ${entryClass}`}></div> */}

                        {/* Circle Element Ends */}
                    </div>
                )}

                <h3>{record.location}</h3>

                {/* Actual Time */}
                <p className="schedule">
                    {starttime ? <>{moment(record.start_time).format('hh:mm a') + ' - '}</> : null}{' '}
                    {starttime ? (
                        endtime ? (
                            <>{moment(record.end_time).format('hh:mm a ') + ' ( Actual  ' + `${record.time_taken} ` + ' min )'}</>
                        ) : (
                            end_string
                        )
                    ) : (
                        'This process has not started'
                    )}
                </p>
                {/* Actual Time Ends */}

                <p className="message">{record.message}</p>

                {/* Scheduled Time */}
                <div className="actual-time">
                    {moment(record.scheduled_start_time).format('hh:mm a') + ' - '}
                    {moment(record.scheduled_end_time).format('hh:mm a ') + ' . Planned '}({record.maximum_time + ' min '} )
                </div>
                {/* Scheduled Time Ends*/}

                <StatusLabel status={status} />
            </div>
        </Tooltip>
    );
}

/**
 *
 * @returns
 */
function Legend() {
    return (
        <>
            {[
                {
                    caption: 'Early',
                    color: 'early',
                },
                {
                    caption: 'Ontime',
                    color: 'on-time',
                },
                {
                    caption: 'Delayed',
                    color: 'delayed',
                },
                {
                    caption: 'NotDone',
                    color: 'not-done',
                },
                {
                    caption: 'pending',
                    color: 'pending',
                },
            ].map((status) => (
                <StatusLabel status={status} />
            ))}
        </>
    );
}

/**
 *
 * @param {*} param0
 * @returns
 */
function StatusLabel({ status = {} }) {
    return (
        <div className={`status-label ${status.color}`}>
            <div className={`circle ${status.color}`}></div>

            <p>{status.caption}</p>
        </div>
    );
}
