/**
 * Settingslist model to group all apis related to it
 *
 *
 * To seperate all logic
 *
 */

import React from 'react';

import Model from './models';

import { ApiUtils } from 'soxo-bootstrap-core';

class SettingsList extends Model {
    constructor() {
        super();
    }

    /**
     * Get a particular settings
     */
    getSettings = (code) => {
        return ApiUtils.get({
            url: `settingslist/settings/?code=${code}`,
        });
    };
}

export default SettingsList;
