/***
 *
 *
 * @description
 * @author jinshakappil
 */

import React, { useEffect, useState } from 'react';

import { Appointments, Bills, Opreg, Opvisits } from '../../../../models';

import './visit-info.scss';

import moment from 'moment';

import { Skeleton, Typography, Avatar, Row, Select, Form } from 'antd';

import { UserOutlined } from '@ant-design/icons';

import { DateUtils, Location, useTranslation } from 'soxo-bootstrap-core';

import DisplayNature from '../../../common/components/display-nature/display-nature';

import OpregSearch from '../../../candidate-visit/opreg-search';
import Bill from '../../../../models/bill';

const { Option } = Select;

const { Title, Text } = Typography;

const formItemLayout = {
    layout: 'horizontal',
};
/**
 * Display the OPREG data of the VISIT
 */
export default function VisitInfo({

    visit_id,
    mode,
    reference_number,
    disableFields,
    enableBillnumber,
    callback,
    startLoading,
    disableCheckBox,
    onReceiveGuestDetails,
    
}) {

    const [loading, setLoading] = useState(false);


    const { t, i18n } = useTranslation(); // To Translate to another language

    var [patient, setPatient] = useState([]);

    

    var urlParams = Location.search();

    useEffect(() => {
        if (reference_number) {
            getGuestInformation(reference_number);
        } else if (visit_id) {
            getPatient();
        }
    }, []);

    /**
     * patient personal information get from opreg
     */
    function getPatient() {
        setLoading(true);

        Opvisits.visitInfo(visit_id).then((result) => {
            if (result.result.length) {
                let opreg = result.result[0];

                setPatient(opreg);
            }

            setLoading(false);
        });
    }
    /**
     *
     * Loading  guest information details
     */
    // function getGuestInformation() {
    //     setLoading(true);
    //     Opreg.loadGuestInformation(reference_number, mode).then(result => {
    //         let data = result.data;

    //         setPatient({ opReg: data });

    //         setLoading(false);
    //     });
    // }
    /**
     *
     * Load guest information
     */
    function getGuestInformation(reference_number) {
        setLoading(true);
        Opreg.getOpregDetails(reference_number).then((result) => {


            let data = result.result;

            data = {
                // first_name: data.op_fname,
                // last_name: data.op_lname,
                name: data.PatientName,
                dob: data.Dob,
                gender: data.Gender,
                age: data.Age,
                opno: data.OpNo,
                email_address: data.Email,
                mobile_number: data.Mobile,
                nature:data.nature
                // blood_group: data.op_bloodgroup,
            };

            setPatient({ opReg: data });
            // setNature(data.natureptr);

            // On Receive the guest details we have to inform the parent
            onReceiveGuestDetails(data);

            setLoading(false);
        });
    }

    let { opReg = {} } = patient;


    // changing reference number while changing guest
    if (opReg.op_number) {
        reference_number = opReg.op_number;
    }

    // for getting nature of the guest
    const nature= opReg.nature;
    
   


    return (
        <>
            {loading ? (
                <Skeleton />
            ) : (
                <div className="visit-info card card-shadow">
                      {nature ? (<DisplayNature nature={nature}/>):null}
                    <div className="visit-info">
                    
                        <div class="card-header">
                            <div className="card-title">
                           
                                <h3>{t('GUEST INFORMATION')}</h3>
                               
                            </div>
                            <div className="actions">
                           
                            
                                <div className="opreg-search">
                                    {enableBillnumber ? (
                                        <OpregSearch
                                            enableVisit={false}
                                            mode={mode}
                                            disableCheckBox={disableCheckBox}
                                            callback={(record) => {
                                                startLoading();
                                                // response formating
                                                record = {
                                                    ...record,
                                                    opno: record.op_number,
                                                    mobile_number: record.mobile1,
                                                    email_address: record.email,
                                                    name:record.first_name,
                                                    age:record.age_year,
                                                    
                                                };

                                                setPatient({ opReg: record });
                                              
                                            }}
                                            model={Appointments}
                                            title={'Change Guest'}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        <div className="first-row">
                       
                            <Avatar shape="circle" size={50} icon={<UserOutlined />} />
                            <div className="name-section">
                                <Row>
                                    <label className="label-class">
                                        <span className="details">{t('NAME')}</span>
                                    </label>
                                </Row>
                                <Row style={{ fontSize: '16px' }}>
                                    <span>
                                        <h5>
                                           
                                            <strong>{opReg.name}</strong>
                                        </h5>
                                    </span>
                                </Row>
                            </div>
                        </div>
                        {/** patient personal details section start */}
                        <div className="second-row">
                            {/* <div gutter={0} className="detail-wrapper" > */}
                            <div className="detail-element" style={{ marginRight: '2rem' }}>
                                <Row>
                                    <label className="label-class">
                                        <span className="details">{t('DOB')}</span>
                                    </label>
                                </Row>
                                <Row className="row-class">
                                    <span>{opReg.dob ? DateUtils.formatDate(opReg.dob) : null}</span>
                                </Row>
                            </div>
                            {/* </div> */}
                            <div className="detail-element" style={{ paddingRight: '2rem' }}>
                                <Row>
                                    <label className="label-class">
                                        <span className="details">{t('AGE')}</span>
                                    </label>
                                </Row>
                                <Row className="row-class">
                                    <span>{opReg.age}</span>
                                </Row>
                            </div>
                            {/* </div> */}
                            {/* <div className="second-row"> */}
                            {/* <div className="detail-element" style={{ marginRight: '2rem' }}>
                                <Row>
                                    <label className="label-class">
                                        <span className="details">BLOOD GROUP</span>
                                    </label>
                                </Row>
                                <Row className="row-class">{opReg.blood_group ? <span>{opReg.blood_group}</span> : '-'}</Row>
                            </div> */}
                            <div className="detail-element" style={{ paddingRight: '2rem' }}>
                                <Row>
                                    <label className="label-class">
                                        <span className="details">{t('GENDER')}</span>
                                    </label>
                                </Row>
                                <Row className="row-class">
                                    <span>{opReg.gender}</span>
                                </Row>
                            </div>
                            <div className="detail-element" style={{ paddingRight: '2rem' }}>
                                <Row>
                                    <label className="label-class">
                                        <span className="details">{t('OP NO')}</span>
                                    </label>
                                </Row>
                                <Row className="row-class">
                                    <span>{opReg.opno}</span>
                                </Row>
                            </div>
                            {/** disableFields Attribute used in room in/out screen . In here mobile hide using this attribute  */}
                            {!disableFields ? null : (
                                <div className="detail-element" style={{ marginRight: '2rem' }}>
                                    <Row>
                                        <label className="label-class">
                                            <span className="details">{t('MOBILE')}</span>
                                        </label>
                                    </Row>
                                    <Row className="row-class">
                                        <span>{opReg.mobile_number}</span>
                                    </Row>
                                </div>
                            )}
                            {/* </div> */}
                            {/** disableFields Attribute used in room in/out screen . In here email hide using this attribute  */}
                            {disableFields ? null : (
                                <div className="detail-element">
                                    <Row>
                                        <label className="label-class">
                                            <span className="details">{t('EMAIL')}</span>
                                        </label>
                                    </Row>
                                    <Row className="row-class">
                                        <span>{opReg.email_address}</span>
                                    </Row>
                                </div>
                            )}

                            {enableBillnumber && reference_number ? (
                                <div className="detail-element">
                                    <BillNumber
                                        opno={reference_number}
                                        callback={(values, bills) => {
                                            callback(values, bills);
                                        }}
                                    ></BillNumber>
                                </div>
                            ) : null}

                            {!enableBillnumber && !disableFields ? (
                                <>
                                    <div className="detail-element" style={{ paddingRight: '2rem' }}>
                                        <Row>
                                            <label className="label-class">
                                                <span className="details">{t('REGISTERED DOCTOR')}</span>
                                            </label>
                                        </Row>
                                        <Row className="row-class">
                                            <span>{patient.registered_doctor}</span>
                                        </Row>
                                    </div>
                                    <div className="detail-element" style={{ marginRight: '2rem' }}>
                                        <Row>
                                            <label className="label-class">
                                                <span className="details">{t('VISIT DOCTOR')}</span>
                                            </label>
                                        </Row>
                                        <Row className="row-class">
                                            <span>{patient.visit_doctor}</span>
                                        </Row>
                                    </div>
                                </>
                            ) : null}
                            {/** This order number  */}
                            {disableFields ? (
                                <div className="detail-element" style={{ marginRight: '2rem' }}>
                                    <Row>
                                        <label className="label-class">
                                            <span className="details">{t('ORDER NO')}</span>
                                        </label>
                                    </Row>
                                    <Row className="row-class">
                                        <span>{urlParams.opb_no}</span>
                                    </Row>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

/**
 *Bill number Loading
 * @param {*} opno
 * @returns
 */
function BillNumber({ opno, callback }) {
    var [billno, setBillNo] = useState([]);
    const [form] = Form.useForm();
    const { t, i18n } = useTranslation(); // To Translate to another language

    useEffect(async () => {
        await getBillNumbers(opno);
    }, [opno]);

    /**
     * Loading bill  numbers
     * @param {*} opno
     */
    async function getBillNumbers(opno) {
        await Bills.loadbilllnumbers(opno).then((result) => {
            // If bill is present set the zeroth elemnt as the initial value for bill_id
            if (result && result.result && result.result.length > 0) {
                setBillNo(result.result);
                // Set initail values
                callback(result.result[0].opb_id, result.result);
                const initailValue = result.result[0].opb_bno + '_' + moment(result.result[0].opb_dt).format('DD/MM/YYYY');
                form.setFieldsValue({ bill_no: initailValue });
            }
        });
    }

    return (
        <>
            <Form {...formItemLayout} form={form}>
                <Form.Item name="bill_no" label={t("Bill No")}>
                    <Select
                        onSelect={(event) => {
                            callback(event, billno);
                        }}
                    >
                        {billno.map((mode) => {
                            return <Option value={mode.opb_id}>{mode.opb_bno + '_' + moment(mode.opb_dt).format('DD/MM/YYYY')}</Option>;
                        })}
                    </Select>
                </Form.Item>
            </Form>
        </>
    );
}
