import React, { useState, useEffect, useContext } from 'react';

import { Card, Button, Select, Form, Tag, Row, message, Skeleton, Typography, Input } from 'antd';

import { ReloadOutlined } from '@ant-design/icons';

import './abdomen-details.scss';

import { Location, GlobalContext, useTranslation } from 'soxo-bootstrap-core';

import { ModalityGroups } from '../../../../models';

const { Title } = Typography;

const { TextArea } = Input;

const { Option } = Select;

export default function AbdomenDetails({ match, mode = 'RNDR', history }) {
    let abdomenOptions = [
        {
            code: 'CTAGBKST',
            desc: 'Gallstone and Kidney stones',
            value: '',
            valuecomment: '',
        },
        {
            code: 'CTAFTLVR',
            desc: 'Fatty liver',
            value: '',
            valuecomment: '',
        },
        //  {
        //     code: 'CTAHPTLY',
        //     desc: 'Hepatomegaly',
        //     value: '',
        //     valuecomment: ''
        // }, {
        //     code: 'CTAHYDNS',
        //     desc: 'Hydronephrosis',
        //     value: '',
        //     valuecomment: ''
        // }, {
        //     code: 'CTARSWLG',
        //     desc: 'Renal Swelling',
        //     value: '',
        //     valuecomment: ''
        // },
        {
            code: 'CTARATPY',
            desc: 'Renal Atrophy',
            value: '',
            valuecomment: '',
        },
        // {
        //     code: 'CTASPMGY',
        //     desc: 'Splenomegaly',
        //     value: '',
        //     valuecomment: ''
        // }
    ];

    const [result, setResult] = useState({});

    const [options, setOptions] = useState([]);

    const [choices, setChoices] = useState(abdomenOptions);

    const [initial, setInitial] = useState({});

    const [btnloading, setBtnloading] = useState(false);

    var urlParams = Location.search();

    const { user, isMobile } = useContext(GlobalContext);

    const [loading, setLoading] = useState(true);

    const [formloading, setFormloading] = useState(false);

    const { t, i18n } = useTranslation();    // To Translate to another language

    const [report, setReport] = useState({
        opbill: {},
        opreg: {},
        modality: { item: {} },
        scrfiles: [],
    });

    const [patient, setPatient] = useState({
        opbill: {},
        opreg: {},
        modality: { item: {} },
        scrfiles: [],
    });
    //Patients list array

    const [form] = Form.useForm();

    const { opno } = match.params;

    let template;

    useEffect(() => {
        // console.log(urlParams);

        getPatientDetails(urlParams); //Patiant list get function
    }, [template]);

    /**
     *
     * Patient Data
     *
     * @param {*} urlParms
     */
    async function getPatientDetails(urlParms) {
        setLoading(true);

        setFormloading(true);

        ModalityGroups.getAbdomenDetails({ op_no: opno || urlParams.opno, accessionno: urlParams.accessionno, mode: 'RNDR' }).then((result) => {
            let values = result;

            setPatient(values);

            let initialValues = {};

            setInitial({});

            var research_entry = result.serviceResultTran.filter((record) => record.srvrs_mode === 'RNDR');

            if (research_entry && research_entry[0]) {
                if (mode === 'RNDR') {
                    initialValues = {
                        ...initialValues,
                        remarks: research_entry[0].srvrs_resultdedesc,
                        status: research_entry[0].srvrs_resultptr,
                    };

                    setInitial(initialValues);
                }
            }

            // We would show the kidney report
            setReport(result);

            // getCOPDReport(urlParams.id);

            setLoading(false);

            // Service result transactions
            // We have to read the xml data to get values and prep
            // prepopulate it to the form
            const serviceResultTran = research_entry;

            if (serviceResultTran.length) {
                const record = serviceResultTran[0].srvrs_otherdet1;

                // We have to parse the xml json data to prefill the input fields
                if (record && record.root && record.root.data) {
                    choices.forEach((choice, index) => {
                        let matching = record.root.data.find((ele) => ele.code[0] === choice.code);

                        choices[index].value = matching.value[0];

                        choices[index].valuecomment = matching.valuecomment[0];

                        // Initial Values
                        initialValues[choice.code] = matching.value[0];

                        initialValues['comments_' + choice.code] = matching.valuecomment[0];
                    });

                    setInitial({ ...initialValues });

                    // Set the choices
                    setChoices([...choices]);
                }
            }

            setFormloading(false);

            getDropdownOptions(values.modality.mpst_itemptr);
        });
    }

    /**
     *
     * @param {*} itemptr
     */
    function getDropdownOptions(itemptr) {
        ModalityGroups.getOptions(itemptr).then((result) => {
            const array = result.sort((a, b) => {
                return a.srvrsm_slno < b.srvrsm_slno ? 1 : -1;
            });

            // Defauling
            // let matching = array.filter((record) => record.srvrsm_desc === "Observations Required")

            // setResult(matching[0]);

            setOptions(array);
        });
    }

    /**
     * Get the final result
     */
    function getFinalResult(values) {
        let matching = options.filter((record) => record.srvrsm_desc === 'Observations Required');

        return matching[0].srvrsm_code;

        // //
        // let isPositive = false;

        // let isObservationRequired = false;

        // let isNegative = false;

        // // setResult(matching[0]);
        // abdomenOptions.forEach(({ code }) => {

        //     if (values[code] === 'Positive') {
        //         isPositive = true;
        //     }
        // })
    }

    /**
     *
     * @param {*} values
     */
    const onSubmit = (values) => {
        // Find the value that is to be saved for resultptr

        // Defauling
        // let matching = array.filter((record) => record.srvrsm_desc === "Observations Required")

        // Defauling
        // let matching = array.filter((record) => record.srvrsm_desc === "Observations Required")[0]

        let result_ptr = getFinalResult(values);

        // setLoading(true);
        setBtnloading(true);

        let otherDetails = values;

        delete otherDetails.status;

        // delete otherDetails.remarks;

        values = {
            // ...values,
            resultptr: result_ptr,
            // resultptr: result.srvrsm_code,
            resultdedesc: values.remarks,
            user: user.email,
            doctorptr: user.staffptr,
            mode: mode,
            op_no: opno || urlParams.opno,
            accessionno: urlParams.accessionno,
            slno: 10,
            srvrs_otherdet1: choices,
        };

        console.log(values);

        ModalityGroups.update(values).then((result) => {
            // console.log(result);

            /**
             *
             * API - Create Patient Report
             *
             */

            // setLoading(true);
            setBtnloading(false);

            message.success('Success');

            history.goBack();
        });
    };

    function refresh() {
        var urlParams = Location.search();

        // console.log(urlParams);

        getPatientDetails(urlParams);
    }

    /**
     * Once the field is updated , Updating the array of choices
     */
    const onFieldsChange = (fields) => {
        let element = fields[0];

        let name = element.name[0];

        let index = name;

        let isComment = false;

        // We have to check if its for comments
        // or for the boolean value
        if (name.indexOf('comments') !== -1) {
            // Replace the name
            index = name.replace('comments_', '');

            isComment = true;
        } else {
        }

        // Find the matching record
        let matching = choices.findIndex((record) => {
            return record.code === index;
        });

        if (isComment) {
            choices[matching].valuecomment = element.value;
        } else {
            choices[matching].value = element.value;
        }

        setChoices([...choices]);

        console.log(choices);
    };

    // From the report , get the image and link
    var image_link = {};

    if (report.scrfiles) {
        var reports = report.scrfiles.filter((record) => record.sflink_type === 'IMG');

        if (reports.length) {
            image_link = reports[0];
        }
    }

    // We have to disable the form if the report status is signed off .
    // Only for admin we have to enable it

    let disabled = false;

    if (initial.report_status && user.UserGrp !== 'adm') {
        disabled = true;
    }

    return (
        <section className="patient-details-abdomen">
            {/* Loading Indicator */}
            {loading ? (
                <Card className="card-shadow card">
                    <Skeleton active />
                </Card>
            ) : (
                <>
                    <div className="page-hero">
                        <div className="card-shadow card">
                            <div className="page-header">
                                <div>
                                    <Title level={3}>{t('Guest Details')}</Title>

                                    <p className="size-hint">{loading ? 'Loading data' : 'Refer below information and update feedback'}</p>
                                </div>

                                <Button onClick={refresh} type="secondary" size={'small'}>
                                    <ReloadOutlined />
                                </Button>
                            </div>

                            <div gutter={0} className="detail-wrapper">
                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>{t('Name')}</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3>{patient.opbill.opb_name}</h3>
                                    </Row>
                                </div>

                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>{t('Gender')}</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3>{patient.opbill.opb_gender}</h3>
                                    </Row>
                                </div>

                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>{t('Age')}</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3>{patient.opbill.opb_age}</h3>
                                    </Row>
                                </div>

                                {/* <div className="detail-element">
                                <Row span={12}>
                                    <span>Bill Time</span>
                                </Row>
                                <Row span={12}>
                                    <h3>{moment(patient.opbill.opb_tm).format('DD/MM/YYYY HH:mm')}</h3>
                                </Row>
                            </div> */}

                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>{t('Modality')}</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3>
                                            <Tag color={'green'}>{patient.modality.item && patient.modality.item.itm_desc}</Tag>
                                        </h3>
                                    </Row>
                                </div>

                                {/* <div className="detail-element">
                                    <Row span={12}>
                                        <span>Modality Time</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3>{moment(patient.modality.mpst_processstarttime).format('DD/MM/YYYY HH:mm')}</h3>
                                    </Row>
                                </div> */}

                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>{t('Mobile')}</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3>{patient.opbill.opb_mobile}</h3>
                                    </Row>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="report-links">
                                {image_link.sflink_filename ? (
                                    <a target="_blank" rel="noreferrer" href={image_link.sflink_filename} className={`report-link vehicle-card card`}>
                                        <h1>{t('PACS')}</h1>
                                        {t('View PACS Image')}
                                    </a>
                                ) : null}

                                <div></div>
                            </div>
                            {/* 
                            <Alert
                                // message="Note"
                                description="After review of the attached report, Use the template to submit report after your observation."
                                type="info"
                            // showIcon
                            /> */}
                        </div>
                    </div>

                    {formloading ? (
                        <Skeleton />
                    ) : (
                        <>
                            <div className="ptnt_dtls_form ">
                                <Form
                                    // {...layout}
                                    form={form}
                                    name="new-record"
                                    onFinish={onSubmit}
                                    layout="vertical"
                                    onFieldsChange={onFieldsChange}
                                    initialValues={{ ...initial }}
                                >
                                    <Title level={5}>{t('Remarks')}</Title>

                                    {/* <Form.Item
                                        name={'status'}
                                        label="Status"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter status',
                                            },
                                        ]}
                                    >
                                        <Select>
                                            {options.map((option) => (
                                                <Option value={option.srvrsm_code}>{option.srvrsm_desc}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item> */}

                                    <div className="horizontal-elements">
                                        {choices.map((option, index) => {
                                            return (
                                                <>
                                                    <div className="elements-category">
                                                        <Form.Item
                                                            name={option.code}
                                                            // name={option.code}
                                                            label={option.desc}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                },
                                                            ]}
                                                        >
                                                            <Select>
                                                                <Option value={'Positive'}>{t('Positive')}</Option>

                                                                <Option value={'Negative'}>{t('Negative')}</Option>

                                                                <Option value={'Observation Required'}>{t('Observation Required')}</Option>
                                                            </Select>
                                                        </Form.Item>

                                                        <Form.Item
                                                            name={`comments_${option.code}`}
                                                            label="Comments"
                                                            rules={[
                                                                {
                                                                    required: option.value === 'Positive' ? true : false,
                                                                    message: t('Please enter Comments'),
                                                                },
                                                            ]}
                                                        >
                                                            <TextArea rows={4} />
                                                        </Form.Item>
                                                    </div>
                                                </>
                                            );
                                        })}
                                    </div>

                                    {/*  */}
                                    <Button loading={btnloading} type="primary" htmlType="submit" className="submit-button">
                                        {t('Save')}
                                    </Button>
                                </Form>
                            </div>
                        </>
                    )}
                </>
            )}
        </section>
    );
}
