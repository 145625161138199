/**
 * SCRFilesLinks Model
 *
 *
 *
 *
 */
import React from 'react';

import Model from './models';

import { ApiUtils,BaseAPI } from 'soxo-bootstrap-core';

class Department extends BaseAPI {
    constructor() {
        super();

        this.fields = [
            {
                field: 'name',

                caption: 'Name',
            },
        ];
    }

    get id() {
        return 'department';
    }

    get getEndpoint() {
        return 'department';
    }

    get path() {
        return `department`;
    }

    get getName() {
        return this.modelName;
    }

    get modelName() {
        return `department`;
    }

    
}

export default Department;
