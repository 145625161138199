/***
 *
 *
 * @description
 * @author Aswin
 */
import React, { useState } from 'react';

import { DatePicker, Form, Input, Radio, Col, Row } from 'antd';

import { InputComponent, useTranslation } from 'soxo-bootstrap-core';

import { useEffect } from 'react';

// import './additional-key.scss';
import { Opreg } from '../../../../models';

// Add , Edit

export default function AdditionalKeyGenerate({ form, callback, seperator, edit, additionalKeyValues = {} }) {
    // state used to patient number
    const [patientnumber, setPatientNumer] = useState([]);

    const { t, i18n } = useTranslation(); // To Translate to another language

    // state used to watch prefix

    const [prefix, setPrefix] = useState(localStorage.prefix ? localStorage.prefix : null);

    useEffect(() => {
        if (!edit) {
            // checking whether prefix in localstorage
            if (prefix) {
                // split the prefix
                let splitValue = prefix.split('');

                additionalKey(prefix);
                // set values to cluster number if loalstorage have prefix
                form.setFieldsValue({
                    cluster_number: splitValue[0],
                });
            }
        } else {

            if (additionalKeyValues&&additionalKeyValues.cluster_number) {

                // Set field values
                form.setFieldsValue({
                    cluster_number: additionalKeyValues.cluster_number,
                });


                // Set serial number in case of edit
                setPatientNumer(additionalKeyValues.serial_number);

            }

        }
    }, [prefix]);

    /**
     * this Function trigger when the cluster number will change
     * @param {*} value
     */
    function additionalKey(value) {
        let record;
        let prefixCode;
        // concat value with the seperator (eg: R-)
        record = value.concat(seperator);

        if (value) {
            Opreg.additionalKey(record).then((result) => {
                if (result.success) {
                    // set serial number to the state
                    setPatientNumer(result.data[0].serial_no);

                    // Passing values to new registration form
                    callback(result.data[0].op_additional_id, result.data[0].prefix);

                    // Setting prifix to locatlstorage

                    prefixCode = result.data[0].prefix.split('');
                }
                localStorage.prefix = prefixCode[0];
            });
        }
    }
    return (
        <>
            <div className="additional-key">
                <Row gutter={10}>
                    <Col xs={10} md={10} xl={10}>
                        <Form.Item
                            name="cluster_number"
                            label={t("Cluster")}
                            rules={[
                                {
                                    required: true,
                                    message: t('Please Enter Cluster Code'),
                                },

                                {
                                    pattern: /^[a-zA-Z0-9\s]*$/g,
                                    message: t('Please Enter Valid Cluster Code!'),
                                },
                            ]}
                        >
                            <InputComponent
                                placeholder={t("Cluster Code")}
                                className="cluster-number"
                                onChange={(value) => {
                                    additionalKey(value);
                                }}
                                readOnly={edit}
                                maxLength={3}
                            />
                        </Form.Item>
                    </Col>
                    {/* <Col xs={10} md={12} xl={10}> */}
                    <Form.Item label={t("Serial Number")}>
                        <InputComponent
                            value={patientnumber}
                            // placeholder="Patient number"
                            className="patient-number"
                            readOnly={true}
                        />
                    </Form.Item>
                    {/* </Col> */}
                </Row>
            </div>
        </>
    );
}
