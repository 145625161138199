/**
 *
 *
 * @description Component for adding and removing components
 * @author Jinisha
 *
 * @param {*} param0
 * @returns
 */

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import React, { useEffect, useState, useCallback } from 'react';

import { Opvisits, Items } from '../../../../models';

import moment from 'moment-timezone';

import { Typography, Card, Button, Modal, Input, message, Form, Popconfirm, Skeleton, Empty, DatePicker, Tag, Pagination } from 'antd';

import { InputComponent,SearchDebounce, useTranslation } from 'soxo-bootstrap-core';

import './item-selection.scss';

const { Search } = Input;

const { Paragraph } = Typography;

const { Title } = Typography;

const dateFormat = 'DD/MM/YYYY';

export function ItemSelection({ callback, includeRate, deleteItem, selectedLabel, selected, category, itemMode ,histories}) {
    const [visible, setVisible] = useState(false);

    const { t, i18n } = useTranslation(); // To Translate to another language

    const [selectedItems, setSelectedItems] = useState([]);

    return (
        <>
            <div className="item-selection">
                {!histories ? (
                    <div className="button-section">
                        <Button
                            type="primary"
                            size="small"
                            className="button"
                            onClick={() => {
                                setVisible(true);
                            }}
                        >
                            <PlusOutlined />
                            {t('Add')}
                        </Button>
                    </div>
                ) : (null)}
            </div>

            {/** modal for investigation start */}
            <Modal
                width="100%"
                className="investigation"
                destroyOnClose={true}
                footer={null}
                title={!includeRate ? 'Investigation' : 'Add Item'}
                visible={visible}
                okText="Okay"
                onOk={() => {
                    setVisible(false);
                }}
                onCancel={() => {
                    setVisible(false);
                }}
            >
                <InvestigationModal
                    // callback={(selectedItems) => {
                    // refresh();
                    // setVisible(false);
                    callback={callback}
                    // If we happen to have more props , we can destructure props
                    // to bring all of its values , but we have to standardise how
                    // props can be used in a similar manner
                    includeRate={includeRate}
                    selectedLabel={selectedLabel}
                    category={category}
                    // }}
                    setVisible={setVisible}
                    selectedItems={selectedItems}
                    selected={selected}
                    setSelectedItems={setSelectedItems}
                    deleteItem={deleteItem}
                    itemMode={itemMode}
                />
            </Modal>
            {/** end */}
        </>
    );
}

/**
 * model for investigation
 *
 * @returns
 */
function InvestigationModal({
    callback,
    setVisible,
    selectedItems,
    setSelectedItems,
    includeRate,
    deleteItem,
    selectedLabel,
    selected,
    category,
    itemMode
    
}) {
    const [loading, setLoading] = useState(true);

    var [query, setQuery] = useState('');

    const { t, i18n } = useTranslation(); // To Translate to another language

    const [items, setItems] = useState([]);

    //Number of Records
    const [count, setCount] = useState(0);

    var [filteredItems, setfilterdItems] = useState([]);
    const [cardLoading, setCardLoading] = useState(true);

    const disabledDate = (current) => {
        // Can not select days before today and today
        return moment().add(-1, 'days') >= current;
    };

    // useEffect(() => {
    //     getItems(includeRate);
    // }, []);`

    /**
     * Set selected items to the array and push that data
     * @param {*} item
     * @param {*} value
     */
    const selectItem = (item, value, key) => {
        var itemRate;

        var itemPointer;
        //To get rate according to category
        if (item.itemrate && item.itemrate.length) {
            item.itemrate.map((rate) => {
                if (rate.ir_ratecatptr === category) {
                    itemRate = rate.ir_rate;
                    itemPointer = rate.ir_itemptr;
                }
            });
        }

        selectedItems.push({
            ...item,
            ...{ name: item.itm_desc },
            // ...{ key: key },
            ...{ itm_code: item.itm_code },
            ...{ remarks: value.remarks },
            ...{ date: value.date },
            ...{ rate: value.rate },
            ...{ ir_rate: itemRate },
            ...{ ir_itemptr: itemPointer },
        });

        setSelectedItems([...selectedItems]);

        // form.resetFields();
    };

    /**
     * Group by function, Groups an array with respect to the specified key
     * @param {} xs
     * @param {*} key
     * @returns
     */
    function groupBy(xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);

            return rv;
        }, {});
    }

    /**
     *
     * @param {*} values
     */

    const onFinish = (values) => {
        // Preparing custom data
        selectItem(
            {
                itm_desc: query,
                itm_code: '',
                date: values.date,
            },
            {
                remarks: values.remarks,
            }
        );
    };

    const [mounted, setMount] = useState(0);
    const [page, setPage] = useState(1); // Pagination number

    const [limit, setLimit] = useState(20);

    function deleteRecord(index) {
        selectedItems.splice(index, 1);

        // deleteItem(index);

        message.success('Record deleted');

        setMount((prev) => {
            return prev + 1;
        });
    }

    /**
     * Get items
     */
    function getItems({ includeRate, pageNo = page, pageLimit = limit }) {
        setLoading(true);
        Opvisits.getItems(pageNo, pageLimit, includeRate, category, count, itemMode).then((result) => {
            // When no data is found then filtered items will be empty array
            if (result.length || result.result.length) {
                if (includeRate) {
                    result = result.map((ele) => {
                        return {
                            ...ele,
                            ir_rate: ele.itemrate[0].ir_rate,
                            ir_itemptr: ele.itemrate[0].ir_itemptr,
                            ir_ratecatptr: ele.itemrate[0].ir_ratecatptr,
                        };
                    });

                    
                    setfilterdItems(result);
                } else {
                    setItems(result.result);
                    setfilterdItems(result.result);
                    setCount(result.count);
                }
            } else {
                setfilterdItems([]);
            }

            setCardLoading(false);

            setLoading(false);

            if (includeRate) {
                setSelectedItems(selected);
                setCardLoading(false);
            }
        });
    }
    /**
     *
     * @param {*} event
     */

    function onSearch(event) { }

    /**
     *
     *
     * @param {*} date
     * @param {*} dateString
     */
    function onDateChange(date, index) {
        selectedItems[index].date = date;
        setSelectedItems([...selectedItems]);
    }

    /**
     *
     * @param {*} remarks
     * @param {*} index
     */
    function onRemarkChange(remarks, index) {
        selectedItems[index].remarks = remarks;

        setSelectedItems([...selectedItems]);
    }

    function onRateChange(rate, index) {
        selectedItems[index].rate = rate;

        setSelectedItems([...selectedItems]);
    }
    function onPagination(pageNo, pageLimit) {
        setPage(pageNo);

        setLimit(pageLimit);
        getItems({ pageNo, pageLimit, itemMode });
    }

    if (selectedItems.length) {
        // Create an array for filtering the items
        let selectedKeys = selectedItems.map((item) => item.itm_code);

        // Filter the items from the cart
        filteredItems = filteredItems.filter((record) => {
            query = query.toUpperCase();

            if (selectedKeys.indexOf(record.itm_code) !== -1) {
                return false;
            }
            // else if (selectedKeys.indexOf(query) != -1) {
            //     return true;
            // }
            else {
                return true;
            }
        });
    }

    /**
     * To search investigation
     */

    async function getInvestigation(query) {
        var result = await Opvisits.getSearch(query);

        setfilterdItems(result);
    }
    /**
     * Disable selecting future dates
     */

    // const disabledDate = (current) => {
    //     return current > moment().endOf('day');
    // };

    /**
     * debounce to avoid repeated call of api
     * @returns
     */

    // const debounceFunction = useCallback(_debounce(handleDebounce, 1000), []);

    // async function handleDebounce(inputValue) {
    //     var result = await Opvisits.getSearch(inputValue);

    //     setfilterdItems(result);
    // }

    // /**
    //  * handle search using debounce
    //  * @param {*} event
    //  */
    // function handleSearch(event) {
    //     setQuery(event.target.value);
    //     debounceFunction(event.target.value);
    // }

    /**
     *  Flitering for search
     */
    // filteredItems = filteredItems.filter((record) => {
    //     query = query.toUpperCase();
    //     if (query) {
    //         if (record.itm_desc.toUpperCase().indexOf(query) !== -1) {
    //             return true;
    //         }
    //     } else {
    //         return true;
    //     }
    // });

    /**
     * Load the matching heads
     */
    // function loadHeads(event) {
    //     setQuery(event.target.value);
    //     if (query) {
    //         return Opvisits.getSearch(query).then((result) => {
    //             setfilterdItems(result);
    //             // return result.map((item) => {
    //             //     return {
    //             //         value: item.itm_code,
    //             //         label: item.itm_desc,
    //             //         ...item,
    //             //     };
    //             // });
    //         });
    //     }
    // }

    return (
        <div className="investigation-modal card card-shadow">
            {/* Cart Section */}
            <div className="cart-section">
                <div className="item-selection-container">
                    <div className="cart">{selectedLabel}</div>
                </div>
                {/* Cart Section */}
                {cardLoading ? (
                    <Skeleton />
                ) : selectedItems.length ? (
                    <>
                        {selectedItems.length &&
                            selectedItems.map((ele, key) => {
                                return (
                                    <div className="cart-item">
                                        <div className="cart-item-icon">
                                            <div>{ele.itm_desc}</div>

                                            <Popconfirm
                                                title={t("Are you sure to delete this Item?")}
                                                onConfirm={() => deleteRecord((ele, key))}
                                                // onCancel={cancel}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <DeleteOutlined className="delete" />
                                            </Popconfirm>
                                        </div>
                                        {/*  */}
                                        {includeRate ? (
                                            <div>{ele.ir_rate}</div>
                                        ) : (
                                            <>
                                                <DatePicker
                                                    onChange={(value) => {
                                                        onDateChange(value, key);
                                                    }}
                                                    value={ele.date}
                                                    defaultValue={moment()}
                                                    format={dateFormat}
                                                    disabledDate={disabledDate}
                                                />
                                                {/* <Paragraph
                                            className="rate"
                                            onChange={(value) => {
                                                onRateChange(value, key);
                                            }}


                                        >
                                            {ele.rate}
                                        </Paragraph> */}
                                                <Paragraph
                                                    className="remarks"
                                                    editable={{
                                                        onChange: (value) => {
                                                            onRemarkChange(value, key);
                                                        },

                                                        maxLength: 100,
                                                    }}
                                                >
                                                    {ele.remarks}
                                                </Paragraph>
                                            </>
                                        )}
                                    </div>
                                );
                            })}
                    </>
                ) : null}

                {/* Cart Section Ends */}

                <div className="cart-submit">
                    <Button
                        className="cart-button"
                        type="primary"
                        htmlType="submit"
                        onClick={() => {
                            setSelectedItems(selectedItems);
                            callback(selectedItems);
                            setVisible(false);
                        }}
                    >
                        {t('Save')}
                    </Button>
                </div>
            </div>
            {/* Cart Section Ends */}

            <div className="investigation-items">
                <div className="investigation-header">
                    <div className="new-item">
                        {loading ? (
                            <Skeleton />
                        ) : (
                            <>
                                {filteredItems.length > 0 ? (
                                    <Title level={4}>{filteredItems.length} {t('results')}</Title>
                                ) : (
                                    <Title level={4}>{t('No results found')}</Title>
                                )}
                            </>
                        )}
                    </div>

                    {/**
                     * Search section begins
                     *
                     * useEffect inside searchdebouce is triggered first when itemselection component is triggered and api call is done accordingly
                     */}
                    <div>
                        <SearchDebounce
                            value={query}
                            onChange={(value) => {
                                if (value.length) {
                                    getInvestigation(value);
                                    setQuery(value);
                                } else {
                                    getItems({ includeRate });
                                }
                            }}
                        />
                        {/* <Search
                            className="search"
                            value={query}
                            placeholder="Enter Search Value"
                            style={{ width: 465, marginTop: '10px', marginBottom: '20px' }}
                            onChange={handleSearch}
                        /> */}
                    </div>
                    {/**Search section ends */}
                </div>

                {loading ? (
                    <Skeleton />
                ) : (
                    <div className="all-items">
                        {/* Matching Results */}
                        {includeRate ? null : query ? (
                            <>
                                {/* <p>
                                    {filteredItems.length} results matching {query} . <Button onClick={addCustomItem}> Create {query}</Button>
                                </p> */}
                                <Form onFinish={onFinish}>
                                    <div className="serach">
                                        <div className="customname">
                                            <Form.Item name="customitem">
                                                <div className="title">
                                                    <Title className="title" level={5}>
                                                        {query}
                                                    </Title>
                                                </div>
                                            </Form.Item>

                                            <div className="new-icon">
                                                <Tag color="green">{t('New')}</Tag>
                                            </div>
                                        </div>

                                        <Form.Item name="date">
                                            <DatePicker
                                                onChange={onDateChange}
                                                defaultValue={moment()}
                                                format={dateFormat}
                                                disabledDate={disabledDate}
                                            />
                                        </Form.Item>
                                        <Form.Item name="remarks">
                                            <InputComponent
                                                placeholder={t("Remarks")}
                                                rules={[{ max: 100, message: t('Length cannot exceed 100 characters!') }]}
                                            />
                                        </Form.Item>
                                        <div>
                                            <Button size="small" className="add-button" type="primary" htmlType="submit">
                                                {t('Add New')}
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            </>
                        ) : null}

                        {/* Matching Results Ends */}

                        {/* Iterating filtered items */}
                        {filteredItems.map((entry, key) => {
                            return (
                                <ItemEntryCard
                                    category={category}
                                    includeRate={includeRate}
                                    entry={entry}
                                    key={key}
                                    disabledDate={disabledDate}
                                    callback={(values) => {
                                        // On Submission of form
                                        selectItem(entry, values, key);
                                    }}
                                />
                            );
                        })}
                        {/* Iterating filtered items Ends */}

                        <div className="pagination">
                            {!loading && !includeRate ? (
                                <Pagination pageSize={limit} showSizeChanger={true} onChange={onPagination} defaultCurrent={page} total={count} />
                            ) : null}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

/**
 *
 * @returns
 */
function ItemEntryCard({ entry, callback, key, includeRate, category, disabledDate }) {
    const [form] = Form.useForm();

    const { t, i18n } = useTranslation(); // To Translate to another language

    /**
     * On Submission of the form
     *
     * @param {*} values
     */
    const onFinish = (values) => {
        callback(values, key);

        form.resetFields();
    };

    return (
        <>
            <Form form={form} onFinish={onFinish} key={key}>
                <div className="card item card-shadow">
                    <div className="description">
                        <div className="card-title">
                            <Title className="title" level={5}>
                                {entry.itm_desc}
                            </Title>
                        </div>

                        {/**If include rate then show rate */}
                        {includeRate ? (
                            // If entry has ir_rate field show ir_rate
                            entry.ir_rate ? (
                                <>
                                    {' '}
                                    <Title className="title" level={5}>
                                        {entry.ir_rate}
                                    </Title>
                                </>
                            ) : (
                                //if entry has itemrate array,then get rate in same category selected
                                entry.itemrate.map((item) => {
                                    if (item.ir_ratecatptr === category) {
                                        return (
                                            <>
                                                <Title className="title" level={5}>
                                                    {item.ir_rate}
                                                </Title>
                                            </>
                                        );
                                    }
                                })
                            )
                        ) : (
                            <>
                                <Form.Item name="date" className="date">
                                    <DatePicker defaultValue={moment()} format={dateFormat} disabledDate={disabledDate} />
                                </Form.Item>
                                {/* <Form.Item initialValue={entry.ir_rate} name="rate" className="rate">
                                                    <Input readOnly={true} />

                                                </Form.Item> */}
                                <Form.Item name="remarks" className="remarks" rules={[{ max: 100, message: t('Length cannot exceed 100 characters!') }]}>
                                    <InputComponent placeholder={t("Remarks")} />
                                </Form.Item>
                            </>
                        )}
                    </div>

                    <Button size="small" className="add-button" type="primary" htmlType="submit">
                        {t('Add')}
                    </Button>
                </div>
            </Form>
        </>
    );
}
