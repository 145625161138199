import React, { useState } from 'react';
import { Row, Button, Modal, message, Tag,Typography } from 'antd';
import { Camera, useTranslation } from 'soxo-bootstrap-core';
import { DocDetails } from '../../../models';
import DocumentPreview from '../../../components/document-preview/document-preview';
const { Title, Text } = Typography;

function UploadId({ callback,upload_status, uploaded_status, id, da_corporateptr, props  }) {
    const [openPreview, setOpenPreview] = useState(false);

    const { t, i18n } = useTranslation(); // To Translate to another language

    const onIdupload = (selectedImages, baseIndex = 0) => {
        message.loading('Uploading EmployeeId Document');

        Promise.all(
            selectedImages.map((ele, index) => {
                var formData = new FormData();

                formData.append('files', ele);
                formData.append('appointment_id', id);
                formData.append('index', baseIndex + (index + 1));
                formData.append('document_code', 'EMID');
                formData.append('customer_code', da_corporateptr);
                
                return DocDetails.documentUpload(formData, props.onPremiseUrl).then(result => {
                    if (result.message === 'success') {
                        return result;
                    }
                });
            })
        ).then((result) => {
            // Handle any additional logic after successful upload
            if (result[0].success) {
                message.success('File Uploaded SuccessFully');

                callback();
            } else {
                message.error('File Upload Failed');

                callback();
            }
        });
    };

    const handlePending = () => {
        console.log('Marking as Pending...');
    };

    const renderContent = () => {
        if (uploaded_status) {
            return (
                <div>
                    <Tag color="green">{t('Submitted')}</Tag>
                    <Button
                        size="middle"
                        type="primary"
                        onClick={() => {
                            setOpenPreview(true);
                        }}
                    >
                        {t('Preview')}
                    </Button>
                </div>
            );
        } else {
            return (
                <>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div className="section-label">
                                <Text type="secondary">{t('STATUS')}</Text>
                            </div>
                            <div className="section-value">
                                <Tag color="orange">{t('Pending')}</Tag>
                            </div>
                        </div>

                    <Camera multiple={true} onUpload={onIdupload} mode={'rear'} />
                </>
            );
        }
    };

    if (upload_status === 'MND' || upload_status === 'OPT') {
        return (
            <div className="card-shadow card">
                
                <Title level={5}>{t('CORPORATE ID INFORMATION')}</Title>
                
                <Row span={12}>
                    {/* Render content based on submission status */}
                    {renderContent()}
                </Row>

                {/* Preview Modal */}
                <Modal
                    width={'100%'}
                    destroyOnClose={true}
                    title={t('Corporate Id File')}
                    visible={openPreview}
                    onCancel={() => {
                        setOpenPreview(false);
                    }}
                    footer={null}
                >
                    {/* You can customize the preview or display the captured image(s) here */}
                    
                    <DocumentPreview id={id} onDocumentUpload={onIdupload} props={{reportConfig:props.reportConfig , onPremiseUrl:props.onPremiseUrl,type:"EMID"}} />

                </Modal>
            </div>
        );
    } else {
        return null;
    }
}

export default UploadId;
