/**
 * Template Master
 *
 *
 *
 *
 */

import React from 'react';

import Model from './models';

import { ApiUtils, BaseAPI } from 'soxo-bootstrap-core';

class DocDetail extends BaseAPI {
    get id() {
        return 'docdet_id';
    }

    get getEndpoint() {
        return 'doc-details';
    }

    get path() {
        return `doc-details`;
    }

    get getName() {
        return this.modelName;
    }

    get modelName() {
        return `DocDetails`;
    }

    // Get Documents
    getDocuments = (op_no) => {
        return ApiUtils.get({
            url: `doc-details?docdet_refptr=${op_no}`,
        });
    };

    // Upload files to docdetails
    uploadFiles = (formData) => {
        return fetch(process.env.REACT_APP_endpoint + `doc-details/upload-file`, {
            // Your POST endpoint
            method: 'POST',
            headers: {
                //   'App-Type': 313,
                //   'App-Version': '1.0.1',
                Authorization: 'Bearer ' + localStorage.access_token,
                // type:'multipart/formData'
            },
            //   credentials: 'include',
            body: formData,
        }).then(
            (result) => {
                return result.json();
            },
            (error) => {
                console.log(error);
                return error;
            },
            (progress) => {
                console.log(progress);
                return progress;
            }
        );
    };

    documentUpload = (formData,onPremiseUrl) => {
        return fetch(onPremiseUrl + `/doc-details/document-upload`, {
            // Your POST endpoint
            method: 'POST',
            headers: {
                //   'App-Type': 313,
                //   'App-Version': '1.0.1',
                Authorization: 'Bearer ' + localStorage.access_token,
                // type:'multipart/formData'
            },
            //   credentials: 'include',
            body: formData,
        }).then(
            (result) => {
                return result.json();
            },
            (error) => {
                console.log(error);
                return error;
            },
            (progress) => {
                console.log(progress);
                return progress;
            }
        );
    };

    /**
     * Function would load the Image from the file location
     * 
     * @param {*} fileName 
     * @returns 
     */
    loadFile = async (config, fileName, type, props) => {

        let pdfArrayBuffer = null;

        let pdfUrl = null;

        let apiURL = null;

        // Payload for the request
        let payload = {
            method: 'GET',
            headers: {
                db_ptr: process.env.REACT_APP_DB_PTR,
                // ...headers,
                Authorization: 'Bearer ' + localStorage.access_token,
            },
        };
        
        if (props.consent == 'consent' || config.documentType == 'report') {
            apiURL = (config.baseUrl || props.onPremiseUrl) + `/doc-details/load-file?location=${fileName}&type=${type}`
        } else {
            apiURL = (config.baseUrl || props.onPremiseUrl) + 'files/read-file-pdf?file=' + `${fileName}&type=${type}`
 
        }
        // var apiURL = (config.baseUrl || process.env.REACT_APP_ON_PREMISE_URL) + `/doc-details/load-file?location=${fileName}&type=${type}`
        // var apiURL = 'http://localhost:3800/' + 'files/read-file-test?file=' + `${fileName}&type=${type}` 

        return await fetch(apiURL, payload).then(response => {
            pdfUrl = response.url;
            return response.arrayBuffer();
        })
            .then(arrayBuffer => {
                pdfArrayBuffer = arrayBuffer;

                return pdfArrayBuffer;
            })
            .catch(error => {
                console.error('Error fetching PDF:', error);
            });
    };

    /**
     * Load the files by id
     * 
     * @param {*} id 
     */
    loadFilesById = (id, onPremiseUrl) => {
        return ApiUtils.get({
            baseUrl: onPremiseUrl,
            url: `/doc-details/load-file-by-id?id=${id}`,
            // url: `doc-details/load-file-img?docdet_location=${fileName}`,
            // url: `doc-details/load-file-by-id?id=${id}`,
        });
    }
}

export default DocDetail;
