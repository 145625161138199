/**
 * @author Sameena
 * Authorize Result Components
 */

import React, { useState, useEffect, useContext } from 'react';

import { Button, Typography, Table, Tag, Skeleton, message } from 'antd';

import { GlobalContext, Location, useTranslation } from 'soxo-bootstrap-core';

import moment from 'moment-timezone';

import { RangePicker } from 'soxo-bootstrap-core';

import { ReloadOutlined } from '@ant-design/icons';
import { Samples } from '../../../../models';

import './authorize-result.scss';

const { Title } = Typography;

export default function AuthorizeResult({ match, title }) {
    var param = Location.search();

    const [range, setRange] = useState([
        moment.tz(param.start_time, process.env.REACT_APP_TIMEZONE).startOf('day'),
        moment.tz(param.end_time, process.env.REACT_APP_TIMEZONE).endOf('day'),
    ]);

    const { user = {}, isMobile } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);

    const [labResult, setLabResult] = useState([]); //Patients list array

    var param = Location.search();

    const [result, setResult] = useState([]);

    const { t, i18n } = useTranslation();  // To Translate to another language

    const [btnloading, setBtnLoading] = useState(false);

    //Get the lab Results that are to be authorized
    async function getLabResult(range) {
        setLoading(true);

        Samples.getLabResults(range, user).then((result) => {
            setLabResult(result.data);
            // setPatients(jsonData[0]);

            setLoading(false);
        });
    }

    useEffect(() => {
        if (user) {
            getLabResult(range); //Lab result get function
        }
    }, [user]);

    /**
     * Update time function. Get the lab results to be authorized within the selected range
     * @param {*} dt
     */
    function updateTime(dt) {
        setRange(dt);

        Location.search({
            start_time: moment(dt[0]).format('MM/DD/YYYY'),
            end_time: moment(dt[1]).format('MM/DD/YYYY'),
        });

        getLabResult(dt); //Patiant list get function
    }

    /**
     * Refresh Function
     */

    function refresh() {
        getLabResult(range);
    }

    /**
     * When a Lab result is selected to authorize
     * @param {*} result
     */
    const selectResult = (result) => {
        setResult(result);
    };

    /**
     * Function to authorize the result. Here the lab result lbtrs_isclosed is updates to 'Y'
     * indicating that the resultis authorized
     */

    async function authorizeResult(lab_result) {
        setBtnLoading(true);

        var params = {
            lbtrs_itemptr: lab_result.lbtrs_itemptr,
            lbtrs_refmode: lab_result.lbtrs_refmode,
            lbtrs_refid: lab_result.lbtrs_refid,
            lbtrs_isclosed: 'Y',
        };

        await Samples.authorizeLabResult(params);

        message.success('You have Successfully Authorized the Result');

        await getLabResult(range);

        setBtnLoading(false);
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => index + 1,
        },

        {
            title: t('Particulars'),
            key: 'name',
            render: (ele) => {
                return ele.lbtrsd_particulrs;
            },
        },

        {
            title: t('Value'),
            key: 'range',
            render: (ele) => {
                return ele.lbtrsd_result;
            },
        },

        {
            title: t('Range'),
            key: 'range',
            render: (ele) => {
                return ele.lbtrsd_refrange;
            },
        },

        {
            title: t('Unit'),
            key: 'lbtrsd_unit',
            render: (ele) => {
                return ele.lbtrsd_unit;
            },
        },
    ];

    return (
        <section className="sample-list card">
            {/* Page Header */}

            <div className="page-header">
                <div className="left">
                    <Title style={{ marginBottom: '0px' }} level={3}>
                        {title}
                    </Title>
                </div>

                <div className="right">
                    <div className="date-and-fltr">
                        <RangePicker
                            allowClear={false}
                            inputReadOnly
                            format={'DD/MM/YYYY'}
                            value={range}
                            onChange={(time) => {
                                updateTime(time, range);
                            }}
                            ranges={{
                                Today: [moment(), moment()],

                                Yesterday: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],

                                'This Week': [moment().startOf('week'), moment().endOf('week')],

                                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],

                                'This Month': [moment().startOf('month'), moment().endOf('month')],

                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                            }}
                        />

                        <Button onClick={refresh} type="secondary" size={'small'}>
                            <ReloadOutlined />
                        </Button>
                    </div>
                </div>
            </div>

            {loading ? (
                <Skeleton active />
            ) : labResult && labResult.length !== 0 ? (
                <div className="authorize-result">
                    {labResult.map((labrst) => {
                        return (
                            <div
                                className={`authorize ${result.lbtrs_refid && result.lbtrs_refid === labrst.lbtrs_refid ? 'active' : ''}`}
                                onClick={() => selectResult(labrst)}
                            >
                                <div className="top-bar">
                                    <div>
                                        <h1>
                                            {labrst && labrst.bill && labrst.bill.opb_name ? (
                                                <p>
                                                    {labrst.bill.opb_name} - {labrst.bill.opb_gender} / {labrst.bill.opb_age}
                                                </p>
                                            ) : null}{' '}
                                        </h1>
                                        <div>
                                            <p>
                                                <strong>{t('OP No')} : {labrst && labrst.bill && labrst.bill.opb_opno}</strong>
                                            </p>
                                        </div>
                                        {t('Result Entered On')}: {moment.tz(labrst.lbtrs_testdonedttm, '').format('DD/MM/YYYY HH:mm A')}
                                        <br />
                                        {/* <br /><strong>{lab.lbtrsd_particulrs}</strong> */}
                                    </div>
                                    <div>
                                        {labrst && labrst.lbtrs_isclosed === 'Y' ? null : <Tag color="red">{t('Pending')}</Tag>}
                                        {labrst.labtresultd.length} test
                                    </div>
                                </div>

                                {/* {result.lbtrs_refid && result.lbtrs_refid === labrst.lbtrs_refid ? */}

                                <div className="result-bar">
                                    <Table pagination={false} columns={columns} dataSource={labrst.labtresultd} />

                                    <Button
                                        className="result-approve"
                                        loading={btnloading}
                                        onClick={() => {
                                            authorizeResult(labrst);
                                        }}
                                    >
                                        {t('Approve')}
                                    </Button>
                                </div>
                                {/* :
                                            null
                                                  } */}
                            </div>
                        );
                    })}
                </div>
            ) : (
                <p>{t('No Results Found')}</p>
            )}
            {/* Page Header Ends */}

            {/* Content Below */}
        </section>
    );
}
