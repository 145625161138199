import React, { useState, useEffect } from 'react';
import { Button, Modal, Card, message } from 'antd';
import { ApiUtils, useTranslation } from 'soxo-bootstrap-core';

function SyncareLink({ children, op_no, className, accession_number, branch_id, firm_id, with_op_no = false }) {

    const { t, i18n } = useTranslation(); // To Translate to another language

    /** State to manage the visibility of the Modal */
    const [syncareLink, setSyncareLink] = useState('');

    /** useEffect to fetch Syncare link when any of the dependency props change */

    useEffect(() => {
        getSyncarelink(op_no, accession_number, branch_id, firm_id, with_op_no);
    }, [op_no, accession_number, branch_id, firm_id, with_op_no]);

    /** Async function to make an API call and fetch the Syncare link */

    async function getSyncarelink(op_no, accession_number, branch_id, firm_id, with_op_no) {
        try {
            const response = await ApiUtils.get({
                baseUrl: 'https://blrffdkhportal.nura-in.com',
                /** API endpoint to fetch Syncare link with query parameters */
                url: `/FileFSLinkTest/files/syncare-link?op_number=${op_no}&accession_number=${accession_number}&branch_id=${branch_id}&firm_id=${firm_id}&with_op_no=${with_op_no}`,
            });

            /** Check if the API call was successful (status code 200) */
            if (response.status == 200) {
                /**  Extract Syncare link from the API response data */
                const imageUrl = response.data;

                /**  Set the syncareLink state with the obtained imageUrl */

                navigator.clipboard.writeText(imageUrl); //clipboard
                setSyncareLink(imageUrl); // Set syncareLinkOpNo if with_op_no is true
            } else {
                console.error('Error fetching syncare link:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    /** Function to open the Syncare link in a new tab */
    const openUrlInNewTab = () => {
        if (syncareLink) {
            window.open(syncareLink, '_blank');
        }
    };

    /** Function to handle clicking the button with op_no */
    async function handleCopyOpNo() {
        await getSyncarelink(op_no, accession_number, branch_id, firm_id, true);
        // navigator.clipboard.writeText(syncareLinkOpNo);
        message.success(`copied to Clipboard`);
    }

    /** Function to handle clicking the button with accession_number */
    async function handleCopyAccessionNo() {
        await getSyncarelink(op_no, accession_number, branch_id, firm_id, false);
        message.success(`copied to Clipboard`);
    }

    return (
        <>
            <div className="syncare-link-view">
                <Button
                    className={className}
                    type="secondary"
                    size={'small'}
                    onClick={() => {
                        openUrlInNewTab(true);
                    }}
                >
                    {children || 'View'}
                </Button>
                <div style={{ display: 'flex', gap: '2rem' }}>
                    <Button
                        type="secondary"
                        size={'small'}
                        onClick={() => {
                            handleCopyOpNo();
                        }}
                    >
                        {t('Copy With OpNo')}
                    </Button>
                    <Button
                        type="secondary"
                        size={'small'}
                        onClick={() => {
                            handleCopyAccessionNo();
                        }}
                    >
                        {t('Copy With AccessionNo')}
                    </Button>
                </div>
            </div>

            {/* <div className="syncare-link card" onClick={openUrlInNewTab}>
                <h1>SyncareLink</h1>
            </div> */}
        </>
    );
}

export default SyncareLink;
