/***
 *
 * Guest information component in Bill-I
 * @description
 * @author Sharafudheen
 */

import React, { useState } from 'react';
import { Form, Row, Col, Radio, InputNumber, Button, Input } from 'antd';
import { useTranslation} from 'soxo-bootstrap-core';

const { TextArea } = Input;

function DiscountModal({ callback, subTotal, discount, avoidRemark,currency }) {
    const [initialValues] = useState(discount);

    const { t, i18n } = useTranslation(); // To Translate to another language
   
    
 
     console.log("currency",currency);
 

    const onFinish = (values) => {
        callback(values);
    };

    return (
        <>
            <p>{t('Mention the discount')}</p>

            <Form layout="vertical" name="basic" initialValues={{ ...initialValues, type: '%', remember: true }} onFinish={onFinish}>
                <Row gutter={20}>
                    <Col xs={24} md={12} xl={8}>
                        <Form.Item name="type" label={t("Discount")} rules={[{ required: true, message: t('Please Select Discount Type') }]}>
                            <Radio.Group>
                                <Radio value="%">In %</Radio>
                                <Radio value={currency}>{t("In Amount")}</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={20}>
                    <Col xs={24} md={12} xl={8}>
                        <Form.Item
                            label={t("Value")}
                            name="value"
                            dependencies={['type']}
                            rules={[
                                { required: true, message: t('Please Input your Amount') },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('type') === currency) {
                                            if (value > subTotal) {
                                                return Promise.reject(new Error('Discount should not exceed net amount.'));
                                            } else if (value && value <= 0) {
                                                return Promise.reject(new Error('Discount cannot be negative or zero'));
                                            } else return Promise.resolve();
                                        } else if (!value || getFieldValue('type') === '%') {
                                            if (value > 100) {
                                                return Promise.reject(new Error('Discount should not exceed 100%.'));
                                            } else if (value && value <= 0) {
                                                return Promise.reject(new Error('Discount cannot be negative or zero'));
                                            } else return Promise.resolve();
                                        } else {
                                            return Promise.resolve();
                                        }
                                    },
                                }),
                            ]}
                        >
                            <InputNumber />
                        </Form.Item>
                    </Col>
                </Row>
                {avoidRemark ? null : (
                    <Row gutter={20}>
                        <Col xs={24} md={24} xl={24}>
                            <Form.Item name="remarks" label={t("Remarks")} rules={[{ required: true, message: t('Please Enter Remarks') }]}>
                                <TextArea rules={[{ required: true }]} />
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {t("Save")}
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}

export default DiscountModal;
