/**
 * Nature Model
 *
 *
 * Nature mode is to decide what mode of Nature to be recorded for their registration , appointment or visit. 
 *
 */
import React, { useState, useEffect, useContext } from 'react';

import Model from './models';

class Nature extends Model {

    constructor() {
        super();

        this.fields = [
            {
                field: 'desc',
                caption: 'Name',
            },
        ];

        // this.columns = ;
    }

    get id() {
        return 'code';
    }

    get getEndpoint() {
        return 'master/nature';
    }

    get path() {
        return `master/nature`;
    }

    get getName() {
        return this.modelName + `s`;
    }

    get modelName() {
        return `master/nature`;
    }

    get columns() {
        return [];
    }

    

}

export default Nature;
