/**
 * @description 
 * @author Ashique 
 * 
 * The component used to register the guest  
 * 
 *
 */

import React, { useState, useEffect } from "react";

import { Input, Form, Switch, Modal, Select, message } from 'antd';

import { Button, ScriptInput, useTranslation } from 'soxo-bootstrap-core'

// import {  } from '@ant-design/icons';

import { Preferences } from "../../../../models";

import './registration-form-settings.scss';

const { Option } = Select;


let formSettings = {
    fields: [{
        field: 'title',
        caption: 'Title',
        required: true,
        visible: true
    }, {
        field: 'first_name',
        caption: 'First Name',
        required: true,
        visible: true
    }, {
        field: 'last_name',
        caption: 'Last Name',
        required: true,
        visible: true
    },

    {
        field: 'date_of_birth',
        caption: 'Date of Birth',
        required: true,
        visible: true
    },

    {
        field: 'gender',
        caption: 'Gender',
        required: true,
        visible: true
    },
    {
        field: 'mobile_number',
        caption: 'Mobile Number',
        required: true,
        visible: true
    },
    {
        field: 'alternate_mobile',
        caption: 'Alternate Mobile Number',
        required: false,
        visible: true
    },

    {
        field: 'email_address',
        caption: 'Email Address',
        required: true,
        visible: true
    },

    {
        field: 'mode_of_communication',
        caption: 'Communication Mode',
        required: true,
        visible: true
    },

    // Address configuration. 

    {
        field: 'district',
        caption: 'District',
        required: false,
        visible: true
    },

    {
        field: 'pincode',
        caption: 'Pin Code',
        required: false,
        visible: true
    },

    {
        field: 'address1',
        caption: 'Address 1',
        required: false,
        visible: true
    },


    {
        field: 'address2',
        caption: 'Address 2',
        required: false,
        visible: true
    },



    {
        field: 'place',
        caption: 'Place',
        required: false,
        visible: true
    },

    {
        field: 'state',
        caption: 'State',
        required: false,
        visible: true
    },

    {
        field: 'country',
        caption: 'Country',
        required: false,
        visible: true
    },

    {
        field: 'city',
        caption: 'City/Town',
        required: false,
        visible: true
    },

    {
        field: 'landmark',
        caption: 'Landmark',
        required: false,
        visible: true
    },

    // Address configuration Ends 


    {
        field: 'department',
        caption: 'Department',
        required: false,
        visible: true
    },

    {
        field: 'doctor',
        caption: 'Doctor',
        required: false,
        visible: true
    },

    {
        field: 'remarks',
        caption: 'Remarks',
        required: false,
        visible: true
    },


    {
        field: 'reason',
        caption: 'Reason',
        required: false,
        visible: true
    },


    {
        field: 'category',
        caption: 'Category',
        required: false,
        visible: true
    },

    {
        field: 'corporate',
        caption: 'Is Corporate?',
        required: false,
        visible: true
    },

    {
        field: 'corporate_pointer',
        caption: 'Corporate',
        required: true,
        visible: true
    },
    {
        field: 'corporate_information',
        caption: 'Corporate Info',
        required: true,
        visible: true
    },

    {
        field: 'reference_details',
        caption: 'Reference Details',
        required: false,
        visible: true
    },


    {
        field: 'new_born_baby',
        caption: 'New Born Baby',
        required: false,
        visible: true
    },


    {
        field: 'ethnic_code',
        caption: 'Ethnic Code',
        required: false,
        visible: true
    },

    {
        field: 'educational_level',
        caption: 'Education Level',
        required: false,
        visible: true
    },


    {
        field: 'occupation',
        caption: 'Occupation',
        required: false,
        visible: true
    },

    {
        field: 'health_facility',
        caption: 'Health Facility',
        required: false,
        visible: true
    },

    {
        field: 'camp_site',
        caption: 'Referrer',
        required: false,
        visible: true
    },

    {
        field: 'date_of_screening',
        caption: 'Date of screening',
        required: false,
        visible: true
    },

    {
        field: 'relation',
        caption: 'Relation',
        required: false,
        visible: true
    },

    {
        field: 'relation_name',
        caption: 'Relation Name',
        required: false,
        visible: true
    },

    {
        field: 'relation_phone',
        caption: 'Relation Phone',
        required: false,
        visible: true
    },

    {
        field: 'relation_mobile',
        caption: 'Relation Mobile',
        required: false,
        visible: true
    },

    // {
    //     field: 'remarks',
    //     caption: 'Remarks',
    //     required: false,
    //     visible: true
    // },


    {
        field: 'passport_number',
        caption: 'Passport Number',
        required: false,
        visible: true
    },

    {
        field: 'referral_type',
        caption: 'Referral Type',
        required: false,
        visible: true
    },

    {
        field: 'referral_name',
        caption: 'Referral Name',
        required: false,
        visible: true
    },

    {
        field: 'national_id',
        caption: 'National ID',
        required: false,
        visible: true
    },

    {
        field: 'nature',
        caption: 'Nature',
        required: false,
        visible: true
    },
    {
        field: 'passport',
        caption: 'Passport',
        required: false,
        visible: true
    },
    {
        field: 'othernatid',
        caption: 'Emirates ID',
        required: false,
        visible: true
    },

    ],
    add_on_fields: []
}

export { formSettings };

export default function FormContainer(props) {

    const [loading, setLoading] = useState(true);

    const [preference, setPreference] = useState({});

   

    useEffect(() => {

        Preferences.getRecord(props.formPreferenceId).then((result) => {

            console.log(result);

            let data = JSON.parse(result.result.scope_value);

            setPreference(data);

            setLoading(false);
        });


    }, [])

    let preferences = {}

    return loading ? null : <RegistrationFormSettings preference={preference} id={props.formPreferenceId}/>
}

/**
 * 
 * @returns 
 */
function RegistrationFormSettings(props) {

    const { t, i18n } = useTranslation();   // To Translate to another language

    // Preferences array
    let preferences = [{
        name: t('Standard Registration'),
        value: 'standard'
    }];

    // Fields value for iteration
    const [preference, setPreference] = useState(() => {

        try {
            if (props.preference) {
                let savedPreferences = props.preference;

                if (savedPreferences) {

                    return savedPreferences;

                } else {

                    return formSettings;
                }
            } else {

                return formSettings

            }

            // if (localStorage.preferences) {

            //     // Form Settings
            //     let savedPreferences = JSON.parse(localStorage.preferences);

            //     if (savedPreferences) {

            //         return savedPreferences;

            //     } else {

            //         return formSettings;
            //     }
            // } else {

            //     return formSettings

            // }

        } catch {

        }
    });

    console.log(preference);

    // Selected value
    const [selected, setSelected] = useState(preferences[0].value);

    /**
     * On Switch
     * 
     * @param {*} value 
     * @param {*} field 
     */
    function onSwitchChange(value, index, key) {

        preference[key][index].required = value;

        setPreference({ ...preference });
    }

    /**
     * On Switch
     * 
     * @param {*} key The object in the preference that needs to be updated
     * @param {*} index The property of object that needs to be updated 
     * @param {*} value 
     */
    function onUpdateValue({ fieldKey, index, property, value }) {

        // preference['fields'][0][property] = value

        preference[fieldKey][index][property] = value;

        setPreference({ ...preference });
    }

    /**
     * On update of new field
     * 
     * @param {*} newField 
     */
    function onUpdate(newField) {

        preference.add_on_fields.push(newField);

        console.log(preference);

        setPreference({ ...preference });
    }

    /**
     * Save the preference
     */
    function savePreference() {

        console.log(preference);

        // Preferences.savePreference({ scope_value: preference }).then((result) => {
        //     console.log('here', result);
        // });

        
        // let formBody = {
        //     menu: 15,
        //     scope: 'testing',
        //     value: preference,
        //     status: 'y',
        // }

        let formBody = {
            value: preference,
        }


        Preferences.editPreference(props.id, formBody).then((result) => {
            console.log('here', result);
            message.success('Preferences has been updated');
        });

        // Preferences.editPreference(13,{ value: preference }).then((result) => {
        //     console.log('here', result);
        // });

        // localStorage.preferences = JSON.stringify(preference)
    }


    return (
        <div className="registration-form-settings">

            {/* <h3>Configure Registration Form</h3> */}

            <div className="page-content">

                <div className="left card card-shadow ">

                    <div className="page-header">

                        <div className="form">

                            <h4>{t('Preference')}</h4>

                            <Select value={selected} style={{ width: '100%' }}>

                                {preferences.map((preference) => <Option>{preference.name}</Option>)}

                            </Select>

                        </div>

                        <div className="actions">

                            <Button onClick={savePreference}>{t('Save')}</Button>

                        </div>
                    </div>

                    {/* Fields */}
                    <FieldList
                        fields={preference.fields}
                        onUpdateValue={onUpdateValue}
                    />
                    {/* Fields Ends */}

                    {/* Page Header */}
                    <div className="page-header">

                        <div className="left">
                            <h3>{t('Add On Fields')}</h3>
                            <small>{t('Add Custom Fields according to your requirement.')}</small>
                        </div>

                        <div className="actions">
                            <FieldCustomizer onFieldUpdate={onUpdate} />
                        </div>

                    </div>
                    {/* Page Header Ends */}

                    {/* Fields */}
                    <FieldList
                        fields={preference.add_on_fields}
                        onUpdateValue={onUpdateValue}
                        fieldKey="add_on_fields"
                    />
                    {/* Fields Ends */}

                </div>
                <div className="right card">

                    <h3>{t('Active Preference')}</h3>

                    <Select value={selected} style={{ width: '100%' }}>

                        {preferences.map((preference) => <Option>{preference.name}</Option>)}

                    </Select>

                </div>
            </div>
        </div>
    );
}

/**
 * 
 * @param {*} param0 
 * @returns 
 */
function FieldList({ fields = [], onUpdateValue, fieldKey = "fields" }) {

    const { t, i18n } = useTranslation();    // To Translate to another language

    return (
        <div className="field-list-container">
            <div className='fields-list-header'>

                <h4>
                    {t('Visible')}
                </h4>

                <h4>
                    {t('Required')}
                </h4>

            </div>

            <div className='fields-list'>

                {/* Field List Starts */}
                {
                    fields.map((field, index) => {

                        return (<div className="card field-element">

                            <h3>
                                {field.caption}
                            </h3>

                            {/* Field Actions */}
                            <div className="field-actions">

                                <FieldCustomizer
                                    caption={t("Edit")}
                                    field={field}
                                    onFieldUpdate={(values) => {

                                        onUpdateValue({
                                            fieldKey,
                                            index,
                                            property: 'caption',
                                            value: values.caption
                                        })
                                    }} />

                                <ScriptInput
                                    disableSave={true}
                                    defaultValue={field.onChangeScript}

                                    callback={(script) => {

                                        onUpdateValue({
                                            fieldKey,
                                            index,
                                            property: 'onChangeScript',
                                            value: script
                                        })
                                    }} />

                                <Switch
                                    checked={field.visible}
                                    placeholder={t("Visibility")}
                                    onChange={(value) => {

                                        onUpdateValue({
                                            fieldKey,
                                            index,
                                            property: 'visible',
                                            value: value
                                        })

                                    }} />

                                <Switch
                                    checked={field.required}
                                    placeholder={t("Required")}
                                    onChange={(value) => {

                                        onUpdateValue({
                                            fieldKey,
                                            index,
                                            property: 'required',
                                            value: value
                                        })

                                    }} />

                            </div>
                            {/* Field Actions Ends */}

                        </div>)
                    })
                }
                {/* Field List Ends */}

            </div>
        </div>
    )
}

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
};

/**
 * Customize the field to control its output , validity and more
 */
function FieldCustomizer({ caption = 'Add Field', field, onFieldUpdate }) {

    const [form] = Form.useForm();

    const [visible, setVisible] = useState(false);

    const { t, i18n } = useTranslation();   // To Translate to another language

    /**
     * On Submit of the form
     * 
     * @param {*} values 
     */
    const onFinish = (values) => {

        console.log(values);

        onFieldUpdate(values);

        setVisible(false);
    }

    /**
     * On edit of field
     */
    const onEdit = () => {
        setVisible(true);
    }


    return (<div className="field-customizer">

        <div className="actions">

            <Button className={"field-editor"} size="small" onClick={onEdit}>
                {/* <EditOutlined style={{ fontSize: 12 }} /> */}
                {caption}
            </Button>

            {/* <ConfirmModal callback={onDelete} caption={<DeleteOutlined style={{ fontSize: 12 }} />}>
            </ConfirmModal> */}

        </div>

        <Modal
            width={'50%'}
            header={null}
            destroyOnClose
            className=""
            visible={visible}
            title={'Configure Field'}
            footer={null}
            onCancel={() => { setVisible(false); }}>

            <Form
                name="form-configurator"
                form={form}
                {...layout}
                onFinish={onFinish}
                layout="vertical"

                initialValues={{
                    ...field
                    // ...formValues,
                    // required: true
                }}>

                <Form.Item label={t("Caption")} name="caption" required tooltip="Caption for the field">
                    <Input placeholder={t("Enter Caption")} />
                </Form.Item>

                {/* <Form.Item label="Required" name="required" required tooltip="Is this a mandatory field?">
                    <Switch placeholder="Select required" />
                </Form.Item> */}

                <Form.Item label={t("Field")} name="field" required tooltip="Is this a mandatory field?">
                    <Input placeholder={t("Enter field")} />
                </Form.Item>


                {/* <Form.Item label="On Change Listener" name="onChangeListener">

                    <ScriptInput
                        disableSave={true}
                        // defaultValue={field.onChangeScript}

                        callback={(script) => {

                            console.log(script);
                            // onUpdateValue({
                            //     key: 'fields',
                            //     index,
                            //     property: 'onChangeScript',
                            //     value: script
                            // })
                        }} />

                </Form.Item> */}

                {/* <Form.Item> */}
                <Button htmlType="submit" type="primary">{t("Submit")}</Button>
                {/* </Form.Item> */}

            </Form>
        </Modal>
    </div>)
}



