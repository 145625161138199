/***
 * @description
 * @author jinshakappil
 * Component Is usedd show Address Details of Guest
 *
 */

import React from 'react';

import { Row, Col, Form, Select, Typography } from 'antd';

import './address-details.scss';

import { InputComponent, useTranslation, ReferenceSelect } from 'soxo-bootstrap-core';
import { ListCountries } from '../../../../models';

const { Title } = Typography;

const formItemLayout = {
    layout: 'vertical',
};

let commonInputRules = [
    {
        pattern: /^(?:(?!').)*$/,
        message: "' is not allowed!",
    },
];

export function AddressDetails({ config, props,validation,initialValues }) {
    const { t, i18n } = useTranslation(); // To Translate to another language
    const { fields = {} } = config;

    function identifyDefaultCountry(options = []) {
        // Initial value for the default country 'lcnt_id'
        let initial = parseInt(process.env.REACT_APP_DEFAULT_COUNTRY) || 1;

        // Object to store the matching country record
        let matching = {};

        // Iterate through each record in the options array
        options.forEach((record) => {
            // Check if the 'lcnt_id' of the current record matches the initial value
            if (record['lcnt_id'] == initial) {
                // If a match is found, assign the current record to the matching object
                matching = record;
            }
        });

        // Return the matching country record, or an empty object if no match is found
        return matching;
    }

    return (
        <div className="address-details">
            <div className="details">
                {/* <Form 
                form={form}
                {...formItemLayout}> */}
                <div className="permanent-address">
                    <div>
                        <Title level={5}>{t('PERMANENT ADDRESS')}</Title>
                    </div>
                    <Row gutter={20}>
                        {/* Pin code */}
                        {fields.pincode.visible ? (
                            <Col xs={24} md={12} xl={6}>
                                <Form.Item
                                    name={fields.pincode.field}
                                    label={fields.pincode.caption}
                                    rules={[
                                        ...[
                                            {
                                                required: fields.pincode.required,
                                                message: `Please enter ${fields.pincode.caption}`,
                                            },
                                            {
                                                min: 5,
                                                message: t('Length should not be less than 5'),
                                            },
                                            {
                                                max: 10,
                                                message: t('Length cannot exceed 10 characters'),
                                            },
                                            {
                                                pattern: /^[0-9]*$/g,
                                                message: t('Please Enter Valid Pincode!'),
                                            },
                                        ],
                                        ...commonInputRules,
                                    ]}
                                    validateStatus={validation && validation.pincode.validate_status}
                                    help={validation && validation.pincode.help_text}
                                    // validateStatus={!initialValues.pincode && props.completeProfile ? 'warning' : ''}
                                    // help={!initialValues.pincode && props.completeProfile ? 'Please fill the details' : ''}
                                >
                                    <InputComponent disabled={props.disabled} maxLength={10} />
                                </Form.Item>
                            </Col>
                        ) : null}
                        {/* Pin code ends */}

                        {fields.address1.visible ? (
                            <Col xs={24} md={12} xl={6}>
                                <Form.Item
                                    name={fields.address1.field}
                                    label={fields.address1.caption}
                                    rules={[
                                        ...[
                                            {
                                                required: fields.address1.required,
                                                message: `Please Select ${fields.address1.caption}`,
                                            },

                                            {
                                                max: 100,
                                                message: 'Length cannot exceed 100 characters !',
                                            },
                                        ],
                                        ...commonInputRules,
                                    ]}
                                    validateStatus={validation && validation.address1.validate_status}
                                    help={validation && validation.address1.help_text}
                                    // validateStatus={!initialValues.address1 && props.completeProfile ? 'warning' : ''}
                                    // help={!initialValues.address1 && props.completeProfile ? 'Please fill the details' : ''}
                                >
                                    <InputComponent disabled={props.disabled} />
                                </Form.Item>
                            </Col>
                        ) : null}

                        {fields.address2.visible ? (
                            <Col xs={24} md={12} xl={6}>
                                <Form.Item
                                    name={fields.address2.field}
                                    label={fields.address2.caption}
                                    rules={[
                                        ...[
                                            {
                                                required: fields.address2.required,
                                                message: `Please enter ${fields.address2.caption}`,
                                            },

                                            {
                                                max: 200,
                                                message: t('Length cannot exceed 200 characters!'),
                                            },
                                        ],
                                        ...commonInputRules,
                                    ]}
                                    validateStatus={validation && validation.address2.validate_status}
                                    help={validation && validation.address2.help_text}
                                    // validateStatus={!initialValues.address2 && props.completeProfile ? 'warning' : ''}
                                    // help={!initialValues.address2 && props.completeProfile ? 'Please fill the details' : ''}
                                >
                                    <InputComponent disabled={props.disabled} />
                                </Form.Item>
                            </Col>
                        ) : null}

                        {fields.district.visible ? (
                            <Col xs={24} md={12} xl={6}>
                                <Form.Item
                                    name={fields.district.field}
                                    label={fields.district.caption}
                                    rules={[
                                        ...[
                                            {
                                                required: fields.district.required,
                                                message: `Please enter ${fields.district.caption}`,
                                            },
                                        ],
                                        ...commonInputRules,
                                    ]}
                                    validateStatus={validation && validation.district.validate_status}
                                    help={validation && validation.district.help_text}
                                    // validateStatus={!initialValues.district && props.completeProfile ? 'warning' : ''}
                                    // help={!initialValues.district && props.completeProfile ? 'Please fill the details' : ''}
                                >
                                    <InputComponent disabled={props.disabled} />
                                </Form.Item>
                            </Col>
                        ) : null}

                        {fields.place.caption ? (
                            <Col xs={24} md={12} xl={6}>
                                <Form.Item
                                    name={fields.place.field}
                                    label={fields.place.caption}
                                    rules={[
                                        ...[{ required: fields.place.required, message: `Please enter ${fields.place.caption}` }],
                                        ...commonInputRules,
                                    ]}
                                    validateStatus={validation && validation.place.validate_status}
                                    help={validation && validation.place.help_text}
                                    // validateStatus={!initialValues.place && props.completeProfile ? 'warning' : ''}
                                    // help={!initialValues.place && props.completeProfile ? 'Please fill the details' : ''}
                                >
                                    <InputComponent disabled={props.disabled} />
                                </Form.Item>
                            </Col>
                        ) : null}

                        {fields.state.visible ? (
                            <Col xs={24} md={12} xl={6}>
                                <Form.Item
                                    name={fields.state.field}
                                    label={fields.state.caption}
                                    rules={[
                                        ...[{ required: fields.state.required, message: `Please enter ${fields.state.caption}` }],
                                        ...commonInputRules
                                    ]}
                                    validateStatus={validation && validation.state.validate_status}
                                    help={validation && validation.state.help_text}
                                    // validateStatus={!initialValues.state && props.completeProfile ? 'warning' : ''}
                                    // help={!initialValues.state && props.completeProfile ? 'Please fill the details' : ''}
                                >
                                    <InputComponent disabled={props.disabled} />
                                </Form.Item>
                            </Col>
                        ) : null}

                        {fields.country.visible ? (
                            <Col xs={24} md={12} xl={6}>
                                <Form.Item
                                    name={fields.country.field}
                                    label={fields.country.caption}
                                    rules={[
                                        ...[{ required: fields.country.required, message: `Please enter ${fields.country.caption}` }],
                                        ...commonInputRules,
                                    ]}
                                    validateStatus={validation && validation.country.validate_status}
                                    help={validation && validation.country.help_text}
                                    // validateStatus={!initialValues.country && props.completeProfile ? 'warning' : ''}
                                    // help={!initialValues.country && props.completeProfile ? 'Please fill the details' : ''}
                                >
                                    <InputComponent disabled={props.disabled} />
                                </Form.Item>
                            </Col>
                        ) : null}
                            {/* {fields.country.visible ? (
                                <Col xs={24} md={12} xl={6}>
                                    <Form.Item
                                        name={fields.country.field}
                                        label={fields.country.caption}
                                        rules={[
                                            {
                                                required: fields.country.required,
                                                message: `Please enter ${fields.country.caption}`,
                                            },
                                        ]}
                                    >
                                        <ReferenceSelect
                                            label="lcnt_nationalitydesc"
                                            mode="nura-base"
                                            model={ListCountries}
                                            field={'lcnt_nationalitydesc'}
                                            defaultValueCondition={identifyDefaultCountry}//Default value condition for a component, using the identifyDefaultCountry function to determine the default country.
                                        />
                                    </Form.Item>
                                </Col>
                            ) : null}   */}

                        {fields.landmark.visible ? (
                            <Col xs={24} md={12} xl={6}>
                                <Form.Item
                                    name={fields.landmark.field}
                                    label={fields.landmark.caption}
                                    rules={[
                                        ...[{ required: fields.landmark.required, message: `Please enter ${fields.country.caption}` }],
                                        ...commonInputRules,
                                    ]}
                                    validateStatus={validation && validation.landmark.validate_status}
                                    help={validation && validation.landmark.help_text}
                                    // validateStatus={!initialValues.landmark && props.completeProfile ? 'warning' : ''}
                                    // help={!initialValues.landmark && props.completeProfile ? 'Please fill the details' : ''}
                                >
                                    <InputComponent disabled={props.disabled} />
                                </Form.Item>
                            </Col>
                        ) : null}
                    </Row>
                </div>

                {/* <div className="temporary-address">
                    <div>
                        <Title level={5}>TEMPORARY ADDRESS</Title>
                    </div>
                    <Row gutter={20}>
                        <Col xs={24} md={12} xl={6}>
                            <Form.Item
                                name="temporary_palika"
                                label="PALLIKA"
                                rules={[
                                    {
                                        required: fields.temporary_palika.required,
                                        message: 'Please Select Pallika',
                                    },
                                ]}
                            >
                                <InputComponent />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12} xl={6}>
                            <Form.Item
                                name="temporary_ward_no"
                                label="WARD NO"
                                rules={[
                                    {
                                        required: fields.temporary_ward_no.required,
                                        message: 'Please Select Ward Number',
                                    },
                                ]}
                            >
                                <InputComponent />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12} xl={6}>
                            <Form.Item
                                name="temporary_tole"
                                label="TOLE"
                                rules={[{ required: fields.temporary_tole.required, message: 'Please Select Tole' }]}>
                                <InputComponent />
                            </Form.Item>
                        </Col>
                    </Row>
                </div> */}
                {/* </Form> */}
            </div>
        </div>
    );
}
