/**
 * Component to Show ScrFile Links details
 *
 *
 * @author Sneha
 */

import React, { useEffect, useState, useContext } from 'react';

import { SCRFilesLink, Doctors, Bills, DocDetails } from '../../../../models';

import { message, Avatar, Typography, Row, Skeleton, Button, Modal, Form, Tabs, Card, Popconfirm } from 'antd';

import { ApiUtils, DateUtils, Location, GlobalContext, useTranslation } from 'soxo-bootstrap-core';

import FileLoader from './../../../../components/file-loader/file-loader';

import { UserOutlined, DeleteOutlined } from '@ant-design/icons';

import axios from 'axios';

import './report-links-viewer.scss';

const pako = require('pako');

const { TabPane } = Tabs;

/**
 *
 * @returns
 */
export default function ReportLinksView({
    reportConfig = {
        baseUrl: 'http://localhost:3800/',
    },
    ...props
}) {

    var params = Location.search();

    var { opb_id } = props.match.params;

    const id = params.opb_id;

    const links = props.links || [];

    let consultation = params.consultation;

    let { enableConsultation } = props;

    //Get logged user data
    const { user, isMobile } = useContext(GlobalContext);

    const [selected, setSelected] = useState({
        reports: [],
    });

    const [isDoctor, setDoctor] = useState(false);

    const [loading, setLoading] = useState(false);

    const { Title } = Typography;

    //Variable for upload Modal
    const [uploadVisible, setUploadVisible] = useState(false);

    //Variable for upload Modal
    const [reportVisible, setReportVisible] = useState(false);

    // variable for final report details
    const [finalReport, setFinalReport] = useState();

    const [blob, setBlob] = useState();

    const [reportBlob, setReportBlob] = useState();

    const [file, setFile] = useState();

    const { t, i18n } = useTranslation();    // To Translate to another language

    const [patient, setPatient] = useState({
        scrReportmas: [],

        opReg: {},
    }); //Patiants list array

    const [bill, setBillDetails] = useState({
        opb_bno: null,

        opb_id: null,
    }); //Bill list object

    useEffect(() => {
        getReports();

        // Get final report file name
        // getFinalReport();
        //Check the logged use is doctor or not
        if (user.staff_id) {
            getDoctorDeatils(user.staff_id);
        }
    }, []);

    /**
     * Getting reports
     *
     */

    function getReports() {
        //Load report with accession no
        SCRFilesLink.getReports(params.opb_id, params.item_ptr).then((result) => {
            setPatient(result.data);

            if (result.data.scrReportmas[0]) {
                const availableReport = result.data.scrReportmas.filter((ele) => ele.report_available === true);
                // The first report is selected here

                setSelected(availableReport[0]);

                // Set bill details
                // setBillDetails({
                //     opb_bno: result.data.opBill.opb_bno,

                //     opb_id: result.data.opBill.opb_id,
                // });
            }
        });
    }

    // Get doctor details to check logged user is doctor or not
    function getDoctorDeatils(id) {
        Doctors.getDoctorDeatils(id).then((result) => {
            if (result) {
                setDoctor(true);
            }
        });
    }

    // Get Final report Details
    // This function returns the final report file name which is used to load the file
    async function getFinalReport() {
        await Bills.getFinalReport(id).then((result) => {
            setFinalReport({ ...result });
        });
    }

    /**
     *
     * @param {*} data
     *  Load report and send its url to vr
     *
     */
    function loadReport(data) {
        // let report = data.reports[data.reports.length - 1];

        // let reportUrl = process.env.REACT_APP_FILELINK + report.report_filename;

        let imagePath = data.report_links.filter((record) => {
            return record.sflink_type === 'RPT';
        })[0];

        let url =
            `${process.env.REACT_APP_REPORT_LINK}` + `/files/read-file-pdf?type=RPT&file=${encodeURIComponent(imagePath[0].sflink_filename.trim())}`;

        // let reportUrl = report.report_filename

        // let reportData = {
        //     report_url: reportUrl,
        // };

        let reportData = {
            // report_url: fileURL,
            report_remote_path: url,
            report_remote_token: localStorage.access_token,
        };

        console.log('Report Data', reportData);

        sendMessageToCSharp(reportData);
    }

    /**
     *
     * Start the consultation
     */
    function onStartConsultation() {
        // The window.vuplex object gets created when the page starts loading,
        // so we double-check that it exists before using it here.
        // You can skip this step if you're sending a message after the page has loaded.

        let message = {
            url: window.location.href + '&consultation=true',
            op_no: id,
            branch_name: user.firm.f_desc,
            isDoctor: isDoctor,
            room: patient.bill_id + '-' + user.firm.f_code + '-room',
            display_name: patient.op_reg.op_fname + ' ' + patient.op_reg.op_lname + '-' + patient.bill_no + '-' + user.firm.f_desc,
        };

        // console.log(message);

        if (window.vuplex) {
            // The window.vuplex object already exists, so go ahead and send the message.
            sendMessageToCSharp(message);
        } else {
            // The window.vuplex object hasn't been initialized yet because the page is still
            // loading, so add an event listener to send the message once it's initialized.

            window.addEventListener('vuplexready', sendMessageToCSharp);
        }
    }

    /**
     * Send Message to VR .
     */
    function sendMessageToCSharp(message) {
        // This object passed to postMessage() automatically gets serialized as JSON
        // and is emitted via the C# MessageEmitted event. This API mimics the window.postMessage API.

        if (window.vuplex) {
            window.vuplex.postMessage(message);
        }
    }

    /**
     * Report Preview Modal
     *
     */

    function PreviewReportModal(selected) {
        return <div>{/* <PdfViewer url={selected.selected} /> */}</div>;
    }

    /**
     * delete uploaded file
     *
     */
    async function deleteFile(ele) {
        console.log('here', ele);
        message.loading('Deleting digital report');
        const report = await SCRFilesLink.deleteReport(ele.sflink_id);
        setSelected({
            reports: [],
        });
        // message.success('Report is Deleted');
        getReports();
    }

    /**
     *
     */
    function reportPreview(selected) {
        let location = `${process.env.REACT_APP_REPORT_PATH}/${selected.sflink_type}/` + selected.sflink_filename;

        switch (selected.sflink_type) {
            case 'RAW':
                // return <LoadPDFImg report={selected} />;

                // return (
                //     <a target="_blank" rel="noreferrer" href={selected.report_filename} className={`report-link vehicle-card card`}>
                //         <h1>PACS</h1>
                //         View PACS Image
                //     </a>
                // );
                return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Popconfirm
                            title={t("Are you sure you want to delete the report ? ")}
                            onConfirm={() => deleteFile(selected)}
                            onCancel={() => { }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="secondary" size={'small'} style={{ width: '15%', alignSelf: 'end', marginBottom: '15px' }}>
                                <DeleteOutlined /> {t('Delete')}
                            </Button>
                        </Popconfirm>
                        <FileLoader url={location} type={selected.sflink_type} />;
                    </div>
                );

                break;

            case 'RPT':
                return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Popconfirm
                            title={t("Are you sure you want to delete the report ? ")}
                            onConfirm={() => deleteFile(selected)}
                            onCancel={() => { }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="secondary" size={'small'} style={{ width: '15%', alignSelf: 'end', marginBottom: '15px' }}>
                                <DeleteOutlined /> {t('Delete')}
                            </Button>
                        </Popconfirm>
                        <FileLoader url={location} type={selected.sflink_type} />;
                    </div>
                );

                break;

            case 'IMG':
                //  return <LoadPDFImg report={selected} />;
                return (
                    <a target="_blank" rel="noreferrer" href={selected.sflink_filename} className={`report-link vehicle-card card`}>
                        <h1>{t("PACS")}</h1>
                        {t("View PACS Image")}
                    </a>
                );
                break;
        }
    }

    let { opReg = {} } = patient;
    opReg = opReg.opReg;
    let { opbilld = {} } = patient;

    /**
     * Handle Publish Report to open medicant.
     */

    const ReprtPublishfinalReport = () => {

        // Extract the 'publish_report' object from props and store it in 'values'
        let values = props.publish_report;
        // Construct the 'parameter' string by concatenating various values from 'values', 'user.name'
        let parameter =
            values.mode + ',' + values.submode + ',' + values.passcode + ',' + values.passvalue + ',' + user.name + '~' + patient.opReg.opb_opno;
        // Make the POST request to the server
        fetch('http://localhost:3800/report/report-publish', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                parameter,
            }),
        })
            .then((response) => {
                // Check if the response status is 200 (success)
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json(); // Parse the JSON data from the response
            })

            .catch((error) => {//Handle any errors that occur during the request
                console.error('Error:', error);
            });
    };


    /**
     * Handle Publish Report to open medicant
     */
    const publishReport = () => {
        // Make the POST request to the server to trigger the print
        fetch('http://localhost:3000/trigger-print', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                // Pass any data you want to send to the server here
                // ID,
                // patientname,
                // opno: guest.op_no
            }),
        })
            .then((response) => {
                // Check if the response status is 200 (success)
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json(); // Parse the JSON data from the response
            })
            .then((data) => {
                // console.log('Server response:', data); // Log the response data
                // setCurrentState(states.continue); // Move to the 'continue' state after receiving the response
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    //Upload Modal
    function uploadModal() {
        setUploadVisible(true);
    }

    //Report Modal
    function reportModal() {
        setReportVisible(true);
    }

    const onChange = (key) => {
        console.log(key);
    };

    /**
     * The render part to display current status
     * of final report at each point
     *
     * @param {*} finalReport
     */
    function finalReportStatus(finalReport) {
        if (finalReport && finalReport.report_present) {
            return '';
        } else if (finalReport && !finalReport.report_present) {
            return ' - Not Available';
        } else {
            return ' - Report Available ';
        }
    }

    /**
     *
     * @param {*} data
     *  Load report and send its url to vr
     *
     */

    const loadPDF = async (reportLink) => {
        setSelected(reportLink);

        let imagePath = reportLink.reports.filter((record) => {
            return record.report_type === 'RPT';
        })[0];

        let url =
            `${process.env.REACT_APP_REPORT_LINK}` + `/files/read-file-pdf?type=RPT&file=${encodeURIComponent(imagePath.report_filename.trim())}`;

        let payload = {
            method: 'GET',
            responseType: 'blob',
            // mode:'no-cors',
            headers: {
                // mode:'no-cors',
                // db_ptr:'nura',
                // db_ptr: 'nuramho',
                db_ptr: process.env.REACT_APP_DB_PTR,
                // db_ptr: process.env.REACT_DB_PTR,
                Authorization: 'Bearer ' + localStorage.access_token,
            },
            // body: formBody ? JSON.stringify(formBody) : null,
        };

        if (imagePath.report_filename) {
            await axios
                .get(`${url}`, payload)

                .then((res) => {
                    var report = new Blob([res.data], {
                        type: 'application/pdf',
                    });

                    setBlob(res.data);

                    setReportBlob(report);

                    let fileURL = URL.createObjectURL(report);

                    console.log('fileurllllll11', fileURL);

                    setFile(fileURL);

                    let reportData = {
                        report_url: fileURL,
                        report_remote_path: url,
                        report_remote_token: localStorage.access_token,
                    };

                    console.log(reportData);

                    sendMessageToCSharp(reportData);
                    //
                    // window.location = fileURL;

                    window.open(fileURL);
                })
                .catch(() => ({
                    error: true,

                    data: null,
                }));
        }
    };

    return (
        <>
            {loading ? (
                <Skeleton />
            ) : (
                <>
                    <div className="report-links-viewer">
                        {/* <div className='title'>
                            <Title className='heading' level={7} style={{ fontSize: '24px' }}>
                                Reports
                            </Title>
                        </div> */}

                        <div className="page-header">
                            {/* {opbilld && opbilld[0] && ['NMGS', 'NWGS'].indexOf(opbilld[0]) !== -1 ? */}

                            {/* : null} */}

                            <div className="page"></div>

                            <div className="actions">
                                {/* {links.map((link) => {

                                    return <a href={link.path}><Button type="link">{link.caption}</Button></a>
                                    })} */}

                                {/* <a href="https://github.com/"><Button type="link">Github</Button></a>

                                    <a href="https://www.netflix.com/in/"><Button type="link">Netflix</Button></a>
                                    */}

                                {enableConsultation ? (
                                    <>{consultation != 'true' ? <Button onClick={onStartConsultation}>{t('Start Consultation')}</Button> : null}</>
                                ) : null}

                                <div className="publish-report">
                                    <div className="report">
                                        <Button size='small' type="primary" onClick={ReprtPublishfinalReport}>{t('Create Final Report')}</Button>
                                    </div>
                                    {/* <div className='report'>
                                        <Button onClick={publishReport} >Generate Report</Button>
                                    </div> */}
                                    <div className="report">
                                        <Button size='small' onClick={uploadModal}>{t('Upload')}</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="details-section">
                            <div className="left-section">
                                <div className="left-top">
                                    <div className="card card-shadow">
                                        <div className="visit-info">
                                            {/* <Title level={7} style={{ fontSize: '24px' }}>
                                                Guest Information
                                            </Title> */}

                                            <div className="first-row">
                                                <Avatar shape="circle" size={50} icon={<UserOutlined />} />

                                                <div className="name-section">
                                                    <Row>
                                                        <label className="label-class">
                                                            <span className="details">{t("NAME")}</span>
                                                        </label>
                                                    </Row>

                                                    <Row style={{ fontSize: '20px' }}>
                                                        <span>
                                                            <h5>
                                                                <strong>{opReg ? opReg.op_fname + ' ' + opReg.op_lname : null}</strong>
                                                            </h5>
                                                        </span>
                                                    </Row>
                                                </div>
                                            </div>

                                            {/** patient personal details section start */}
                                            <div className="second-row">
                                                <div className="detail-element" style={{ marginRight: '2rem' }}>
                                                    <Row>
                                                        <label className="label-class">
                                                            <span className="details">{t("DOB")}</span>
                                                        </label>
                                                    </Row>

                                                    <Row className="row-class">
                                                        <span>{opReg && opReg.op_dob ? DateUtils.formatDate(opReg.op_dob) : null}</span>
                                                    </Row>
                                                </div>

                                                {/* </div> */}

                                                <div className="detail-element" style={{ paddingRight: '2rem' }}>
                                                    <Row>
                                                        <label className="label-class">
                                                            <span className="details">{t("PHONE")}</span>
                                                        </label>
                                                    </Row>

                                                    <Row className="row-class">
                                                        <span>{opReg ? opReg.op_phone : null}</span>
                                                    </Row>
                                                </div>

                                                <div className="detail-element" style={{ paddingRight: '2rem' }}>
                                                    <Row>
                                                        <label className="label-class">
                                                            <span className="details">{t("GENDER")}</span>
                                                        </label>
                                                    </Row>

                                                    <Row className="row-class">
                                                        <span>{opReg ? opReg.op_gender : null}</span>
                                                    </Row>
                                                </div>

                                                <div className="detail-element" style={{ marginRight: '2rem' }}>
                                                    <Row>
                                                        <label className="label-class">
                                                            <span className="details">{t("PLACE")}</span>
                                                        </label>
                                                    </Row>

                                                    <Row className="row-class">
                                                        <span>{opReg ? opReg.op_place : null}</span>
                                                    </Row>
                                                </div>
                                                {/* </div> */}

                                                <div className="detail-element" style={{ paddingRight: '2rem' }}>
                                                    <Row>
                                                        <label className="label-class">
                                                            <span className="details">{t("EMAIL")}</span>
                                                        </label>
                                                    </Row>

                                                    <Row className="row-class">
                                                        <span>{opReg ? opReg.op_email : null}</span>
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card card-shadow">
                                    <div className="head">
                                        <Title level={7} style={{ fontSize: '16px' }}>
                                            {t("REPORTS")}
                                        </Title>
                                    </div>
                                    <div className="left-bottom">
                                        {/**report_present varible tells us if the final report is ready, Using that we control final report  view */}
                                        {/* {finalReport && finalReport.report_present ? */}

                                        <div
                                            className={`test-name card card-shadow ${selected && selected.item_ptr && finalReport && finalReport.item_ptr
                                                ? selected.item_ptr === finalReport.item_ptr
                                                    ? 'active'
                                                    : ''
                                                : ''
                                                }`}
                                            onClick={() => {
                                                if (finalReport && finalReport.report_present) {
                                                    loadReport(finalReport);
                                                }
                                            }}
                                            style={{ height: '64px' }}
                                        >
                                            <p> {t("Final Report")} {finalReportStatus(finalReport)}</p>
                                        </div>
                                        {/* :
                                            finalReport && !finalReport.report_present ?
                                                <Card
                                                    style={{ height: '64px' }}
                                                    className={`card-inactive `}

                                                >
                                                    <p>Final Report  - Not Available</p>
                                                </Card>
                                                :
                                                <Card
                                                    style={{ height: '64px' }}
                                                    className={`card-inactive `}

                                                >
                                                    <p>Final Report  - <span style={{ color: 'grey' }}>Loading Report </span></p>
                                                </Card>
                                        } */}

                                        {patient &&
                                            patient.scrReportmas &&
                                            patient.scrReportmas.map((result, index) => {
                                                return (
                                                    <div
                                                        className={`test-name card card-shadow ${result.report_available
                                                            ? selected &&
                                                                selected.opbilld &&
                                                                selected.opbilld[0].opbd_itemptr === result.opbilld[0].opbd_itemptr
                                                                ? 'active'
                                                                : ''
                                                            : 'notactive'
                                                            }`}
                                                        onClick={() => {
                                                            // # Note  We have to implement a prop to control how the report needs to be displayed
                                                            // We have to call this function for the meta viewer
                                                            // reportPreview(result);

                                                            setSelected(result);

                                                            // setReportVisible(true)

                                                            // We have to call the function for normal reports
                                                            // loadPDF(result)
                                                        }}
                                                    >
                                                        {index + 1}.{result.scrReportMas.srepmd_desc}-{' '}
                                                        <small>{result.scrReportMas.srepmd_filetype}</small>
                                                        <br />
                                                        {result.report_available ? null : (
                                                            <small style={{ color: 'red' }}>{t('Report Not Available')}</small>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            </div>
                            {/* Right Section */}
                            <div className="card card-shadow right-section">
                                <div className="tab-section">
                                    <Tabs destroyInactiveTabPane={true}>
                                        {selected && selected.report_links
                                            ? selected.report_links.map((report, index) => {
                                                return report.sflink_filename ? (
                                                    <TabPane tab={`${report.sflink_type}`} key={report.sflink_id}>
                                                        {/* <div>{reportPreview(report)}</div> */}

                                                        <ReportPreviewFileLoader config={reportConfig} selected={{
                                                            report_type: 'RPT',
                                                            report_path: report.sflink_filename,

                                                        }} />

                                                    </TabPane>
                                                ) : null;
                                            })
                                            : null}
                                    </Tabs>
                                </div>
                            </div>
                            {/* Right Section Ends */}
                        </div>
                        <Modal
                            className="fullscreen-modal"
                            destroyOnClose={true}
                            footer={null}
                            title={t("Report")}
                            visible={reportVisible}
                            okText="Okay"
                            onOk={() => {
                                setReportVisible(false);
                            }}
                            onCancel={() => {
                                setReportVisible(false);
                            }}
                        >
                            <PreviewReportModal selected={file} />
                        </Modal>
                        <Modal
                            destroyOnClose={true}
                            footer={null}
                            title={t("Upload")}
                            visible={uploadVisible}
                            okText="Okay"
                            onOk={() => {
                                setUploadVisible(false);
                            }}
                            onCancel={() => {
                                setUploadVisible(false);
                            }}
                        >
                            <UploadFile setVisible={setUploadVisible} accession_no={params.accessionno} getReports={getReports} />
                        </Modal>
                    </div>
                </>
            )}
        </>
    );
}

// }

//Component to uploadFiles
function UploadFile({ setVisible, accession_no, getReports }) {
    const { Title } = Typography;
    const [loading, setLoading] = useState(false);

    const { t, i18n } = useTranslation(); // To Translate to another language


    var [reportPath, setReportPath] = useState();

    var [rawReportData, setRawReportData] = useState();

    //Onsumbit of the modal, both files are send to backend

    async function submit() {
        setLoading(true);

        var formData = new FormData();

        var arr = [];

        // Api params for two api calls for upload
        let apiParams = [];

        // Api params for the pdf file
        apiParams.push({
            file_type: 'File',
            accession_no: accession_no,
            opb_bno: '',
            type: 'RPT',
            file: reportPath,
        });

        // Api params for the raw file
        apiParams.push({
            file_type: 'File',
            accession_no: accession_no,
            opb_bno: '',
            type: 'RAW',
            file: rawReportData,
        });

        // await this.uploadSelectedFiles();
        for (const payload of apiParams) {
            // console.log("PAyload",payload)
            // apiParams.map(async(ele)=>{
            await uploadFile(payload, accession_no);

            // })
        }

        setLoading(false);

        // getReports();
    }

    /**
     * Uploads the file to on premise and calls the
     * nura api to create records in scr file links
     *
     *
     * @param {*} payload
     */
    async function uploadFile(payload, accession_no) {
        let formData = new FormData();
        formData.append('file_type', payload.file_type);
        formData.append('accession_no', payload.accession_no);
        formData.append('type', payload.type);
        formData.append('reportPath', payload.file, {
            filename: `${payload.file}.pdf`,
            contentType: 'application/pdf',
        });
        // formData.append('rawReportData', payload.file, {
        //     filename: `${payload.file}.pdf`,
        //     contentType: 'application/pdf'
        // });

        // console.log('PF', payload.file);
        // console.log('PFkkkkkkk', formData);

        //Files are uploaded and path saved to scrfilelink
        const result = await SCRFilesLink.uploadReport(formData, accession_no);

        getReports();

        // console.log('here 10: ', result);

        if (result && result.status) {
            setVisible(false);
            message.success('Files uploaded successfully');
        } else {
            message.error('Please check the files');
        }
    }

    // On choosing report path file
    function handleReportPath(e) {
        // console.log("handleRepprtpath",e.target.files[0])
        let files = e.target.files[0];

        setReportPath(files);
    }

    //On choosing rawreport data file
    function handleRawRepotData(e) {
        let files = e.target.files[0];

        setRawReportData(files);
    }

    return (
        <div className="card card-shadow">
            <Form onFinish={submit}>
                <>
                    <div>
                        <form id="myform">
                            <br />
                            <Title level={5}>{t('Report Path')}</Title>
                            <label>{t('Select File')}</label>
                            <br />

                            <input type="file" name="reportPath" onChange={(e) => handleReportPath(e)} />
                            <br />
                            <br />
                            <Title level={5}>{t('Raw Report Data')}</Title>

                            <label>{t('Select File')}</label>
                            <br />

                            <input type="file" name="rawReportData" onChange={(e) => handleRawRepotData(e)} />
                            <br />
                            <br />
                        </form>
                    </div>

                    <div></div>
                    <br />
                </>

                <Button loading={loading} htmlType="submit">
                    {t('Submit')}
                </Button>
            </Form>
        </div>
    );
}




/**
 *
 * @param {*} value
 * @returns
 */
export function ReportPreviewFileLoader({ config, selected }) {
    console.log(selected);

    let fileName;

    if (selected.report_type === 'IMG') {
        fileName = selected.pacs_path;
    } else {
        fileName = selected.report_path;
    }

    let location = `${fileName}`;

    /**
     * In case we have different type of components that needs to be rendered
     *
     * @param {*} ele
     * @returns
     */
    const renderFile = ele => {
        switch (ele.report_type) {
            case 'IMG':
                // return <LoadPDFImg report={selected} />;
                // return (
                //     <a target="_blank" rel="noreferrer" href={selected.report_filename} className={`report-link vehicle-card card`}>
                //         <h1>PACS</h1>
                //         View PACS Image
                //     </a>
                // );
                return <FileLoader config={config} url={location} type={ele.report_type} />;
                break;
            case 'RPT':
                return <FileLoader config={config} url={location} type={ele.report_type} />;
                break;
            default:
                return <FileLoader config={config} url={location} type={ele.report_type} />;
        }
    };

    return <>{renderFile(selected)}</>;
}
