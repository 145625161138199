/***
 * Guest information component in Bill-I
 * @description
 * @author Sharafudheen
 */

import React, { useState } from 'react';
import { Form, Button, Col, Row, Input, message } from 'antd';

import { Bills } from '../../../../../../models';
import { useTranslation} from 'soxo-bootstrap-core';

function CouponModal({ callback,itemCode, setCouponVisible, subTotal }) {

    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const [coupon, setCoupon] = useState();

    const { t, i18n } = useTranslation(); // To Translate to another language


    /**
     * Check whether the applied coupon is valid and also does the amount exceed total
     * Onfinish set coupon and deduct the applied coupon ammount
     * @param {*} event
     */

    //

    const onFinish = async (values) => {
        setLoading(true);

        var value = values.coupon;

        if (value) {
            let formBody = {
                coupon_no: value,
                item:itemCode
            }
            Bills.validatecoupon(formBody)
                .then((result) => {
                    let couponAmount
                    if (result.success) {
                        // If coupon type is percentage
                        if (result.data.value_type === 'P')
                            couponAmount = subTotal * (result.data.coupon_value / 100)
                        // If coupon type is amount
                        else if (result.data.value_type === 'A')
                            couponAmount = result.data.coupon_value
                        result.data = {
                            ...result.data,
                            coupon_amount: couponAmount
                        }
                        setCoupon(result);

                        //Check whether the coupon amount is greater than the net total

                        if (result.data.coupon_amount > subTotal) {
                            message.error('Please make sure the Coupon amount do not exceed the net Amount');

                            setLoading(false);

                            // setCouponVisible(false)

                            return false;
                        }

                        setLoading(false);

                        callback(result.data);
                    } else {
                        setLoading(false);

                        message.error('Invalid Coupon');
                    }
                })
                .catch((error) => {
                    console.log(error);

                    setLoading(false);

                    message.warning('The Entered Coupon is an Invalid Coupon. Please Enter a Valid Coupon');
                });
        }
    };

    return (
        <>
            <p>{t('Enter the Coupon Details')}</p>

            <Form
                form={form}
                layout="vertical"
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                rules={[{ required: true, message: 'Please Enter the Coupon Number' }]}
            >
                <Row gutter={20}>
                    <Col xs={24} md={24} xl={24}>
                        <Form.Item
                            label={t("Coupon")}
                            name="coupon"
                            rules={[{ required: true, message: 'Please Enter Coupon' }]}
                        // onChange={validateCoupon}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item>
                    <Button loading={loading} type="primary" htmlType="submit">
                        {t("Save")}
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}

export default CouponModal;