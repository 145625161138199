/***
 *
 * Vital Information
 *
 * @description
 * @author Sneha
 */

import React, { useEffect, useState, useContext } from 'react';

import { Clinicals } from '../../../../models';

import { InputComponent, useTranslation } from 'soxo-bootstrap-core';

import { Opreg } from './../../../../models/';

import './vital-information.scss';

import { Card, message, Form, Input, Button, Skeleton, Typography, Row } from 'antd';

import ReadBloodPressure from '../read-bloodpressure/read-bloodpressure';

import RecordHeightandWeight from '../record-height-weight/record-height-weight';

export default function VitalInformation({
    bill,
    formToShow, // New prop to determine which form to show
    visit_id,
    information,
    callback,
    refrence_number,
    refrence_id,
    module,

    attributes
}) {

    const [guest, setGuest] = useState({});

    const [formDisabled, setFormDisabled] = useState(false);

    const [loading, setLoading] = useState(false)

    const { t, i18n } = useTranslation(); // To Translate to another language


    const [form] = Form.useForm();

    useEffect(() => {
        // Loading the guest details for the Clinical Component
        loadGuestDetails();
    }, []);

    /**
     * Load the guest information
     */
    const loadGuestDetails = async () => {
        const response = await Opreg.loadGuestDetails(refrence_number);

        setGuest(response.result);
    };

    /**
     * on cancel
     */

    function onCancel() {
        callback();
    }

    /**
     * On submit of form
     *
     * @param {*} event
     */
    async function onSubmit(values) {
        
        setLoading(true)

        let body = {
            ...values,
            module: module,
            refrence_number: refrence_number,
            id: refrence_id,
        };

        if (formToShow === 'form1') {

            // Append the body to the details
            body = {
                ...body,
                height: values.srvbcd_height ? parseInt(values.srvbcd_height) : null,
                weight: values.srvbcd_weight ? parseFloat(values.srvbcd_weight) : null,

            }

        } else {

            body = {
                ...body,
                pulse: values.srvbcd_pulse ? parseInt(values.srvbcd_pulse) : null,
                bp_systolic: values.srvbcd_bpsys ? parseInt(values.srvbcd_bpsys) : null,
                bp_diastolic: values.srvbcd_bpdia ? parseInt(values.srvbcd_bpdia) : null,

            }

        }

        //In case of edit
        if (information.srvbcd_id) {
            await Clinicals.createVitalInformation(body)
                .then((result) => {
                    if (result.success === false) {
                        message.error('Updation failed!');
                        setLoading(false)
                    } else {
                        message.success('Details updated successfully!');
                        setLoading(false)

                        callback();
                    }
                })
                .catch((e) => {
                    message.error('Updation failed!');
                    setLoading(false)

                });
        } else {
            //When entering new case
            await Clinicals.createVitalInformation(body)
                .then((result) => {
                    if (result.success === false) {
                        message.error('Failed!');
                        setLoading(false)

                    } else {
                        message.success('Details added successfully!');
                        setLoading(false)

                        callback();
                    }
                })
                .catch((e) => {
                    message.error('Failed!');
                    setLoading(false)

                });
        }
    }

    function onFieldsChange(fields, values) {
        let field = fields[0];
        if (field && field.value) {
            if (field.name == 'srvbcd_height' || field.name == 'srvbcd_weight') {
                // console.log("", values[0].value);

                if (values[0].value && values[1].value) {
                    var bmi = (values[1].value / (values[0].value * values[0].value)) * 10000;

                    values[2].value = Math.round(bmi * 100) / 100;

                    values[2] = values[2].value;

                    form.setFieldsValue({ srvbcd_bmi: values[2] });
                } else {
                    values[2].value = null;
                }
            }
        }
    }

    return (
        <div className="vital-information">
            {/* <p className="size-hint">{loading ? 'Loading data' : 'Refer below information'}</p> */}
            {/* <div className="guest-detail">
                <div>
                    Name: <b>{guest?.first_name + ' ' + guest?.last_name}</b>
                </div>
                <div>
                    OP No: <b>{guest?.op_number}</b>
                </div>
            </div> */}

            {/* Form for collecting Height/Weight Information */}
            {formToShow === 'form1' ? (

                <div className="form-step">

                    <Form
                        className="clinical-form"
                        form={form}
                        initialValues={{
                            ...information,
                        }}
                        disabled={formDisabled}
                        layout="vertical"
                        onFinish={onSubmit}
                        onFieldsChange={onFieldsChange}
                    >
                        <p>{t('Enter below details')}</p>

                        <Row className="inline-form">
                            <Form.Item
                                name={'srvbcd_height'}
                                label={t("Height (In cm)")}
                                rules={[
                                    {
                                        required: true,
                                        message: t('Please Enter Height !'),
                                    },
                                    {
                                        pattern: /^\d{1,3}(\.\d{1,2})?$/,
                                        message: t('Please Enter Valid Height !'),
                                    },
                                    // {
                                    //     max: 3,
                                    //     message: 'Length cannot exceed 3 characters !',
                                    // },
                                ]}
                            >
                                <InputComponent />
                            </Form.Item>

                            <Form.Item
                                name={'srvbcd_weight'}
                                label={t("Weight (In kg)")}
                                rules={[
                                    {
                                        required: true,
                                        message: t('Please Enter Weight'),
                                    },
                                    {
                                        pattern: /^\d{1,3}(\.\d{1,2})?$/,
                                        message: t('Please Enter Valid Weight !'),
                                    },
                                ]}
                            >
                                <InputComponent />
                            </Form.Item>
                        </Row>

                        <Form.Item>
                            {
                                guest?.id
                                &&
                                <RecordHeightandWeight
                                    bill={bill}
                                    guest={guest}
                                    form={form}
                                    setFormDisabled={setFormDisabled}

                                    attributes={attributes}

                                />
                            }
                        </Form.Item>

                        <Form.Item disabled name={'srvbcd_bmi'} label="BMI">
                            <InputComponent readOnly={true} />
                        </Form.Item>

                       
                        <div className="footer-actions">
                            <Button htmlType="submit" type="primary" loading={loading}>
                                {t('Save')}
                            </Button>

                            <Button onClick={onCancel}>{t('Cancel')}</Button>
                        </div>
                    </Form>

                    {/* Form for collecting Height/Weight Information Ends */}
                </div>
            ) : (
                <div className="form-step">


                    <Form
                        className="clinical-form"
                        form={form}
                        initialValues={{
                            ...information,
                        }}
                        disabled={formDisabled}
                        layout="vertical"
                        onFinish={onSubmit}
                        onFieldsChange={onFieldsChange}
                    >
                        <p>{t('Enter below details')}</p>


                        <Row>
                            <Form.Item
                                name={'srvbcd_bpsys'}
                                label={t("Blood Pressure (Systolic)")}
                                rules={[
                                    {
                                        required: true,
                                        message: t('Please Enter Systolic Blood Pressure'),
                                    },
                                    {
                                        pattern: /^[0-9]*$/,
                                        message: t('Please Enter Valid Systolic Blood Pressure !'),
                                    },
                                ]}
                            >
                                <InputComponent maxLength={3} />
                            </Form.Item>

                            <Form.Item
                                name={'srvbcd_bpdia'}
                                label={t("Blood Pressure (Diastolic)")}
                                rules={[
                                    { required: true, message: t('Please Enter Diastolic Blood Pressure') },
                                    {
                                        pattern: /^[0-9]*$/,
                                        message: t('Please Enter Valid Diastolic Blood Pressure !'),
                                    },
                                ]}
                            >
                                <InputComponent maxLength={3} />
                            </Form.Item>
                        </Row>

                        <Form.Item>
                            <ReadBloodPressure
                                attributes={attributes}
                                form={form}
                                setFormDisabled={setFormDisabled}
                            />
                        </Form.Item>

                        <Form.Item
                            name={'srvbcd_pulse'}
                            label={t("Pulse")}
                            rules={[
                                { required: true, message: t('Please Enter Pulse') },
                                {
                                    pattern: /^[0-9]*$/,
                                    message: t('Please Enter Valid Pulse Rate !'),
                                },
                            ]}
                        >
                            <InputComponent maxLength={3} />
                        </Form.Item>
                        <Form.Item
                            name={'srvbcd_spo2'}

                            label={t("SpO2")}
                            rules={[
                                {
                                    required: false,
                                    message: t('Please Enter spo2'),
                                }
                              
                            ]}
                        >
                            <InputComponent />
                        </Form.Item>

                        <div className="footer-actions">
                            <Button htmlType="submit" type="primary" loading={loading}>
                                {t('Save')}
                            </Button>

                            <Button onClick={onCancel}>{t('Cancel')}</Button>
                        </div>
                    </Form>
                </div>
            )}
        </div>
    );
}
