/**
 * @description
 * @author Sneha
 *
 * Coupons page to list campaign name and to list and edit coupons attached to it
 */

import React, { useState, useEffect } from 'react';

import { Discounts } from '../../../../models';

import { useTranslation }  from 'soxo-bootstrap-core';

import './coupons.scss';

import { Link } from 'react-router-dom';

import { Skeleton, Input, Button, Form, Card, Table, Typography, Space, Modal, Row, message, Select, Radio, DatePicker, Pagination, Tag } from 'antd';

import { ReloadOutlined, PlusOutlined } from '@ant-design/icons';

import moment from 'moment-timezone';


const { Title } = Typography;

const { Search } = Input;

const formItemLayout = {
    labelCol: {
        // xs: { span:0 },
        // sm: { span: 0},
    },
    wrapperCol: {
        span: 50,
    },
    layout: 'vertical',
};

export default function Coupons({ mode, active ,title}) {
    const [coupons, setCoupons] = useState([]);

    const [isModalVisible, setIsModalVisible] = useState(false);

    let [page, setPage] = useState(1);

    let [limit, setLimit] = useState(10);

    const [loading, setLoading] = useState(true);

    const [btnloading, setBtnloading] = useState(false);

    var [query, setQuery] = useState('');

    const [initial, setInitial] = useState({});

    const [form] = Form.useForm();

    const [couponid, setCouponId] = useState();

    // Number of total records
    const [count, setCount] = useState(0);

    // To Translate to another language
    const { t, i18n } = useTranslation();

    useEffect(() => {
        getCoupons({ mode, active, page, limit });
    }, []);

    /**
     *  Get Coupons
     */

    function getCoupons({ mode, active, page, limit }) {
        setLoading(true);

        Discounts.getCoupons({ mode, active, limit, page }).then((result) => {
            setLoading(false);

            setCoupons(result.data);

            // // If the count is present , update the size of the pagination component
            if (result && result.data[0] && result.data[0].count) {
                setCount(result.data[0].count);
            }

            setLoading(false);
        });
    }

    /**
     * Search function
     */

    function onSearch(event) {
        setQuery(event.target.value);
    }

    /**
     *  Refresh
     */
    function refresh() {
        getCoupons({ mode, active, page, limit });
    }

    /**
     * Edit Coupons
     * @param {*} formBody
     */

    const editCoupons = (value) => {
        setBtnloading(true);

        setLoading(true);

        var values = {
            id: couponid,

            active: value.active,

            start_date: value.from.format(),

            end_date: value.to.format(),
        };

        Discounts.updateCoupons(values).then(() => {
            setBtnloading(false);

            message.success('Reason Added Successfully!');

            refresh();

            setLoading(false);
        });

        setIsModalVisible(false);
    };

    /**
     * Modal to ADD new Reason
     */

    const showModal = (record) => {
        setCouponId(record.coupon_id);

        setInitial({
            from: moment(record.from_date),

            to: moment(record.to_date),

            active: record.active,

            ...record,
        });

        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    /**
     *  columns
     */
    const Couponcolumns = [
        {
            title: '#',

            dataIndex: 'index',

            render: (value, item, index) => {
                return (page - 1) * limit + index + 1;
            },
        },

        {
            title: t('Mode'),

            dataIndex: 'mode',
        },

        {
            title: t('From Date'),

            dataIndex: 'from_date',
        },

        {
            title: t('To Date'),

            dataIndex: 'to_date',
        },
        // {
        //     title: ' Expire Date',

        //     dataIndex: 'expire_date',
        // },

        {
            title: t('Available From'),

            dataIndex: 'can_avail_from',
        },
        {
            title: t('Available To'),

            dataIndex: 'can_avail_to',
        },

        {
            title: t('Coupon No.'),

            key: 'coupon_no.',

            render: (record) => {
                return record.coupon_no;
            },
        },

        {
            title: t('Description'),

            dataIndex: 'remarks',
        },
        {
            title: t('Applicable On'),

            dataIndex: 'applicable_on',
        },

        // {
        //     title: 'Item Type',

        //     dataIndex: 'item_type',
        // },

        {
            title: t('Value Type'),

            dataIndex: 'value_type',
        },

        {
            title: t('Value'),

            dataIndex: 'value',
        },

        // {
        //     title: 'Active',

        //     dataIndex: 'active',
        // },

        {
            title: t('Packages'),

            dataIndex: 'packages',

            // render: (packageNames) => (

            //     <div>

            //         {packageNames.map((packageName, index) => (

            //             <Tag key={index} color="green">

            //                 {packageName}

            //             </Tag>
            //         ))}

            //     </div>

            // ),
        },

        {
            title: t('Action'),

            fixed: 'right',

            render: (record) => {
                return (
                    <Space size="middle">
                        <Link
                            onClick={() => {
                                showModal(record);
                            }}
                        >
                            {t('Edit')}
                        </Link>
                    </Space>
                );
            },
        },
    ];

    // Defining Data source for passing to list
    var filtered = coupons.filter((record) => {
        query = query.toUpperCase();

        if (query) {
            if (record.coupon_no && record.coupon_no.toUpperCase().indexOf(query) != -1) {
                return true;
            } else if (record.mode && record.mode.indexOf(query) != -1) {
                return true;
            }
        } else {
            return true;
        }
    });

    /**
     * On Page Change
     *
     * @param {*} data
     */
    function onPagination(page, limit) {
        setLimit(limit);

        setPage(page);

        getCoupons({ mode, active, limit, page });
    }

    return (
        <div className="card">
            {loading ? (
                <Skeleton />
            ) : (
                <>
                    <div className="page-header">
                        <div className="left">
                            <Title level={3}>{title}</Title>

                            <Search
                                placeholder={t("Enter Search Value")}
                                allowClear
                                style={{ width: 300, marginTop: '10px', marginBottom: '20px' }}
                                onChange={onSearch}
                            />
                        </div>

                        <div className="table-actions right">
                            <div className="button-container">
                                <Space size="small">
                                    <Button onClick={refresh} size={'small'}>
                                        <ReloadOutlined />
                                    </Button>

                                    {/* <Button type="primary" size="small" onClick={addCoupons}>

                                        <PlusOutlined />Create Coupons

                                    </Button> */}
                                </Space>
                            </div>
                        </div>
                    </div>

                    <div className="table-card">
                        <Table
                            //  rowKey={record => record.coupon_id}

                            dataSource={filtered}
                            scroll={{ x: true }}
                            columns={Couponcolumns}
                            pagination={false}
                        />

                        {/* <div className="table-footer"> */}

                        {!loading ? (
                            <Pagination pageSize={limit} showSizeChanger={true} onChange={onPagination} current={page} total={count} />
                        ) : null}

                        {/* </div> */}
                    </div>
                </>
            )}

            {/** Modal For Add New Coupons  Start */}
            <Modal
                title={"Coupons" +"   "+initial.coupon_no}
                visible={isModalVisible}
                // onOk={handleOk}

                onCancel={handleCancel}
                destroyOnClose
                footer={[]}
                width={900}
            >
                {initial ? (
                    <div className="coupons">
                        <Card className="form card">
                            <div className="section">
                                <div className="left-section">
                                    <div className="first-column">
                                        <DetailWrapper label={t('DESCRIPTION')} value={initial && initial.remarks ? initial.remarks : null} />
                                    </div>
                                    {initial && initial.caption ? (
                                        <div className="second-column">
                                            <div className="detail-element">
                                                <DetailWrapper label={t('CAPTION')} value={initial && initial.caption ? initial.caption: null} />
                                            </div>
                                        </div>
                                    ) : null}
                                    <div className="third-column">
                                        <div className="detail-element">
                                            <DetailWrapper label={t('RATE TYPE')} value={initial.rate_type} />
                                        </div>

                                        <div className="detail-element">
                                            <DetailWrapper label={t('BILL MODULE')} value={initial.billmoduletypes} />
                                        </div>

                                        <div className="detail-element">
                                            <DetailWrapper label={t('MODE')} value={initial.mode} />
                                        </div>
                                    </div>

                                    <div className="fourth-column">
                                        <div className="detail-element">
                                            <DetailWrapper label={t('ITEM TYPE')} value={initial.item_type} />
                                        </div>

                                        <div className="detail-element">
                                            <DetailWrapper label={t('ITEM VALUE TYPE')} value={initial.value_type ?initial.value_type:null } />
                                        </div>

                                        <div className="detail-element">
                                            <DetailWrapper label={t('VALUE')} value={initial.value} />
                                        </div>
                                    </div>

                                    <div style={{ paddingTop: '12px' }}>
                                        <div className="detail-element">
                                            <DetailWrapper label={t('PACKAGES')} value={initial.packages} />
                                        </div>
                                    </div>
                                </div>

                                <Form
                                    layout="vertical"
                                    name="basic"
                                    form={form}
                                    initialValues={{
                                        ...initial,
                                    }}
                                    onFinish={editCoupons}
                                    {...formItemLayout}
                                >
                                    <div className="right-section">
                                        <div className="top-section">
                                            <div className="date-section">
                                                <div className="detail-element">
                                                    <Row className="row-class">
                                                        <Form.Item label={t('FROM DATE')} name="from" className="custom-label">
                                                            <DatePicker format={'DD/MM/YYYY'} />
                                                        </Form.Item>
                                                    </Row>
                                                </div>

                                                <div className="detail-element">
                                                    <Row className="row-class">
                                                        <Form.Item label={t('TO DATE')} name="to" className="custom-label">
                                                            <DatePicker format={'DD/MM/YYYY'} />
                                                        </Form.Item>
                                                    </Row>
                                                </div>
                                            </div>

                                            <div className="mode-section">
                                                <div className="detail-element">
                                                    <Row className="row-class" name="active">
                                                        <Form.Item label={t('ACTIVE')} name="active" className="custom-label">
                                                            <Radio.Group>
                                                                <Radio value="Y">{t('Yes')}</Radio>

                                                                <Radio value="N">{t('No')}</Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="bottom-section">
                                            <div className="table-actions-right">
                                                <div className="button-container">
                                                    <Space size="small">
                                                        <div className="submit-button">
                                                            <Form.Item>
                                                                <Button type="primary" htmlType="submit" size={'middle'}>
                                                                    {t('Submit')}
                                                                </Button>
                                                            </Form.Item>
                                                        </div>

                                                        <div>
                                                            <Form.Item>
                                                                <Button type="primary" size="middle" onClick={handleCancel}>
                                                                    {t('Cancel')}
                                                                </Button>
                                                            </Form.Item>
                                                        </div>
                                                    </Space>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </Card>
                    </div>
                ) : null}
            </Modal>

            {/** end  */}
        </div>
    );
}

/**
 * For detailwrapping of form
 * @returns label
 * @returns value
 */

function DetailWrapper({ label, value }) {
    return (
        <div className="detail-wrapper-header">
            <div className="page-header-type">
                <Row>
                    <label className="label-class-type">
                        <span className="details">{label}</span>
                    </label>
                </Row>

                <Row className="row-class-type">
                    <span>{value}</span>
                </Row>
            </div>
        </div>
    );
}
