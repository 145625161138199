/**
 * Component to Show, send syncare image link to mail
 *
 *
 *
 */

import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, Input, Space, Typography, message, Checkbox, Row, Col, Radio, Skeleton, Modal } from 'antd';
import { BranchesOutlined } from '@ant-design/icons';
import { SCRFilesLink } from '../../../../models';
import { useTranslation} from 'soxo-bootstrap-core';
const { Text } = Typography;

export default function SendImageLink({ callback, billId, opNo, guestPrereg, props }) {
    const [disable, setDisable] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const [modalityData, setModalityData] = useState([]); // modality
    const [guestData, setGuestData] = useState([]); //guest
    const [selectedRadio, setSelectedRadio] = useState('modality'); //select Radio to check modality and guest

    const [selected, setSelected] = useState([]);
    const [selectAll, setSelectAll] = useState(false); //selectd all modality wise links
    // State variable to store selected guest data items
    const [selectedGuestData, setSelectedGuestData] = useState([]);
    const { t, i18n } = useTranslation(); // To Translate to another language
    const guest = guestPrereg;
    const [editedEmail, setEditedEmail] = useState('');
    const [form] = Form.useForm();
    

    useEffect(() => {
        getImageLinks();
    }, []);

    //  To get Syncare Image links
    async function getImageLinks() {
        setIsLoading(true);
        //Load linkimage with description with billId
        const result = await SCRFilesLink.getSyncImageLink(billId, opNo);
        setModalityData(result.resultWithAccessionno);
        setGuestData(result.resultWithOpno);
        setIsLoading(false);
    }
    //  To send Syncare Image links to mail.
    async function SendSyncareImageLink() {
        let userData = {
            username: 'AUTOLOGIN',
            password: 'Auto@Log88',
        };
        // Fetching access token for report sending
        const userDataDetails = await SCRFilesLink.getAccessTokenForReportSending(userData);

        const token = userDataDetails.access_token;

        const dbPtr = props.settings.headers.db_ptr.toString();
        let links; // links based on the selected radio

        if (selectedRadio === 'modality') {
            links = selected; //  'modality', use the selected modality links
        } else {
            links = selectedGuestData;
        }

        /* `formBody` with three properties: `mail`, `links`,
       and `name`. */
        let formBody = {
            mail: props.isEmailEditable ? editedEmail || guest.prereg_email : guest.prereg_email,
            name: guest.prereg_fname,
            links: links,
        };

        let response = await SCRFilesLink.sendSyncareLinks(formBody, dbPtr, token);
        if (response.success) {
            setIsLoading(false);
            message.success('Syncare Link send successfully');
            setDisable(true);
            setSelected([]);
        } else {
            setIsLoading(false);
            message.error(response.message);
            setDisable(true);
        }
    }

    //  to handle click 2 or more item selected

    const handleClick = (item_description, image_link) => {
        setDisable(false);
        if (isSelected(item_description)) {
            // If the item is already selected, remove it
            setSelected(selected.filter((item) => item.item_description !== item_description));
        } else {
            // If the item is not selected, add it to the selected array
            setSelected([...selected, { item_description, image_link }]);
        }
    };

    const isSelected = (item_description) => {
        // Check if the item is in the selected array
        return selected.some((item) => item.item_description === item_description);
    };

    // to handle select all checkbox for modality wise data
    const handleSelectAllModality = () => {
        // If selectAll is true, deselect all items
        if (selectAll) {
            setSelected([]);
            setDisable(true);
        } else {
            // If selectAll is false, select all items
            setDisable(false);
            setSelected(modalityData.map((item) => ({ item_description: item.item_description, image_link: item.image_link })));
        }
        // Toggle selectAll state
        setSelectAll(!selectAll);
    };

    // Function to handle radio button change
    const handleRadioChange = (e) => {
        setDisable(true);
        setSelectedRadio(e.target.value);
        if (e.target.value === 'guest') {
            // If "Guest wise"  is selected, trigger default selection
            //  select the default guest data items
            const defaultGuestData = guestData.map((item) => ({
                image_link: item.image_link,
                item_description: [guest.prereg_fname, guest.prereg_opno],
            }));
            setSelectedGuestData(defaultGuestData); //  default guest data selection
            setDisable(false); // Enable the share
        }
    };

    //  OnShare confirmation Modal
    const onShare = () => {
        const emailToSend = props.isEmailEditable ? editedEmail || guest.prereg_email : guest.prereg_email;
        if (guest.prereg_email.trim() !== '') {
            Modal.confirm({
                title: 'Confirmation',
                content: `Are you sure you want to send the image links to  ${emailToSend}?`,
                onOk() {
                    if (selectedRadio === 'guest') {
                        // If "Guest wise"  selected, proceed with share
                        SendSyncareImageLink();
                    } else {
                        // If "Modality wise"  selected, check if any items are selected
                        if (selected.length > 0) {
                            // If items are selected, proceed to send the links
                            SendSyncareImageLink();
                        } else {
                            message.error('Please select one item before sending.');
                        }
                    }
                },
            });
        }
    };

    const filteredData = selectedRadio === 'modality' ? modalityData : [modalityData[0]]; // Only display one row for "Guest" radio

    return (
        <div className="detail-element">
            <div className="card report-link card-shadow">
                {/* <div className="card-title" style={{ width: '100%' }}>
                    <h3>IMAGE LINKS</h3>
                </div> */}
                <div>
                    <Radio.Group onChange={handleRadioChange} value={selectedRadio}>
                        <Radio value="modality">{t('Modality wise')}</Radio>
                        <Radio value="guest">{t('Guest wise')}</Radio>
                    </Radio.Group>
                </div>

                {/* modaily base start */}
                {selectedRadio === 'modality' ? (
                    <div>
                        <div style={{ marginBottom: '8px', display: 'flex', justifyContent: 'flex-end' }}>
                            <Checkbox onChange={handleSelectAllModality} checked={selectAll}>
                                {t('Select All')}
                            </Checkbox>
                        </div>
                        {isLoading ? (
                            <Skeleton active />
                        ) : (
                            <Row gutter={[8, 8]} style={{ fontSize: '12px' }}>
                                {modalityData.map((imageLink) => (
                                    <Col key={imageLink.id} span={12}>
                                        <div
                                            className={`test-name card card-shadow ${isSelected(imageLink.item_description) ? 'active' : ''}`}
                                            onClick={() => handleClick(imageLink.item_description, imageLink.image_link)}
                                            style={{ height: '64px', backgroundColor: isSelected(imageLink.item_description) ? '#f3fbff' : '' }}
                                        >
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <Checkbox
                                                    onChange={() => {}}
                                                    checked={isSelected(imageLink.item_description)}
                                                    style={{ marginRight: '8px' }}
                                                />
                                                <div className="information">
                                                    <Text type="primary">{imageLink.item_description}</Text>

                                                    <Row>
                                                        {props.showImageLink ? <a target="_blank" href={imageLink.image_link}>https://syncareviewer.nura-in</a> : ''}
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        )}
                    </div>
                ) : (
                    <div style={{ marginTop: '8px' }}>
                        {isLoading ? (
                            <Skeleton active />
                        ) : (
                            // group base start
                            <Row gutter={[8, 8]} style={{ fontSize: '12px' }}>
                                {guestData.map((item) => (
                                    <Col key={item.id} span={24}>
                                        <div
                                            className={`test-name card card-shadow ${item.image_link ? 'active' : ''}`}
                                            style={{ height: '64px', backgroundColor: item.image_link ? '#f3fbff' : '' }}
                                        >
                                            <div className="information">
                                                <Text type="primary">
                                                    {guest.prereg_fname} ({guest.prereg_opno})
                                                </Text>
                                               
                                                <Row>{props.showImageLink ? <a target="_blank" href={item.image_link} >https://syncareviewer.nura-in</a> : ''}</Row>
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        )}
                    </div>
                )}
            </div>

            <div style={{ marginTop: '8px' }}>
                <Form form={form} name="emailForm" onFinish={onShare} style={{ width: '450px' }} initialValues={{ email: guest.prereg_email }}>
                    <Text type="primary">{t('SEND VIA')}</Text>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Form.Item name="email" valuePropName="checked" initialValue={true}>
                            <Radio>{t('Email')}</Radio>
                        </Form.Item>
                        {/* <Form.Item name="whatsapp" valuePropName="checked" initialValue={false}>
                            <Radio>Whatsapp</Radio>
                        </Form.Item> */}
                    </div>
                    <Form.Item
                        name="email"
                        // rules={[
                        //     { required: true, message: 'Please enter your email!' },
                        //     { type: 'email', message: 'Please enter a valid email address!' },
                        // ]}
                    >
                        {props.isEmailEditable ? (
                            <Input
                                type="email"
                                value={guest.prereg_email}
                                onChange={(e) => setEditedEmail(e.target.value)}
                                style={{ width: '640px' }}
                            />
                        ) : (
                            <Input type="email" value={guest.prereg_email} readOnly style={{ width: '640px' }} />
                        )}
                    </Form.Item>
                    {/* <Form.Item
                       
                        rules={[
                            { required: true, message: 'Enter whatsapp number!' },
                            { type: 'email', message: 'Please enter a valid number!' },
                        ]}
                    >
                        <Input placeholder="Enter Whatsapp Number" />
                    </Form.Item> */}
                    <Form.Item>
                        <Space>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={isLoading}
                                disabled={disable || (selectedRadio === 'guest' && selectedGuestData.length === 0)}
                            >
                                <BranchesOutlined />
                                {t('Share')}
                            </Button>
                            <Button htmlType="button" onClick={callback}>
                                {t('Cancel')}
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}
