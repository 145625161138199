/**
 * Registration Page specific to StopTB but also built considering 
 * Nura/Matria and All medicant requirements
 * 
 * The component in support with new registration api 
 * should support creation of appointment, registration , visit creation etc
 * 
 * @description
 * @author jinshakappil 
 * @author Ashique Mohammed
 * @author Sameena

 * This component is used to gather information from the guest .
 * 
 * This components demos/explores the first use of generic forms 
 * along with custom forms . 
 * 
 * We have defined useFormUtils which accepts a configuration that is specific to the form 
 * and allows binding client side scripting to expose all the custom functions that 
 * are maintained within the useFormUtils 
 *
 */
import React, { useState, useEffect } from 'react';

import { Row, Col, Form, Select, Typography, Input, Tabs, Button, Skeleton, message, Modal, Card, Checkbox } from 'antd';

import { InputComponent, CountryPhoneInput, Location, useFormUtils, ReferenceSelect, useTranslation } from 'soxo-bootstrap-core';

import AgeSelection from './components/age-selection';

import AdditionalKeyGenerate from './components/additional-key-generate';

import { GuestPreferences } from './guest-preference/guest-preferences';

import { ReadEmiratesId } from './read-emiratesid/read-emiratesid';

// import { TabPane } from 'rc-tabs';

import moment from 'moment';

// Below are components created to support the registration form

import { AddressDetails } from './address-details/address-details';
import { GuestRegistrationAddressDetails } from './registration-address-details/registration-address-details';
import { PersonalDetails } from './personal-details/personal-details';
import { CampaignDetails } from './campaign-details/campaign-details';
import { OtherDetailsOfGuest } from './other-details/other-details';

// Models for use in the form
import { Opreg, Communications, Preferences, NatureList,Registrations, Referrers, Prereg, Appointments, ListCountries, Customers, Items } from '../../../models';

// Form Configuration specific to stoptb
// # TODO This configuration will from preferences.
import { formSettings } from './registration-form-settings/registration-form-settings';

import './new-guest-information.scss';
import { Summary } from './summary/summary';
import { ApplyCoupon } from './apply-coupon/apply-coupon';
// import { Tabs } from 'antd'

import ExistingCustomerList from './list-existing-customers';


const { Option } = Select;
const { Title, Text } = Typography;
const { TabPane } = Tabs;

const formItemLayout = {
    layout: 'vertical',
};

// const { TabPane } = Tabs;

export default function FormContainer(props) {
    const [loading, setLoading] = useState(true);

    const [preference, setPreference] = useState({});

    useEffect(() => {
        Preferences.getRecord(props.formPreferenceId).then((result) => {
            let data = JSON.parse(result.result.scope_value);

            setPreference(data);

            setLoading(false);
        });
    }, []);

    return loading ? <Skeleton /> : <GuestDetailsForNewPatient {...props} preference={preference} />;
}

/**
 *
 *
 * @param {*} props
 * @returns
 */
function GuestDetailsForNewPatient(props) {
    // Variable to control the loading state of the form
    const [loading, setLoading] = useState(true);

    const [validation, setValidation] = useState('');
    // Control the loading state of the button on submission
    const [btnloading, setBtnLoading] = useState(false);

    const [alreadyBooked, setAlreadyBooked] = useState(false);

    const [alreadyExist, setAlreadyExist] = useState(false);

    //  alert based on setting value
    const [mandatorySetting, setMandatorySetting] = useState(false);

    // Using the form reference supported by antd for further use
    const [form] = Form.useForm();

    form.validateFields = ({ ...props }) => {
        console.log(props);

        console.log(arguments);
    };

   

    /**
     * Finds and returns the default country record from the provided options array.
     * The default country is identified by its 'lcnt_id' field matching the given initial value.
     * If no match is found, an empty object is returned.
     *
     * @param {Array} options - An array of country records to search through.
     * @returns {Object} - The country record matching the default 'lcnt_id', or an empty object if not found.
     */
    function identifyDefaultCountry(options = []) {
        // Initial value for the default country 'lcnt_id'
        let initial = parseInt(process.env.REACT_APP_DEFAULT_COUNTRY) || 1;

        // Object to store the matching country record
        let matching = {};

        // Iterate through each record in the options array
        options.forEach((record) => {
            // Check if the 'lcnt_id' of the current record matches the initial value
            if (record['lcnt_id'] == initial) {
                // If a match is found, assign the current record to the matching object
                matching = record;
            }
        });

        // Return the matching country record, or an empty object if no match is found
        return matching;
    }


      

    // UseFormUtils have specific functions as an event listner and control the configuration of the form
    const { setVisible, config, onChange } = useFormUtils(form, formSettings, props.preference);

    // Field configuration that is formatted as per requirement by the formUtils
    const { fields = {}, add_on_fields = [] } = config;

    const [initialValues, setInitialValues] = useState({
        category: 'GEN',
    });


    const [additionalValues, setAdditionalValues] = useState([]);

    const [disableCountryCode, setDisableCountryCode] = useState(false);

    const [coupon, setCoupon] = useState(null);

    const [discount, setDiscount] = useState(null);

    const [item, setItems] = useState(null);

    const [total, setTotal] = useState(null);

    const [genderItem, setGenderItem] = useState();

    // All customers with the same email is save in existingCustomers
    const [existingCustomers, setExistingCustomers] = useState([]);

    // Visible for the modal to be opened., if any previous customer had the same mail address
    const [existingCustomerVisible, setExistingCustomerVisible] = useState(false);

    // The selcted Customer, if the mail address was used by a previous customer and that customer is selected
    const [selectedCustomer, setSelectedCustomer] = useState({});

    // If corporate is checked, we use this variable
    const [corporate, setCorporate] = useState(false);

    // Visible for alert when address is changed
    const [changeAddressAlert, setChangeAddressAlert] = useState(false);

    const [addressChangedFields, setAddressChangedFields] = useState([]);

    const params = Location.search();

    const { t, i18n } = useTranslation(); // To Translate to another language

    let additionalkey;

    useEffect(async () => {
        // If url contains id and table, it is an indication to repeated case.
        // To prepopulate the columns, we will have to load guest details

        if (params.id || params.edit) {
           
            // Get guest information to initialize form values from id in url
            await loadGuestInformation();
           
        } else {
            if (config) {
                setLoading(false);
            }
        }
    }, [config, params.id]);

    const callBack = (values) => {
        // if the corperate is selected
        if (values.target.checked) {
            setCorporate(values.target.checked);
        } else {
            // if the corperate is dis selected then revert bill value
            setCorporate(values.target.checked);

            setTotal(item.ir_rate);
        }
    };

    /**
     * On Change of values in the form
     * We have to trigger all the configuration
     *
     * @param {*} field
     * @param {*} values
     */
    const onValuesChange = (keys, values, confirmed) => {
        if (props.completeProfile) {
            // Retrieve all the field values from the form
            const fieldValues = form.getFieldValue();
            // Get all the field names (keys) from the field values

            let obj = Object.keys(fieldValues);
            // Create a copy of the current fields state to update
            // console.log("QQQQQQQQQQQQQQQQ",fields)
            const newFields = { ...fields };
            // Loop through each field name
            obj.map((ele) => {
                // Check if the field value is null
                if (fieldValues[ele] === null ||fieldValues[ele] === undefined) {                  
                    // Update the specific field's state with a warning status and help text

                    newFields[ele] = { ...newFields[ele], validate_status: 'warning', help_text: 'Please Fill the details' };
                }
            });
            // Update the state with the new fields object containing the warnings
            setValidation(newFields);
        }
   
        Opreg.onValuesChange(keys, values, form, genderItem, fields, add_on_fields, onChange, initialValues, showAddressChangeAlert, confirmed);
    };

    /**
     * Callback function executed when form submission fails due to validation errors.
     *
     * This function processes the validation errors provided by the form and updates
     * the form state to reflect these errors. It is called automatically by Ant Design's
     * form handling system when the form fails to pass validation rules.
     *
     */
    const onFinishFailed = (errorInfo) => {
        // Process the error fields from errorInfo
        const errorFields = errorInfo.errorFields.reduce((acc, field) => {
            acc[field.name[0]] = field.errors[0];
            return acc;
        }, {});
        // Create a copy of the current fields state to update
        const newFields = { ...fields };
        // Set errors for each specific field
        Object.keys(errorFields).forEach((fieldName) => {
            // Update the specific field's state with an error status and help text
            newFields[fieldName] = {
                validate_status: 'error',
                help_text: errorFields[fieldName],
            };
        });
        // Update the state with the new fields object containing the error statuses and help texts

        setValidation(newFields);
    };

    /**
     * Function to alert when changing address
     * @param {*} result
     */
    const showAddressChangeAlert = (result) => {
        const values = form.getFieldsValue();
        const arr = [];
        // Get values to replaced and the values that are replacing
        const columns = [
            {
                field: 'country',
                value: 'country',
            },
            {
                field: 'place',
                value: 'city',
            },
            {
                field: 'state',
                value: 'state',
            },
            {
                field: 'address2',
                value: 'post_office',
            },
        ];

        if (params.redirect === 'registration') {
            columns.push({
                field: 'district',
                value: 'district',
            });
        }

        // Gte the previous and new values to metion in the alert
        columns.map((ele) => {
            if (result[ele['value']] !== values[ele['field']])
                arr.push({
                    field: ele['field'],
                    previousValue: values[ele['field']],
                    newvalue: result[ele['value']],
                });
        });
        if (arr.length > 0) setChangeAddressAlert(true);
        setAddressChangedFields(arr);
    };

    /**
     * This function is triggerd onblur of email address input to check if
     *  any previous customer has used the same email address
     *  Here consolidated opreg api is hit, to see if any customer in the same location had used the email address previously
     *  If yes, all those cases are listed and any account can be selected from the list
     *  It will navigate to existing-customer url, and manage the case in existing customer flow
     * @param {*} event
     */
    const onBlur = async (event) => {
        const emailValue = form.getFieldValue('email_address');

        // if (params.edit || params.redirect) {
        if (!params.complete_profile && (params.id || params.edit || params.redirect)) {
            // To avoid repeated flow for registartion and edit cases
        } else {
            let formBody = {
                search_by: 'email',
                criteria: 'anywhere',
                search_text: emailValue,
                index: 'ALL',
            };

            var existingCustomerArray;
            //   enablePreregSuggestion attribute used to control  User Selection dialog box in Direct Registration

            if (emailValue) {
                // if (emailValue && !props.enablePreregSuggestion) {
                existingCustomerArray = await Appointments.consolidatedSearch(formBody);

                setExistingCustomers(existingCustomerArray.result);

                if (existingCustomerArray.result.length > 0) {
                    setExistingCustomerVisible(true);
                } else {
                    message.info('No matching records found for this Email ID');
                }
            }
        }
    };   
    // if (corporatePointer) {
    //     let values = {

    //         corporate_pointer: corporatePointer,
    //     };
    //     setInitialValues(values);
    // }
    /**
     * Load guest details for repeated case.
     * We get the table and id from url, get data accordingly
     */
    async function loadGuestInformation(emiratesDetails) {
        setLoading(true);
        // If redirect is appointment, table is taken as prereg
        if (params.redirect && params.redirect === 'appointment') {
            params.table = 'prereg';
        }
        // If redirect is registration, table is taken as opreg
        else if (params.redirect && params.redirect === 'registration') {
            params.table = 'opreg';
        }
        // If the case of complete profile, table is taken as opreg
        if (params.complete_profile && !params.repeat) {
            params.table = 'doctor_appointment';
        }
        // let result = await Opreg.loadGuestInformation(params.id, params.table, params.da_id);
        let loadResults = await Opreg.loadGuestInformation(params.id, params.table, params.da_id || params.appointmentId);

        /*check whether emirates id presents and set results data */

        let result = emiratesDetails ? emiratesDetails : loadResults.data;

        // If coupon is applied, set coupon only for edit cases
        // For existing customer flow, this step is to be avoided
        // If couponno is present, this indicates coupon was applied
        // Else disocunt is applies
        if (result && result.coupon && params.edit) {
            if (result.coupon && result.couponno)
                setCoupon({
                    coupon_value: result.coupon,
                    coupon_amount: result.coupon,
                    coupon_no: result.couponno,
                });
            else if (result.coupon && !result.coupono)
                setDiscount({
                    discount: result.coupon,
                });
        }
        if (result && result.billValue >= 0)
            // Set bill value to total
            setTotal(result.billValue);
        // If not for edit cases, refferer should be made null, as the data here is got from doctor appointment table
        if (!params.edit && params.table === 'prereg' && !params.redirect) {
            result = {
                ...result,
                camp_site: null,
                remarks: null,
            };
        }

        if (result.corporate_pointer) {
            setCorporate(true);
            // if there is coporrate is present in existing flow  then we want to prepopulate bill value
            await onselectChange(result.corporate_pointer);
        }
        // Set mobile number and country code
        // Both mobile_number and alternate_mobile are set according to the phone input
        var mobile_number = await validatePhoneNumber(result.mobile_number, result.countrycode);
        var alternate_mobile = await validatePhoneNumber(result.alternate_mobile, result.countrycode2);

        //if mobile number have value
        if (mobile_number.value) {
            result = {
                ...result,
                mobile_number,
                alternate_mobile,
                category: 'GEN',
            };
        }

        if (result.date_of_birth && result.date_of_birth.dob) {
            result.date_of_birth = {
                ...result.date_of_birth,
                dob: moment(result.date_of_birth.dob),
            };
        } else {
            result.date_of_birth = {
                ...result.date_of_birth,
                dob: null,
            };
        }
  
        setInitialValues(result);
        setGenderItem(result.gender);  // to set gender validation for registration 
        // Set initial values to prepopulate
        form.setFieldsValue(result);

        if (config) setLoading(false);
    }

    /**
     *
     * @returns
     */
    function validatePhoneNumber(mobile_number, countrycode) {
        // Trim mobile number if any white space is included
        if (mobile_number) {
            // mobile_number = mobile_number.trim()
            // Remove white space from mobile number and country code
            mobile_number = mobile_number.replace(/\s+/g, '');
            mobile_number = mobile_number.replace(/-/g, '');
            mobile_number = mobile_number.replace(/\+/g, '');
        }
        if (countrycode) {
            countrycode = countrycode.replace(/\s+/g, '');
            countrycode = countrycode.replace(/\+/g, '');
        }
        // 1. Two Columns - mobile + country_code | Component || API || Form

        // 2. Single Column = With Country Code

        // 3. Single Column - Without Country Code

        let phoneNumber = {};

        let valid = false;

        if (mobile_number && countrycode && (parseFloat(countrycode) || parseFloat(countrycode) >= 0)) {
            // Joining country code with mobile number
            let numberWithCode = countrycode.concat(mobile_number);
            // checking the number is valid
            if (countrycode == 91) {
                if (numberWithCode.length == 12)
                    // India
                    valid = true;
            } else if (countrycode == 971) {
                // UAE
                if (numberWithCode.length == 12) valid = true;
            } else if (countrycode == 966) {
                //KSA
                if (numberWithCode.length == 12) valid = true;
            } else if (countrycode == 976) {
                // Mongolia
                if (numberWithCode.length == 11) valid = true;
            } else if (countrycode == 65) {
                // Singapore
                if (numberWithCode.length == 10) valid = true;
            } else if (countrycode == 1) {
                // USA Aand Canada
                if (numberWithCode.length == 11) valid = true;
            } else if (countrycode == 61) {
                // Australia
                if (numberWithCode.length == 12) valid = true;
            } else if (countrycode == 974) {
                //Qatar
                if (numberWithCode.length == 11) valid = true;
            } else if (countrycode == 81) {
                //Japan
                if (numberWithCode.length > 10) valid = true;
            } else if (countrycode === '+00' && numberWithCode.length <= 13) {
                // For cases without countrycode, country code is adde as'+00'
                valid = true;
            } else {
                valid = true;
            }

            phoneNumber = {
                value: mobile_number,
                code: {
                    dialCode: countrycode,
                },
                valid: valid,
            };
        } else if (!countrycode && mobile_number && mobile_number.length < 10) {
            phoneNumber = {
                value: mobile_number,
                code: {
                    dialCode: '+00',
                },
                valid: true,
            };
            setDisableCountryCode(true);
        } else {
            if (mobile_number && mobile_number.length == 10) {
                phoneNumber = {
                    value: mobile_number,
                    code: {
                        dialCode: '91',
                    },
                    valid: true,
                };
                setDisableCountryCode(true);
            } else if (mobile_number && mobile_number.indexOf('91') !== -1 && mobile_number.length === 12) {
                phoneNumber = {
                    value: mobile_number.substring(2, mobile_number.length),
                    code: {
                        dialCode: '+91',
                    },
                    valid: true,
                };
            } else if (mobile_number && mobile_number.indexOf('971') !== -1 && mobile_number.length === 12) {
                phoneNumber = {
                    value: mobile_number.substring(3, mobile_number.length),
                    code: {
                        dialCode: '+971',
                    },
                    valid: true,
                };
            } else if (mobile_number && mobile_number.indexOf('976') !== -1 && mobile_number.length === 11) {
                phoneNumber = {
                    value: mobile_number.substring(3, mobile_number.length),
                    code: {
                        dialCode: '+976',
                    },
                    valid: true,
                };
            }
        }

        return {
            ...phoneNumber,
        };
    }

    /**
     * On submission of the form
     */
    async function onFinish({ verified, existing_user }) {
        let values = form.getFieldsValue();
        values = {
            ...values,
            is_nuradesk: true,
            existing_user: existing_user,
        };

        // Check if a user already exists and the setting is 'NAP'
        if (existing_user) {
            values.existing_user = true; // Ensure 'existing_user' is true when a user exists
        }
        // If verified is true(when the appointment create, is triggered but a appointmentm with same name and email or name and mobile number is present in appointment and the customer has approved for appointment)

        if (verified) {
            values.verified = true;
        }

        let queryParam = {};

        setBtnLoading(true);
        let appointment_mode;

        // if (!params.edit) {
        const genderValidation = await Opreg.validateGender(values.gender, genderItem);

        // If gender validation is false, selectedngender does not match the gender specified for the selected package
        if (!genderValidation) {
            message.error('The Selected Gender does not match with the Selected Package Gender. Please Select the Correct Gender');
            setBtnLoading(false);
            return false;
        }
        // }

        // Append additional key in case of stoptb
        values = {
            ...values,
            addition_key_id: additionalkey,
        };

        // If address is not entered save it as an empty string
        if (!values.address1) values.address1 = '';
        if (!values.address2) values.address2 = '';

        // Get appointmentMode
        if (props.appointmentMode) {
            // For nura the attribute evalRequired is present, and we dont need to eval the appointmentmode
            if (props.evalRequired) {
                appointment_mode = props.appointmentMode;
            } else {
                appointment_mode = eval(props.appointmentMode)();
            }
        }

        /**Get mobile number and countrycode from the entered data */
        // Storing value of op_mobile to a variable
        var mobile = values.mobile_number;

        var dialCodeLength,
            mobile_number,
            countryCode = null;

        //Storing country code length
        dialCodeLength = mobile.code.dialCode.length;

        if (mobile.value.length > 10) mobile_number = mobile.value.substring(dialCodeLength);
        else mobile_number = mobile.value;

        countryCode = mobile.code.dialCode;

        var alternate_mobile;

        // If alternate Mobile number is entered, get the mobile number from the value by avoiding the country code
        if (values.alternate_mobile && values.alternate_mobile.value)
            if (values.alternate_mobile.value.length > 10)
                alternate_mobile = values.alternate_mobile.value.substring(values.alternate_mobile.code.dialCode.length);
            else alternate_mobile = values.alternate_mobile.value;

        const time = moment(params.selected_time, 'DD/MM/YYYY hh:mm A').valueOf();

        values = {
            ...values,
            package_value: params.da_packagetypevalue,
            doctor: params.da_doctorptr,
            token_no: params.token_no,
            age_type: values.date_of_birth.age_type,
            age: values.date_of_birth.age,
            age_day: values.date_of_birth.day,
            age_month: values.date_of_birth.month,
            age_year: values.date_of_birth.year,
            mobile_number: mobile_number,
            countrycode: countryCode,
            dob: values.date_of_birth.dob,
            opno: initialValues && initialValues.opno ? initialValues.opno : null,
            // Visit Remarks
            visit_remarks: params.remarks,
            total: total,
            discount: discount,
            package_type: 'ITM',
            referrer_pointer: values.camp_site,

            // op_iscorporate: 'N',

            // mode_of_communication: '3',
            // op_dispage: 'null',
        };

        // I f alternate mobile number is present save country code and mobile number
        if (values.alternate_mobile && values.alternate_mobile.value) {
            values = {
                ...values,
                alternate_mobile: alternate_mobile,
                countrycode2: values.alternate_mobile.code.dialCode,
            };
        } else {
            values = {
                ...values,
                alternate_mobile: null,
                countrycode2: null,
            };
        }
        if (corporate) {
            values.corporate = corporate;
        }

        // Check if the entered phone number is valid or not
        if (values.mobile_number && values.countrycode) {
            const phoneValidation = await validatePhoneNumber(values.mobile_number, values.countrycode);
            // If not valid, show an error message
            if (phoneValidation.valid) {
            } else {
                message.error('Please Check the Entered Phone Number');
                setBtnLoading(false);
                return false;
            }
        } else {
            // This else condition include based on prod issue.
            message.error('Please Enter Phone Number');
            setBtnLoading(false);
            return false;
        }

        // Check if the entered alternate phone number is valid or not
        if (values.alternate_mobile && values.countrycode2) {
            const phoneValidation = await validatePhoneNumber(values.alternate_mobile, values.countrycode2);
            // If not valid, show an error message
            if (phoneValidation.valid) {
            } else {
                message.error('Please Check the Entered Phone Number');
                setBtnLoading(false);
                return false;
            }
        }

        // If coupon is present
        // To show total in summary, coupon value is reduced from total,when coupon is applied
        // On submission, the coupon is validated once more in backend and if that is a success, coupon value is deducted from da_billvalue ie, total
        // So we are adding back the coupon value as it would be deducted from backend once again
        if (coupon && coupon.coupon_amount) {
            values = {
                ...values,
                coupon: coupon,
            };
        }
        if (discount && discount.discount) {
            values = {
                ...values,
                disocunt: discount.discount,
            };
        }
        // If selected_time is present in url, append appointment date and time
        if (params.selected_time) {
            values = {
                ...values,
                appointment_date: moment(time).format('YYYY-MM-DD'),
                appointment_time: moment(time).format('hh:mm A'),
            };
        }
        // In case of existing customer flow, add prereg or opreg id to the values
        if (params.table === 'prereg') {
            values = {
                ...values,
                prereg_id: params.id,
            };
        } else if (params.table === 'opreg') {
            values = {
                ...values,
                opreg_id: params.id,
            };
        }
       

        /**Get all the add_on fields and it's corresponding values */
        // Get keys of values entered in the form
        const fields = Object.keys(values);
        // Get values of values entered in the form
        const value = Object.values(values);

        // Get field name from add_on_fields
        const extraFields = Object.values(add_on_fields);

        // For each add_on_field element, get the values entered and store it in an array
        // This array is used to store data in op_otherdetails1in xml format
        if (extraFields && extraFields.length)
            extraFields.map((ele) => {
                const index = fields.indexOf(ele.field);
                if (value[index])
                    additionalValues.push({
                        field: ele.field,
                        value: value[index],
                    });
            });

        // Add the array with additinal values
        values = {
            ...values,
            additionalValues: additionalValues,
        };

        // ExtraParams for billing is passed as attribute, this includes item to be billed,rate and quantity
        if (props.extraBillingParams) {
            values = {
                ...values,
                ...props.extraBillingParams,
            };
        }
        // Append appointment mode
        if (props.appointmentMode) {
            values = {
                ...values,
                appointment_mode: appointment_mode,
                mode: props.mode,
                appointmentId: params.appointmentId,
                id: params.id,
            };
        }
        // in the case icarus flow need  opreg
        if (params.complete_profile) {
            values = {
                ...values,
                da_id: params.da_id,
            };
        }


         // in the case icarus flow need  opreg
         if (params.complete_profile) {
           
            values = {
                ...values,
                da_id: params.da_id,
            };
        }
        // For edit of appointment provide doctor appointmnet id
        if (params.edit && params.redirect === 'appointment') {
            values = {
                ...values,
                id: params.da_id,
            };
        }

        if (values.date_of_birth && values.date_of_birth.dob) {
            let dob = moment(values.date_of_birth.dob).format('DD/MM/YYYY');

            values.date_of_birth.dob = moment(dob, 'DD/MM/YYYY');
        }
        const response = await Opreg.saveNewRegistration(values);

        let result = response.result;

        //    if (result.success) {
        if (result) {
            let opno;

            // 1. Appointment Create- appointment/:id
            // 2. Appointment Edit - appointment/:id

            // 3. Repeat Appointment with Prereg - appointment/:id
            // 4. Repeat Appointment with Opreg - appointment/:id

            // 5. Registration Add - registration/:id
            // 6. Registration Edit - registration/:id

            // 7. Registration from Appointment - /multiple-candidate/bill/opno=${opno}&appointmentId=${id}

            // For nura-desk, redirect to appointment detail page
           
            if (props.redirect) {
                // For registration add and edit of nura
                // if (props.evalRequired) {

                //     // opno = result.result.op_no

                //     opno = result.op_no
                // } else {

                //     //  #NOTE Sneha - Used for appointment flow

                //     opno = result.opReg.op_no
                // }

                if (params.appointmentId) {
                    result = {
                        ...result,
                        appointmentId: params.appointmentId,
                    };
                }

                let keys = Object.keys(result);

                let finalRedirectUrl;

                keys.forEach((key) => {
                    if (key === 'op_no' || key === 'da_id') {
                        finalRedirectUrl = props.redirect.replace(':id', result[key]);
                    }
                    if (key === 'appointmentId') {
                        finalRedirectUrl = finalRedirectUrl.replace(':appointmentId', result[key]);
                    }

                    console.log(finalRedirectUrl);
                });

                Location.navigate({ method: 'replace', url: `${finalRedirectUrl}` });

                setBtnLoading(false);

                // Location.navigate({ url: props.redirect + result.da_id })

                // In case of new registration it will redirect to billing page else in case of edit registration redirect back to registration detail page (in nura)
                // if (props.appointmentMode === 'OPREG') {

                // Location.navigate({ url: `/multiple-candidate/bill?op_no=${opno}` });

                // } else {

                //     Location.navigate({ url: `/registrations/${opno}` });
                // }
            } else {
                //For stoptb visit and bill is created
                if (props.extraBillingParams) {
                    setBtnLoading(false);

                    Location.navigate({ url: `/patient-visits-new` });
                } else {
                    //For alsalama navigate to billing page
                    Location.navigate({ url: `/multiple-candidate/bill`, queryParam });

                    setBtnLoading(false);
                }
            }
        } else {
            //for already taken appointment
            if (response.setting) {
                if (response.verified === true) {
                    if (response.setting === 'NAP') {
                        onFinish({});
                    } else if (response.setting === 'MND') {
                        setAlreadyBooked(false);
                    } else {
                        setAlreadyBooked(true);
                    }
                } else {
                    // Check the value of the setting property in the response object
                    if (response.setting === 'MND') {
                        // If setting is 'MND', set mandatory setting and already booked to true
                        setMandatorySetting(true);
                        setAlreadyBooked(true);
                    } else if (response.setting === 'NAP') {
                        // If setting is 'NAP', set already exist to true
                        setAlreadyExist(true);
                    } else {
                        // For any other setting, set already booked to true
                        setAlreadyBooked(true);
                    }
                }
            }
            //for other issues(eg:slot already taken)
            else {
                setBtnLoading(false);
                message.error(response.message);
            }
        }
    }
    /**
     *  function used  if the corperate selected then the bill value want to change
     * @param {*} value
     */
    async function onselectChange(value) {
        // query params

        let record;
        if (params.da_packagetypevalue) {
            record = {
                // set package value as array .
                // it's maintained for billing screen functionality
                package_value: [params.da_packagetypevalue],
                type: props.corporate_type,
                corporate_pointer: value,
            };
        }

        // get call

        let corporateRate = await Items.getItemRateMapping(record);
        // if the corperate is selected then want to set bill value
        if (corporateRate.success) {
            setTotal(corporateRate.data[0].rate);
        }
    }
    /**
     * Function to be feeded for reference select
     * to default the referrer for all
     * cases the one which has the lowest refr_code
     *
     * @param {*} options
     * @returns
     */
    function identifyDefaultReferrer(options = []) {
       
       /* It updates the `initial` value and assigns the matching record to the `matching` variable. Finally, it returns the matching record. */
        let initial = initialValues.camp_site || 1

        let matching = {};
        if (!initialValues.camp_site) {
            options.forEach((record) => {
                if (initial >= parseInt(record['refr_code'])) {
                    initial = parseInt(record['refr_code']);

                    matching = record;
                }
            });

            return matching;
        }
    }

    // set dob validation in registration page . The Date of Birth should be Mandatory in the registration screen, even if the Age is given
    // let dobValidation = false;

    // if (params.redirect && !params.edit) {

    //     dobValidation = true;

    // }

    // Note : We are adding this common set of rules to disallow reserved variables to the input
    // This however is a temporary hack . As we need to define a common / extendable component
    // that needs to be used as a base component that would filter any input data to avoid SQL injection or
    // any threat .
    let commonInputRules = [
        {
            pattern: /^(?:(?!').)*$/,
            message: "' is not allowed!",
        },
    ];

    // Maintaining a prop to control latin characters in Name
    let enableAllCharacters = props.enableAllCharacters;

    // Enable the latin
    if (!enableAllCharacters) {
        // To the common input rules , we need to concat additional controls
        commonInputRules = [].concat(commonInputRules, {
            pattern: /^[a-zA-Z\s]*$/g,
            message: t('Please Enter Valid Name!'),
        });
    }
    /* The code is accessing the value of a form field with the name 'title' using the
`getFieldValue` method of the `form` object. Its for , title is 'empty' case and manage with attribute 'editieldsDisable' for editable and noneditable */
    // Access form values
    // const titleValue = form.getFieldValue('title');
    // //  Determine if the Select should be disabled case of 
    // const isDisabled = titleValue ? props.editFieldsDisable : false;

   /* The code is accessing the value of a form field with the name 'title' using the
`getFieldValue` method of the `form` object. Its for , title is 'empty' case and manage with attribute 'editieldsDisable' for editable and noneditable */
    // Access form values
    const titleValue = form.getFieldValue('title');
    //  Determine if the Select should be disabled case of
    const isDisabled = titleValue ? props.editFieldsDisable : false;
    return loading ? (
        <Skeleton />
    ) : (
        <div className="registration-screen">
            {/** Guest card section start */}
            <div className="guest-details card ">
                <Form
                    form={form}
                    {...formItemLayout}
                    onValuesChange={onValuesChange}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    initialValues={initialValues}
                >
                    <div className="guest-information">
                        {/** Page Header section start */}
                        {/* <div className="page-header">
                            <div>
                                <Title level={4}>{t('GUEST INFORMATION')}</Title>

                                <p className="page-desc">{t('Please enter the below details to complete the registration.')}</p>
                            </div>
                        </div> */}
                        {/** Page Header section start */}

                        {/** Form Content of patient section start */}
                        <div className="form-content">
                            <div className="steps-content">
                                <Row gutter={[16, 0]}>
                                    {' '}
                                    {/* Adjust gutter spacing as needed */}
                                    {fields.email_address.visible ? (
                                        <Col xs={24} md={12} xl={12}>
                                            <Form.Item
                                                autoFocus={true}
                                                name="email_address"
                                                label={fields.email_address.caption}
                                                rules={[
                                                    {
                                                        type: 'email',
                                                        message: `The input is not valid ${fields.email_address.caption}`,
                                                    },
                                                    {
                                                        required: fields.email_address.required,
                                                        message: `Please Enter ${fields.email_address.caption}`,
                                                    },
                                                    {
                                                        max: 100,
                                                        message: t('Invalid Email!'),
                                                    },
                                                ]}
                                                validateStatus={validation && validation.email_address.validate_status}
                                                help={validation && validation.email_address.help_text}
                                                // validateStatus={!initialValues.email_address && props.completeProfile ? 'warning' : ''}
                                                // help={!initialValues.email_address && props.completeProfile ? 'Please fill the details' : ''}
                                            >
                                                <Input mode={'lowercase'} disabled={props.disabled || props.editFieldsDisable||props.verifiedDetails} onBlur={onBlur} />
                                            </Form.Item>
                                        </Col>
                                    ) : null}
                                    {params.edit ? null : (
                                        <Col xs={24} md={12} xl={12} style={{ display: 'flex', paddingTop: '32px' }}>
                                            <Button type="primary" onClick={() => onBlur()}>
                                                {t('Check Email')}
                                            </Button>
                                        </Col>
                                    )}
                                </Row>

                                <Row gutter={20}>
                                    {fields.title.visible ? (
                                        <Col xs={24} md={12} xl={6}>
                                            <Form.Item
                                                name="title"
                                                label={fields.title.caption}
                                                rules={[
                                                    { required: fields.title.required, message: t('Please Select Title') },
                                                    {
                                                        validator(_, value) {
                                                            var genderValid;

                                                            // Validate if the selcted title and gender is suitable for the gender of the item
                                                            if (value === 'Mr.') {
                                                                genderValid = 'Male';
                                                            } else if (value === 'Ms.' || value === 'Mrs.') {
                                                                genderValid = 'Female';
                                                            } else if (value === 'Dr.') {
                                                                genderValid = 'Both';
                                                            } else {
                                                                genderValid = 'Both';
                                                            }
                                                            const validGender = Opreg.validateGender(genderValid, genderItem);
                                                            if (!validGender) {
                                                                return Promise.reject(
                                                                    'This title cannot be used as it does not match with Package gender'
                                                                );
                                                            } else {
                                                                return Promise.resolve();
                                                            }
                                                        },
                                                    },
                                                ]}
                                                validateStatus={!initialValues.title && props.completeProfile ? 'warning' : ''}
                                                help={!initialValues.title && props.completeProfile ? 'Please fill the details' : ''}
                                            >
                                                <Select showSearch optionFilterProp="children" disabled={props.disabled || isDisabled}>
                                                    <Option value="Mr.">{t('Mr')}.</Option>
                                                    <Option value="Ms.">{t('Ms')}.</Option>
                                                    <Option value="Mrs.">{t('Mrs')}.</Option>
                                                    <Option value="Dr.">{t('Dr')}.</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    ) : null}

                                    {fields.first_name.visible ? (
                                        <Col xs={24} md={12} xl={6}>
                                            <Form.Item
                                                name="first_name"
                                                label={fields.first_name.caption}
                                                rules={[
                                                    ...[
                                                        {
                                                            required: fields.first_name.required,
                                                            message: t('Please Enter First Name'),
                                                        },
                                                        {
                                                            max: 100,
                                                            message: t('Length cannot exceed 100 characters !'),
                                                        },
                                                    ],
                                                    ...commonInputRules,
                                                ]}
                                                validateStatus={validation && validation.first_name.validate_status}
                                                help={validation && validation.first_name.help_text}
                                                // validateStatus={!initialValues.first_name && props.completeProfile ? 'warning' : ''}
                                                // help={!initialValues.first_name && props.completeProfile ? 'Please fill the details' : ''}
                                            >
                                                <InputComponent disabled={props.disabled} />
                                            </Form.Item>
                                        </Col>
                                    ) : null}

                                    {fields.last_name.visible ? (
                                        <Col xs={24} md={12} xl={6}>
                                            <Form.Item
                                                name="last_name"
                                                label={fields.last_name.caption}
                                                rules={[
                                                    ...[
                                                        {
                                                            required: fields.last_name.required,
                                                            message: t('Please Enter Last Name'),
                                                        },
                                                        {
                                                            max: 100,
                                                            message: t('Length cannot exceed 100 characters !'),
                                                        },

                                                        // {
                                                        //     pattern: /^[a-zA-Z\s]*$/g,
                                                        //     message: 'Please Enter Valid Name!',
                                                        // },
                                                        ...commonInputRules,
                                                    ],
                                                ]}
                                                validateStatus={validation && validation.last_name.validate_status}
                                                help={validation && validation.last_name.help_text}
                                                // validateStatus={!initialValues.last_name && props.completeProfile ? 'warning' : ''}
                                                // help={!initialValues.last_name && props.completeProfile ? 'Please fill the details' : ''}
                                            >
                                                <InputComponent disabled={props.disabled} />
                                            </Form.Item>
                                        </Col>
                                    ) : null}
                                    {fields.gender.visible ? (
                                        <Col xs={24} md={12} xl={6}>
                                            <Form.Item
                                                name="gender"
                                                label={fields.gender.caption}
                                                rules={[{ required: fields.gender.required, message: t('Please Select Gender') }]}
                                                validateStatus={validation && validation.gender.validate_status}
                                                help={validation && validation.gender.help_text}
                                                // validateStatus={!initialValues.gender && props.completeProfile ? 'warning' : ''}
                                                // help={!initialValues.gender && props.completeProfile ? 'Please fill the details' : ''}
                                            >
                                                <Select disabled={props.disabled || props.editFieldsDisable || props.gender_validation}>
                                                    <Option value="Male">{t('Male')}</Option>
                                                    <Option value="Female">{t('Female')}</Option>
                                                    <Option value="Other">{t('Other')}</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    ) : null}
                                </Row>

                                <Row gutter={20}>
                                    {fields.date_of_birth.visible ? (
                                        <Form.Item
                                            name="date_of_birth"
                                            // label="DATE OF BIRTH"
                                            label={fields.date_of_birth.caption}
                                            rules={[{ required: true, message: t('Please Select Date of Birth ') }]}
                                            validateStatus={validation && validation.date_of_birth.validate_status}
                                            help={validation && validation.date_of_birth.help_text}
                                            // validateStatus={!initialValues.date_of_birth && props.completeProfile ? 'warning' : ''}
                                            // help={!initialValues.date_of_birth && props.completeProfile ? 'Please fill the details' : ''}
                                        >
                                            <AgeSelection
                                                field="date_of_birth"
                                                form={form}
                                                ageValues={
                                                    //In case of edit age should prepopulate
                                                    initialValues && initialValues.date_of_birth
                                                        ? {
                                                              dob: initialValues.date_of_birth.dob,
                                                              year: initialValues.date_of_birth.year,
                                                              month: initialValues.date_of_birth.month,
                                                              day: initialValues.date_of_birth.day,
                                                          }
                                                        : null
                                                }
                                                disabled={props.disabled}
                                                //The Date of Birth should be Mandatory in the registration screen, even if the Age is given.

                                                dobValidation={props.dobValidation}
                                            />
                                        </Form.Item>
                                    ) : null}

                                    {fields.mobile_number.visible ? (
                                        <Col xs={24} md={24} xl={12}>
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: fields.mobile_number.required,
                                                        message: `The input is not valid ${fields.mobile_number.caption}`,
                                                    },
                                                    // {
                                                    //     validator(_, value) {

                                                    //         if (value) {
                                                    //             // checking the number is valid or not
                                                    //             let numberValid = validatePhoneNumber(value.value, value.code.dialCode)

                                                    //             if (!numberValid.valid) {

                                                    //                 return Promise.reject('Please provide a valid mobile number');

                                                    //             } else {

                                                    //                 return Promise.resolve();

                                                    //             }
                                                    //         } else {
                                                    //             return Promise.resolve();
                                                    //         }
                                                    //     },
                                                    // },
                                                ]}
                                               
                                                name="mobile_number"
                                                label={fields.mobile_number.caption}
                                                className="phone-input"
                                                validateStatus={validation && validation.mobile_number.validate_status}
                                                help={validation && validation.mobile_number.help_text}
                                                //validateStatus={!initialValues.mobile_number && props.completeProfile ? 'warning' : ''}
                                                // help={!initialValues.mobile_number && props.completeProfile ? 'Please fill the details' : ''}
                                            >
                                                <CountryPhoneInput
                                                    disableCountryCode={disableCountryCode}
                                                    required={fields.mobile_number.required}
                                                    disabled={props.disabled}
                                                    defaultCountryCode={process.env.REACT_APP_COUNTRYCODE || 'in'}
                                                />
                                            </Form.Item>
                                        </Col>
                                    ) : null}

                                    {fields.alternate_mobile.visible ? (
                                        <Col xs={24} md={24} xl={12}>
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: fields.alternate_mobile.required,
                                                        message: `The input is not valid ${fields.alternate_mobile.caption}`,
                                                    },
                                                    // {
                                                    //     validator(_, value) {

                                                    //         if (value && value.value && value.code) {

                                                    //             // checking the number is valid or not
                                                    //             let numberValid = validatePhoneNumber(value.value, value.code.dialCode)

                                                    //             if (!numberValid.valid) {

                                                    //                 return Promise.reject('Please provide a valid mobile number');

                                                    //             } else {

                                                    //                 return Promise.resolve();

                                                    //             }
                                                    //         } else {
                                                    //             return Promise.resolve();
                                                    //         }

                                                    //     },
                                                    // },
                                                ]}
                                               
                                                name="alternate_mobile"
                                                label={fields.alternate_mobile.caption}
                                                className="phone-input"
                                                validateStatus={validation && validation.alternate_mobile.validate_status}
                                                help={validation && validation.alternate_mobile.help_text}
                                                // validateStatus={!initialValues.alternate_mobile && params['complete-profile'] ? 'warning' : ''}
                                                //help={!initialValues.alternate_mobile && params['complete-profile'] ? 'Please fill the details' : ''}
                                            >
                                                <CountryPhoneInput
                                                    disableCountryCode={disableCountryCode}
                                                    required={fields.alternate_mobile.required}
                                                    disabled={props.disabled}
                                                    defaultCountryCode={process.env.REACT_APP_COUNTRYCODE || 'in'}
                                                />
                                            </Form.Item>
                                        </Col>
                                    ) : null}

                                    {/* Communication Mode */}
                                    {fields.mode_of_communication.visible ? (
                                        <Col xs={24} md={12} xl={12}>
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: fields.mode_of_communication.required,
                                                        message: t('The input is not valid selection!'),
                                                    },
                                                ]}
                                                name="mode_of_communication"
                                                label={fields.mode_of_communication.caption}
                                                className="phone-input"
                                                validateStatus={validation && validation.mode_of_communication.validate_status}
                                                help={validation && validation.mode_of_communication.help_text}
                                                //validateStatus={!initialValues.mode_of_communication && props.completeProfile ? 'warning' : ''}
                                                // help={!initialValues.mode_of_communication && props.completeProfile ? 'Please fill the details' : ''}
                                            >
                                                <ReferenceSelect
                                                    // disabled={disabledfield}
                                                    label="comt_desc"
                                                    mode="nura-base"
                                                    model={Communications}
                                                    field={'comt_code'}
                                                    disabled={props.disabled}
                                                />
                                            </Form.Item>
                                        </Col>
                                    ) : null}

                                    {fields.category.visible ? (
                                        <Col xs={24} md={12} xl={12}>
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: fields.category.required,
                                                        message: t('Please select Category'),
                                                    },
                                                ]}
                                                name="category"
                                                label={fields.category.caption}
                                                className="phone-input"
                                                validateStatus={validation && validation.category.validate_status}
                                                help={validation && validation.category.help_text}
                                                // validateStatus={!initialValues.category && props.completeProfile ? 'warning' : ''}
                                                // help={!initialValues.category && props.completeProfile ? 'Please fill the details' : ''}
                                            >
                                                <Select disabled={props.disabled}>
                                                    <Option value="GEN">{t('General')}</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    ) : null}
                                </Row>
                                <Row gutter={20}>
                                    {fields.national_id.visible ? (
                                        <Col xs={24} md={12} xl={8}>
                                            <Form.Item
                                                name={'national_id'}
                                                label={fields.national_id.caption}
                                                rules={[
                                                    {
                                                        required: fields.national_id.required,
                                                        message: `Please enter ${fields.national_id.caption}`,
                                                    },
                                                ]}
                                                validateStatus={!initialValues.national_id && props.completeProfile ? 'warning' : ''}
                                                help={!initialValues.national_id && props.completeProfile ? 'Please fill the details' : ''}
                                            >
                                                <ReferenceSelect
                                                    label="lcnt_nationalitydesc"
                                                    mode="nura-base"
                                                    model={ListCountries}
                                                    field={'lcnt_id'}
                                                    defaultValueCondition={identifyDefaultCountry} //Default value condition for a component, using the identifyDefaultCountry function to determine the default country.
                                                />
                                            </Form.Item>
                                        </Col>
                                    ) : null}
                                    {fields.nature.visible ? (
                                        <Col xs={24} md={12} xl={8}>
                                            <Form.Item
                                                name={'nature'}
                                                label={fields.nature.caption}
                                                rules={[
                                                    {
                                                        required: fields.nature.required,
                                                        message: `Please select nature`,
                                                    },
                                                ]}
                                            >
                                                <ReferenceSelect
                                                    label="desc"
                                                    mode="nura-base"
                                                    model={NatureList}
                                                    field={'code'}
                                                />
                                            </Form.Item>
                                        </Col>
                                    ) : null}
                                        {fields.passport.visible ? (
                                        <Col xs={10} md={10} xl={8}>
                                            <Form.Item                                             
                                                name="passport"
                                                label={fields.passport.caption}
                                                className="phone-input"
                                                rules={[
                                                    
                                                    {
                                                        max: 10,
                                                        message: t('Length cannot exceed 10 characters'),
                                                    },
                                                ]}
                                            >
                                                <InputComponent />
                                            </Form.Item>
                                        </Col>
                                    ) : null}
                                    
                                </Row>                               


                                {/**guestPreferenceDisableis the attribute used to check if the case is of nura or stoptb
                                 * Here is guestPreferenceDisable is true, campsite is shown as a part of basic information
                                 */}
                                <Row gutter={20}>
                                    {fields.camp_site.visible && props.guestPreferenceDisable ? (
                                        <Col xs={24} md={12} xl={8}>
                                            <Form.Item
                                                name={'camp_site'}
                                                label={fields.camp_site.caption}
                                                rules={[
                                                    {
                                                        required: fields.camp_site.required,
                                                        message: `Please enter ${fields.camp_site.caption}`,
                                                    },
                                                ]}
                                                validateStatus={validation && validation.camp_site.validate_status}
                                                help={validation && validation.camp_site.help_text}
                                                // validateStatus={!initialValues.camp_site && props.completeProfile ? 'warning' : ''}
                                                // help={!initialValues.camp_site && props.completeProfile ? 'Please fill the details' : ''}
                                            >
                                                <ReferenceSelect
                                                    label="refr_desc"
                                                    mode="nura-base"
                                                    model={Referrers}
                                                    // disable Referrer
                                                    disabled={props.disableReferrer}
                                                    config={{
                                                        queries: [
                                                            {
                                                                field: 'refr_active',

                                                                value: 'Y',
                                                            },
                                                        ],
                                                    }}
                                                    field={'refr_code'}
                                                    defaultValueCondition={identifyDefaultReferrer}
                                                />
                                            </Form.Item>
                                        </Col>
                                    ) : null}
                                    {fields.othernatid.visible ? (
                                    <Col xs={10} md={10} xl={8}>
                                        <Form.Item                                             
                                            name="othernatid"
                                            label={fields.othernatid.caption}
                                            className="phone-input"
                                            rules={[
                                                
                                                {
                                                    max: 25,
                                                    message: t('Length cannot exceed 25 characters'),
                                                },
                                            ]}
                                        >
                                            <Input disabled={props.emiratesCardDisable} />

                                        </Form.Item>
                                    </Col>
                                    ) : null}
                                    {fields.remarks.visible ? (
                                        <Col xs={10} md={10} xl={10}>
                                            <Form.Item
                                                rules={[
                                                    ...[
                                                        {
                                                            required: fields.remarks.required,
                                                            message: t('Enter Remarks'),
                                                        },
                                                    ],
                                                    ...commonInputRules,
                                                ]}
                                                validateStatus={validation && validation.remarks.validate_status}
                                                help={validation && validation.remarks.help_text}
                                                // validateStatus={!initialValues.remarks && props.completeProfile ? 'warning' : ''}
                                                // help={!initialValues.remarks && props.completeProfile ? 'Please fill the details' : ''}
                                                name="remarks"
                                                label={fields.remarks.caption}
                                                className="phone-input"                                              
                                            >
                                                <InputComponent />
                                            </Form.Item>
                                        </Col>
                                    ) : null}
                                </Row>
                                <Row gutter={20}>
                                    {fields.corporate.visible ? (
                                        <Col xs={24} md={6} xl={6}>
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: fields.corporate.required,
                                                        message: t('Enter Remarks'),
                                                    },
                                                ]}
                                                name="corporate"
                                                label={fields.corporate.caption}
                                                className="phone-input"
                                                validateStatus={validation && validation.corporate.validate_status}
                                                help={validation && validation.corporate.help_text}
                                                // /validateStatus={!initialValues.corporate && props.completeProfile ? 'warning' : ''}
                                                // help={!initialValues.corporate && props.completeProfile ? 'Please fill the details' : ''}
                                            >
                                                <Checkbox onChange={callBack} checked={corporate} defaultChecked={corporate} />
                                            </Form.Item>
                                        </Col>
                                    ) : null}

                                    {/* 
                                    Only if the is corporate is true , we 
                                    have to show the below block
                                    */}
                                    {corporate ? (
                                        <>
                                        
                                            {fields.corporate_pointer.visible ? (
                                                <Col xs={24} md={12} xl={8}>
                                                    <Form.Item
                                                        rules={[
                                                            {
                                                                required: fields.corporate_pointer.required,
                                                                message: `Enter ${fields.corporate_pointer.caption}`,
                                                            },
                                                        ]}
                                                        name="corporate_pointer"
                                                        label={fields.corporate_pointer.caption}
                                                        className="phone-input"
                                                        validateStatus={validation && validation.corporate_pointer.validate_status}
                                                        help={validation && validation.corporate_pointer.help_text}
                                                        // validateStatus={
                                                        //     !initialValues.corporate_pointer && params['complete-profile'] ? 'warning' : ''
                                                        // }
                                                        // help={
                                                        //     !initialValues.corporate_pointer && params['complete-profile']
                                                        //         ? 'Please fill the details'
                                                        //         : ''
                                                        // }
                                                    >
                                                        <ReferenceSelect
                                                            // onChange={onCorperateChange}
                                                            config={{
                                                                queries: [
                                                                    {
                                                                        field: 'cc_active',

                                                                        value: 'Y',
                                                                    },
                                                                ],
                                                            }}
                                                            onChange={(value) => onselectChange(value)}
                                                            label="cc_desc"
                                                            mode="nura-base"
                                                            model={Customers}
                                                            field={'cc_code'}
                                                            disabled={props.disableCorporate} // This will disable corporate
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            ) : null}

                                            {fields.corporate_information.visible ? (
                                                <Col xs={24} md={12} xl={8}>
                                                    <Form.Item
                                                        rules={[
                                                            {
                                                                required: fields.corporate_information.required,
                                                                message: `Enter ${fields.corporate_information.caption}`,
                                                            },
                                                        ]}
                                                        name="corporate_information"
                                                        label={fields.corporate_information.caption}
                                                        className="phone-input"
                                                        validateStatus={validation && validation.corporate_information.validate_status}
                                                        help={validation && validation.corporate_information.help_text}
                                                        // validateStatus={
                                                        //     !initialValues.corporate_information && props.completeProfile ? 'warning' : ''
                                                        // }
                                                        // help={
                                                        //     !initialValues.corporate_information && props.completeProfile
                                                        //         ? 'Please fill the details'
                                                        //         : ''
                                                        // }
                                                    >
                                                        {/* disable corporate value  */}
                                                        <InputComponent  disabled={props.disableCorporate} />
                                                    </Form.Item>
                                                </Col>
                                            ) : null}
                                        </>
                                    ) : null}
                                </Row>

                                {props.disable_cluster ? null : (
                                    <>
                                        {/* Communication Mode */}
                                        <Row gutter={20}>
                                            <AdditionalKeyGenerate
                                                seperator={props.seperator}
                                                form={form}
                                                callback={(values) => (additionalkey = values)}
                                                // In case of edit cluster_number and serial_number needs to be prepopulated
                                                additionalKeyValues={
                                                    initialValues && initialValues.cluster_number
                                                        ? {
                                                              cluster_number: initialValues.cluster_number,
                                                              serial_number: initialValues.serial_number,
                                                          }
                                                        : null
                                                }
                                                //Only in case of edit cluster field should be readonly
                                                edit={params.edit ? true : false}
                                            ></AdditionalKeyGenerate>
                                        </Row>
                                    </>
                                )}

                                {/* Communication Mode Ends */}
                            </div>
                            {/** Form content  of patient section Ends */}

                            {/** other details of patient section start */}

                            <div className="other-details ">
                                <div className="section-2">
                                    <Tabs defaultActiveKey="0">
                                        {/** Tab used for address details of guest start */}
                                        <TabPane tab={t('ADDRESS DETAILS')} key="0">
                                            <AddressDetails config={config} props={props} initialValues={initialValues} validation={validation} />
                                        </TabPane>

                                        {/** Tab used for address details of guest end */}

                                        {/** Tab used for Registration details of guest start */}

                                        {/* <TabPane tab="REGISTRATION DETAILS" key="1">
                                                <GuestRegistrationAddressDetails config={config} props={props} />
                                            </TabPane> */}
                                        {/** Tab used for Registration details of guest end */}

                                        {/** Tab used for personal details of guest start */}

                                        {/* <TabPane tab="PERSONAL DETAILS" key="2">
                                                <PersonalDetails config={config} />
                                            </TabPane> */}
                                        {/** Tab used for address details of guest ends */}

                                        {/** Tab used for Campaign details of guest start */}

                                        {/* <TabPane tab="CAMPAIGN DETAILS" key="3"> */}
                                        {/**guestPreferenceDisableis the attribute used to check if the case is of nura or stoptb
                                         * Here is guestPreferenceDisable is true, campsite is shown as a part of basic information
                                         * Here it is disabled
                                         */}
                                        {/* {props.guestPreferenceDisable ? null : <CampaignDetails config={config} />} */}
                                        {/* </TabPane> */}
                                        {/** Tab used for Other details of guest start */}
                                        {props.otherdetails_disable ? null : (
                                            <TabPane tab="OTHER DETAILS" key="4">
                                                <OtherDetailsOfGuest config={config} />
                                            </TabPane>
                                        )}
                                        {/** Tab used for other details of guest end */}
                                    </Tabs>
                                </div>
                            </div>
                            {/** other details of patient section ends */}
                        </div>
                    </div>

                    <div className="footer-actions">
                        <div className="actions">
                            <Button type="primary" htmlType="submit" loading={btnloading}>
                                {t('Submit')}
                            </Button>

                            {/* <Button htmlType="submit">
                                        Cancel
                                    </Button> */}
                        </div>
                    </div>
                </Form>
                {/**Modal component to handle the scenario where a user already exists and The settig is 'NAP' */}
                <Modal
                    maskClosable={false}
                    destroyOnClose={true} // Ensure the modal is destroyed on close to reset its state
                    title="" // No title for the modal
                    visible={alreadyExist} // Control the visibility of the modal
                    okText="Yes" // Text for the OK button
                    cancelText="NO" // Text for the Cancel button
                    closable={false}
                    onOk={() => {
                        // Callback for when the OK button is clicked
                        onFinish({ verified: true }); // Execute onFinish with verified parameter
                        setBtnLoading(false); // Stop any button loading state
                        setAlreadyExist(false); // Hide the modal
                    }}
                    onCancel={() => {
                        // Callback for when the Cancel button is clicked
                        setBtnLoading(false); // Stop any button loading state
                        setAlreadyExist(false); // Hide the modal

                        onFinish({ verified: true, existing_user: true }); // Execute onFinish with specific parameters
                    }}
                >
                    <p>There is an account already registered with given details. Are you taking appointment for the same guest ?</p>
                </Modal>

                {/** if there is a appoinment with same email and name*/}
                <Modal
                    destroyOnClose={true}
                    title=""
                    visible={alreadyBooked}
                    cancelButtonProps={{
                        style: {
                            display: mandatorySetting ? 'none' : 'true',
                        },
                    }}
                    okText="Okay"
                    onOk={() => {
                        onFinish({ verified: true });
                        setBtnLoading(false);
                        setAlreadyBooked(false);
                    }}
                    onCancel={() => {
                        setBtnLoading(false);
                        setAlreadyBooked(false);
                    }}
                >
                    {mandatorySetting ? (
                        <p>{t('You already have an Appointment booked So you cant take an Appointment')}</p>
                    ) : (
                        <p>{t('You already have an Appointment for this Do you wish to proceed?')}</p>
                    )}
                </Modal>
                {/** Modal to list existing customer with same mail address starts */}
                <Modal
                    destroyOnClose={true}
                    footer={null}
                    title={t('Account Selection')}
                    visible={existingCustomerVisible}
                    width={'90%'}
                    okText="Okay"
                    cancelText="Cancel"
                    onOk={() => {
                        setExistingCustomerVisible(false);
                    }}
                    onCancel={() => {
                        setExistingCustomerVisible(false);
                    }}
                >
                    <ExistingCustomerList
                        selectedCustomer={selectedCustomer}
                        existingCustomers={existingCustomers}
                        props={props}
                        
                        callBack={(element) => {
                            setSelectedCustomer(element);
                        }}
                        setVisible={() => setExistingCustomerVisible(false)}
                    />
                </Modal>
                {/** Modal to list existing customer with same mail address ends */}
                {/** Modal for change address alert */}
                <Modal
                    destroyOnClose={true}
                    open={changeAddressAlert}
                    width={'50%'}
                    okText="Yes"
                    cancelText="No"
                    onOk={() => {
                        const key = {
                            pincode: form.getFieldValue('pincode'),
                        };
                        const confirmed = true;
                        onValuesChange(key, form.getFieldsValue(), confirmed);
                        setChangeAddressAlert(false);
                    }}
                    onCancel={() => {
                        setChangeAddressAlert(false);
                    }}
                >
                    <p>{t('Do you wish to change the address with respect to the pincode entered?')}</p>
                    {addressChangedFields.map((ele) => {
                        return (
                            <p>
                                {ele.field} : {ele.previousValue} to {ele.newvalue}
                            </p>
                        );
                    })}
                </Modal>
                {/**  Modal for change address alert */}
            </div>
            {/** GuestPreferences card section start */}
            <div className="primary-details">
                {/* Guest Preference */}
                {props.guestPreferenceDisable ? null : <GuestPreferences />}
                {/* Guest Preference Ends */}

                {/* </div> */}
                {/* ) : null} */}
                {/*  */}
                {initialValues && props.couponSection !== false ? (
                    <>
                        {/* Summary Section */}
                        <Summary
                            setGender={setGenderItem}
                            hideDiscountButton={props.hideDiscountButton}
                            disableApplyCoupon={props.disableApplyCoupon}
                            appliedDiscount={discount}
                            appliedCoupon={coupon}
                            totalValue={total}
                            corporate={corporate}
                            setItems={setItems}
                            callback={(values, mode, total, result) => {
                                /** Corresponding corporate should be auto populated when typing a coupon */
                                if (result.success && result.data.corporate_pointer) {
                                    setCorporate(true);

                                    form.setFieldsValue({ corporate_pointer: result.data.corporate_pointer });
                                    // referrer should be auto populated when typing a coupon
                                } else if (result.data.camp_site) {
                                    form.setFieldsValue({ camp_site: result.data.camp_site });
                                }
                                if (mode === 'coupon') {
                                    // If coupon is null, it indicates applied coupon was cancelled
                                    // If coupon is present set discount as null
                                    if (values === null) setCoupon(null);
                                    else setCoupon(result.data);
                                    setDiscount(null);
                                } else if (mode === 'discount') {
                                    // If discount is null, it indicates applied discount was cancelled
                                    // If discount is present st coupon as null
                                    if (values === null) setDiscount(null);
                                    else setDiscount(values.discount);
                                    setCoupon(null);
                                }
                                setTotal(total);
                            }}
                        />
                        {/* Summary Section Ends */}
                    </>
                ) : null}
                {props.emiratesCardDisable ? null : <ReadEmiratesId  loadGuestInformation={loadGuestInformation}/>

}

            </div>

            {/** GuestPreferences card section ends  */}
        </div>
    );
}
