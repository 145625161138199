/**
 *
 * @author Sameena Ammad
 *  To list all the customer who have used the same email address previously
 *  If any customer is selected, the case is handled as an existing customer repeated case
 *  Else, The normal flow is applied
 * @param {*}
 * @returns
 */

import { Button, Card, Row, Col } from 'antd';

import { Location, useTranslation } from 'soxo-bootstrap-core';

import { CheckCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Appointments } from '../../../models';
import React, { useState } from 'react';
import './list-existing-customers';

export default function ExistingCustomerList({ selectedCustomer, existingCustomers, callBack, setVisible,props }) {
    const params = Location.search();

    const [btnLoading, setBtnLoading] = useState(false);

    const { t, i18n } = useTranslation();  // To Translate to another language

    const [disabled, setDisabled] = useState(true);
    async function onFinish() {
        setBtnLoading(true);

        let queryParam;

        // If the customer is not a repeated customer of the same location, we will have to create a prereg or opreg in this location
        if (selectedCustomer && selectedCustomer.db_ptr && selectedCustomer.db_ptr !== process.env.REACT_APP_DB_PTR.toString()) {
            const opReg = await Appointments.createExistingCustomer(selectedCustomer);

            if (opReg && opReg.opReg && opReg.opReg.op_id) {
                queryParam = {
                    ...params,
                    id: opReg.opReg.op_id,
                    table: 'opreg',
                    repeat: true,
                };
            } else if (opReg && opReg.preReg && opReg.preReg.prereg_id) {
                queryParam = {
                    ...params,
                    id: opReg.preReg.prereg_id,
                    table: 'prereg',
                    repeat: true,
                };
            }
        } else {
            // maintain the case is repeated, the opreg or prereg id and the mode in url
            if (selectedCustomer && selectedCustomer.op_id) {
                queryParam = {
                    ...params,
                    id: selectedCustomer.op_id,
                    table: 'opreg',
                    repeat: true,
                };
            } else if (selectedCustomer && selectedCustomer.prereg_id) {
                queryParam = {
                    ...params,
                    id: selectedCustomer.prereg_id,
                    table: 'prereg',
                    repeat: true,
                };
                
            }
        }

        setBtnLoading(false);

        // Navigate to existing customer flow url and icarus(complete-profile url).
    
        Location.navigate({ url: props.user_redirect_existing, queryParam })
    
        
    }

    return (
        <div className="existing-customer">
            <div className="customer-details">
                <div>
                    <span>
                        {' '}
                        We have found that {existingCustomers.length} {existingCustomers.length > 1 ? 'accounts' : 'account'} are already linked to
                        the email address <strong>{existingCustomers[0].email}</strong>
                    </span>
                    <br />
                    <span>{t('Please select an account to continue the Appointment Process')}</span>
                </div>

                <Button onClick={() => setVisible()}>
                    <PlusOutlined />
                        {t('Book For Another Person')}
                </Button>
            </div>

            <Row>
                {existingCustomers &&
                    existingCustomers.length &&
                    existingCustomers.map((element, index) => (
                        <Col key={index} span={12}>
                            <Card
                                className={`mode ${
                                    selectedCustomer.prereg_id
                                        ? selectedCustomer.prereg_id === element.prereg_id
                                            ? 'active'
                                            : ''
                                        : selectedCustomer.op_id === element.op_id
                                        ? 'active'
                                        : ''
                                }`}
                                onClick={() => {
                                    callBack(element);
                                    setDisabled();
                                }}
                            >
                                <div className="primary-element">
                                    <div className="detail-element">
                                        <CheckCircleOutlined />
                                        <h4>
                                            <strong>
                                                {' '}
                                                {element.first_name.toUpperCase()} {element.last_name.toUpperCase()}
                                            </strong>
                                        </h4>
                                    </div>
                                    <div className="details">
                                        {element && element.op_number ? (
                                            <>
                                                <div>
                                                    <Row>
                                                        <span>{t('OP NUMBER')}</span>
                                                    </Row>
                                                    <Row>
                                                        <span>
                                                            <strong>{element.op_number}</strong>
                                                        </span>
                                                    </Row>
                                                </div>
                                            </>
                                        ) : (
                                            <div>
                                                <Row>
                                                    <span>{t('ACCOUNT ID')}</span>
                                                </Row>
                                                <Row>
                                                    <span>
                                                        <strong>{element.prereg_id}</strong>
                                                    </span>
                                                </Row>
                                            </div>
                                        )}
                                        <div className="vertical-line" />
                                        <div>
                                            <Row>
                                                <span>{t('USERNAME')}</span>
                                            </Row>
                                            <Row>
                                                <span>
                                                    <strong>{element.user_name}</strong>
                                                </span>
                                            </Row>
                                        </div>
                                        <div className="vertical-line" />
                                        <div>
                                            <Row>
                                                <span>{t('MOBILE NUMBER')}</span>
                                            </Row>
                                            <Row>
                                                <span>
                                                    <strong>{element.mobile1}</strong>
                                                </span>
                                            </Row>
                                        </div>
                                        <div className="vertical-line" />

                                        <div>
                                            <Row>
                                                <span>{t('DATE')}</span>
                                            </Row>
                                            <Row>
                                                <span>
                                                    <strong> {element.date}</strong>
                                                </span>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    ))}
            </Row>
            {/* {existingCustomers &&
                existingCustomers.length &&
                existingCustomers.map((element) => {
                    return (
                        
                        <Card
                            className={`mode ${
                                selectedCustomer.prereg_id
                                    ? selectedCustomer.prereg_id === element.prereg_id
                                        ? 'active'
                                        : ''
                                    : selectedCustomer.op_id === element.op_id
                                    ? 'active'
                                    : ''
                            }`}
                            onClick={() => {
                                callBack(element);
                                // setDisabled(false);
                            }}
                        >
                            <div className="primary-element">
                                <h4>
                                    <CheckCircleOutlined /> {element.first_name} {element.last_name}
                                </h4>
                                <div className="details">
                                    {element && element.op_number ? (
                                        <>
                                            <div>
                                                <p>OP Number</p>
                                                <p>{element.op_number}</p>
                                            </div>
                                        </>
                                    ) : (
                                        <div>
                                            <p>Account ID</p>
                                            <p>{element.prereg_id}</p>
                                        </div>
                                    )}
                                    <div className="vertical-line" />
                                    <div>
                                        <p>Username</p>
                                        <p> {element.user_name}</p>
                                    </div>
                                    <div className="vertical-line" />
                                    <div>
                                        <p>Mobile Number</p>
                                        <p> {element.mobile1}</p>
                                    </div>
                                    <div className="vertical-line" />
                                    <div>
                                        <p>Address</p>
                                        <p> {element.address1}</p>
                                        <p> {element.address2}</p>
                                    </div>
                                    <div className="vertical-line" />
                                    <div>
                                        <p>Date</p>
                                        <p> {element.date}</p>
                                    </div>
                                </div>
                            </div>
                        </Card>
                        
                    );
                })} */}

            <div style={{ marginBottom: '12px', display: 'flex', gap: '0.5rem' }}>
                <Button type="primary" htmlType="submit" onClick={() => onFinish()} loading={btnLoading} disabled={disabled}>
                    {t('Continue')}
                </Button>
                <Button onClick={() => setVisible()}>{t('Cancel')}</Button>
            </div>
        </div>
    );
}
