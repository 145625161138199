/**
 *
 * Component for Previewing reports button which is used for reporting dashboard reports
 *
 * @description
 * @author R Lakshmy 
 *
 */

import React, { useState } from 'react';

import PrintPreview from '../print-preview/print-preview';

import './preview.scss';

import { Button, Modal } from 'antd';

/**
 *
 * @returns 
 */
export default function Preview({ receiptNO, groupPointer, templateCode, ...props }) {

    // state to manage modal view
    const [reportModalVisible, setReportModalVisible] = useState(false);


    /**
     *  Function to show modal
     */
    const showModal = () => {

        setReportModalVisible(true);
    };


    /**
     * Function to close modal
     */
    const handleClose = () => {

        setReportModalVisible(false);
    };

    return (

        <div>

            <Button type="primary" onClick={showModal} size="small" className='btncls'>

                Open Preview

            </Button>

            <Modal

                width={'80%'}

                height={'570px'}

                destroyOnClose={true}

                footer={null}

                title="Collection Preview"

                visible={reportModalVisible}

                onCancel={handleClose}

                okButtonProps={{ style: { display: 'none' } }}

            >
                {/* The component to preview a pdf */}
                <PrintPreview templatecode={templateCode} refereneceId={receiptNO} groupPointer={groupPointer} props={props} />

            </Modal>
            
        </div>
    );
}
