/**
 * EventMas Model
 * @authour Sneha
 *
 */

import { BaseAPI } from 'soxo-bootstrap-core';
import { ApiUtils } from 'soxo-bootstrap-core';

class EventMas extends BaseAPI {
    constructor() {
        super();
    }

    get id() {
        return 'evtm_id';
    }

    get getEndpoint() {
        return 'event-mas';
    }

    get path() {
        return `event-mas`;
    }

    get getName() {
        return this.modelName;
    }

    get modelName() {
        return `event-mas`;
    }

    getPackageList = (mode,submode) => {
        return ApiUtils.get({
            url: `event-mas/get-event-data?mode=${mode}&submode=${submode}`,
        });
    };

}

export default EventMas;