import React, { useState, useEffect, useContext } from 'react';

//
import { DatePicker, Typography, Button, Tag, Card, Table, Skeleton } from 'antd';

import { ReloadOutlined } from '@ant-design/icons';

import { Collections } from '../../../../models';

import { Location, GlobalContext, useTranslation} from 'soxo-bootstrap-core';

import moment from 'moment-timezone';

import { RangePicker } from 'soxo-bootstrap-core';

const { Title, Text } = Typography;

export default function CollectionList({ props }) {
    var [patients, setPatients] = useState([]); //Patients list array

    const [loading, setLoading] = useState(false);

    const { user = {}, isMobile } = useContext(GlobalContext);

    var [query, setQuery] = useState('');

    const [view, setView] = useState(isMobile ? true : false); //Need to check this condition

    const [pageNo, setPageNo] = useState(); // Pagination number

    const [page, setPage] = useState(1);

    const { t, i18n } = useTranslation();   // To Translate to another language

    var param = Location.search();

    //Setting starttime and endtime within a week from today
    var starttime = moment.tz().startOf('day'),
        endtime = moment.tz().endOf('day').startOf('day');


    function refresh() {
        loadData();
    }

    if (param.start_time) {
        updateRange();
    }

    /**
     * Update Range
     * @returns 
     */
    function updateRange() {
        starttime = moment.tz(param.start_time, 'Asia/Calcutta').startOf('day');

        endtime = moment.tz(param.end_time, 'Asia/Calcutta').startOf('day');

        return starttime, endtime;
    }


    //Setting Range by default
    const [range, setRange] = useState([starttime, endtime]);

    useEffect(() => {
        // if (user) {

        loadCollections(range);
    }, []);

    async function loadCollections(range) {
        setLoading(true);

        Collections.loadCollections(range).then((result) => {
            setPatients(result);

            setLoading(false);
        });
    }

    // Location.navigate({
    //     url: `/colldetails`,
    // });

    /**
     * Load the data
     */

    function loadData() {
        let urlParams = Location.search();

        // Match params
        let params = {
            start_time: range[0].format('MM/DD/YYYY'),
            end_time: range[1].format('MM/DD/YYYY'),

            // limit: 20,

            ...urlParams,
        };
        loadCollections(params);
    }
    let dataSource = patients.filter((record) => {
        query = query.toUpperCase();

        if (query) {
            if (record.opbill.opb_name.toUpperCase().indexOf(query) != -1) {
                return true;
            } else if (record.opbill.opb_mobile && record.opb_mobile.indexOf(query) != -1) {
                return true;
            }
        } else {
            return true;
        }
    });

    // let dataSource =patients
    function updateTime(dt) {
        setRange(dt);

        setPage(1);

        Location.search({
            //  limit: 10 ,
            start_time: moment(dt[0]).format('MM/DD/YYYY'),
            end_time: moment(dt[1]).format('MM/DD/YYYY'),
        });

        loadCollections(dt)
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
             render: (value, item, index) => (page - 1) * 10 + index + 1,
        },

        {
            title: t('Name'),
            // dataIndex: 'Name',
            key: 'name',
            render: (ele) => {
                if (ele.opbill && ele.opbill.name) return ele.opbill.name;
            },
        },
        {
            title: t('Gender'),
            // dataIndex: 'id',

            key: 'genderr',
            render: (ele) => {
                if (ele.opbill && ele.opbill.gender) {
                    let gender = null;
                    if (ele.opbill.gender === 'Male') {
                        gender = 'Male';
                    } else if (ele.opbill.gender === 'M') {
                        gender = 'Male';
                    } else if (ele.opbill.gender === 'Female') {
                        gender = 'Female';
                    } else if (ele.opbill.gender) {
                        gender = 'Female';
                    }
                    return gender;
                } else {
                    return ele.gender;
                }
            },
        },
        {
            title: t('Bill Id'),
            // dataIndex: 'Name',
            key: 'bill_id',
            render: (ele) => {
                if (ele.opbill && ele.opbill.bill_id) return ele.opbill.bill_id;
            },
        },
        {
            title: t('Mobile'),
            // dataIndex: 'Name',
            key: 'mobile',
            render: (ele) => {
                if (ele.opbill && ele.opbill.mobile) return ele.opbill.mobile;
            },
        },
        {
            title: t('OP No'),
            // dataIndex: 'Name',
            key: 'opno',
            render: (ele) => {
                if(ele.opbill && ele.opbill.opno)
                return ele.opbill.opno ;
            },
        },
        {
            title: t('Total Amount'),
            dataIndex: 'total_amt',
            key: 'cold_amt',
        },
        {
            title: t('receipt no.'),
            dataIndex: 'recipt_no',
            key: 'receipt no.',
        },
        {
            title: t('Action'),
            disableExport: true,
            // dataIndex: "billed_status",
            fixed: 'right',
            key: 'action',

            render: (record) => {
                return (
                    <span>
                        <Button
                            onClick={() => {
                                Location.navigate({ url: `/receipts/${record.recipt_no}` });
                            }}
                            type="secondary"
                            size="small"
                            color="green"
                        >
                            {t('View')}
                        </Button>
                    </span>
                );
            },
        },
    ];

    return (
        <section className="collection-details">
            <div className="page-header">
                <div>
                    <Title style={{ marginBottom: '0px' }} level={3}>
                    {t('Collection List')}
                    </Title>
                    <p className="size-hint">{loading ? 'Loading' : patients.length} records.</p>
                </div>
                <div className="date-and-fltr">
                    {/* <div className="action-header">Date</div> */}
                    <RangePicker
                        allowClear={false}
                        inputReadOnly
                        format={'DD/MM/YYYY'}
                        value={range}
                        onChange={(time) => {
                            updateTime(time, range);
                        }}
                        ranges={{
                            Today: [moment(), moment()],

                            Yesterday: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],

                            'This Week': [moment().startOf('week'), moment().endOf('week')],

                            'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],

                            'This Month': [moment().startOf('month'), moment().endOf('month')],

                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                        }}
                    />

                    <Button onClick={refresh} type="secondary" size={'small'}>
                        <ReloadOutlined />
                    </Button>
                </div>
            </div>
            {/* Page Header Ends */}

            {loading ? (
                <>
                    <Skeleton active />
                </>
            ) : (
                <>
                    {view ? (
                        <>
                            <CardList dataSource={dataSource} />
                        </>
                    ) : (
                        <>
                            <Card>
                                <Table
                                    rowKey={(record) => record.AccessionNo}
                                    dataSource={dataSource}
                                    scroll={{ x: true }}
                                    columns={columns}
                                    pagination={{
                                        current: page,
                                        onChange(current) {
                                            setPage(current);
                                        },
                                    }}
                                />{' '}
                            </Card>
                        </>
                    )}
                </>
            )}

            {/* <div>{contextHolder}</div> */}
            {/* </> */}
        </section>
    );
}

//Mobile view card Section
function CardList({ dataSource, columns, url }) {
    const { user = {}, isMobile, dispatch } = useContext(GlobalContext);

    function onClick(item) {
        Location.navigate({
            url: `/colldetails/${item.id}`,
        });

        dispatch({ type: 'index', payload: item.rowIndex });
    }

    return dataSource.map((item, index) => {
        // to={`/lab-detail/${item.BillID}`}
        return (
            <div
                key={index}
                className="report-item"
                onClick={() => {
                    onClick(item);
                }}
            >
                <GuestCard record={item} />
            </div>
        );
    });
}

function GuestCard({ record, ele }) {
    const { t, i18n } = useTranslation();    // To Translate to another language
    return (
        <Card className="card vehicle-card">
            <div className="card">
                {/*Guest Details */}

                <div className="title">
                    <h1>
                    {t('Name')}: <strong>{record.opbill ? record.opbill.opb_name : null} </strong>
                    </h1>
                </div>

                <div className="values">
                    <p>
                    {t('Gender')} : <strong>{record.opbill ? record.opbill.opb_gender : null}</strong>
                    </p>
                </div>

                <div className="values">
                    <p>{t('Age')}: {record.opbill ? record.opb_age : null}</p>
                </div>
                <div className="values">
                    <p>{t('Mobile')}: {record.opbill ? record.opb_mobile : null}</p>
                </div>

                {/* Bill status */}
                {/* 
                    <div>
                        <strong>Bill Status</strong>   <Tag color='orange'>Pending</Tag>
                    </div> */}

                {/* <h3 className="values">
                Primary Result : {record.PrimaryResult || 'Pending'}

            </h3> */}

                <Text type="secondary">{record.place}</Text>

                <h4 className="values">{record.da_date}</h4>
            </div>
        </Card>
    );
}
