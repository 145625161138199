/**
 * @description
 * @author Jinishakappil
 */

import React, { useEffect, useState, useContext } from 'react';

import { Link } from 'react-router-dom';

import moment from 'moment-timezone';

import { GlobalContext, DateUtils, Location, useTranslation } from 'soxo-bootstrap-core';

import OpregSearch from './opreg-search';

import { Card, Switch, Button, Typography, Table, Skeleton, Divider, Input, Tag, Tooltip } from 'antd';

import { ReloadOutlined, OrderedListOutlined, PicCenterOutlined, PlusOutlined } from '@ant-design/icons';

import { RangePicker } from 'soxo-bootstrap-core';

import './candidate-visit.scss';

import { Opreg, Opvisits } from '../../models';

const { Title } = Typography;

const { Search } = Input;

export default function CandidateVisit({
    redirect_link,
    enableDoctorFilter,
    newButtonDisable,
    includeItems,
    todoColumn,
    editLinkDisable,
    includeConsultation,
    includeSummary,

    redirectPath = `/multiple-candidate/mode`,
}) {
    var [candidates, setCandidates] = useState([]);

    var [summary, setSummary] = useState([]);

    const [loading, setLoading] = useState(true);

    var param = Location.search();

    var starttime = moment.tz(process.env.REACT_APP_TIMEZONE).startOf('day');

    //Setting starttime and endtime within a week from today
    var endtime = moment.tz(process.env.REACT_APP_TIMEZONE).endOf('day'); 
    
    var [query, setQuery] = useState('');

    const { user = {}, isMobile } = useContext(GlobalContext);

    // Need to check this condition
    const [view, setView] = useState(isMobile ? true : false); 
    
    const [page, setPage] = useState(1);

    const [limit, setLimit] = useState(10);

    const { t, i18n } = useTranslation(); // To Translate to another language

    /**
     * update range
     */

    if (param.start_time) {
        updateRange();
    }

    function updateRange() {
        starttime = moment.tz(param.start_time, process.env.REACT_APP_TIMEZONE).startOf('day');
        endtime = moment.tz(param.end_time, process.env.REACT_APP_TIMEZONE).startOf('day');

        return starttime, endtime;
    }
    /**
     *   Setting Range by default
     */
    const [range, setRange] = useState([starttime, endtime]);

    function changeView(result) {
        setView(result);
    }
    /**
     * update range time
     * @param {} range
     */
    function updateTime(range) {
        setRange(range);
        setPage(1);

        Location.search({
            start_time: moment(range[0]).format('MM/DD/YYYY'),
            end_time: moment(range[1]).format('MM/DD/YYYY'),
        });
        getCandidateList(range);
    }

    /**
     * Get call
     */

    useEffect(() => {
        getCandidateList(range);
    }, []);

    /**
     *  columns
     */
    var columns = [
        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => {
                return (page - 1) * limit + index + 1;
            },
        },

        {
            title: t('Visit Date'),
            key: 'date',
            render: (ele) => {
                return DateUtils.formatDate(ele.visit_date);
            },
        },

        {
            title: t('OP No'),
            key: 'op no',

            render: (ele) => {
                return (
                    <span>
                        <Tag color="blue">{ele.op_no}</Tag>
                    </span>
                );
            },
        },

        {
            title: t('Name'),
            key: 'name',
            render: (ele) => {
                // If lastname is null then display only name
                if (ele.last_name) {
                    return ele.name + ' ' + ele.last_name;
                } else {
                    return ele.name;
                }
            },
        },

        {
            title: t('Age'),
            key: 'age',
            dataIndex: 'age',
        },

        {
            title: t('Gender'),
            key: 'gender',
            dataIndex: 'gender',
        },

        {
            title: t('Action'),
            fixed: 'right',

            render: (ele) => {
                let redirectLink = `/emr/:id`;
                let opNo;

                // op_no should be passed to view detail
                opNo = ele.op_no;

                // visit id is passed
                redirectLink = redirectLink.replace(':id', ele.visit_id);

                return <Link to={`${redirectLink}?op_no=${opNo}`}>{t('View')}</Link>;
            },
        },

        {
            fixed: 'right',

            render: (ele) => {
                let opReason, opNo;
                // op_reason is passed to url
                opReason = ele.reason;

                opNo = ele.op_no;

                let redirectLink = `/patient-visits-new/guest-details`;

                return editLinkDisable ? null : (
                    // In case of stoptb edit
                    <Link to={`${redirectLink}?reason=${opReason}&remarks=${ele.remarks ? ele.remarks : ''}&op_no=${opNo}&edit=true`}>{t('Edit')}</Link>
                );
            },
        },
    ];

    //In case of stoptb todo, done and contact columns are included
    if (todoColumn != false) {
        var contactColumns = {
            title: 'Contact',
            key: 'contact',
            dataIndex: 'contact',
        };

        var todoColumns = {
            title: t('To Do'),
            render: ({ to_do }) => {
                if (to_do.length > 2) {
                    var remainingArray = to_do.splice(0, 2);
                    var todoDisplayed = to_do.splice(0, 10);
                    var tooltip;
                    if (to_do.length > 0) {
                        if (to_do.length === 1) {
                            tooltip = `${todoDisplayed}....(${to_do.length} more item)`;
                        } else {
                            tooltip = `${todoDisplayed}....(${to_do.length} more items)`;
                        }
                    } else {
                        tooltip = `${todoDisplayed}`;
                    }
                    return (
                        <Tooltip title={tooltip} color="gold">
                            {remainingArray.map((element) => (
                                <Tag color="gold">{element}</Tag>
                            ))}
                        </Tooltip>
                    );
                } else {
                    return to_do.map((element) => <Tag color="gold">{element}</Tag>);
                }
            },
        };
        var doneColumn = {
            title: t('Done'),
            key: 'date',
            render: ({ done }) => {
                if (done.length > 2) {
                    var doneArray = done.splice(0, 2);
                    var doneDisplayed = done.splice(0, 10);
                    var tooltitle;
                    if (done.length > 0) {
                        if (done.length === 1) {
                            tooltitle = `${doneDisplayed}....(${done.length} more item)`;
                        } else {
                            tooltitle = `${doneDisplayed}....(${done.length} more items)`;
                        }
                    } else {
                        tooltitle = `${doneDisplayed}`;
                    }
                    return (
                        <Tooltip title={tooltitle} color="#87d068">
                            {doneArray.map((element) => (
                                <Tag color="green">{element}</Tag>
                            ))}
                        </Tooltip>
                    );
                } else {
                    return done.map((element) => <Tag color="green">{element}</Tag>);
                }
            },
        };

        columns.splice(6, 0, contactColumns, todoColumns, doneColumn);
    }

    //In case of matria type, reason and consultationstatus columns are included
    // Used the same attribute that used to maintain todocolumns (todoColumn) to maintain this columns
    if (todoColumn == false) {
        var TypeColumns = {
            title: t('Type'),
            // key: 'contact',
            render: (ele) => {
                if (ele && ele.type) {
                    // let opv_vtype = null;
                    if (ele && ele.type === 'N') {
                        ele.type = 'New';
                    } else if (ele && ele.type === 'V') {
                        ele.type = 'Visit';
                    } else if (ele && ele.type === 'R') {
                        ele.type = 'Repeat';
                    }

                    return ele.type;
                }
            },
        };

        var ReasonColumns = {
            title: t('Reason'),
            // key: 'contact',
            render: (ele) => {
                if (ele) {
                    return ele.reason;
                } else {
                    return 0;
                }
            },
        };

        // consultationStatus OF patient
        var consultationStatus = {
            title: t('Consultation status'),
            // key: 'contact',
            render: (ele) => {
                if (ele.consultation_status) {
                    return (
                        <span>
                            <Tag color="green">{t('Done')}</Tag>
                        </span>
                    );
                } else {
                    return (
                        <span>
                            <Tag color="orange">{t('Pending')}</Tag>
                        </span>
                    );
                }
            },
        };

        // Name of Registered Doctor
        var registeredDoctor = {
            title: t('Registered Doctor'),
            // key: 'contact',
            render: (ele) => {
                if (ele.registered_doctor) {
                    return ele.registered_doctor;
                } else {
                    return null;
                }
            },
        };
        // Name of visit Doctor
        var visitDoctor = {
            title: t('Visit Doctor'),
            // key: 'contact',
            render: (ele) => {
                if (ele.visit_doctor) {
                    return ele.visit_doctor;
                } else {
                    return null;
                }
            },
        };

        columns.splice(6, 0, TypeColumns, ReasonColumns, consultationStatus, registeredDoctor, visitDoctor);
    }

    /**
     * Get candidate visit List
     */
    async function getCandidateList(range) {
        setLoading(true);

        /**
         * enableDoctorFilter is a attribute is Used to filter data by using Staff_id
         *  We create a candidate visit list if the logged in is a doctor
         * Staff_id passed a doctor_ptr in  opvisit Api .
         */

        if (enableDoctorFilter) {
            let doctorPtr = user.staff_id;

            // In case of stoptb includeItems is true, since response should include opbill details also.
            // staff_id is passed if user wants to view only specific cases.
            Opvisits.getCandidateList({ range, doctorPtr, includeItems, includeConsultation, includeSummary }).then((result) => {
                let list = result.result.visits;

                let summeayOptions = result.result.summary;

                setSummary(summeayOptions);

                setCandidates(list);

                setLoading(false);
            });
        } else {
            Opvisits.getCandidateList({ range, includeItems, includeConsultation, includeSummary }).then((result) => {
                let list = result.result.visits;

                let summeayOptions = result.result.summary;

                setSummary(summeayOptions);

                setCandidates(list);

                setLoading(false);
            });
        }
    }

    /**
     * refresh the page
     */

    function refresh() {
        getCandidateList(range);
    }

    /**
     * for Search
     * @param {*} event
     */
    function onSearch(event) {
        setQuery(event.target.value);
    }

    function changeView(result) {
        setView(result);
    }

    /**
     * Search in candidate visit List
     */

    let filtered 

    if (candidates.length > 0){
         filtered = candidates.filter((record) => {
            query = query.toUpperCase();
            // const { op_reg } = record;
            if (query) {
                if (record.name.toUpperCase().indexOf(query) != -1) {
                    return true;
                } else if (record && record.op_mobile && record.contact.indexOf(query) != -1) {
                    return true;
                } else if (record && record.op_no.indexOf(query) != -1) {
                    return true;
                }
            } else {
                return record;
            }
        });

    }else{
        filtered = candidates
    }

    return (
        <section className="Candidate-list card portlet-container">
            {/* Page Header */}

            <div className="page-header">
                <div className="left">
                    <Title style={{ marginBottom: '0px' }} level={3}>
                        {t('Patient Visits')}
                    </Title>
                </div>

                <div className="right">
                    <div className="date-and-fltr">
                        <RangePicker
                            allowClear={false}
                            inputReadOnly
                            format={'DD/MM/YYYY'}
                            value={range}
                            onChange={(time) => {
                                updateTime(time, range);
                            }}
                            ranges={{
                                Today: [moment(), moment()],

                                Yesterday: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],

                                'This Week': [moment().startOf('week'), moment().endOf('week')],

                                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],

                                'This Month': [moment().startOf('month'), moment().endOf('month')],

                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                            }}
                        />

                        <Button onClick={refresh} type="secondary" size={'small'}>
                            <ReloadOutlined />
                        </Button>
                    </div>
                </div>
            </div>
            {/* Page Header Ends */}

            {/* Content Below */}
            {loading ? (
                <div className="">
                    <Skeleton active />
                </div>
            ) : (
                <>
                    {/*  */}
                    <div className="">
                        {/** Summary card starts */}

                        {includeSummary ? (
                            <div className="patient-summery card">
                                {summary.map((record, index) => (
                                    <SummaryCard caption={record.caption} value={record.value} color={record.color}></SummaryCard>
                                ))}
                            </div>
                        ) : null}

                        {/** Summary card ends */}

                        <div className="page-header">
                            <Search
                                placeholder={t("Enter Search Value")}
                                allowClear
                                style={{ width: 300, marginTop: '10px', marginBottom: '20px' }}
                                onChange={onSearch}
                            />

                            <div className="top-actions">
                                {/* Export Data */}
                                {newButtonDisable ? null : (
                                    <Link to={redirectPath}>
                                        <Button type="primary" size="small">
                                            {t('New')} <PlusOutlined />
                                        </Button>
                                    </Link>
                                )}

                                {/* Opreg search Component */}
                                <OpregSearch
                                    enableVisit={true}
                                    callback={(visit, opreg) => {
                                        Location.navigate({ url: `/emr/${visit}?op_no=${opreg} ` });
                                    }}
                                    // searchVisible={true}
                                    model={Opreg}
                                />
                                {/* Opreg search Component Ends */}

                                {/* Export Data Ends */}

                                <Switch
                                    defaultChecked={view}
                                    onChange={changeView}
                                    checked={view}
                                    checkedChildren={<OrderedListOutlined />}
                                    unCheckedChildren={<PicCenterOutlined />}
                                />
                            </div>
                        </div>

                        <div>
                            {view ? (
                                <>
                                    {' '}
                                    <CardList dataSource={filtered} redirect_link={redirect_link} />{' '}
                                </>
                            ) : (
                                <>
                                    <Card className="table-card">
                                        <Table
                                            scroll={{ x: true }}
                                            // rowKey={(record) => record.da_id}
                                            dataSource={filtered}
                                            columns={columns}
                                            pagination={{
                                                current: page,
                                                onChange(current) {
                                                    setPage(current);
                                                },
                                            }}
                                        />
                                    </Card>
                                </>
                            )}
                        </div>

                        {/* <div>{contextHolder}</div> */}
                    </div>
                </>
            )}

            {/* <Divider /> */}
        </section>
    );
}

/**
 *  This function is used to display Summary of opvisits
 * @param {*} param0
 * @returns
 */
function SummaryCard({ icon, caption, value, color }) {
    return (
        // Setting background color of each card according to color from backend
        <div className="events card" style={{ borderColor: color }}>
            <i class={icon}></i>
            <p> {caption}</p>
            <strong >{value} </strong>
        </div>
    );
}
/**
 * Mobile view card Section for Candidate Visit list
 */

function CardList({ dataSource, redirect_link }) {
    const { user = {}, isMobile, dispatch } = useContext(GlobalContext);
    const { t, i18n } = useTranslation(); // To Translate to another language

    function onClick(record) {
        let redirectLink = `/emr/:id`;
        Location.navigate({
            url: redirectLink,
        });
    }
    return dataSource.map((item, index) => {
        let redirectLink = `/emr/:id`;
        redirectLink = redirectLink.replace(':id', item.visit_id);

        return (
            <Link
                to={`${redirectLink}?op_no=${item.op_no}`}
                key={index}
                className="candidate-item"
                onClick={() => {
                    onClick(item);
                }}
            >
                <CandidateCard record={item}  />
            </Link>
        );
    });
}

function CandidateCard({ record }) {
    // const { op_reg } = record;

    const { t, i18n } = useTranslation(); // To Translate to another language

    return (
        <Card className="card vehicle-card">
            <div className="title">
                <p>
                {t('Name')}: <strong>{record.name} </strong>
                </p>
            </div>
            <div className="title">
                <p>
                {t('Vist Date')}: <strong> {moment(record.visit_date).format('DD/MM/YYYY')} </strong>
                </p>
            </div>
            <div className="values">
                <p>
                {t('OP No')} :{' '}
                    <Tag color="blue">
                        <strong>{record.op_no}</strong>
                    </Tag>
                </p>
            </div>
            <div className="title">
                <p>
                {t('Contact')}: <strong>{record.contact} </strong>
                </p>
            </div>

            <div className="values">
                <p>
                {t('Gender')} : <strong>{record.gender}</strong>
                </p>
            </div>
        </Card>
    );
}
