/**
 * Sample Collection model to group all apis related to it
 *
 *
 * To seperate all logic
 *
 */

import Model from './models';

import { ApiUtils } from 'soxo-bootstrap-core';

import moment from 'moment-timezone';

class Samples extends Model {
    constructor() {
        super();

        this.fields = [
            {
                field: 'name',
                caption: 'Name',
            },
            {
                field: 'amount',
                caption: 'Amount',
            },
        ];

        // this.columns = ;
    }

    /**
     * Record Sample Collection
     *
     * @param {*} formBody
     * @returns
     */
    collectSample = (params, user) => {
        var formBody = {
            lbss_refmode: 'B',
            lbss_refid: params.bill_id,
            lbss_samtranmode: 'SC',
            lbss_user: user.email,
            // lbss_user: user.name,//for stoptb
            lbss_notes: '',
            lbss_remarks: params.lbss_remarks,
            specimen: params.specimen,

            rows: params.rows,
            isTestOrder: params.isTestOrder,
            lbss_samtrandttm: params.lbss_samtrandttm,
        };

        // var formBody = {
        //     lbss_refmode: 'B',
        //     lbss_hid: 0,
        //     lbss_refid: params.bill_id,
        //     lbss_tranid: 0,
        //     lbss_itemptr: params.lbss_itemptr,
        //     lbss_samtranmode: 'SC',
        //     lbss_samtranstatus: '',
        //     lbss_samtrandttm: moment.format(params.lbss_samtrandttm).format('YYYY-MM-DD hh:mm:ss'),
        //     lbss_notes: '',
        //     lbss_remarks: params.lbss_remarks,
        //     lbss_slno: 0,
        //     lbss_user: user.UserName,
        //     lbss_canedit: 'Y',
        //     specimen:params.specimen
        //     // lbss_canflg: 'string',
        //     // isTestOrder: params.is_test_order,
        // };

        return ApiUtils.post({ url: 'labsamplestatus/sample-collection', formBody });
    };

    /**
     *  This Api is used Authorise the labresults
     * @param {*} formBody
     * @returns
     */
    authorizeLabResult = (formBody) => {
        return ApiUtils.put({
            url: `labtresult/updateResult`,
            formBody,
        });
    };
    /**
     * Create / update record in servicebaseclinical tran 
     * @param {*} formBody 
     * @returns 
     */
    serviceEntry = (formBody) => {
        return ApiUtils.post({
            url: `services/service-entry`,
            formBody,
        });
    };

    /**
     * Api is used to Unauthorize LabResult
     * @param { } formBody
     * @returns
     */
    UnauthorizeLabResult = (formBody) => {
        return ApiUtils.post({
            url: `labtresult/result-unauthorize`,
            formBody,
        });
    };
    /**
     * Record Sample Receive
     *
     * @param {*} formBody
     * @returns
     */
    recieveSample = (params, user) => {
        var formBody = {
            lbss_refmode: 'B',
            lbss_refid: params.bill_id,
            lbss_samtranmode: 'LR',
            lbss_user: user.name,
            lbss_notes: '',
            lbss_remarks: params.lbss_remarks,
            specimen: params.specimen,

            rows: params.rows,
            isTestOrder: params.isTestOrder,
            lbss_samtrandttm: params.lbss_samtrandttm,
        };

        // var formBody = {
        //     lbss_refmode: 'B',
        //     lbss_hid: 0,
        //     lbss_refid: params.bill_id,
        //     lbss_tranid: 0,
        //     lbss_itemptr: params.lbss_itemptr,
        //     lbss_samtranmode: 'SC',
        //     lbss_samtranstatus: '',
        //     lbss_samtrandttm: moment.format(params.lbss_samtrandttm).format('YYYY-MM-DD hh:mm:ss'),
        //     lbss_notes: '',
        //     lbss_remarks: params.lbss_remarks,
        //     lbss_slno: 0,
        //     lbss_user: user.UserName,
        //     lbss_canedit: 'Y',
        //     specimen:params.specimen
        //     // lbss_canflg: 'string',
        //     // isTestOrder: params.is_test_order,
        // };

        return ApiUtils.post({ url: 'labsamplestatus/sample-collection', formBody });
    };

    /**
     * Reject a sample
     *
     * @param {*} opbill
     * @returns
     */
    rejectSample = (params, user) => {
        var formBody = {
            lbss_refmode: 'B',
            lbss_hid: 0,
            lbss_refid: params.bill_id,
            lbss_tranid: 0,
            lbss_itemptr: params.lbss_itemptr,
            lbss_samtranmode: 'SR',
            lbss_samtranstatus: '',
            // lbss_samtrandttm:moment()._d,
            lbss_notes: '',
            lbss_remarks: params.lbss_remarks,
            lbss_slno: 0,
            lbss_user: user.email,
            lbss_canedit: 'Y',
            specimen: params.specimen,
            rows: params.rows,
            // lbss_canflg: 'string',
            // isTestOrder: params.is_test_order,
        };

        return ApiUtils.post({ url: 'labsamplestatus/sample-collection', formBody });
        // return ApiUtils.post({ url: 'labsamplestatus', formBody });
    };

    /**
     * Get all the specimens to be collected for the bill
     *
     * @param {*} opbill
     * @returns
     */
    getSpecimens = (opbill) => {
        return ApiUtils.get({ url: 'labtest/specimen?bill_id=' + opbill.opb_id });
    };

    /**
     * Get all
     *
     * @param {*} selected
     * @param {*} opbill
     * @returns
     */
    getSpecimenDetail = (selected, opbill) => {
        return ApiUtils.get({ url: 'opbill/specimen-detail?bill_id=' + opbill.opb_id + '&specimen=' + selected });
    };

    /**
     * Get all labtests
     * @returns
     */
    getLabtestlist = () => {
        return ApiUtils.get({ url: 'labtest/labtest-listing' });
    };

    /**
     * Get lab test detail with item pointer
     * @param {*} param0
     * @returns
     */
    getLabTestDetail = ({ testNumber }) => {
        return ApiUtils.get({ url: `labtest/lab-test-details?item=${testNumber}` });
    };

    /**
     * Create new lab test
     * @param {*} param0
     * @returns
     */
    createLabTest = ({ formBody }) => {
        return ApiUtils.post({ url: 'labtreport/create-labtreport', formBody });
    };

    /**
     * Edit lab test
     * @param {*} param0
     * @returns
     */
    editLabTest = ({ formBody, id }) => {
        return ApiUtils.put({ url: `labtreport/update-labtreport/${id}`, formBody });
    };

    getSampleList = (opbill, opbilld) => {
        return ApiUtils.get({ url: 'labsamplestatus/sample-list?billid=' + opbill.opb_id + '&itemid=' + opbilld.labTest.lbt_itemptr });
    };

    getLabResults = (range, user) => {
        const start_date = moment(range[0]).format('MM/DD/YYYY');

        const end_date = moment(range[1]).format('MM/DD/YYYY');

        return ApiUtils.get({
            url: `labtresult/unauthorized-list?start_date=${start_date}&end_date=${end_date}`,
        });
    };

    /**
     * Get Clinical Information
     *
     * @param {*} opbill
     * @param {*} opbilld
     * @returns
     */
    getClinicalInfomation = (bill) => {
        //   return ApiUtils.get({ url: 'servicebaseclinicaldettran?opb_id=' + bill.opb_id });
        return ApiUtils.get({ url: `servicebaseclinicaldettran/${bill.opb_bno}` });
    };

    /**
     * function to get the lab detail
     *
     * @returns
     */
    getPatientSpecimenSampleDetail = (opno, specimen) => {
        return ApiUtils.get({
            url: `opbill/sample-collection?bill_id=${opno}&specimen=${specimen}`,
        });
    };

    /**
     * function to get the lab list
     *
     * @returns
     */
    static getsamplelist = (range, user) => {
        const starttime = moment(range[0]).format('MM/DD/YYYY');

        const endtime = moment(range[1]).format('MM/DD/YYYY');

        return ApiUtils.get({
            url: `labtest/Lab?starttime=${starttime}&endtime=${endtime}`,
        });
    };

    /**
     * function to get the lab test
     *
     * @returns
     */
    getLabTest = (itemptr) => {
        return ApiUtils.get({
            url: `labtest?lbt_itemptr=${itemptr}`,
        });
    };

    /**
     * function to get the lab detail
     *
     * @returns
     */
    getLabDetail = (billId, mode) => {
        return ApiUtils.get({
            // url: `opbill/detail/${opno}`,
            url: `opbill/detail?id=${billId}&mode=${mode}`,
        });
    };

    /**
     * function to get the lab detail
     *
     * @returns
     */
    getBillID = (opno) => {
        return ApiUtils.get({
            url: `dev/opbill/findByOpNo?op_no=${opno}`,
        });
    };

    /**
     * Function to get the lab report
     *
     * @param {*} opno
     * @returns
     */
    getLabReportResult = (itemptr, billId) => {
        return ApiUtils.get({
            url: `labtreport/sample-details?lbt_itemptr=${itemptr}&bill_id=${billId}`,
        });
    };

    /**
     * loading lab result detailsbased on bill id 
     * @param {*} billId 
     * @returns 
     */
    getLabReportResultByBillId = (billId) => {
        return ApiUtils.get({
            url: `opbill/lab-result/${billId}`,
        });
    };

    /**
     * Function to get the lab report
     *
     * @param {*} opno
     * @returns
     */
    getLabReport = (opno, billno) => {
        return ApiUtils.get({
            url: `labtreport/labtest-report?lbt_itemptr=${opno}&bill_id=${billno}`,
        });
    };

    /**
     * Get labtreport with id
     * @param {*} lbtr_id
     * @returns
     */

    getLabReportWithId = (id) => {
        return ApiUtils.get({
            url: `labtreport/labtest-report/${id}`,
        });
    };

    /**
     * Get the labresults
     */

    getWithItem = (billId) => {
        return ApiUtils.get({
            url: `labtresult/lab-result-with-item?bill_id=${billId}`,
        });
    };

    /**
     * Get the patient lab report
     */

    getpatientLabResults = (formBody) => {
        return ApiUtils.post({
            url: `opbill/patient-lab-report`,
            formBody,
        });
    };

    /**
     * Function to get the lab result
     *
     * @param {*} opno
     * @returns
     */
    getLabResult = (opb_id, opbd_itemptr) => {
        // &
        // &
        //
        return ApiUtils.get({
            url: `labtresult?lbtrs_refid=${opb_id}&lbtrs_refmode=B&lbtrs_itemptr=${opbd_itemptr}`,
        });
    };

    /**
     * Function to get the lab result detail
     *
     * @param {*} opno
     * @returns
     */
    getLabResultDetail = (labresult, opb_id, opbd_itemptr) => {
        //
        return ApiUtils.get({
            url: `labtresultd?lbtrsd_hdrid=${labresult.lbtrs_id}&lbtrsd_itemptr=${opbd_itemptr}`,
        });
    };

    /**
     * Enter the clinical
     *
     * @returns
     */
    enterClinical = (params, user) => {
        var formBody = {
            // srvbcd_modmodeptr: 'string',
            // srvbcd_module: 'string',
            srvbcd_refid: params.bill_id,
            // srvbcd_refno: 'string',
            // srvbcd_detrefid: 0,
            // srvbcd_accessionno: 'string',
            // srvbcd_modalityptr: 'string',
            // srvbcd_itemptr: 'string',
            srvbcd_doctorptr: user.staffptr,
            srvbcd_dt: moment().format('YYYY-MM-DD'),
            srvbcd_tm: moment().format('YYYY-MM-DD hh:mm:ss'),
            srvbcd_height: params.srvbcd_height,
            srvbcd_weight: params.srvbcd_weight,
            srvbcd_bmi: params.srvbcd_bmi,
            srvbcd_bpsys: params.srvbcd_bpsys,
            srvbcd_pulse: params.srvbcd_pulse,
            // srvbcd_bpdia: 0,
            // srvbcd_pulse: 0,
            // srvbcd_abdominalcircumference: 0,
            srvbcd_remarks: 'string',
            // srvbcd_extradet1: 'string',
            // srvbcd_extradet2: 'string',
            // srvbcd_extradet3: 'string',
            // srvbcd_firmid: 0,
            // srvbcd_finyearid: 0,
            srvbcd_user: user.email,
            // updttm: '2021-08-02T15:35:50.070Z',
            // srvbcd_visceralfatobesity: 0,
            // srvbcd_doctornotes: 'string',
            // srvbcd_doctorverified: 'string',
            // srvbcd_verifieddttm: '2021-08-02T15:35:50.070Z',
        };

        return ApiUtils.post({ url: 'servicebaseclinicaldettran', formBody });
    };

    /**
     * Update the clinical
     *
     * @param {*} id
     * @param {*} formBody
     * @returns
     */
    updateClinical = (id, formBody) => {
        return ApiUtils.put({ url: `servicebaseclinicaldettran/${id}`, formBody });
    };


    /**Read data from test order */
    createOrder = (formBody) => {

        return ApiUtils.post({
            url: `labsamplestatus/create-order`,
            formBody
        });
    }



    /**Read data from test order */
    readData = (billno) => {

        return ApiUtils.get({
            url: `servicebaseclinicaldettran/read-data?bill_no=${billno}`
        });
    }

    get id() {
        return 'cold_id';
    }

    get getEndpoint() {
        return 'colldetails';
    }

    get path() {
        return `collections`;
    }

    get getName() {
        return this.modelName + `s`;
    }

    get modelName() {
        return `collection`;
    }

    get columns() {
        return [
            {
                caption: 'Staff',
                field: 'staff.name',
                key: 'staff',
            },
            {
                caption: 'Weight',
                field: 'weight',
                key: 'weight',
            },
            {
                caption: 'Date',
                field: 'created_at',
                key: 'created_at',
            },
        ];
    }

    /**
     *
     */
    createNew = (id) => {
        return ApiUtils.post({ url: 'opbill/kiosk/' + id });
    };
}

export default Samples;
