/**
 * @description
 * @author Sneha
 *
 * General-list page to list reasons, add new reasons, edit reasons
 */

import React, { useState, useEffect } from 'react';

import { GeneralLists } from '../../../../models';

//  import './referrer-list.scss';

import { InputComponent, useTranslation } from 'soxo-bootstrap-core';

import { Link } from 'react-router-dom';

import { Skeleton, Button, Input, Form, Card, Table, Typography, Space, Switch, Modal, Row, Col, message, Select } from 'antd';

import { ReloadOutlined, OrderedListOutlined, PicCenterOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const { Option } = Select;

const { TextArea, Search } = Input;

const formItemLayout = {
    labelCol: {
        // xs: { span:0 },
        // sm: { span: 0},
    },
    wrapperCol: {
        span: 50,
    },
    layout: 'vertical',
};

export default function GeneralList({ genl_mode }) {
    const [reasons, setReasons] = useState([]);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [page, setPage] = useState(1);

    const [limit, setLimit] = useState(10);

    const [view, setView] = useState(false);

    const [loading, setLoading] = useState(true);

    const [btnloading, setBtnloading] = useState(false);

    var [query, setQuery] = useState('');

    const [initial, setInitial] = useState({});

    const [edit, setEdit] = useState(false);

    //To disable all fields on view
    const [viewDisable, setViewDisable] = useState(false);

    //To disable feilds
    const [disabled, setDisabled] = useState(false);

    const { t, i18n } = useTranslation(); // To Translate to another language

    useEffect(() => {

        getReasons(genl_mode);

    }, []);

    /**
     *  Get Reasons
     */

    function getReasons() {

        console.log("genl_mode",genl_mode)
        GeneralLists.getReason(genl_mode).then((result) => {

           let results = result.result.filter((item)=>item.genl_mode === genl_mode)

            setReasons(results);

            setLoading(false);

        });
    }
    /**
    * get reasons with Id
    * @param {} result
    */

    async function getReasonWithId(code) {

        var result = await GeneralLists.getReasonWithId(code);

        console.log("results", result);


        //Here phone is changed to a format that the component accepts.
        if (result.result.genl_code) {

            result = {

                ...result.result,

            };
        }

        // Set as initial values of form
        setInitial(result);

    }

    /**
     * Search function
     */

    function onSearch(event) {
        setQuery(event.target.value);
    }

    /**
     *
     * @param {*} formBody
     */
    const onSubmit = (value) => {

        setBtnloading(true);

        //When Adding new reason
        if (!edit) {
            var values = {
                genl_slno: value.genl_slno,

                genl_code: value.genl_code,

                genl_desc: value.genl_desc,

                genl_mode: genl_mode,

                genl_active: value.genl_active,

                genl_remarks: value.genl_remarks,
            };

            GeneralLists.generalAdd(values).then(() => {

                getReasons(genl_mode);

                setBtnloading(false);

                handleOk();

                message.success('Reason Added Successfully!');
            });

        } else {


            //In case of edit
            var values = {
                genl_slno: value.genl_slno,

                genl_desc: value.genl_desc,

                genl_active: value.genl_active,

                genl_remarks: value.genl_remarks,
            };

            GeneralLists.editReason(value.genl_code, values).then(() => {

                setBtnloading(false);

                handleOk();

                message.success('Campaign Edited Successfully!');

            });
        }
    };

    //add reason
    const addReason = () => {

        setEdit(false);

        setDisabled(false);

        setViewDisable(false);

        setInitial({});

        showModal();

    };

    //edit reason
    const getReasonDetails = async (code) => {

        await getReasonWithId(code);

        await showModal();
    };

    /**
      * Modal to ADD new Reason
      */

    const showModal = () => {

        setIsModalVisible(true);

    };

    const handleOk = () => {

        setIsModalVisible(false);

        getReasons(genl_mode);
    };

    const handleCancel = () => {

        setIsModalVisible(false);

    };

    //To check if entered referrer code is unique
    const checkCode = async (code, callback) => {

        const result = await GeneralLists.checkCode(code, genl_mode);

        if (result.data == null) {

            return true;

        } else {

            return false;
        }

    };

    /**
     *  columns
     */
    const Reasoncolumns = [

        {

            title: '#',

            dataIndex: 'index',

            render: (value, item, index) => {

                return (page - 1) * limit + index + 1;

            },

        },

        {

            title: t('Sl No'),

            key: 'slno',

            render: (record) => {

                return record.genl_slno;

            },

        },

        {

            title: t('Code'),

            key: 'code',

            width: '15px',

            render: (record) => {

                return record.genl_code;

            },

        },

        {

            title: t('Reason'),

            key: 'reason',

            render: (record) => {

                return record.genl_desc;

            },

        },

        {

            title: t('Mode'),

            key: 'mode',

            render: (record) => {

                return record.genl_mode;

            },

        },

        {
            title: t('Active'),

            key: 'active',

            render: (record) => {

                return record.genl_active;

            },

        },

        {

            title: t('Remarks'),

            key: 'remarks',

            render: (record) => {

                return record.genl_remarks;

            },

        },

        {

            title: '',

            key: 'action',

            fixed: 'right',

            render: (record) => {

                function onClick() { }

                return (

                    <Space size="middle">

                        <Link

                            onClick={() => {

                                setEdit(false);

                                setDisabled(false);

                                setViewDisable(true);

                                getReasonDetails(record.genl_code);
                            }}
                        >
                            {t('View')}
                        </Link>

                    </Space>
                );
            },
        },
        {
            title: '',

            key: 'action',

            fixed: 'right',

            render: (record) => {
                function onClick() { }

                return (
                    <Space size="middle">
                        <Link
                            onClick={async () => {

                                setEdit(true);

                                setDisabled(true);

                                setViewDisable(false);

                                await getReasonDetails(record.genl_code);
                            }}
                        >
                            {t('Edit')}
                        </Link>
                    </Space>
                );
            },
        },
    ];

    // Defining Data source for passing to list
    var dataSource = reasons.filter((item) => {

        query = query.toUpperCase();

        if (query) {

            if (item.genl_desc.toUpperCase().indexOf(query) != -1) {

                return true;
            }
        } else {

            return true;

        }

    });

    var validationProps = [

        {

            required: true,

            message: t('Please Enter Code'),

        },

        {

            max: 10,

            message: t('Length cannot exceed 10 characters!'),

        },

        {

            pattern: /^[ A-Za-z0-9]*$/,

            message: t('Please Enter valid Code!'),

        },

    ];

    //In case of edit, validation of code is not required
    if (!edit) {

        validationProps.push({

            //Custom validation
            validator(rule, value) {

                return new Promise(async (resolve, reject) => {

                    //Check if code is unique
                    const result = await checkCode(value);

                    //if result returns false this message is shown
                    if (!result) {

                        reject('Please enter unique code');

                    } else {

                        resolve();
                    }
                });
            },
        });
    }

    return (
        <div className="card">

            {loading ? (

                <Skeleton />

            ) : (
                <>
                    <div className="page-header">

                        <div className="left">

                            <Title level={3}>{t('Reasons')}</Title>

                            <Search

                                placeholder="Enter Search Value"

                                allowClear

                                style={{ width: 300, marginTop: '10px', marginBottom: '20px' }}

                                onChange={onSearch}

                            />

                        </div>

                        <div className="table-actions right">

                            <div className="button-container">

                                <Space size="small">

                                    <Button onClick={getReasons} size={'small'}>

                                        <ReloadOutlined />

                                    </Button>

                                    <Button type="primary" size="small" onClick={addReason}>

                                        {t('Add')}
                                    </Button>

                                </Space>

                            </div>

                        </div>

                    </div>

                    <div className="table-card">

                        <Table

                            dataSource={dataSource}

                            scroll={{ x: true }}

                            columns={Reasoncolumns}

                            pagination={{

                                onChange(current) {

                                    setPage(current);

                                },

                            }}

                        />

                    </div>

                </>

            )}

            {/** Modal For Add New Reason  Start */}
            <Modal title={t("Reason")} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} destroyOnClose footer={[]} width={600}>

                <div>

                    <Card className="card">

                        <Form

                            {...formItemLayout}

                            initialValues={{

                                ...initial,

                                genl_mode: genl_mode

                            }}

                            onFinish={onSubmit}
                        >
                            <Row gutter={20}>

                                <Col xs={12} md={12} xl={12}>

                                    <Form.Item name="genl_code" label={t("Code")}

                                        rules={validationProps}

                                        hasFeedback
                                    >

                                        <InputComponent placeholder={t("Enter code")} disabled={viewDisable || disabled} />

                                    </Form.Item>

                                </Col>

                                <Col xs={12} md={12} xl={12}>

                                    <Form.Item name="genl_mode" label={t("Mode")}>

                                        <InputComponent placeholder={t("Enter mode")} disabled={viewDisable || true} />

                                    </Form.Item>

                                </Col>

                            </Row>

                            <Row gutter={20}>

                                <Col xs={24} md={24} xl={24}>

                                    <Form.Item

                                        name="genl_desc"

                                        label={t("Reason")}

                                        rules={[

                                            {

                                                required: true,

                                                message: t('Please Enter Reason !'),

                                            },

                                            {

                                                max: 100,

                                                message: t('Length cannot exceed 100 characters !'),

                                            },

                                            {
                                                pattern: /^[a-zA-Z\s]*$/g,

                                                message: t('Please Enter Valid Reason !'),
                                            },

                                        ]}


                                    >
                                        <InputComponent placeholder={t("Enter Reason")} disabled={viewDisable} />

                                    </Form.Item>

                                </Col>

                            </Row>

                            <Row gutter={20}>

                                <Col xs={12} md={12} xl={12}>

                                    <Form.Item name="genl_active" label={t("Active")}>

                                        <Select disabled={viewDisable}>

                                            <Option value="Y">{t('Yes')}</Option>

                                            <Option value="N">{t('No')}</Option>

                                        </Select>

                                    </Form.Item>

                                </Col>

                                <Col xs={12} md={12} xl={12}>

                                    <Form.Item

                                        name="genl_slno"

                                        label={t("Sl No")}

                                        rules={[



                                            {
                                                pattern: /^[0-9]*$/g,

                                                message: t('Please Enter Valid Sl No. !'),

                                            },

                                        ]}
                                    >
                                        <InputComponent placeholder={t("Enter Sl No.")} disabled={viewDisable} />

                                    </Form.Item>

                                </Col>

                            </Row>

                            <Row gutter={20}>

                                <Col xs={24} md={24} xl={24}>

                                    <Form.Item name="genl_remarks" label={t("Remarks")}
                                        rules={[

                                            {

                                                max: 50,

                                                message: t('Length cannot exceed 50 characters !'),

                                            },

                                            {
                                                pattern: /^[a-zA-Z\., ]*$/g,

                                                message: t('Please Enter Valid Remarks !'),
                                            },

                                        ]}

                                    >

                                        <InputComponent placeholder={t("Enter remarks")} disabled={viewDisable} />

                                    </Form.Item>

                                </Col>

                            </Row>

                            <Form.Item>

                                <div>

                                    <Button loading={btnloading} type="primary" htmlType="submit" disabled={viewDisable}>
                                        {t('Save')}
                                    </Button>

                                    <Button onClick={handleCancel} disabled={viewDisable}>{t('Cancel')}</Button>

                                </div>

                            </Form.Item>

                        </Form>

                    </Card>

                </div>

            </Modal>

            {/** end  */}

        </div>
    );
}
