/***
 *
 * Component for Medical Reports
 * @description
 * @author Sameena
 */

import React, { useEffect, useState } from 'react';

import { CheckOutlined, FileOutlined, CloseOutlined, UnorderedListOutlined, EditOutlined } from '@ant-design/icons';
import { Form, Row, Col, Modal, Select, Input, Typography, Button, Space, Menu, Dropdown, Skeleton, message, Alert } from 'antd';
import { ReferenceSelect, useTranslation } from 'soxo-bootstrap-core';
import DisplayNature from '../display-nature/display-nature';
import { TemplateMasters, ModalityGroups } from '../../../../models';

import './medical-reports.scss';
import { Opreg, MedicalReports as MedicalReport } from '../../../../models';
import ReportList from '../report-list/report-list';
import { Editor } from '@tinymce/tinymce-react';

const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
    labelCol: {
        // xs: { span:0 },
        // sm: { span: 0},
    },

    wrapperCol: {
        span: 50,
    },

    layout: 'vertical',
};

export default function MedicalReports() {
    const [opNo, setOpNo] = useState(null);

    const [guestDetails, setGuestDetails] = useState('');

    const [btnloading, setBtnloading] = useState(false);

    const [initial, setInitial] = useState({ report_status: 'D' });

    const [currentReportId, setCurrentReportId] = useState(null);

    const [loading, setLoading] = useState(false);

    const [template, setTemplate] = useState();
    // state to manage modal visibility
    const [reportList, setReportList] = useState(false);

    const [html, setHtml] = useState();

    const [templateDetails, setTemplateDetails] = useState({});

    const [templateLoading, setTemplateLoading] = useState(false);
    // store api result
    const [medicalReport, setMedicalReport] = useState({});

    const [mode, setMode] = useState();

    const { Title, Text } = Typography;

    const [form] = Form.useForm();

    const { t, i18n } = useTranslation();     // To Translate to another language

    useEffect(() => {
        // loadGuestInformation();
    }, []);

    /**
     * The function `searchGuestDetails` loads guest information based on the provided `opNo`.
     */
    const searchGuestDetails = () => {
        loadGuestInformation(opNo);
    };

    /**
     * The function "loadGuestInformation" loads guest information using the "loadGuestDetails" function
     * and sets the guest details using the response result.
     */
    const loadGuestInformation = async () => {
        setLoading(true);
        if (opNo !== null) {
            const response = await Opreg.getOpregDetails(opNo);
            // Reset all fields and html
            setInitial({});
            setHtml();
            setTemplateDetails();
            setMode();
            setCurrentReportId(null);
            form.resetFields();
            setGuestDetails(response.result);
            setLoading(false);
        } else {
            message.error('Enter valid opNo');
            setLoading(false);
        }
    };

    /**
     * Function to print report
     */
    const printReport = () => {
        if (window.tinymce && window.tinymce.activeEditor) {
            window.tinymce.activeEditor.execCommand('mcePrint');
        }
    };

    /**
     * Function to save and edit medical reports
     * @param {*} param0
     * @returns
     */
    const saveMedicalReports = async ({ edit }) => {
        const values = form.getFieldsValue();
        const body = {
            ...values,
            id: currentReportId,
            opno: opNo,
            report: html,
            doctorptr: values.doctor,
        };

        return await MedicalReport.saveMedicalreport(body).then(result => {
            if (result.success) {
                message.success(result.message);

                setCurrentReportId(result.id);
            } else {
                message.error(result.message);
            }
            // This is done to change the mode after saving the medical report
            // If the status was saved as sign off mode is just view or edit is enabled
            if (values.report_status === 'S') {
                setMode('view');
            } else if (values.report_status === 'D') {
                setMode('edit');
            } else {
                // If the status is either, currentReportId is saved null, to prevent updation of teh record
                setCurrentReportId(null);
            }
        });
    };

    /**
     * Function to be feeded for reference select
     * to default the referrer for all
     * cases the one which has the lowest refr_code
     *
     * @param {*} options
     * @returns
     */
    function identifyDefaultReferrer(options = []) {
        let initial = 1;

        let matching = {};

        //To load lab report as default doctor
        if (options) {
            options.forEach(async record => {
                if (record['refr_code'] === guestDetails.ReferrerPointer) {
                    matching = record;
                }
            });
        }
        if (matching) {
            return matching;
        }
    }

    /**
     *  Load default Template
     * @param {*} options
     * @returns
     */

    function identifyDefaultTemplate(options = []) {
        let matching = {};

        //To load lab report as default template
        if (options) {
            options.forEach(async record => {
                if (record) {
                    matching = record;
                }
            });
        }

        if (matching) {
            setTemplate(matching.tmpl_desc);
            return matching;
        }
    }

    /**
     *Function to Cancel
     * @returns
     */
    const handleCancel = async () => {
        setInitial({});
        await loadGuestInformation();
        setHtml();
        setTemplateDetails();
        setMode();
        setCurrentReportId(null);
        form.resetFields();
    };

    /**
     * Function to clear
     * @returns
     */
    const handleClear = () => {
        setOpNo(null);
        setGuestDetails('');
        setHtml('');
        setTemplateDetails({});
        setTemplateLoading(false);
        form.resetFields();
        return true;
    };

    /**
     * @param {*} value
     * viewReportList
     *
     */

    async function viewReportList() {

        // Initially it is taken from guestDetails state but in that case Report list is not comming, 
        // So opno is changes as taking from the opNo state, which is set during the input is typed itself
        const result = await ModalityGroups.getMedicalReport(opNo);

        setMedicalReport(result.report);

        setReportList(true);
    }

    /**
     * Function to get report. This triggered when fill Report button is clicked
     * The report with the given template is loaded and shown.
     * The template is editable and then can saved or printed
     */
    async function getReport() {
        setTemplateLoading(true);
        setCurrentReportId(null);
        setMode();
        if (opNo) {
            if (templateDetails && templateDetails.tmpl_code) {
                let formbody = {
                    template_code: templateDetails.tmpl_code,
                };

                const res = await Opreg.getReport(formbody, opNo);

                setHtml(res.html);

                setTemplateLoading(false);
            } else {
                message.error('Please Select a Template');
                setTemplateLoading(false);
            }
        } else {
            message.error('Please enter a valid OP Number');
            setTemplateLoading(false);
        }
    }

    /**
     * Cancel Report
     * @param {*} values
     */
    async function cancelReport(record, values) {
        const result = await MedicalReport.cancelMedicalreport(record.id, { remarks: values.remarks });
        if (result.success) {
            message.success(result.message);
            setCurrentReportId(null);
            setHtml()
            setInitial({})
            await loadGuestInformation()
            form.resetFields()
            setReportList(false);
        } else {
            message.error(result.message);
            setReportList(false);
        }
    }

    /**
     * Function to update editor
     */
    const handleUpdate = (value, editor, ...props) => {
        const editorContent = editor.getContent();
        setHtml(editorContent);
    };
 
    // for getting nature of the guest
    const nature=guestDetails?.nature;

    return (
        <>
            {loading ? (
                <>
                    <Skeleton />
                </>
            ) : (
                <div className="medical-report">
                    <div className="card card-shadow" style={{ width: '360px' }}>

                        <Form.Item name="selected" rules={[{ required: true, message: t('Please select value type') }]} label={t("OP NO")}>
                            <Space>
                                <Input
                                    placeholder={t("OP ID")}
                                    disabled={currentReportId ? true : false}
                                    style={{ width: '198px', height: '36px' }}
                                    value={opNo}
                                    onChange={e => setOpNo(e.target.value)}
                                />
                                <Button
                                    type="secondary"
                                    disabled={currentReportId ? true : false}
                                    style={{ width: '46', height: '36px' }}
                                    onClick={searchGuestDetails}
                                    onPressEnter={{ searchGuestDetails }}
                                >
                                    {t('Search')}
                                </Button>
                            </Space>

                            {/* search Details of Op id */}
                            {guestDetails ? (
                                <div>
                                    <div style={{ borderRadius: '10px', width: '320px', height: '180px' }} className="guest-info card card-shadow">
                                    {nature ? (<DisplayNature nature={nature}/>):null}
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            {' '}
                                            <Title level={5}>{t('GUEST INFORMATION')}</Title>
                                          
                                            <EditOutlined />
                                        </div>
                                        <div className="guest-details">
                                            <div>
                                                <Row span={12}>
                                                    <span className="details">{t('NAME')}</span>
                                                </Row>
                                                <Row span={12}>
                                                    <span>
                                                        <strong>{guestDetails.PatientName}</strong>
                                                    </span>
                                                </Row>
                                            </div>

                                            <div className="guest-details-info">
                                                <div className="detail-element ">
                                                    {' '}
                                                    <Row span={8}>
                                                        <span className="details">{t('GENDER')}</span>
                                                    </Row>
                                                    <Row>
                                                        <span>
                                                            <strong>{guestDetails.Gender}</strong>
                                                        </span>
                                                    </Row>
                                                </div>

                                                <div className="detail-element ">
                                                    <Row span={8}>
                                                        <span className="details">{t('AGE')}</span>
                                                    </Row>
                                                    <Row>
                                                        <span>
                                                            <strong> {guestDetails.Age.trim()}</strong>
                                                        </span>
                                                    </Row>
                                                </div>

                                                <div className="detail-element ">
                                                    <Row span={8}>
                                                        <span className="details">{t('OP NO')}</span>
                                                    </Row>
                                                    <Row>
                                                        <span>
                                                            <strong>{guestDetails.OpNo}</strong>
                                                        </span>
                                                    </Row>
                                                </div>
                                            </div>

                                            {/* <div>
                                                <Row span={24}>
                                                    <span className="details">ADDRESS</span>
                                                </Row>
                                                <Col>
                                                    <span>
                                                       <strong>{guestDetails.address1}</strong>
                                                    </span>
                                                </Col>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </Form.Item>

                        <Form
                            {...formItemLayout}
                            initialValues={{
                                ...initial,
                            }}
                            form={form}
                            onFinish={getReport}
                        >
                            {/* <Form.Item
                                name="department"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please select Department',
                                    },
                                ]}
                                label="DEPARTMENT"
                            >
                                <div className="">
                                    <Select
                                        // value={''}
                                        allowClear
                                        placeholder="-Select Department-"
                                    >
                                        <Option value={''}></Option>
                                    </Select>
                                </div>
                            </Form.Item> */}

                            {/* <Form.Item
                                name="service"
                                label="SERVICE"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please Enter SERVICE',
                                    },
                                ]}
                            >
                                <InputComponent placeholder="Enter Service" />
                            </Form.Item> */}
                            <Form.Item label={t("TEMPLATE")} name="template">
                                <ReferenceSelect
                                    config={{
                                        queries: [
                                            {
                                                field: 'tmpl_groupptr',
                                                value: 'PVMRTND',
                                            },
                                            {
                                                field: 'tmpl_active',
                                                value: 'Y',
                                            },
                                        ],
                                    }}
                                    // defaultValueCondition={identifyDefaultTemplate}
                                    field="tmpl_code"
                                    label="tmpl_desc"
                                    mode="nura-base"
                                    disabled={currentReportId ? true : false}
                                    model={TemplateMasters}
                                    onChange={(e, options) => setTemplateDetails(options.record)}
                                    placeholder={t("Select Template")}
                                />
                            </Form.Item>

                            {/* <Form.Item label="DOCTOR" name="doctor">
                                <ReferenceSelect
                                    field="refr_code"
                                    config={{
                                        limit: 50,
                                    }}
                                    // defaultValueCondition={identifyDefaultReferrer}
                                    allowClear
                                    label="refr_desc"
                                    mode="nura-base"
                                    model={Referrers}
                                />
                            </Form.Item> */}

                            {/* <Form.Item
                                name="report_name"
                                label="REPORT NAME"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please Enter REPORT NAME',
                                    },
                                ]}
                            >
                                <InputComponent placeholder="Enter Report name" />
                            </Form.Item> */}

                            <Form.Item label={t("REPORT STATUS")} name="report_status">
                                <Select>
                                    <Option key="D">{t('Draft')}</Option>
                                    <Option key="S">{t('Sign Off')}</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="remarks"
                                label={t("Remarks")}
                                rules={[
                                    {
                                        required: false,
                                        message: t('Please Enter Remarks'),
                                    },
                                    {
                                        max: 250,
                                        message: t('Limit Exceeded '),
                                    },
                                ]}
                            >
                                <TextArea maxLength={250} placeholder={t("Enter Remarks")}></TextArea>
                            </Form.Item>
                            <Form.Item>
                                <Row gutter={20}>
                                    <Col xs={12} md={12} xl={12}>
                                        <Button
                                            loading={btnloading}
                                            disabled={currentReportId ? true : false}
                                            type="primary"
                                            htmlType="submit"
                                            className="submit-button"
                                            style={{ width: '100%' }}
                                        >
                                            {t("Fill Report")}
                                        </Button>
                                    </Col>
                                    <Col xs={12} md={12} xl={12}>
                                        <Button onClick={handleCancel} style={{ width: '100%' }}>
                                            {t("Cancel")}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                    </div>

                    <div className="print-template card card-shadow" style={{ width: '100%' }}>
                        <div className="" style={{ width: '100%' }}>
                            <div className="page-header">

                                <div className='right'>

                                    {opNo ? (
                                        <div className='page-actions'>
                                            <Button type="secondary" size="small" onClick={viewReportList}>
                                                {' '}
                                                <UnorderedListOutlined /> {t("Report List")}{' '}
                                            </Button>
                                            {html && !mode ? (
                                                <>
                                                    <Button type="secondary" size="small" className="submit-button" onClick={handleClear}>
                                                        <CloseOutlined />
                                                        {t("Clear")}
                                                    </Button>
                                                    <Button type="secondary" size="small" className="submit-button" onClick={printReport}>
                                                        <FileOutlined />
                                                        {t("Print")}
                                                    </Button>
                                                    <Button type="primary" size="small" className="submit-button" onClick={saveMedicalReports}>
                                                        <CheckOutlined />
                                                        {t("Save")}
                                                    </Button>
                                                </>
                                            ) : // <Dropdown overlay={menu}>
                                                //     <Button type="primary" htmlType="submit" style={{ marginLeft: '20px' }}>
                                                //         Select Action <DownOutlined />
                                                //     </Button>
                                                // </Dropdown>
                                                null}
                                            {mode === 'view' ? (
                                                <Button type="secondary" size="small" onClick={printReport}>
                                                    {t("Print")}
                                                </Button>
                                            ) : mode === 'edit' ? (
                                                <Button type="secondary" size="small" onClick={() => saveMedicalReports({ edit: true })}>
                                                    {t("Save")}
                                                </Button>
                                            ) : null}
                                        </div>
                                    ) : null}

                                </div>

                            </div>

                            {templateLoading ? (
                                <Skeleton />
                            ) : (
                                <>
                                    {html ?
                                        <Editor
                                            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}

                                            // apiKey="y7bj7rt02vqi84dggp8vydsl2tu4e21rzomlej8at6ynx9ja"
                                            value={html}
                                            onEditorChange={handleUpdate}
                                            disabled={mode && mode === 'view' ? true : false}
                                            // saveButtonClicked={saveButtonClicked}
                                            init={{
                                                selector: 'textarea',
                                                height: 700,
                                                readonly: true,
                                                visual: false,
                                                extended_valid_elements: '*[*]', // Allow all elements and their contents
                                                plugins: ['print', 'table', 'image', 'media', 'link', 'code'],
                                                toolbar:
                                                    'template | undo redo | formatselect | ' + 'image media link' +
                                                    'bold italic backcolor | alignleft aligncenter ' +
                                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                                    'removeformat | help' +
                                                    'print',
                                                image_title: true,
                                                file_picker_types: 'image media',
                                                // for image 
                                                file_picker_callback: (callback, value, meta) => {
                                                    const input = document.createElement('input');
                                                    input.setAttribute('type', 'file');
                                                    input.setAttribute('accept', 'image/*'); // Restrict to images only

                                                    // Note:  handle other file types.
                                                    if (meta.filetype === 'media') {
                                                        input.setAttribute('accept', 'video/*,audio/*');
                                                    }

                                                    input.onchange = () => {
                                                        const file = input.files[0];
                                                        const reader = new FileReader();

                                                        reader.onload = (e) => {
                                                            callback(e.target.result, {
                                                                alt: file.name
                                                            });
                                                        };

                                                        reader.readAsDataURL(file);
                                                    };

                                                    input.click();
                                                },

                                                content_css: true,
                                                content_style: `
                                                
                                                @import url('https://fonts.googleapis.com/css?family=Source Sans 3' rel='stylesheet')

                                                @import url('https://fonts.googleapis.com/css?family=Signika' rel='stylesheet')

                                                @page {
                                                size:A4;
                                                background: white;
                                                width: 14.85cm;
                                                /* height: 14.85cm; */
                                                display: block;
                                                margin: 0 auto;
                                                padding: 10px;
                                                box-shadow: 0 0 16px rgba(32, 32, 32, 0.338);
                                                margin-bottom: 0.5cm;
                                                background-image: url(https://live.staticflickr.com/65535/53501771382_8055b58acc_o.png);
                                                background-repeat: no-repeat;
                                                background-position: center bottom;
                                            }
                                            {
                                                box-sizing: border-box;
                                            }
                                           
                                    
                                            body {


                                                background: #e5e5e5;
                                                font-size: 12px;
                                                line-height: 1.3;
                                                background: white;
                                                size:A4;
                                                padding-right:20px;
                                                padding-left:20px;
                                                display: block;
                                                margin: 0 auto;
                                             
                                                margin-bottom: 0.5cm;

                                            }
                                    
                                            .letter-head-nura{
                                                background-image: url(https://live.staticflickr.com/65535/53501771382_8055b58acc_o.png);
                                                background-repeat: no-repeat;
                                                background-position: center bottom;
                                                background-size:100%;
                                            }

                                    
                                            .ff-signika {
                                                font-optical-sizing: auto;
                                                font-weight: 400;
                                                font-style: normal;
                                                font-variation-settings:
                                                    "GRAD"0;
                                            }
                                    
                                          
                                    
                                            @media print {
                                    
                                                body,
                                                @page {
                                                    size:A4;
                                                    margin: 0;
                                                    box-shadow: none;
                                                    background-color: white;
                                                }
                                            }
                                    
                                            .text-head {
                                                font-weight: bold;
                                            }
                                    
                                            .text-gray-1 {
                                                color: #415668;
                                            }
                                    
                                            .text-gray-2 {
                                                color: #7985A9;
                                            }
                                    
                                            .text-description {
                                                font-weight: normal;
                                            }
                                    
                                            .text-center {
                                                text-align: center;
                                            }
                                    
                                            .text-right {
                                                text-align: right !important;
                                            }
                                    
                                            p {
                                                font-size: 12px;
                                            }
                                    
                                            .mb-4 {
                                                margin-bottom: 4px;
                                            }
                                    
                                            .mb-20 {
                                                margin-bottom: 20px;
                                            }
                                    
                                            .mb-50 {
                                                margin-bottom: 50px;
                                            }
                                    
                                            .mb-150 {
                                                margin-bottom: 150px;
                                            }
                                    
                                            .table-normal {
                                                width: 100%;
                                                border-collapse: collapse;
                                                border-top: 1px solid #7AC9D0;
                                                border-bottom: 1px solid #7AC9D0;
                                            }
                                    
                                            .table-normal tr td {
                                                padding: 5px;
                                            }
                                    
                                            .table-normal thead tr th {
                                                border-bottom: 1px solid #7AC9D0;
                                                text-align: left;
                                                background-color: #FFFAF0;
                                                font-weight: normal;
                                            }
                                    
                                            .table-borderless {
                                                width: 100%;
                                                border-collapse: collapse;
                                            }
                                    
                                            .table-borderless tr td {
                                                padding: 5px;
                                                border: 0;
                                            }

                                            .table-borderless-footer {
                                                width: 100%;
                                                border-collapse: collapse;
                                            }
                                    
                                            .table-borderless-footer tr td {
                                                padding-top: 230px;
                                                border: 0;
                                            }
                                    
                                    
                                            .table-borderless .table-borderless tr td {
                                                padding: 0;
                                            }
                                    
                                            .v-top {
                                                vertical-align: top;
                                            }
                                    
                                            .head-box {
                                                text-transform: uppercase;
                                                text-align: center;
                                                color: white;
                                                padding: 4px;
                                                font-weight: 600;
                                            }
                                    
                                            .px-0 {
                                                padding-left: 0 !important;
                                                padding-right: 0 !important;
                                            }
                                    
                                            .p-0 {
                                                padding: 0 !important;
                                            }
                                    
                                            .p-5 {
                                                padding: 5px !important;
                                            }

                                            .d-1{
                                                padding-right: 43px !important;
                                            }
                                    
                                            .d-flex {
                                                display: flex;
                                            }
                                    
                                            .fs-16 {
                                                font-size: 16px;
                                            }
                                    
                                            .table-top {
                                                border-top: 1px solid #7AC9D0;
                                            }
                                    
                                            .h1 {
                                                font-size: 36px;
                                            }
                                    
                                            .hr-line {
                                                border-top: 1px solid #7AC9D0;
                                                margin: 50px 0;
                                            }
                                            
                                            .vl {
                                                border-left: 2px solid #ffc10775;
                                                margin-left: 20px;
                                            }
                                            .mt-1 {
                                                margin-top: 10px;
                                            }
                                            .mt-2 {
                                                margin-top: 20px;
                                            }


                                            // .ff-signika {
                                            //     font-family: 'Signika';
                                            //     font-optical-sizing: auto;
                                            //     font-weight: 400;
                                            //     font-style: normal;
                                            //     font-variation-settings: "GRAD" 0;
                                            //     color: #404F66;
                                            // }

                                            .ff-sors-sans3 {
                                                color: #404F66;
                                            }
                                            
                                            `
                                            }}
                                        /> : <small>
                                            {t('Nothing to show')}</small>}
                                </>
                            )}
                        </div>

                        {/* Modal for Viewing ReportList */}
                        {reportList ? (
                            <>
                                <Modal
                                    width={'70%'}
                                    destroyOnClose={true}
                                    footer={null}
                                    title={t("REPORT LIST")}
                                    visible={reportList}
                                    okText="Okay"
                                    onOk={() => {
                                        setReportList(false);
                                    }}
                                    onCancel={() => {
                                        setReportList(false);
                                    }}
                                >
                                    <ReportList
                                        callback={(values, mode) => {
                                            setMode(mode);
                                            setInitial(values);
                                            form.setFieldsValue(values);
                                            setCurrentReportId(values.id);
                                            setHtml(values.report);
                                            setReportList(false);
                                        }}
                                        cancelReport={(values, record) => cancelReport(record, values)}
                                        medicalReport={medicalReport}
                                        template={template}
                                        opNo={opNo}
                                        form={form}
                                    />
                                </Modal>
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
