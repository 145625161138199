/**
 * 
 * 
 * @author Ashique Mohammed
 * 
 */
import React from 'react';

import { Tooltip } from 'antd';

/**
 * Component would accept a tooltip text and
 * and max length so that it can show the tooltip for the remaining content
 * 
 * @returns 
 */
export default function ToolTipWrapper({ content, length }) {

    return (<>

        {
            content && content.length ? <Tooltip title={content}>
                <span>
                    {content.substring(0, length)}

                    {/* If the length was more thatn {length} , we have to  */}
                    {content.length >= length ? '..' : null}

                </span>
            </Tooltip>
                :
                content
        }
    </>)
}