/**
 * Component to Show sentReport mail
 *
 *
 *
 */

import React, { useState, useEffect, useRef } from 'react';
import ReportLink from '../report-link/report-link';
import { Button, Form, Input, Space, Typography, Radio, message } from 'antd';
import { SCRFilesLink } from '../../../../models';
import { useTranslation } from 'soxo-bootstrap-core';
const { Text } = Typography;

export default function SendReport({ props, billId, mail, callback }) {
    const [selected, setSelected] = useState({
        reports: [],
    });
    const [disable, setDisable] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedReport, setSelectedReport] = useState(null);
    const [form] = Form.useForm();
    const [editedEmail, setEditedEmail] = useState('');
    const { t, i18n } = useTranslation(); // To Translate to another language
    /**
     * The function `sendReportToMail` sends a report to a specified email address using an API call
     * and handles any errors that occur.
     */
    async function sendReportToMail() {
        setIsLoading(true);
        let userData = {
            username: 'AUTOLOGIN',
            password: 'Auto@Log88',
        };
        // Fetching access token for report sending
        const userDataDetails = await SCRFilesLink.getAccessTokenForReportSending(userData);

        const token = userDataDetails.access_token

        const dbPtr = props.settings.headers.db_ptr.toString()

        /* The code is creating an object called `formBody` with three properties: `mail`, `report_id`,
       and `type`. */
        let formBody = {
            mail:props.isEmailEditable ? editedEmail|| mail :mail,
            report_id: selected.report_id,
            type: selected.type,
        };

        let response = await SCRFilesLink.sendReportMail(formBody,token,dbPtr);
        if (response.success) {
            setIsLoading(false);
            message.success('Report sent successfully');
            setDisable(true);
        } else {
            setIsLoading(false);
            message.error(response.message);
            setDisable(true);
        }
    }

    return (
        <div className="detail-element">
            <div>
                <ReportLink
                    billId={billId}
                    displayColumns={true}
                    selected={selected}
                    callback={values => {
                        setSelected(values);
                        setDisable(false);
                    }}
                />
            </div>
            <div className="card">
                <Form name="emailForm" onFinish={sendReportToMail} style={{ width: '450px' }}initialValues={{ email:mail }}>
                    <Text type="primary">{t('SEND VIA')}</Text>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Form.Item name="email" valuePropName="checked" initialValue={true}>
                            <Radio>{t('Email')}</Radio>
                        </Form.Item>
                        {/* <Form.Item name="whatsapp" valuePropName="checked" initialValue={false}>
                            <Radio>Whatsapp</Radio>
                        </Form.Item> */}
                    </div>
                    <Form.Item
                    name='email'
                    // rules={[
                    //     { required: true, message: 'Please enter your email!' },
                    //     { type: 'email', message: 'Please enter a valid email address!' },
                    // ]}
                    >
                       {props.isEmailEditable ? (
                        <Input
                            type="email"
                            value={mail}
                            onChange={(e) => setEditedEmail(e.target.value)}
                            style={{ width: '640px' }}
                        />
                    ) : (
                        <Input
                            type="email"
                            value={mail}
                            readOnly
                            style={{ width: '640px' }}
                        />
                    )}
                    </Form.Item>
                    {/* <Form.Item
                       
                        rules={[
                            { required: true, message: 'Enter whatsapp number!' },
                            { type: 'email', message: 'Please enter a valid number!' },
                        ]}
                    >
                        <Input placeholder="Enter Whatsapp Number" />
                    </Form.Item> */}
                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit" loading={isLoading} disabled={disable}>
                                {t('Send Report')}
                            </Button>
                            <Button htmlType="button" onClick={callback}>
                                {t('Cancel')}
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}
