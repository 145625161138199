/**
 *@description
 * @author Safuvan
 */

import React, { useState, useEffect } from 'react';

import { Referrers } from './../../../../models/';

import './referrer-list.scss';
import { isValidPhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js';

import { LocationSearchInput, GoogleMap, InputComponent, PhoneInput, CountryPhoneInput, ErrorBoundary, useTranslation, ExtraInfoDetail } from 'soxo-bootstrap-core';

import { Skeleton, Button, Input, Form, Card, Table, Typography, Space, Switch, Modal, Row, Col, message, Select } from 'antd';
import { ReloadOutlined, OrderedListOutlined, PicCenterOutlined } from '@ant-design/icons';

import { Link } from 'react-router-dom';

const { Title, Text } = Typography;

const { Option } = Select;

const { TextArea, Search } = Input;

const formItemLayout = {
    labelCol: {
        // xs: { span:0 },
        // sm: { span: 0},
    },
    wrapperCol: {
        span: 50,
    },
    layout: 'vertical',
};

function ReferrerList({ latitude, longitude, defaultPlace, caption, enableGooglePlacesApi,
    extraInfo, // To manage extra info component
    modeValue, // To mension mode for script execution 
    title,  // To mension title of extra info
    icon // To mension icon
}) {

    const [referrer, setReferrer] = useState([]);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [isViewVisible, setIViewModalVisible] = useState(false);

    const [page, setPage] = useState(1);

    const [limit, setLimit] = useState(10);

    const [view, setView] = useState(false);

    var [item, setItem] = useState(false);

    const [loading, setLoading] = useState(true);

    // const [map, setMap] = useState(false);

    const [btnloading, setBtnloading] = useState(false);

    const [lat, setLat] = useState(latitude);

    const [lng, setLng] = useState(longitude);

    const [place, setPlace] = useState();

    const [mapcontrol, setMapcontrol] = useState({});

    var [query, setQuery] = useState('');

    const [initial, setInitial] = useState({});

    const [edit, setEdit] = useState(false);

    //To disable refr code when edit
    const [disabled, setDisabled] = useState(false);

    // To Translate to another language
    const { t, i18n } = useTranslation();

    //To disable all fields when view
    const [viewDisable, setViewDisable] = useState(false);

    useEffect(() => {
        getReferrer();
    }, []);

    /**
     *  Get Referrer
     */

    function getReferrer() {
        Referrers.get().then(result => {
            setReferrer(result.result);
            setLoading(false);
        });
    }

    /**
     * get referrer with Id
     * @param {} result
     */

    async function getReferrerWithId(code) {
        var result = await Referrers.getReferrerWithId(code);

        //Here phone is changed to a format that the component accepts.
        if (result.refr_phone) {
            result = {
                ...result,
                refr_phone: {
                    value: result.refr_phone,
                    code: {
                        dialCode: result.refr_mobcountrycode,
                    },
                },

                // Changed to this form to get values on edit
                refr_place: {
                    value: result.refr_place,
                    ll: {
                        lat: result.refr_latitude,
                        lng: result.refr_longitude,
                    },
                },
            };
        }

        // Set as initial values of form
        setInitial(result);

        // To set in map since we get lat and lng inside refr_place object
        if (result.refr_place.ll) {
            setLat(result.refr_place.ll.lat);
            setLng(result.refr_place.ll.lng);
            setPlace(result.refr_place.value);
        } else {
            // set initial value if there is no latitude, longitude are present in result . 
            setLat(latitude);
            setLng(longitude);
            setPlace(defaultPlace);
        }
    }

    function changeView(result) {
        setView(result);
    }

    function onSearch(event) {
        setQuery(event.target.value);
    }

    /**
     *
     * @param {*} formBody
     */
    const onSubmit = value => {
        setBtnloading(true);

        // Storing value of op_mobile to a variable
        var mobile = value.refr_phone;
        let mobile_number;
        //Storing country code length
        var dialCodeLength = mobile.code.dialCode.length;

        // Save phone number without country code
        if (mobile.value.length > 10) mobile_number = mobile.value.substring(dialCodeLength);
        else mobile_number = mobile.value;
        //When Adding new referrer
        if (!edit) {
            var values = {
                refr_add1: value.refr_add1,
                refr_add2: value.refr_add2,
                refr_desc: value.refr_desc,

                // Save phone number without country code
                // refr_phone: mobile.value.substring(dialCodeLength),
                refr_phone: mobile_number,

                refr_remarks: value.refr_remarks,
                refr_code: value.refr_code,

                refr_typecode: value.refr_typecode,

                //Save country code
                refr_mobcountrycode: mobile.code.dialCode,
                refr_active : "Y" //add the active status of referrer

            };
            if (value.refr_place) {
                values = {
                    ...values,
                    refr_latitude: value.refr_place.ll.lat,
                    refr_longitude: value.refr_place.ll.lng,
                    refr_place: value.refr_place.value,
                };
            }

            Referrers.addReferrer({ values }).then(() => {
                getReferrer();
                setBtnloading(false);
                handleOk();
                message.success('Campaign Added Successfully!');
            });
        } else {
            // Storing value of op_mobile to a variable
            // var mobile = value.refr_phone;

            // //Storing country code length
            // var dialCodeLength = mobile.code.dialCode.length;

            //In case of edit
            var values = {
                refr_add1: value.refr_add1,
                refr_add2: value.refr_add2,
                refr_desc: value.refr_desc,
                refr_phone: mobile_number,
                refr_remarks: value.refr_remarks,
                refr_code: value.refr_code,

                refr_typecode: value.refr_typecode,

                //Save country code
                refr_mobcountrycode: mobile.code.dialCode,
            };

            if (value.refr_place) {
                values = {
                    ...values,
                    refr_latitude: value.refr_place.ll.lat,
                    refr_longitude: value.refr_place.ll.lng,
                    refr_place: value.refr_place.value,
                };
            }
            Referrers.editReferrer(value.refr_code, values).then(() => {
                setBtnloading(false);
                handleOk();
                message.success('Campaign Edited Successfully!');
            });
        }
    };

    //add referrer
    const addReferrer = () => {
        setEdit(false);
        setDisabled(false);
        setViewDisable(false);
        setLat(latitude);
        setLng(longitude);
        setPlace();
        setInitial({});
        showModal();
    };

    //edit referrer
    const getReferrerDetails = async code => {
        await getReferrerWithId(code);
        await showModal();
    };

    /**
     * Modal to ADD new Refferer
     */

    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleOk = () => {
        setIsModalVisible(false);
        getReferrer();
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    /**
     *  View a Refferer Details
     * @param {} record
     */
    const viewModal = record => {

        setIViewModalVisible(false);
        setItem(record);
        // item =record
    };

    const afterClose = () => {
        //     setMap(false);
    };

    const checkCode = async (code, callback) => {
        //To check if entered referrer code is unique
        const result = await Referrers.checkCode(code);

        if (result.message === 'Code Exist') {
            return false;
        } else {
            return true;
        }
    };

    // const Cancel = () => {
    //     setItem({})
    //     setIViewModalVisible(false);
    // };

    /**
     *  columns
     */
    const Referrercolumns = [
        ...[
            {
                title: '#',
                dataIndex: 'index',
                render: (value, item, index) => {
                    return (page - 1) * limit + index + 1;
                },
            },
        ],

        {
            title: t('Code'),
            key: 'code',
            render: record => {
                return record.refr_code;
            },
        },

        {
            title: t('Name'),
            key: 'name',
            width: '15px',
            render: record => {
                return record.refr_desc;
            },
        },
        {
            title: t('Place'),
            key: 'place',
            render: record => {
                return record.refr_place;
            },
        },
        {
            title: t('Eye'),

            // key: 'cc_type',

            render: ele => {

                return (
                    <div>
                        {/*Extra Info component start  */}
                        {extraInfo ? (
                            <ExtraInfoDetail
                                {...ele}
                                // record={urlParams} 
                                modeValue={modeValue}
                                title={title}
                                icon={icon}
                            />

                        ) : null}
                        {/*Extra Info component end  */}

                    </div>
                );

            },
        },

        {
            title: '',
            key: 'action',
            fixed: 'right',

            render: record => {
                function onClick() { }

                return (
                    <Space size="middle">
                        <Link
                            onClick={() => {
                                setEdit(false);
                                setDisabled(false);
                                setViewDisable(true);
                                getReferrerDetails(record.refr_code);
                            }}
                        >
                            {t('View')}
                        </Link>
                    </Space>
                );
            },
        },
        {
            title: '',
            key: 'action',
            fixed: 'right',

            render: record => {
                function onClick() { }

                return (
                    <Space size="middle">
                        <Link
                            onClick={async () => {
                                setEdit(true);
                                setDisabled(true);
                                setViewDisable(false);
                                await getReferrerDetails(record.refr_code);
                            }}
                        >
                            {t('Edit')}
                        </Link>
                    </Space>
                );
            },
        },
    ];

    // Defining Data source for passing to list
    var dataSource = referrer.filter(item => {
        query = query.toUpperCase();

        if (query) {
            if (item.refr_desc.toUpperCase().indexOf(query) != -1) {
                return true;
            }
        } else {
            return true;
        }
    });

    var validationProps = [
        {
            required: true,
            message: t('Please Enter Code'),
        },
        {
            max: 10,
            message: t(' Length cannot exceed 10 characters!'),
        },
        {
            pattern: /^[ A-Za-z0-9]*$/,
            message: t(' Please Enter valid Code!'),
        },
    ];

    //In case of edit, validation of code is not required
    if (!edit) {
        validationProps.push({
            //Custom validation
            validator(rule, value) {
                return new Promise(async (resolve, reject) => {
                    //Check if code is unique
                    const result = await checkCode(value);

                    //if result returns false this message is shown
                    if (!result) {
                        reject('Please enter unique code');
                    } else {
                        resolve();
                    }
                });
            },
        });
    }

    return (
        <div className="card card-shadow">
            {loading ? (
                <Skeleton />
            ) : (
                <>
                    <div className="page-header">
                        <div className="left">
                            {/* <Title level={3}>{caption}</Title> */}

                            <Search
                                placeholder={t("Enter Search Value")}
                                allowClear
                                style={{ width: 300, }}
                                onChange={onSearch}
                            />
                        </div>

                        <div className="table-actions right">
                            <div className="button-container">
                                <Space size="small">
                                    <Button onClick={getReferrer} size={'small'}>
                                        <ReloadOutlined />
                                    </Button>
                                    {/* 
                                    <Switch
                                        defaultChecked
                                        onChange={changeView}
                                        checked={view}
                                        checkedChildren={<OrderedListOutlined />}
                                        unCheckedChildren={<PicCenterOutlined />}
                                    /> */}
                                    <Button type="primary" size="small" onClick={addReferrer}>
                                        {t('Add')}
                                    </Button>
                                </Space>
                            </div>
                        </div>
                    </div>

                    <div className="table-card">
                        <Table
                            dataSource={dataSource}
                            scroll={{ x: true }}
                            columns={Referrercolumns}
                            pagination={{
                                onChange(current) {
                                    setPage(current);
                                },
                            }}
                        />
                    </div>
                </>
            )}
            {/** Modal For Add New Referrer  Start */}
            <Modal
                title={t("Campaign")}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                destroyOnClose
                footer={[]}
                width={900}
            // afterClose={afterClose}
            >
                <Card className="card vehicle-card">
                    <div className="modal-add">
                        <div className="google-map">
                            <Card className="inner-card">
                                <GoogleMap
                                    lat={lat}
                                    lng={lng}
                                    referrer={place || defaultPlace}
                                    center={{
                                        lat: lat,
                                        lng: lng,
                                    }}
                                    height={'470px'}
                                    width={'450px'}
                                    onMapsLoaded={(map, maps) => {
                                        setMapcontrol(map);
                                    }}
                                    draggable={false}
                                />
                            </Card>

                            {/* ) : null} */}
                        </div>

                        <div className="form-card">
                            <Card className="inner-card">
                                <Form
                                    {...formItemLayout}
                                    initialValues={{
                                        ...initial,
                                    }}
                                    onFinish={onSubmit}
                                    onFieldsChange={(fields, value) => {
                                        let field = fields[0];

                                        // if (field && field.value) {
                                        if (field.name[0] === 'refr_place') {
                                            setLat(field.value.ll.lat);
                                            setLng(field.value.ll.lng);
                                            setPlace(field.value.value);

                                            // setMap(true);
                                        }
                                        // } else {
                                        // }
                                    }}
                                >
                                    <Row gutter={20}>
                                        <Col xs={12} md={12} xl={12}>
                                            <Form.Item
                                                name="refr_code"
                                                // onBlur={(e) => checkCode(e.target.value)}
                                                label={t("Code")}
                                                rules={validationProps}
                                                hasFeedback
                                            >
                                                <InputComponent placeholder={t("Enter Code")} disabled={disabled || viewDisable} />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={12} md={12} xl={12}>
                                            <Form.Item
                                                name="refr_desc"
                                                label={t("Name")}
                                                rules={[
                                                    { required: true, message: t('Please Enter Name') },
                                                    {
                                                        max: 150,
                                                        message: t('Length cannot exceed 150 characters!'),
                                                    },
                                                    {
                                                        pattern: /^[a-zA-Z\., ]*$/g,
                                                        message: t('Please Enter Valid Name!'),
                                                    },
                                                ]}
                                            >
                                                <InputComponent placeholder={t("Enter name")} disabled={viewDisable} />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={12} md={12} xl={12}>
                                            <Form.Item
                                                name="refr_typecode"
                                                label={t("Campaign Type")}
                                                rules={[{ required: true, message: t('Please Select Campaign Type') }]}
                                            >
                                                <Select disabled={viewDisable}>
                                                    <Option value="6">{t('Health Camp')}</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={20}>
                                        <Col xs={12} md={12} xl={12}>
                                            <Form.Item
                                                name="refr_add1"
                                                label={t("Address1")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t('Please Enter Address1'),
                                                    },
                                                    {
                                                        max: 150,
                                                        message: t('Length cannot exceed 150 characters!'),
                                                    },
                                                    {
                                                        // pattern:/^[a-zA-Z\., ]*$/g,
                                                        pattern: /^(?=[\p{L}0-9])[\p{L}\p{N}_@,.&$%#\s-]{1,50}$/u,
                                                        message: t('Enter Valid Address!'),
                                                    },
                                                ]}
                                            >
                                                <InputComponent placeholder={t("Enter Address1")} disabled={viewDisable} />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={12} md={12} xl={12}>
                                            <Form.Item
                                                name="refr_add2"
                                                label={t("Address2")}
                                                rules={[
                                                    { required: true, message: t('Please Enter Address2') },
                                                    {
                                                        max: 150,
                                                        message: t('Length cannot exceed 150 characters!'),
                                                    },
                                                    {
                                                        // pattern: /^[a-zA-Z\., ]*$/g,
                                                        pattern: /^(?=[\p{L}0-9])[\p{L}\p{N}_@,.&$%#\s-]{1,50}$/u,
                                                        message: t('Enter Valid Address!'),
                                                    },
                                                ]}
                                            >
                                                <InputComponent placeholder={t("Enter Address2")} disabled={viewDisable} />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    {/**This component will not be shown when application is running offline */}
                                    {enableGooglePlacesApi ?
                                        <ErrorBoundary>
                                            <Row gutter={20}>
                                                <Col xs={24} md={24} xl={24}>
                                                    <Form.Item
                                                        name="refr_place"
                                                        label={t("Place")}
                                                        rules={[{ required: true, message: t('Please Select Place') }]}
                                                    >
                                                        <LocationSearchInput
                                                            callback={({ ll }) => {
                                                                mapcontrol.setCenter(ll.lng, ll.lat);
                                                            }}
                                                            disabled={viewDisable}
                                                        />
                                                    </Form.Item>
                                                </Col>{' '}
                                            </Row>
                                        </ErrorBoundary> : null}

                                    <Row gutter={20}>
                                        <Col xs={14} md={14} xl={14}>
                                            <Form.Item
                                                name="refr_phone"
                                                label={t("Mobile")}
                                                rules={[
                                                    { required: true, message: '' },
                                                    () => ({
                                                        validator(_, value) {
                                                            var code = value && value.code.countryCode.toUpperCase();
                                                            var value = value && value.value;
                                                            // Check if the number is valid
                                                            if (code && value && isValidPhoneNumber(value, code)) {
                                                                return Promise.resolve();
                                                            } else {
                                                                // Returns the reason for the number to be invalid,mostly TOO SHORT
                                                                let errorMessage;
                                                                if (code && value) {
                                                                    errorMessage = validatePhoneNumberLength(value, code);
                                                                }

                                                                if (errorMessage) {
                                                                    return Promise.reject(errorMessage);
                                                                } else {
                                                                    return Promise.reject('Please Enter a Valid Mobile Number');
                                                                }
                                                            }
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <CountryPhoneInput disabled={viewDisable}
                                                    defaultCountryCode={process.env.REACT_APP_COUNTRYCODE || 'in'}
                                                />
                                                {/* <InputComponent placeholder="Enter Mobile Number" disabled={viewDisable} /> */}
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={20}>
                                        <Col xs={24} md={24} xl={24}>
                                            <Form.Item
                                                name="refr_remarks"
                                                label={t("Remarks")}
                                                rules={[{ max: 150, message: t('Length cannot exceed 150 characters!') }]}
                                            >
                                                <TextArea placeholder={t("Enter Remarks if any")} disabled={viewDisable} />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={20}>
                                        <Form.Item>
                                            <div className="form-button">
                                                <Button loading={btnloading} type="primary" htmlType="submit" disabled={viewDisable}>
                                                    {t('Save')}
                                                </Button>

                                                <Button onClick={handleCancel}>{t('Cancel')}</Button>
                                            </div>
                                        </Form.Item>
                                    </Row>
                                </Form>
                            </Card>
                        </div>
                    </div>
                </Card>
            </Modal>
            {/** end  */}
            {/** View modal For Referrer Details  */}
            <Modal
                title={t("Campaign Details")}
                visible={isViewVisible}
                onCancel={() => {
                    setItem({});
                    setIViewModalVisible(false);
                }}
                okText="Yes"
                footer={[]}
                destroyOnClose
            >
                <Form layout="vertical" initialValues={{ ...item }}>
                    <Form.Item name={'refr_desc'} label={t("Name")}>
                        <InputComponent readOnly />
                    </Form.Item>

                    <Form.Item name={'refr_place'} label={t("Place")}>
                        <InputComponent readOnly />
                    </Form.Item>

                    <Form.Item name={'refr_add1'} label={t("Address1")}>
                        <InputComponent readOnly />
                    </Form.Item>

                    <Form.Item name={'refr_add2'} label={t("Address2")}>
                        <InputComponent readOnly />
                    </Form.Item>

                    {/* <Form.Item name={'refr_place'} label="Place">
                        <LocationSearchInput />
                    </Form.Item> */}

                    <Form.Item name={'refr_phone'} label={t("Mobile")}>
                        <InputComponent readOnly />
                    </Form.Item>

                    <Form.Item>
                        <Button loading={btnloading} type="primary" htmlType="submit">
                            {t('Save')}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

export default ReferrerList;
