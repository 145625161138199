/**
 * Bill Model
 *
 *
 *
 *
 */
import React, { useState, useEffect, useContext } from 'react';

import { Tag, Card, Typography, Button, message } from 'antd';

import { ApiUtils, BaseAPI, Location, useTranslation } from 'soxo-bootstrap-core';

import { Registrations } from '.';
// import { Referrers } from '.';

// import BillDetailView from './../modules/common/components/bill-detail/bill-detail';

// import BillDetail from './../modules/common/components/bill-detail/bill-detail';

const { Text } = Typography;

const moment = require('moment-timezone');

class Opreg extends BaseAPI {
    constructor() {
        super();

        this.fields = [
            {
                field: 'opb_name',
                caption: 'Name',
            },
            {
                field: 'opb_opno',
                caption: 'num',
            },
            // {
            //     field: 'cold_tm',
            //     caption: 'Date'
            // },
            // {
            //     field: 'Collected By',
            //     caption: 'cold_user'
            // }
        ];

        // this.columns = ;
    }

    get id() {
        return 'opreg';
    }

    get getEndpoint() {
        return 'opreg';
    }

    get path() {
        return `opreg`;
    }

    get getName() {
        return this.modelName;
    }

    get modelName() {
        return `opreg`;
    }

    disableAdd = () => {
        return true;
    };

    createRegistration = formBody => {
        return ApiUtils.post({
            url: `opreg/op-create`,
            formBody,
        });
    };

    /**
     * Save registration for StopTB
     *
     * @param {*} values
     * @returns
     */
    saveNewRegistration = formBody => {
        return ApiUtils.post({
            
            url: `opreg/registration-create`,
            formBody,
        });
    };


    /**
     * edit registration
     */
    editRegistration = (formBody, id) => {
        return ApiUtils.put({ url: `opreg/update-registration/${id}`, formBody });
    };

    /**
     * On Change of values in the form
     * We have to trigger all the configuration
     *
     * @param {*} field
     * @param {*} values
     */
    onValuesChange = (keys, values, form, gender, fields, add_on_fields, onChange, initialValues, showAddressChangeAlert, confirmed) => {
        // Key
        let field = Object.keys(keys)[0];

        // Load the configuration
        let fieldConfiguration = fields[field];

        if (!fieldConfiguration) {
            fieldConfiguration = add_on_fields[field];
        }

        // We have predefined handlers that needs to work for all forms
        if (field === 'gender') {
            this.onGenderChange(values.gender, gender, form);
        }

        if (field === 'title') {
            this.onTitleChange(values.title, form, initialValues);
        }

        if (field === 'pincode') {
            // This condition is check field is op_zip  , This Condition is decide to trigger onChangePincode function
            let address2 = form.getFieldValue('address2');
            let place = form.getFieldValue('place');
            // form.setFieldsValue({ country: null, state: null });
            // Check if the length of the pincode is greater than 5 and no other character is present
            if (values.pincode.length >= 5 && !(/[^0-9]/.test(values.pincode))) {
                if (address2) {
                    this.onChangePincode(form, values.pincode, address2, confirmed, showAddressChangeAlert);
                } else {
                    this.onChangePincode(form, values.pincode, place);
                }
            }
        }


        // Call the onChange listner of the hook
        // to trigger any listner that is specified in the config
        onChange({
            field,
            fieldConfiguration,
            values,
        });
    };

    /**
     *  The function for  Tittle is selected the gender should pre populate
     * @param {*} value
     */
    onTitleChange = (value, form, initialValues) => {
        var genderSelected;
        const values = form.getFieldValue();

        if (value === 'Mr.') {
            genderSelected = 'Male';
        } else if (value === 'Ms.' || value === 'Mrs.') {
            genderSelected = 'Female';
        } else if (value === 'Dr.') {
            genderSelected = initialValues && initialValues.gender ? initialValues.gender : values.gender;
        } else {
            genderSelected = initialValues && initialValues.gender ? initialValues.gender : values.gender;
        }
        form.setFieldsValue({ gender: genderSelected });
    };

    /**
     *  When gender is selected the titile should pre populate
     * Gneder is also validated in this function
     *
     * @param {*} value
     */
    onGenderChange = async (value, gender, form) => {
        var titleSelected;
        var validGender;

        // Get form values, if selected gender is other then the title value shold be kept as before
        const values = form.getFieldValue();

        if (values.title === 'Dr.') {
            validGender = true;
        } else {
            // Validate the selected gender, If the selected gender is same to the gender for the selcted item
            validGender = await this.validateGender(value, gender);
        }
        if (!validGender) {
            message.warning('You have Selected the Wrong Gender');
        } else {
            if (value === 'Male') {
                titleSelected = values.title ? values.title : 'Mr.';
            } else if (value === 'Female') {
                titleSelected = values.title ? values.title : 'Ms.';
            } else {
                titleSelected = values.title;
            }
            form.setFieldsValue({ title: titleSelected });
        }
    };

    /**
     * Function to validate if the package selected is for the same gender selected
     * If gender is not Both(returned for DR title in onTitleChange) gender is validated according to the gender of item
     * @param {*} gender
     * @returns
     */
    validateGender = (gender, genderItem) => {
        const params = Location.search();
        var itemGender;
          /*  checks the values of the variables `gender`, `params.item_gender`, and  genderItem ('opreg' and 'prereg' table) for validation gender  to determine the `itemGender`. */
        if (gender !== 'Both') {
            if (params.item_gender === 'M' || genderItem === 'M'|| genderItem === 'Male') {
                itemGender = 'Male';
            } else if (params.item_gender === 'F' || genderItem === 'F'|| genderItem === 'Female') {
                itemGender = 'Female';
            } else if (params.item_gender === 'PF' || genderItem === 'PF') {
                itemGender = 'Female';
            } else itemGender = null;

            if (itemGender != null) {
                if (gender !== itemGender) return false;
                else return true;
            } else return true;
        } else {
            return true;
        }
    };

    /**
     *  This function is used for prepopulate  country ,state and city  Based on pincode.
     * if we enter any pincode by using the Api response it prepopulate city And country ,state
     * @param {*} value
     */
    onChangePincode = (form, value, address2, confirmed, showAddressChangeAlert) => {
        Registrations.getPincodeDetails(value).then(result => {
            if (result.data) {
                // If address once entered or saved, get confirmation from the user to change it according to the data
                if (address2) {
                    if (!confirmed) showAddressChangeAlert(result.data);
                    else
                        form.setFieldsValue({
                            country: result.data.country,
                            place: result.data.city,
                            state: result.data.state,
                            address2: result.data.post_office,
                            district: result.data.district,
                        });
                } else {
                    form.setFieldsValue({
                        country: result.data.country,
                        place: result.data.city,
                        state: result.data.state,
                        address2: result.data.post_office,
                        district: result.data.district,
                    });
                }
            }
        });
    };

    get columns() {
        return [
            {
                field: 'opb_name',
                render: record => {
                    return record.opb_name;
                },
                caption: 'Name',
            },
            {
                field: 'opb_gender',
                render: record => {
                    return <Tag color={record.opb_gender === 'Male' ? 'green' : 'orange'}>{record.opb_gender}</Tag>;
                },
                caption: 'Gender',
            },
            {
                field: 'opb_age',
                render: record => {
                    return record.opb_age;
                },
                caption: 'Age',
            },
            {
                field: 'opb_mobile',
                render: record => {
                    return record.opb_mobile;
                },
                caption: 'Mobile',
            },
            {
                field: 'opb_opno',
                render: record => {
                    return record.opb_opno;
                },
                caption: 'OP No.',
            },
            {
                // field: 'cold_amt',
                render: record => {
                    return record.opb_amt;
                },
                caption: 'Amount',
            },

            {
                // field: 'cold_amt',
                render: record => {
                    return record.opb_bdisamt;
                },
                caption: 'Discount',
            },

            {
                // field: 'cold_amt',
                render: record => {
                    return record.opb_netamt;
                },
                caption: 'Net Amount',
            },

            {
                // field: 'cold_amt',
                render: record => {
                    return record.opb_paidamt;
                },
                caption: 'Paid',
            },
            // {
            //     field: 'cold_dt',
            //     render: (record) => {
            //         return record.colldetails ? record.colldetails.cold_dt : null;
            //     },
            //     caption: 'Date'
            // },
            // {
            //     field: 'Collected By',
            //     render: (record) => {
            //         return record.colldetails ? record.colldetails.cold_user : null;
            //     },
            //     caption: 'cold_user'
            // },
            {
                field: 'opb_remarks',
                render: record => {
                    return record.opb_remarks;
                },
                caption: 'Remarks',
            },
        ];
    }

    getCustomers = () => {
        return ApiUtils.get({
            url: `customer?page=1&limit=10`,
        });
    };

    /**
     * Get Doctor
     * @param {*} value
     * @returns
     */
    getDoctor = value => {
        return ApiUtils.get({
            url: `doctor?do_code=${value}`,
        });
    };

    /**
     * Function to get guest details
     * If mode is opreg, data is taken from opreg
     * Else if mode is prereg, data is taken from prereg
     * @param {*} id
     * @param {*} mode
     * @returns
     */
    loadGuestInformation = (id, mode, da_id) => {
        if (da_id)
            return ApiUtils.get({
                url: `opreg/guest-details/${id}?mode=${mode}&doctorappointment_id=${da_id}`,
            });
        else
            return ApiUtils.get({
                url: `opreg/guest-details/${id}?mode=${mode}`,
            });
    };

    /**
 * Function to get guest details
 * If mode is opreg, data is taken from opreg
 * Else if mode is prereg, data is taken from prereg
 * @param {*} id
 * @param {*} mode
 * @returns
 */
    loadGuestDetails = (id) => {

        return ApiUtils.get({
            url: `opreg/guest-info/${id}`,
        });
    };

    /**
     *   This Config is used for user search componet
     */

    searchConfig = {
        url: 'opreg/opreg-search',
        columns: callback => {
            return [
                {
                    field: 'op_no',
                    title: 'Op No',
                    dataIndex: 'op_number',
                },
                {
                    field: 'op_fname',
                    title: 'First Name',
                    dataIndex: 'first_name',
                },
                {
                    field: 'op_lname',
                    title: 'Last  Name',
                    dataIndex: 'last_name',
                },

                {
                    // field: '',
                    title: 'Actions',
                    render: record => {
                        console.log('opreg', record);
                        return (
                            <Button
                                onClick={event => {
                                    callback(record);
                                    // getPatientHistory(record.op_no)
                                    // setVisibleView(true);
                                }}
                                type="link"
                            >
                                Select
                                {record.action}
                            </Button>
                        );
                    },
                },

                // {
                //     // field: '',
                //     title: 'Actions',
                //     render: (record) => {
                //         return (
                //             <Button
                //             // onClick={onClick(record)}

                //                 onClick={(event) => {
                //                     onClick(event,record);
                //                 }}
                //                 type="link"
                //             >
                //                 {' '}
                //                 View
                //             </Button>
                //         );

                //     },
                // },
            ];
        },
        searchBy: [
            {
                caption: 'First Name',
                field: 'first_name',
            },
            {
                caption: 'Emirates ID',
                field: 'emirates_id',
            },
            {
                caption: 'Passport Number',
                field: 'passport_id',
            },
            {
                caption: 'Corporate',
                field: 'corporate',
            },
            {
                caption: 'Appointment ID',
                field: 'appointment_id',
            },
        ],
        criteria: [
            {
                caption: 'Start with',
                field: 'start_with',
            },
            {
                caption: 'End with',
                field: 'end_with',
            },
            {
                caption: 'Any Where',
                field: 'anywhere',
            },
        ],
    };

    /**
     * Registration List
     * @param {*} range
     * @returns
     */

    getRegistration = (range, page) => {
        const start_time = moment(range[0]).format('MM/DD/YYYY');

        const end_time = moment(range[1]).format('MM/DD/YYYY');

        return ApiUtils.get({
            // <<<<<<< HEAD
            //             url: `opreg?page=${page}&da_date_gte=${start_time}&da_date_lte=${end_time}&limit=20`,
            // =======
            url: `opreg/get-opreg?page=${page}&date_gte=${start_time}&date_lt=${end_time}&limit=20`,
            // >>>>>>> medicant-main
        });
    };

    /**
     * Get opreg details
     * @param {*} opno 
     * @returns 
     */
    getOpregDetails = (opno) => {
        return ApiUtils.get({
            url: `opreg/opreg-details/${opno}`,
        });
    };

    /**
     *
     * @param {*} formBody
     * @returns
     */
    verifyemail = formBody => {
        return ApiUtils.post({
            url: `opreg/username-validation`,
            formBody,
        });
    };


    /**
    * Get Medical Report
    * @param {*} formBody
    * @returns
    */
    getReport = (formBody, opno) => {
        return ApiUtils.post({
            url: `opreg/print-template/${opno}`,
            formBody,
        });
    };
    /**
     *
     * @param {*} formBody
     * @returns
     */
    loadQuestionaire = formBody => {
        return ApiUtils.post({
            url: `scrquestionnairemas/get-questionaire`,
            formBody,
        });
    };
    /**
     *Addition key generating Function in opreg
     * @param {*} formBody
     * @returns
     */
    additionalKey = id => {
        return ApiUtils.get({
            url: `opreg/addtionalkey?key=${id}`,
        });
    };

    /**
     *
     * @param {*} da_id
     * @param {*} formBody
     * @returns
     */
    updateRegistration = (da_id, formBody) => {
        return ApiUtils.put({
            url: `opreg/update-registartion/${da_id}`,
            formBody,
        });
    };

    updateOpregDetail = (opno, params) => {
        var formBody = {
            // op_modeofcommptr: "1",
            op_remarks: 'Patient ID :   ' + params.remarks,
            ...params,
        };

        return ApiUtils.put({
            url: `opreg/${opno}`,
            formBody,
        });
    };

    Card = ({ ...props }) => {
        return <GuestCard record={props} {...props}></GuestCard>;
    };

    // DetailComponent = (routeParams) => <BillDetailView {...routeParams} model={this} />;
    //  AddComponent = () => <CollectionAdd model={this} />;
}

// function onClick(event,record) {
//     console.log("record",record)

//     return (
//         <OpregSearch  callback={record}  ></OpregSearch>

//     )
// }
function GuestCard({ record }) {
    return (
        <Card className="card vehicle-card">
            <div className="card">
                <h2 className="title">{record.opb_name}</h2>

                {/* {record.opreg.opb_age} */}

                {/* <h3 className="values">{record.da_aptime}</h3> */}

                {/* <Tag color={record.da_questfilled === 'F' ? 'orange' : 'green'}>{record.da_questfilled === 'F' ? 'Filled' : 'Pending'}</Tag> */}

                <Tag color="green">{record.opb_gender}</Tag>

                <Text type="secondary">{record.opb_mobile}</Text>

                <h4 className="values">{record.opb_opno}</h4>
            </div>
        </Card>
    );
}

export default Opreg;
