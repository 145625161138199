/***
 *
 *
 * @description
 * @author Sameena
 */

/**
 * Scenarios to handle
 *
 * 1. New Guest : Create Doctor Appointment and Create Prereg
 *
 * 2. Create OPreg
 *
 * 3. Edit Prereg/ DoctorAppointment
 *
 * 4. Edit opreg
 */

import React, { useState, useEffect } from 'react';

import { Skeleton, Row, Col, Button, Form, Select, Card, Typography, Space, message, Checkbox, Progress, Modal, Input } from 'antd';

import { Location, ReferenceSelect, InputComponent, CountryPhoneInput, useTranslation } from 'soxo-bootstrap-core';

import AgeSelection from './components/age-selection';

import { isValidPhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js';

import moment from 'moment';

import { EditOutlined, CloseOutlined } from '@ant-design/icons';

import { Registrations, Customers, Appointments, Items, Prereg, Opreg, Doctors, Referrers, Bills } from './../../../models';

import './guest-details.scss';

import { GeneralLists } from './../../../models';

import Status from './../multiple-candidate/status-component/status';

const { Option } = Select;

const { Title } = Typography;
const { TextArea } = Input;

const formItemLayout = {
    labelCol: {
        // xs: { span:0 },
        // sm: { span: 0},
    },
    wrapperCol: {
        // xs: { span: 0},
        // sm: { span: 0},
    },
    layout: 'vertical',
};

const title = ['Mr', 'Ms', 'Mrs', 'Dr'];
let timeoutinterval = null;
let myTimeout = null;

export default function GuestDetailsForMultipleCandidate({ ...props }) {
    const [btnloading, setBtnLoading] = useState(false);

    const [loading, setLoading] = useState(true);

    const [form] = Form.useForm();

    const [modes, setModes] = useState([]);

    const [urlData, setUrlData] = useState([]);

    const [selected, setSelected] = useState({});

    const [corporate, setCorporate] = useState(false);

    const [list, setList] = useState([]);

    const [selectedcorporate, setSelectedCorporate] = useState();

    const [countries, setCountrys] = useState([]);

    const [doctor, setDoctor] = useState();

    const [reason, setReason] = useState();

    const [city, setCity] = useState([]);

    const [coupon, setCoupon] = useState(null);

    const [visible, setVisible] = useState(false);

    const [editableStr, setEditableStr] = useState();

    const [modalVisible, setModalVisible] = useState(false);

    const [count, setCount] = useState(0);

    const [item, setItem] = useState({}); //Get Package Details

    const { t, i18n } = useTranslation();   // To Translate to another language

    const [initial, setInitial] = useState(() => {
        if (localStorage.appConfiguration) {
            let app = JSON.parse(localStorage.appConfiguration);

            return {
                op_referrerptr: app.referrer,
            };
        } else {
            return {
                op_referrerptr: [],
            };
        }
    });
    const status = {
        // image: require(`./../../../../public/soxo/doctor2.png`),
        Heading: 'Tip to remember',
        text: 'Register a guest easily by selecting the reason and providing any remarks.',
        colour: '#002993',
        width: '43%',
        right: '-36px',
    };

    var urlParams = Location.search();

    useEffect(() => {
        let data = Location.search();
        setUrlData(data);
        setEditableStr(urlParams.reason);
        if (!props.doctorSelectionDisable) getDoctorDeatils();

        getCustomers().then(() => {
            var params = Location.search();

            // If redirect is appointment and there is da_id in url
            if ((params.da_id && !params.prereg_opno) || (params.da_id && params.redirect === 'appointment')) {
                // getAppointment(params.da_id);
                // If appointment id is present , recdirect is registration
            } else if (params.da_id && params.redirect === 'registration') {
                // If registration edit
                getRegistration(params.prereg_opno);
            } else if (params.op_no) {
                // Billng from registration
                // In case of alsalama from registration list when clicking on view data is prepopulated using op_no
                getRegistration(params.op_no);
            } else {
                setLoading(false);
            }

            //To get selected package
            getSelectedPackage(params.da_packagetypevalue);

            // Get the communication Mode
            getCommunicationMode();

            //Get Country list
            getCountries();

            //Get City list
            getCityList();

            //Get Item Details
            getItems();
            getReasonDetails();
        });
    }, []);

    //Get country list
    function getCountries() {
        Appointments.getCountries().then((result) => {
            setCountrys(result.result);
        });
    }

    function getDoctorDeatils() {
        Doctors.getDoctorDeatils(urlParams.doctor).then((result) => {
            setDoctor(result.do_name);
        });
    }
    /**
     * Get reason Details
     */
    function getReasonDetails() {
        Doctors.getReasonDetails(urlParams.reason).then((result) => {
            setReason(result.result[0].genl_desc);
            // console.log(result.result[0].genl_desc)
        });
    }

    //Get Communication mode
    function getCommunicationMode() {
        Appointments.getCommunication().then((result) => {
            setModes(result.result);
        });
    }

    /**
     * Get bill for lab test if xray is positive
     *
     * @returns
     */
    async function generateBill(registartion) {
        // setModalVisible(true)

        var item = await Items.getItemByCategory('CXRAY');

        item = item.map((ele) => {
            return {
                ...ele,
                ir_rate: ele.itemrate[0].ir_rate,
                ir_itemptr: ele.itemrate[0].ir_itemptr,
            };
        });

        var formBody = {
            opb_opno: registartion.op_no,
            visit_id: registartion.visit_id,

            rate_type: '3', // Not sure if required
            opd_remarks: 'Chest Xray',
            category: 'GEN',

            billtype: 0,

            cmgdcpn_couponnumber: null,

            doctorptr: null,

            // refererptr: patient.opbill.opb_referrerptr,
        };

        return Bills.createBill(formBody, item)
            .then((result) => {
                if (result.OpBillD) message.success('Bill has been Successfully Created');

                setBtnLoading(false);
                Location.navigate({ url: `/candidate` });

                return result;
            })
            .catch((result) => {
                setBtnLoading(false);

                message.error(JSON.stringify(result.message));

                return result;
            });
    }

    /**
     * Get city list of selected country
     */
    function getCityList(value) {
        return Appointments.getCityList(value).then((result) => {
            setCity(result.result);
        });
    }

    /**
     * Get the details of selected package
     */
    function getItems() {
        Items.getItems(urlParams.da_packagetypevalue).then((result) => {
            setItem(result);
        });
    }
    /**
     *  The function for  Tittle is selected the gender should pre populate
     * @param {*} value
     */
    function onTitleChange(value) {
        var GenderSelected;

        if (value === 'Mr.') {
            GenderSelected = t('Male');
        } else if (value === 'Ms.' || value === 'Mrs.') {
            GenderSelected = t('Female');
        }
        form.setFieldsValue({ op_gender: GenderSelected });
    }

    /**
     *  When gender is selected the titile should pre populate
     * @param {*} value
     */
    function onGenderChange(value) {
        var titleSelected;

        if (value === t('Male')) {
            titleSelected = 'Mr.';
        } else if (value === t('Female')) {
            titleSelected = 'Ms.';
        }
        form.setFieldsValue({ op_title: titleSelected });
    }

    //To save Coupon details
    var total;

    /**
     * Get the registration
     *
     * @param {*} id
     */
    function getRegistration(id) {
        setLoading(true);

        Registrations.getRegistrationsList(id).then((result) => {
            // var result = res.response;

            let initial = {
                op_title: result.op_title,
                op_fname: result.op_fname,
                op_lname: result.op_lname,
                op_gender: result.op_gender,

                op_add1: result.op_add1,
                op_add2: result.op_add2,
                op_referrerptr: result.op_referrerptr,
                op_ageyear: result.op_ageyear,
                op_agemonth: result.op_agemonth,
                op_ageday: result.op_ageday,
                op_place: result.op_place,
                op_country: result.op_country,
                op_zip: result.op_zip,
                op_phone: result.op_phone,
                op_bloodgroup: result.op_bloodgroup,

                op_mobile2: result.op_mobile2,
                op_email: result.op_email,
                op_modeofcommptr: result.op_modeofcommptr,
                op_iscorporate: null,
                op_corporateptr: result.op_corporateptr,
                op_patcatptr: result.op_patcatptr,
                op_remarks: result.op_remarks,
            };

            //If dob is null then for edit dob should ne null
            if (result.op_dob) {
                initial = {
                    ...initial,
                    op_dob: moment(result.op_dob),
                };
            } else {
                initial = {
                    ...initial,
                    op_dob: null,
                };
            }

            //Here op_mobile is changed to a format that the component accepts.
            if (result.op_mobile) {
                initial = {
                    ...initial,
                    op_mobile: {
                        value: result.op_mobile,
                        code: {
                            dialCode: result.op_mobcountrycode,
                        },
                    },
                };
            }

            if (result && result.drAppointment && result.drAppointment.preReg && result.drAppointment.preReg.prereg_id) {
                initial = {
                    ...initial,
                    prereg_id: result.drAppointment.preReg.prereg_id,
                };
            }

            if (urlParams.da_id || (urlParams.edit && result.drAppointment)) {
                initial = {
                    ...initial,
                    op_date: moment(result.op_dt).format('DD/MM/YYYY '),
                    op_time: result.drAppointment.da_aptime,
                };
            }

            getCityList(initial.op_country);

            if (result.op_iscorporate === 'Y') {
                initial.op_iscorporate = result.op_iscorporate;
            }

            // To initialise form
            setGuest({ ...initial });

            // To get referrer in edit registration
            if (result.op_referrerptr) {
                setInitial({ op_referrerptr: result.op_referrerptr });
            }

            setLoading(false);
        });
    }

    /**
     * Get customers
     *
     * @returns
     */
    function getCustomers() {
        return Customers.getCustomers(props.cc_code).then((result) => {
            setList(result);
            // console.log("result",result.result[0].cc_code)
            setSelectedCorporate(result.result[0].cc_code);
        });
    }

    //Initialising Values
    const [guest, setGuest] = useState({
        op_patcatptr: 'GEN',
        op_modeofcommptr: '1',
    });

    /**
     *Get Selected package
     */
    function getSelectedPackage(da_packagetypevalue) {
        // setLoading(true);

        Items.getRecord(da_packagetypevalue).then((result) => {
            // setLoading(false);

            setSelected(result);
        });
    }

    function stop() {
        setCount(0);
        clearInterval(timeoutinterval);
        clearTimeout(myTimeout);
    }

    /**
     * on Submit
     * @param {*} formBody
     */

    const onFinish = async (formBody) => {
        //Either of Date of Birth or Age should be provided for Appointment
        if (!formBody.op_dob) {
            message.error('Please Enter Date of Birth or Age');
            setBtnLoading(false);
            return false;
        }

        setBtnLoading(true);

        var urlParams = Location.search();

        // Edit appointment flow
        if (urlParams.edit) {
            return editRegistration(formBody, urlParams.op_no);
        }
        //If not Edit
        else {
            //For Registration

            {
                formBody = {
                    ...formBody,
                    da_time: urlParams.selected_time,
                    da_tokenno: urlParams.token_no,
                    da_packagetypevalue: urlParams.da_packagetypevalue,
                };

                // Create Opreg record
                createOPRegistration(urlParams, formBody).then((result) => {
                    setBtnLoading(false);
                });
            }
        }
    };

    /**
     * Create Reg for Appoitment Flow
     */
    function createOPRegistration(urlParams, formBody) {
        var values = form.getFieldValue();

        var iscorporate = null;

        // Storing value of op_mobile to a variable
        var mobile = values.op_mobile;

        //Storing country code length
        var dialCodeLength = mobile.code.dialCode.length;

        formBody.op_iscorporate ? (iscorporate = 'Y') : (iscorporate = 'N');

        var params = {
            mode: 'new',

            op_title: values.op_title,
            opv_reason: urlParams.reason,
            opv_remarks: urlParams.remarks,
            opv_doctorptr: null,
            op_fname: values.op_fname,
            op_lname: values.op_lname,
            op_gender: formBody.op_gender,
            op_ageyear: values.op_dob.year,
            op_agemonth: values.op_dob.month,
            op_ageday: values.op_dob.day,
            op_bloodgroup: values.op_bloodgroup,
            op_dob: values.op_dob.dob,
            op_remarks: values.op_remarks,

            // Save phone number without country code
            op_mobile: mobile.value.substring(dialCodeLength),

            //Save country code
            op_mobcountrycode: mobile.code.dialCode,

            op_email: values.op_email,
            op_patcatptr: values.op_patcatptr,
            op_active: 'Y',
            // op_no: billno,

            op_natureptr: '`NOR`',
            op_modeofcommptr: formBody.op_modeofcommptr,
            op_corporateptr: values.op_corporateptr,
            op_iscorporate: iscorporate,
            op_add1: values.op_add1,
            op_add2: values.op_add2,
            op_country: values.op_country,
            op_place: values.op_place,
            op_zip: values.op_zip,
            op_profession: 'Not Specified',

            //In case of stoptb
            op_isnewborn: 'N',
            opv_gpfee: 0,

            // In case of stoptb
            opv_billed: 'N',

            //In case of stoptb
            category: 'GEN',
        };

        //ExtraParams for billing is passed as attribute, this includes item to be billed,rate and quantity
        if (props.extraBillingParams)
            params = {
                ...params,
                ...props.extraBillingParams,
            };

        /**
         *  if age is selected
         */
        if (values.op_dob.year && !values.op_dob.day && !values.op_dob.month) {
            params = {
                ...params,
                op_age: values.op_dob.year,
                op_agetype: 'Years',
            };
        } else if (!values.op_dob.year && values.op_dob.month) {
            params = {
                ...params,
                op_age: values.op_dob.month,
                op_agetype: 'months',
            };
        } else if (!values.op_dob.year && !values.op_dob.month && values.op_dob.day) {
            params = {
                ...params,
                op_age: values.op_dob.day,
                op_agetype: 'Days',
            };
        } else if (
            (values.op_dob.year && values.op_dob.month && values.op_dob.day) ||
            (values.op_dob.year && values.op_dob.month) ||
            values.op_dob.year
        ) {
            params = {
                ...params,
                op_age: values.op_dob.year,
                op_agetype: 'Years',
            };
        }

        if (formBody.op_referrerptr && formBody.op_referrerptr.length !== 0) {
            params = {
                ...params,
                op_referrerptr: formBody.op_referrerptr,
            };
        }

        setBtnLoading(true);

        return Opreg.createRegistration(params)
            .then((result) => {
                let queryParam;
                queryParam = {
                    op_no: result.result,

                    reason: editableStr,
                };

                if (!props.doctorSelectionDisable) {
                    queryParam = {
                        ...queryParam,
                        doctor: doctor,
                    };
                }

                if (iscorporate === 'y') {
                    queryParam = {
                        ...queryParam,
                        op_corporateptr: result.op_corporateptr,
                        op_iscorporate: result.op_iscorporate,
                    };
                }

                //For stoptb visit and bill is created
                if (props.extraBillingParams) {
                    setBtnLoading(false);
                    Location.navigate({ url: `/candidate` });
                } else {
                    //For alsalama navigate to billing page
                    Location.navigate({ url: `/multiple-candidate/bill`, queryParam });
                    setBtnLoading(false);
                }

                return result;
            })
            .catch((error) => {
                message.error('Operation Failed');

                console.log(error);

                setBtnLoading(false);
            });
    }

    /**
     * Update Registration
     */

    function editRegistration(formBody, op_no) {
        var values = form.getFieldValue();

        var iscorporate = null;

        // Storing value of op_mobile to a variable
        var mobile = values.op_mobile;

        //Storing country code length
        var dialCodeLength = mobile.code.dialCode.length;

        formBody.op_iscorporate ? (iscorporate = 'Y') : (iscorporate = 'N');

        var params = {
            op_title: values.op_title,
            op_fname: values.op_fname,
            op_lname: values.op_lname,
            op_gender: formBody.op_gender,
            op_ageyear: values.op_dob.year,
            op_agemonth: values.op_dob.month,
            op_ageday: values.op_dob.day,
            op_bloodgroup: values.op_bloodgroup,
            op_dob: values.op_dob.dob,

            // Save phone number without country code
            op_mobile: mobile.value.substring(dialCodeLength),

            //Save country code
            op_mobcountrycode: mobile.code.dialCode,

            op_email: values.op_email,
            op_patcatptr: values.op_patcatptr,

            op_modeofcommptr: formBody.op_modeofcommptr,
            op_corporateptr: values.op_corporateptr,
            op_iscorporate: iscorporate,
            op_add1: values.op_add1,
            op_add2: values.op_add2,
            op_country: values.op_country,
            op_place: values.op_place,
            op_zip: values.op_zip,
            op_remarks: values.op_remarks,
        };

        if (values.op_dob.year && !values.op_dob.day && !values.op_dob.month) {
            params = {
                ...params,
                op_age: values.op_dob.year,
                op_agetype: 'Years',
            };
        } else if (!values.op_dob.year && values.op_dob.month) {
            params = {
                ...params,
                op_age: values.op_dob.month,
                op_agetype: 'months',
            };
        } else if (!values.op_dob.year && !values.op_dob.month && values.op_dob.day) {
            params = {
                ...params,
                op_age: values.op_dob.day,
                op_agetype: 'Days',
            };
        } else if (
            (values.op_dob.year && values.op_dob.month && values.op_dob.day) ||
            (values.op_dob.year && values.op_dob.month) ||
            values.op_dob.year
        ) {
            params = {
                ...params,
                op_age: values.op_dob.year,
                op_agetype: 'Years',
            };
        }

        if (formBody.op_referrerptr) {
            params = {
                ...params,
                op_referrerptr: formBody.op_referrerptr,
            };
        }

        setBtnLoading(true);
        return Opreg.editRegistration(params, op_no)
            .then((result) => {
                setBtnLoading(false);
                Location.navigate({ url: `/candidate` });
            })
            .catch((error) => {
                message.error('Operation Failed');

                console.log(error);

                setBtnLoading(false);
            });
    }
    /**
     * Create Prereg
     */
    function editPreReg(details) {
        var urlParams = Location.search();

        var values = form.getFieldValue();
        var iscorporate = null;
        values.op_iscorporate ? (iscorporate = 'y') : (iscorporate = 'n');

        var params = {
            op_title: values.op_title,
            op_fname: values.op_fname,
            op_lname: values.op_lname,
            op_gender: values.op_gender,
            op_dob: values.op_dob,
            op_add1: values.op_add1,
            op_add2: values.op_add2,
            op_place: values.op_place?.value,
            op_country: values.op_country,
            op_zip: values.op_zip,
            op_phone: values.op_phone,
            op_mobile: values.op_mobile1,
            op_email: values.op_email,
            op_modeofcommptr: values.op_modeofcommptr,
            op_iscorporate: iscorporate,
            op_patcatptr: values.op_patcatptr,
            updttm: moment().format('MM/DD/YYYY hh:mm:ss'), // Last Updated
        };

        setBtnLoading(true);

        return Opreg.updateOpregDetail(urlParams.prereg_opno, params);
    }

    //Edit Appointment
    function updateRegistration(formBody) {
        var urlParams = Location.search();

        var values = form.getFieldValue();
        var iscorporate = null;
        values.op_iscorporate ? (iscorporate = 'y') : (iscorporate = 'n');

        var params = {
            prereg_title: values.op_title,
            prereg_fname: values.op_fname,
            prereg_lname: values.op_lname,
            prereg_gender: values.op_gender,
            prereg_age: values.op_age,
            prereg_dob: values.op_dob.dob,
            prereg_address1: values.op_add1,
            prereg_address2: values.op_add2,
            prereg_city: formBody.op_place,
            prereg_country: values.op_country,
            prereg_phone: values.op_phone,
            prereg_mobile1: values.op_mobile1,
            prereg_mobile2: values.op_mobile2,
            prereg_email: values.op_email,
            prereg_packagetypevalue: urlParams.da_packagetypevalue,
            prereg_packagetype: 'ITM',
            prereg_zip: values.op_zip,
            prereg_landmark: null,
            da_dob: values.op_dob,
            da_doctorptr: urlParams.da_doctorptr,
            da_tokenno: formBody.da_tokenno,
            da_remarks: 'Booked',
        };

        setBtnLoading(true);

        return Prereg.updateRegistration(urlParams.da_id, params);
    }

    /**
     * navigate to bill
     */

    function gotoBill() {
        let queryParam = {
            op_no: urlParams.op_no,

            reason: urlParams.reason,
        };
        Location.navigate({ url: `/multiple-candidate/bill`, queryParam });
    }

    /**
     *
     * @param {*} value
     */
    function onDateChange(value) {
        const today = moment();
        const date_of_birth = moment(value);
        const ages = moment.duration(today.diff(date_of_birth));
        const age = ages.years();

        form.setFieldsValue({ op_age: age });
    }

    //For DOB dates from today are disabled
    function disabledDates(current) {
        return current > moment().endOf('day');
    }

    //Date of Birth Mandatory for Edit Appointment
    var required = false;

    var addressRequired = false;

    if (urlParams.redirect === 'appointment' && !guest.op_dob) {
        required = true;
    }

    if (urlParams.redirect === 'appointment' && !guest.op_add1 && !guest.da_add2) {
        addressRequired = true;
    }

    var editEnable = true;

    if (urlParams.da_id || urlParams.edit) {
        editEnable = false;
    }

    //For validation of gender checking item gender from url queryparams
    var genderitm = null;

    if (urlParams.item_gender === 'M') {
        genderitm = 'Male';
    } else if (urlParams.item_gender === 'F') {
        genderitm = 'Female';
    } else if (urlParams.item_gender === 'PF') {
        genderitm = 'Female';
    } else genderitm = null;

    var disabled = false;
    var disabledfield = false;
    if (urlParams.edit && urlParams.da_id) {
        disabled = true;
    }
    // if (urlParams.op_no) {
    //     disabledfield = true;
    //     disabled = true;
    // }
    async function UpdateCorporate(value) {
        setSelectedCorporate(value);
    }

    return (
        <div className="guest">
            <div className="guest-details">
                <div className="card form-step">
                    {/* Form Content */}
                    <div className="form-content">
                        {!loading ? (
                            <>
                                <div className="page-header">
                                    <div>
                                        <Title level={4}>{t('Guest Information')}</Title>

                                        <p className="page-desc">{t('Please enter the below details to complete the registration.')}</p>
                                    </div>
                                </div>

                                <Form
                                    {...formItemLayout}
                                    form={form}
                                    name="register"
                                    initialValues={{
                                        ...guest,
                                        ...initial,
                                    }}
                                    onFieldsChange={(fields) => {
                                        if (fields[0].name[0] === 'op_iscorporate') {
                                            setCorporate(fields[0].value);
                                        }

                                        if (fields[0].name[0] === 'op_title') {
                                            if (fields[0].value !== 'Dr.') {
                                                onTitleChange(fields[0].value);
                                            } else {
                                            }
                                        }

                                        if (fields[0].name[0] === 'op_gender') {
                                            onGenderChange(fields[0].value);
                                        }

                                        if (fields[0].name[0] === 'op_dob') {
                                            onDateChange(fields[0].value);
                                        }
                                    }}
                                    onValuesChange={(fields) => {}}
                                    onFinish={onFinish}
                                    scrollToFirstError
                                >
                                    <div className="steps-content">
                                        <>
                                            <Row gutter={20}>
                                                <Col xs={24} md={12} xl={8}>
                                                    <Form.Item
                                                        name="op_title"
                                                        label="Title"
                                                        rules={[{ required: false, message: t('Please Select Title') }]}
                                                    >
                                                        <Select autoFocus={true} showSearch optionFilterProp="children" disabled={disabledfield}>
                                                            <Option value="Mr.">{t('Mr.')}</Option>
                                                            <Option value="Ms.">{t('Ms.')}</Option>
                                                            <Option value="Mrs.">{t('Mrs.')}</Option>
                                                            <Option value="Dr.">{t('Dr.')}</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>

                                                <Col xs={24} md={12} xl={8}>
                                                    <Form.Item
                                                        name="op_fname"
                                                        label={t("First Name")}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: t('Please Enter First Name'),
                                                            },
                                                            {
                                                                max: 100,
                                                                message: t('Length cannot exceed 100 characters !'),
                                                            },
                                                            {
                                                                pattern: /^[a-zA-Z\s]*$/g,
                                                                message: t('Please Enter Valid Name!'),
                                                            },
                                                        ]}
                                                    >
                                                        <InputComponent disabled={disabledfield} />
                                                    </Form.Item>
                                                </Col>

                                                <Col xs={24} md={12} xl={8}>
                                                    <Form.Item
                                                        name="op_lname"
                                                        label={t("Last Name")}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: t('Please Enter Last Name'),
                                                            },
                                                            {
                                                                max: 100,
                                                                message: t('Length cannot exceed 100 characters !'),
                                                            },
                                                            {
                                                                pattern: /^[a-zA-Z\s]*$/g,
                                                                message: t('Please Enter Valid Name!'),
                                                            },
                                                        ]}
                                                    >
                                                        <InputComponent disabled={disabledfield} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row gutter={20}>
                                                <Col xs={24} md={6} xl={6}>
                                                    <Form.Item
                                                        name="op_gender"
                                                        label="Gender"
                                                        rules={[{ required: true, message: t('Please Select Gender') }]}
                                                    >
                                                        <Select disabled={disabled}>
                                                            <Option value={t('Male')}>{t('Male')}</Option>
                                                            <Option value={t('Female')}>{t('Female')}</Option>
                                                            <Option value={t('Other')}>{t('Other')}</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>

                                                {/* <Col xs={12} md={8} xl={8}> */}
                                                <Form.Item
                                                    className="op_dob"
                                                    name="op_dob"
                                                    label={t("Date of Birth")}
                                                    rules={[{ required: true, message: t('Please Select Date of Birth ') }]}
                                                >
                                                    <AgeSelection

                                                        field="op_dob"

                                                        form={form}
                                                        ageValues={
                                                            guest.op_ageyear
                                                                ? {
                                                                      dob: guest.op_dob,
                                                                      year: guest.op_ageyear,
                                                                      month: guest.op_agemonth,
                                                                      day: guest.op_ageday,
                                                                  }
                                                                : null
                                                        }
                                                    />
                                                </Form.Item>
                                                {/* </Col> */}
                                            </Row>
                                            <div>
                                                <Row gutter={20}>
                                                    <Col xs={24} md={12} xl={12}>
                                                        <Form.Item
                                                            name="op_mobile"
                                                            label={t("Mobile Number")}
                                                            rules={[
                                                                // message is giving as '' to show error message in async validator
                                                                { required: true, message: '' },

                                                                () => ({
                                                                    validator(_, value) {
                                                                        // Handled with condition to show custom validation message instead of validation error
                                                                        if (value) {
                                                                            var code = value.code.countryCode.toUpperCase();

                                                                            // Check if the number is valid
                                                                            if (isValidPhoneNumber(value.value, code)) {
                                                                                return Promise.resolve();
                                                                            } else {
                                                                                // Returns the reason for the number to be invalid,mostly TOO SHORT
                                                                                var errorMessage = validatePhoneNumberLength(value.value, code);

                                                                                if (errorMessage) {
                                                                                    //This is done to change format of error message
                                                                                    errorMessage = errorMessage.toLowerCase().replaceAll('_', ' ');

                                                                                    var firstLetter = errorMessage.charAt(0).toUpperCase();

                                                                                    var message = errorMessage.slice(1);

                                                                                    errorMessage = firstLetter + message;

                                                                                    return Promise.reject(errorMessage);
                                                                                } else {
                                                                                    return Promise.reject('Invalid Number');
                                                                                }
                                                                            }
                                                                        } else {
                                                                            return Promise.reject('Please Enter Mobile Number');
                                                                        }
                                                                    },
                                                                }),
                                                            ]}
                                                            className="phone-input"
                                                            // hidden={urlParams.edit}
                                                        >
                                                            <CountryPhoneInput />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} md={12} xl={12}>
                                                        <Form.Item
                                                            name="op_email"
                                                            label={t("E-mail")}
                                                            rules={[
                                                                {
                                                                    type: 'email',
                                                                    message: t('The input is not valid E-mail!'),
                                                                },
                                                                {
                                                                    required: true,
                                                                    message: t('Please Enter E-mail!'),
                                                                },
                                                                {
                                                                    max: 100,
                                                                    message: t('Invalid Email!'),
                                                                },
                                                            ]}
                                                        >
                                                            <InputComponent disabled={disabled} mode={'lowercase'} />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                                <Row gutter={20}>
                                                    <Col xs={24} md={12} xl={12}>
                                                        {addressRequired ? <small style={{ color: 'orange' }}>{t('Address Entry is Pending')}</small> : null}
                                                        <Form.Item
                                                            name="op_add1"
                                                            label={t("Address1")}
                                                            rules={[
                                                                {
                                                                    required: false,
                                                                    message: t('Please Enter Primary Address'),
                                                                },
                                                                {
                                                                    max: 100,
                                                                    message: t('Length cannot exceed 100 characters !'),
                                                                },
                                                                {
                                                                    pattern: /^[ A-Za-z0-9_@.,/#()&-]*$/, // /^[ A-Za-z0-9_@./#()&-]*$/,
                                                                    message: t('Enter Valid Address!'),
                                                                },
                                                            ]}
                                                        >
                                                            <InputComponent disabled={disabledfield} />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col xs={24} md={12} xl={12}>
                                                        {addressRequired ? <small style={{ color: 'orange' }}>{t('Address Entry is Pending')}</small> : null}
                                                        <Form.Item
                                                            name="op_add2"
                                                            label={t("Address2")}
                                                            rules={[
                                                                {
                                                                    required: false,
                                                                    message: t('Please Enter Secondary Address'),
                                                                },
                                                                {
                                                                    max: 100,
                                                                    message: t('Length cannot exceed 100 characters !'),
                                                                },
                                                                {
                                                                    pattern: /^[ A-Za-z0-9_@.,/#()&-]*$/, // /^[ A-Za-z0-9_@./#()&-]*$/,
                                                                    message: t('Enter Valid Address!'),
                                                                },
                                                            ]}
                                                        >
                                                            <InputComponent disabled={disabledfield} />
                                                        </Form.Item>
                                                    </Col>

                                                    {/* <Row gutter={20}> */}
                                                    <Col xs={24} md={12} xl={8}>
                                                        <Form.Item
                                                            rules={[
                                                                {
                                                                    // Cheacking if the field is required or not using attribute
                                                                    required: props.mandatoryCampaign,

                                                                    message: t('Please select campaign'),
                                                                },
                                                            ]}
                                                            label={t("Campaign")}
                                                            name="op_referrerptr"
                                                        >
                                                            <ReferenceSelect
                                                                disabled={disabledfield}
                                                                label="refr_desc"
                                                                mode="nura-base"
                                                                model={Referrers}
                                                                field={'refr_code'}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    {/* </Row> */}
                                                </Row>

                                                <Row gutter={20}>
                                                    <Col xs={24} md={12} xl={8}>
                                                        <Form.Item
                                                            name="op_country"
                                                            label={t("Country")}
                                                            rules={[{ required: false, message: t('Please Enter Country') }]}
                                                        >
                                                            <Select value={title} onSelect={getCityList} disabled={disabledfield}>
                                                                {countries.map((mode) => {
                                                                    return <Option value={mode.lcnt_desc}>{mode.lcnt_desc}</Option>;
                                                                })}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>

                                                    <Col xs={24} md={12} xl={8}>
                                                        <Form.Item
                                                            name="op_place"
                                                            label={t("City/Town")}
                                                            rules={[
                                                                {
                                                                    required: false,
                                                                    message: t('Please Enter City/Town'),
                                                                },
                                                                {
                                                                    max: 100,
                                                                    message: t('Length cannot exceed 100 characters !'),
                                                                },
                                                                {
                                                                    pattern: /^[a-zA-Z\s]*$/g,
                                                                    message: t('Please Enter Valid City/Town !'),
                                                                },
                                                            ]}
                                                        >
                                                            <InputComponent disabled={disabledfield} />

                                                            {/* <CreatableSelect
                                                                isClearable
                                                                // onChange={this.handleChange}
                                                                // onInputChange={this.handleInputChange}
                                                                options={city}
                                                                value={title}
                                                                isDisabled={disabledfield}
                                                            /> */}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} md={12} xl={8}>
                                                        <Form.Item
                                                            name="op_zip"
                                                            label={t("PIN Code")}
                                                            // rules={[{ required: false, message: 'Please Enter Pin Code' }]}
                                                            rules={[
                                                                { required: false },
                                                                {
                                                                    min: 5,
                                                                    message: t('Length can need 5 characters!'),
                                                                },
                                                                {
                                                                    max: 10,
                                                                    message: t('Length cannot exceed 10 characters!'),
                                                                },
                                                                {
                                                                    pattern: /(^\d{6,7}$)|(^\d{5}-\d{4}$)/,
                                                                    message: t('Please enter valid Pin Code '),
                                                                },
                                                            ]}
                                                        >
                                                            <InputComponent disabled={disabledfield} />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                                <Row gutter={20}></Row>
                                                {urlParams.mode || (urlParams.edit && urlParams.redirect === 'appointment') ? null : (
                                                    <>
                                                        <Row gutter={20}>
                                                            <Col xs={24} md={12} xl={8}>
                                                                <Form.Item
                                                                    name="op_modeofcommptr"
                                                                    label={t("Communication Mode")}
                                                                    rules={[{ required: true, message: t('Please Enter Communication Mode') }]}
                                                                >
                                                                    <Select value={title} disabled={disabledfield}>
                                                                        {modes.map((mode) => {
                                                                            return <Option value={mode.comt_code}>{mode.comt_desc}</Option>;
                                                                        })}
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>

                                                            <Col xs={24} md={12} xl={8}>
                                                                <Form.Item
                                                                    name="op_patcatptr"
                                                                    label={t("Category")}
                                                                    rules={[{ required: false, message: t('Please Enter Category') }]}
                                                                >
                                                                    <Select disabled={disabledfield}>
                                                                        <Option value="GEN">{t('General')}</Option>
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24} md={12} xl={8}>
                                                                <Form.Item
                                                                    name="op_bloodgroup"
                                                                    label={t("Blood Group")}
                                                                    rules={[
                                                                        {
                                                                            required: props.bloodGroupMandatory,
                                                                        },
                                                                    ]}
                                                                >
                                                                    <ReferenceSelect
                                                                        config={{
                                                                            queries: [
                                                                                {
                                                                                    field: 'genl_mode',

                                                                                    value: 'BLGRP',
                                                                                },
                                                                            ],
                                                                        }}
                                                                        field="genl_desc"
                                                                        label="genl_desc"
                                                                        mode="nura-base"
                                                                        model={GeneralLists}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24} md={12} xl={8}>
                                                                <Form.Item
                                                                    name="op_remarks"
                                                                    label={t("Remarks")}
                                                                    rules={[
                                                                        {
                                                                            required: false,
                                                                            message: t('Please Enter Remarks'),
                                                                        },
                                                                        {
                                                                            max: 250,
                                                                            message: t('Limit Exceeded '),
                                                                        },
                                                                    ]}
                                                                >
                                                                    <TextArea maxLength={250}></TextArea>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>

                                                        {props.corporate === false ? null : (
                                                            <div>
                                                                {' '}
                                                                <Row gutter={20}>
                                                                    <Col xs={24} md={12} xl={8}>
                                                                        <Form.Item
                                                                            valuePropName="checked"
                                                                            name="op_iscorporate"
                                                                            rules={[{ required: false, message: t('Is this a Corporate Customer') }]}
                                                                        >
                                                                            <Checkbox disabled={disabledfield}>
                                                                                {t('Is this a Corporate Customer?')}
                                                                            </Checkbox>
                                                                        </Form.Item>
                                                                    </Col>

                                                                    {corporate || guest.op_corporateptr ? (
                                                                        <Col xs={24} md={12} xl={8}>
                                                                            <Form.Item
                                                                                name="op_corporateptr"
                                                                                label={t("Corporate")}
                                                                                rules={[
                                                                                    { required: true, message: t('Please Select Corporate Customer') },
                                                                                ]}
                                                                            >
                                                                                <ReferenceSelect
                                                                                    disabled={disabledfield}
                                                                                    label="cc_desc"
                                                                                    mode="nura-base"
                                                                                    model={Customers}
                                                                                    field={'cc_code'}
                                                                                />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    ) : null}
                                                                </Row>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </>
                                    </div>
                                    <Space>
                                        <Button loading={btnloading} disabled={urlParams.op_no && !urlParams.edit} type="primary" htmlType="submit">
                                           {t('Save')}
                                        </Button>

                                        {/* {urlParams.edit ? null : (
                                            <Button type="secondary">
                                                <CloseOutlined />
                                                Clear
                                            </Button>
                                        )} */}

                                        {urlParams.op_no && !urlParams.edit ? (
                                            <Button type="secondary" onClick={gotoBill}>
                                                <CloseOutlined />
                                                {t('Goto Bill')}
                                            </Button>
                                        ) : null}
                                    </Space>

                                    {/* Modal for Billing Lab if xray is positive */}
                                    {props.AutomateLabBill ? (
                                        <Modal
                                            width={'35%'}
                                            destroyOnClose={true}
                                            title={t("Alert")}
                                            visible={modalVisible}
                                            okText="Okay"
                                            onOk={() => {
                                                onFinish();

                                                setModalVisible(false);
                                                stop();
                                                setCount(0);
                                            }}
                                            onCancel={() => {
                                                setCount(0);
                                                stop();
                                                setModalVisible(false);
                                            }}
                                        >
                                            {/* <img src="https://img.icons8.com/external-flaticons-flat-flat-icons/64/000000/external-danger-security-flaticons-flat-flat-icons.png" />{' '} */}
                                            <Progress
                                                type="circle"
                                                strokeColor={{
                                                    '0%': '#108ee9',
                                                    '100%': '#87d068',
                                                }}
                                                percent={count}
                                            />{' '}
                                            <p> {t('We are proceeding for Billing. Please Wait')} </p>
                                            <div className="anime">
                                                <div className="images">
                                                    <img className="card-icon" src={require(`../../../assets/icons/ct-scanner.png`)} />
                                                    <div className="arrow">
                                                        {/* <div class="arrow-container">
                        <div class="arrow-down"></div>
                    </div> */}
                                                    </div>

                                                    {/* <img
                    className="card-icon"
                    src="https://img.icons8.com/external-flat-02-chattapat-/64/undefined/external-lab-back-to-school-flat-02-chattapat-.png"
                /> */}
                                                </div>
                                            </div>
                                        </Modal>
                                    ) : null}
                                </Form>
                            </>
                        ) : (
                            <Skeleton />
                        )}
                    </div>

                    {/* Form Content Ends */}
                </div>
            </div>
            <div className="primary-details">
                <div className="right-card">
                    <div className="title">
                        <Title level={5}> {t('GUEST PREFERENCES')} </Title>
                        {/* <EditOutlined className="icon" /> */}
                    </div>

                    {/* Doctor Selection */}
                    {props.doctorSelectionDisable ? null : (
                        <div className="detail-card" style={{ backgroundColor: '#DCF9FF' }}>
                            <>
                                <p className="header">{t('DOCTOR')}</p>
                                <p className="data"> {t('Dr. Kiran Kumar')}</p>
                            </>
                        </div>
                    )}
                    {/* Doctor Selection Ends */}

                    {/* Reason Section */}
                    <div className="detail-card" style={{ backgroundColor: '#EBFFD3' }}>
                        {/* {doctorSelectionDisable ? null : ( */}
                        <>
                            <p className="header">{t('REASON')} </p>
                            <p className="data"> {reason}</p>
                        </>
                        {/* )} */}
                    </div>
                    {/* Reason Section Ends */}

                    <div className="detail-card" style={{ backgroundColor: '#E2DBFF' }}>
                        <>
                            <p className="header">{t('REMARKS')}</p>
                            <p className="data" style={{ fontSize: '16px' }}>
                                {' '}
                                {urlParams.remarks}
                            </p>
                        </>
                    </div>
                </div>

                <Status status={status}></Status>
            </div>
        </div>
    );
}
