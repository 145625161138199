/***
 *
 * Guest information component in Bill-I
 *
 * @description
 * @author Sharafudheen
 */

import React, { useState } from 'react';

import { Popconfirm, Button, Modal, Dropdown, Menu, Table, Typography } from 'antd';

import { DeleteFilled } from '@ant-design/icons';

import { Rupee } from 'soxo-bootstrap-core';

import { MoreOutlined, CloseCircleTwoTone } from '@ant-design/icons';

import DiscountModal from '../../../discount-model/discount-model';

import moment from 'moment';
import { formatCurrency } from '../../../../../../utils/currency.utils';
import PriceFormatter from '../../../format-price/format-price';

const { Text } = Typography;

function SelectedList({ items = [], onUpdateitmdiscount, setSelected, callback, enableItemDiscount, currency}) {
    const [roundValue] = useState(2);

    // const [itmdiscount, setitmDiscount] = useState({});
    // console.log('selected List data ', items);

    // useEffect(async () => {}, [items]);
    // Update the items to add callback functions
    items = items.map(entry => {
        // console.log("nowwwwwwwwwwww",entry)

        // Callback Function
        entry.callback = props => {
            // While preparing data we prepare discount object
            entry.discount = {
                ...props,
            };

            // setEntries(items);

            callback(items);
        };

        entry.quantity = 1;

        entry.amount = entry.ir_rate * entry.quantity;

        // console.log("entryyyyyyyyyyyyyyyyy",entry)


        return entry;
    });



    /**
     * Delete the selected Item
     * @param {*} value
     */

    function deleteSelected(value) {
        items.map((ele, index) => {
            if (ele.itm_code.indexOf(value.itm_code) !== -1) {
                items.splice(index, 1);
            }
        });

        callback(items);
        setSelected(items);

        // setItems(items)
    }

    /**
     * On Update remarks
     *
     * @param {*} event
     */

    /**
     * Function to delete the applied discount in item discounts
     * @param {*} value
     */
    function deleteDiscount(value) {
        items.map((ele, index) => {
            if (ele.itm_code.indexOf(value.itm_code) !== -1) {
                delete ele.discount;
            }
        });
        setSelected(items);
    }

    // const [entries, setEntries] = useState(items);

    // useEffect(() => {

    //     setEntries(items);

    // }, [items])


    // console.log('selected List data ', items);


    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => index + 1,
        },
        {
            title: 'Date',
            // dataIndex: 'no'

            render: () => {
                return moment().format('DD/MM/YYYY');
            },
        },
        {
            title: 'Service Item',
            dataIndex: 'itm_desc',
        },

        // {
        //     title: 'Doctor',
        //     dataIndex: 'price',
        // },

        {
            title: 'Tax %',
            dataIndex: 'price',
        },

        {
            title: 'Rate',
            // dataIndex: 'ir_rate',
            render: (record) => {
                return record.ir_rate;
            },
        },

        {
            title: 'Quantity',
            dataIndex: 'quantity',
        },

        {
            title: 'Discount',
            render: record => {
                // const { discount = recorditmdiscount } = record;
                if (record.discount) {
                    return (
                        <>
                            <span>{record.discount.value ? record.discount.value + ' ' + record.discount.type : '-'}</span>
                            <Popconfirm
                                title="Are you sure you want to delete the applied discount ? "
                                onConfirm={() => deleteDiscount(record)}
                                onCancel={() => {}}
                                okText="Yes"
                                cancelText="No"
                            >
                                {/* <Button size="small"> */}
                                <CloseCircleTwoTone twoToneColor="red" />
                                {/* </Button> */}
                            </Popconfirm>
                        </>
                    );
                } else return true;
            },
        },

        {
            title: 'Amount',
            // dataIndex: 'amount',
            render: record => {
                const { discount, amount } = record;
                var final = amount;

                if (discount) {
                    if (discount.type === '%') {
                        final = amount - (amount * discount.value) / 100;
                    } else {
                        final = amount - discount.value;
                    }
                }

                return (
                    <span>
                        {discount ? 
                        // <Rupee value={parseFloat(final.toFixed(roundValue))}></Rupee>
                        formatCurrency(parseFloat(final.toFixed(roundValue)), currency)
                        : parseFloat(amount.toFixed(roundValue))}
                        {discount ? (
                            <Text type="danger" delete>
                                {/* <Rupee value={parseFloat(amount.toFixed(roundValue))}></Rupee> */}
                               {formatCurrency(parseFloat(amount.toFixed(roundValue)), currency)}
                            </Text>
                        ) : null}
                    </span>
                );
            },
        },
        {
            title: '#',
            render: record => {
                return (
                    <>
                        <Popconfirm
                            title="Are you sure you want to delete the item ? "
                            onConfirm={() => deleteSelected(record)}
                            onCancel={() => {}}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button size="small">
                                <DeleteFilled />
                            </Button>
                        </Popconfirm>
                        {enableItemDiscount ? <MoreAction callback={record.callback} record={record} /> : null}
                    </>
                );
            },
        },
        // {
        //     title: '',
        //     key: '',
        //     render: (record) => {
        //         return (

        //         );
        //     },
        // },
    ];

    // Calculation to identify total on bill
    let total = 0;
    let subTotal = 0;

    // Find the total of items from the selected list
    items.forEach(entry => {
        total = total + entry.quantity * parseFloat(entry.amount);

        // Row level discount is saved in each record
        const { discount, amount } = entry;

        // var final = amount;
        if (discount) {
            if (discount.type === '%') {
                total = total - (amount * discount.value) / 100;
            } else {
                total = total - discount.value;
            }
        }
    });

    subTotal = total;

    return (
        <div className="summary-content">
            {items.length ? (
                <>
                    {/* <Title level={3}>Proforma Invoice</Title> */}
                    <div className="bill-table-wrapper">
                        <Table
                            className="bill-table"
                            rowKey={entry => entry.itm_code}
                            scroll={{ x: true }}
                            pagination={false}
                            columns={columns}
                            dataSource={items}
                            size="small"
                            summary={pageData => {
                                let total = 0;

                                // We need to iterate through the rows to add , apply discount and arrive the final value
                                pageData.forEach(entry => {
                                    var rowTotal = entry.quantity * parseFloat(entry.amount);

                                    // If row level discount is needed we have to mention that

                                    const { discount, amount } = entry;

                                    // var final = amount;

                                    if (discount) {
                                        if (discount.type === '%') {
                                            rowTotal = amount - (amount * discount.value) / 100;
                                        } else {
                                            rowTotal = amount - discount.value;
                                        }
                                    }

                                    total = total + rowTotal;
                                });

                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell>Total</Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>

                                            <Table.Summary.Cell></Table.Summary.Cell>

                                            <Table.Summary.Cell></Table.Summary.Cell>

                                            <Table.Summary.Cell></Table.Summary.Cell>

                                            {/* <Table.Summary.Cell></Table.Summary.Cell> */}
                                            <Table.Summary.Cell>
                                                {/* <Text> */}
                                                    {/* <Rupee value={total}></Rupee> */}
                                                    {/* {formatCurrency(total,currency)} */}
                                                {/* </Text> */}
                                                <PriceFormatter price={total}  locale={currency.priceFormat}
                                             currency={currency.currency_code}
                                             enablePriceFormatting={currency.enablePriceFormatting}/>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                        />
                    </div>
                </>
            ) : (
                <>
                    <p>Nothing added yet</p>
                </>
            )}
        </div>
    );

    /**
     * More Action for Row
     *
     * @returns
     */
    function MoreAction({ callback, record }) {
        const [visible, setVisible] = useState(false);

        const handleClick = params => {
            switch (params.key) {
                case 'item_discount':
                    setVisible(true);

                    break;
                case 'item_discount':
                    break;
                default:
            }
        };

        const menu = (
            <Menu onClick={handleClick}>
                <Menu.Item key="item_discount" className="item-discount">
                    Add Discount
                </Menu.Item>
            </Menu>
        );

        return (
            <>
                <Dropdown overlay={menu} placement="bottomLeft">
                    <Button size="small">
                        <MoreOutlined />
                    </Button>
                </Dropdown>

                {/* Modal for Adding Discount */}
                <Modal
                    destroyOnClose={true}
                    footer={null}
                    title="Item Discount"
                    visible={visible}
                    okText="Okay"
                    onOk={() => {
                        setVisible(false);
                    }}
                    onCancel={() => {
                        setVisible(false);
                    }}
                >
                    <DiscountModal
                        callback={values => {
                            setVisible(false);

                            callback(values);

                            onUpdateitmdiscount(values);
                        }}
                        subTotal={record.amount}
                        avoidRemark={true}
                        discount={record.discount}
                    />
                </Modal>
                {/* Modal for Adding Discount Ends */}
            </>
        );
    }
}

export default SelectedList;
