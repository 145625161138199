/***
 *
 * Payment list component
 * @description
 * @author Sajina
 */

import React, { useState, useEffect } from 'react';

import { Table, Skeleton } from 'antd';

import moment from 'moment-timezone';

import { Payments } from '../../models';

import './payment-list.scss';

import { useTranslation } from 'soxo-bootstrap-core';

export default function PaymentList({ record}) {
    const [resultArray, setResultArray] = useState({});

    const [list, setList] = useState([]);

    const [filtered, setFiltered] = useState(false);

    const [page, setPage] = useState(1);

    const [limit, setLimit] = useState(10);

    const [loading, setLoading] = useState(true);

    const { t, i18n } = useTranslation(); // To Translate to another language


    /**
     * Columns according to type
     */

    const columns = [
        {
            title: '#',

            dataIndex: 'index',

            render: (value, item, index) => (page - 1) * limit + index + 1,
        },

        {
            title: t('Amount'),

            key: 'amount',

            render: record => {
                return record ? record.amount : null;
            },
        },
        {
            title: t('Status'),

            key: 'status',

            render: record => {
                return record ? record.status : null;
            },
        },

        {
            title: t('Method'),

            key: 'method',

            render: record => {
                return record ? record.method : null;
            },
        },

        {
            title: t('User'),

            key: 'user',

            render: record => {
                return record ? record.user : null;
            },
        },

        {
            title: t('Time'),

            key: 'time',

            render: record => {
                return record ? moment.tz(record.time, '').format('DD/MM/YYYY hh:mm a') : null;
            },
        },
    ];

    /**
     * function to get paymentlist
     */

    async function getList() {
        setPage(1);

        setLimit(10);

        let id = record.da_id;

        await Payments.getPaymentList(id).then(res => {
            if (res.success) {
                setResultArray(res.data);
                setLoading(false);
            }

            // setResultArray(res.data);
        });
    }

    useEffect(() => {
        getList();
    }, []);

    return (
        <div className="payment">
            {loading ? (
                <>
                    <Skeleton active />
                </>
            ) : (
                <>
                    <>
                        <div className="payment-box">
                            <Table
                                dataSource={resultArray}
                                scroll={{ x: true }}
                                columns={columns}
                                pagination={{
                                    current: page,

                                    pageSize: limit,

                                    onChange(current, limit) {
                                        setPage(current);

                                        setLimit(limit);
                                    },
                                }}
                            />
                        </div>
                    </>
                </>
            )}
        </div>
    );
}
