/**
 * Registration Model
 *
 *
 * To seperate all login for Registration Process
 *
 */

import Model from './models';

import { ApiUtils } from 'soxo-bootstrap-core';

import moment from 'moment-timezone';

class Registration extends Model {
    constructor() {
        super();

        this.fields = [
            {
                field: 'name',
                caption: 'Name',
            },
            {
                field: 'amount',
                caption: 'Amount',
            },
        ];

        // this.columns = ;
    }

    get id() {
        return 'cold_id';
    }

    get getEndpoint() {
        return 'opreg';
    }

    get path() {
        return `collections`;
    }

    get getName() {
        return this.modelName + `s`;
    }

    get modelName() {
        return `collection`;
    }

    locationPointer = {
        1: 'nura',
        2: 'nuradel',
    };

    get columns() {
        return [
            {
                caption: 'Staff',
                field: 'staff.name',
                key: 'staff',
            },
            {
                caption: 'Weight',
                field: 'weight',
                key: 'weight',
            },
            {
                caption: 'Date',
                field: 'created_at',
                key: 'created_at',
            },
        ];
    }

    /**
 * Pincode Validation Api
 * @param {*} id 
 * @returns 
 */
    getPincodeDetails = (id) => {

        return ApiUtils.get({
            url: `list-country/pincode-details?id=${id}`,
        });

    }


    /**
     *
     * Get the registrations
     *
     * @param {*} range
     * @returns
     */
    getRegistrations = (range) => {
        const start_time = moment(range[0]).format('MM/DD/YYYY');
        const end_time = moment(range[1]).format('MM/DD/YYYY');

        return ApiUtils.get({
            url: `opreg/registrations?date_gte=${start_time}&date_lte=${end_time}`,
        });
    };

    getOpregDetail = (opno) => {
        return ApiUtils.get({
            url: `opreg/OpregId/${opno}`,
        });
    };

    /**
     * Load the registration detail
     * 
     * @param {*} id 
     * @returns 
     */
    getRegistrationRecord = (id) => {

        return ApiUtils.get({
            url: `opreg/registration-details/${id}`
        });
    };

    getRegistrationsList = (id) => {
        // let headers = {}
        // if (db_ptr) {
        //     headers = {
        //         ...headers,
        //         db_ptr: db_ptr
        //     }
        return ApiUtils.get({
            url: `opreg/registration-detail/${id}`,
            // headers
        });
        // }
    };

    /**
     * API call to get details of the opreg id
     * @param {*} id 
     * @returns 
     */
    getRegistrationDetail = (id) => {
        return ApiUtils.get({ url: `opreg/get-registration-detail/${id}` })
    }

    /**
     *
     */
    createNew = (id) => {
        return ApiUtils.post({ url: 'opbill/kiosk/' + id });
    };
}

export default Registration;
