/**
 * Appointments Model
 *
 *
 * Appointments is a record created on
 *
 */
import React, { useState, useEffect, useContext } from 'react';

import Model from './models';

import { Link } from 'react-router-dom';

import { Tag, Card, Typography, Button } from 'antd';

import { ApiUtils, GetData } from 'soxo-bootstrap-core';

// import moment from 'moment';

const { Text } = Typography;

const moment = require('moment-timezone');

class Preferences extends Model {
    constructor() {
        super();

        // this.fields = [
        //     {
        //         field: 'opb_name',
        //         caption: 'Name',
        //     },
        //     {
        //         field: 'opb_opno',
        //         caption: 'num',
        //     },
        // ];
    }

    get id() {
        return 'preferences';
    }

    get getEndpoint() {
        return 'core-user-preferences';
    }

    get path() {
        return `core-user-preferences`;
    }

    get getName() {
        return this.modelName + `s`;
    }

    get modelName() {
        return `Preference`;
    }

    getPreference = (opbd_id) => {
        return ApiUtils.get({ url: `opbilld/items/${opbd_id}` });
    };

    savePreference = (formBody) => {
        return ApiUtils.post({
            url: `core-user-preferences/create-preferences`,
            formBody,
            // headers: {
            //     db_ptr: 'nuramho',
            // },
        });
    };

    editPreference = (id, formBody) => {
        return ApiUtils.put({
            url: `core-user-preferences/update-preference/${id}`,
            formBody,
        });
    };

}

export default Preferences;