/**
 * Component to Show scrreporttran details
 *
 *
 * @author Sameena
 */

import React, { useEffect, useState, useContext } from 'react';

import { SCRFilesLink, Doctors, Bills } from '../../../../models';

import { message, Avatar, Typography, Row, Skeleton, Button, Modal, Form, Tabs, Card } from 'antd';

import { ApiUtils, PdfViewer, DateUtils, Location, GlobalContext, useTranslation } from 'soxo-bootstrap-core';

import { UserOutlined } from '@ant-design/icons';

import { ReportPreviewFileLoader } from './../../../../components/report-preview/report-preview';

import './report-links-viewer.scss';
import ReportLink from '../report-link/report-link';
/**
 *
 * @returns
 */
export default function ReportPreview(props) {
    var params = Location.search();

    var { opb_id } = props.match.params;

    //Get logged user data
    const { user, isMobile } = useContext(GlobalContext);

    const [selected, setSelected] = useState({
        reports: [],
    });

    const [isDoctor, setDoctor] = useState(false);

    const [loading, setLoading] = useState(false);

    // variable for final report details
    const [finalReport, setFinalReport] = useState({});

    const { t, i18n } = useTranslation();   // To Translate to another language

    const [patient, setPatient] = useState({
        opReg: {},
        report: [],
    }); //Patiants list array

    useEffect(async () => {
        await getReports();

        //Check the logged use is doctor or not
        if (user.staff_id) {
            getDoctorDeatils(user.staff_id);
        }
    }, []);

    /**
     * Getting reports
     *
     */
    async function getReports() {
        //Load report with accession no
        await SCRFilesLink.getReportDetails(params.opb_id, params.op_no).then(result => {
            setPatient(result.data);

            if (result.data.report && result.data.report[0] && result.data.report[0].report_details) {
                let selectedReport = {
                    report_type: 'RPT',
                    report_path: result.data.report[0].scrrept_filename,
                    report_id: result.data.report[0].scrrept_id,
                };

                console.log(selectedReport);

                // The first report is selected here

                setFinalReport({ ...selectedReport });

                setSelected({ ...selectedReport });
                // loadPDF(result.data.report[0].report_details[0])
            }
        });
    }

    // Get doctor details to check logged user is doctor or not
    function getDoctorDeatils(id) {
        Doctors.getDoctorDeatils(id).then(result => {
            if (result) {
                setDoctor(true);
            }
        });
    }

    let { opReg = {} } = patient;

    return (
        <>
            {loading ? (
                <Skeleton />
            ) : (
                <>
                    <div className="report-preview report-links-viewer">
                        {/* <div className='title'>
                            <Title className='heading' level={7} style={{ fontSize: '24px' }}>
                                Reports
                            </Title>
                        </div> */}

                        <div className="page-header">
                            <div className="page"></div>
                            <div className="actions"></div>
                        </div>

                        <div className="details-section">
                            <div className="left-section">
                                <div className="left-top">
                                    <div className="card card-shadow">
                                        <div className="card-title">
                                            <h3>{t('GUEST INFORMATION')}</h3>
                                        </div>

                                        <div className="visit-info">
                                            <div className="first-row">
                                                <Avatar shape="circle" size={50} icon={<UserOutlined />} />

                                                <div className="name-section">
                                                    <Row>
                                                        <label className="label-class">
                                                            <span className="details">{t('NAME')}</span>
                                                        </label>
                                                    </Row>

                                                    <Row style={{ fontSize: '20px' }}>
                                                        <span>
                                                            <h5>
                                                                <strong>
                                                                    {opReg.op_fname} {opReg.op_lname}
                                                                </strong>
                                                            </h5>
                                                        </span>
                                                    </Row>
                                                </div>
                                            </div>

                                            {/** patient personal details section start */}
                                            <div className="second-row">
                                                <div className="detail-element" style={{ marginRight: '2rem' }}>
                                                    <Row>
                                                        <label className="label-class">
                                                            <span className="details">{t('DOB')}</span>
                                                        </label>
                                                    </Row>

                                                    <Row className="row-class">
                                                        <span>{opReg.op_dob ? DateUtils.formatDate(opReg.op_dob) : null}</span>
                                                    </Row>
                                                </div>

                                                {/* </div> */}

                                                <div className="detail-element" style={{ paddingRight: '2rem' }}>
                                                    <Row>
                                                        <label className="label-class">
                                                            <span className="details">{t('PHONE')}</span>
                                                        </label>
                                                    </Row>

                                                    <Row className="row-class">
                                                        <span>{opReg.op_phone}</span>
                                                    </Row>
                                                </div>

                                                <div className="detail-element" style={{ paddingRight: '2rem' }}>
                                                    <Row>
                                                        <label className="label-class">
                                                            <span className="details">{t('GENDER')}</span>
                                                        </label>
                                                    </Row>

                                                    <Row className="row-class">
                                                        <span>{opReg.op_gender}</span>
                                                    </Row>
                                                </div>

                                                <div className="detail-element" style={{ marginRight: '2rem' }}>
                                                    <Row>
                                                        <label className="label-class">
                                                            <span className="details">{t('PLACE')}</span>
                                                        </label>
                                                    </Row>

                                                    <Row className="row-class">
                                                        <span>{opReg.op_place}</span>
                                                    </Row>
                                                </div>
                                                {/* </div> */}

                                                <div className="detail-element" style={{ paddingRight: '2rem' }}>
                                                    <Row>
                                                        <label className="label-class">
                                                            <span className="details">{t('EMAIL')}</span>
                                                        </label>
                                                    </Row>

                                                    <Row className="row-class">
                                                        <span>{opReg.op_email}</span>
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <ReportLink
                                        billId={params.opb_id}
                                        displayColumns={false}
                                        showRadioButtons={false}
                                        callback={values => {
                                            setSelected(values);
                                        }}
                                    />

                                    {/* <div className="card-title">
                                        <h3>REPORTS</h3>
                                    </div>

                                    <div className="left-bottom">
                                        <div
                                            className={`test-name card card-shadow ${(selected.report_id === finalReport.report_id) ? 'active' : null}`}
                                            onClick={() => {

                                                let selectedReport = {
                                                    report_path: finalReport.report_path,
                                                    report_type: 'RPT',
                                                    report_id: finalReport.report_id
                                                }
                                                setSelected({ ...selectedReport })
                                            }}
                                            style={{ height: '64px' }}
                                        >
                                            <p> Final Report </p>
                                        </div>

                                        {
                                            patient.report && patient.report[0] ? patient.report[0].report_details.map((result, index) => {

                                                return (
                                                    <div
                                                        className={`test-name card card-shadow ${selected.report_id === result.scrreptd_id ? 'active' : ''
                                                            }`}
                                                        onClick={() => {

                                                            let selectedReport = {
                                                                report_path: result.scrreptd_filename,
                                                                report_type: 'RPT',
                                                                report_id: result.scrreptd_id
                                                            }

                                                            console.log(selectedReport, result)

                                                            setSelected({ ...selectedReport })
                                                        }}
                                                    >
                                                        {index + 1}.{result.report_master_details.srepmd_desc}
                                                    </div>
                                                );
                                            }) : null
                                        }
                                    </div> */}
                                </div>
                            </div>

                            {/* Right Section */}
                            <div className="card right-section card-shadow">
                                <div className="tab-section">
                                    {selected && selected.report_path ? (
                                        <>
                                            <ReportPreviewFileLoader selected={selected} config={props.reportConfig} />
                                        </>
                                    ) : null}
                                </div>
                            </div>
                            {/* Right Section Ends */}
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
